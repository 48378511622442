import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { AreasModule } from "../../../API/Areas";
import { Shifts } from "../../../API/Shifts";
import Content from "../Common/Content";
import ContentHeading from "../Common/ContentHeading";
import TextHeading from "../Common/Heading";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface ShiftDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
  OpenUpdateModal?: () => void;
}
export default function ShiftDetailModal({
  isOpen,
  onClose,
  id,
  OpenUpdateModal,
}: ShiftDetailModalProps) {
  const toast = useToast();
  const DeleteModal = useDisclosure();
  const [isLoading, setIsLoading] = useBoolean(true);
  const { getValues, reset } = useFormContext<Shifts.Shift>();
  const areas = getValues("areas");
  const { setLastUpdated, employees, locations } = useScheduleContext();
  const locationTimezone =
    locations.find((location: any) => location.id === getValues("locationId"))
      ?.timezone ?? "";
  const { employeesList, locationList } = useBusinessContext();
  const [areasList, setAreasList] = useState<Array<AreasModule.Area>>([]);
  useEffect(() => {
    setIsLoading.on();
    if (id) {
      Shifts.FetchShiftById(
        id,
        (sh) => {
          reset(sh);
          setIsLoading.off();
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
          setIsLoading.off();
        }
      );
      AreasModule.FetchAreas(
        (res) => {
          setAreasList(res);
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
        }
      );
    }
  }, [id]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor="primary.500" borderTopRadius="6px">
          <HStack w="100%">
            <TextHeading flex={1} color="white">
              {moment(getValues("shiftStartDate"), "YYYY-MM-DD").format(
                "dddd, MMMM Do YYYY"
              )}
            </TextHeading>

            <Icon
              as={AiOutlineClose}
              boxSize="17px"
              onClick={onClose}
              color="white"
            />
          </HStack>
        </ModalHeader>
        <ModalBody p="25px">
          {isLoading ? (
            <Center>
              <Spinner size="lg" color="secondary.500" />
            </Center>
          ) : (
            <Stack spacing="16px">
              <HStack justifyContent="space-between">
                <Stack spacing="4px">
                  <ContentHeading>Member</ContentHeading>
                  <Content>
                    {employeesList?.find((emp) => {
                      return emp.id === getValues("employeeId");
                    })?.firstName +
                      " " +
                      employeesList?.find((emp) => {
                        return emp.id === getValues("employeeId");
                      })?.lastName}
                  </Content>
                  ;
                </Stack>
                <Stack spacing="4px">
                  <ContentHeading>Location</ContentHeading>
                  <Content>
                    {
                      locationList?.find((loc) => {
                        return loc.id === getValues("locationId");
                      })?.name
                    }
                  </Content>
                  ;
                </Stack>
                <Stack spacing="4px">
                  <ContentHeading>Area</ContentHeading>
                  <Content>
                    {getValues("areas")
                      ?.map((areaId: string) => {
                        return areasList.find((area) => area.id === areaId)
                          ?.name;
                      })
                      .join(", ")}
                  </Content>
                </Stack>
              </HStack>
              <HStack>
                <Stack w="50%" spacing="4px">
                  <ContentHeading>Start Time</ContentHeading>

                  <Content>
                    {moment(getValues("shiftStartTime"), "hh:mm:ss").format(
                      "hh:mm a"
                    )}
                  </Content>
                </Stack>
                <Stack spacing="4px">
                  <ContentHeading>
                    {moment(getValues("shiftStartTime"), "hh:mm:ss").isAfter(
                      moment(getValues("shiftEndTime"), "hh:mm:ss")
                    ) ? (
                      <ContentHeading>End Time - Next Day</ContentHeading>
                    ) : (
                      <ContentHeading>End Time</ContentHeading>
                    )}
                  </ContentHeading>

                  <Content>
                    {moment(getValues("shiftEndTime"), "hh:mm:ss").format(
                      "hh:mm a"
                    )}
                  </Content>
                </Stack>
              </HStack>
              <HStack>
                <Stack w="50%" spacing="4px">
                  <ContentHeading>Break (Minutes)</ContentHeading>
                  <Content>{getValues("break")}</Content>;
                </Stack>
              </HStack>
              <Stack spacing="4px">
                <ContentHeading fontSize="11px" color="icons.primary">
                  Notes
                </ContentHeading>
                <Content>{getValues("notes")}</Content>;
              </Stack>
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              px="16px"
              h="32px"
              borderRadius="4px"
              colorScheme="primary"
              onClick={OpenUpdateModal}
            >
              Edit
            </Button>
            <Button
              px="16px"
              h="32px"
              borderRadius="4px"
              colorScheme="red"
              onClick={() => {
                DeleteModal.onOpen();
              }}
            >
              Delete
            </Button>
            <Modal isOpen={DeleteModal.isOpen} onClose={DeleteModal.onClose}>
              <ModalOverlay />
              <ModalContent mx="10px">
                <ModalHeader fontWeight="semibold" py="4px">
                  Confirmation
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to Delete this?</ModalBody>

                <ModalFooter
                  display="flex"
                  justifyContent="flex-end"
                  pt="16px"
                  border="0px"
                >
                  <ButtonGroup size="xs" variant="solid" borderRadius="5px">
                    <Button onClick={DeleteModal.onClose} borderRadius="5px">
                      No
                    </Button>
                    <Button
                      colorScheme="red"
                      borderRadius="5px"
                      onClick={() => {
                        if (id) {
                          Shifts.DeleteShiftBulk(
                            { shiftIds: [id] },
                            () => {
                              toast({
                                title: "Success",
                                description: "Shift deleted sucessfully",
                                status: "success",
                              });
                              onClose();
                              DeleteModal.onClose();
                              setLastUpdated(Date.now());
                            },
                            (error) => {
                              toast({
                                title: "Error",
                                description: error,
                                status: "error",
                              });
                            }
                          );
                        }
                      }}
                    >
                      Yes
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
