import { axiosInstance } from "./axiosInstance-hris";

declare module ChartOfAccounts {
  type AddNewAccount = {
    accountCategory: string;
    accountType: string;
    code: number;
    name: string;
    description: string;
    businesTaxRateId: string;
    showDashboardWatchlist: boolean;
    showExpenseClaims: boolean;
    enablePayments: boolean;
  };
  type BulkUploadData = {
    accountCategory: string;
    accountType: string;
    code: number;
    name: string;
    description: string;
    businesTaxRateId: string | undefined;

    showDashboardWatchlist: boolean;
    showExpenseClaims: boolean;
    enablePayments: boolean;
    businessId: string | undefined;
  };
  type AddBulkChartOfAccount = {
    data: Array<BulkUploadData>;
  };
  type FetchChartOfAccounts = {
    id: string;
    accountCategory: string;
    accountType: string;
    code: number;
    name: string;
    description: string;
    businesTaxRateId: string;
    showDashboardWatchlist: boolean;
    showExpenseClaims: boolean;
    enablePayments: boolean;

    businessId: string;

    archive: boolean;
  };
  type FetchChartOfAccountsYTD = {
    [key: string]: number;
  };
  type ChangeTaxRate = {
    ids: string[];
    businesTaxRateId: string;
  };
}
module ChartOfAccounts {
  export enum AccountType {
    "CURRENT_ASSET" = "CURRENT_ASSET",
    "FIXED_ASSET" = "FIXED_ASSET",
    "INVENTORY" = "INVENTORY",
    "NON_CURRENT_ASSET" = "NON_CURRENT_ASSET",
    "PREPAYMENT" = "PREPAYMENT",
    EQUITY = "EQUITY",
    DEPRECIATION = "DEPRECIATION",
    "DIRECT_COSTS" = "DIRECT_COSTS",
    "EXPENSE" = "EXPENSE",
    OVERHEAD = "OVERHEAD",
    "CURRENT_LIABILITY" = "CURRENT_LIABILITY",
    LIABILITY = "LIABILITY",
    NON_CURRENT_LIABILITY = "NON_CURRENT_LIABILITY",
    OTHER_INCOME = "OTHER_INCOME",
    REVENUE = "REVENUE",
    SALES = "SALES",
  }
  export enum AccountCategory {
    ASSETS = "ASSETS",
    REVENUE = "REVENUE",
    EQUITY = "EQUITY",
    LIABILITY = "LIABILITY",
    EXPENSES = "EXPENSES",
    ARCHIVE = "ARCHIVE",
  }
  const API_URL = "/chartOfAccounts";
  export function NewAccountAdded(
    data: AddNewAccount,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function PostBulkUploadChartOfAccount(
    data: AddBulkChartOfAccount,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/bulk`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateChartOfAccounts(
    id: string,
    data: Partial<AddNewAccount>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function UpdatedTaxRate(
    // id: Array<string>,
    data: Partial<ChangeTaxRate>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/bulk/changeTaxRate`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function DeleteChartOfAccounts(
    id: Array<string>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function GetAllAccounts(
    params: {
      category?: string;
      archive?: boolean;
      limit?: number;
      page?: number;
    },
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function GetChartofAccountThroughCategory(
    success: (data: any) => void,
    error: (message: string) => void,
    accountCategory?: string // Optional parameter
  ) {
    const apiUrl = accountCategory
      ? `${API_URL}/accountCategory/${accountCategory}`
      : API_URL;

    axiosInstance
      .get(apiUrl)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function ArchiveChartOfAccounts(
    ids: string[],
    achieveStatus: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/accounts/archive/${achieveStatus}`, { ids })
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function FetchAllArchivedAccounts(
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    const apiUrl = `${API_URL}/accounts/archived`;

    axiosInstance
      .get(apiUrl)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function FetchChartofAccountsYTD(
    success: (data: ChartOfAccounts.FetchChartOfAccountsYTD) => void,
    error: (message: string) => void
  ) {
    const apiUrl = `${API_URL}/ytds`;

    axiosInstance
      .get(apiUrl)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
}
export { ChartOfAccounts };
