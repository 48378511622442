import { axiosInstance } from "./axiosInstance-hris";

declare module TaxFinancialYear {
  type AddNewTax = {
    id?: string;
    name: string;
    startDate: string;
    endDate: string;
    chartOfAccountId: string;
    taxBrackets: Array<taxBracketsComponent>;
    isActivated: boolean;
  };

  type taxBracketsComponent = {
    minimum: number | undefined;
    maximum: number | undefined;
    percentage: number | undefined;
    isHighestSlab: boolean;
  };
  type FetchTaxBrackets = {
    id: string;
    minimum: number;
    maximum: number;
    percentage: number;
    fixedAmount: number;
    taxFinancialYearId: string;
    isHighestSlab: boolean;
  };
  type FetchTax = {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    chartOfAccountId: string;
    taxBrackets: Array<FetchTaxBrackets>;
    isActivated: boolean;
  };
}
module TaxFinancialYear {
  const API_URL = "/tax-financial-years";
  export function AddTaxFinancialYear(
    data: AddNewTax,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateTaxFinancialYear(
    id: string,
    data: Partial<AddNewTax>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetTaxFinancialYear(
    success: (data: Array<FetchTax>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
  export function GetActiveTaxFinancialYear(
    success: (data: FetchTax) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/active`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }

  export function DeleteTaxFinancialYear(
    id: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateStatus(
    id: string,
    isActive: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/status/${id}/${isActive}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchTaxById(
    id: string,
    success: (data: AddNewTax) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
}
export { TaxFinancialYear };
