import { Box } from "@chakra-ui/react";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement, Tooltip, Legend);
interface StaffChartProps {
  maleGenderEmpCount?: string;
  femaleGenderEmpCount?: string;
  otherGenderEmpCount?: string;
}
export default function StaffChart({
  femaleGenderEmpCount,
  maleGenderEmpCount,
  otherGenderEmpCount,
}: StaffChartProps) {
  const staff = {
    labels: ["Male", "Female", "Others"],
    datasets: [
      {
        data: [maleGenderEmpCount, femaleGenderEmpCount, otherGenderEmpCount],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 255, 0, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 255, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,

        bodyFont: {
          size: 10,
        },
        titleFont: {
          size: 10,
        },
      },
    },
  };
  return (
    <Box w="60px" h="60px" marginRight={"5px"}>
      {/* <CircularProgress
                  value={42}
                  color="orange.400"
                  thickness="11px"
                  size="40px"
                /> */}
      <Doughnut data={staff} options={options} />
    </Box>
  );
}
