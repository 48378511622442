import {
  Box,
  Button,
  Center,
  Container,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { jwtDecode } from "jwt-decode";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Employees } from "../../Api/Employees";

import TableData from "./Components/tableData";

export default function EmployeeReport() {
  const religionOption = [
    { value: "CHRISTIANITY", label: "Christianity" },
    { value: "ISLAM", label: "Islam" },
    { value: "JUDAISM", label: "Judaism" },
    { value: "ZOROASTRIANISM", label: "Zoroastrianism" },
    { value: "HINDUISM", label: "Hinduism" },
    { value: "BUDDHISM", label: "Buddhism" },
    { value: "ATHEIST", label: "Atheist/NonReligious" },
    { value: "Sikhism", label: "Sikhism" },
    { value: "JAINISM", label: "Jainism" },
    { value: "OTHER", label: "Other Religions" },
  ];
  const provinceOptions = [
    { value: "PUNJAB", label: "Punjab" },
    { value: "SINDH", label: "Sindh" },
    { value: "BALOCHISTAN", label: "Balochistan" },
    { value: "KPK", label: "KPK" },
    { value: "GILGIT BALTISTAN", label: "Gilgit Baltistan" },
    { value: "AJK", label: "AJK" },
  ];
  const maritialStatusOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "SINGLE", label: "Single" },
    { value: "WIDOWED", label: "Widowed" },
    { value: "DIVORCED", label: "Divorced" },
    { value: "SEPARATED", label: "Separated" },
    { value: "DEFACTO", label: "Defacto" },
    { value: "OTHER", label: "Other" },
  ];
  const relationOptions: Array<{ label: string; value: string }> = [
    {
      label: "Father",
      value: "FATHER",
    },
    {
      label: "Mother",
      value: "MOTHER",
    },
    {
      label: "Spouse",
      value: "SPOUSE",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ];
  const terminationReason = [
    "Voluntary cessation",
    "III health",
    "Deceased",
    "Redundancy",
    "Dismissal",
    "Contract cessation",
    "Transfer",
  ].map((options) => ({ label: options, value: options }));
  const incomeTypeOptions = [
    { value: "SALARY_AND_WAGES", label: "Monthly Salary" },
    { value: "CLOSELY_HELD_PAYEES", label: "Closely held payees" },
    { value: "WORKING_HOLIDAY_MAKER", label: "Working holiday maker" },
  ];
  const contractorIncomeTypeOptions = [
    { value: "LABOUR_HIRE", label: "Labour hire" },
    { value: "NON_EMPLOYEE", label: "Non-Employee" },
  ];

  const employeeBasicOptions = [
    { value: "FULL_TIME", label: "Full-time" },
    { value: "PART_TIME", label: "Part-time" },
    { value: "CASUAL", label: "Daily Wager" },
  ];
  const [EmployeeReportData, setEmployeeReportData] =
    useState<Employees.EmployeeReport>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const toast = useToast();
  useEffect(() => {
    setIsLoading(true);
    Employees.FetchEmployeesReportByID(
      id!,
      (data) => {
        setEmployeeReportData(data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);

  const token = localStorage.getItem("_auth");
  const decodedToken = jwtDecode<any>(token!);
  const businessId = decodedToken.businessId;
  const { businessesList } = useBusinessContext();
  const captureScreenshot = async () => {
    const div = document.querySelector("#scrollable-component");

    if (div) {
      const totalColPadding =
        (div
          ?.getElementsByTagName("thead")
          .item(0)
          ?.getElementsByTagName("tr")
          .item(0)
          ?.getElementsByTagName("th").length ?? 0) * 12;

      const doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "A4",
      });

      const img = {
        width: 0,
        height: 0,
      };
      const addCustomHeader = (data: any) => {
        if (data?.pageNumber === 1) {
          //   if (business?.logoPath) {
          //     doc.addImage(
          //       business?.logoPath + "?r=" + Math.floor(Math.random() * 100000),
          //       "JPEG",
          //       data.settings.margin.left,
          //       30,
          //       img.width,
          //       img.height
          //     );
          //   }
          doc.setFontSize(18);
          doc.setTextColor(0, 0, 0);
          doc.text(
            "Employee Detail Report",
            data.settings.margin.left,
            img.height + 50
          );
          doc.setFontSize(20);
          doc.setTextColor(0, 0, 0);
          doc.text(
            businessesList.find((business) => business.id === businessId)
              ?.name ?? "",
            data.settings.margin.left,
            img.height + 70
          );

          //   doc.setFontSize(20);
          //   doc.setTextColor(0, 0, 0);
          //   doc.text(
          //     `For the period ${dateRange.startDate.format(
          //       "DD/MM/YYYY"
          //     )} to ${dateRange.endDate.format("DD/MM/YYYY")}`,
          //     data.settings.margin.left,
          //     img.height + 90
          //   );

          // doc.setFontSize(20);
          // doc.setTextColor(0, 0, 0);
          // doc.text(
          //   `Report Type: ${selectedReport.label}`,
          //   data.settings.margin.left,
          //   img.height + 110,
          // );
        }
        data.settings.margin.top = 50;
      };

      autoTable(doc, {
        html: "#scrollable-component",
        pageBreak: "auto",
        margin: {
          top: (img?.height ?? 0) + 100,
          right: 30,
          bottom: 10,
          left: 30,
        },

        theme: "grid",
        includeHiddenHtml: true,
        willDrawPage: addCustomHeader,
        useCss: true,
        tableWidth: 400,
        columnStyles: {
          0: {
            cellWidth: 100,
          },
          1: {
            cellWidth: 300,
          },
        },
      });

      doc.save("Employee full detail Report.pdf");
    }
  };
  return (
    <Stack py="25px" px="60px" h="calc(100vh - 70px)" overflowY="auto">
      <Container maxW="container.md">
        <Stack justifyContent="center">
          <Text
            fontSize="16px"
            fontWeight={700}
            textAlign="center"
            _light={{
              color: "customColor.black7",
            }}
          >
            Employee Detail Report
          </Text>
          <Text
            fontSize="16px"
            fontWeight={700}
            textAlign="center"
            _light={{
              color: "customColor.black7",
            }}
          >
            {businessesList.find((business) => business.id === businessId)
              ?.name ?? ""}
          </Text>
        </Stack>
        <Box display="flex" justifyContent="flex-end">
          <Button
            mt="15px"
            mb="15px"
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              captureScreenshot();
            }}
          >
            Download
          </Button>
        </Box>

        <TableContainer>
          <Table id={"scrollable-component"}>
            <Tbody>
              {isLoading ? (
                <Center>
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="primary.500"
                    size="xl"
                  />
                </Center>
              ) : (
                <Fragment>
                  <Tr
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      bg="gray.200"
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderRightWidth={"1px"}
                      borderLeftWidth={"1px"}
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRightColor={"borders.tableBorder"}
                      borderLeftColor={"borders.tableBorder"}
                      textAlign="center"
                      colSpan={2}
                    >
                      Personal Details
                    </Td>
                  </Tr>
                  <Fragment>
                    <TableData
                      property="Title"
                      value={EmployeeReportData?.title}
                    />
                    <TableData
                      property="First Name"
                      value={EmployeeReportData?.firstName}
                    />
                    <TableData
                      property="Middle Name"
                      value={
                        EmployeeReportData?.middleName
                          ? EmployeeReportData.middleName
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Last Name"
                      value={EmployeeReportData?.lastName}
                    />
                    <TableData
                      property="Email"
                      value={EmployeeReportData?.email}
                    />
                    <TableData
                      property="Mobile Number"
                      value={EmployeeReportData?.mobileNumber}
                    />
                    <TableData
                      property="Date of Birth"
                      value={EmployeeReportData?.dateOfBirth}
                    />
                    <TableData
                      property="Religion"
                      value={
                        religionOption.find(
                          (opt) => opt.value === EmployeeReportData?.religion
                        )?.label
                      }
                    />
                    <TableData
                      property="Gender"
                      value={
                        EmployeeReportData?.gender === "MALE"
                          ? "Male"
                          : "Female"
                      }
                    />
                    <TableData
                      property="Address"
                      value={EmployeeReportData?.address}
                    />
                    <TableData
                      property="City"
                      value={EmployeeReportData?.city}
                    />
                    <TableData
                      property="Province"
                      value={
                        provinceOptions.find(
                          (opt) => opt.value === EmployeeReportData?.province
                        )?.label
                      }
                    />
                    <TableData
                      property="Country"
                      value={EmployeeReportData?.country}
                    />
                    <TableData
                      property="Postal Code"
                      value={EmployeeReportData?.postalCode}
                    />
                    <TableData
                      property="Cnic"
                      value={EmployeeReportData?.cnic}
                    />
                    <TableData
                      property="Cnic Issue Date"
                      value={EmployeeReportData?.cnicIssuance}
                    />
                    <TableData
                      property="Cnic Expiry Date"
                      value={EmployeeReportData?.cnicExpiry}
                    />
                    <TableData
                      property="Nationality"
                      value={EmployeeReportData?.nationality}
                    />
                    <TableData
                      property="Marital Status"
                      value={
                        maritialStatusOptions.find(
                          (opt) =>
                            opt.value === EmployeeReportData?.maritalStatus
                        )?.label
                      }
                    />
                    <TableData
                      property="Parent/Partner Name"
                      value={EmployeeReportData?.parentOrPartner}
                    />
                    <TableData
                      property="Relationship Type"
                      value={
                        relationOptions.find(
                          (opt) =>
                            opt.value === EmployeeReportData?.relationType
                        )?.label
                      }
                    />
                    <TableData
                      property="Blood Group"
                      value={EmployeeReportData?.bloodGroup}
                    />

                    <TableData
                      property="Passport Number"
                      value={
                        EmployeeReportData?.passportNumber
                          ? EmployeeReportData.passportNumber
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Passport Issuance"
                      value={
                        EmployeeReportData?.passportIssuance
                          ? EmployeeReportData.passportIssuance
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Passport Expiry"
                      value={
                        EmployeeReportData?.passportExpiry
                          ? EmployeeReportData.passportExpiry
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Driving License Number"
                      value={
                        EmployeeReportData?.driverLicenseNumber
                          ? EmployeeReportData.driverLicenseNumber
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Driving License Issuance"
                      value={
                        EmployeeReportData?.driverLicenseIssuance
                          ? EmployeeReportData.driverLicenseIssuance
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Driving License Expiry"
                      value={
                        EmployeeReportData?.driverLicenseExpiry
                          ? EmployeeReportData.driverLicenseExpiry
                          : "N/A"
                      }
                    />
                  </Fragment>
                  <Tr
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      bg="gray.200"
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderRightWidth={"1px"}
                      borderLeftWidth={"1px"}
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRightColor={"borders.tableBorder"}
                      borderLeftColor={"borders.tableBorder"}
                      textAlign="center"
                      colSpan={2}
                    >
                      Employment Details
                    </Td>
                  </Tr>
                  <Fragment>
                    <TableData
                      property="Employment Type"
                      value={
                        EmployeeReportData?.employeeEmployementDetails
                          ?.empType === "EMPLOYEE"
                          ? "Employee"
                          : EmployeeReportData?.employeeEmployementDetails
                              ?.empType === "CONTRACTOR"
                          ? "Contractor"
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Income Type"
                      value={
                        EmployeeReportData?.employeeEmployementDetails
                          ?.empIncomeType
                          ? incomeTypeOptions.find(
                              (opt) =>
                                opt.value ===
                                EmployeeReportData?.employeeEmployementDetails
                                  ?.empIncomeType
                            )?.label
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Employment Basis"
                      value={
                        EmployeeReportData?.employeeEmployementDetails?.empBasis
                          ? employeeBasicOptions?.find(
                              (opt) =>
                                opt.value ===
                                EmployeeReportData?.employeeEmployementDetails
                                  ?.empBasis
                            )?.label
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Employment Start Date"
                      value={
                        EmployeeReportData?.employeeEmployementDetails
                          ?.startDate
                          ? EmployeeReportData.employeeEmployementDetails
                              ?.startDate
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Employment End Date"
                      value={
                        EmployeeReportData?.employeeEmployementDetails?.endDate
                          ? EmployeeReportData.employeeEmployementDetails
                              ?.endDate
                          : "N/A"
                      }
                    />
                    <TableData
                      property="Department"
                      value={
                        EmployeeReportData?.employeeEmployementDetails
                          ?.department
                          ? EmployeeReportData?.employeeEmployementDetails
                              ?.department
                          : "N/A"
                      }
                    />
                  </Fragment>
                  <Tr
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      bg="gray.200"
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderRightWidth={"1px"}
                      borderLeftWidth={"1px"}
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRightColor={"borders.tableBorder"}
                      borderLeftColor={"borders.tableBorder"}
                      textAlign="center"
                      colSpan={2}
                    >
                      Education Details
                    </Td>
                  </Tr>
                  {(EmployeeReportData?.education?.length ?? 0) > 0 ? (
                    EmployeeReportData?.education?.map((education) => {
                      return (
                        <Fragment>
                          <TableData
                            property="Qualification"
                            value={education?.qualification}
                          />
                          <TableData
                            property="Course Name"
                            value={education?.courseName}
                          />
                          <TableData
                            property="Institute"
                            value={education?.instituteName}
                          />
                          <TableData
                            property="Start Date"
                            value={education?.dateFrom}
                          />
                          <TableData
                            property="End Date"
                            value={education?.dateTo}
                          />
                          <TableData
                            property="Country"
                            value={education?.country}
                          />
                        </Fragment>
                      );
                    })
                  ) : (
                    <Tr borderWidth={"1px"} borderColor={"borders.tableBorder"}>
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        borderBottomWidth="0px"
                        textAlign="center"
                        colSpan={2}
                      >
                        No Education Details Found
                      </Td>
                    </Tr>
                  )}
                  <Tr
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      bg="gray.200"
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderRightWidth={"1px"}
                      borderLeftWidth={"1px"}
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRightColor={"borders.tableBorder"}
                      borderLeftColor={"borders.tableBorder"}
                      textAlign="center"
                      colSpan={2}
                    >
                      References
                    </Td>
                  </Tr>
                  {(EmployeeReportData?.references?.length ?? 0) > 0 ? (
                    EmployeeReportData?.references?.map((ref) => {
                      return (
                        <Fragment>
                          <TableData
                            property="Reference Type"
                            value={ref?.referenceType}
                          />
                          <TableData property="Name" value={ref?.name} />
                          <TableData
                            property="Position"
                            value={ref?.position}
                          />
                          <TableData property="Email" value={ref?.email} />
                          <TableData
                            property="Contact Number"
                            value={ref?.contact}
                          />
                        </Fragment>
                      );
                    })
                  ) : (
                    <Tr borderWidth={"1px"} borderColor={"borders.tableBorder"}>
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        borderBottomWidth="0px"
                        textAlign="center"
                        colSpan={2}
                      >
                        No References Found
                      </Td>
                    </Tr>
                  )}
                  <Tr
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      bg="gray.200"
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderRightWidth={"1px"}
                      borderLeftWidth={"1px"}
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      borderRightColor={"borders.tableBorder"}
                      borderLeftColor={"borders.tableBorder"}
                      textAlign="center"
                      colSpan={2}
                    >
                      Bank account Details
                    </Td>
                  </Tr>
                  {(EmployeeReportData?.employeeBankDetails?.length ?? 0) >
                  0 ? (
                    EmployeeReportData?.employeeBankDetails?.map((acc) => {
                      return (
                        <Fragment>
                          <TableData
                            property="Account Title"
                            value={acc?.accountName}
                          />
                          <TableData
                            property="Account Number"
                            value={acc?.accountNumber}
                          />
                          <TableData property="IBAN" value={acc.iban} />
                          <TableData
                            property="Bank Name"
                            value={acc?.bankName}
                          />
                          <TableData
                            property="Branch Code"
                            value={acc?.branchCode}
                          />
                        </Fragment>
                      );
                    })
                  ) : (
                    <Tr borderWidth={"1px"} borderColor={"borders.tableBorder"}>
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        borderBottomWidth="0px"
                        textAlign="center"
                        colSpan={2}
                      >
                        No Bank Details Found
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Stack>
  );
}
