import {
  Button,
  Container,
  Divider,
  Skeleton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccessControl } from "../../../../../../api/AccessControl";
import { InviteUser } from "../../../../../../api/InviteUser";
import CurrentUsers from "./CurrentUsers/CurrentUsers";
import PendingUsers from "./PendingUsers/PendingUsers";

export function AllUsers() {
  const navigate = useNavigate();
  const height = window.innerHeight - 60 + "px";
  const toast = useToast();
  const [allPendingUsers, setAllPendingUsers] = useState<
    Array<InviteUser.userDetail>
  >([]);
  const [allActiveUsers, setAllActiveUsers] = useState<
    Array<AccessControl.UserDetail>
  >([]);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    InviteUser.FetchPendingUsers(
      (data) => {
        setAllPendingUsers(data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
    AccessControl.FetchActiveUsers(
      (data) => {
        setAllActiveUsers(data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  return (
    <Stack
      padding={"24px 16px"}
      // height={height}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        // bgColor: "customColor.gray.400",
        bgColor: "customColor.white",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Container
        maxW={"container.xl"}
        height={height}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"0px 60px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          padding={"0px 0px 16px"}
          gap={0}
        >
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            _light={{
              bg: "#fff",
            }}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Add HRIS Support
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => navigate("/app/payroll/InviteUser")}
          >
            Invite a User
          </Button>
        </Stack>

        <Stack
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="borders.tableBorder"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderRadius={"3px"}
          gap={0}
          overflowX={"auto"}
          cursor={"pointer"}
        >
          <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
            {allPendingUsers.length > 0 && (
              <Fragment>
                <PendingUsers
                  allPendingUsers={allPendingUsers}
                  setLastUpdated={setLastUpdated}
                  isLoading={isLoading}
                />
                <Divider borderBottom={"1px solid #ccced2"} />
              </Fragment>
            )}
            {allActiveUsers.length > 0 && (
              <CurrentUsers
                allActiveUsers={allActiveUsers}
                setLastUpdated={setLastUpdated}
                isLoading={isLoading}
              />
            )}
          </Skeleton>
        </Stack>
      </Container>
    </Stack>
  );
}
