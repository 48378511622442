import {
  Box,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { PayItemDeductionApi } from "../../Api/PayItemDeduction";
import { PayItemEarningApi } from "../../Api/PayItemEarning";
import { PayItemReimbursementApi } from "../../Api/PayItemReinbursement";
import { PayItemRetirement } from "../../Api/PayItemRetirement";
import { PayTemp } from "../../Api/PayTemp";

import { useParams } from "react-router";
import { CustomInput } from "../../../Common/CustomInput";
import { Deduction } from "../Employees/components/deduction";
import { Earning } from "../Employees/components/earning";
import { Reimbursement } from "../Employees/components/reimbursement";
import { Retirement } from "../Employees/components/retirement";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";

export default function PayHistory() {
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const form = useForm<PayTemp.AddNewPayTemp>({
    defaultValues: {
      grossSalary: 0,
      earnings: [],
      deductions: [],
      reimbursments: [],
      retirements: [],
      deletedIds: [],
    },
  });

  const [accountEarning, setAccountEarning] = useState<
    PayItemEarningApi.EarningById[]
  >([]);

  const [accountDeduction, setAccountDeduction] = useState<
    PayItemDeductionApi.DeductionById[]
  >([]);

  const [accountReimbursement, setAccountReimbursement] = useState<
    PayItemReimbursementApi.ReimbursementById[]
  >([]);
  const [accountRetirement, setAccountRetirement] = useState<
    Array<PayItemRetirement.FetchRetirementFund>
  >([]);

  const earningFieldArray = useFieldArray({
    control: form.control,
    name: "earnings",
    keyName: "uniqueId",
  });
  const deductionFieldArray = useFieldArray({
    control: form.control,
    name: "deductions",
    keyName: "uniqueId",
  });
  const reimbursementFieldArray = useFieldArray({
    control: form.control,
    name: "reimbursments",
    keyName: "uniqueId",
  });
  const retirementFieldArray = useFieldArray({
    control: form.control,
    name: "retirements",
    keyName: "uniqueId",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (id) {
      PayTemp.GetPayTemp(
        id,
        (data) => {
          form.reset(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Compensation not found",
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Compensation not found",
        status: "error",
      });
    }
  }, []);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        justifyContent="center"
        alignItems="center"
        textAlign={"left"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Heading
          textAlign="left"
          fontSize=".9975rem"
          _light={{
            color: "customColor.black7",
          }}
          fontWeight="bold"
          padding="20px"
          as="h2"
          size="md"
        >
          Compensation
        </Heading>

        <Box
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginTop="20px"
          px="10px"
        >
          <Stack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.gray.950",
            }}
            border={"1px solid #e5e5e5"}
            padding={"15px 10px 20px"}
            gap={0}
          >
            <Stack direction="row" alignItems={"center"}>
              <Stack flex={1}>
                <Text
                  fontSize={"12px "}
                  margin={"0px 0px 4px"}
                  color={"customColor.gray.800"}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"700"}
                >
                  Gross Salary
                </Text>
                <Text
                  color={"primary.950"}
                  padding={"6px 0px 0px"}
                  fontSize={"12px"}
                  borderTop={"1px solid #CCC !important"}
                  borderBottom={"1px solid #E8EAEC !important"}
                  marginBottom={"5px"}
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  Gross Salary
                </Text>
              </Stack>
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Gross Salary is required",
                      },
                    }}
                    defaultValue={0}
                    control={form.control}
                    name={`grossSalary`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          defaultValue: 0,
                          bg: "#fff",
                          variant: "outline",
                          isDisabled: true,
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "0px 0px 0px 3px",
                          boxShadow: "none",
                          placeholder: "",
                          _hover: {
                            borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                          },
                        }}
                        label="Fixed Amount"
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing="20px">
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                earningFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                earningFieldArray.fields.length > 0 ? "15px 10px 20px" : "none"
              }
              gap={0}
              marginTop={"20px"}
            >
              <Earning
                form={form}
                earningFieldArray={earningFieldArray}
                accountEarning={accountEarning}
                setAccountEarning={setAccountEarning}
                currentEmployeeId={id}
              />
            </Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                deductionFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                deductionFieldArray.fields.length > 0
                  ? "15px 10px 20px"
                  : "none"
              }
            >
              <Deduction
                form={form}
                deductionFieldArray={deductionFieldArray}
                accountDeduction={accountDeduction}
                currentEmployeeId={id}
                setAccountDeduction={setAccountDeduction}
              />
            </Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                reimbursementFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                reimbursementFieldArray.fields.length > 0
                  ? "15px 10px 20px"
                  : "none"
              }
            >
              <Reimbursement
                form={form}
                reimbursementFieldArray={reimbursementFieldArray}
                accountReimbursement={accountReimbursement}
                currentEmployeeId={id}
                setAccountReimbursement={setAccountReimbursement}
              />
            </Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                retirementFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                retirementFieldArray.fields.length > 0
                  ? "15px 10px 20px"
                  : "none"
              }
            >
              <Retirement
                form={form}
                retirementFieldArray={retirementFieldArray}
                accountRetirement={accountRetirement}
                currentEmployeeId={id}
                setAccountRetirement={setAccountRetirement}
              />
            </Stack>
          </Stack>
        </Box>
        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Box>
    </Skeleton>
  );
}
