import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EmployeeLeaveEntitlement } from "../../Api/EmployeeLeaveEntitlement";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { EmployeeLeaveModule } from "../../Api/EmployeeLeaveModule";

import { useParams } from "react-router";
import LeaveEligibilityBalance from "../EmployeeDetails/Tabs/Leaves/LeaveEligibilityBalance";
import LeaveTable from "../EmployeeDetails/Tabs/Leaves/LeaveTable";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import LeaveForEmployee from "./LeaveForEmployee";

interface LeaveProps {
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  lastUpdated: number;
}
export function LeaveParent({ lastUpdated, setLastUpdated }: LeaveProps) {
  const leave = useDisclosure();
  const currentEmployeeId = useParams<{ id: string }>().id;
  // const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const [selectedLeaveId, setSelectedLeaveId] = useState<string>();

  const [fetchLeaves, setFetchLeaves] = useState<
    Array<PayItemLeaveApi.LeaveById>
  >([]);

  const [FetchLeavesEligibility, setFetchLeavesEligibility] = useState<
    Array<EmployeeLeaveEntitlement.LeaveEntitlement>
  >([]);
  const [FetchAllLeaves, setFetchAllLeaves] = useState<
    Array<EmployeeLeaveModule.Leave>
  >([]);
  // useEffect(() => {
  //   PayItemLeaveApi.FetchLeave(
  //     (data) => {
  //       setFetchLeaves(data);
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //         status: "error",
  //       });
  //     }
  //   );
  // }, []);
  // useEffect(() => {
  //   EmployeeLeaveEntitlement.FetchLeaveEntitlements(
  //     currentEmployeeId!,
  //     (data) => {
  //       setFetchLeavesEligibility(data);
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //         status: "error",
  //       });
  //     }
  //   );
  //   EmployeeLeaveModule.FetchLeave(
  //     currentEmployeeId!,
  //     (data) => {
  //       setFetchAllLeaves(data);
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //         status: "error",
  //       });
  //     }
  //   );
  // }, []);
  const toast = useToast();

  const LeaveEligibilityForm =
    useForm<EmployeeLeaveEntitlement.LeaveEntitlement>({
      defaultValues: {
        empId: "",
        payrollPayItemLeaveId: "",
        calculationMethod: "",
        quantity: 0,
        quantityPerPayPeriod: 0,
        balance: 0,
        terminationUnusedBalance: "",
        etpLeaveType: false,
        includeRetirementContribution: false,
        accruedQuantity: 0,
      },
    });

  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (data) => {
        setFetchLeaves(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  useEffect(() => {
    EmployeeLeaveEntitlement.FetchLeaveEntitlements(
      currentEmployeeId!,
      (data) => {
        setFetchLeavesEligibility(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    EmployeeLeaveModule.FetchLeave(
      currentEmployeeId!,
      (data) => {
        setFetchAllLeaves(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);

  const form = useForm<EmployeeLeaveModule.NewLeave>({
    defaultValues: {
      description: "",
      endDate: "",
      quantity: undefined,
      leaveCategory: "NORMAL_LEAVE",
      notes: "",
      payoutDate: "",
      startDate: "",
      payrollPayItemLeaveId: "",
    },
  });

  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
    >
      <Box justifyContent="center" alignItems="center" p={0}>
        <HStack borderBottom={"1px solid #ddd"} paddingRight={"10px"}>
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px"
            as="h2"
            size="md"
            display={"flex"}
            flex={1}
            _light={{
              color: "customColor.black7",
            }}
          >
            Available Leave Balances
          </Heading>

          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              form.reset({
                description: undefined,
                endDate: undefined,
                quantity: undefined,
                leaveCategory: "NORMAL_LEAVE",
                notes: undefined,
                payoutDate: undefined,
                startDate: undefined,
                payrollPayItemLeaveId: undefined,
              });
              setSelectedLeaveId(undefined);

              leave.onOpen();
            }}
          >
            New Leave Request <ChevronDownIcon />
          </Button>
        </HStack>
        <FormProvider {...LeaveEligibilityForm}>
          <LeaveEligibilityBalance
            LeavesEligibilityBalance={FetchLeavesEligibility}
            payItemLeaves={fetchLeaves}
            empId={currentEmployeeId!}
            setLastUpdated={setLastUpdated}
          />
        </FormProvider>
      </Box>
      {/* <LeaveUsed /> */}
      <FormProvider {...form}>
        <Box justifyContent="center" alignItems="center" borderRadius="md">
          <HStack borderBottom={"1px solid #ddd"} paddingRight={"10px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              _light={{
                color: "customColor.black7",
              }}
              as="h2"
              size="md"
              display={"flex"}
              flex={1}
            >
              Leave Requests
            </Heading>
          </HStack>
          <LeaveTable
            AllLeaves={FetchAllLeaves}
            fetchLeaves={fetchLeaves}
            empId={currentEmployeeId!}
            setLastUpdated={setLastUpdated}
            lastUpdated={lastUpdated}
            FetchLeavesEligibility={FetchLeavesEligibility}
          />
        </Box>
      </FormProvider>
      <Stack
        marginX={"auto"}
        borderTop={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginRight={"auto"}
        marginLeft={"auto"}
        w="100%"
        marginTop={"20px"}
      >
        <HistoryAccordion />
      </Stack>

      <FormProvider {...form}>
        <LeaveForEmployee
          isOpen={leave.isOpen}
          onClose={leave.onClose}
          setLastUpdated={setLastUpdated}
          FecthedLeaves={fetchLeaves}
          employeeId={currentEmployeeId!}
          lastUpdated={lastUpdated}
          FetchLeavesEligibility={FetchLeavesEligibility}
        />
      </FormProvider>
    </Stack>
  );
}
