import { axiosInstance } from "./axiosInstance-hris";
declare module DependentsApi {
  type AddDependents = {
    id?: string;
    name: string;
    dateOfBirth: string;
    gender: string;
    cnic: string;
    relationship: string;
    martialStatus: string;
    empId?: string;
  };
  type FetchDependents = {
    id: string;
    name: string;
    dateOfBirth: string;
    gender: string;
    cnic: string;
    relationship: string;
    martialStatus: string;
    empId: string;
  };
}
module DependentsApi {
  const URL = "/employee-dependents";
  export function PostDependents(
    data: AddDependents,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetDependentsDetails(
    id: string,
    success: (response: Array<FetchDependents>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateDependents(
    id: string,
    data: Partial<AddDependents>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteDependents(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}

export { DependentsApi };
