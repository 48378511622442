import { axiosInstance } from "./axiosInstance-hris";

declare module NotificationGroup {
  type NotificationGroup = {
    id?: string;
    name: string;
    employeeIds: Array<string>;
  };
}
module NotificationGroup {
  const API_URL = "/notification-user-groups";
  export function CreateNotificationGroup(
    data: NotificationGroup,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateNotificationGroup(
    id: string,
    data: Partial<NotificationGroup>,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchNotificationGroups(
    params: {
      page?: number;
      limit?: number;
      search?: string;
    },
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchNotificationGroupById(
    id: string,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteNotificationGroup(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { NotificationGroup };
