import { InfoIcon } from "@chakra-ui/icons";

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Country, State } from "country-state-city";
import { useEffect, useRef, useState } from "react";
import { AddressInput } from "../../../Common/AddressInput";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { PayrollOrganisation } from "../../Api/PayrollOrganisation";
import { UploadAttachments } from "../../Api/UploadAttachments";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import { loadGoogleMapsScript } from "../TimeSheet/utils";
interface OrgTabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
const libraries = ["places"];
export default function OrgTab({ setSelectedTab, selectedTab }: OrgTabProps) {
  const toast = useToast();
  const addressRegex = /^(?!\d+$)[A-Za-z0-9\s#,\-]+$/;
  const form = useForm<PayrollOrganisation.Organisation & { id: string }>({
    defaultValues: {
      liabilityAccount: "",
      wagesExpenseAccount: "",
      wagesPayableAccount: "",
      retirementLiabilityAccount: "",
      retirementExpenseAccount: "",
      employeeGroup: undefined,
      timesheetCategory: undefined,
      showAnnualSalary: false,
      showEmployementBasis: false,
      companyLogo: "",
      address: "",
      province: "",
      country: "",
      city: "",
      postalCode: "",
      email: "",
      mobileNumber: "",
      taxNumber: "",
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedLogo, setUploadLogo] = useState<number>(Date.now());
  const [isHovering, setIsHovering] = useState(false);
  const [logoLoading, setLogoLoading] = useState<boolean>(false);

  let countryData = Country.getAllCountries();
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find((country) => country.name === form.getValues("country"))
        ?.isoCode
    );
    const province = provinceOptions.map((province) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, [form.watch("country")]);

  const [liability, setLiability] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [wagePayable, setWagePayable] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [superannuationLiability, setSuperannuationLiability] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [wageExpense, setWageExpense] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [superannuationExpense, setSuperannuationExpense] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  useEffect(() => {
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setLiability(res);
        setWagePayable(res);
        setSuperannuationLiability(res);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      },
      "LIABILITY"
    );
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setWageExpense(res);
        setSuperannuationExpense(res);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      },
      "EXPENSES"
    );
    PayrollOrganisation.FetchOrganisation(
      (data) => {
        form.reset(data);
      },
      (error) => {
        toast({
          title: error,
          status: "error",
        });
      }
    );
  }, []);
  useEffect(() => {
    loadGoogleMapsScript(() => {
      // The callback will be executed when the Google Maps API has been loaded
    });
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentBusinessId } = useBusinessContext();
  return (
    <Box
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      marginTop={"15px"}
      marginBottom={"1.25rem"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Stack>
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          // maxW="940px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px"
            as="h2"
            _light={{
              color: "customColor.black7",
            }}
            size="md"
          >
            Account setup
          </Heading>
          <Divider orientation="horizontal" />
        </Box>
        <Box
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <HStack padding="15px" minHeight="100px" align="baseline">
            {/* <Controller
              name="bankaccount"
              control={control}
              render={({ field }) => ( */}
            <Tooltip
              // label="Its bank account setup in it be automatically included and cannot be edited."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
            >
              <FormControl>
                <FormLabel
                  mb={0}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight="600"
                  fontSize="12px"
                >
                  Bank Account <Icon color="#999" as={InfoIcon} />
                </FormLabel>
                <Input
                  // {...field}
                  mt={0}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight="bold"
                  fontSize="12px"
                  h="30px"
                  isDisabled
                  bg="#eee"
                  type="text"
                  placeholder="No Bank Account setup"
                />
              </FormControl>
            </Tooltip>
            {/* )}
            /> */}
            <Tooltip
              label="A PAYG (Pay As You Go) Liability Account is where businesses track taxes withheld from employee wages before remitting them to the tax authority."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
            >
              <FormControl>
                <FormLabel
                  mb={0}
                  fontWeight="600"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  PAYG Liability Account
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "PAYG Liability Account type is required",
                    },
                  }}
                  control={form.control}
                  name="liabilityAccount"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      defaultValue={"804: Wages Payable - Payroll"}
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select PAYG Liability Account type"
                      withValidation
                      options={liability}
                      value={{
                        id: field.value,
                        name:
                          liability.find(
                            (liability) => liability.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
            <Tooltip
              label="
A Wages Expense Account tracks the total amount of money paid to employees for their work."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
            >
              <FormControl>
                <FormLabel
                  mb={0}
                  fontWeight="600"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Wages Expense Account
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Wages Expense Account type is required",
                    },
                  }}
                  control={form.control}
                  name="wagesExpenseAccount"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      defaultValue={"804: Wages Payable - Payroll"}
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Wages Expense Account type"
                      withValidation
                      options={wageExpense}
                      value={{
                        id: field.value,
                        name:
                          wageExpense.find(
                            (wageExpense) => wageExpense.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
          </HStack>
          <HStack padding="15px" mx="auto" align="baseline">
            <Tooltip
              label="A Wages Payable Account records the amount of wages owed to employees but not yet paid out by the company."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
            >
              <FormControl>
                <FormLabel
                  mb={0}
                  fontWeight="600"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Wages Payable Account
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "  Wages Payable Account type is required",
                    },
                  }}
                  control={form.control}
                  name="wagesPayableAccount"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      defaultValue={"804: Wages Payable - Payroll"}
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select   Wages Payable Account type"
                      withValidation
                      options={wagePayable}
                      value={{
                        id: field.value,
                        name:
                          wagePayable.find(
                            (wagePayable) => wagePayable.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
            <Tooltip
              label="
A Statutory Benefits Liability Account represents the amount of funds set aside by a company to cover statutory benefits owed to employees, such as vacation pay or sick leave."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
            >
              <FormControl>
                <FormLabel
                  mb={0}
                  fontWeight="600"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Statutory Benefits liability Account
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message:
                        "Statutory Benefits liability Account type is required",
                    },
                  }}
                  control={form.control}
                  name="retirementLiabilityAccount"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      defaultValue={"804: Wages Payable - Payroll"}
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Statutory Benefits liability Account type"
                      withValidation
                      options={superannuationLiability}
                      value={{
                        id: field.value,
                        name:
                          superannuationLiability.find(
                            (superannuationLiability) =>
                              superannuationLiability.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
            <Tooltip
              label="
A Statutory Benefits Expense Account records the company's expenditures related to statutory benefits provided to employees, such as healthcare contributions or retirement plans.."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
            >
              <FormControl>
                <FormLabel
                  mb={0}
                  fontWeight="600"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Statutory Benefits Expense Account
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Statutory Benefits Expense Account is required",
                    },
                  }}
                  control={form.control}
                  name="retirementExpenseAccount"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      defaultValue={"804: Wages Payable - Payroll"}
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Statutory Benefits Expense Account type"
                      withValidation
                      options={superannuationExpense}
                      value={{
                        id: field.value,
                        name:
                          superannuationExpense.find(
                            (superannuationExpense) =>
                              superannuationExpense.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
          </HStack>
          {/* <Heading
            fontSize="14px"
            mt="20px"
            fontWeight="bold"
            _light={{
            color: "customColor.black7",
          }}
            _dark={{ color: "customColor.white" }}
            padding="1%"
          >
            Payroll Tracking
          </Heading>
          <Divider orientation="horizontal" />
          <HStack maxWidth="625px" padding="2%" minHeight="100px">
            <FormControl>
              
                <FormLabel
                  mb={0}
                  fontWeight="bold"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                Employee Groups (optional)
              </FormLabel>
              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Employee Groups is required",
                //   },
                // }}
                control={form.control}
                name="employeeGroup"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select"
                    // defaultValue={"804: Wages Payable - Payroll"}
                    // marginBottom="30px"
                    // width={"48em"}
                    // isInvalid={fieldState.invalid}
                    // ErrorMessage="Select Employee Groups"
                    // withValidation
                    options={employeeGroup}
                    value={employeeGroup.find(
                      (employeeGroup) => employeeGroup.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              
                <FormLabel
                  mb={0}
                  fontWeight="bold"
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                Timesheet Category (optional)
              </FormLabel>
              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Timesheet Category type is required",
                //   },
                // }}
                control={form.control}
                name="timesheetCategory"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select"
                    // defaultValue={"804: Wages Payable - Payroll"}
                    // marginBottom="30px"
                    // width={"48em"}
                    // isInvalid={fieldState.invalid}
                    // ErrorMessage="Select Timesheet Category type"
                    // withValidation
                    options={timesheet}
                    value={timesheet.find(
                      (timesheet) => timesheet.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </FormControl>
          </HStack>
          <Heading
            fontSize="14px"
            marginTop="20px"
            fontWeight="bold"
            _light={{
            color: "customColor.black7",
          }}
            _dark={{ color: "customColor.white" }}
            padding="1%"
          >
            Payslip Options
          </Heading>
          <Divider orientation="horizontal" />
          <HStack padding="2%" mx="auto">
            <Controller
              control={form.control}
              name="showAnnualSalary"
              render={({ field }) => (
                <Checkbox
                  isChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                >
                  <Text fontSize={"12px"} fontWeight={"700"}>
                    Show Annual Salary
                  </Text>
                </Checkbox>
              )}
            />
            <Controller
              control={form.control}
              name="showEmployementBasis"
              render={({ field }) => (
                <Checkbox
                  isChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                >
                  <Text fontSize={"12px"} fontWeight={"700"}>
                    Show Employement Basis
                  </Text>
                </Checkbox>
              )}
            />
          </HStack>
          */}
          <Heading
            fontSize="14px"
            mt="20px"
            fontWeight="bold"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            padding="1%"
          >
            Company Logo
          </Heading>
          <Divider orientation="horizontal" />
          <Text
            p="2%"
            fontSize="12px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            fontWeight="bold"
          >
            Your company logo will be used on things like payslips.
          </Text>
          <Flex flexDirection="column" maxW={"150px"} alignItems={"center"}>
            <Stack position="relative" mb={"10px"}>
              <Avatar
                key={uploadedLogo}
                size={"xl"}
                h={"80px"}
                w={"80px"}
                objectFit="fill"
                src={`${form.watch("companyLogo")}?updated=${uploadedLogo}`}
                // Other props
                borderWidth={"2px"}
                borderColor={"gray.200"}
              />
              {logoLoading && (
                <Center
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  backgroundColor="rgba(255, 255, 255, 0.8)" // Optional: semi-transparent overlay
                >
                  <Spinner size="lg" />
                </Center>
              )}
            </Stack>

            <Button
              marginLeft={"15px"}
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
            >
              {logoLoading
                ? "Uploading..."
                : form.getValues("companyLogo")
                ? "Change Logo"
                : "Upload Logo"}
            </Button>
            <Button
              marginLeft={"15px"}
              mt={"10px"}
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                form.setValue("companyLogo", "");
                setUploadLogo(Date.now());
              }}
            >
              Remove Logo
            </Button>
          </Flex>
          <input
            type="file"
            ref={inputRef}
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                const formData = new FormData();
                formData.append("docType", "PAYSLIP_LOGO");
                formData.append("file", file);
                formData.append("businessId", currentBusinessId!);

                setLogoLoading(true);
                UploadAttachments.UploadAttachment(
                  formData,
                  (success) => {
                    form.setValue("companyLogo", success.url);
                    setUploadLogo(Date.now());
                    setLogoLoading(false);
                  },
                  (err) => {
                    toast({
                      title: "Error uploading photo",
                      status: "error",
                      description: err,
                    });
                    setLogoLoading(false);
                  }
                );
              }
              e.target.value = "";
            }}
            style={{ display: "none" }}
          />

          <Heading
            fontSize="14px"
            mt="20px"
            fontWeight="bold"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            padding="1%"
          >
            Contact Information
          </Heading>
          <Divider orientation="horizontal" />
          <HStack alignItems={"baseline"} padding={"15px"}>
            <Stack flex={1}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Mobile Phone is required",
                  },
                }}
                control={form.control}
                name="mobileNumber"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "number",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Mobile number"
                    labelProps={{
                      fontSize: ".845rem",

                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <Controller
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Tax number is required",
                //   },
                // }}
                control={form.control}
                name="taxNumber"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "number",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Tax Number (Optional)"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                }}
                control={form.control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "email",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Email"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </HStack>
          <Stack padding={"15px"}>
            <Stack gap={0}>
              <Text
                color={"customColor.black7"}
                fontSize={".845rem"}
                fontWeight={"600"}
              >
                Search address
              </Text>

              <AddressInput
                onPlaceSelected={(place) => {
                  const addressParts = place.formatted_address.split(",");
                  const addressUpToThirdComma = addressParts
                    .slice(0, 3)
                    .join(",");
                  form.setValue("address", addressUpToThirdComma);
                  form.setValue("country", place?.country);
                  form.setValue("city", place?.city);
                  form.setValue("province", place?.state);
                  form.setValue("postalCode", place?.postcode);
                }}
                input={{
                  _light: {
                    color: "customColor.black7",
                  },
                  border: "1px solid #e5e7eb",
                  _hover: {
                    border: "1px solid hsl(0, 0%, 70%)",
                  },
                  borderTopRadius: "3px",
                  _focus: {
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  },
                  _focusVisible: {
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  },
                  fontSize: ".845rem",
                  placeholder: "Search address",
                  padding: "12px 15px",
                  variant: "outline",
                  marginTop: "10px",
                  type: "text",
                }}
              />
            </Stack>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Address is required",
                },
                // validate: {
                //   notOnlyNumbers: (value) =>
                //     addressRegex.test(value) ||
                //     "Address shouldn't only contain numbers or special characters like % $",
                // },
              }}
              control={form.control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    padding: "12px 15px",
                    borderTopRadius: "3px",
                  }}
                  placeholder="Address option 1"
                  label="Address"
                  labelProps={{
                    fontSize: ".845rem",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <HStack alignItems={"baseline"} padding={"15px"}>
            <Stack flex={2} gap={0}>
              <Text
                fontSize="12px"
                fontWeight="600"
                _dark={{
                  color: "customColor.white",
                }}
                margin={"0px 0px 8px 0px"}
              >
                Country
              </Text>

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Country is required",
                  },
                }}
                control={form.control}
                name="country"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select Country"
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Country"
                    withValidation
                    options={countryData}
                    value={countryData.find((op) => op.name === field.value)}
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.name);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.name}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <FormControl>
                <FormLabel
                  fontSize="12px"
                  fontWeight="600"
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Province
                </FormLabel>
                <Controller
                  control={form.control}
                  name="province"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={provinces}
                      ErrorMessage="Please Select Province"
                      placeholder="Select..."
                      value={provinces?.find(
                        (op: any) => op.name === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.name);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack flex={2}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "City is required",
                  },
                }}
                control={form.control}
                name="city"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="City"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </Stack>

            <Stack flex={1}>
              <Controller
                control={form.control}
                name="postalCode"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "number",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Zip Code"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </HStack>
        </Box>
      </Stack>
      <Container
        justifyContent="center"
        alignItems="center"
        textAlign={"left"}
        p="30px 0px"
        maxW={"100%"}
      >
        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Container>

      <Box
        borderTop="1px solid #ddd"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        position="sticky"
        left={0}
        bottom="0"
        w="full"
        h="50px"
      >
        <Container
          justifyContent="center"
          alignItems="flex-end"
          textAlign={"right"}
          maxW={"100%"}
        >
          <ButtonGroup size="sm" mt="10px">
            <Tooltip
              label="Save typically refers to preserving or storing data, documents, or resources in a secure location for future use."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="top"
            >
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setIsLoading(true);
                      if (data.id) {
                        PayrollOrganisation.UpdateOrganisation(
                          data,
                          () => {
                            toast({
                              title: "Organisation is updated Successfully",
                              status: "success",
                            });
                            setIsLoading(false);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      } else {
                        PayrollOrganisation.CreateOrganisation(
                          data,
                          () => {
                            toast({
                              title: "Organisation is created Successfully",
                              status: "success",
                            });
                            setIsLoading(false);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      }
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                Save
              </Button>
            </Tooltip>
            <Tooltip
              label="Save your progress and continue to the next step."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="top"
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setIsLoading(true);
                      if (data.id) {
                        PayrollOrganisation.UpdateOrganisation(
                          data,
                          () => {
                            toast({
                              title: "Organisation is updated Successfully",
                              status: "success",
                            });

                            setSelectedTab(selectedTab + 1);
                            setIsLoading(false);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      } else {
                        PayrollOrganisation.CreateOrganisation(
                          data,
                          () => {
                            toast({
                              title: "Organisation is created Successfully",
                              status: "success",
                            });
                            setIsLoading(false);

                            setSelectedTab(selectedTab + 1);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      }
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                {isLoading ? "Saving..." : " Save and Next"}
              </Button>
            </Tooltip>
            <Tooltip
              label="              
Closing refers to concluding a task, session, or operation, often by shutting down a program, ending a conversation, or completing a transaction."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="auto"
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => navigate("/app/payroll/PayRollEmployee")}
              >
                Close
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Container>
      </Box>
    </Box>
  );
}
