import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaSquarePlus } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { RiVideoFill } from "react-icons/ri";
import DefaultSettingModal from "./DefaultSettingModal";
import { InvoicesSettingTable } from "./InvoicesSettingsTable";
import NewBrandingModal from "./NewBrandingModal";
export function InvoicesSetting() {
  const defaultModal = useDisclosure();
  const newBrandingModal = useDisclosure();

  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        border={"1px solid #e5e7eb"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        gap={0}
        padding={"0px 0px 0px 20px"}
        justifyContent={"center"}
        minH={"60px"}
      >
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon fontSize={"11px"} color={"#3454d1"} />}
        >
          <BreadcrumbItem fontSize={"11px"} color={"#3454d1"}>
            <BreadcrumbLink href="#">organisation settings</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem fontSize={"11px"} color={"#3454d1"}>
            <BreadcrumbLink href="#"></BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading
          fontSize="24px"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{ color: "customColor.white" }}
          fontWeight="bold"
        >
          Invoices settings
        </Heading>
      </Stack>
      <Container
        justifyContent="center"
        maxW={"container.lg"}
        alignItems="center"
        textAlign={"left"}
        p="10px 20px"
      >
        <HStack justifyContent={"flex-end"}>
          <HStack gap={0}>
            <Box width={"100%"}>
              <Button
                leftIcon={<FaSquarePlus />}
                width={"100%"}
                borderRadius={"3px"}
                borderRightRadius={0}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"38px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
                onClick={() => {
                  newBrandingModal.onOpen();
                }}
              >
                New Branding Theme
              </Button>
            </Box>
            <Menu>
              <MenuButton
                as={IconButton}
                h={"38px"}
                _light={{
                  bgColor: "customColor.white",
                  color: "customColor.black7",
                }}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                fontWeight={"400"}
                borderTop={"1px solid #e5e7eb"}
                borderRight={"1px solid #e5e7eb"}
                borderBottom={"1px solid #e5e7eb"}
                borderRightRadius={"4px"}
                _hover={{
                  color: "customColor.black7",
                  bg: "#f2f3f4",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "customColor.black7",
                  bg: "#f2f3f4",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                icon={<IoIosArrowDown />}
              />

              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Standard
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Custom.docx
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>{" "}
          <Button
            fontSize={"13px"}
            leftIcon={<RiVideoFill size={"13px"} />}
            bgColor="#3454D1"
            h={"38px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              defaultModal.onOpen();
            }}
          >
            Default Theme
          </Button>
          <DefaultSettingModal
            isOpen={defaultModal.isOpen}
            onClose={defaultModal.onClose}
          />
          <NewBrandingModal
            isOpen={newBrandingModal.isOpen}
            onClose={newBrandingModal.onClose}
          />
        </HStack>
        <InvoicesSettingTable />
      </Container>
    </Stack>
  );
}
