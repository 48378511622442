import {
  Box,
  Button,
  Container,
  HStack,
  Input,
  InputGroup,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { PaginationControl } from "../../../PayrollEmployee/PaginationControl/PaginationControl";
import { Restore } from "../Modal/Restore";

interface HeaderForAchiveProps {
  numberOfCheckedRows: number;
  selectedIds: string[];
  setLastUpdated: (timestamp: number) => void;
  searchQuery: string;
  debounced: (e: any) => void;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageLimit: number;
  setPageLimit: React.Dispatch<React.SetStateAction<number>>;
}
export function HeaderForAchive({
  numberOfCheckedRows,
  selectedIds,
  setLastUpdated,
  searchQuery,
  debounced,
  pageLimit,
  setPageNumber,
  setPageLimit,
}: HeaderForAchiveProps) {
  const buttonSize = useBreakpointValue({ base: "sm", sm: "md" }); // Adjust button size based on screen size
  const restoreModal = useDisclosure();
  return (
    <Container
      justifyContent="center"
      alignItems="center"
      maxW={"100%"}
      p={"10px 25px"}
      margin={0}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Stack direction={"row"} gap={0}>
        <Stack
          direction={["column", "row"]}
          alignItems="center"
          padding={["2px", "2px 10px 2px"]}
          gap={0}
          flex={1}
        >
          <Button
            color={"#999"}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            cursor={"pointer"}
            size={buttonSize}
            _hover={{
              backgroundColor: "#fff",
              color: "#3454D1",
            }}
            onClick={() => restoreModal.onOpen()}
          >
            Restore
          </Button>
          <Restore
            isOpen={restoreModal.isOpen}
            onClose={restoreModal.onClose}
            setLastUpdated={setLastUpdated}
            status={false}
            selectedIds={selectedIds}
          />
        </Stack>
        <Text color={"#868686"} fontSize={"11px"}>
          {selectedIds.length} account selected
        </Text>
      </Stack>
      <Stack
        justifyContent={"space-between"}
        alignItems="center"
        direction={"row"}
      >
        <Box w={"100%"}>
          <PaginationControl
            limit={pageLimit}
            setLimit={setPageLimit}
            setPageNumber={setPageNumber}
          />
        </Box>
        <Stack direction={["row"]} alignItems="center" gap={0}>
          <HStack height={"60px"}>
            <Text
              fontSize={"13.44px"}
              _light={{
                color: "customColor.gray.6b7885",
              }}
            >
              Search:
            </Text>
            <InputGroup ml={[0, "7px"]}>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
                onChange={(e) => {
                  debounced(e.target.value);
                }}
              />
            </InputGroup>
          </HStack>
        </Stack>
      </Stack>
    </Container>
  );
}
