import { axiosInstance } from "./axiosInstance-hris";
declare module MedicalBenefitsApi {
  type BenefitsData = {
    id: string;
    businessInsuranceId?: string | null;
  };
}
module MedicalBenefitsApi {
  const API_URL = "/employee-medical-benefits";

  export function CreateMedicalBenefits(
    empId: string | undefined,
    data: BenefitsData,
    success: (data: BenefitsData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchMedicalBenefits(
    empId: string | undefined,
    success: (data: BenefitsData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { MedicalBenefitsApi };
