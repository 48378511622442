import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Current from "./Current";
import BulkModal from "./Modals/BulkUpload";
import Past from "./Past";
export function PayrollEmployee() {
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const navBarButtons: Array<{
    btnLabel: string;
    menuItems: Array<{
      label: string;
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    }>;
  }> = [
    {
      btnLabel: "New employee",
      menuItems: [
        {
          label: "Add employee",
          onClick: () => navigate("/app/payroll/AddEmployee"),
        },
        { label: "Bulk upload", onClick: () => onOpen() },
      ],
    },
  ];
  const [lastUpdatedEmployees, setLastUpdatedEmployees] = useState<number>(
    Date.now()
  );
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };
  // useEffect(() => {
  //   navigate("/app/payroll/PayrollEmployee/?page=1&limit=25");
  // }, [activeTab]);
  return (
    <Stack h="calc(100vh - 70px)" overflowY="auto">
      <Tabs w="100%" isLazy index={activeTab} onChange={handleTabChange}>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          justifyContent="space-between"
          h={{ xs: "120px", md: "60px" }}
          borderBottom={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <HStack>
            <Text
              fontWeight={"bold"}
              _dark={{
                color: "customColor.white",
              }}
              _light={{
                color: "customColor.black7",
              }}
              padding={"16px 0px"}
              fontSize={"17px"}
              margin={"0px 4px 0px 20px"}
            >
              Employees
            </Text>
            <TabList padding={0}>
              <Tab fontSize={"15px"} h={"60px"}>
                Current
              </Tab>
              <Tab fontSize={"15px"} h={"60px"}>
                Past
              </Tab>
            </TabList>
          </HStack>
          <HStack paddingRight={"20px"}>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
                color: "#0073c0",
              }}
            >
              Earning Certificates
            </Button>
            {navBarButtons.map((navItems, i) => {
              return (
                <Menu key={i}>
                  <MenuButton
                    as={Button}
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    h={"40px"}
                    borderRadius={"3px"}
                    border={"primary.950"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    marginRight={"5px"}
                    textAlign={"center"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    _active={{
                      bgColor: "#3454D1",
                      borderWidth: "1px",
                      borderColor: "#3454d1!important",
                    }}
                  >
                    <HStack>
                      <Text>{navItems.btnLabel}</Text>
                      <Icon as={AiFillCaretDown} />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    {navItems.menuItems.map((items, k) => {
                      return (
                        <MenuItem onClick={items.onClick} key={k}>
                          <Text
                            fontWeight={500}
                            fontSize={"13.3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            {items.label}
                          </Text>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              );
            })}
          </HStack>
        </Stack>
        <TabPanels>
          <TabPanel padding={0}>
            <Current
              paddingValue={"30px"}
              tabKey={`tab-${activeTab}`}
              lastUpdatedEmployees={lastUpdatedEmployees}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <Past
              tabKey={`tab-${activeTab}`}
              paddingValue={"30px"}
              lastUpdatedEmployees={lastUpdatedEmployees}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <BulkModal
        isOpen={isOpen}
        onClose={onClose}
        setLastUpdatedEmployees={setLastUpdatedEmployees}
      />
    </Stack>
  );
}
