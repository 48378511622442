import {
  Avatar,
  Box,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { IoMdMail } from "react-icons/io";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export default function UpcomingBirthdays() {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();
  const form = useForm<Analytics.DashBoardData>();
  const toast = useToast();
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   overflowY={"auto"}
    //   boxShadow="0 4px 8px rgba(15,34,58,.35)"
    //   _hover={{
    //     boxShadow: "0 4px 8px rgba(15,34,58,.65)",
    //   }}
    //   // display={
    //   //   editDashboard ||
    //   //   form.getValues("hideOrShowTiles.upcomingBirthdaysData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    // >
    <FormProvider {...form}>
      <Box
        word-wrap="break-word"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        w="100%"
        h={editDashboard ? "calc(100% - 30px)" : "100%"}
        overflowY="auto"
        sx={{
          "::-webkit-scrollbar": {
            width: "0px", // Initially, the scrollbar is hidden
            background: "transparent",
          },
          ":hover::-webkit-scrollbar": {
            width: "8px", // Scrollbar visible on hover
          },
          ":active::-webkit-scrollbar": {
            width: "8px", // Scrollbar visible when active
          },
          ":focus::-webkit-scrollbar": {
            width: "8px", // Scrollbar visible on focus
          },
          // Add for Firefox and other browsers if needed
          scrollbarWidth: "none",
          ":hover": {
            scrollbarWidth: "thin",
          },
          ":active": {
            scrollbarWidth: "thin",
          },
          ":focus": {
            scrollbarWidth: "thin",
          },
        }}
      >
        <Box p={"25px"} borderBottom={"1px solid #e5e7eb"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.150",
            }}
            lineHeight="1.2"
            fontSize="16px"
            fontWeight={600}
          >
            Upcoming Birthday's
          </Text>
        </Box>
        <Box padding={"25px"}>
          {dashboardAnalytics?.birthdaysThisMonth?.map(
            (item: any, index: number) => (
              <Box key={index}>
                <HStack
                  border={"1px solid #f0f0f0"}
                  padding={"12px 15px"}
                  margin={"4px 0"}
                  boxSizing="border-box"
                  gap={0}
                  _hover={{
                    boxShadow:
                      "0 2px 5px rgba(0,0,0,.14), 0 2px 10px rgba(0,0,0,.1)",
                    transition:
                      "box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1)",
                  }}
                >
                  <Avatar
                    h={"50px"}
                    w={"50px"}
                    objectFit="fill"
                    // objectFit="cover"
                    // padding={"20px"}
                    name={item?.firstName + " " + item?.lastName}
                    src={item?.photo}
                    // Other props
                    borderWidth={"2px"}
                    borderColor={"gray.200"}
                  />

                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    flex={1}
                    justifyContent={"space-between"}
                  >
                    <Stack paddingLeft={"5px"}>
                      <Text>
                        <Text
                          fontWeight={"bold"}
                          fontSize={"14px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                        >
                          {item.firstName} {item.lastName}
                        </Text>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.gray.64748B",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                        >
                          {item.email}
                        </Text>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.gray.64748B",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                        >
                          {moment(item.dateOfBirth).format("Do MMMM YYYY")}
                        </Text>
                      </Text>
                    </Stack>
                    <Icon
                      as={IoMdMail}
                      _light={{
                        color: "customColor.gray.64748B",
                        _hover: {
                          color: "blue.500",
                        },
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      h={"25px"}
                      w={"30px"}
                      onClick={form.handleSubmit(
                        () => {
                          Analytics.Birthday(
                            item.id,
                            () => {
                              toast({
                                title: "Email Sent for Birthday",
                                description:
                                  "Please check your email, an email is sent to your email address to wish you happy birthday",
                                status: "success",
                              });
                            },
                            (error) => {
                              toast({
                                title: "Email Failed",
                                description: error,
                                status: "error",
                              });
                            }
                          );
                        },
                        (error) => {
                          toast({
                            title: "Error",

                            status: "error",
                          });
                        }
                      )}
                    />
                  </Stack>
                </HStack>
              </Box>
            )
          )}
        </Box>
      </Box>
    </FormProvider>
  );
}
