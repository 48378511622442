import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeeLeaveEntitlement {
  type LeaveEntitlement = {
    id?: string;
    // empId: string;
    // payrollPayItemLeaveId: string;
    // calculationMethod: string;
    // hours: number;
    // hoursPerPayPeriod: number;
    // balance: number;
    // terminationUnusedBalance: string;
    // etpLeaveType: boolean;
    // includeRetirementContribution: boolean;
    empId: string;
    payrollPayItemLeaveId: string;
    calculationMethod: string;
    quantity: number;
    quantityPerPayPeriod: number;
    balance: number;
    openingBalance: number;
    terminationUnusedBalance: string;
    etpLeaveType: boolean;
    includeRetirementContribution: boolean;
    accruedQuantity: number;
    payrollPayItemLeave: {
      name: string;
    };
  };
  type fetchEntitlementsHours = {
    id: string;
    balance: number;
  };
}
module EmployeeLeaveEntitlement {
  const API_URL = "/employee-leave-entitlements";
  export function PostLeaveEntitlement(
    data: LeaveEntitlement,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchLeaveEntitlements(
    empId: string,
    success: (data: Array<LeaveEntitlement>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchEntitlementsHours(
    empId: string,
    payrollPayItemLeaveId: string,
    success: (data: fetchEntitlementsHours) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/employee-entitlement/${empId}/${payrollPayItemLeaveId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function UpdateLeaveEntitlement(
    id: string,
    data: Partial<LeaveEntitlement>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteLeaveEntitlement(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { EmployeeLeaveEntitlement };
