import { axiosInstanceTime } from "./axiosInstanceTime";

declare module ShiftsOperations {
  type StartShiftData = {
    id: string;
    shiftStartTime: string;
    shiftStartDate: string;
    shiftStartCoordinates: {
      latitude: string;
      longitude: string;
    };
  };
  type StartEndData = {
    id: string;
    shiftEndTime: string;
    shiftEndDate: string;
    shiftEndCoordinates: {
      latitude: string;
      longitude: string;
    };
  };
  type ShiftCheckInData = {
    coordinates: {
      latitude: string;
      longitude: string;
    };
    checkInTime: string;
    areaId: string;
    shiftRecordId: string;
  };
  type ShiftCheckOutData = {
    coordinates: {
      latitude: string;
      longitude: string;
    };
    checkOutTime: string;
    areaId: string;
    shiftRecordId: string;
  };
}
module ShiftsOperations {
  const API_URL = "/employee-shifts-record";
  export function StartShift(
    data: StartShiftData,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/shift/start`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function EndShift(
    data: StartEndData,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/shift/end`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function ShiftCheckIn(
    data: ShiftCheckInData,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/shift/checkin`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function ShiftCheckOut(
    id: string,
    data: ShiftCheckOutData,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/shift/checkout/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
}
export { ShiftsOperations };
