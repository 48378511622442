import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { StatutoryBenefitsApi } from "../../Api/StautoryBenefitsApi";

export function Superannuation() {
  const navigate = useNavigate();
  const toast = useToast();
  const [statutoryBenefits, setStatutoryBenefits] = useState<
    Array<StatutoryBenefitsApi.FetchPayments>
  >([]);
  useEffect(() => {
    StatutoryBenefitsApi.GetPayments(
      (data) => {
        setStatutoryBenefits(data);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Stack
      gap={0}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #dee0e2"}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        padding={"16px 0px"}
        h={"60px"}
      >
        <Heading
          margin={"0px 4px 0px 20px"}
          fontSize={"18px"}
          _light={{
            color: "customColor.black7",
          }}
          alignItems={"center"}
          _dark={{ color: "customColor.white" }}
          fontWeight="bold"
        >
          Auto Statuary Benefits Payments
        </Heading>
      </Stack>
      <Container
        justifyContent="center"
        alignItems="center"
        p="20px"
        maxW={"container.xl"}
        borderRadius="md"
      >
        <Stack alignItems={"flex-end"}>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            marginBottom={"15px"}
            marginRight={"10px"}
            borderRadius={"3px"}
            leftIcon={<FaPlus />}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              navigate("/app/payroll/SuperannuationPaymentDetails", {
                state: {
                  status:
                    StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PENDING,
                },
              });
            }}
          >
            {" "}
            Statutory Benefits
          </Button>
        </Stack>
        <Stack
          borderRadius="4px"
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px"
          borderColor="#f6f6f6"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          gap={0}
        >
          <Stack
            direction={"row"}
            borderBottom={"1px solid #e5e7eb"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"25px"}
          >
            <Text
              fontSize={"17px"}
              color={"customColor.black7"}
              fontWeight={"bold"}
            >
              Payments Table
            </Text>
            <Text fontSize="12px" fontWeight={600} color="#3454d1">
              Total Payments {statutoryBenefits?.length}
            </Text>
          </Stack>
          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Created",
                      value: "15%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Status",
                      value: "30%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Amount",
                      value: "35%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Approved",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Processed",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {statutoryBenefits &&
                  statutoryBenefits?.map((item, i) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(
                            `/app/payroll/SuperannuationPayment/${item?.id}`
                          );
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>
                            {moment(item.createdAt).format("DD MMM YYYY")}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>
                            {item.status ===
                            StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                              .APPROVED
                              ? "Approved"
                              : item.status ===
                                StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                                  .PENDING
                              ? "Pending"
                              : item.status ===
                                StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                                  .REJECTED
                              ? "Rejected"
                              : item.status ===
                                StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                                  .PROCESSED
                              ? "Processed"
                              : item.status ===
                                StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                                  .PENDING_APPROVAL
                              ? "Pending Approval"
                              : item.status ===
                                StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                                  .WITHDRAWN
                              ? "Withdrawn"
                              : item.status ===
                                StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                                  .PENDING_PROCESSING
                              ? "Pending Processing"
                              : ""}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{(item.amount ?? "0").toLocaleString()}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {item.status ===
                            StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                              .APPROVED && (
                            <Text>
                              {moment(item.approvalDate).format("DD MMM YYYY")}
                            </Text>
                          )}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {" "}
                          {item.status ===
                            StatutoryBenefitsApi.StatutoryBenefitStatusEnum
                              .PROCESSED && (
                            <Text>
                              {moment(item.processedDate).format("DD MMM YYYY")}
                            </Text>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
}
