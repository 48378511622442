import {
  Box,
  BoxProps,
  Input as CHInput,
  InputProps as CHInputProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
export interface InputProps {
  wrapper?: BoxProps;
  input?: CHInputProps;
  label?: string;
  withValidation?: boolean;
  validationMessage?: string;
  right?: any;
  placeholder?: string;
  labelProps?: FormLabelProps;
  children?: React.ReactNode;
  isInvalid?: boolean;
  left?: any;
}
export const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      children,
      input,
      isInvalid,
      label,
      labelProps,
      placeholder,
      right,
      validationMessage,
      withValidation,
      wrapper,
      left,
    },
    ref
  ) => {
    if (withValidation) {
      return (
        <FormControl isInvalid={input?.isInvalid} zIndex={1}>
          {label && (
            <FormLabel
              fontSize="20px"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              {...labelProps}
            >
              {label}
            </FormLabel>
          )}
          <InputGroup>
            {left && <InputLeftElement>{left}</InputLeftElement>}
            {children ? (
              children
            ) : (
              <CHInput
                h="40px"
                px="16px"
                py="7px"
                variant="flushed"
                borderRadius="0px"
                minW="40px"
                marginTop="0px"
                _dark={{
                  color: "customColor.white",
                }}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize=".845rem"
                border="1px solid #e5e7eb"
                borderBottomRadius="3px"
                placeholder={placeholder}
                _hover={{
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _focus={{
                  borderWidth: "1px",

                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                _focusVisible={{
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                {...input}
                ref={ref}
              />
            )}
            {right && <InputRightElement>{right}</InputRightElement>}
          </InputGroup>
          {input?.isInvalid && (
            <FormErrorMessage>{validationMessage}</FormErrorMessage>
          )}
        </FormControl>
      );
    } else {
      return (
        <Box {...wrapper}>
          {label && (
            <FormLabel
              fontSize="15px"
              fontWeight="bold"
              _dark={{
                color: "customColor.white",
              }}
              marginBottom="8px"
              {...labelProps}
            >
              {label}
            </FormLabel>
          )}
          {children ? (
            children
          ) : (
            <CHInput
              h="40px"
              px="16px"
              py="7px"
              variant="flushed"
              _dark={{
                color: "customColor.white",
              }}
              borderRadius="0px"
              placeholder={placeholder}
              _focus={{
                boxShadow: "0 0 0 3px rgba(0,10,30,.2)",
                borderColor: "#47C5E8",
              }}
              _focusVisible={{
                boxShadow: "50px",
                borderColor: "gray.500",
              }}
              _hover={{ border: " 0.0833333rem solid rgb(110, 116, 129)" }}
              {...input}
              ref={ref}
            />
          )}
        </Box>
      );
    }
  }
);
