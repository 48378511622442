import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function OrganisationSettings() {
  const navigate = useNavigate();
  const data1: Array<{
    title: string;
    desc: string;
    cursor?: string;
    onClick?: () => void;
  }> = [
    {
      title: "Business Setup",
      desc: "Address,logo and basic financial information",
      onClick: () => navigate("/app/payroll/OrganizationDetailParent"),
      cursor: "pointer",
    },
    {
      title: "Users",
      desc: "Add, remove or modify users of their organisation",
      onClick: () => navigate("/app/payroll/user"),
      cursor: "pointer",
    },

    {
      title: "Currencies",
      desc: "Manage the currencies your business uses",
      cursor: "not-allowed",
    },
    {
      title: "Mapping Window",
      desc: "A mapping window allows users to map or link different fields, such as names, roles, and departments, to corresponding database columns or attributes in a single interface.",
      onClick: () => navigate("/app/payroll/mappingWindow"),
      cursor: "pointer",
    },
    {
      title: " Connected Apps ",
      desc: "Add and manage third party connections to HRIS-360",
      cursor: "not-allowed",
    },
  ];
  const data2: Array<{
    title: string;
    desc: string;
    cursor?: string;
    onClick?: () => void;
  }> = [
    {
      title: "Invoice settings",
      desc: "Create branding theme for documents, add payment services and auto reminders for payments",
      // onClick: () => navigate("/app/payroll/invoiceSettings"),
      // cursor: "pointer",
      cursor: "not-allowed",
    },
    {
      title: "Payment services",
      desc: "Add and manage online payment options for your sales invoices",
      cursor: "not-allowed",
    },
    {
      title: "Email settings",
      desc: "Set a reply-to email address and email template content",
      cursor: "not-allowed",
    },
    {
      title: " Payroll settings ",
      desc: "Manage your payroll settings",
      onClick: () => navigate("/app/payroll/payrollSettings"),
      cursor: "pointer",
    },
    {
      title: "Expenses ",
      desc: "Manage your expense settings",
      cursor: "not-allowed",
    },
    {
      title: " HRIS-360 to HRIS-360 ",
      desc: "Connect with other HRIS-360 users to automate invoices and bills",
      cursor: "not-allowed",
    },
    {
      title: " Custom contact links ",
      desc: "Create shortcuts for your favourite HRIS-360 contacts and other tools",
      cursor: "not-allowed",
    },
  ];
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Stack
        borderBottom={"1px solid #d6dade"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        width={"100%"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container padding={0} maxW={"940px"}>
          <Stack h={"60px"}>
            <Heading
              fontSize="17px"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              paddingLeft={{ xs: "15px", md: "0" }}
              fontWeight="700"
              marginTop={"18px"}
            >
              Organisation settings
            </Heading>
          </Stack>
        </Container>
      </Stack>
      <Container maxW={"940px"} padding={"30px 0px 30px"}>
        <Center>
          <Stack
            spacing={6}
            direction={{ xs: "column", lg: "row" }}
            w={"100%"}
            paddingLeft={{ xs: "15px", md: "0" }}
            paddingRight={{ xs: "15px", md: "0" }}
          >
            <Accordion
              flex={1}
              w={"100%"}
              allowMultiple
              allowToggle
              defaultIndex={[0]}
              _dark={{
                bgColor: "customColor.dark.100",
              }}
            >
              <AccordionItem
                border={"1px solid #ccced2"}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  padding={"10px 5px"}
                >
                  <AccordionButton
                    w={"30px"}
                    padding={0}
                    h={"30px"}
                    borderRadius={"full"}
                    justifyContent={"center"}
                    _focus={{
                      boxShadow: "0 0 0 1px #fff, 0 0 0 4px rgba(0,10,30,.5)",
                      transition: "box-shadow .1s",
                      color: "#000a1e",
                    }}
                  >
                    <AccordionIcon />
                  </AccordionButton>
                  <Text
                    margin={"0px 0px 10px 0px"}
                    fontSize={"15px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                  >
                    General
                  </Text>
                </Stack>

                <AccordionPanel flex={1} w={"100%"}>
                  <Stack spacing={3}>
                    {data1.map((item, i) => {
                      return (
                        <Stack
                          key={i}
                          border={"1px dashed #ccced2"}
                          w={"100%"}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            _hover: { bgColor: "#f2f3f4" },
                          }}
                          p={"15px 20px"}
                          // _hover={{ boxShadow: "0 0 2px 2px lightgrey" }}
                          _hover={{
                            bgColor: "#f2f3f4",
                          }}
                          onClick={item.onClick}
                          cursor={item.cursor}
                          // w={{ lg: "510px", md: "510px", sm: "510px", base: "510px" }}
                        >
                          <Heading
                            fontSize={{ base: "sm", md: "15px" }}
                            color={"primary.950"}
                            fontWeight={"500"}
                          >
                            {item.title}
                          </Heading>
                          <Heading
                            fontSize={{ base: "xs", md: "13px" }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.200",
                            }}
                            fontWeight={"400"}
                          >
                            {item.desc}
                          </Heading>
                        </Stack>
                      );
                    })}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Accordion
              flex={1}
              w={"100%"}
              allowMultiple
              allowToggle
              defaultIndex={[0]}
              _dark={{
                bgColor: "customColor.dark.100",
              }}
            >
              <AccordionItem
                border={"1px solid #ccced2"}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  padding={"10px 5px"}
                >
                  <AccordionButton
                    w={"30px"}
                    padding={0}
                    h={"30px"}
                    borderRadius={"full"}
                    justifyContent={"center"}
                    _focus={{
                      boxShadow: "0 0 0 1px #fff, 0 0 0 4px rgba(0,10,30,.5)",
                      transition: "box-shadow .1s",
                      color: "#000a1e",
                    }}
                  >
                    <AccordionIcon />
                  </AccordionButton>
                  <Text
                    margin={"0px 0px 10px 0px"}
                    fontSize={"15px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                  >
                    Feature
                  </Text>
                </Stack>
                <AccordionPanel flex={1} w={"100%"}>
                  <Stack spacing={3}>
                    {data2.map((item, i) => {
                      return (
                        <Stack
                          key={i}
                          border={"1px dashed #ccced2"}
                          w={"100%"}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            _hover: { bgColor: "#f2f3f4" },
                          }}
                          p={"15px 20px"}
                          // _hover={{ boxShadow: "0 0 2px 2px lightgrey" }}
                          _hover={{
                            bgColor: "#f2f3f4",
                          }}
                          onClick={item.onClick}
                          cursor={item.cursor}
                          // w={{ lg: "510px", md: "510px", sm: "510px", base: "510px" }}
                        >
                          <Heading
                            fontSize={{ base: "sm", md: "15px" }}
                            color={"primary.950"}
                            fontWeight={"500"}
                          >
                            {item.title}
                          </Heading>
                          <Heading
                            fontSize={{ base: "xs", md: "13px" }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.200",
                            }}
                            fontWeight={"400"}
                          >
                            {item.desc}
                          </Heading>
                        </Stack>
                      );
                    })}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Center>
        <Center>
          <Button
            marginTop="20px"
            variant="ghost"
            color={"primary.950"}
            fontWeight="400"
            fontSize="13px"
            padding="11px 15px"
            _hover={{
              _light: {
                bgColor: "#f2f3f4",
              },
              _dark: {
                bgColor: "customColor.dark.50",
              },
            }}
            onClick={() => navigate("/app/payroll/advancedSettings")}
          >
            Looking for advanced settings?
          </Button>
        </Center>
      </Container>
    </Stack>
  );
}
