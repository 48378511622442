import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";

export function Employee() {
  const { employeesList } = useBusinessContext();
  const [selectedEmployeeStatus, setSelectedOptionEmployeeStatus] =
    useState("All");

  const handleOptionSelectEmployeeStatus = (option: any) => {
    setSelectedOptionEmployeeStatus(option);
  };
  const [selectedMenu, setSelectedOptionMenu] = useState("");

  const handleOptionSelectMenu = (option: any) => {
    setSelectedOptionMenu(option);
  };
  const employeeOptions = ["All", "Individual"];
  return (
    <Stack
      margin={"20px 0px 0px"}
      padding={"0px 0px 0px 10px"}
      direction={"row"}
      alignItems={"center"}
    >
      <Text
        fontSize="12px"
        display={"block"}
        fontWeight="700"
        _light={{
          color: "customColor.black2",
        }}
        _dark={{
          color: "customColor.white",
        }}
      >
        Employee:
      </Text>
      <HStack>
        <Box width={"100%"}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              width={"157px"}
              textAlign={"start"}
              h={"25px"}
              _light={{
                bgColor: "customColor.white",
              }}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              color={"black"}
              fontWeight={"400"}
              border={"1px solid #b7bec5"}
              borderRadius={"5px"}
              _hover={{
                color: "black",
                bg: "#f2f3f4",
                border: "1px solid #a6a9b0",
              }}
              _active={{
                color: "black",
                bg: "#f2f3f4",
                border: "1px solid #a6a9b0",
              }}
            >
              {selectedEmployeeStatus}
            </MenuButton>
            <MenuList overflowY="auto" zIndex={"overlay"}>
              {employeeOptions.map((item, i) => {
                return (
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                    onClick={() => handleOptionSelectEmployeeStatus(item)}
                  >
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black5",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      {item}
                    </Text>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
          {selectedEmployeeStatus === "Individual" && (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                width={"182px"}
                textAlign={"start"}
                h={"25px"}
                _light={{
                  bgColor: "customColor.white",
                }}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                color={"black"}
                fontWeight={"400"}
                border={"1px solid #b7bec5"}
                borderRadius={"5px"}
                _hover={{
                  color: "black",
                  bg: "#f2f3f4",
                  border: "1px solid #a6a9b0",
                }}
                _active={{
                  color: "black",
                  bg: "#f2f3f4",
                  border: "1px solid #a6a9b0",
                }}
              >
                {selectedMenu}
              </MenuButton>
              <MenuList
                overflow="auto"
                zIndex={"overlay"}
                minW={"300px"}
                maxH={"350px"}
              >
                {employeesList?.map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"0px 12px"}
                      _hover={{
                        backgroundColor: "rgba(0,10,30,.1)",
                      }}
                      onClick={() =>
                        handleOptionSelectMenu(
                          `${item.firstName} ${item.lastName}`
                        )
                      }
                    >
                      <Text
                        padding={"8px"}
                        margin={"0px 12px 0px 0px"}
                        _light={{
                          color: "customColor.black5",
                        }}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        fontSize={"15px"}
                      >
                        {item.firstName} {item.lastName}
                      </Text>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          )}
        </Box>
      </HStack>
    </Stack>
  );
}
