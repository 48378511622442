import { ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

export  function Footer() {
    return (
      <Box bgColor="primary.100" minHeight="550" maxWidth='100%'>
        
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems="center"
          p={5}
          /*maxW="1100px"*/
          mx="auto"
          
        >
          <Stack spacing={7} marginLeft='80px' marginTop='30px'  direction={{ base: 'column', md: 'row' }} align="center" >
            <VStack flex={1} align="start" /*mt={-8}*/>
              <Heading fontSize='18px' fontWeight='bold'>Accounting software</Heading>
              <Link href="#" color='teal'>How HRIS accounting software works</Link>
              <Link href="#" color='teal'>See all HRIS features</Link>
              <Link href="#" color='teal'>Explore the HRIS App Store</Link>
              <Link href="#" color='teal'>Plans</Link>
            </VStack>
            <VStack flex={1} align="start" /*mt={1}*/ >
              <Heading fontSize='1.1rem' fontWeight='bold'>HRIS for</Heading>
              <Link href="#" color='teal'>Accountants & bookkeepers</Link>
              <Link href="#" color='teal'>Media</Link>
              <Link href="#" color='teal'>Investors</Link>
              <Link href="#" color='teal'>App integrators</Link>
              <Link href="#" color='teal'>App developers</Link>
            </VStack>
            <VStack flex={1} align="start" /*mt={65}*/>
              <Heading fontSize='1.1rem' fontWeight='bold'>Resources</Heading>
              <Link href="#" color='teal'>Product updates</Link>
              <Link href="#" color='teal'>Small business insights</Link>
              <Link href="#" color='teal'>Small business guides</Link>
              <Link href="#" color='teal'>Small business templates</Link>
              <Link href="#" color='teal'>Accounting glossary</Link>
              <Link href="#" color='teal'>Business resources</Link>
              <Link href="#" color='teal'>Customer stories</Link>
              </VStack>
            <VStack flex={1} align="start" /*mt={7}*/>
              <Heading fontSize='1.1rem' fontWeight='bold'>Company</Heading>
              <Link href="#" color='teal'>About HRIS</Link>
              <Link href="#" color='teal'>Sustainability at HRIS 360</Link>
              <Link href="#" color='teal'>Blog</Link>
              <Link href="#" color='teal'>Our brands</Link>
              <Link href="#" color='teal'>Careers</Link>
              <Link href="#" color='teal'>Contact us</Link>
              </VStack>
            <VStack flex={1} align="start" /*mt={-16}*/ >
              <Text fontSize='1.1rem' fontWeight='bold'>Support</Text>
              <Link  href="#" color='teal'>Get support</Link>
              <Link href="#" color='teal'>Take a course</Link>
              <Link href="#" color='teal'>Find an accountant or bookkeeper</Link>
              </VStack>
              </Stack>
        </Flex>
        
        <br/>
        
        <Flex h='20px'>    
          <Divider orientation="horizontal" colorScheme="teal" maxWidth='100%' />  
        </Flex>
        {/* <svg >
          
          <line  x1="150" y1="0" x2="1190" y2="0" stroke="teal" strokeWidth="3" />
        </svg>  */}
      
        {/* <Box bgColor="gray.100" py={2} textAlign="left"> */}
        
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems="center"
          marginTop='30px'
          p={2}
          paddingBottom='10px'
          // maxW="1100px"
          // mx="auto"
        >
          {/* <Stack spacing={2} direction={{ base: 'column', md: 'row' }} align="center"> */}
          <VStack flex={1} justifyContent='start'>
          
          <Text fontSize="md" maxW='375px' textColor="teal">
            © {new Date().getFullYear()} HRIS Limited. All rights reserved. "HRIS" and "Beautiful business" are trademarks of HRIS Limited..
          </Text>
          <br/>
          <HStack  spacing={7}>
          <Link href="#" color="teal">Legal</Link>
          <Link href="#" color="teal">Privacy notice</Link>
          <Link href="#" color="teal">Accessibility</Link>
          </HStack>
          </VStack>
          <VStack flex={1} justifyContent='right' >
            <HStack>
            <Text align='right'>Region</Text>
            <Link href="#" color="rgb(33, 59, 85)" fontWeight='bold'><Icon as={ChevronUpIcon} />Australia (AUD)</Link>
            </HStack>
            <br/>
            <HStack spacing={7} >
            <Icon as={FaTwitter} fontSize="2xl" color="blue.500"/>
            <Icon as={FaFacebook} fontSize="2xl" color="blue.500"/>
            <Icon as={FaYoutube} fontSize="2xl" color="blue.500"/>
            <Icon as={FaLinkedin} fontSize="2xl" color="blue.500"/>
            <Icon as={FaInstagram} fontSize="2xl" color="blue.500"/>
            
             </HStack>

          </VStack>
          {/* </Stack> */}
          </Flex>
        
      </Box>
    );
  };