import axiosInstanceGateway from "../Payroll/Api/axiosInstance-gateway";

declare module InviteUser {
  type UserInviteDetail = {
    id?: string;
    userId?: string;
    businessId?: string;
    email: string;
    user: {
      firstName: string;
      lastName: string;
      email?: string;
    };
    allowedFeatures: {
      reports: {
        employeeCheckIn: boolean;
        employeeDetails: boolean;
      };
      accounting: {
        chartOfAccounts: boolean;
        taxRates: boolean;
      };
      payroll: {
        timesheets: boolean;
        payRuns: boolean;
        payrollSettings: boolean;
      };
      timeAndAttendance: {
        locations: boolean;
        notifications: boolean;
        schedules: boolean;
      };
      employee: {
        financialDetails: boolean;
        personalDetails: boolean;
      };
    };
    accessLevel: string;
    status?: string;
  };
  type userDetail = {
    id: string;
    email: string;
    user: {
      lastName: string;
      firstName: string;
      email?: string;
    };
    allowedFeatures: {
      payroll: {
        payRuns: boolean;
        timesheets: boolean;
        payrollSettings: boolean;
      };
      reports: {
        employeeCheckIn: boolean;
        employeeDetails: boolean;
      };
      employee: {
        personalDetails: boolean;
        financialDetails: boolean;
      };
      accounting: {
        taxRates: boolean;
        chartOfAccounts: boolean;
      };
      timeAndAttendance: {
        locations: boolean;
        schedules: boolean;
        notifications: boolean;
      };
    };
    accessLevel: string;
    status: string;
  };
}
module InviteUser {
  const API_URL = "/business-invitation";
  export function InviteUser(
    data: UserInviteDetail,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchPendingUsers(
    success: (response: Array<userDetail>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchUserById(
    id: string,
    success: (response: UserInviteDetail) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateUserById(
    id: string,
    data: Partial<UserInviteDetail>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteUserById(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function ResendInvitation(
    email: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/resend/${email}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { InviteUser };
