import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";

import { useEffect, useState } from "react";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { DependentsApi } from "../../../../../Api/Dependents";

interface AddDependentsProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId: string | undefined;
  setLastUpdated: (time: number) => void;
}
export default function AddDependents({
  isOpen,
  onClose,
  selectedId,
  setLastUpdated,
}: AddDependentsProps) {
  const form = useFormContext<DependentsApi.AddDependents>();

  const toast = useToast();
  const [displayValue, setDisplayValue] = useState("");
  useEffect(() => {
    setDisplayValue(formatDisplayValue(form.watch("cnic")));
  }, [form.watch("cnic")]);
  const formatDisplayValue = (value: any) => {
    const numericValue = value?.replace(/\D/g, "");
    if (numericValue?.length <= 5) {
      return numericValue;
    } else if (numericValue?.length <= 12) {
      return `${numericValue?.slice(0, 5)}-${numericValue?.slice(5, 12)}`;
    } else {
      return `${numericValue?.slice(0, 5)}-${numericValue?.slice(
        5,
        12
      )}-${numericValue?.slice(12, 13)}`;
    }
  };
  const genderType: Array<{ label: string; value: string }> = [
    {
      label: "Male",
      value: "MALE",
    },
    {
      label: "Female",
      value: "FEMALE",
    },
  ];
  const relationType: Array<{ label: string; value: string }> = [
    {
      label: "Mother",
      value: "Mother",
    },
    {
      label: "Father",
      value: "Father",
    },
    {
      label: "Spouse",
      value: "Spouse",
    },
    {
      label: "Children",
      value: "Children",
    },
  ];
  const maritalStatusType: Array<{ label: string; value: string }> = [
    {
      label: "Single",
      value: "SINGLE",
    },
    {
      label: "Married",
      value: "MARRIED",
    },
    {
      label: "Divorced",
      value: "DIVORCED",
    },
    {
      label: "Widowed",
      value: "WIDOWED",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Dependents
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            borderTop={"1px solid #ddd"}
            borderBottom={"1px solid #ddd"}
          >
            <Box>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Name:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Dependent Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabetic characters are allowed",
                      },
                    }}
                    control={form.control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",

                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder="Enter Name"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  DOB:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Dependent DOB is required",
                      },
                      // validate: {
                      //   isNotFuture: (value) =>
                      //     isAfter(today, new Date(value)) ||
                      //     "Date cannot be a future date",
                      //   isNot18: (value) => {
                      //     const dob = new Date(value);
                      //     const today = new Date();
                      //     let age = today.getFullYear() - dob.getFullYear();
                      //     const monthDiff = today.getMonth() - dob.getMonth();
                      //     if (
                      //       monthDiff < 0 ||
                      //       (monthDiff === 0 && today.getDate() < dob.getDate())
                      //     ) {
                      //       age--;
                      //     }
                      //     return (
                      //       age >= 18 || "You must be at least 18 years old"
                      //     );
                      //   },
                      // },
                    }}
                    control={form.control}
                    name="dateOfBirth"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          h: "36px",
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          type: "date",

                          borderTopRadius: "3px",
                        }}
                        placeholder="Enter DOB"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Gender:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Gender type is required",
                      },
                    }}
                    control={form.control}
                    name="gender"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        {...field}
                        options={genderType}
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select gender"
                        placeholder="Select..."
                        value={genderType.find(
                          (op) => op.value === field.value
                        )}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.value);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  CNIC:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Dependent CNIC/BFORM is required",
                      },
                      minLength: {
                        value: 13,
                        message: "CNIC must be 13 characters",
                      },
                      maxLength: {
                        value: 13,
                        message: "CNIC must be 13 characters",
                      },
                    }}
                    control={form.control}
                    name="cnic"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          value: displayValue,
                          minLength: 15,
                          maxLength: 15,
                          onChange: (e) => {
                            const formattedValue = formatDisplayValue(
                              e.target.value
                            );
                            setDisplayValue(formattedValue);
                            field.onChange(e.target.value.replace(/\D/g, ""));
                          },
                        }}
                        placeholder="Enter CNIC/BFORM Number"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Relationship:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Relationship type is required",
                      },
                    }}
                    control={form.control}
                    name="relationship"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        {...field}
                        options={relationType}
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select relationship"
                        placeholder="Select..."
                        value={relationType.find(
                          (op) => op.value === field.value
                        )}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.value);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Marital Status:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Marital Status is required",
                      },
                    }}
                    control={form.control}
                    name="martialStatus"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        {...field}
                        options={maritalStatusType}
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select marital status"
                        placeholder="Select..."
                        value={maritalStatusType.find(
                          (op) => op.value === field.value
                        )}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.value);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  if (form.getValues("id")) {
                    DependentsApi.UpdateDependents(
                      form.getValues("id")!,
                      data,
                      (success) => {
                        toast({
                          title: "Dependents Updated",
                          status: "success",
                        });

                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: err,
                          status: "error",
                        });
                      }
                    );
                  } else {
                    DependentsApi.PostDependents(
                      data,
                      (success) => {
                        toast({
                          title: "Dependents Added",
                          status: "success",
                        });

                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: err,
                          status: "error",
                        });
                      }
                    );
                  }
                })(e);
              }}
            >
              {selectedId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
