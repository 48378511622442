import {
  Avatar,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { AiFillCopy } from "react-icons/ai";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Shifts } from "../../../API/Shifts";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface ShiftTagProps {
  shift: Shifts.ShiftData;
  onClicked: MouseEventHandler<HTMLDivElement> | undefined;
}
export default function ShiftTag({ shift, onClicked }: ShiftTagProps) {
  const { setCopiedShifts, locations } = useScheduleContext();
  const [isLoading, setIsLoading] = useBoolean(false);
  const { employeesList } = useBusinessContext();
  const toast = useToast();
  const randomColors = [
    "#10b759",
    "#5b47fb",
    "#f10075",
    "#fd7e14",
    "#0168fa",
    "#00cccc",
  ];
  const randomColor =
    randomColors[Math.floor(Math.random() * randomColors.length)];

  return (
    <Stack px="2px" pt="2px" w="100%" onClick={onClicked}>
      <Stack
        backgroundColor={`${randomColor}33`}
        // "#DF362D66"
        spacing={0}
        px="4px"
        direction="row"
        py="3px"
        borderColor={randomColor}
        borderLeftWidth="2px"
        onClick={(e) => {
          onClicked && onClicked(e);

          e.stopPropagation();
        }}
      >
        <HStack spacing="2px" flex={1}>
          <Avatar
            name={
              employeesList?.find((emp) => emp.id === shift.employeeId)
                ?.firstName +
              " " +
              employeesList?.find((emp) => emp.id === shift.employeeId)
                ?.lastName
            }
            h={"24px"}
            w={"24px"}
            size={"sm"}
            marginLeft={"8px"}
            bg={"#daa3e4"}
            color={"#3f1946"}
          />

          <Text
            fontSize="11px"
            fontWeight={500}
            color="textcolors.primary"
            w="50%"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {shift.shiftStartTime} - {shift.shiftEndTime}
          </Text>

          <Text
            fontSize="11px"
            color="textcolors.primary"
            w="50%"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {shift.location?.name ?? ""}
          </Text>
        </HStack>
        <Tooltip label="Copy" fontSize="md">
          <IconButton
            isLoading={isLoading}
            aria-label="copy"
            icon={<AiFillCopy color="#000000" />}
            size="xs"
            variant="ghost"
            onClick={(e) => {
              setCopiedShifts({
                shiftId: shift.id,
                copyAll: false,
                copyDate: shift.shiftStartDate,
                copyFromEmployeeId: shift.employeeId,
              });
              e.stopPropagation();
            }}
          />
        </Tooltip>
      </Stack>
    </Stack>
  );
}
