import axios from "axios";
const axiosInstanceGateway = axios.create({
  baseURL: process.env.REACT_APP_API_URL_GATEWAY,
});

axiosInstanceGateway.interceptors.request.use(function (config) {
  config.headers["Accept"] = "application/json";
  const token = localStorage.getItem("_auth");

  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["ngrok-skip-browser-warning"] = true;
    }
  }
  return config;
});

export default axiosInstanceGateway;
export { axiosInstanceGateway as axiosInstance_gateway };
