import {
  HStack,
  Progress,
  ProgressLabel,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { SetupGuideApi } from "../../../Payroll/Api/SetupGuideApi";

export default function SetupGuideItems() {
  const { currentBusinessId, setSetupGuide, setupGuide } = useBusinessContext();
  const form = useForm<SetupGuideApi.SetupGuideData>({
    defaultValues: setupGuide,
  });

  const getProgress = () => {
    const totalStacks = 5;
    const clickedStacks = [
      setupGuide?.completedItems?.chartOfAccounts,
      setupGuide?.completedItems?.employees,
      setupGuide?.completedItems?.orgDetails,
      setupGuide?.completedItems?.payrollSettings,
      setupGuide?.completedItems?.users,
    ].filter((stack) => stack).length;

    return (clickedStacks / totalStacks) * 100;
  };
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <FormProvider {...form}>
      <Stack marginTop={"1rem"} marginBottom={"1rem"}>
        <Progress value={getProgress()} height={"24px"} borderRadius={"12px"}>
          <ProgressLabel
            fontSize={"15px"}
            textAlign={"left"}
            padding={"0px 12px"}
          >
            {getProgress()}%
          </ProgressLabel>
        </Progress>
      </Stack>

      <HStack
        padding={"8px 0px"}
        onClick={form.handleSubmit((data) => {
          if (!setupGuide?.completedItems?.orgDetails) {
            SetupGuideApi.UpdateSetupGuide(
              {
                ...setupGuide,
                completedItems: {
                  ...setupGuide.completedItems,
                  orgDetails: true,
                },
                completed: getProgress() === 100 ? true : false,
              },
              () => {
                toast({
                  title:
                    "Organisation Detail's Progress is add in Progress Bar",
                  status: "success",
                });
                navigate("/app/payroll/OrganizationDetailParent");
                form.setValue("completedItems.orgDetails", true);
                setSetupGuide({
                  ...setupGuide,
                  completedItems: {
                    ...setupGuide.completedItems,
                    orgDetails: true,
                  },
                  completed: getProgress() === 100 ? true : false,
                });
              },
              (error) => {
                toast({
                  title:
                    "Failed to add Organisation Detail's Progress in Progress bar",
                  description: error,
                  status: "error",
                });
              }
            );
          }
        })}
      >
        <Stack
          borderRadius={"full"}
          border={"1px solid #ddd"}
          minH={"34px"}
          minW={"34px"}
          bgColor={setupGuide?.completedItems?.orgDetails ? "#00823C" : "#fff"}
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
        >
          {setupGuide?.completedItems?.orgDetails && <FaCheck color="#fff" />}
        </Stack>
        <Stack gap={0} cursor="pointer">
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"16px"}
            fontWeight={"700"}
          >
            Organisation Details
          </Text>
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"14px"}
          >
            In organisation detail fill necessary info related to your business.
          </Text>
        </Stack>
      </HStack>
      <HStack
        padding={"8px 0px"}
        onClick={form.handleSubmit((data) => {
          if (!setupGuide?.completedItems?.payrollSettings) {
            SetupGuideApi.UpdateSetupGuide(
              {
                ...setupGuide,
                completedItems: {
                  ...setupGuide.completedItems,
                  payrollSettings: true,
                },
                completed: getProgress() === 100 ? true : false,
              },
              () => {
                toast({
                  title: "Payroll Setting's Progress is add in Progress Bar",
                  status: "success",
                });
                navigate("/app/payroll/payrollsettings");
                form.setValue("completedItems.payrollSettings", true);
                setSetupGuide({
                  ...setupGuide,
                  completedItems: {
                    ...setupGuide.completedItems,
                    payrollSettings: true,
                  },
                  completed: getProgress() === 100 ? true : false,
                });
              },
              (error) => {
                toast({
                  title:
                    "Failed to add Payroll Setting's Progress in Progress bar",
                  description: error,
                  status: "error",
                });
              }
            );
          }
        })}
      >
        <Stack
          borderRadius={"full"}
          border={"1px solid #ddd"}
          minH={"34px"}
          minW={"34px"}
          bgColor={
            setupGuide?.completedItems.payrollSettings ? "#00823C" : "#fff"
          }
          cursor="pointer"
          justifyContent="center" // Align content horizontally center
          alignItems="center" // Align items vertically center
        >
          {setupGuide?.completedItems.payrollSettings && (
            <FaCheck color="#fff" />
          )}
        </Stack>
        <Stack gap={0} cursor="pointer">
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"16px"}
            fontWeight={"700"}
          >
            PayRoll Settings
          </Text>
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"14px"}
          >
            Manage Organisation, Holidays, Calendars, Pay Items and Tax Slabs
          </Text>
        </Stack>
      </HStack>
      <HStack
        padding={"8px 0px"}
        onClick={form.handleSubmit((data) => {
          if (!setupGuide?.completedItems?.employees) {
            SetupGuideApi.UpdateSetupGuide(
              {
                ...setupGuide,
                completedItems: {
                  ...setupGuide.completedItems,
                  employees: true,
                },
                completed: getProgress() === 100 ? true : false,
              },
              () => {
                toast({
                  title: "Employee's Progress is add in Progress Bar",
                  status: "success",
                });
                navigate("/app/payroll/PayrollEmployee");
                form.setValue("completedItems.employees", true);
                setSetupGuide({
                  ...setupGuide,
                  completedItems: {
                    ...setupGuide.completedItems,
                    employees: true,
                  },
                  completed: getProgress() === 100 ? true : false,
                });
              },
              (error) => {
                toast({
                  title: "Failed to add Employee's Progress in Progress bar",
                  description: error,
                  status: "error",
                });
              }
            );
          }
        })}
      >
        <Stack
          borderRadius={"full"}
          border={"1px solid #ddd"}
          minH={"34px"}
          minW={"34px"}
          bgColor={setupGuide?.completedItems?.employees ? "#00823C" : "#fff"}
          cursor="pointer"
          justifyContent="center" // Align content horizontally center
          alignItems="center" // Align items vertically center
        >
          {setupGuide?.completedItems?.employees && <FaCheck color="#fff" />}
        </Stack>
        <Stack gap={0} cursor="pointer">
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"16px"}
            fontWeight={"700"}
          >
            Employees
          </Text>
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"14px"}
          >
            To manage, create or delete employees
          </Text>
        </Stack>
      </HStack>

      <HStack
        padding={"8px 0px"}
        onClick={form.handleSubmit((data) => {
          if (!setupGuide?.completedItems?.chartOfAccounts) {
            SetupGuideApi.UpdateSetupGuide(
              {
                ...setupGuide,
                completedItems: {
                  ...setupGuide.completedItems,
                  chartOfAccounts: true,
                },
                completed: getProgress() === 100 ? true : false,
              },
              () => {
                toast({
                  title: " ChartOfAccount's Progress is add in Progress Bar",
                  status: "success",
                });
                navigate("/app/payroll/ChartOfAccount");
                form.setValue("completedItems.chartOfAccounts", true);
                setSetupGuide({
                  ...setupGuide,
                  completedItems: {
                    ...setupGuide.completedItems,
                    chartOfAccounts: true,
                  },
                  completed: getProgress() === 100 ? true : false,
                });
              },
              (error) => {
                toast({
                  title:
                    "Failed to add ChartOfAccount's Progress in Progress bar",
                  description: error,
                  status: "error",
                });
              }
            );
          }
        })}
      >
        <Stack
          borderRadius={"full"}
          border={"1px solid #ddd"}
          minH={"34px"}
          minW={"34px"}
          bgColor={
            setupGuide?.completedItems?.chartOfAccounts ? "#00823C" : "#fff"
          }
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
        >
          {setupGuide?.completedItems?.chartOfAccounts && (
            <FaCheck color="#fff" />
          )}
        </Stack>
        <Stack gap={0} cursor="pointer">
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"16px"}
            fontWeight={"700"}
          >
            Chart of Accounts
          </Text>
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"14px"}
          >
            In Chart of accounts, you can keep track of your accounts under
            their categories
          </Text>
        </Stack>
      </HStack>
      <HStack
        padding={"8px 0px"}
        onClick={form.handleSubmit((data) => {
          if (!setupGuide?.completedItems?.users) {
            SetupGuideApi.UpdateSetupGuide(
              {
                ...setupGuide,
                completedItems: {
                  ...setupGuide.completedItems,
                  users: true,
                },
                completed: getProgress() === 100 ? true : false,
              },
              () => {
                toast({
                  title: "User's Progress is add in Progress Bar",
                  status: "success",
                });
                navigate("/app/payroll/user");
                form.setValue("completedItems.users", true);
                setSetupGuide({
                  ...setupGuide,
                  completedItems: {
                    ...setupGuide.completedItems,
                    users: true,
                  },

                  completed: getProgress() === 100 ? true : false,
                });
              },
              (error) => {
                toast({
                  title: "Failed to add User's Progress in Progress bar",
                  description: error,
                  status: "error",
                });
              }
            );
          }
        })}
      >
        <Stack
          borderRadius={"full"}
          border={"1px solid #ddd"}
          minH={"34px"}
          minW={"34px"}
          bgColor={setupGuide?.completedItems?.users ? "#00823C" : "#fff"}
          cursor="pointer"
          justifyContent="center" // Align content horizontally center
          alignItems="center" // Align items vertically center
        >
          {setupGuide?.completedItems?.users && <FaCheck color="#fff" />}
        </Stack>
        <Stack gap={0} cursor="pointer">
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"16px"}
            fontWeight={"700"}
          >
            Users
          </Text>
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"14px"}
          >
            In users you can invite users , see current users ,login history and
            access module
          </Text>
        </Stack>
      </HStack>
    </FormProvider>
  );
}
