import { axiosInstance } from "./axiosInstance-hris";
declare module PayrollHoliday {
  type AddHoliday = {
    holidaysGroupId: string;
    name: string;
    date: string;
  };
  type Holiday = {
    id: string;
    holidaysGroupId: string;
    name: string;
    date: string;
  };
}
module PayrollHoliday {
  const API_URL = "/payroll-holidays";
  export function CreateHoliday(
    data: AddHoliday,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateHoliday(
    id: string,
    data: Partial<AddHoliday>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchHolidayThroughGroupId(
    id: string,
    success: (data: Array<Holiday>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/group/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchHolidayThroughId(
    id: string,
    success: (data: Array<Holiday>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteHoliday(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PayrollHoliday };
