import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

export function Content({ children, ...props }: TextProps) {
  return (
    <Text
      fontSize="14px"
      color="textcolors.primary"
      fontWeight={500}
      {...props}
    >
      {children}
    </Text>
  );
}
export default Content;
