import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { LuArrowDownToLine, LuArrowUpToLine } from "react-icons/lu";
import { MdOutlineReceiptLong } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BillsTable } from "../BillsToPay/BillsTable";
import { AllContactsTable } from "./AllContactsTable";

export function AllContact() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const isMediumScreen = useBreakpointValue({ base: false, md: true });
  const tabCategories = [
    {
      label: "All",
      index: 0,
      flexValue: 1,
      component: <AllContactsTable />,
    },
    {
      label: "Customer",
      index: 1,
      flexValue: 1,
      component: <AllContactsTable />,
    },
    {
      label: "Suppliers",
      index: 2,
      flexValue: 1,
      component: <AllContactsTable />,
    },
    {
      label: "Achieved",
      index: 3,
      flexValue: 1,
      component: <AllContactsTable />,
    },
    {
      label: "Groups",
      index: 4,
      flexValue: 1,
      component: <AllContactsTable />,
    },
    {
      label: "Smart lists",
      index: 5,
      flexValue: 1,
      isMenu: true, // Indicate that this tab will have a dropdown menu
      component: <BillsTable />,
      menuOptions: [
        { name: "Have purchased an item", index: 6 },
        { name: "Outstanding > 30 days", index: 7 },
        { name: "Overdue > 7 days", index: 8 },
        { name: "Paid us (in the last year)", index: 9 },
      ],
    },
  ];

  const handleTabChange = (index: any) => {
    setSelectedTab(index);
  };

  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      maxH={"calc(100vh - 70px)"}
      overflowY="auto"
    >
      <Stack
        h={"60px"}
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"container.xl"} p={{ xs: "16px", lg: "16px 50px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Heading
                fontSize="17px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
                fontWeight="bold"
              >
                Contacts
              </Heading>
            </Stack>
            <Stack>
              <HStack gap={0}>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#2c48b5",
                  }}
                  onClick={() => {
                    navigate("/app/payroll/CreateContact");
                  }}
                >
                  New Contact
                </Button>

                <Menu>
                  <MenuButton
                    as={Button}
                    padding={0}
                    backgroundColor={"transparent"}
                    h={"34.5px"}
                    marginLeft={"8px"}
                    color={"customColor.black7"}
                    borderRadius={"full"}
                    _hover={{
                      color: "#fff",
                      backgroundColor: "#c1caf1",
                    }}
                    _active={{
                      color: "#fff",
                      backgroundColor: "#97a7e7",
                    }}
                  >
                    <Icon as={FiMoreVertical} />
                  </MenuButton>
                  <MenuList style={{ minWidth: "140px" }}>
                    {[
                      { name: "Import", icon: <LuArrowDownToLine /> },
                      { name: "Export", icon: <LuArrowUpToLine /> },
                      {
                        name: "Send Statement",
                        icon: <MdOutlineReceiptLong />,
                      },
                    ].map((menuItem, i) => (
                      <MenuItem key={i}>
                        <Button
                          leftIcon={menuItem.icon}
                          variant={"ghost"}
                          fontWeight={"600"}
                          color={"customColor.black7"}
                          fontSize={"13.5px"}
                          _hover={{ bg: "none" }}
                        >
                          {menuItem.name}
                        </Button>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </HStack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"container.xl"} p={{ xs: "16px", lg: "0px 50px" }}>
          <Stack
            marginTop={"24px"}
            borderTopRadius={"10px"}
            borderTop={isMediumScreen ? "1px solid #ddd" : 0}
            borderLeft={isMediumScreen ? "1px solid #ddd" : 0}
            borderRight={isMediumScreen ? "1px solid #ddd" : 0}
            transition={"all .3s ease"}
          >
            {isMediumScreen ? (
              <Tabs index={selectedTab} orientation="horizontal" isLazy>
                <TabList>
                  {tabCategories.map((tab, i) => {
                    return tab.isMenu ? (
                      <Menu key={i}>
                        <MenuButton
                          as={Tab}
                          _selected={{
                            _light: { color: "#3454d1" },
                            fontWeight: 600,
                            bg: "rgba(52,84,209,.075)",
                            borderBottom: "3px solid #3454d1",
                          }}
                          _hover={{
                            _light: { color: "#3454d1" },
                            fontWeight: 600,
                          }}
                          fontSize={"13.44px"}
                          fontWeight={"600"}
                          flex={tab.flexValue}
                        >
                          {tab.label}
                        </MenuButton>
                        <MenuList>
                          {tab.menuOptions.map((menu, index) => (
                            <MenuItem
                              key={index}
                              fontSize={"13.5px"}
                              fontWeight={"600"}
                              onClick={() => handleTabChange(menu.index)}
                            >
                              {menu.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    ) : (
                      <Tab
                        onClick={() => handleTabChange(tab.index)}
                        key={i}
                        flex={tab.flexValue}
                        borderRight={
                          tab.index !== 5 ? "1px solid #ddd" : "none"
                        }
                        fontSize={"13.44px"}
                        padding={"20px 15px"}
                        fontWeight={"600"}
                        _light={{ color: "#283c50" }}
                        _selected={{
                          _light: { color: "#3454d1" },
                          fontWeight: 600,
                          bg: "rgba(52,84,209,.075)",
                          borderBottom: "3px solid #3454d1",
                        }}
                        _hover={{
                          _light: { color: "#3454d1" },
                          fontWeight: 600,
                        }}
                      >
                        {tab.label}
                      </Tab>
                    );
                  })}
                </TabList>
              </Tabs>
            ) : (
              <Box alignContent={"flex-end"} justifyContent={"flex-end"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<AiOutlineCaretDown />}
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    justifyContent="flex-end"
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    minW={"170px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      color: "primary.950",
                      backgroundColor: "#fff",
                    }}
                    cursor={"pointer"}
                    _active={{
                      backgroundColor: "#fff",
                      color: "primary.950",
                    }}
                  >
                    {tabCategories[selectedTab].label}
                  </MenuButton>
                  <MenuList>
                    {tabCategories.map((tab) => (
                      <MenuItem
                        fontSize={"13.44px"}
                        key={tab.index}
                        onClick={() => setSelectedTab(tab.index)}
                      >
                        {tab.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
          </Stack>
        </Container>
      </Stack>
      <Container
        maxW={"container.xl"}
        padding={{ xs: "16px", lg: "30px 50px" }}
      >
        <Box>{tabCategories[selectedTab].component}</Box>
      </Container>
    </Stack>
  );
}
