import {
  Box,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
interface PreviewProps {
  payloadData: any;
}
export function Preview({ payloadData }: PreviewProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  function convertExcelDateToStandard(excelDate: number): string {
    const baseDate = new Date(1899, 11, 30);
    const jsDate = new Date(
      baseDate.getTime() + excelDate * 24 * 60 * 60 * 1000
    );
    const formattedDate = moment(jsDate).format("YYYY-MM-DD");
    return formattedDate;
  }
  const Data: Array<{
    title: string;
    items: Array<{
      option: string;
      value: string;
    }>;
  }> = [
    {
      title: "Personal Detail",
      items: [
        { option: "Employee ID", value: payloadData[currentIndex]?.uniqueId },
        { option: "Title", value: payloadData[currentIndex]?.title },
        { option: "First Name", value: payloadData[currentIndex]?.fname },
        { option: "Last Name", value: payloadData[currentIndex]?.lname },
        {
          option: "Date of Birth",
          value:
            payloadData[currentIndex]?.dob &&
            convertExcelDateToStandard(payloadData[currentIndex]?.dob),
        },
        { option: "Cnic", value: payloadData[currentIndex]?.cnic },
        { option: "Gender", value: payloadData[currentIndex]?.gender },
      ],
    },
    {
      title: "Employment Detail",
      items: [
        {
          option: "Date of Joining",
          value:
            payloadData[currentIndex]?.dateOfJoining &&
            convertExcelDateToStandard(
              payloadData[currentIndex]?.dateOfJoining
            ),
        },
        { option: "Position", value: payloadData[currentIndex]?.position },
        { option: "Department", value: payloadData[currentIndex]?.department },
      ],
    },
    {
      title: "Contact Detail",
      items: [
        { option: "Email", value: payloadData[currentIndex]?.email },
        {
          option: "Mobile Number",
          value: payloadData[currentIndex]?.mobileNumber,
        },
        {
          option: "Address",
          value: payloadData[currentIndex]?.address,
        },
        { option: "City", value: payloadData[currentIndex]?.city },
        { option: "Province", value: payloadData[currentIndex]?.province },
        { option: "Country", value: payloadData[currentIndex]?.country },
        { option: "Postal Code", value: payloadData[currentIndex]?.postalCode },
      ],
    },
    {
      title: "Bank Detail",
      items: [
        { option: "Bank Name", value: payloadData[currentIndex]?.bankName },
        {
          option: "Account Name",
          value: payloadData[currentIndex]?.accountName,
        },
        {
          option: "IBAN/Account Number",
          value: payloadData[currentIndex]?.iban,
        },
      ],
    },
    {
      title: "Salary Detail",
      items: [
        {
          option: "Gross Salary",
          value: payloadData[currentIndex]?.grossSalary,
        },
      ],
    },
  ];

  return (
    <Stack
      border={"1px solid #d6dade"}
      borderTopRadius={"3px"}
      borderBottomRadius={"3px"}
      padding={"0px"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      gap={0}
    >
      <HStack padding={"15px"} gap={0} justifyContent={"space-between"}>
        <IconButton
          aria-label="add"
          icon={<MdKeyboardArrowLeft fontSize={"25px"} />}
          color={"black"}
          variant="ghost"
          bg={"transparent"}
          _hover={{
            bg: "transparent",
          }}
          onClick={() => {
            if (currentIndex > 0) {
              setCurrentIndex(currentIndex - 1);
            }
          }}
        />
        <Text
          _dark={{
            color: "customColor.white",
          }}
          _light={{
            color: "customColor.black7",
          }}
          fontWeight={"700"}
          fontSize={"15px"}
        >
          Preview employee {currentIndex + 1} of {payloadData.length}
        </Text>
        <IconButton
          aria-label="add"
          icon={<MdKeyboardArrowRight fontSize={"25px"} />}
          color={"black"}
          variant="ghost"
          bg={"transparent"}
          _hover={{
            bg: "transparent",
          }}
          onClick={() => {
            if (currentIndex < payloadData.length - 1) {
              setCurrentIndex(currentIndex + 1);
            }
          }}
        />
      </HStack>

      {Data?.map((item: any, i: number) => {
        return (
          <Stack>
            <Text
              _dark={{
                color: "customColor.white",
              }}
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"600"}
              fontSize={"13px"}
              padding={"10px 15px"}
              gap={0}
              borderTop={"1px solid #d6dade"}
              borderBottom={"1px solid #d6dade"}
            >
              {item.title}
            </Text>
            <SimpleGrid columns={3} spacing={2} padding={"10px 15px"} gap={0}>
              {item.items.map((options: any, j: number) => {
                return (
                  <Box margin={"0px 10px"} padding={"0px 0px 20px"} key={j}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"500"}
                      fontSize={"13.44px"}
                    >
                      {options.option}
                    </Text>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {options.value}
                    </Text>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Stack>
        );
      })}
    </Stack>
  );
}
