import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
// import { differenceInDays, differenceInMonths, parseISO } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavigateFunction, useParams } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { EmployeeSeparationApi } from "../../../Api/EmployeeSeparationApi";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";
interface TabProps {
  navigate: NavigateFunction;
}
export function EmployeeSeparation({ navigate }: TabProps) {
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState<string>("");

  const terminationReason = [
    "Resignation - Undisclosed Reason",
    "Resignation - Domestic reason",
    "Resignation - (Family Commitment)",
    "Resignation - Better Prospects (Local)",
    "Resignation - Better Prospects (Overseas)",
    "Resignation - Further Studies",
    "Resignation - Solicited",
    "Termination - Fraud",
    "Termination - Insubordination",
    "Termination - No Show",
    "Termination - Harassment",
    "Termination - Misconduct",
    "Termination - Contract Conclusion",
    "III health",
    "Deceased",
    "Redundancy",
    "Contract cessation",
    "Transfer",
  ].map((options) => ({ label: options, value: options }));
  const form = useForm<EmployeeSeparationApi.SeparationData>({
    defaultValues: {
      separationDate: "",
      reason: "",
      interviewDate: "",
      resignationDate: "",
      comments: "",
      idCardReturned: false,
      clearanceReceived: false,
      noticePeriodCompleted: false,
    },
  });
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      EmployeeSeparationApi.FetchEmployeeSeparation(
        id,
        (data) => {
          form.reset(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [id]);
  const calculateDuration = (resignDate: any, separationDate: any) => {
    if (!resignDate || !separationDate) return;

    const startDate = moment(resignDate);
    const endDate = moment(separationDate);

    const duration = moment.duration(endDate.diff(startDate));
    const months = duration.months();
    const days = duration.days();

    setDuration(`${duration.years() * 12 + months} months and ${days} days`);
  };

  useEffect(() => {
    const { resignationDate, separationDate } = form.getValues();
    calculateDuration(resignationDate, separationDate);
  }, [form.watch("resignationDate"), form.watch("separationDate")]);

  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      marginBottom={"30px"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Stack>
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="600"
            padding="20px"
            as="h2"
            size="md"
            _light={{
              color: "customColor.black7",
            }}
          >
            Full and Final Settlement
          </Heading>
          <Divider orientation="horizontal" />
          <Container
            maxW="400px"
            marginTop="15px"
            paddingTop="20px"
            pb="8px"
            marginX="auto"
            color={"rgb(0,10,30,.75)"}
            px="0px"
          >
            <Stack spacing="35px">
              <FormControl>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Resign Date is required",
                    },
                  }}
                  control={form.control}
                  name="resignationDate"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "date",

                        borderTopRadius: "3px",
                      }}
                      placeholder=""
                      label="Resignation Date"
                      labelProps={{
                        fontSize: ".845rem",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              <HStack spacing={0} align={"baseline"}>
                <Stack flex={1}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Separation Date is required",
                      },
                    }}
                    control={form.control}
                    name="separationDate"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "date",

                          borderTopRadius: "3px",
                        }}
                        placeholder=""
                        label="Separation Date"
                        labelProps={{
                          fontSize: ".845rem",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack flex={2}>
                  <FormControl>
                    <FormLabel
                      fontSize=".845rem"
                      fontWeight="500"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                    >
                      Reason
                    </FormLabel>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Select Reason is required",
                        },
                      }}
                      control={form.control}
                      name="reason"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          options={terminationReason}
                          withValidation
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Please Select Reason"
                          placeholder="Select..."
                          value={terminationReason.find(
                            (op) => op.value === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              </HStack>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Exit Interview Date is required",
                  },
                }}
                control={form.control}
                name="interviewDate"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "date",

                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Exit Interview Date"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Stack gap={0}>
                <FormLabel
                  fontSize=".845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px"}
                >
                  Comments
                </FormLabel>
                <Controller
                  name="comments"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <>
                      <Textarea
                        {...field}
                        //   isInvalid={fieldState.invalid}
                        //   errorBorderColor="Red"
                        border="1px solid #e5e7eb"
                        fontSize=".845rem"
                        _hover={{
                          border: "1px solid hsl(0, 0%, 70%)",
                        }}
                        _focus={{
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _focusVisible={{
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _dark={{
                          bgColor: "customColor.dark.50",
                        }}
                        _light={{
                          bgColor: "customColor.white",
                          color: "customColor.black7",
                        }}
                        mb="15px"
                        overflowX="hidden"
                        maxLength={300}
                        w="400px"
                        shadow="inner"
                        placeholder="Type a Comment"
                        h="80px"
                      />
                      {/* {fieldState.invalid && (
                          <Text color="red" fontSize="12px">
                            {fieldState.error?.message}
                          </Text>
                        )} */}
                    </>
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <FormLabel
                  fontSize=".845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  ID Card
                </FormLabel>
                <Controller
                  name="idCardReturned"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup
                      {...field}
                      value={field.value ? "Yes" : "No"} // Convert boolean to string
                      onChange={(e) => field.onChange(e === "Yes")} // Convert string back to boolean
                    >
                      <Radio
                        isInvalid={fieldState.invalid}
                        size="md"
                        value="Yes"
                        colorScheme="green"
                        paddingRight={"10px"}
                      >
                        Yes
                      </Radio>

                      <Radio
                        isInvalid={fieldState.invalid}
                        size="md"
                        colorScheme="red"
                        value="No"
                      >
                        No
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <FormLabel
                  fontSize=".845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Clearance Received
                </FormLabel>
                <Controller
                  name="clearanceReceived"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup
                      {...field}
                      value={field.value ? "Yes" : "No"} // Convert boolean to string
                      onChange={(e) => field.onChange(e === "Yes")} // Convert string back to boolean
                    >
                      <Radio
                        isInvalid={fieldState.invalid}
                        size="md"
                        value="Yes"
                        colorScheme="green"
                        paddingRight={"10px"}
                      >
                        Yes
                      </Radio>

                      <Radio
                        isInvalid={fieldState.invalid}
                        size="md"
                        colorScheme="red"
                        value="No"
                      >
                        No
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <FormLabel
                  fontSize=".845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Notice Period
                </FormLabel>
                <Controller
                  name="noticePeriodCompleted"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup
                      {...field}
                      value={field.value ? "Yes" : "No"} // Convert boolean to string
                      onChange={(e) => field.onChange(e === "Yes")} // Convert string back to boolean
                    >
                      <Radio
                        isInvalid={fieldState.invalid}
                        size="md"
                        value="Yes"
                        colorScheme="green"
                        paddingRight={"10px"}
                      >
                        Yes
                      </Radio>

                      <Radio
                        isInvalid={fieldState.invalid}
                        size="md"
                        colorScheme="red"
                        value="No"
                      >
                        No
                      </Radio>
                    </RadioGroup>
                  )}
                />

                <Text
                  fontSize=".845rem"
                  fontWeight="700"
                  mt="25px"
                  color="red.500"
                >
                  Duration: {duration}
                </Text>
              </Stack>
            </Stack>
          </Container>

          <Stack
            h={"80px"}
            borderTop={"2px solid #d6dade"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            paddingRight={"20px"}
            direction={"row"}
            marginTop={"30px"}
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate(-1);
                // navigate("/app/payroll/PayrollEmployee");
              }}
            >
              Cancel
            </Button>

            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={loading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setLoading(true);
                    EmployeeSeparationApi.CreateEmployeeSeparation(
                      id!,
                      data,
                      (success) => {
                        toast({
                          title: "Employee Separation Added/Updated",
                          status: "success",
                          duration: 2000,
                          isClosable: true,
                        });

                        setLoading(false);
                      },
                      (error) => {
                        toast({
                          title: error,
                          status: "error",
                          duration: 2000,
                          isClosable: true,
                        });
                        setLoading(false);
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      status: "error",
                    });
                    setLoading(false);
                  }
                )(e);
              }}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Box>
      </Stack>
      <Stack
        marginX={"auto"}
        borderTop={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginRight={"auto"}
        marginLeft={"auto"}
        w="100%"
        marginTop={"20px"}
      >
        <HistoryAccordion />
      </Stack>
    </Stack>
  );
}
