import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Fragment } from "react";
import MainNavBar from "../Navbar/MainNavBar";
import AccountingApp from "./images/AccountingApp.png";
import ClaimExpenses from "./images/ClaimExpenses.png";
import GSTReturns from "./images/GSTReturn.png";
import HQ from "./images/HQ.png";
import acceptPayment from "./images/acceptPayment.png";
import accountingDashboard from "./images/accountingDashboard.png";
import analytics from "./images/analytics.png";
import bankConnection from "./images/bankConnections.png";
import bankReconiliation from "./images/bankReconiliation.png";
import bookkeeper from "./images/bookkeeper.png";
import captureData from "./images/captureData.jpg";
import caskbook from "./images/cashbook.png";
import contacts from "./images/contacts.png";
import files from "./images/files.jpg";
import integrateApps from "./images/integrateApps.png";
import inventory from "./images/inventory.jpg";
import invoices from "./images/invoices.jpg";
import manageAssets from "./images/manageAssets.png";
import manager from "./images/manager.png";
import multiCurrency from "./images/multiCurrency.jpg";
import payBills from "./images/payBills.jpg";
// import payment from "./images/payment.";
import payroll from "./images/payroll.jpg";
import purchaseOrder from "./images/purchaseOrder.jpeg";
import quotes from "./images/quotes.png";
import reporting from "./images/reporting.jpg";
import trackProject from "./images/trackProject.png";
// import {SlArrowUpCircle} from 'react-icons/si'
// import {LuArrowUpCircle } from 'react-icons/lu'
import { IoIosArrowUp } from "react-icons/io";
export  function AllFeatures() {
  const item: Array<{ title: string; desc: string; img?: string }> = [
    {
      title: "Pay bills",
      desc: "Track and pay bills on time. And get a clear overview of accounts payable and cash flow.",
      img: payBills,
    },
    {
      title: "Bank connections",
      desc: "Connect your bank to HRIS 360 and set up bank feeds. Transactions flow securely straight into HRIS 360 each business day.",
      img: bankConnection,
    },
    {
      title: "Bank reconciliation",
      desc: "Keep your account balances and financials up to date. Easily confirm that the transactions in your bank accounts are recorded in your business financial records with HRIS 360’s bank reconciliation software.",
      img: bankReconiliation
    },
    {
      title: "Capture data",
      desc: "Get copies of documents and key data stored into HRIS 360 automatically. Hubdoc makes data capture easy.",
      img: captureData,
    },
    {
      title: "Files",
      desc: "Use HRIS 360 for online file storage. Manage and share documents, contracts, bills and receipts safely from anywhere.",
      img: files,
    },
    {
      title: "Online invoicing",
      desc: "Work smarter, not harder with HRIS 360’s intuitive invoicing software. With HRIS 360 online accounting, you can send invoices, automate reminders and so much more from the comfort of your desktop or mobile app. Finish your invoice admin at a time that works for you and your small business. It’s only fair.",
      img: invoices,
    },
    {
      title: "Multi-currency",
      desc: "Pay and get paid in over 160 currencies with multi-currency accounting. Use instant currency conversions.",
      img: multiCurrency,
    },
    {
      title: "Quotes",
      desc: "Create professional online quotes using HRIS 360 software or the app. Send quotes instantly from your phone or desktop.",
      img: quotes,
    },

    {
      title: "Accept Payments",
      desc: "Accept online payments by credit card, debit card or direct debit straight from your HRIS 360 invoice. Let customers pay the way they want, increase the likelihood of getting paid on time, and reduce the time spent chasing payments.",
      img: acceptPayment,
    },
    {
      title: "Reporting",
      desc: "Track your finances with accurate accounting reports. And collaborate with your advisor online in real time.",
      img: reporting,
    },
    {
      title: "Analytics",
      desc: "See future cash flow, check financial health and track metrics. Upgrade from HRIS 360 Analytics to Analytics Plus for more.",
      img: analytics,
    },
    {
      title: "Accounting dashboard",
      desc: "Keep an eye on your finances day by day using the accounting dashboard. Track bank balances, invoices, bills and more.",
      img: accountingDashboard,
    },
    {
      title: "GST Returns",
      desc: "HRIS 360 calculates GST and speeds up preparing business activity statements. Submit your BAS to the ATO from HRIS 360.",
      img: GSTReturns,
    },
    {
      title: "Track projects",
      desc: "Quote, invoice and get paid for jobs. Plus keep track of costs and profitability with project and job tracker software.",
      img: trackProject,
    },
    {
      title: "Manage HRIS 360 contacts",
      desc: "Use HRIS 360 for contact management. See details of a customer’s or supplier’s sales, invoices and payments in one place.",
      img: contacts,
    },
    {
      title: "Inventory",
      desc: "Keep track of stock levels with HRIS 360’s inventory management system, which populates invoices and orders with items you buy and sell.",
      img: inventory,
    },
    {
      title: "Purchase orders",
      desc: "Create and send purchase orders online with purchase order software. Keep track of orders and deliveries at every step.",
      img: purchaseOrder,
    },
    {
      title: "Manage fixed assets",
      desc: "HRIS 360 makes it easy to track fixed assets and work with your accountant to manage them, keeping your books up to date.",
      img: manageAssets,
    },
    {
      title: "Payroll",
      desc: "Pay staff and report payroll details to the ATO with online payroll software. Automate tax, pay and super calculations.",
      img: payroll,
    },
    {
      title: "Claim expenses",
      desc: "Manage spending and submit or reimburse expense claims with HRIS 360’s expense management tools.",
      img: ClaimExpenses,
    },
    {
      title: "Integrate apps",
      desc: "Choose apps, integrations, and financial services offerings that connect to HRIS 360 to run your business better.",
      img: integrateApps,
    },
    {
      title: "HRIS 360 Accounting app",
      desc: "Run your small business from anywhere and remain productive in otherwise unproductive moments with the accounting app.",
      img: AccountingApp,
    },
  ];
  const data: Array<{ label: string; desc?: string ;img?:string}> = [
    { label: "HRIS 360 HQ", desc: "Manage clients and staff.",img:HQ },
    {
      label: "HRIS 360 Cashbook,HRIS 360 Ledger",
      desc: "Starter plans for smaller clients",
      img: caskbook,
    },
    {
      label: "HRIS 360 Practice Manager",
      desc: "Manage your practice efficiently.",
      img: manager,
    },
    { label: "Explore products for accountants & bookkeepers",img:bookkeeper },
  ];
  return (
    <Stack marginLeft={"38px"} marginRight={"28px"} marginTop={"2px"}>
      <MainNavBar />
      <Stack padding={"120px 100px 80px 120px"}>
        <Stack
          justifyContent={"center"}
          textAlign={"center"}
          color={"#213B55"}
          marginBottom={"6rem"}
        >
          <Heading
            fontSize={"70px"}
            fontWeight={"extrabold"}
            lineHeight={"0.9"}
            letterSpacing={"-0.0833rem"}
          >
            All HRIS 360 features
          </Heading>
          <Text fontSize={"27.6px"} lineHeight={"1.2"} marginTop={"20px"}>
            Discover the features you need to run business .
          </Text>
          <Image
            src={
              "https://www.xero.com/content/dam/xero/pilot-images/features/all-features/all-features-hero-banner.1646877466934.png"
            }
            alt="features ss"
            justifyContent={"center"}
            width={"100%"}
            maxWidth={"175rem"}
          />
        </Stack>
        <Stack color={"#213B55"}>
          {item.map((dt, k) => {
            return (
              <Fragment key={k}>
                <HStack align="top" justifyContent="center">
                  <Stack marginBottom={"5rem"}>
                    <Image
                      src={dt.img}
                      h={{ lg: "200px", md: "100px" }}
                      w={{ lg: "200px", md: "100px" }}
                      display={{ lg: "flex", md: "flex", xs: "none" }}
                    />
                  </Stack>
                  <Stack marginLeft={"20px"} width={"30vw"}>
                    <Text
                      fontSize={{ lg: "30px", xs: "22px" }}
                      fontWeight={"bold"}
                      letterSpacing={"-0.0533rem"}
                      lineHeight={"1.15"}
                      justifyContent={"left"}
                      textAlign={"left"}
                    >
                      {dt.title}{" "}
                    </Text>
                    <Text
                      paddingBottom={"6px"}
                      fontSize={{ lg: "18px", xs: "14px" }}
                      lineHeight={"1.3"}
                    >
                      {dt.desc}
                    </Text>
                    <Button
                      leftIcon={<IoIosArrowUp />}
                      variant={"link"}
                      justifyContent={"left"}
                      textAlign={"left"}
                      color={"#213B55"}
                      fontSize={"15px"}
                      textDecoration={"2px underline rgb(0, 137, 220)"}
                      textUnderlineOffset={"0.2rem"}
                      padding={"0px 0px 4px"}
                      _hover={{
                        textDecoration: "2px underline rgb(0, 137, 220)",
                        textUnderlineOffset: "0.4rem",
                      }}
                    >
                      {dt.title}
                    </Button>
                  </Stack>
                </HStack>
              </Fragment>
            );
          })}
        </Stack>
      </Stack>

      <Stack
        backgroundColor={"#172C40"}
        textColor={"rgb(255, 255, 255)"}
        textAlign={"left"}
        maxWidth={"100%"}
        marginBottom={"52px"}
      >
        <Stack padding={"80px 100px 80px 120px"}>
          <HStack justifyContent={"space-between"}>
            <Stack>
              <Heading
                fontSize={"51.6px"}
                fontWeight={"bold"}
                letterSpacing={"-0.07rem"}
                lineHeight={"1.15"}
                marginBottom={"30px"}
                color={"white"}
              >
                For accountants & bookkeepers
              </Heading>
            </Stack>
            <Stack>
              <Text fontSize={"21.96px"}>
                Become a partner to access free tools,benefits and rewards for
                your practice
              </Text>
              <HStack>
                <Button
                  size={"sm"}
                  backgroundColor={"rgb(80,220,170)"}
                  borderRadius={"3px"}
                  fontSize={"15px"}
                  height={"35.2px"}
                  variant={"solid"}
                  color={"#172C40"}
                  lineHeight={"2.66667rem"}
                  fontWeight={"bold"}
                  marginTop={"10px"}
                  _hover={{
                    backgroundColor: "rgb(74, 196, 157)",
                    // border: "0.2rem solid rgb(74, 196, 157)"
                  }}
                >
                  Become a partner
                </Button>
                <Button
                  variant={"link"}
                  textDecoration={"2px underline rgb(0, 137, 220)"}
                  textUnderlineOffset={"0.2rem"}
                  fontSize={{ lg: "15px", md: "14px" }}
                  fontWeight={"bold"}
                  color={"white"}
                  paddingLeft={"25px"}
                  _hover={{
                    textDecoration: "2px underline rgb(0, 137, 220)",
                    textUnderlineOffset: "0.4rem",
                  }}
                >
                  About the partner program
                </Button>
              </HStack>
            </Stack>
          </HStack>
          <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 4 }}
            spacing={10}
            // templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
            paddingTop={"50px"}
            paddingBottom={"20px"}

            // justifyItems='center'

            // height={'345px'}
          >
            {data.map((item, i) => {
              return (
                <Box
                  key={i}
                  boxShadow={"0"}
                  bgColor={"transparent"}
                  minH={"310px"}
                  display="flex"
                  flexDirection="column"
                >
                  {/* <CardBody   p={0}> */}
                  <Box flex={1}>
                    <Text
                      fontSize={"20.04px"}
                      marginBottom={"11px"}
                      color={"white"}
                      textDecoration={"2px underline rgb(0, 137, 220)"}
                      textUnderlineOffset={"0.2rem"}
                      fontWeight={"bold"}
                      lineHeight={"1.15"}
                      _hover={{
                        textDecoration: "3px underline rgb(0, 137, 220)",
                        textUnderlineOffset: "0.4rem",
                      }}
                    >
                      {item.label}
                    </Text>
                    <Text
                      fontSize={"16px"}
                      marginBottom={"11px"}
                      color={"white"}
                      fontWeight={"normal"}
                      lineHeight={"1.15"}
                    >
                      {item.desc}
                    </Text>
                  </Box>

                  <Image
                    src={item.img}
                    h={{ lg: "276px", md: "100px" }}
                    // w={{ lg: "250px", md: "100px" }}
                  />

                  {/* </CardBody> */}
                </Box>
              );
            })}
          </SimpleGrid>
        </Stack>
      </Stack>
    </Stack>
  );
}
