import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { AreasModule } from "../../../TimeAttendance/API/Areas";
import { Shifts } from "../../../TimeAttendance/API/Shifts";
import { ShiftsOperations } from "../../../TimeAttendance/API/ShiftsOperations";
import {
  fetchLocation,
  isWithinGeofence,
  loadGoogleMapsScript,
} from "../TimeSheet/utils";
import ScheduleMap from "./Modals/ScheduleMap";
import UnscheduledShiftModal from "./Modals/UnscheduledShiftModal";

export function Schedule() {
  const [latitude, setLatitude] = useState<string>("24.8607");
  const [longitude, setLongitude] = useState<string>("67.0011");
  const [locationError, setLocationError] = useState(false);
  const toast = useToast();

  const [rosterData, setRosterData] = useState<any>([]);
  const [selectedShiftId, setSelectedShiftId] = useState<string>();
  const [selectedTransfer, setSelectedTransfer] = useState<any>();
  const [selectedShift, setSelectedShift] = useState<any>();
  const [areasList, setAreasList] = useState<any>([]);
  const [areaStatus, setAreaStatus] = useState<any>({});
  const [startisLoad, setStartIsLoad] = useState(false);
  const [endisLoad, setEndIsLoad] = useState(false);
  const [updatedRoasterData, setUpdatedRoasterData] = useState(Date.now());
  const [updatedShiftData, setUpdatedShiftData] = useState(Date.now());
  const [isRosterLoading, setIsRosterLoading] = useState(false);
  const [todayShifts, setTodayShifts] = useState([]);
  const [previousShifts, setPreviousShifts] = useState([]);
  const [upcomingShifts, setUpcomingShifts] = useState([]);
  const rosterStartDate = moment().subtract(3, "days").format("YYYY-MM-DD");
  const rosterEndDate = moment().add(30, "days").format("YYYY-MM-DD");
  useEffect(() => {
    setIsRosterLoading(true);
    Shifts.GetEmployeeRoster(
      {
        startDate: rosterStartDate,
        endDate: rosterEndDate,
      },
      (data) => {
        setRosterData(data);
        categorizeShifts(data);
        setIsRosterLoading(false);
      },
      (error) => {}
    );
  }, [updatedRoasterData]);
  const categorizeShifts = (data: any) => {
    const today = moment().format("YYYY-MM-DD");
    const sortedTodayShifts: any = [];
    const sortedPreviousShifts: any = [];
    const sortedUpcomingShifts: any = [];

    data?.forEach((shift: any) => {
      const shiftDate = moment(shift?.shiftStartDate).format("YYYY-MM-DD");
      if (shiftDate === today) {
        sortedTodayShifts.push(shift);
      } else if (moment(shiftDate).isBefore(today)) {
        sortedPreviousShifts.push(shift);
      } else if (moment(shiftDate).isAfter(today)) {
        sortedUpcomingShifts.push(shift);
      }
    });

    setTodayShifts(sortedTodayShifts);
    setPreviousShifts(sortedPreviousShifts);
    setUpcomingShifts(sortedUpcomingShifts);
  };
  const calculateDuration = (start: any, end: any) => {
    const startTime = moment(start, "YYYY-MM-DDTHH:mm:ss");
    const endTime = moment(end, "YYYY-MM-DDTHH:mm:ss");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };
  const handleFetchLocation = async () => {
    try {
      const { latitude, longitude }: any = await fetchLocation();
      console.log("latitude", latitude);
      console.log("longitude", longitude);
      setLatitude(latitude.toString());
      setLongitude(longitude.toString());
      setLocationError(false);
    } catch (error) {
      setLocationError(true);
      toast({
        title: "Error fetching location",
        duration: 5000,
        description:
          "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
        status: "error",
      });
    }
  };
  useEffect(() => {
    handleFetchLocation();
  }, [latitude, longitude]);
  useEffect(() => {
    loadGoogleMapsScript(() => {});
  }, []);
  useEffect(() => {
    if (selectedShiftId) {
      Shifts.GetShiftById(
        selectedShiftId,
        (data) => {
          setSelectedShift(data);
          AreasModule.FetchAreasByLocationId(
            data?.locationId,
            (areaData) => {
              setAreasList(areaData);
              const statusUpdate: any = {};
              data?.shiftRecord?.checkIns.forEach((checkIn: any) => {
                if (!statusUpdate[checkIn.areaId]) {
                  statusUpdate[checkIn.areaId] = [];
                }
                statusUpdate[checkIn.areaId].push({
                  checkedIn: true,
                  checkedOut: !!checkIn.checkOutTime,
                  checkInId: checkIn?.id,
                  checkInTime: checkIn.checkInTime,
                });
              });
              setAreaStatus(statusUpdate);
            },
            (error) => {
              toast({
                title: "Error",
                description: error,
                status: "error",
              });
            }
          );
        },
        (error) => {}
      );
    }
  }, [selectedShiftId, updatedShiftData]);
  // function updateTimeDifference() {
  //   const shiftStartTime = ?.shiftRecord?.shiftStartTime;
  //   const timeDifference = getTimeDifference(shiftStartTime);
  //   setTimeDifference(timeDifference);
  // }
  const handleStartShift = async () => {
    try {
      const userLocation: any = await fetchLocation();
      const geofenceResult = await isWithinGeofence(
        userLocation,
        selectedShift
      );

      if (geofenceResult.withinRange) {
        setStartIsLoad(true);
        ShiftsOperations.StartShift(
          {
            id: selectedShift.id,
            shiftStartTime: moment().format("HH:mm:ss"),
            shiftStartDate: moment().format("YYYY-MM-DD"),
            shiftStartCoordinates: {
              latitude: String(userLocation.latitude) ?? "0",
              longitude: String(userLocation.longitude) ?? "0",
            },
          },
          (data) => {
            toast({
              title: "Shift Started",
              description: data,
              status: "success",
            });
            setStartIsLoad(false);
            setUpdatedRoasterData(Date.now());
            setUpdatedShiftData(Date.now());
          },
          (message) => {
            toast({
              title: "Error",
              description: message,
              status: "error",
            });
            setStartIsLoad(false);
          }
        );
      } else {
        toast({
          title: "Error",
          description: `You are not within the required geofence area. You are ${geofenceResult.distance?.toFixed(
            2
          )} km away.`,
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Failed to get your location, please ensure your location services are enabled.",
        status: "error",
      });
    }
  };
  const handleEndShift = async () => {
    try {
      const userLocation: any = await fetchLocation();
      const geofenceResult = await isWithinGeofence(
        userLocation,
        selectedShift
      );

      if (geofenceResult.withinRange) {
        setEndIsLoad(true);
        ShiftsOperations.EndShift(
          {
            id: selectedShift.id,
            shiftEndTime: moment().format("HH:mm:ss"),
            shiftEndDate: moment().format("YYYY-MM-DD"),
            shiftEndCoordinates: {
              latitude: String(userLocation.latitude) ?? "0",
              longitude: String(userLocation.longitude) ?? "0",
            },
          },
          (data) => {
            toast({
              title: "Shift Ended",
              description: data,
              status: "success",
            });
            setEndIsLoad(false);
            setUpdatedRoasterData(Date.now());
            setUpdatedShiftData(Date.now());
          },
          (message) => {
            toast({
              title: "Error",
              description: message,
              status: "error",
            });
            setEndIsLoad(false);
          }
        );
      } else {
        toast({
          title: "Error",
          description: `You are not within the required geofence area. You are ${geofenceResult.distance?.toFixed(
            2
          )} km away.`,
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Failed to get your location, please ensure your location services are enabled.",
        status: "error",
      });
    }
  };
  const getRecentActiveCheckIn = () => {
    for (const areaId in areaStatus) {
      const areaCheckIns = areaStatus[areaId];
      const activeCheckIn = areaCheckIns.find(
        (checkIn: any) => !checkIn.checkedOut
      );
      if (activeCheckIn) {
        return activeCheckIn;
      }
    }
    return null;
  };
  const handleCheckIn = async (areaId: any) => {
    const userLocation: any = await fetchLocation();
    const geofenceResult = await isWithinGeofence(userLocation, selectedShift);
    if (!geofenceResult.withinRange) {
      toast({
        title: "Error",
        description: `You are not within the required geofence area. You are ${geofenceResult.distance?.toFixed(
          2
        )} km away.`,
        status: "error",
      });
      return;
    }
    const activeCheckIn = getRecentActiveCheckIn();

    if (activeCheckIn) {
      toast({
        title: "Error",
        description:
          "Please check out from the previous area before checking in to a new one.",
        status: "error",
      });
      return;
    }

    ShiftsOperations.ShiftCheckIn(
      {
        coordinates: {
          latitude: latitude ?? "0",
          longitude: longitude ?? "0",
        },
        checkInTime: moment().format("HH:mm:ss"),
        areaId: areaId,
        shiftRecordId: selectedShift?.shiftRecord?.id,
      },
      (data) => {
        toast({
          title: "Checked In",
          description: data,
          status: "success",
        });
        setAreaStatus((prev: any) => ({
          ...prev,
          [areaId]: [
            ...(prev[areaId] || []),
            {
              checkedIn: true,
              checkedOut: false,

              checkInTime: moment().format("HH:mm:ss"),
            },
          ],
        }));
        setUpdatedShiftData(Date.now());
      },
      (message) => {
        toast({
          title: "Error",
          description: message,
          status: "error",
        });
      }
    );
  };

  const handleCheckOut = async (areaId: any) => {
    const userLocation: any = await fetchLocation();
    const geofenceResult = await isWithinGeofence(userLocation, selectedShift);
    if (!geofenceResult.withinRange) {
      toast({
        title: "Error",
        description: `You are not within the required geofence area. You are ${geofenceResult.distance?.toFixed(
          2
        )} km away`,
        status: "error",
      });
      return;
    }
    const areaCheckIns = areaStatus[areaId];
    const activeCheckInIndex = areaCheckIns
      ? areaCheckIns.findIndex(
          (checkIn: any) => checkIn.checkedIn && !checkIn.checkedOut
        )
      : -1;

    if (activeCheckInIndex !== -1) {
      const checkInRecord = areaCheckIns[activeCheckInIndex];

      ShiftsOperations.ShiftCheckOut(
        checkInRecord.checkInId, // Use the check-in ID here
        {
          coordinates: {
            latitude: latitude ?? "0",
            longitude: longitude ?? "0",
          },
          checkOutTime: moment().format("HH:mm:ss"),
          areaId: areaId,
          shiftRecordId: selectedShift?.shiftRecord?.id,
        },
        (data) => {
          toast({
            title: "Checked Out",
            description: data,
            status: "success",
          });

          setAreaStatus((prev: any) => {
            const updatedCheckIns = prev[areaId].map(
              (checkIn: any, index: number) => {
                if (index === activeCheckInIndex) {
                  return { ...checkIn, checkedOut: true };
                }
                return checkIn;
              }
            );
            return { ...prev, [areaId]: updatedCheckIns };
          });

          setUpdatedShiftData(Date.now());
        },
        (message) => {
          toast({
            title: "Error",
            description: message,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Error",
        description: "No active check-in found to check out from.",
        status: "error",
      });
    }
  };
  const unscheduledShift = useDisclosure();
  const hasPendingShift = (shifts: any): boolean => {
    return shifts?.some(
      (shift: any) => shift?.status === "pending" || shift?.status === "started"
    );
  };
  const isPendingShift = hasPendingShift(todayShifts);
  const transferShift = useDisclosure();
  return (
    <Fragment>
      <UnscheduledShiftModal
        isOpen={unscheduledShift.isOpen}
        onClose={unscheduledShift.onClose}
        setSelectedShiftId={setSelectedShiftId}
        setUpdatedRoasterData={setUpdatedRoasterData}
        latitude={latitude}
        longitude={longitude}
        locationError={locationError}
      />
      {/* <ShiftTransferModal
        isOpen={transferShift.isOpen}
        onClose={transferShift.onClose}
        shift={selectedTransfer}
        updatedRoasterData={updatedRoasterData}
      /> */}
      {!isRosterLoading ? (
        <Stack minH={"100px"} padding={"24px"} gap={0}>
          <Flex justifyContent={"space-between"} maxH="700px">
            <Stack
              w={"30%"}
              overflowY="auto"
              sx={{
                "::-webkit-scrollbar": {
                  width: "0px", // Initially, the scrollbar is hidden
                  background: "transparent",
                },
                ":hover::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible on hover
                },
                ":active::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible when active
                },
                ":focus::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible on focus
                },
                // Add for Firefox and other browsers if needed
                scrollbarWidth: "none",
                ":hover": {
                  scrollbarWidth: "thin",
                },
                ":active": {
                  scrollbarWidth: "thin",
                },
                ":focus": {
                  scrollbarWidth: "thin",
                },
              }}
              maxH="700px"
            >
              {(todayShifts?.length === 0 || !isPendingShift) && (
                <HStack
                  padding={"12px"}
                  w="90%"
                  border={".5px solid #ccced2"}
                  bg={"#f2f3f4"}
                  // bg={selectedShiftId === roster?.id ? "#73DFF6" : "#f2f3f4"}
                  _hover={{
                    border: ".5px solid black",
                  }}
                  margin={"0px 2px 4px"}
                  borderRadius={"3px"}
                >
                  <Stack w="100%">
                    <Text
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={"15px"}
                    >
                      {moment().format("DD MMM YYYY")}
                    </Text>

                    <Button
                      w="100%"
                      borderRadius="5px"
                      onClick={unscheduledShift.onOpen}
                    >
                      Start an Unscheduled Shift
                    </Button>
                    <Text
                      fontWeight={"400"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={"11px"}
                    >
                      The shift will be recorded as unscheduled and the duration
                      will be of 8 hours
                    </Text>
                  </Stack>
                </HStack>
              )}

              <Text fontSize={"20px"} fontWeight={600}>
                Roster
              </Text>
              <Box>
                <Text fontSize={"13px"} fontWeight={600} mb="5px">
                  Today
                </Text>
                {todayShifts?.map((roster: any, i: number) => {
                  return (
                    <HStack
                      width={"100%"}
                      key={i}
                      padding={"12px"}
                      cursor={
                        roster?.shiftStartDate ===
                          moment().format("YYYY-MM-DD") &&
                        roster?.status !== "ended"
                          ? "pointer"
                          : "not-allowed"
                      }
                      w="90%"
                      border={".5px solid #ccced2"}
                      bg={
                        selectedShiftId === roster?.id ? "#73DFF6" : "#f2f3f4"
                      }
                      _hover={{
                        border: ".5px solid black",
                      }}
                      margin={"0px 2px 4px"}
                      borderRadius={"3px"}
                    >
                      <Stack w={"100%"}>
                        <HStack justifyContent="space-between">
                          <Text
                            fontWeight={"600"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontSize={"15px"}
                          >
                            {moment(roster?.shiftStartDate).format(
                              "DD MMM YYYY"
                            )}
                          </Text>
                          {/* <Button
                            variant="link"
                            // onClick={() => {
                            //   setSelectedTransferId(roster?.id);
                            //   transferShift.onOpen();
                            // }}
                            onClick={() => {
                              setSelectedTransfer(roster);
                              transferShift.onOpen();
                            }}
                          >
                            Transfer
                          </Button> */}
                        </HStack>
                        {(roster?.status === "pending" ||
                          roster?.status === "started") && (
                          <Button
                            w="100%"
                            borderRadius="5px"
                            bgColor={
                              roster?.status === "pending"
                                ? "#47C5E8"
                                : "#FFA500"
                            }
                            _hover={{
                              bgColor:
                                roster?.status === "pending"
                                  ? "#47C5E8"
                                  : "#FFA500",
                            }}
                            onClick={() =>
                              roster?.shiftStartDate ===
                                moment().format("YYYY-MM-DD") &&
                              roster?.status !== "ended"
                                ? setSelectedShiftId(roster?.id)
                                : null
                            }
                          >
                            {roster?.status === "pending"
                              ? "Start your today's shift"
                              : "End your today's shift"}
                          </Button>
                        )}

                        <Text
                          fontWeight={"400"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"15px"}
                        >
                          {`${moment(
                            `${roster?.shiftStartDate}T${roster?.shiftStartTime}`
                          ).format("hh:mm A")} - ${moment(
                            `${roster?.shiftEndDate}T${roster?.shiftEndTime}`
                          ).format("hh:mm A")}`}
                        </Text>
                        <Text
                          fontWeight={"400"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"11px"}
                        >
                          Total Shift time:{" "}
                          {calculateDuration(
                            `${roster?.shiftStartDate}T${roster?.shiftStartTime}`,
                            `${roster?.shiftEndDate}T${roster?.shiftEndTime}`
                          )}
                        </Text>
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Shift Started:{" "}
                              {`${moment(
                                `${roster?.shiftRecord?.shiftStartDate}T${roster?.shiftRecord?.shiftStartTime}`
                              ).format("hh:mm A")}`}
                            </Text>
                          )}
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Shift Ended:{" "}
                              {`${moment(
                                `${roster?.shiftRecord?.shiftEndDate}T${roster?.shiftRecord?.shiftEndTime}`
                              ).format("hh:mm A")}`}
                            </Text>
                          )}
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Duration:{" "}
                              {calculateDuration(
                                `${roster?.shiftRecord?.shiftStartDate}T${roster?.shiftRecord?.shiftStartTime}`,
                                `${roster?.shiftRecord?.shiftEndDate}T${roster?.shiftRecord?.shiftEndTime}`
                              )}
                            </Text>
                          )}

                        <Text
                          fontWeight={"500"}
                          color={
                            roster?.status === "pending"
                              ? "#FFA500"
                              : roster?.status === "started"
                              ? "#000000"
                              : "#FF0000"
                          }
                          fontSize={"12px"}
                        >
                          {roster?.status === "pending"
                            ? "Pending"
                            : roster?.status === "started"
                            ? "Started"
                            : "Ended"}
                        </Text>
                      </Stack>
                    </HStack>
                  );
                })}
              </Box>
              <Box>
                <Text fontSize={"13px"} fontWeight={600} mb="5px">
                  Upcoming
                </Text>
                {upcomingShifts?.map((roster: any, i: number) => {
                  return (
                    <HStack
                      key={i}
                      padding={"12px"}
                      cursor={
                        roster?.shiftStartDate ===
                          moment().format("YYYY-MM-DD") &&
                        roster?.status !== "ended"
                          ? "pointer"
                          : "not-allowed"
                      }
                      w="90%"
                      border={".5px solid #ccced2"}
                      bg={
                        selectedShiftId === roster?.id ? "#73DFF6" : "#f2f3f4"
                      }
                      _hover={{
                        border: ".5px solid black",
                      }}
                      margin={"0px 2px 4px"}
                      borderRadius={"3px"}
                      onClick={() =>
                        roster?.shiftStartDate ===
                          moment().format("YYYY-MM-DD") &&
                        roster?.status !== "ended"
                          ? setSelectedShiftId(roster?.id)
                          : null
                      }
                    >
                      <Stack>
                        <Text
                          fontWeight={"600"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"15px"}
                        >
                          {moment(roster?.shiftStartDate).format("DD MMM YYYY")}
                        </Text>

                        <Text
                          fontWeight={"400"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"15px"}
                        >
                          {`${moment(
                            `${roster?.shiftStartDate}T${roster?.shiftStartTime}`
                          ).format("hh:mm A")} - ${moment(
                            `${roster?.shiftEndDate}T${roster?.shiftEndTime}`
                          ).format("hh:mm A")}`}
                        </Text>
                        <Text
                          fontWeight={"400"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"11px"}
                        >
                          Total Shift time:{" "}
                          {calculateDuration(
                            `${roster?.shiftStartDate}T${roster?.shiftStartTime}`,
                            `${roster?.shiftEndDate}T${roster?.shiftEndTime}`
                          )}
                        </Text>
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Shift Started:{" "}
                              {`${moment(
                                `${roster?.shiftRecord?.shiftStartDate}T${roster?.shiftRecord?.shiftStartTime}`
                              ).format("hh:mm A")}`}
                            </Text>
                          )}
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Shift Ended:{" "}
                              {`${moment(
                                `${roster?.shiftRecord?.shiftEndDate}T${roster?.shiftRecord?.shiftEndTime}`
                              ).format("hh:mm A")}`}
                            </Text>
                          )}
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Duration:{" "}
                              {calculateDuration(
                                `${roster?.shiftRecord?.shiftStartDate}T${roster?.shiftRecord?.shiftStartTime}`,
                                `${roster?.shiftRecord?.shiftEndDate}T${roster?.shiftRecord?.shiftEndTime}`
                              )}
                            </Text>
                          )}

                        <Text
                          fontWeight={"500"}
                          color={
                            roster?.status === "pending"
                              ? "#FFA500"
                              : roster?.status === "started"
                              ? "#000000"
                              : "#FF0000"
                          }
                          fontSize={"12px"}
                        >
                          {roster?.status === "pending"
                            ? "Pending"
                            : roster?.status === "started"
                            ? "Started"
                            : "Ended"}
                        </Text>
                      </Stack>
                    </HStack>
                  );
                })}
              </Box>
              <Box>
                <Text fontSize={"13px"} fontWeight={600} mb="5px">
                  Past
                </Text>
                {previousShifts?.map((roster: any, i: number) => {
                  return (
                    <HStack
                      key={i}
                      padding={"12px"}
                      cursor={
                        roster?.shiftStartDate ===
                          moment().format("YYYY-MM-DD") &&
                        roster?.status !== "ended"
                          ? "pointer"
                          : "not-allowed"
                      }
                      w="90%"
                      border={".5px solid #ccced2"}
                      bg={
                        selectedShiftId === roster?.id ? "#73DFF6" : "#f2f3f4"
                      }
                      _hover={{
                        border: ".5px solid black",
                      }}
                      margin={"0px 2px 4px"}
                      borderRadius={"3px"}
                      onClick={() =>
                        roster?.shiftStartDate ===
                          moment().format("YYYY-MM-DD") &&
                        roster?.status !== "ended"
                          ? setSelectedShiftId(roster?.id)
                          : null
                      }
                    >
                      <Stack>
                        <Text
                          fontWeight={"600"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"15px"}
                        >
                          {moment(roster?.shiftStartDate).format("DD MMM YYYY")}
                        </Text>

                        <Text
                          fontWeight={"400"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"15px"}
                        >
                          {`${moment(
                            `${roster?.shiftStartDate}T${roster?.shiftStartTime}`
                          ).format("hh:mm A")} - ${moment(
                            `${roster?.shiftEndDate}T${roster?.shiftEndTime}`
                          ).format("hh:mm A")}`}
                        </Text>
                        <Text
                          fontWeight={"400"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          fontSize={"11px"}
                        >
                          Total Shift time:{" "}
                          {calculateDuration(
                            `${roster?.shiftStartDate}T${roster?.shiftStartTime}`,
                            `${roster?.shiftEndDate}T${roster?.shiftEndTime}`
                          )}
                        </Text>
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Shift Started:{" "}
                              {`${moment(
                                `${roster?.shiftRecord?.shiftStartDate}T${roster?.shiftRecord?.shiftStartTime}`
                              ).format("hh:mm A")}`}
                            </Text>
                          )}
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Shift Ended:{" "}
                              {`${moment(
                                `${roster?.shiftRecord?.shiftEndDate}T${roster?.shiftRecord?.shiftEndTime}`
                              ).format("hh:mm A")}`}
                            </Text>
                          )}
                        {roster?.shiftRecord !== null &&
                          roster?.status === "ended" && (
                            <Text
                              fontWeight={"400"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              fontSize={"11px"}
                            >
                              Duration:{" "}
                              {calculateDuration(
                                `${roster?.shiftRecord?.shiftStartDate}T${roster?.shiftRecord?.shiftStartTime}`,
                                `${roster?.shiftRecord?.shiftEndDate}T${roster?.shiftRecord?.shiftEndTime}`
                              )}
                            </Text>
                          )}

                        <Text
                          fontWeight={"500"}
                          color={
                            roster?.status === "pending"
                              ? "#FFA500"
                              : roster?.status === "started"
                              ? "#000000"
                              : "#FF0000"
                          }
                          fontSize={"12px"}
                        >
                          {roster?.status === "pending"
                            ? "Pending"
                            : roster?.status === "started"
                            ? "Started"
                            : "Ended"}
                        </Text>
                      </Stack>
                    </HStack>
                  );
                })}
              </Box>
            </Stack>

            <Stack w="70%" alignItems="center">
              <ScheduleMap
                latitute={latitude}
                longitude={longitude}
                setLatitute={setLatitude}
                setLongitude={setLongitude}
              />
              {selectedShiftId && selectedShiftId !== undefined && (
                <Stack
                  w="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Text fontSize={"20px"} fontWeight={600}>
                    Areas
                  </Text>
                  {selectedShift?.areas.map((area: any, i: number) => {
                    return (
                      <Flex
                        key={i}
                        h="50px"
                        borderRadius="10px"
                        bg={"#f2f3f4"}
                        padding={"4px"}
                        border={".5px solid #ccced2"}
                        w="80%"
                        justifyContent={"space-between"}
                      >
                        <Text>
                          {
                            areasList.find((item: any) => item.id === area)
                              ?.name
                          }
                        </Text>
                        <ButtonGroup>
                          <Button
                            fontSize={"10px"}
                            bgColor="#E97132"
                            h={"30px"}
                            borderRadius={"3px"}
                            borderWidth={"1px"}
                            variant={"solid"}
                            padding={"6px 8px"}
                            fontWeight={700}
                            display={
                              selectedShift?.status === "started"
                                ? "block"
                                : "none"
                            }
                            _hover={{
                              bgColor: "#48B016",
                              borderColor: "#48B016",
                            }}
                            marginBottom={"16px"}
                            onClick={() => {
                              if (locationError) {
                                toast({
                                  title: "Error",
                                  description:
                                    "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
                                  status: "error",
                                });
                                return;
                              }
                              handleCheckIn(area);
                            }}
                            isDisabled={!!getRecentActiveCheckIn()}
                          >
                            {areaStatus[area]?.checkedIn
                              ? "Checked In"
                              : "Check In"}
                          </Button>
                          {areaStatus[area]?.map(
                            (checkIn: any, index: number) =>
                              !checkIn.checkedOut && (
                                <Button
                                  fontSize={"10px"}
                                  bgColor="#E97132"
                                  h={"30px"}
                                  borderRadius={"3px"}
                                  borderWidth={"1px"}
                                  variant={"solid"}
                                  display={
                                    selectedShift?.status === "started"
                                      ? "block"
                                      : "none"
                                  }
                                  padding={"6px 8px"}
                                  fontWeight={700}
                                  _hover={{
                                    bgColor: "#48B016",
                                    borderColor: "#48B016",
                                  }}
                                  marginBottom={"16px"}
                                  onClick={() => {
                                    if (locationError) {
                                      toast({
                                        title: "Error",
                                        description:
                                          "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
                                        status: "error",
                                      });
                                      return;
                                    }
                                    handleCheckOut(area);
                                  }}
                                >
                                  Check Out
                                </Button>
                              )
                          )}
                        </ButtonGroup>
                      </Flex>
                    );
                  })}

                  <Button
                    isLoading={startisLoad}
                    display={
                      selectedShift?.status === "pending" ? "block" : "none"
                    }
                    fontSize={"13px"}
                    bgColor="#4CBB17"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#48B016",
                      borderColor: "#48B016",
                    }}
                    marginBottom={"16px"}
                    onClick={() => {
                      if (locationError) {
                        toast({
                          title: "Error",
                          description:
                            "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
                          status: "error",
                        });
                        return;
                      }
                      handleStartShift();
                    }}
                  >
                    Start Shift
                  </Button>
                  <Button
                    isLoading={endisLoad}
                    display={
                      selectedShift?.status === "started" ? "block" : "none"
                    }
                    fontSize={"13px"}
                    bgColor="#FF0000"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#E60000",
                      borderColor: "#E60000",
                    }}
                    marginBottom={"16px"}
                    onClick={() => {
                      if (locationError) {
                        toast({
                          title: "Error",
                          description:
                            "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
                          status: "error",
                        });
                        return;
                      }
                      handleEndShift();
                    }}
                  >
                    End Shift
                  </Button>
                </Stack>
              )}
            </Stack>
          </Flex>
        </Stack>
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </Fragment>
  );
}
