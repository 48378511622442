import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { ChartOfAccounts } from "../../../../Api/ChartOfAccounts";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
  numberOfCheckedRows: number;
  setLastUpdated: (timestamp: number) => void;

  selectedIds: string[];
}

export function Delete({
  isOpen,
  onClose,
  numberOfCheckedRows,
  selectedIds,
  setLastUpdated,
}: DeleteProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Delete Accounts
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <Text
            padding={"0px 0px 10px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            You have selected{" "}
            {numberOfCheckedRows > 0 ? numberOfCheckedRows : "0"}
            {numberOfCheckedRows === 0 ? " account" : " accounts"} account to be
            Deleted.
          </Text>
          <Text
            padding={"0px 0px 10px"}
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            This account will be removed from any contracts using it as a
            default account.
          </Text>
          <Text
            padding={"10px 0px 0px"}
            margin={"10px 0px 0px"}
            fontSize={"11px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            <b>Note:</b> Deleting Account is permanent and cannot be undone.
            Achived Accounts can be Restored at any time.
          </Text>
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              ChartOfAccounts.DeleteChartOfAccounts(
                selectedIds,
                (message) => {
                  onClose();
                  setLastUpdated(Date.now());
                  setIsLoading(false);
                  toast({
                    title: "Delete Successfully!",
                    status: "success",
                  });
                },
                (error) => {
                  toast({
                    title: "Failed to Delete!",
                    status: "error",
                  });
                  setIsLoading(false);
                }
              );
            }} // Pass selectedIds to onDelete function
          >
            Delete
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            margin={"0px 0px 0px 10px"}
            w={"104.6px"}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
