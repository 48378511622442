import { axiosInstance } from "./axiosInstance-hris";
declare module Statutory {
  type StatutoryBenefitsData = {
    id: string;
    socialSecurityNumber: string;
    eobiNumber: string;
    businessInsuranceId?: string | null;
  };
}
module Statutory {
  const API_URL = "/employee-statutory-benefits";

  export function CreateStatutoryBenefits(
    empId: string | undefined,
    data: StatutoryBenefitsData,
    success: (data: StatutoryBenefitsData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchStatutoryBenefits(
    empId: string | undefined,
    success: (data: StatutoryBenefitsData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Statutory };
