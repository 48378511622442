import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { PayRun } from "../../../Api/PayRun";

interface AddPayRunPros {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
}
export function AddPayRun({ isOpen, onClose, setLastUpdated }: AddPayRunPros) {
  const form = useFormContext<PayRun.CreatePayrun>();
  const [allPayPeriods, setAllPayPeriods] = useState<Array<PayRun.PayPeriod>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const toast = useToast();
  useEffect(() => {
    PayRun.FetchPayPeriod(
      (data) => {
        setAllPayPeriods(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <>
      <FormProvider {...form}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w={"350px"}>
            <ModalHeader
              _light={{
                color: "customColor.black7",
              }}
              fontSize=".9975rem"
              _dark={{
                color: "customColor.white",
              }}
              padding={"15px 5px 15px 15px"}
              fontWeight={"700"}
              lineHeight={"1.75rem"}
              h={"52px"}
            >
              Add a Pay Run
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              padding={"15px"}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "#FBFBFB",
              }}
              border={"1px solid #E5E5E5"}
            >
              <Text
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                lineHeight={"1.3077"}
                padding={"0px 0px 5px"}
              >
                Select a pay period
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Pay Period is required",
                  },
                }}
                control={form.control}
                name="payPeriod"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select "
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Pay Period"
                    withValidation
                    options={allPayPeriods}
                    value={field.value}
                    onChange={(newValue: any) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option: any) => option.string}
                    getOptionValue={(option: any) => option}
                  />
                )}
              />
            </ModalBody>

            <ModalFooter>
              <ButtonGroup size="sm" marginLeft="700px">
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    setIsLoading(true);
                    form.handleSubmit(
                      (data) => {
                        PayRun.CreatePayRun(
                          data.payPeriod,
                          (success) => {
                            toast({
                              title: "PayRun is Added",
                              status: "success",
                            });
                            setLastUpdated(Date.now());
                            onClose();
                          },
                          (err) => {
                            toast({
                              title: "Error",
                              status: "error",
                              description: err,
                            });
                            setIsLoading(false);
                          }
                        );
                      },
                      (error) => {
                        toast({
                          title: " Failed",

                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    )(e);
                  }}
                >
                  {isLoading ? "Next....." : "Next"}
                </Button>
                <Button
                  backgroundColor={"transparent"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"#ea4d4d"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormProvider>
    </>
  );
}
