import { ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Collapse,
  Container,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { MdAdd, MdRemove } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { CustomInput } from "../../../../Common/CustomInput";

export function OpeningBalances() {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const handleToggle2 = () => setShow2(!show2);
  const [file, setFile] = useState<File | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Explicitly typing the ref to refer to an HTMLInputElement
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      setFile(droppedFile);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input value with an empty string
    }
  };
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        flex={1}
        overflowY="auto"
      >
        <Stack
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          padding={"16px"}
          gap={0}
          lineHeight={1.2}
        >
          <Container maxWidth={"container.md"} p={0}>
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="ProductAndServices"
                  fontSize={"13px"}
                  color={"#3454d1"}
                >
                  Inventory
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                _light={{ color: "customColor.black7" }}
                fontSize={"24px"}
                fontWeight={"700"}
              >
                Import Opening Balances
              </Text>
            </Stack>
          </Container>
        </Stack>
        <Container maxWidth={"container.md"} p={0}>
          <Stack maxWidth={"650px"} p={0}>
            <Stack padding={"20px 0"} gap={0}>
              <Stack
                gap={0}
                _light={{
                  bg: "#fff",
                }}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                overflow={"hidden"}
                border={"1px solid #e5e7eb"}
              >
                <HStack
                  justifyContent={"space-between"}
                  padding={"15px 15px 14px 15px"}
                  alignItems={"center"}
                  onClick={handleToggle}
                >
                  <Text fontSize={"15px"} fontWeight={"700"}>
                    Current opening balances
                  </Text>
                  {show ? (
                    <MdRemove fontSize={"23px"} />
                  ) : (
                    <MdAdd fontSize={"23px"} />
                  )}
                </HStack>
                <Collapse in={show} animateOpacity>
                  <Stack
                    borderTop={"1px solid #e5e7eb"}
                    direction={"row"}
                    padding={"5px 0px 0px 20px"}
                  >
                    <Stack flex={1} h={"64px"}>
                      <Text
                        fontSize={"18px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={700}
                      >
                        3
                      </Text>
                      <Text
                        fontSize={"14px"}
                        _light={{
                          color: "customColor.gray.6b7885",
                        }}
                      >
                        Items with opening balances
                      </Text>
                    </Stack>
                    <Stack flex={1} h={"64px"}>
                      <Text
                        fontSize={"18px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={700}
                      >
                        320.00
                      </Text>
                      <Text
                        fontSize={"14px"}
                        _light={{
                          color: "customColor.gray.6b7885",
                        }}
                      >
                        Total value of opening balances
                      </Text>
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
              <Stack
                _light={{
                  bg: "#fff",
                }}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                marginBottom={"20px"}
                gap={0}
                overflow={"hidden"}
                borderBottom={"1px solid #e5e7eb"}
                borderRight={"1px solid #e5e7eb"}
                borderLeft={"1px solid #e5e7eb"}
              >
                <HStack
                  justifyContent={"space-between"}
                  padding={"15px 15px 14px 15px"}
                  alignItems={"center"}
                  onClick={handleToggle2}
                >
                  <Text fontSize={"15px"} fontWeight={"700"}>
                    Update opening balances
                  </Text>
                  {show2 ? (
                    <MdRemove fontSize={"23px"} />
                  ) : (
                    <MdAdd fontSize={"23px"} />
                  )}
                </HStack>
                <Collapse in={show2} animateOpacity>
                  <Stack
                    borderTop={"1px solid #e5e7eb"}
                    padding={"20px"}
                    gap={0}
                  >
                    <VStack paddingBottom={"10px"} marginBottom={"10px"}>
                      <Text
                        fontSize={"14px"}
                        _light={{
                          color: "#d66618",
                        }}
                      >
                        Updating your opening balances will replace all existing
                        opening balances
                      </Text>
                    </VStack>
                    <Stack
                      borderTop={"1px solid #e5e7eb"}
                      padding={"20px 0 10px 0"}
                    >
                      <Stack direction={"row"}>
                        {" "}
                        <Text
                          fontSize={"14px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          1.
                        </Text>
                        <Stack>
                          <Text
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"700"}
                          >
                            What is the date of your opening balances?
                          </Text>
                          <CustomInput
                            withValidation
                            input={{
                              w: "150px",
                              h: "38px",
                              padding: "12px 15px",
                              fontSize: ".845rem",
                              marginBottom: "10px",
                              variant: "outline",
                              type: "date",
                              borderTopRadius: "3px",
                              alignContent: "center",
                            }}
                            placeholder=""
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      borderTop={"1px solid #e5e7eb"}
                      padding={"20px 0 10px 0"}
                    >
                      <Stack direction={"row"}>
                        {" "}
                        <Text
                          fontSize={"14px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          2.
                        </Text>
                        <Stack gap={0}>
                          <Text
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"700"}
                          >
                            Download a template containing your opening balances
                          </Text>
                          <Text
                            padding={"10px 0px"}
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"400"}
                          >
                            This contains all your tracked inventory items and
                            their existing opening balances.
                          </Text>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            paddingBottom={"10px"}
                          >
                            <Icon as={SiMicrosoftexcel} />
                            <Link href="" color={"#3454d1"} fontSize={"14px"}>
                              Download existing opening balance{" "}
                            </Link>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      borderTop={"1px solid #e5e7eb"}
                      padding={"20px 0 10px 0"}
                    >
                      <Stack direction={"row"}>
                        {" "}
                        <Text
                          fontSize={"14px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          3.
                        </Text>
                        <Stack gap={0}>
                          <Text
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"700"}
                          >
                            Update any opening balances you wish to change
                          </Text>
                          <Text
                            padding={"10px 0px 0px 0px"}
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"400"}
                          >
                            Using Excel or another spreadsheet editor, enter
                            your quantities and balances into the correct
                            columns.
                          </Text>
                          <Text
                            padding={"0px 0px 10px 0px"}
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"400"}
                          >
                            This import is limited to a maximum of 4,000 lines.
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      borderTop={"1px solid #e5e7eb"}
                      padding={"20px 0 10px 0"}
                    >
                      <Stack direction={"row"}>
                        {" "}
                        <Text
                          fontSize={"14px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          3.
                        </Text>
                        <Stack gap={0}>
                          <Text
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"700"}
                          >
                            Upload the updated template
                          </Text>
                          <Text
                            padding={"10px 0px"}
                            fontSize={"14px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"400"}
                          >
                            Make sure you save your updated template as .csv or
                            .txt.
                          </Text>
                          <Box>
                            <Button
                              backgroundColor={"transparent"}
                              borderRadius={"3px"}
                              border={"1px solid #dcdee4"}
                              fontSize={"13px"}
                              variant={"solid"}
                              textColor={"customColor.black7"}
                              padding={"12px 16px"}
                              fontWeight={600}
                              _hover={{
                                backgroundColor: "#fff",
                                color: "#3454D1",
                              }}
                              onClick={onOpen}
                              // onClick={() => {
                              //   if (fileInputRef.current) {
                              //     fileInputRef.current.click();
                              //   }
                              // }}
                            >
                              Browse
                            </Button>
                            <Modal isOpen={isOpen} onClose={onClose}>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  fontSize={"21px"}
                                  fontWeight={"700"}
                                >
                                  Upload items
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  <Stack
                                    border={"1px dashed #a6a9b0"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    padding={"20px"}
                                    textAlign={"center"}
                                    borderRadius={"3px"}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    position="relative"
                                  >
                                    {file ? (
                                      <>
                                        <Image
                                          src={URL.createObjectURL(file)}
                                          alt="Dropped file"
                                          maxWidth="100%"
                                          maxHeight="300px"
                                          minH={"300px"}
                                          borderRadius="3px"
                                        />
                                        <IconButton
                                          icon={<CloseIcon />}
                                          size="sm"
                                          colorScheme="red"
                                          position="absolute"
                                          top="10px"
                                          right="10px"
                                          onClick={handleDelete}
                                          aria-label="Delete Image"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Text
                                          fontSize={"15px"}
                                          _light={{
                                            color: "customColor.black7",
                                          }}
                                          marginBottom={"12px"}
                                        >
                                          Drag and drop file or select manually
                                        </Text>
                                        <Button
                                          backgroundColor={"transparent"}
                                          borderRadius={"3px"}
                                          border={"1px solid #dcdee4"}
                                          fontSize={"13px"}
                                          variant={"solid"}
                                          textColor={"customColor.black7"}
                                          padding={"12px 16px"}
                                          fontWeight={600}
                                          _hover={{
                                            backgroundColor: "#fff",
                                            color: "#3454D1",
                                          }}
                                          onClick={() => {
                                            if (fileInputRef.current) {
                                              fileInputRef.current.click();
                                            }
                                          }}
                                        >
                                          Select File
                                        </Button>
                                        <input
                                          type="file"
                                          ref={fileInputRef}
                                          style={{ display: "none" }}
                                          onChange={handleFileSelect}
                                        />
                                      </>
                                    )}
                                  </Stack>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    backgroundColor={"#fff"}
                                    borderRadius={"3px"}
                                    border={"1px solid #dcdee4"}
                                    fontSize={"13px"}
                                    h={"40px"}
                                    variant={"solid"}
                                    textColor={"#ea4d4d"}
                                    padding={"12px 16px"}
                                    marginRight={"8px"}
                                    fontWeight={600}
                                    _hover={{
                                      backgroundColor: "#fff",
                                    }}
                                    onClick={onClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    fontSize={"13px"}
                                    bgColor="#3454D1"
                                    h={"40px"}
                                    borderRadius={"3px"}
                                    borderWidth={"1px"}
                                    borderColor={"#3454d1!important"}
                                    variant={"solid"}
                                    padding={"12px 16px"}
                                    fontWeight={700}
                                    _hover={{
                                      bgColor: "#3454D1",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>

                            {/* <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                            /> */}
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack
        bottom={0}
        spacing={4}
        position={"sticky"}
        bg={"customColor.white"}
        borderTop={"1px solid #e5e7eb"}
        padding={"10px"}
        alignItems={"center"}
        direction={"row"}
        boxShadow={"2px 0px 4px rgba(15,34,58,.12)"}
        justifyContent={"space-between"}
      >
        <Button
          backgroundColor={"#fff"}
          borderRadius={"3px"}
          border={"1px solid #dcdee4"}
          fontSize={"13px"}
          variant={"solid"}
          textColor={"#ea4d4d"}
          padding={"12px 16px"}
          marginRight={"12px"}
          fontWeight={600}
          _hover={{
            backgroundColor: "#fff",
          }}
        >
          Cancel
        </Button>
        <Button
          fontSize={"13px"}
          bgColor="#3454D1"
          borderRadius={"3px"}
          borderWidth={"1px"}
          borderColor={"#3454d1!important"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            bgColor: "#3454D1",
          }}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}
