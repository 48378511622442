import { Box } from "@chakra-ui/react";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDashboardContext } from "../../Hooks/DashboardContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

type AttributeType = "gender" | "department" | "province" | "employeementType";
type CalculationType = "sum" | "average" | "median" | "min" | "max";

interface DynamicPieChartProps {
  attribute: AttributeType;
  calculation?: CalculationType;
  colors: string[]; // Array of colors or gradients for each slice
  fontStyle?: "normal" | "italic" | "oblique";
  fontWeight?: "normal" | "bold";
  fontSize?: number;
}

// Calculation logic to process the data based on the calculation type
const calculateValues = (
  values: number[],
  calculation: CalculationType
): number[] => {
  switch (calculation) {
    case "min":
      const minValue = Math.min(...values);
      return values.map((value) => (value === minValue ? value : 0));
    case "max":
      const maxValue = Math.max(...values);
      return values.map((value) => (value === maxValue ? value : 0));
    case "average":
      const avgValue = values.reduce((a, b) => a + b, 0);
      return values.map((value) =>
        parseFloat(((value * 100) / avgValue).toFixed(2))
      );
    case "median":
      values.sort((a, b) => a - b);
      const mid = Math.floor(values.length / 2);
      const medianValue =
        values.length % 2 !== 0
          ? values[mid]
          : (values[mid - 1] + values[mid]) / 2;
      return new Array(values.length).fill(medianValue);
    case "sum":
    default:
      return values;
  }
};

// Function to check if a color is a gradient
function isGradient(color: string): boolean {
  return color.includes("linear-gradient");
}

// Function to normalize the case of `rgb` and `rgba`
function normalizeColorCase(color: string): string {
  return color.replace(/RGB/gi, "rgb").replace(/RGBA/gi, "rgba");
}

// Function to fix gradients by adding a 100% stop if missing
function fixGradient(color: string): string {
  color = normalizeColorCase(color); // Normalize the case for `rgb` and `rgba`

  if (!isGradient(color)) return color; // If it's not a gradient, return the solid color

  // Parse the gradient to check if there's a 100% stop
  const lastStopRegex = /(\d+%)(?!.*\d+%)/; // Find the last percentage
  const has100PercentStop = lastStopRegex.test(color);

  if (!has100PercentStop) {
    // If there's no 100% stop, add it
    const lastColorRegex = /(rgba?\([\d\s,]+\))\s*\d+%?/; // Match the last color in the gradient
    const match = color.match(lastColorRegex);

    if (match) {
      const lastColor = match[1]; // Extract the last color
      return `${color}, ${lastColor} 100%)`; // Add the 100% stop
    }
  }
  return color; // If the gradient already has 100%, return it as is
}

// Function to extract both hex and rgba colors from the gradient string
function extractColorsFromGradient(gradientString: string): string[] {
  const colors: string[] = [];
  // Regex for rgba and rgb
  const regexRgb = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([0-9.]+))?\)/g;
  // Regex for hex colors
  const regexHex = /#([0-9a-f]{3,6})/gi;
  let matchRgb, matchHex;

  // Match rgb and rgba colors
  while ((matchRgb = regexRgb.exec(gradientString)) !== null) {
    const [_, r, g, b, a] = matchRgb;
    colors.push(a ? `rgba(${r},${g},${b},${a})` : `rgb(${r},${g},${b})`);
  }

  // Match hex colors
  while ((matchHex = regexHex.exec(gradientString)) !== null) {
    colors.push(matchHex[0]); // Add hex color directly
  }

  return colors;
}

// Function to create a gradient from the extracted colors
function createGradientFromColors(ctx: any, chartArea: any, colors: string[]) {
  const gradient = ctx.createLinearGradient(
    0,
    0,
    chartArea.width,
    chartArea.height
  );
  const colorStops = colors.length;

  colors.forEach((color, index) => {
    // Calculate the correct position for each color stop between 0 and 1
    const position = index / (colorStops - 1);
    gradient.addColorStop(position, color);
  });

  return gradient;
}

// Function to handle background color assignment for each slice
function getBackgroundColor(context: any, colors: string[], labels: string[]) {
  const chart = context.chart;
  const { ctx, chartArea } = chart;

  // If chart area is not yet available, use solid colors directly
  if (!chartArea) {
    return colors.slice(0, labels.length);
  }

  // Assign gradients or solid colors based on input
  return colors.map((color) => {
    // Normalize and fix the gradient
    const fixedColor = fixGradient(normalizeColorCase(color));

    if (isGradient(fixedColor)) {
      const extractedColors = extractColorsFromGradient(fixedColor);
      return createGradientFromColors(ctx, chartArea, extractedColors);
    }
    return fixedColor; // Return solid color
  });
}

export default function DynamicPieChart({
  attribute,
  calculation = "sum",
  colors = ["#54a0ff"], // Default fallback color
  fontStyle = "normal",
  fontWeight = "normal",
  fontSize = 12,
}: DynamicPieChartProps) {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();

  // Ensure the colors array is not empty
  const safeColors = colors.length > 0 ? colors : ["#54a0ff"];

  let labels: string[] = [];
  let dataValues: number[] = [];

  // Prepare data based on the attribute prop
  if (attribute === "gender") {
    labels = ["Male", "Female"];
    dataValues = [
      dashboardAnalytics?.maleGenderEmpCount || 0,
      dashboardAnalytics?.femaleGenderEmpCount || 0,
    ];
  } else if (attribute === "department") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInDepartments || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInDepartments || {}
    );
  } else if (attribute === "province") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInProvinces || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInProvinces || {}
    );
  } else if (attribute === "employeementType") {
    labels = ["Full Time", "Part Time", "Daily Wagers"];
    dataValues = [
      dashboardAnalytics?.fullTimeEmployees || 0,
      dashboardAnalytics?.partTimeEmployees || 0,
      dashboardAnalytics?.casualEmployees || 0,
    ];
  }

  // Apply the calculation logic (sum, average, median, etc.)
  const calculatedDataValues = calculateValues(dataValues, calculation);

  const data = {
    labels,
    datasets: [
      {
        label: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Distribution`,
        data: calculatedDataValues,
        backgroundColor: (context: any) =>
          getBackgroundColor(context, safeColors, labels),
        borderColor: safeColors.map((color) =>
          isGradient(color) ? "#000000" : "#fff"
        ),
        borderWidth: 1,
        hoverOffset: 10, // Adds spacing between slices on hover for 3D effect
        hoverBorderWidth: 2,
      },
    ],
  };

  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          padding: 20,
          font: {
            size: fontSize,
            style: fontStyle,
            weight: fontWeight,
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: fontSize,
          weight: fontWeight,
          style: fontStyle,
        },
        backgroundColor: "#fff", // White background for contrast
        borderColor: "#000", // Border around tooltip
        borderWidth: 1,
        titleColor: "#000", // Title text color
        bodyColor: "#000", // Body text color
        displayColors: false, // Remove color box in tooltip
      },
      title: {
        display: true,
        text: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Distribution`,
        font: {
          size: fontSize + 2,
          style: fontStyle,
          weight: fontWeight,
        },
      },
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      minW={"100%"}
      p="20px"
      overflowY="auto"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Pie
        data={data as any}
        options={options}
        style={{ width: "100%", height: "100%", alignSelf: "center" }}
      />
    </Box>
  );
}
