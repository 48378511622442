import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { PayRun } from "../../../Api/PayRun";
import { PayTemp } from "../../../Api/PayTemp";
interface AddLeavesModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountLeaves: Array<PayItemLeaveApi.LeaveById>;
  resetData: {
    payItemLeaveId: string;
    index?: number;
  };
  onPressOK: (data: {
    payItemId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum;
    category: PayTemp.PayTemplateItemTypeEnum;
    index?: number;
  }) => void;
}
export default function AddLeavesModal({
  isOpen,
  onClose,
  accountLeaves,
  onPressOK,
  resetData,
}: AddLeavesModalProps) {
  const form = useFormContext<PayRun.PayrunByEmpId>();
  const [payItemId, setPayItemId] = useState("");
  const [index, setIndex] = useState<number | undefined>(resetData.index);
  const [description, setDescription] = useState("");
  const handlePayItemChange = (newValue: any) => {
    setPayItemId(newValue?.id);
  };
  useEffect(() => {
    setPayItemId(resetData.payItemLeaveId);
    setIndex(resetData.index);
  }, [resetData]);
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          h="50px"
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.dark.250",
          }}
        >
          Add a Leave
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack maxW={"300px"} gap={0}>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"bold"}
            >
              Leaves
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={accountLeaves}
              value={{
                id: payItemId,
                name:
                  accountLeaves.find((account) => account.id === payItemId)
                    ?.name ?? "",
              }}
              onChange={handlePayItemChange}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onPressOK({
                payItemId,
                calculationType:
                  PayTemp.PayTemplateCalcTypeEnum.MANUALLY_RECOREDED_RATE,
                category: PayTemp.PayTemplateItemTypeEnum.LEAVE,
                index: index,
              });
              onClose();
            }}
          >
            Add
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
