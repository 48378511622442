import { axiosInstance } from "./axiosInstance-hris";

declare module AddEmployeeNotifications {
  type AddNotification = {
    employeeIds?: Array<string>;
    title?: string;
    message?: string;
    notificationUserGroupId?: string;
    action: string;
    scheduledTime?: string;
    scheduleDate?: string;
    type: string;
    id?: string;
  };
  type getNotification = {
    id: string;
    createdAt: string;
    updatedAt: string;
    title: string;
    message: string;
    recipients: Array<string>;
    employeeIds: Array<string>;
    businessId: string;
    attachments?: Array<string> | null;
    type: string;
    scheduledTime?: null | string;
  };
  type getAllNotifications = {
    data: Array<getNotification>;
    total: number;
  };
}
module AddEmployeeNotifications {
  const URL = "/notifications/on-demand";
  export enum NotificationScheduleType {
    Immediate = "Immediate",
    Schedule = "Schedule",
  }
  export enum NotificationTypes {
    SMS = "SMS",
    Email = "Email",
    Push = "Push",
  }
  export function AddNotification(
    data: AddNotification,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function GetAllNotifications(
    params: {
      page?: number;
      limit?: number;
    },
    success: (response: getAllNotifications) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function GetNotificationById(
    id: string,
    success: (response: getNotification) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateNotification(
    id: string,
    data: Partial<AddNotification>,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function DeleteNotification(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
}
export { AddEmployeeNotifications };
