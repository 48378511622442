import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { EmployeesReport } from "../../../Api/EmployeeReport";
interface GroupingProps {
  setSelectedGroupBy: Dispatch<SetStateAction<any>>;

  groupingBtn: {
    groupingItem: {
      label: string;
      key?:
        | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
        | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
        | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
        | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
        | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
        | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
        | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
        | EmployeesReport.GroupingLeaveRequestReport["groupBy"];
    }[];
  }[];
}
export function Grouping({ setSelectedGroupBy, groupingBtn }: GroupingProps) {
  const [selectedGrouping, setSelectedGrouping] = useState({
    radio: "Group by",
    menuItem: "None",
  });

  const handleOptionSelectGrouping = (optionValue: string) => {
    setSelectedGrouping((prevSelectedGrouping) => ({
      ...prevSelectedGrouping,
      radio: optionValue,
      menuItem: "None", // Reset menuItem when radio option changes
    }));
  };

  const { radio, menuItem } = selectedGrouping;

  return (
    <Stack margin={"20px 0px 0px"} padding={"0px 0px 0px 10px"}>
      <HStack gap={0}>
        <Text
          fontSize="13px"
          display={"block"}
          fontWeight="700"
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Grouping/Summarising
        </Text>
      </HStack>

      <Box>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            width={"100%"}
            textAlign={"start"}
            h={"36px"}
            _light={{
              bgColor: "customColor.white",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            color={"black"}
            fontWeight={"400"}
            border={"1px solid #b7bec5"}
            borderRadius={"5px"}
            _hover={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid #a6a9b0",
            }}
            _active={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid #a6a9b0",
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {`${radio} ${menuItem === "None" ? "" : `by ${menuItem}`}`}
          </MenuButton>
          <MenuList overflowY="auto" zIndex={"overlay"} minW={"300px"}>
            <RadioGroup
              defaultValue={radio}
              onChange={(value) => handleOptionSelectGrouping(value)}
            >
              <MenuItem
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
                h={"38.5px"}
              >
                <Radio value="Group by" size="lg">
                  <Text
                    fontSize={"15px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Group by
                  </Text>
                </Radio>
              </MenuItem>

              <MenuItem
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
                h={"38.5px"}
              >
                <Radio value="Summarising by" size="lg">
                  <Text
                    fontSize={"15px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                  >
                    Summarising by
                  </Text>
                </Radio>
              </MenuItem>
            </RadioGroup>
            <Stack
              borderBottom={"1px solid #ccced2"}
              marginTop={"12px"}
              marginBottom={"12px"}
            />
            {groupingBtn.map((item, i) => {
              return (
                <MenuGroup
                  fontSize={"13px"}
                  color={"#404756"}
                  fontWeight={"normal"}
                  whiteSpace={"nowrap"}
                  key={i}
                >
                  {item.groupingItem.map((list, j) => {
                    return (
                      <MenuItem
                        key={j}
                        padding={"0px 12px"}
                        _hover={{
                          backgroundColor: "rgba(0,10,30,.1)",
                        }}
                        // onClick={() => handleOptionSelectGrouping("menuItem", item)}
                        onClick={() => {
                          setSelectedGroupBy(list.label);
                          handleOptionSelectGrouping(list.label);
                        }}
                      >
                        <Text
                          padding={"8px"}
                          margin={"0px 12px 0px 0px"}
                          _light={{
                            color: "customColor.black5",
                          }}
                          _dark={{
                            color: "customColor.pearlWhite",
                          }}
                          fontSize={"15px"}
                        >
                          {list.label}
                        </Text>
                      </MenuItem>
                    );
                  })}
                  {i !== groupingBtn.length - 1 && <MenuDivider />}
                </MenuGroup>
              );
            })}
          </MenuList>
        </Menu>
      </Box>
    </Stack>
  );
}
