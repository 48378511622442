import { Td, Tr } from "@chakra-ui/react";

interface TableDataProps {
  property: string;
  value: string | number | undefined | null;
}
export default function TableData({ property, value }: TableDataProps) {
  return (
    <>
      <Tr border="1px" borderColor="borders.tableBorder">
        <Td
          padding="15px 15px 15px 30px"
          fontSize="13.44px"
          color={"customColor.black7"}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight={1.5}
          fontWeight={500}
          textAlign="center"
          w="25%"
          borderBottom={"1px"}
          borderColor="borders.tableBorder"
        >
          {property}
        </Td>
        <Td
          padding="15px 15px 15px 30px"
          fontSize="13.44px"
          color={"customColor.black7"}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight={1.5}
          fontWeight={500}
          textAlign="center"
          borderLeft="1px"
          borderBottom={"1px"}
          borderColor="borders.tableBorder"
          w="75%"
          whiteSpace="normal"
          overflowWrap="break-word"
        >
          {value}
        </Td>
      </Tr>
    </>
  );
}
