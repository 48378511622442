import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";
import { ProductAndService } from "../../Api/ProductAndServices";

interface AddItemProps {
  isOpen: boolean;
  onClose: () => void;
}
export function AddItem({ isOpen, onClose }: AddItemProps) {
  const form = useFormContext<ProductAndService.AddProductAndService>();
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  const [inventoryAccounts, setInventoryAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  const [taxRateData, setTaxRateData] = useState<
    BusinessTaxRate.FetchTaxRate[]
  >([]);
  const watchPurchase = useWatch({
    control: form.control,
    name: "purchase",
  });
  const watchSell = useWatch({
    control: form.control,
    name: "sell",
  });
  const watchTrack = useWatch({
    control: form.control,
    name: "trackItem",
  });
  useEffect(() => {
    form.setValue("sell", true);
    form.setValue("purchase", true);
  }, []);
  useEffect(() => {
    ChartOfAccounts.GetAllAccounts(
      {
        category: "",

        page: 1,
        limit: 100,
      },
      (res) => {
        setChartOfAccounts(res.data);
        const inventoryAccounts = res.data.filter(
          (account: any) => account.accountType === "INVENTORY"
        );
        setInventoryAccounts(inventoryAccounts);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRateData(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, []);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <FormProvider {...form}>
      <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent minW={"800px"}>
          <ModalHeader
            fontSize="var(--x-font-size-large, 1.0625rem)"
            _light={{
              color: "customColor.black7",
            }}
            fontWeight="700"
            padding={"12px 20px"}
          >
            New Item
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody p={0}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              padding={"20px 8px"}
              gap={0}
            >
              <Stack padding={"0px 12px"} flex={1}>
                <FormControl>
                  <FormLabel
                    mb={0}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize="13px"
                    fontWeight="400"
                    paddingBottom={"4px"}
                  >
                    <b>Code</b> (required)
                  </FormLabel>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Code is required",
                      },
                    }}
                    control={form.control}
                    name="code"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "39.6px",
                          fontSize: "15px",
                          variant: "outline",
                          type: "number",
                          padding: "12px 15x",
                          borderTopRadius: "3px",
                          // value: watchPayPeriod,
                        }}
                        placeholder="Code"
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack direction={"row"} padding={"0px 12px"} flex={2}>
                <FormControl>
                  <FormLabel
                    mb={0}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize="13px"
                    fontWeight="400"
                    paddingBottom={"4px"}
                  >
                    <b>Name</b>
                  </FormLabel>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    }}
                    control={form.control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "39.6px",
                          fontSize: "15px",
                          variant: "outline",
                          type: "text",
                          padding: "12px 15x",
                          borderTopRadius: "3px",
                          // value: watchPayPeriod,
                        }}
                        placeholder="Code"
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Stack
              borderTop={"1px solid #e5e7eb"}
              marginTop={"32px"}
              padding={"20px"}
              gap={0}
            >
              <Controller
                control={form.control}
                name="trackItem"
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Agree with T&Cs and privacy policy",
                //   },
                // }}
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    {...field}
                    size={"lg"}
                    isInvalid={error !== undefined}
                    isChecked={value}
                    mr={5}

                    // onChange={() => handleCheckboxChange("option1")}
                  >
                    <Text
                      fontSize="16px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"700"}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                    >
                      Track inventory item
                    </Text>
                  </Checkbox>
                )}
              />
              <Text
                _light={{ color: "customColor.black7" }}
                fontSize={"15px"}
                paddingTop={"12px"}
              >
                Track the quantity and value of stock on hand. This option is
                suitable for organisations with less than 4,000 products or
                services, who purchase items before they are sold, and who
                calculate the average cost of items.
                <Link color={"#3454d1"}>
                  Learn more about tracked inventory
                </Link>
              </Text>

              {watchTrack && (
                <Stack gap={0}>
                  <Stack
                    alignItems={"flex-start"}
                    gap={0}
                    direction={"row"}
                    margin={"16px 0px 8px"}
                    padding={"12px 16px"}
                    bg={"#f2f3f4"}
                    boxShadow={"0 0 0 1px #000a1e33"}
                    borderRadius={"3px"}
                  >
                    <Icon
                      as={FaInfoCircle}
                      color="customColor.black7"
                      fontSize={"20px"}
                      paddingTop={"5px"}
                    />

                    <Text
                      paddingLeft={"5px"}
                      fontSize={"15px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Items can’t be untracked once they appear on a
                      transaction. This includes the opening balance, an
                      adjustment, or a bill or an invoice.
                    </Text>
                  </Stack>
                  <Stack gap={0} direction={"row"}>
                    <Box flex={1}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          mt={"12px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Inventory Asset account
                        </FormLabel>
                        <Controller
                          control={form.control}
                          name="inventoryAccount"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder=""
                              isInvalid={fieldState.invalid}
                              ErrorMessage=""
                              withValidation
                              options={inventoryAccounts?.map((acc: any) => ({
                                value: acc.id,
                                label: `${acc.code}: ${acc.name}`, // Including code before name
                              }))}
                              value={inventoryAccounts
                                ?.map((acc: any) => ({
                                  value: acc.id,
                                  label: `${acc.code}: ${acc.name}`, // Including code before name
                                }))
                                .find(
                                  (option: any) => option.value === field.value
                                )}
                              onChange={(newValue: any) =>
                                field.onChange(newValue?.value)
                              }
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                    <Box flex={2}></Box>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack
              borderTop={"1px solid #e5e7eb"}
              marginTop={"32px"}
              padding={"20px"}
              gap={0}
            >
              <Controller
                control={form.control}
                name="purchase"
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Agree with T&Cs and privacy policy",
                //   },
                // }}
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    {...field}
                    size={"lg"}
                    isInvalid={error !== undefined}
                    isChecked={value}
                    isDisabled={watchTrack ? true : false}
                    mr={5}
                    // onChange={() => handleCheckboxChange("option1")}
                  >
                    <Text
                      fontSize="16px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"700"}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                    >
                      Purchase
                    </Text>
                  </Checkbox>
                )}
              />
              <Text
                _light={{ color: "customColor.black7" }}
                fontSize={"15px"}
                paddingTop={"12px"}
              >
                Add item to bills, purchase orders, and other purchase
                transactions
              </Text>
              {watchPurchase && (
                <Stack gap={0}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    marginBottom={"32px"}
                  >
                    {" "}
                    <Stack paddingTop={"12px"} flex={1}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Cost price
                        </FormLabel>
                        <Controller
                          control={form.control}
                          name="purchaseCost"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                h: "39.6px",
                                fontSize: "15px",
                                variant: "outline",
                                type: "number",
                                padding: "12px 15x",
                                borderTopRadius: "3px",
                                // value: watchPayPeriod,
                              }}
                              placeholder="Code"
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <Stack paddingTop={"12px"} flex={1} paddingLeft={"12px"}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Purchase account
                        </FormLabel>
                        <Controller
                          control={form.control}
                          name="purchaseAccount"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder="Select chart of account"
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Select chart of account"
                              withValidation
                              options={chartOfAccounts?.map((acc: any) => ({
                                value: acc.id,
                                label: `${acc.code}: ${acc.name}`, // Including code before name
                              }))}
                              value={chartOfAccounts
                                ?.map((acc: any) => ({
                                  value: acc.id,
                                  label: `${acc.code}: ${acc.name}`, // Including code before name
                                }))
                                .find(
                                  (option: any) => option.value === field.value
                                )}
                              onChange={(newValue: any) =>
                                field.onChange(newValue?.value)
                              }
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <Stack paddingTop={"12px"} flex={1} paddingLeft={"12px"}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Tax Rate
                        </FormLabel>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Tax is required",
                            },
                          }}
                          control={form.control}
                          name="purchaseTaxRate"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder="Select Tax type"
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Select Tax type"
                              withValidation
                              options={taxRateData}
                              value={
                                field.value
                                  ? {
                                      id: field.value,
                                      name:
                                        taxRateData.find(
                                          (taxRateData) =>
                                            taxRateData.id === field.value
                                        )?.name ?? "",
                                    }
                                  : undefined
                              }
                              onChange={(val: any) => {
                                field.onChange(val?.id);
                              }}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.id}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                  <FormControl>
                    <FormLabel
                      mb={0}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize="13px"
                      fontWeight="700"
                      paddingBottom={"4px"}
                    >
                      Description
                    </FormLabel>
                    <Controller
                      control={form.control}
                      name="purchaseDescription"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          //   validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "39.6px",
                            fontSize: "15px",
                            variant: "outline",
                            type: "text",
                            padding: "12px 15x",
                            borderTopRadius: "3px",
                            // value: watchPayPeriod,
                          }}
                          placeholder="Description"
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              )}
            </Stack>
            <Stack
              borderTop={"1px solid #e5e7eb"}
              marginTop={"32px"}
              padding={"20px"}
              gap={0}
            >
              <Controller
                control={form.control}
                name="sell"
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Agree with T&Cs and privacy policy",
                //   },
                // }}
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    {...field}
                    size={"lg"}
                    isInvalid={error !== undefined}
                    isChecked={value}
                    isDisabled={watchTrack ? true : false}
                    mr={5}
                    // onChange={() => handleCheckboxChange("option1")}
                  >
                    <Text
                      fontSize="16px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"700"}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                    >
                      Sell
                    </Text>
                  </Checkbox>
                )}
              />
              <Text
                _light={{ color: "customColor.black7" }}
                fontSize={"15px"}
                paddingTop={"12px"}
              >
                Add item to invoices, quotes, and other sales transactions
              </Text>
              {watchSell && (
                <Stack gap={0}>
                  {" "}
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    marginBottom={"32px"}
                  >
                    {" "}
                    <Stack paddingTop={"12px"} flex={1}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Sale price
                        </FormLabel>
                        <Controller
                          control={form.control}
                          name="sellSale"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                h: "39.6px",
                                fontSize: "15px",
                                variant: "outline",
                                type: "number",
                                padding: "12px 15x",
                                borderTopRadius: "3px",
                                // value: watchPayPeriod,
                              }}
                              placeholder="Code"
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <Stack paddingTop={"12px"} flex={1} paddingLeft={"12px"}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Sale account
                        </FormLabel>
                        <Controller
                          control={form.control}
                          name="sellSaleAccount"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder="Select chart of account"
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Select chart of account"
                              withValidation
                              options={chartOfAccounts?.map((acc: any) => ({
                                value: acc.id,
                                label: `${acc.code}: ${acc.name}`, // Including code before name
                              }))}
                              value={chartOfAccounts
                                ?.map((acc: any) => ({
                                  value: acc.id,
                                  label: `${acc.code}: ${acc.name}`, // Including code before name
                                }))
                                .find(
                                  (option: any) => option.value === field.value
                                )}
                              onChange={(newValue: any) =>
                                field.onChange(newValue?.value)
                              }
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <Stack paddingTop={"12px"} flex={1} paddingLeft={"12px"}>
                      <FormControl>
                        <FormLabel
                          mb={0}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize="13px"
                          fontWeight="700"
                          paddingBottom={"4px"}
                        >
                          Tax Rate
                        </FormLabel>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Tax is required",
                            },
                          }}
                          control={form.control}
                          name="sellTaxRate"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              placeholder="Select Tax type"
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Select Tax type"
                              withValidation
                              options={taxRateData}
                              value={
                                field.value
                                  ? {
                                      id: field.value,
                                      name:
                                        taxRateData.find(
                                          (taxRateData) =>
                                            taxRateData.id === field.value
                                        )?.name ?? "",
                                    }
                                  : undefined
                              }
                              onChange={(val: any) => {
                                field.onChange(val?.id);
                              }}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.id}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                  <FormControl>
                    <FormLabel
                      mb={0}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize="13px"
                      fontWeight="700"
                      paddingBottom={"4px"}
                    >
                      Description
                    </FormLabel>
                    <Controller
                      control={form.control}
                      name="sellDescription"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          //   validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "39.6px",
                            marginBottom: "32px",
                            fontSize: "15px",
                            variant: "outline",
                            type: "text",
                            padding: "12px 15x",
                            borderTopRadius: "3px",
                            // value: watchPayPeriod,
                          }}
                          placeholder="Description"
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Save & add another
            </Button>
            <Button
              ml="8px"
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
            >
              {" "}
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
