import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ProductivityTracker } from "../../../Api/ProductivityTracker";

interface DeleteTimeSheetProps {
  isOpen: boolean;
  onClose: () => void;
  timesheetId: string;
}
export function DeleteTimeSheet({
  isOpen,
  onClose,
  timesheetId,
}: DeleteTimeSheetProps) {
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"350px"}>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text
            fontSize={"18px"}
            padding={"15px 0px"}
            color={"customColor.black7"}
          >
            Delete Timesheet
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack
            color={"customColor.gray.800"}
            _dark={{
              color: "customColor.dark.250",
            }}
            fontSize={"12px"}
            gap={0}
          >
            <Text fontWeight={"normal"} color={"customColor.black7"}>
              Are you sure you want to delete this timesheet?
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              variant="solid"
              fontSize={"13px"}
              margin={"0px 0px 0px 10px"}
              padding={"10px 15px"}
              borderRadius={"3px"}
              border={"1px solid #FDEDED"}
              bg={"#FDEDED"}
              color={"#EA4D4D"}
              minW={"75px"}
              _hover={{
                border: "1px solid #EA4D4D",
                bg: "#EA4D4D",
                color: "#fff",
              }}
              onClick={() => {
                ProductivityTracker.DeleteTimeSheet(
                  timesheetId,
                  (res) => {
                    toast({
                      title: "Timesheet Deleted Successfully",
                      status: "success",
                      description: res,
                    });
                    onClose();
                    navigate("/app/payroll/Timesheets");
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      status: "success",
                      description: err,
                    });
                  }
                );
              }}
            >
              Delete
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
