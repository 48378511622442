import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import update from "immutability-helper";
import moment from "moment";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AiFillCopy } from "react-icons/ai";
import { BiSolidPaste } from "react-icons/bi";
import { FaPaste } from "react-icons/fa";
import { MdAddBox } from "react-icons/md";
import { DragContainer } from "../../../../Common/Dnd/drag-container";
import { Draggable } from "../../../../Common/Dnd/draggable";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Shifts } from "../../../API/Shifts";
import ShiftTag from "../Common/ShiftTag";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface MonthProps {
  currentMonth: moment.Moment;
  selectedTimestamp: (timestamp: number) => void;
  selectedShiftId: (shiftId: string) => void;
  setViewShiftsIds: (ids: string[]) => void;
}
export default function Month({
  currentMonth,
  selectedTimestamp,
  selectedShiftId,
  setViewShiftsIds,
}: MonthProps) {
  const startDate = moment(currentMonth).startOf("month");
  const endDate = moment(currentMonth).endOf("month");
  const daysInMonth = endDate.diff(startDate, "days") + 1;
  const firstDayOfMonth = startDate.day();
  const lastDayofMonth = 6 - endDate.day();
  const dates = Array.from({ length: daysInMonth }, (_, index) =>
    moment(startDate).add(index + 1, "days")
  );

  const prevDates = Array.from({ length: firstDayOfMonth }, (_, i) =>
    moment(startDate).subtract(i, "days")
  ).reverse();
  const upComingDates = Array.from({ length: lastDayofMonth }, (_, i) =>
    moment(endDate).add(i + 2, "days")
  );

  const days = prevDates.concat(dates.concat(upComingDates));
  const weeksInMonth = Math.ceil((daysInMonth + firstDayOfMonth) / 7);
  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const {
    copiedShifts,
    locations,
    setLastUpdated,
    lastUpdated,
    monthlyCopiedShifts,
    setMonthlyCopiedShifts,
  } = useScheduleContext();
  const toast = useToast();
  const [shiftsData, setShiftsData] = useState<
    Array<Shifts.FetchByDatesResponse>
  >([]);
  const findShiftsForDate = (date: any) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const shiftsForDate = shiftsData.find(
      (shift) => shift.date === formattedDate
    );
    return shiftsForDate ? shiftsForDate.shifts : [];
  };
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const [selectedTime, setSelectedTime] = useState<string>();
  useEffect(() => {
    Shifts.FetchShiftsByDate(
      {
        startDate: currentMonth.startOf("month").format("YYYY-MM-DD"),
        endDate: currentMonth.endOf("month").format("YYYY-MM-DD"),
        employeeId: selectedEmployee,
        periodOfDay: selectedTime,
      },
      (allShifts) => {
        setShiftsData(allShifts);
        const shiftIds = allShifts.flatMap((shift) =>
          shift.shifts.map((s) => s.id)
        );
        setViewShiftsIds(shiftIds);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);
  const { employeesList } = useBusinessContext();
  const options: Array<{
    label: string;
    value: string;
  }> = [
    {
      label: "AM",
      value: "AM",
    },
    {
      label: "PM",
      value: "PM",
    },
  ];
  return (
    <DndProvider backend={HTML5Backend}>
      <Stack spacing="2px" backgroundColor="#f3f6f9" padding={"0px"} w={"100%"}>
        <Stack
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.gray.400",
          }}
          borderTop={"1px solid #cfd2d4"}
          borderBottom={"1px solid #dadddf"}
        >
          <Container
            justifyContent="center"
            alignItems="center"
            p="0px"
            maxW={"100%"}
            borderRadius="md"
          >
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              borderRadius={"4px"}
              margin={"10px 1px 10px"}
              border="1px solid #f6f6f6"
              padding={"7.52px 12px"}
              boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              gap={0}
            >
              <Stack margin={"5px 0px 0px 0px"} direction={"row"}>
                <Stack w="20%" gap={0}>
                  <Text
                    fontSize={"13.44px"}
                    margin={"0px 0px 5px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"bold"}
                  >
                    Employee/s
                  </Text>

                  <ReactSelect
                    placeholder=""
                    options={employeesList?.map((employee: any) => ({
                      value: employee.id,
                      label: `${employee.firstName} ${employee.lastName}`,
                    }))}
                    value={
                      selectedEmployee
                        ? {
                            value: selectedEmployee,
                            label: `${
                              employeesList?.find(
                                (employee) => employee.id === selectedEmployee
                              )?.firstName
                            } ${
                              employeesList?.find(
                                (employee) => employee.id === selectedEmployee
                              )?.lastName
                            }`,
                          }
                        : ""
                    }
                    onChange={(newValue: any) => {
                      setSelectedEmployee(newValue?.value);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                </Stack>
              </Stack>

              <Stack alignItems={"flex-end"} padding={"10px"}>
                <Stack direction={"row"}>
                  <Button
                    backgroundColor={"transparent"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      setSelectedEmployee("");
                      setSelectedTime("");
                      setLastUpdated(Date.now());
                    }}
                  >
                    Reset Filter
                  </Button>
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    onClick={() => {
                      setLastUpdated(Date.now());
                    }}
                  >
                    Apply Filter
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <TableContainer backgroundColor="white" overflowY="auto">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr>
                {daysOfWeek.map((day, i) => {
                  return (
                    <Th p={0} borderColor="borders.accent" key={i} w="14.2%">
                      <Stack
                        padding={"8px 26px 8px 15px"}
                        borderLeft="1px"
                        color={"customColor.black7"}
                        borderColor="borders.accent"
                        textAlign="center"
                      >
                        <Text
                          fontSize="12px"
                          fontWeight={500}
                          color={"customColor.black7"}
                          padding={"8px 12px"}
                          textTransform="uppercase"
                        >
                          {day}
                        </Text>
                      </Stack>
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {Array.from({ length: weeksInMonth }).map((_, i) => {
                return (
                  <Tr key={i}>
                    {daysOfWeek.map((_, k) => {
                      const date = days[i * 7 + k];
                      const shiftsForDay = findShiftsForDate(date);
                      return (
                        <Td
                          key={k}
                          p={0}
                          role="group"
                          borderLeft="1px"
                          color={"customColor.black7"}
                          borderColor="borders.accent"
                        >
                          <DragContainer<
                            Shifts.ShiftData & {
                              dateIndex: number;
                              shiftIndex: number;
                            }
                          >
                            accept="SHIFT"
                            onDrop={(shift: any) => {
                              const dateIndex = shiftsData.findIndex(
                                (s) => s.date === date.format("YYYY-MM-DD")
                              );
                              shift.shiftStartDate = date.format("YYYY-MM-DD");

                              setShiftsData(
                                update(shiftsData, {
                                  ...(dateIndex === -1
                                    ? {
                                        [shiftsData.length]: {
                                          $set: {
                                            date: date.format("YYYY-MM-DD"),
                                            shifts: [shift],
                                          },
                                        },
                                      }
                                    : {
                                        [dateIndex]: {
                                          shifts: {
                                            $push: [shift],
                                          },
                                        },
                                      }),

                                  [shift.dateIndex]: {
                                    shifts: {
                                      $splice: [[shift.shiftIndex, 1]],
                                    },
                                  },
                                })
                              );

                              Shifts.UpdateShift(
                                shift.id,
                                {
                                  shiftStartDate: date.format("YYYY-MM-DD"),
                                  shiftEndDate: date.format("YYYY-MM-DD"),
                                },
                                (success) => {
                                  toast({
                                    title: "Shift Updated",
                                    status: "success",
                                  });
                                  // setLastUpdated(Date.now());
                                },
                                (error) => {
                                  toast({
                                    title: "Error",
                                    description: error,
                                    status: "error",
                                  });
                                }
                              );
                            }}
                          >
                            <Box
                              minW={"200px"}
                              minH={"200%"}
                              h={"100%"}
                              textAlign="right"
                              justifyItems={"flex-end"}
                              overflowY="auto"
                              padding={"8px 12px"}
                            >
                              <Box
                                position="sticky"
                                top="0px"
                                alignItems={"flex-start"}
                                h={"100%"}
                                backgroundColor="white"
                                zIndex={5}
                              >
                                <HStack
                                  alignItems={"flex-start"}
                                  h={"100%"}
                                  display={
                                    shiftsForDay?.length > 0 ? "flex" : "none"
                                  }
                                  _groupHover={{
                                    display: "flex",
                                  }}
                                >
                                  <Tooltip label="Add shift" fontSize="md">
                                    <IconButton
                                      aria-label="Add-button"
                                      icon={<MdAddBox />}
                                      size="xs"
                                      variant="ghost"
                                      onClick={() => {
                                        const timestamp = date
                                          .clone()
                                          .set({
                                            hour: 0,
                                            minute: 0,
                                            second: 0,
                                          })
                                          .unix();
                                        selectedTimestamp(timestamp);
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    label="Copy all shifts"
                                    fontSize="md"
                                  >
                                    <IconButton
                                      aria-label="copy-all"
                                      icon={<AiFillCopy />}
                                      size="xs"
                                      variant="ghost"
                                      onClick={() => {
                                        if (setMonthlyCopiedShifts) {
                                          setMonthlyCopiedShifts({
                                            copyFrom: {
                                              startDate:
                                                date.format("YYYY-MM-DD"),
                                              endDate:
                                                date.format("YYYY-MM-DD"),
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    label="Paste all shifts"
                                    fontSize="md"
                                  >
                                    <IconButton
                                      aria-label="paste-all"
                                      icon={<FaPaste />}
                                      size="xs"
                                      variant="ghost"
                                      onClick={() => {
                                        if (monthlyCopiedShifts) {
                                          Shifts.CopyShiftsBulk(
                                            {
                                              copyFrom: {
                                                startDate: monthlyCopiedShifts
                                                  ?.copyFrom
                                                  ?.startDate as string,
                                                endDate: monthlyCopiedShifts
                                                  ?.copyFrom?.endDate as string,
                                              },
                                              copyTo: {
                                                startDate:
                                                  date.format("YYYY-MM-DD"),
                                                endDate:
                                                  date.format("YYYY-MM-DD"),
                                              },
                                            },
                                            () => {
                                              toast({
                                                title: "Shifts Pasted",
                                                status: "success",
                                              });
                                              setLastUpdated(Date.now());
                                            },
                                            () => {
                                              toast({
                                                title: "Error",
                                                description: "Failed to paste",
                                                status: "error",
                                              });
                                            }
                                          );
                                        } else {
                                          toast({
                                            title: "Error",
                                            description: "No shifts copied",
                                            status: "error",
                                          });
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip label="Paste" fontSize="md">
                                    <IconButton
                                      aria-label="paste"
                                      icon={<BiSolidPaste />}
                                      size="xs"
                                      variant="ghost"
                                      onClick={() => {
                                        if (copiedShifts) {
                                          Shifts.CopyPasteShift(
                                            {
                                              copyAll: copiedShifts.copyAll,
                                              copyDate: copiedShifts.copyDate,
                                              copyFromEmployeeId:
                                                copiedShifts.copyFromEmployeeId,
                                              pasteDate:
                                                date.format("YYYY-MM-DD"),
                                              copyToEmployeeId:
                                                copiedShifts.copyFromEmployeeId,
                                              shiftId: copiedShifts.shiftId,
                                            },
                                            () => {
                                              toast({
                                                title: "Shift Pasted",
                                                status: "success",
                                              });
                                              setLastUpdated(Date.now());
                                            },
                                            () => {
                                              toast({
                                                title: "Error",
                                                description: "Failed to paste",
                                                status: "error",
                                              });
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                </HStack>
                                <Text
                                  minW="20px"
                                  fontSize="14px"
                                  fontWeight={400}
                                  alignSelf="end"
                                  flex={1}
                                  color={
                                    date.isSame(currentMonth, "month")
                                      ? "#373857"
                                      : "gray.400"
                                  }
                                  px="8px"
                                  py="5px"
                                  lineHeight={1.5}
                                >
                                  {date?.format("D")}
                                </Text>
                              </Box>
                              {shiftsForDay &&
                                shiftsForDay?.map((shift, shiftIndex) => (
                                  <Draggable<
                                    Shifts.ShiftData & {
                                      dateIndex: number;
                                      shiftIndex: number;
                                    }
                                  >
                                    item={{
                                      ...shift,
                                      dateIndex: shiftsData.findIndex(
                                        (s) =>
                                          s.date === date.format("YYYY-MM-DD")
                                      ),
                                      shiftIndex,
                                    }}
                                    type="SHIFT"
                                  >
                                    <ShiftTag
                                      key={shift.id}
                                      shift={shift}
                                      onClicked={() => {
                                        selectedShiftId(shift.id);
                                      }}
                                    />
                                  </Draggable>
                                ))}
                            </Box>
                          </DragContainer>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </DndProvider>
  );
}
