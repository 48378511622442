import { axiosInstance } from "./axiosInstance-hris";
declare module NextOfKinApi {
  type AddNextOfKin = {
    id?: string;
    empId: string;
    name: string;
    cnic: string;
    address: string;
    relationship: string;
    contact: string;
    shareInPercent: number;
  };
  type FetchNextOfKin = {
    id: string;

    empId: string;
    name: string;
    cnic: string;
    address: string;
    relationship: string;
    contact: string;
    shareInPercent: number;
  };
}
module NextOfKinApi {
  const URL = "/employee-next-of-kins";
  export function PostNextOfKin(
    data: AddNextOfKin,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetNextOfKinDetails(
    id: string,
    success: (response: Array<FetchNextOfKin>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateNextOfKin(
    id: string,
    data: Partial<AddNextOfKin>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteNextOfKin(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}

export { NextOfKinApi };
