import { HStack, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";

const options = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

interface ListLimitProps {
  totalCount?: number;
}

export default function ListLimit({ totalCount }: ListLimitProps) {
  const form = useFormContext();
  const limit = form.getValues("pagination.limit");
  const [selectValue, setSelectedValue] = useState();
  return (
    <HStack spacing={0} justify="end">
      <Stack direction={"row"} alignItems={"center"} marginBottom={"8px"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"14.4px"}
        >
          Show
        </Text>
        <Controller
          name="pagination.limit"
          control={form.control}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              value={options.find((option) => option.value === limit)}
              onChange={(selectedOption) => {
                field.onChange(selectedOption && selectedOption.value);
                form.setValue("pagination.page", 1); // Reset page number to 1
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: "30px",
                  fontSize: "14.4px",
                  height: "30px",
                  borderRadius: "4px",
                  border: "1px solid #E2E8F0",
                  padding: "0 4px",
                  _hover: { borderColor: "#CBD5E0" },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "0 6px",
                }),
                input: (provided) => ({
                  ...provided,
                  margin: "0",
                  padding: "0",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: "0",
                }),
                menu: (provided) => ({
                  ...provided,
                  minWidth: "150px",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                }),
              }}
            />
          )}
        />
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"14.4px"}
        >
          entries
        </Text>
      </Stack>
    </HStack>
  );
}
