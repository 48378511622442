import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayRun } from "../../../Api/PayRun";

interface PostPayRunProps {
  isOpen: boolean;
  onClose: () => void;
  payrunId?: string;
  nextPayDate?: string;
  endDate?: string;
  calenderName?: string;
  note: string;
}

export function PostPayRun({
  isOpen,
  onClose,
  payrunId,
  calenderName,
  endDate,
  nextPayDate,
  note,
}: PostPayRunProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"380px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"15px 5px 15px 15px"}
          fontWeight={"700"}
          lineHeight={"1.75rem"}
          h={"52px"}
        >
          Post Pay Run
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            color: "customColor.black7",
          }}
          border={"1px solid #E5E5E5"}
          fontSize={"12px"}
        >
          Are you sure you want to post this pay run?
        </ModalBody>

        <ModalFooter padding={"0px"}>
          <ButtonGroup
            size="sm"
            marginTop="10px"
            marginBottom={"10px"}
            marginRight={"10px"}
          >
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                if (payrunId)
                  PayRun.PostPayrun(
                    payrunId,
                    (success) => {
                      navigate(`/app/payroll/postedPayRun/${payrunId}`, {
                        state: {
                          nextPayDate: moment(nextPayDate).format("D MMM YYYY"),
                          endDate: endDate,
                          calenderName: calenderName,
                          status: "POSTED",
                        },
                      });
                      if (note) {
                        PayRun.UpdateNotes(
                          payrunId,
                          { note: note },
                          (response) => {
                            toast({
                              title: "Pay Run Posted",
                              status: "success",
                            });
                          },
                          (err) => {
                            toast({
                              description: err,
                              title: "Error Updating Notes",
                              status: "error",
                            });
                          }
                        );
                      } else {
                        toast({
                          title: "Pay Run Posted",
                          status: "success",
                        });
                      }
                      onClose();
                    },
                    (err) => {
                      toast({
                        description: err,
                        title: "Error Posting Pay Run",
                        status: "error",
                      });
                    }
                  );
              }}
            >
              Yes
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              No
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
