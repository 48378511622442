import { axiosInstance } from "./axiosInstance-hris";

declare module UploadAttachments {
  type ProfilePictureUpload = {
    doc_Type: string;
    file: File;
    employeeId: string;
  };
  type PostDocument = {
    fileName: string;
    fileUrl: string;
    documentType: string;
  };
  type Document = {
    id: string;
    fileName: string;
    fileUrl: string;
    empId: string;
    documentType: string;
    createdAt?: string;
  };
  type requiredDocument = {
    title: string;
    isUploaded: boolean;
    docType: string;
    remainingDocuments: number;
    maxDocs: number;
  };
  type FetchDocuments = {
    requiredDocuments: Array<requiredDocument>;
    uploadedDocuments: Array<Document>;
  };
}
module UploadAttachments {
  const API_URL = "/attachments/upload";
  const API_URL_DOCUMENT = "/employee-documents";
  export function UploadAttachment(
    data: FormData,
    success: (response: { url: string }) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_URL, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function PostDocument(
    empId: string,
    data: PostDocument,
    success: (response: Document) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL_DOCUMENT}/${empId}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetAllDocuments(
    empId: string,
    success: (response: FetchDocuments) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL_DOCUMENT}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteDocument(
    id: string,
    empId: string,
    fileName: string,
    success: (response: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL_DOCUMENT}/${empId}/${id}/${fileName}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { UploadAttachments };
