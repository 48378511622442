import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";
import { PayItemReimbursementApi } from "../../../Api/PayItemReinbursement";
import { PayTemp } from "../../../Api/PayTemp";

interface ReimbursementLineModalProps {
  isOpen: boolean;
  onClose: () => void;
  resetData: {
    payItemReimbursementId: string;
    description: string;
    index?: number;
  };

  onPressOK: (data: {
    payItemReimbursementId: string;
    description: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum;
    category: PayTemp.PayTemplateItemTypeEnum;
    index?: number;
  }) => void;
  account: PayItemReimbursementApi.ReimbursementById[];
}
export function ReimbursementLineModal({
  isOpen,
  resetData,
  onClose,
  onPressOK,
  account,
}: ReimbursementLineModalProps) {
  const [payItemId, setPayItemId] = useState("");
  const [description, setDescription] = useState("");
  const [index, setIndex] = useState<number | undefined>(resetData?.index);
  const handlePayItemChange = (newValue: any) => {
    setPayItemId(newValue?.id);
  };
  const handleDescriptionChange = (newValue: any) => {
    setDescription(newValue);
  };
  useEffect(() => {
    setPayItemId(resetData.payItemReimbursementId);
    setDescription(resetData.description);
    setIndex(resetData.index);
  }, [resetData]);
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Add an Reimbursements Line
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack maxW={"300px"} gap={0}>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"bold"}
              marginBottom={"8px"}
            >
              Reimbursement Rate <Icon color="teal.500" as={QuestionIcon} />
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={account}
              value={{
                id: payItemId,
                name:
                  account.find((account) => account.id === payItemId)?.name ??
                  "",
              }}
              onChange={handlePayItemChange}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
            <CustomInput
              withValidation
              input={{
                isInvalid: false,
                fontSize: "12px",
                variant: "outline",
                type: "text",
                value: description,
                onChange: (e: any) => handleDescriptionChange(e.target.value),
                borderWidth: "1px",
                _light: {
                  color: "customColor.black7",
                },
                borderStyle: "solid",
                bg: "#fff",
                height: "25px",
                padding: "0px 0px 0px 3px",
                boxShadow: "none",
                placeholder: "",
              }}
              label="Description"
              labelProps={{
                fontSize: "12px",
                fontWeight: "bold",
                _light: {
                  color: "customColor.black7",
                },
                marginBottom: "5px",
              }}
            />

            <FormControl>
              <FormLabel
                mb={0}
                fontWeight="bold"
                marginTop="10px"
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Control of Expense Account
              </FormLabel>

              <Text
                textAlign={"left"}
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                marginTop={"5px"}
                marginBottom={"5px"}
                borderTop={"1px solid #999"}
                borderBottom={"1px solid #ccc"}
              >
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.code
                }
                {":"}
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.name
                }
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onPressOK({
                payItemReimbursementId: payItemId,
                description,
                calculationType: PayTemp.PayTemplateCalcTypeEnum.REIMBURSEMENT,
                category: PayTemp.PayTemplateItemTypeEnum.REIMBURSEMENT,
                index: index,
              });
              onClose();
            }}
          >
            Add
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
