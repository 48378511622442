import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TbMinusVertical } from "react-icons/tb";
import { Column } from "../Reports/Columns";

interface ColumnsProps {
  setSelectedColumns: Dispatch<SetStateAction<Column[]>>;
  selectedColumns: any;
  colBtns: {
    colItem: Array<Column>;
  }[];
}

export function ColumnsComponent({
  setSelectedColumns,
  selectedColumns,
  colBtns,
}: ColumnsProps) {
  const reportForm = useFormContext();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const menuRef = useRef<HTMLDivElement>(null); // Change ref type to HTMLDivElement

  useEffect(() => {
    // Reset focused index when the menu closes
    return () => setFocusedIndex(-1);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex((prev) => (prev + 1) % getMenuItemsCount());
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex(
        (prev) => (prev - 1 + getMenuItemsCount()) % getMenuItemsCount()
      );
    } else if (event.key === " ") {
      event.preventDefault();
      if (focusedIndex >= 0 && focusedIndex < getMenuItemsCount()) {
        const item = getMenuItem(focusedIndex);
        if (item) {
          const isSelected =
            selectedColumns.findIndex((v: any) => v.column === item.column) >
            -1;

          if (isSelected) {
            setSelectedColumns((prev) =>
              prev.filter((column) => column.column !== item.column)
            );
          } else {
            setSelectedColumns((prev) => [...prev, item]);
          }
        }
      }
    }
  };

  const getMenuItemsCount = () => {
    return colBtns.reduce((count, items) => count + items.colItem.length, 0);
  };

  const getMenuItem = (index: number) => {
    let currentIndex = 0;
    for (const items of colBtns) {
      for (const list of items.colItem) {
        if (currentIndex === index) return list;
        currentIndex++;
      }
    }
    return null;
  };

  return (
    <Stack margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
      <Text
        fontSize="13px"
        display={"block"}
        fontWeight="600"
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.white",
        }}
      >
        Columns
      </Text>

      <Box>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                rightIcon={
                  <Flex>
                    <TbMinusVertical
                      fontSize={"22px"}
                      color={"hsl(0,0%,80%)"}
                    />
                    <ChevronDownIcon
                      fontSize={"22px"}
                      color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                      _hover={{
                        color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                      }}
                    />
                  </Flex>
                }
                width={"100%"}
                textAlign={"start"}
                h={"38px"}
                _light={{
                  bgColor: "customColor.white",
                  color: "customColor.black7",
                }}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                fontWeight={"400"}
                border={"1px solid #e5e7eb"}
                borderRadius={"4px"}
                _hover={{
                  color: "black",
                  bg: "#f2f3f4",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "black",
                  bg: "#f2f3f4",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
              >
                {selectedColumns.length} Selected Columns
              </MenuButton>
              <MenuList
                ref={menuRef} // Use the ref here for the MenuList
                w={"200px"}
                maxH="300px"
                overflowY="auto"
                zIndex={"overlay"}
                onKeyDown={handleKeyDown}
              >
                {colBtns.map((items, k) => (
                  <MenuGroup
                    fontSize={"13px"}
                    color={"#404756"}
                    fontWeight={"normal"}
                    whiteSpace={"nowrap"}
                    key={k}
                  >
                    {items.colItem.map((list, j) => {
                      const isChecked =
                        selectedColumns.findIndex(
                          (v: any) => v.column === list.column
                        ) > -1;
                      return (
                        <MenuItem
                          key={j}
                          fontSize={"15px"}
                          padding={"9px 15px"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                          tabIndex={0} // Make the item focusable
                          onFocus={() =>
                            setFocusedIndex(k * items.colItem.length + j)
                          } // Set focused index on focus
                          style={{
                            backgroundColor:
                              focusedIndex === k * items.colItem.length + j
                                ? "rgba(0, 0, 0, 0.1)"
                                : "transparent",
                          }} // Highlight focused item
                        >
                          <Checkbox
                            isChecked={isChecked}
                            paddingRight={"10px"}
                            size={"md"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedColumns([...selectedColumns, list]);
                              } else {
                                setSelectedColumns(
                                  selectedColumns.filter(
                                    (item: any) => item.column !== list.column
                                  )
                                );
                              }
                            }}
                          >
                            <Text
                              fontSize={".845rem"}
                              _light={{
                                color: "customColor.black7",
                              }}
                            >
                              {list.colLabel}
                            </Text>
                          </Checkbox>
                        </MenuItem>
                      );
                    })}
                    {k !== colBtns.length - 1 && <MenuDivider />}
                  </MenuGroup>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </Stack>
  );
}
