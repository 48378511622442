import {
  Box,
  Button,
  HStack,
  Icon,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { FinalPayApi } from "../../Api/FinalPayApi";
interface FinalPayModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
}
export function FinalPayModal({
  isOpen,
  onClose,
  setLastUpdated,
}: FinalPayModalProps) {
  const form = useForm<FinalPayApi.AddFinalPay>({
    defaultValues: {
      date: "",
      payPeriodType: "Last_Pay_Period",
      reason: "",
      payPeriod: "",
      averageEarning: "",
    },
  });
  const watchRadioValue = useWatch({
    control: form.control,
    name: "payPeriodType",
  });
  const terminationReason = [
    "Voluntary cessation",
    "III health",
    "Deceased",
    "Redundancy",
    "Dismissal",
    "Contract cessation",
    "Transfer",
  ].map((options) => ({ label: options, value: options }));
  const payPeriod = [
    "Fortnightly ending 31 March 2024 -$1,534.25",
    "Fortnightly ending 17 March 2024 -$1,534.25",
  ].map((options) => ({ label: options, value: options }));
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent width={"520px"}>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Set as final pay
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            padding={"15px 24px"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "#FBFBFB",
            }}
            border={"1px solid #E5E5E5"}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"12px"}
              fontWeight={"bold"}
            >
              The following will happen when your final pay is set:
            </Text>
            {[
              "Reset and recalculate the payslip",
              "Allow for Employee Termination Payments(ETP) to be added",
              "Include leave balances that are set up to be paid out",
              "Remove the employee from future pay runs after the termination date",
              "Remove the employee's access 14 days after termination date",
            ].map((item, i) => {
              return (
                <UnorderedList key={i}>
                  <ListItem
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize={"12px"}
                  >
                    {item}
                  </ListItem>
                </UnorderedList>
              );
            })}
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"12px"}
              margin={"0px 0px 12px 20px"}
            >
              (Employee records will remain available to you)
            </Text>
            <Stack spacing={3}>
              <Box maxW={"350px"}>
                <Controller
                  control={form.control}
                  name="date"
                  rules={{
                    required: {
                      value: true,
                      message: "Date is required",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",

                        borderRadius: "4px",
                        placeholder: "",
                      }}
                      label="Termination Date"
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box maxW={"350px"}>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={".845rem"}
                  fontWeight={"600"}
                  margin={"0px 0px 5px"}
                >
                  Reason for termination (required by government)
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Reason is required",
                    },
                  }}
                  control={form.control}
                  name="reason"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={terminationReason}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Reason"
                      placeholder="Select..."
                      value={terminationReason.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Box>
              <Box maxW={"350px"}>
                <HStack alignItems={"center"} margin={"0px 0px 5px"}>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize={".845rem"}
                    fontWeight={"600"}
                  >
                    Full pay period earnings
                  </Text>
                  <Icon fontSize={"12px"} />
                </HStack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Pay Period Type is required",
                    },
                  }}
                  name="payPeriodType"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup {...field}>
                      <HStack>
                        <Radio
                          isInvalid={fieldState.invalid}
                          size="md"
                          value="Last_Pay_Period"
                          colorScheme="blue"
                          fontSize={"0.9375rem"}
                          fontWeight={"700"}
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontSize={"13.44px"}
                          >
                            Select last full pay period
                          </Text>
                        </Radio>

                        <Radio
                          isInvalid={fieldState.invalid}
                          size="md"
                          defaultChecked
                          value="Average_Earning"
                          colorScheme="blue"
                          fontSize={"0.9375rem"}
                          fontWeight={"700"}
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontSize={"13.44px"}
                          >
                            Enter average earings
                          </Text>
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                />
                {watchRadioValue === "Last_Pay_Period" && (
                  <Stack marginTop={"10px"} gap={0}>
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={".845rem"}
                      fontWeight={"600"}
                      margin={"0px 0px 5px"}
                    >
                      Select an example of a normal full pay period
                    </Text>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Select Pay Period is required",
                        },
                      }}
                      control={form.control}
                      name="payPeriod"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          options={payPeriod}
                          withValidation
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Please Pay Period"
                          placeholder="Select..."
                          value={payPeriod.find(
                            (op) => op.value === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Stack>
                )}
                {watchRadioValue === "Average_Earning" && (
                  <Stack marginTop={"10px"}>
                    <Controller
                      control={form.control}
                      name="averageEarning"
                      rules={{
                        required: {
                          value: true,
                          message: "Average Earnings is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            type: "number",
                            // w:"",
                            mb: "-2px",
                            h: "25px",

                            border: "1px solid",
                            borderColor: "gray.200",
                            borderRadius: "1px",
                            padding: "9px 6.5px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label="Enter average earnings"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "700",
                            _dark: {
                              color: "customColor.white",
                            },
                            _light: {
                              color: "customColor.gray.800",
                            },
                            margin: "0px 0px 5px",
                          }}
                        />
                      )}
                    />
                  </Stack>
                )}
              </Box>
              <Box width={"95%"}>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={"13.44px"}
                  fontWeight={"normal"}
                  flex={1}
                >
                  Schedule 7 tax table is used to calculate the tax on unused
                  leave.{" "}
                  <Link fontSize={"13.44px"}>
                    For more information check the ATO website
                  </Link>
                </Text>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter padding={"10px"}>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={form.handleSubmit(
                (data) => {
                  onClose();
                },
                (error) => {}
              )}
            >
              Save
            </Button>
            <Button
              marginLeft={"8px"}
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
