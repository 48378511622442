import {
  Button,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  AiOutlineCalendar,
  AiOutlineDollarCircle,
  AiOutlineSetting,
} from "react-icons/ai";
import { HiUserAdd } from "react-icons/hi";
import yt from "./yt.png";

export function Employee() {
  const Items: Array<{ pic?: any; title: string; button: string }> = [
    { pic: { yt }, title: "How Payroll Works", button: "Take a tour?" },
    {
      title: "Need more information",
      button: "Learn more about HRIS 360 Payroll",
    },
  ];
  const data: Array<{
    icon: any;
    title: string;
    desc: string;
    button: string;
  }> = [
    {
      icon: AiOutlineSetting,
      title: "Organisation Details",
      desc: "Confirm your ABN, legal trading name, postal address, and LandLine Phone.",
      button: "Add organisation",
    },
    {
      icon: AiOutlineSetting,
      title: "Payroll Accounts",
      desc: "Assign the bank accounts and HRIS 360 accounts you will use to pay your employees.",
      button: "Add Payroll Accounts",
    },
    {
      icon: AiOutlineCalendar,
      title: "Pay Calendars",
      desc: "Pick when your employees will get paid.",
      button: "Add Pay Calendar",
    },
    {
      icon: HiUserAdd,
      title: "Employees",
      desc: "Add your first employee into the system.",
      button: "Add Employee",
    },
    {
      icon: AiOutlineDollarCircle,
      title: "Single Touch Payroll-Not available in trial.",
      desc: "Opt-in to Single Touch Payroll",
      button: "Setup STP",
    },
  ];

  return (
    <Stack bg={"#f2f3f4"} minHeight="auto" paddingBottom="20px">
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        p={{ base: 4, md: "40px 0px 40px 0px" }}
      >
        <Stack>
          <Heading
            fontSize={{ base: "xl", md: "2xl" }}
            marginLeft={{ lg: "230px", md: "120px", sm: "0px" }}
            marginBottom={"25px"}
            paddingBottom={"10px"}
            borderBottom={"1px solid #f2f3f4"}
          >
            Get Started with Payroll
          </Heading>
        </Stack>
        <HStack justifyContent={"center"} spacing={4}>
          {Items.map((item, i) => {
            return (
              <VStack key={i} spacing={2}>
                <Heading
                  fontSize={{ base: "sm", md: "18px" }}
                  fontWeight={"normal"}
                >
                  {item.title}
                </Heading>
                <Button
                  variant={"link"}
                  fontSize={{ base: "x-small", md: "14px" }}
                  fontWeight={"normal"}
                >
                  {item.button}{" "}
                </Button>
              </VStack>
            );
          })}
        </HStack>
      </Stack>
      <Stack
        justifyContent={"center"}
        textAlign={"center"}
        p={{ base: 4, md: 8 }}
      >
        <Text fontSize={{ base: "x-small", md: "14px" }} color={"#999999"}>
          There are just a few tasks you need to complete before running your
          first payroll
        </Text>
      </Stack>
      <Stack textAlign={"left"} px={{ base: 2, sm: 20, md: 40, lg: 60 }}>
        {data.map((item, index) => {
          return (
            <Stack
              key={index}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              p={"20px"}
              border={{ md: "1px solid grey" }}
              borderRadius={{ md: "md" }}
              marginBottom={{ base: "10px", md: "20px" }}
            >
              <HStack>
                <IconButton
                  aria-label="add"
                  icon={<item.icon />}
                  color={"black"}
                  w={"44px"}
                  h={"44px"}
                  marginRight={"30px"}
                  borderRadius={"100%"}
                  padding={"11px"}
                  border={"1px solid #f0f0f0"}
                  bg={"transparent"}
                />
                <Stack flex={1}>
                  <Text fontSize={{ base: "sm", md: "20px" }}>
                    {item.title}
                  </Text>
                  <Text fontSize={{ base: "sm", md: "16px" }}>{item.desc}</Text>
                </Stack>
                <Button
                  variant={"solid"}
                  bg={"#77c123"}
                  h={"30px"}
                  marginTop={"0.8em"}
                  fontWeight={"400"}
                  border={"1px solid #4cae4c"}
                  boxShadow={"#538716 0 1px 0px"}
                  fontSize={"13px"}
                  padding={"6px 12px"}
                  w={"170px"}
                  borderRadius={"3px"}
                  _hover={{
                    backgroundColor: "#6cb020",
                  }}
                >
                  {item.button}
                </Button>
              </HStack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
