import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BillsTable } from "./BillsTable";

export function BillsToPayParent() {
  const navigate = useNavigate();

  const tabCategories = [
    {
      label: "All",
      index: 0,
      flexValue: 1,
      component: <BillsTable />,
    },
    {
      label: "Draft",
      index: 1,
      flexValue: 1,
      component: <BillsTable />,
    },
    {
      label: "Awaiting Approval",
      index: 2,
      flexValue: 3,
      component: <BillsTable />,
    },
    {
      label: "Awaiting Payment",
      index: 3,
      flexValue: 3,
      component: <BillsTable />,
    },
    {
      label: "Paid",
      index: 4,
      flexValue: 1,
      component: <BillsTable />,
    },
    {
      label: "Repeating",
      index: 5,
      flexValue: 1,
      component: <BillsTable />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const isMediumScreen = useBreakpointValue({ base: false, md: true });
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        h={"60px"}
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"container.xl"} p={{ xs: "16px", lg: "16px 50px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="#3454d1" />}
              >
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink href="#" color={"#3454d1"} fontSize={"13px"}>
                    Purchase Overview
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem></BreadcrumbItem>
              </Breadcrumb>
              <Heading
                fontSize="17px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
                fontWeight="bold"
              >
                Bills
              </Heading>
            </Stack>
            <Stack>
              <HStack gap={0}>
                <Box width={"100%"}>
                  <Button
                    width={"100%"}
                    borderRadius={"3px"}
                    borderRightRadius={0}
                    backgroundColor={"#fff"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"38px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                      color: "#3454D1",
                    }}
                    onClick={() => {
                      navigate("/app/payroll/CreateBill");
                    }}
                  >
                    New Bills
                  </Button>
                </Box>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    h={"38px"}
                    _light={{
                      bgColor: "customColor.white",
                      color: "customColor.black7",
                    }}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    fontWeight={"400"}
                    borderTop={"1px solid #e5e7eb"}
                    borderRight={"1px solid #e5e7eb"}
                    borderBottom={"1px solid #e5e7eb"}
                    borderRightRadius={"4px"}
                    _hover={{
                      color: "customColor.black7",
                      bg: "#f2f3f4",
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _active={{
                      color: "customColor.black7",
                      bg: "#f2f3f4",
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    icon={<IoIosArrowDown />}
                  />

                  <MenuList overflowY="auto" zIndex={"overlay"}>
                    {[
                      {
                        name: "New Bills",
                        action: () => {
                          navigate("/app/payroll/CreateBill");
                        },
                      },
                      {
                        name: "New Repeating Bills",
                      },
                      {
                        name: "Import from CSV",
                      },
                      {
                        name: "New Credit notes",
                        action: () => {
                          navigate("/app/payroll/CreditBill");
                        },
                      },
                    ].map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          padding={"0px 12px"}
                          _hover={{
                            backgroundColor: "rgba(0,10,30,.1)",
                          }}
                        >
                          {" "}
                          <Text
                            padding={"8px"}
                            margin={"0px 12px 0px 0px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.pearlWhite",
                            }}
                            fontWeight={"600"}
                            fontSize={"13px"}
                            onClick={item.action}
                          >
                            {item.name}
                          </Text>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    padding={0}
                    backgroundColor={"transparent"}
                    h={"34.5px"}
                    marginLeft={"8px"}
                    color={"customColor.black7"}
                    display={{
                      lg: "flex",
                      md: "flex",
                      sm: "flex",
                      xs: "flex",
                    }}
                    borderRadius={"full"}
                    _hover={{
                      color: "#fff",
                      backgroundColor: "#c1caf1",
                    }}
                    _active={{
                      color: "#fff",
                      backgroundColor: "#97a7e7",
                    }}
                  >
                    <Icon as={FiMoreVertical} />
                  </MenuButton>
                  <MenuList style={{ minWidth: "140px" }}>
                    {[
                      {
                        name: "Automate bill entry",
                      },
                      {
                        name: "Export bills",
                      },
                      {
                        name: "View bills not assigned to projects",
                      },
                      {
                        name: "Default bill page",
                      },
                    ].map((menuItem, i) => {
                      return (
                        <MenuItem
                          key={i}
                          padding={"1px 0px 1px 20px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          <Button
                            variant={"ghost"}
                            fontWeight={"600"}
                            fontSize={"13px"}
                            padding={"0px 20px 0px 0px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            _hover={{ bg: "none" }}
                          >
                            {menuItem.name}
                          </Button>
                        </MenuItem>
                      );
                    })}
                    <MenuItem key={1} padding={"0px 12px"}>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <FormLabel
                          htmlFor="email-alerts"
                          padding={"8px"}
                          margin={"0px 12px 0px 0px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.pearlWhite",
                          }}
                          fontWeight={"600"}
                          fontSize={"13px"}
                        >
                          Compact View
                        </FormLabel>
                        <Switch id="email-alerts" />
                      </FormControl>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"container.xl"} p={{ xs: "16px", lg: "0px 50px" }}>
          <Stack
            marginTop={"24px"}
            borderTopRadius={"10px"}
            borderTop={isMediumScreen ? "1px solid #ddd" : 0}
            borderLeft={isMediumScreen ? "1px solid #ddd" : 0}
            borderRight={isMediumScreen ? "1px solid #ddd" : 0}
            transition={"all .3s ease"}
          >
            {isMediumScreen ? (
              <Tabs
                index={selectedTab}
                orientation="horizontal"
                onChange={setSelectedTab}
                isLazy
              >
                <TabList>
                  {tabCategories.map((tab, i) => (
                    <Tab
                      key={i}
                      flex={tab.flexValue}
                      borderRight={tab.index !== 5 ? "1px solid #ddd" : "none"}
                      fontSize={"13.44px"}
                      padding={"20px 15px"}
                      fontWeight={"600"}
                      _light={{ color: "#283c50" }}
                      onClick={() => setSelectedTab(tab.index)}
                      _selected={{
                        _light: { color: "#3454d1" },
                        fontWeight: 600,
                        bg: "rgba(52,84,209,.075)",
                        borderBottom: "3px solid #3454d1",
                      }}
                      _hover={{ _light: { color: "#3454d1" }, fontWeight: 600 }}
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </TabList>

                {/* <TabPanels p={0}>
                  {tabCategories.map((tab, i) => (
                    <TabPanel key={i} padding={0}>
                      {" "}
                      {tab.component}
                    </TabPanel>
                  ))}
                </TabPanels> */}
              </Tabs>
            ) : (
              <Box alignContent={"flex-end"} justifyContent={"flex-end"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<AiOutlineCaretDown />}
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    justifyContent="flex-end"
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    minW={"170px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      color: "primary.950",
                      backgroundColor: "#fff",
                    }}
                    cursor={"pointer"}
                    _active={{
                      backgroundColor: "#fff",
                      color: "primary.950",
                    }}
                  >
                    {tabCategories[selectedTab].label}
                  </MenuButton>
                  <MenuList>
                    {tabCategories.map((tab) => (
                      <MenuItem
                        fontSize={"13.44px"}
                        key={tab.index}
                        onClick={() => setSelectedTab(tab.index)}
                      >
                        {tab.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
          </Stack>
        </Container>
      </Stack>
      <Container
        maxW={"container.xl"}
        padding={{ xs: "16px", lg: "30px 50px" }}
      >
        <Box>{tabCategories[selectedTab].component}</Box>
      </Container>
    </Stack>
  );
}
