import { axiosInstance } from "./axiosInstance-hris";
declare module EmployeeSeparationApi {
  type SeparationData = {
    id: string;
    separationDate: string;
    reason: string;
    interviewDate: string;
    resignationDate: string;
    comments: string;
    idCardReturned: boolean;
    clearanceReceived: boolean;
    noticePeriodCompleted: boolean;
  };
}
module EmployeeSeparationApi {
  const API_URL = "/employee-separations";

  export function CreateEmployeeSeparation(
    empId: string | undefined,
    data: SeparationData,
    success: (data: SeparationData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchEmployeeSeparation(
    empId: string | undefined,
    success: (data: SeparationData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { EmployeeSeparationApi };
