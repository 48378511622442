import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { EmployeeBankAccountsModule } from "../../../Api/EmployeeBankAccounts";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
  navigate?: NavigateFunction;
}
export function BankAccount({ setSelectedTab, selectedTab }: TabProps) {
  const form = useForm<EmployeeBankAccountsModule.AddAccounts>({
    defaultValues: {
      bankAccounts: [
        {
          accountName: "",
          accountNumber: "",
          branchCode: "",
          bankName: "",
          iban: "",
        },
      ],
    },
  });

  const toast = useToast();
  const { id } = useParams();

  const AccountsArr = useFieldArray({
    control: form.control,
    name: "bankAccounts",
    keyName: "accId",
  });
  const navigate = useNavigate();
  const DeleteAccountsArr = useFieldArray({
    control: form.control,
    name: "deltedBankAccounts",
    keyName: "id",
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    EmployeeBankAccountsModule.FetchBankAccounts(
      id!,
      (data) => {
        form.reset({
          bankAccounts: data,
        });
        setLoading(false);
      },
      (err) => {
        toast({
          title: "Error Occured",
          status: "error",
          description: err,
        });
        setLoading(false);
      }
    );
  }, []);
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  return (
    <FormProvider {...form}>
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginBottom={"30px"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderRadius={"5px"}
        gap={0}
        border={"1px solid #f6f6f6"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          p="0px"
        >
          <Heading
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              color: "customColor.black7",
              bgColor: "customColor.white",
            }}
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px"
            as="h2"
            size="md"
          >
            Bank Account Details
          </Heading>
          <Divider orientation="horizontal" />
          {/* {AccountsArr.fields.map((item, i) => {
          return ( */}
          <Stack padding={"20px "}>
            <Heading
              textAlign={"left"}
              fontSize=".9975rem"
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={"600"}
              marginTop={"14px"}
              marginBottom={"7px"}
            >
              {/* {getAccountLabel(i)} */}
              Primary Bank Account
            </Heading>

            <Stack marginTop={"15px"} marginBottom={"15px"} spacing={"30px"}>
              <Controller
                control={form.control}
                name={`bankAccounts.${0}.bankName`}
                render={({ field, fieldState }) => (
                  <CustomInput
                    withValidation
                    validationMessage="This field is required"
                    input={{
                      ...field,
                      isInvalid: fieldState.invalid,
                      isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder="e.g Pay Cheque "
                    label="Bank Name"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={`bankAccounts.${0}.accountName`}
                render={({ field, fieldState }) => (
                  <CustomInput
                    withValidation
                    validationMessage="This field is required"
                    input={{
                      ...field,

                      isInvalid: fieldState.invalid,
                      isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Account Name"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={`bankAccounts.${0}.branchCode`}
                render={({ field, fieldState }) => (
                  <CustomInput
                    withValidation
                    validationMessage="This field is required"
                    input={{
                      ...field,
                      isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                      isInvalid: fieldState.invalid,

                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder="000-000"
                    label="Branch Code"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={`bankAccounts.${0}.accountNumber`}
                render={({ field, fieldState }) => (
                  <CustomInput
                    withValidation
                    validationMessage="This field is required"
                    input={{
                      ...field,
                      isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                      isInvalid: fieldState.invalid,

                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder="000000"
                    label="Account Number"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={`bankAccounts.${0}.iban`}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{16}$/,
                    message:
                      "IBAN must be in the format: PK55UNIL0109000279164441",
                  },
                  maxLength: {
                    value: 24,
                    message: "IBAN must be exactly 24 characters long",
                  },
                }}
                render={({ field, fieldState }) => (
                  <CustomInput
                    withValidation
                    validationMessage={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    input={{
                      ...field,
                      isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                      isInvalid: fieldState.invalid,
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                      borderTopRadius: "3px",
                    }}
                    placeholder="000000"
                    label="IBAN Number"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Box>
        <Box
          borderTop="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          h="60px"
          display={decodedToken.accessLevel === "EMPLOYEE" ? "none" : "block"}
        >
          <Stack
            direction={"row"}
            padding={"10px"}
            display="flex"
            justifyContent="flex-end"
          >
            <ButtonGroup>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  navigate(-1);
                  // navigate("/app/payroll/PayrollEmployee");
                }}
              >
                Cancel
              </Button>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setLoading(true);
                      EmployeeBankAccountsModule.AddEmployeeBankDetails(
                        id!,
                        data.bankAccounts,
                        () => {
                          toast({
                            title: "Bank Account Added/Updated",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                          setLoading(false);
                          setSelectedTab(selectedTab + 1);
                        },
                        (error) => {
                          toast({
                            title: error,
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                          setLoading(false);
                        }
                      );
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                      setLoading(false);
                    }
                  )(e);
                }}
              >
                Save and Next
              </Button>

              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setLoading(true);
                      EmployeeBankAccountsModule.AddEmployeeBankDetails(
                        id!,
                        data.bankAccounts,
                        () => {
                          toast({
                            title: "Bank Account Added/Updated",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                          setLoading(false);
                        },
                        (error) => {
                          toast({
                            title: error,
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                          setLoading(false);
                        }
                      );
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                      setLoading(false);
                    }
                  )(e);
                }}
              >
                Save
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      </Stack>
    </FormProvider>
  );
}
