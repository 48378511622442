import {
  Box,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { RiArrowDownSFill } from "react-icons/ri";
import { CustomInput } from "../../../../Common/CustomInput";

export function AddNewGroup() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        h={"32px"}
        color={"#3454d1"}
        fontSize={"15px"}
        bgColor={"customColor.white"}
        rightIcon={<RiArrowDownSFill />}
        fontWeight={"700"}
        variant={"ghost"}
        _hover={{
          bg: "#f2f3f4",
        }}
        _active={{
          bg: "#f2f3f4",
        }}
        onClick={onClose}
      >
        Add to group
      </MenuButton>
      <MenuList>
        <Stack
          h={"60px"}
          borderBottom={"1px solid #e5e7eb"}
          gap={0}
          padding={"12px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"15px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Add to group
          </Text>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#2c48b5",
            }}
          >
            Add
          </Button>
        </Stack>
        <Stack gap={0} margin={"16px"}>
          <Stack direction={"row"} paddingTop={"12px"}>
            <Checkbox size={"lg"}>
              <Text
                fontSize={"15px"}
                color={"customColor.black7"}
                fontWeight={"600"}
              >
                Contractors
              </Text>
            </Checkbox>
          </Stack>
          <Stack direction={"row"} paddingTop={"12px"} marginTop={"16px"}>
            <Checkbox size={"lg"}>
              <Text
                fontSize={"15px"}
                color={"customColor.black7"}
                fontWeight={"600"}
              >
                Support Clients (monthly)
              </Text>
            </Checkbox>
          </Stack>
        </Stack>

        <Box
          borderTop={"1px solid #e5e7eb"}
          position={"sticky"}
          zIndex={9999}
          bottom={0}
          bgColor={"customColor.white"}
        >
          <Popover
            isOpen={isOpen}
            onClose={onClose}
            placement="left-start"
            preventOverflow={true}
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Button
                margin={"12px 20px 12px 12px"}
                h={"38px"}
                leftIcon={<FaPlus />}
                color={"#3454d1"}
                fontSize={"15px"}
                padding={"5px 12px"}
                bgColor={"customColor.white"}
                fontWeight={"700"}
                variant={"ghost"}
                _hover={{
                  bg: "#f2f3f4",
                }}
                _active={{
                  bg: "#f2f3f4",
                }}
                onClick={onOpen} // Open the Popover on button click
              >
                New group
              </Button>
            </PopoverTrigger>
            <PopoverContent alignItems={"center"}>
              <PopoverBody minW={"250px"}>
                <CustomInput
                  withValidation
                  input={{
                    w: "100%",
                    h: "38px",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Group name"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
                <HStack justifyContent={"flex-end"} marginTop={"15px"}>
                  <Button
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    color={"#ea4d4d"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    variant={"solid"}
                    h={"40px"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    margin={"0px 0px 0px 10px"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#2c48b5",
                    }}
                    onClick={onClose} // Close the Popover on button click
                  >
                    Confirm
                  </Button>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      </MenuList>
    </Menu>
  );
}
