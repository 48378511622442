import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeesReport } from "../../../Api/EmployeeReport";
import { DownloadExcel } from "../../TimeSheet/utils";
import { ColumnsComponent } from "../Components/Columns";
import ListLimit from "../Components/ListLimit";
import PaginationComponent from "../Components/PaginationComponent";

import { FilterModal } from "../Modal/FilterModal";
import { ReportingTable } from "../Tables/ReportingTable";
import { Column } from "./Columns";
import ReportsHeader from "./ReportsHeader";
interface GenericObject {
  [key: string]: any;
}
export function EmployeeContactDetailReport() {
  const name = "Employee Contact Details";
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState<
    boolean | undefined
  >(undefined);
  const EmployeeStatus: Array<{ label: string; value?: boolean }> = [
    {
      label: "All",
      value: undefined,
    },
    {
      label: "Active",
      value: true,
    },
    {
      label: "Terminated",
      value: false,
    },
  ];
  const FilterData: Array<{
    name: string;
    startRate?: string;
    endRate?: string;
    dataType?: string;
    endDate?: string;
    startDate?: string;
    StartKeyName?: string;
    EndKeyName?: string;
    singleProperty?: string;
    PayLoadDataType?: any;
  }> = [
    {
      name: "Address",
      dataType: "text",
      singleProperty: "filters.address",
      PayLoadDataType: String,
    },
    {
      name: "Age",
      dataType: "text",
      singleProperty: "filters.age",
      PayLoadDataType: String,
    },
    {
      name: "Date of Birth",
      startDate: "date",
      endDate: "date",
      StartKeyName: "filters.dateOfBirth.from",
      EndKeyName: "filters.dateOfBirth.to",
      PayLoadDataType: String,
    },
    {
      name: "Email",
      dataType: "email",
      singleProperty: "filters.email",
      PayLoadDataType: String,
    },
    {
      name: "First Name",
      dataType: "text",
      singleProperty: "filters.firstName",
      PayLoadDataType: String,
    },
    {
      name: "Last Name",
      dataType: "text",
      singleProperty: "filters.lastName",
      PayLoadDataType: String,
    },
    {
      name: "Mobile",
      dataType: "text",
      singleProperty: "filters.mobileNumber",
      PayLoadDataType: String,
    },
    {
      name: "Postal Code",
      dataType: "text",
      singleProperty: "filters.postalCode",
      PayLoadDataType: String,
    },
    {
      name: "Province",
      dataType: "text",
      singleProperty: "filters.province",
      PayLoadDataType: String,
    },
    {
      name: "City",
      dataType: "text",
      singleProperty: "filters.city",
      PayLoadDataType: String,
    },
    {
      name: "Phone Number",
      dataType: "text",
      singleProperty: "filters.phoneNumber",
      PayLoadDataType: String,
    },
  ];
  const employmentStatus = ["All", "Active", "Terminated"];
  const toast = useToast();
  const reportForm = useForm<EmployeesReport.EmployeesContactDetailReport>({
    defaultValues: {
      columns: {
        address: true,
        age: true,
        city: true,
        dateOfBirth: true,
        email: true,
        firstName: true,
        lastName: true,
        mobileNumber: true,
        phoneNumber: true,
        postalCode: true,
        province: true,
      },
      filters: {},
      pagination: { page: 1, limit: 10 },
    },
  });
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0],
    colBtns[0].colItem[1],
    colBtns[0].colItem[2],
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem]);
  const [selectedData, setSelectedData] =
    useState<EmployeesReport.fetchReportData>({
      data: [],
    });
  const [totalCount, setTotalCount] = useState<number>(0);

  const filterModal = useDisclosure();
  const { allEmployeesList } = useBusinessContext();
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined
  );
  const [excelData, setExcelData] = useState<any>(undefined);
  const postPayload = reportForm.handleSubmit((data) => {
    let payloadData = { ...data };
    //  if (
    //    data.filters?.dateRange?.from === undefined ||
    //    data.filters?.dateRange?.to === undefined
    //  ) {
    //    delete payloadData.filters?.dateRange;
    //  }

    const payload = {
      ...payloadData,
      filters: {
        ...payloadData.filters,
        empId: selectedEmployee,
        active: selectedEmployeeStatus,
      },
      columns: colBtns[0].colItem.reduce((acc, item) => {
        return {
          ...acc,
          [item.column]: selectedColumns.some(
            (col) => col.column === item.column
          ),
        };
      }, {} as any),
    };
    EmployeesReport.CreateEmployeeContactDetailReport(
      payload,
      (success: any) => {
        setSelectedData(success);
        setTotalCount(success.count);
        setClonedSelectedColumns(selectedColumns);
        setExcelData(payload);
        toast({
          title: "Report Created",
          description: "Report Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      (error: any) => {
        toast({
          title: "Report Creation Failed",
          description: "Report Creation Failed",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  });
  const watchedLimit = useWatch({
    control: reportForm.control,
    name: "pagination.limit",
  });
  const watchedPage = useWatch({
    control: reportForm.control,
    name: "pagination.page",
  });
  useEffect(() => {
    if (selectedData.data.length > 0) {
      postPayload();
    }
  }, [watchedLimit, watchedPage]);
  return (
    <FormProvider {...reportForm}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          direction={"row"}
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          alignItems={"center"}
          justifyContent={"space-between"} // Add this line
          width={"100%"}
        >
          <ReportsHeader name={name} />
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingLeft={"10px"}
            marginRight={"16px"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                marginRight={"8px"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateEmployeeContactDetailReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "EmployeeContactDetailReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                reportForm.reset({
                  columns: {
                    address: true,
                    age: true,
                    city: true,
                    dateOfBirth: true,
                    email: true,
                    firstName: true,
                    lastName: true,
                    mobileNumber: true,
                    phoneNumber: true,
                    postalCode: true,
                    province: true,
                  },
                  filters: {},
                  pagination: { page: 1, limit: 10 },
                });
                setSelectedColumns([
                  colBtns[0].colItem[0],
                  colBtns[0].colItem[1],
                  colBtns[0].colItem[2],
                ]);
                setSelectedEmployee(undefined);
                setSelectedEmployeeStatus(undefined);
                reportForm.setValue("pagination", { page: 1, limit: 10 });
              }}
            >
              Clear Filters
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              marginLeft={"8px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={postPayload}
            >
              Upload
            </Button>
          </Box>
        </Stack>

        <Container padding={"0px 12px"} maxW={"1150px"} marginTop={"10px"}>
          <Stack padding={"0px"}>
            <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee"
                  options={[
                    { value: undefined, label: "All" }, // Add 'All' option
                    ...(allEmployeesList?.map((employee: any) => ({
                      value: employee.id,
                      label: `${employee.firstName} ${employee.lastName}`,
                    })) || []),
                  ]}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : { value: undefined, label: "All" }
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee Status (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee Status"
                  options={EmployeeStatus}
                  value={
                    EmployeeStatus.find(
                      (status) => status.value === selectedEmployeeStatus
                    ) || EmployeeStatus[0]
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployeeStatus(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              {/* <EmployeeMenu /> */}
              {/* <EmployeeStatus employmentStatus={employmentStatus} />
               */}

              <ColumnsComponent
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colBtns={colBtns}
              />

              <Stack
                margin={"10px 0px 0px"}
                padding={"0px 0px 0px 10px"}
                width="100%"
              >
                <Text
                  fontSize="13px"
                  display={"block"}
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Filter
                </Text>

                <Box>
                  <Button
                    width={"100%"}
                    textAlign={"left"}
                    backgroundColor={"#fff"}
                    borderRadius={"4px"}
                    border={"1px solid #e5e7eb"}
                    fontSize={"13px"}
                    h={"38px"}
                    justifyContent={"flex-start"}
                    variant={"solid"}
                    textColor={"#3454d1"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    leftIcon={<FaFilter color="#3454d1" />}
                    _hover={{
                      backgroundColor: "#fff",
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _active={{
                      backgroundColor: "#fff",
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                    }}
                    onClick={() => {
                      filterModal.onOpen();
                    }}
                  >
                    Filter
                  </Button>

                  <FilterModal
                    FilterData={FilterData}
                    isOpen={filterModal.isOpen}
                    onClose={filterModal.onClose}
                  />
                </Box>
              </Stack>
            </SimpleGrid>

            {/* <Button onClick={downloadExcel} borderRadius={"3px"}>
              Download as Excel
            </Button> */}
          </Stack>
        </Container>
        {selectedData.data.length > 0 && (
          <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
            <Box w={"100%"} display="flex" justifyContent="flex-end">
              <ListLimit totalCount={totalCount} />
            </Box>
            <ReportingTable
              name={name}
              Data={selectedData?.data}
              selectedColumns={clonedSelectedColumns}
              selectedData={selectedData}

              // tabRef={tabRef}
            />
            <Box w={"100%"}>
              <PaginationComponent
                totalCount={totalCount}
                // dataLength={selectedData?.data.length}
              />
            </Box>
          </Container>
        )}
        {/* <Box
          borderTop="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          position="fixed"
          left={0}
          bottom="0"
          w="full"
          h="50px"
          // zIndex={"overlay"}
        >
          <Container
            justifyContent="center"
            alignItems="flex-end"
            textAlign={"right"}
            maxW={"90%"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginTop={"10px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateEmployeeContactDetailReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "EmployeeContactDetailReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Container>
        </Box> */}
      </Stack>
    </FormProvider>
  );
}

const colBtns: Array<{
  colItem: Array<Column>;
}> = [
  {
    colItem: [
      {
        colLabel: "First Name",
        column: "firstName",
        position: 1,
        dataKey: "firstName",
      },
      {
        colLabel: "Last Name",
        column: "lastName",
        position: 2,
        dataKey: "lastName",
      },
      {
        colLabel: "Address",
        column: "address",
        position: 3,
        dataKey: "address",
      },
      { colLabel: "Age", column: "age", position: 4, dataKey: "age" },
      {
        colLabel: "Date of Birth",
        column: "dateOfBirth",
        position: 5,
        dataKey: "dateOfBirth",
      },
      { colLabel: "Email", column: "email", position: 6, dataKey: "email" },
      {
        colLabel: "Mobile",
        column: "mobileNumber",
        position: 7,
        dataKey: "mobileNumber",
      },
      {
        colLabel: "Postal Code",
        column: "postalCode",
        position: 8,
        dataKey: "postalCode",
      },
      {
        colLabel: "Province",
        column: "province",
        position: 9,
        dataKey: "province",
      },
      { colLabel: "City", column: "city", position: 10, dataKey: "city" },
      {
        colLabel: "Phone Number",
        column: "phoneNumber",
        position: 11,
        dataKey: "phoneNumber",
      },
    ],
  },
];
