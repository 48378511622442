import moment from "moment-timezone";

export function splitArrayIntoChunks(arr: any[]): any[][] {
  const chunkSize = 7;
  const result: any[][] = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }

  return result;
}

export function DownloadExcel(data: any, fileName: string) {
  const bufferArray = new Uint8Array(data);

  // Create a Blob from the Uint8Array
  const blob = new Blob([bufferArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link for the Blob
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName; // You can specify the filename here

  // Append the link, trigger download, and then remove the link
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Optionally, you can add the success toast here as needed
}

type FlattenedObject = { [key: string]: any };

export function flattenArrayObjects(
  data: any | any[]
): FlattenedObject | FlattenedObject[] {
  const flattenHelper = (obj: any, prefix = ""): FlattenedObject => {
    let flattened: FlattenedObject = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = prefix ? `${prefix}_${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.assign(flattened, flattenHelper(obj[key], newKey));
        } else {
          flattened[newKey] = obj[key];
        }
      }
    }
    return flattened;
  };

  if (Array.isArray(data)) {
    return data.map((item) => flattenHelper(item));
  } else {
    return flattenHelper(data);
  }
}
export function isDate(value: any): boolean {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

// Helper function to format the date
export function formatDate(value: string): string {
  const date = moment(value).format("YYYY-MM-DD");
  return date; // You can customize the format as needed
}

export const getLocation = (successCallback: any, errorCallback: any) => {
  if (navigator.geolocation) {
    console.log("Geolocation is supported by this browser.");
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  } else {
    errorCallback(new Error("Geolocation is not supported by this browser."));
  }
};

export const fetchLocation = () => {
  return new Promise((resolve, reject) => {
    getLocation(
      (position: any) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error: any) => {
        console.error(error);
        reject(error);
      }
    );
  });
};
export function loadGoogleMapsScript(callback: any) {
  if (!window.google) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=IzaSyCZ6p-O1f1OsTcmPNOEitFCQBBDML9fOr4&callback=initMap&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
}
export const capitalizeFirstLetter = (text: string): string => {
  if (!text) return ""; // Return an empty string if the input is empty or null
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
export function ReadeableFormatDate(value: string): string {
  const date = moment(value).format("DD-MM-YYYY");
  return date; // You can customize the format as needed
}
export function ReadeableFormatTimeWithoutDate(
  dateString: string,
  timeString: string
): string {
  const time = moment(`${dateString}T${timeString}`).format("hh:mm A");
  return time; // You can customize the format as needed
}
export function convertminutesToHours(totalMinutes: number): string {
  let duration = moment.duration(totalMinutes, "minutes");
  let hours = Math.floor(duration.asHours());
  let minutes = duration.minutes();
  let formattedDuration = `${hours} hr ${minutes} min`;
  return formattedDuration;
}
export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R: number = 6371; // Radius of the Earth in kilometers

  // Helper function to convert degrees to radians
  const rad = (deg: number): number => deg * (Math.PI / 180);

  // Calculate deltas between coordinates
  const deltaLat: number = rad(lat2 - lat1);
  const deltaLon: number = rad(lon2 - lon1);

  // Haversine formula
  const a: number =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(rad(lat1)) *
      Math.cos(rad(lat2)) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance: number = R * c; // Distance in kilometers

  return distance;
}
type GeofenceResult = {
  withinRange: boolean;
  distance?: number;
};
export async function isWithinGeofence(
  userLocation: { latitude: number; longitude: number },
  shift: any
): Promise<GeofenceResult> {
  if (!shift.geoFencingEnabled) return { withinRange: true };

  const geofenceCenter = shift.location.addressCoordinates;
  const distance = calculateDistance(
    geofenceCenter.latitude,
    geofenceCenter.longitude,
    userLocation.latitude,
    userLocation.longitude
  );

  if (distance > 1) {
    // Assuming a default radius of 1km
    return {
      withinRange: false,
      distance: distance, // Provide the actual distance if out of range
    };
  }

  return { withinRange: true };
}
