import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Flex,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import moment, { Moment } from "moment";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { ChevronDownIcon } from "@chakra-ui/icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FaFilter } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { TbMinusVertical } from "react-icons/tb";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Business } from "../../../Payroll/Api/Business";

import { useParams } from "react-router";
import { ShiftReport } from "../../../TimeAttendance/API/ShiftReport";
import FiltersModal from "../../../TimeAttendance/pages/Report/FiltersModal";
import { Column } from "../../../TimeAttendance/pages/Report/interfaces";
import { ReportingTable } from "./reportingTable";
interface FilterState {
  [key: string]: string[];
}
export default function TimesheetReport() {
  const weekly = useBreakpointValue({
    md: "Week",
    xs: "W",
  });
  const fortnight = useBreakpointValue({
    md: "Fortnight",
    xs: "F",
  });

  const tabs = [
    {
      label: weekly,
      index: 0,
    },
    {
      label: fortnight,
      index: 1,
    },
  ];
  const { currentBusinessId, businessesList } = useBusinessContext();
  const [currentBusinessDetails, setCurrentBusinessDetails] =
    useState<Business.GetBusinessDetails>();

  const [lastUpdated, setLastUpdated] = useState(0);

  const [selectedRange, setSelectedRange] = useState<string>("weekly");
  const [allFilters, setAllFilters] = useState<any>({});
  const [shiftsReportData, setShiftsReportData] = useState<
    | ShiftReport.WithoutGroupingReportResponse
    | ShiftReport.GroupingReportResponse
  >([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: string[] }>(
    {}
  );

  const [dateRange, setDateRange] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({
    startDate: moment().startOf("isoWeek"),
    endDate: moment().endOf("isoWeek"),
  });

  const [clonedDateRange, setClonedDateRange] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>(dateRange);

  const [selectedAreas, setSelectedAreas] = useState<any>([]);
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const form = useForm({
    defaultValues: {
      locationIds: [],
    },
  });
  const [locationsList, setLocationsList] = useState<Array<any>>([]);

  const options = [
    { label: "Checkin Report", value: "checkin_report" },
    { label: "Detailed Checkin Report", value: "detailed_checkin_report" },
  ];

  const colBtns: Array<{
    colTitle: string;
    colItem: Array<Column>;
  }> = [
    {
      colTitle: "Location Columns",
      colItem: [
        {
          colLabel: "Location name",
          column: "locationName",
          groupingKey: "LOCATION_NAME",
          position: 1,
        },
        { colLabel: "Address", column: "locationAddress", position: 2 },
        {
          colLabel: "Code",
          column: "locationCode",
          groupingKey: "LOCATION_CODE",
          position: 3,
        },
        { colLabel: "Area", column: "areaName", position: 4 },
      ],
    },
    {
      colTitle: "Employee Columns",
      colItem: [
        {
          colLabel: "Full Name",
          column: "employeeName",
          groupingKey: "EMPLOYEE_NAME",
          position: 5,
        },

        { colLabel: "Employee Email", column: "employeeEmail", position: 6 },
      ],
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0], //location name
    colBtns[0].colItem[3], //area
    colBtns[1].colItem[0], //employee name
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem, ...colBtns[1].colItem]);
  const [selectedGroupBy, setSelectedGroupBy] =
    useState<ShiftReport.GroupingReportPayload["groupBy"]>();

  const [clonedSelectedGroupBy, setClonedSelectedGroupBy] =
    useState<ShiftReport.GroupingReportPayload["groupBy"]>();
  const [selectedGroupingLabel, setSelectedGroupingLabel] =
    useState<string>("None");
  const groupingBtn: Array<{
    groupingTitle: string;
    groupingItem: Array<{
      label: string;
      key?: ShiftReport.GroupingReportPayload["groupBy"];
    }>;
  }> = [
    {
      groupingTitle: "None",
      groupingItem: [
        {
          label: "None",
        },
      ],
    },
    {
      groupingTitle: "Location Columns",
      groupingItem: [
        { label: "Location name", key: "LOCATION_NAME" },
        { label: "Area", key: "LOCATION_AREA" },

        { label: "Code", key: "LOCATION_CODE" },
        { label: "Contact Name", key: "LOCATION_CONTACT_NAME" },

        { label: "Industry", key: "LOCATION_INDUSTRY" },
        // {label: 'Area', key: ''},
      ],
    },
    {
      groupingTitle: "Employee Columns",
      groupingItem: [{ label: "Full Name", key: "EMPLOYEE_NAME" }],
    },
  ];
  const [selectedReport, setSelectedReport] = useState(options[0]);
  useEffect(() => {
    businessesList.forEach((business) => {
      if (business.id === currentBusinessId) {
        setCurrentBusinessDetails(business);
      }
    });
  }, []);
  const captureScreenshot = async () => {
    const div = document.querySelector("#scrollable-component");

    if (div) {
      const totalColPadding =
        (div
          ?.getElementsByTagName("thead")
          .item(0)
          ?.getElementsByTagName("tr")
          .item(0)
          ?.getElementsByTagName("th").length ?? 0) * 12;
      const tableWidth = div.scrollWidth;
      const pageHeight = div.scrollHeight < 150 ? 400 : div.scrollHeight;

      // Set the PDF page width based on the table width, with some margin
      const pdfWidth = tableWidth + 80;
      const doc = new jsPDF({
        orientation: pdfWidth > 841 ? "landscape" : "portrait", // Set orientation based on width
        unit: "pt",
        format: [pdfWidth, Math.max(pageHeight, 595)],
      });

      const img = {
        width: 50,
        height: 50,
      };
      const addCustomHeader = (data: any) => {
        if (data?.pageNumber === 1) {
          if (currentBusinessDetails?.logo) {
            doc.addImage(
              currentBusinessDetails?.logo +
                "?r=" +
                Math.floor(Math.random() * 100000),
              "JPEG",
              data.settings.margin.left,
              30,
              img.width,
              img.height
            );
          }
          doc.setFontSize(16);
          doc.setTextColor(0, 0, 0);
          doc.text(
            "Check-In Report",
            data.settings.margin.left,
            img.height + 50
          );
          doc.setFontSize(16);
          doc.setTextColor(0, 0, 0);
          doc.text(
            currentBusinessDetails?.name ?? "",
            data.settings.margin.left,
            img.height + 70
          );

          doc.setFontSize(16);
          doc.setTextColor(0, 0, 0);
          doc.text(
            `For the period ${clonedDateRange.startDate.format(
              "DD/MM/YYYY"
            )} to ${clonedDateRange.endDate.format("DD/MM/YYYY")}`,
            data.settings.margin.left,
            img.height + 90
          );

          doc.setFontSize(16);
          doc.setTextColor(0, 0, 0);
          doc.text(
            `Report Type: ${selectedReport.label}`,
            data.settings.margin.left,
            img.height + 110
          );
        }
        data.settings.margin.top = 50;
      };

      autoTable(doc, {
        html: "#scrollable-component",
        pageBreak: "auto",
        margin: {
          top: (img?.height ?? 0) + 140,
          right: 50,
          bottom: 10,
          left: 50,
        },
        theme: "grid",
        // includeHiddenHtml: true,
        willDrawPage: addCustomHeader,
        useCss: true,
        // columnStyles: columnStyles,
        tableWidth: "auto",
        styles: {
          cellPadding: 1,
          fontSize: 8,
          cellWidth: "auto",
          lineWidth: 0,
        },

        headStyles: {
          fillColor: "#FFFFFF", // Header row background color
          textColor: 8, // Header row text color
          fontStyle: "bold", // Header row font style
          halign: "left", // Horizontal alignment of column cells
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontStyle: "normal",
          cellWidth: "auto",
          lineWidth: 0,
        },
      });

      doc.save("Checkin Report.pdf");
    }
  };

  useEffect(() => {
    setLoading(true);
    ShiftReport.CreateGroupingReport(
      {
        startDate: dateRange.startDate.format("MM-DD-YYYY"),
        endDate: dateRange.endDate.format("MM-DD-YYYY"),
        employeeId: id,
        // groupBy: selectedGroupBy,
        // filters: checkedItems,
      },
      (data) => {
        setClonedDateRange(dateRange);
        setClonedSelectedGroupBy(selectedGroupBy);
        setClonedSelectedColumns(selectedColumns);
        setShiftsReportData(data);
        // setAllFilters(filters);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        // toast({
        //   title: "Error",
        //   description: error,
        //   status: "error",
        // });
        setLoading(false);
      }
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Box
        bgColor="customColor.white"
        justifyContent="center"
        alignItems="center"
        textAlign={"left"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        overflowY="auto"
      >
        <Container maxW={"1390px"} p={0}>
          <Fragment>
            <Stack padding={"20px"}>
              <Stack px="15px" pt="15px">
                <Text
                  fontSize={"24px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  textAlign="center"
                >
                  Check-in Report
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  textAlign="center"
                >
                  {businessesList.find((b) => b.id === currentBusinessId)
                    ?.name ?? ""}
                </Text>
                <SimpleGrid
                  minChildWidth={"270px"}
                  columns={5}
                  spacing={"10px"}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"4px"}
                  paddingTop={"10px"}
                  paddingLeft={"10px"}
                  margin={"0px 1px 20px"}
                  border="1px solid #f6f6f6"
                  padding={"7.52px 12px"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                >
                  <Stack flex={3} gap={0}>
                    <HStack gap={0}>
                      <Text
                        fontSize="13px"
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        margin={"0px 0px 5px"}
                      >
                        Date range
                      </Text>
                      <Text
                        paddingLeft={"3px"}
                        fontSize="13px"
                        display={"block"}
                        fontWeight="400"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                      >
                        ({selectedRange})
                      </Text>
                    </HStack>

                    <Box>
                      <HStack gap={0}>
                        <Input
                          // maxW={{xs: '100%', md: '130px'}}
                          h="38px"
                          px="8px"
                          placeholder="Select Date"
                          border="1px solid"
                          borderColor="borders.tableBorder"
                          borderLeftRadius={"5px"}
                          max={"9999-12-31"}
                          borderRightRadius={0}
                          fontSize=".845rem"
                          fontWeight={400}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            color: "customColor.black7",
                          }}
                          type="date"
                          padding="12px 15px"
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          value={dateRange.startDate.format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setDateRange((prev) => ({
                              ...prev,
                              startDate: moment(e.target.value),
                            }));
                          }}
                        />
                        <Input
                          h="38px"
                          px="8px"
                          placeholder="Select Date"
                          border="1px solid"
                          borderColor="borders.tableBorder"
                          borderRightRadius={"0"}
                          borderLeftRadius={0}
                          max={"9999-12-31"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _dark={{
                            bgColor: "customColor.dark.50",
                          }}
                          _light={{
                            bgColor: "customColor.white",
                            color: "customColor.black7",
                          }}
                          type="date"
                          padding="12px 15px"
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          value={dateRange.endDate.format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setDateRange((prev) => ({
                              ...prev,
                              endDate: moment(e.target.value),
                            }));
                          }}
                        />
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            h={"38px"}
                            _light={{
                              bgColor: "customColor.white",
                              color: "customColor.black7",
                            }}
                            _dark={{
                              bgColor: "customColor.dark.50",
                            }}
                            fontWeight={"400"}
                            borderTop={"1px solid #e5e7eb"}
                            borderRight={"1px solid #e5e7eb"}
                            borderBottom={"1px solid #e5e7eb"}
                            borderRightRadius={"4px"}
                            _hover={{
                              color: "customColor.black7",
                              bg: "#f2f3f4",
                              border: "1px solid hsl(0, 0%, 70%)",
                            }}
                            _active={{
                              color: "customColor.black7",
                              bg: "#f2f3f4",
                              borderWidth: "1px",
                              boxShadow: "0 0 0 1px #2684FF",
                              _hover: {
                                borderWidth: "1px",
                                borderColor: "#2684FF",
                                boxShadow: "0 0 0 1px #2684FF",
                              },
                            }}
                            icon={<IoIosArrowDown />}
                          />
                          <MenuList maxW={"100px"} zIndex={"overlay"}>
                            <MenuItem
                              justifyContent={"space-between"}
                              onClick={() => {
                                setSelectedRange("weekly");
                                setDateRange((prev) => ({
                                  ...prev,
                                  startDate: moment().startOf("isoWeek"),
                                  endDate: moment().endOf("isoWeek"),
                                }));
                              }}
                            >
                              <Text
                                fontSize={".845rem"}
                                padding={"8px 4px 8px 8px"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _hover={{ color: "#0078C8" }}
                              >
                                Weekly
                              </Text>
                            </MenuItem>
                            <MenuItem
                              justifyContent={"space-between"}
                              onClick={() => {
                                setSelectedRange("fortnightly");
                                setDateRange((prev) => ({
                                  ...prev,
                                  startDate: moment().startOf("isoWeek"),
                                  endDate: moment()
                                    .startOf("isoWeek")
                                    .add(2, "weeks")
                                    .subtract(1, "days"),
                                }));
                              }}
                            >
                              <Text
                                fontSize={".845rem"}
                                padding={"8px 4px 8px 8px"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _hover={{ color: "#0078C8" }}
                              >
                                Fortnight
                              </Text>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                    </Box>
                  </Stack>
                  <Stack flex={2} gap={0}>
                    <Text
                      fontSize="13px"
                      margin={"0px 0px 5px"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                    >
                      Columns
                    </Text>

                    <Box>
                      <Menu closeOnSelect={false} matchWidth>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              as={Button}
                              rightIcon={
                                <Flex>
                                  <TbMinusVertical
                                    fontSize={"22px"}
                                    color={"hsl(0,0%,80%)"}
                                  />
                                  <ChevronDownIcon
                                    fontSize={"22px"}
                                    color={
                                      isOpen
                                        ? "hsl(0, 0%, 40%)"
                                        : "hsl(0,0%,80%)"
                                    }
                                    _hover={{
                                      color: isOpen
                                        ? "hsl(0, 0%, 20%)"
                                        : "hsl(0,0%,60%)",
                                    }}
                                  />
                                </Flex>
                              } //5A5A5A //383c40
                              width={"100%"}
                              textAlign={"start"}
                              h={"38px"}
                              _light={{
                                bgColor: "customColor.white",
                                color: "customColor.black7",
                              }}
                              _dark={{
                                bgColor: "customColor.dark.50",
                              }}
                              fontWeight={"400"}
                              border={"1px solid #e5e7eb"}
                              borderRadius={"4px"}
                              _hover={{
                                color: "black",
                                bg: "#f2f3f4",
                                border: "1px solid hsl(0, 0%, 70%)",
                              }}
                              _active={{
                                color: "black",
                                bg: "#f2f3f4",
                                borderWidth: "1px",

                                boxShadow: "0 0 0 1px #2684FF",
                                _hover: {
                                  borderWidth: "1px",
                                  borderColor: "#2684FF",
                                  boxShadow: "0 0 0 1px #2684FF",
                                },
                              }}
                            >
                              {selectedColumns.length} Columns Selected
                            </MenuButton>
                            <MenuList
                              // w={'300px'}
                              height="300px"
                              overflowY="auto"
                              zIndex={"overlay"}
                            >
                              {colBtns.map((items, k) => {
                                return (
                                  <MenuGroup
                                    fontSize={"13px"}
                                    color={"#404756"}
                                    fontWeight={"normal"}
                                    whiteSpace={"nowrap"}
                                    title={items.colTitle}
                                    key={k}
                                  >
                                    {items.colItem.map((list, j) => {
                                      return (
                                        <MenuItem
                                          key={j}
                                          fontSize={"15px"}
                                          padding={"9px 15px"}
                                          _dark={{
                                            color: "customColor.dark.150",
                                          }}
                                          _light={{
                                            color: "customColor.black7",
                                          }}
                                        >
                                          <Checkbox
                                            isChecked={
                                              selectedColumns.findIndex(
                                                (v) => v.column === list.column
                                              ) > -1
                                            }
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setSelectedColumns([
                                                  ...selectedColumns,
                                                  list,
                                                ]);
                                              } else {
                                                setSelectedColumns(
                                                  selectedColumns.filter(
                                                    (item) =>
                                                      item.column !==
                                                      list.column
                                                  )
                                                );
                                              }
                                            }}
                                            paddingRight={"10px"}
                                            size={"md"}
                                          >
                                            <Text
                                              fontSize=".845rem"
                                              fontWeight="400"
                                              _light={{
                                                color: "customColor.black7",
                                              }}
                                              _dark={{
                                                color: "customColor.white",
                                              }}
                                            >
                                              {" "}
                                              {list.colLabel}
                                            </Text>
                                          </Checkbox>
                                        </MenuItem>
                                      );
                                    })}

                                    {k !== colBtns.length - 1 && (
                                      <MenuDivider />
                                    )}
                                  </MenuGroup>
                                );
                              })}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    </Box>
                  </Stack>
                  <Stack flex={2} gap={0}>
                    <Text
                      fontSize="13px"
                      margin={"0px 0px 5px"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                    >
                      Grouping
                    </Text>

                    <Box>
                      <Menu matchWidth>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              as={Button}
                              rightIcon={
                                <Flex>
                                  <TbMinusVertical
                                    fontSize={"22px"}
                                    color={"hsl(0,0%,80%)"}
                                  />
                                  <ChevronDownIcon
                                    fontSize={"22px"}
                                    color={
                                      isOpen
                                        ? "hsl(0, 0%, 40%)"
                                        : "hsl(0,0%,80%)"
                                    }
                                    _hover={{
                                      color: isOpen
                                        ? "hsl(0, 0%, 20%)"
                                        : "hsl(0,0%,60%)",
                                    }}
                                  />
                                </Flex>
                              } //5A5A5A //383c40
                              width={"100%"}
                              textAlign={"start"}
                              h={"38px"}
                              _light={{
                                bgColor: "customColor.white",
                                color: "customColor.black7",
                              }}
                              _dark={{
                                bgColor: "customColor.dark.50",
                              }}
                              fontWeight={"400"}
                              border={"1px solid #e5e7eb"}
                              borderRadius={"4px"}
                              _hover={{
                                color: "black",
                                bg: "#f2f3f4",
                                border: "1px solid hsl(0, 0%, 70%)",
                              }}
                              _active={{
                                color: "black",
                                bg: "#f2f3f4",
                                borderWidth: "1px",

                                boxShadow: "0 0 0 1px #2684FF",
                                _hover: {
                                  borderWidth: "1px",
                                  borderColor: "#2684FF",
                                  boxShadow: "0 0 0 1px #2684FF",
                                },
                              }}
                            >
                              {selectedGroupingLabel}
                            </MenuButton>
                            <MenuList
                              height="300px"
                              overflowY="auto"
                              zIndex={"overlay"}
                            >
                              {groupingBtn.map((items, k) => {
                                return (
                                  <MenuGroup
                                    fontSize={"13px"}
                                    color={"#404756"}
                                    fontWeight={"normal"}
                                    whiteSpace={"nowrap"}
                                    title={items.groupingTitle}
                                    key={k}
                                  >
                                    {items.groupingItem.map((list, j) => {
                                      return (
                                        <MenuItem
                                          key={j}
                                          fontSize={"15px"}
                                          padding={"9px 15px"}
                                          _dark={{
                                            color: "customColor.dark.150",
                                          }}
                                          _light={{
                                            color: "customColor.black7",
                                          }}
                                          onClick={() => {
                                            setSelectedGroupBy(list.key);
                                            setSelectedGroupingLabel(
                                              list.label
                                            );
                                          }}
                                        >
                                          {list.label}
                                        </MenuItem>
                                      );
                                    })}

                                    {k !== groupingBtn.length - 1 && (
                                      <MenuDivider />
                                    )}
                                  </MenuGroup>
                                );
                              })}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    </Box>
                  </Stack>
                  {form.watch("locationIds").length > 0 && (
                    <Stack flex={2} gap={0}>
                      <Text
                        fontSize="13px"
                        margin={"0px 0px 5px"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                      >
                        Area
                      </Text>
                      <Box minW="300px">
                        <ReactSelect
                          isMulti
                          withValidation
                          placeholder="Please select areas (optional)"
                          options={locationsList
                            .filter((loc) =>
                              (form.watch("locationIds") as string[]).includes(
                                loc.id
                              )
                            )
                            .flatMap((loc) => loc.areas)}
                          value={selectedAreas}
                          onChange={(selectedOption: any) => {
                            setSelectedAreas(selectedOption);
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.id}
                        />
                      </Box>
                    </Stack>
                  )}

                  <Stack flex={2} gap={0}>
                    <Text
                      fontSize="13px"
                      margin={"0px 0px 5px"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                    >
                      Report Type
                    </Text>
                    <Menu matchWidth>
                      <MenuButton
                        as={Button}
                        rightIcon={
                          <Flex>
                            <TbMinusVertical
                              fontSize={"22px"}
                              color={"hsl(0,0%,80%)"}
                            />
                            <ChevronDownIcon
                              fontSize={"22px"}
                              color={
                                isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"
                              }
                              _hover={{
                                color: isOpen
                                  ? "hsl(0, 0%, 20%)"
                                  : "hsl(0,0%,60%)",
                              }}
                            />
                          </Flex>
                        } //5A5A5A //383c40
                        width={"100%"}
                        textAlign={"start"}
                        h={"38px"}
                        _light={{
                          bgColor: "customColor.white",
                          color: "customColor.black7",
                        }}
                        _dark={{
                          bgColor: "customColor.dark.50",
                        }}
                        fontWeight={"400"}
                        border={"1px solid #e5e7eb"}
                        borderRadius={"4px"}
                        _hover={{
                          color: "black",
                          bg: "#f2f3f4",
                          border: "1px solid hsl(0, 0%, 70%)",
                        }}
                        _active={{
                          color: "black",
                          bg: "#f2f3f4",
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                      >
                        {selectedReport.label}
                      </MenuButton>
                      <MenuList overflowY="auto" zIndex={"overlay"}>
                        {options.map((type, i) => {
                          return (
                            <MenuItem
                              key={i}
                              fontSize={"15px"}
                              padding={"9px 15px"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              _light={{
                                color: "customColor.black",
                              }}
                              onClick={() => {
                                setSelectedReport(type);
                              }}
                            >
                              {type.label}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Stack>
                  <Button
                    maxW={"200px"}
                    marginTop={"10px"}
                    textAlign={"left"}
                    backgroundColor={"#fff"}
                    borderRadius={"4px"}
                    border={"1px solid #e5e7eb"}
                    fontSize={"13px"}
                    h={"38px"}
                    justifyContent={"flex-start"}
                    variant={"solid"}
                    textColor={"#3454d1"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    leftIcon={<FaFilter color="#3454d1" />}
                    _hover={{
                      bg: "#fff",
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _active={{
                      bg: "#fff",
                      borderWidth: "1px",

                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    onClick={onOpen}
                  >
                    Filter
                  </Button>
                </SimpleGrid>
                <FiltersModal
                  isOpen={isOpen}
                  onClose={onClose}
                  filters={allFilters}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                />
                <HStack
                  justifyContent="space-between"
                  padding={"10px 10px 10px 0px"}
                >
                  <ButtonGroup variant="outline" isAttached>
                    <IconButton
                      aria-label="backward"
                      icon={<FiChevronLeft width="6px" height="13px" />}
                      w="34px"
                      color="#545f70"
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "customColor.white",
                      }}
                      borderColor="borders.accent"
                      _hover={{
                        borderColor: "primary.500",
                        backgroundColor: "transparent",
                        zIndex: 1,
                      }}
                      onClick={() => {
                        selectedRange === "fortnightly"
                          ? setDateRange({
                              startDate: dateRange.startDate
                                .clone()
                                .subtract(2, "week"),
                              endDate: dateRange.endDate
                                .clone()
                                .subtract(2, "week"),
                            })
                          : setDateRange({
                              startDate: dateRange.startDate
                                .clone()
                                .subtract(1, "week"),
                              endDate: dateRange.endDate
                                .clone()
                                .subtract(1, "week"),
                            });
                      }}
                    />
                    <IconButton
                      aria-label="forward"
                      icon={<FiChevronRight width="6px" height="13px" />}
                      w="34px"
                      color="#545f70"
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "customColor.white",
                      }}
                      borderColor="borders.accent"
                      _hover={{
                        borderColor: "primary.500",
                        backgroundColor: "transparent",
                        zIndex: 1,
                      }}
                      onClick={() => {
                        selectedRange === "fortnightly"
                          ? setDateRange({
                              startDate: dateRange.startDate
                                .clone()
                                .add(2, "week"),
                              endDate: dateRange.endDate.clone().add(2, "week"),
                            })
                          : setDateRange({
                              startDate: dateRange.startDate
                                .clone()
                                .add(1, "week"),
                              endDate: dateRange.endDate.clone().add(1, "week"),
                            });
                      }}
                    />
                  </ButtonGroup>
                  <Box>
                    <Button
                      isLoading={isLoading}
                      fontSize={"13px"}
                      bgColor="#3454D1"
                      h={"40px"}
                      borderRadius={"3px"}
                      borderWidth={"1px"}
                      borderColor={"#3454d1!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#3454D1",
                      }}
                      onClick={() => {
                        setLastUpdated(Date.now());
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      marginLeft={"8px"}
                      fontSize={"13px"}
                      bgColor="#3454D1"
                      h={"40px"}
                      borderRadius={"3px"}
                      borderWidth={"1px"}
                      borderColor={"#3454d1!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#3454D1",
                      }}
                      onClick={() => {
                        captureScreenshot();
                      }}
                    >
                      PDF
                    </Button>
                  </Box>
                </HStack>
                <Stack
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"4px"}
                  paddingTop={"10px"}
                  paddingLeft={"10px"}
                  border="1px solid #f6f6f6"
                  padding={"20px"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                >
                  <ReportingTable
                    startDate={clonedDateRange.startDate}
                    endDate={clonedDateRange.endDate}
                    selectedColumns={clonedSelectedColumns}
                    groupBy={clonedSelectedGroupBy}
                    data={shiftsReportData}
                    reportType={selectedReport.value}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Fragment>
        </Container>
      </Box>
    </FormProvider>
  );
}
