import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrDocumentPerformance, GrSystem } from "react-icons/gr";
import { IoIosStats, IoMdNotifications, IoMdTime } from "react-icons/io";
import { IoCashOutline, IoHomeOutline } from "react-icons/io5";
import {
  MdDomainVerification,
  MdOutlineAccountBalance,
  MdOutlineOnDeviceTraining,
  MdOutlineUpcoming,
  MdPeople,
} from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import { TbHierarchy3, TbReportSearch } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";

import { AiOutlineDollar } from "react-icons/ai";
import {
  AccessControlContextProps,
  useAccessControl,
} from "../../../Hooks/AccessControlContext";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
interface MenuTabProps {
  isSidebarOpen: boolean;
  isHovered: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
}
export default function MenuTab({
  isSidebarOpen,
  isHovered,
  handleMouseEnter,
  handleMouseLeave,
}: MenuTabProps) {
  const { currentBusinessId } = useBusinessContext();
  const access = useAccessControl();
  const navigate = useNavigate();

  const [openItem, setOpenItem] = useState<string | null>(null);
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const SideBarMenu: Array<{
    display?: boolean;
    btnIcon?: any;
    btnlabel: string;
    menu: Array<{
      menuTitle?: string;
      menuIcon?: any;
      menuItem?: Array<{
        menulabel: string;
        // onClick?: React.MouseEventHandler<HTMLButtonElement>;
        href?: string;
        accessKey?: keyof AccessControlContextProps;
      }>;
    }>;
  }> = [
    // {
    //   display: currentBusinessId ? true : false,
    //   btnIcon: <MdContacts />,
    //   btnlabel: "Contacts",
    //   menu: [
    //     {
    //       menuTitle: "Contacts",
    //       menuIcon: MdContacts,
    //       menuItem: [
    //         {
    //           menulabel: "All Contacts ",

    //           href: "/app/payroll/AllContact",
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      display: currentBusinessId ? true : false,
      btnIcon: <IoCashOutline />,
      btnlabel: "Employee Suite",
      menu: [
        {
          menuTitle: "Employee Management",
          menuIcon: MdPeople,
          menuItem: [
            {
              menulabel: "Employees",

              href: "/app/payroll/PayrollEmployee/?page=1&limit=25",
            },

            {
              menulabel: "Leave",

              href: "/app/payroll/Leave",
            },

            // {
            //   menulabel: "Productivity Tracker",

            //   href: "/app/payroll/ProductivityTracker",
            //   accessKey: "payroll.timesheets",
            // },
          ],
        },
        {
          menuTitle: "Time & Attendance",
          menuIcon: IoMdTime,
          menuItem: [
            {
              menulabel: "Location",

              href: "/app/TimeAttendance/Location",
              accessKey: "timeAndAttendance.locations",
            },
            {
              menulabel: "Schedule",
              href: "/app/TimeAttendance/Schedule",
              accessKey: "timeAndAttendance.schedules",
            },
            {
              menulabel: "Checkin Report",

              href: "/app/TimeAttendance/checkinreport",
              accessKey: "reports.employeeCheckIn",
            },
            // {
            //   menulabel: "Notifications",

            //   href: "/app/TimeAttendance/notifications",
            //   accessKey: "timeAndAttendance.notifications",
            // },
            {
              menulabel: "Notifications",

              href: "/app/TimeAttendance/notificationSettings",
              accessKey: "timeAndAttendance.notifications",
            },
          ],
        },
        {
          menuTitle: "Payroll processing",
          menuIcon: GiTakeMyMoney,
          menuItem: [
            {
              menulabel: "Timesheets",

              href: "/app/payroll/Timesheets",
              accessKey: "payroll.timesheets",
            },
            {
              menulabel: "Pay Employee",

              href: "/app/payroll/PayEmployee",
              accessKey: "payroll.payRuns",
            },

            {
              menulabel: "Payroll setting",

              href: "/app/payroll/payrollsettings",
              accessKey: "payroll.payrollSettings",
            },
            // {
            //   menulabel: "Pricing",

            //   href: "/app/payroll/Pricing",
            // },
            // {
            //   menulabel: "Setup Guide",

            //   href: "/app/payroll/SetupGuide",
            // },
          ],
        },
        {
          menuTitle: "Reports",
          menuIcon: TbReportSearch,
          menuItem: [
            {
              menulabel: "Reports",
              href: "/app/payroll/Reports",
              // accessKey: "accounting.chartOfAccounts",
            },
          ],
        },

        {
          menuTitle: "Statuary Benefits",
          menuIcon: AiOutlineDollar,
          menuItem: [
            {
              menulabel: "Statuary Benefits",
              href: "/app/payroll/Superannuation",
              // accessKey: "accounting.chartOfAccounts",
            },
          ],
        },
        // {
        //   menuTitle: "Administration",
        //   menuIcon: GrUserAdmin,
        //   menuItem: [
        //     {
        //       menulabel: "Payroll setting",

        //       href: "/app/payroll/payrollsettings",
        //       accessKey: "payroll.payrollSettings",
        //     },
        //     // {
        //     //   menulabel: "Pricing",

        //     //   href: "/app/payroll/Pricing",
        //     // },
        //     // {
        //     //   menulabel: "Setup Guide",

        //     //   href: "/app/payroll/SetupGuide",
        //     // },
        //   ],
        // },
      ],
    },

    // {
    //   display: currentBusinessId ? true : false,
    //   btnIcon: <LuCalendarClock />,
    //   btnlabel: "Time & Attendance",
    //   menu: [
    //     {
    //       menuTitle: "Manage Attendance",
    //       menuIcon: IoMdTime,
    //       menuItem: [
    //         {
    //           menulabel: "Location",

    //           href: "/app/TimeAttendance/Location",
    //           accessKey: "timeAndAttendance.locations",
    //         },
    //         {
    //           menulabel: "Schedule",
    //           href: "/app/TimeAttendance/Schedule",
    //           accessKey: "timeAndAttendance.schedules",
    //         },
    //         {
    //           menulabel: "Checkin Report",

    //           href: "/app/TimeAttendance/checkinreport",
    //           accessKey: "reports.employeeCheckIn",
    //         },
    //         // {
    //         //   menulabel: "Notifications",

    //         //   href: "/app/TimeAttendance/notifications",
    //         //   accessKey: "timeAndAttendance.notifications",
    //         // },
    //         {
    //           menulabel: "Notifications",

    //           href: "/app/TimeAttendance/notificationSettings",
    //           accessKey: "timeAndAttendance.notifications",
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      display: currentBusinessId ? true : false,
      btnIcon: <MdOutlineAccountBalance />,
      btnlabel: "Accounting",
      menu: [
        // {
        //   menuTitle: "Business",
        //   menuIcon: MdAddBusiness,
        //   menuItem: [
        //     {
        //       menulabel: "Bills to pay",

        //       href: "/app/payroll/BillsToPay",
        //     },
        //     {
        //       menulabel: "Invoices",

        //       href: "/app/payroll/InvoicesParent",
        //     },
        //     {
        //       menulabel: "Products and services",

        //       href: "/app/payroll/ProductAndServices",
        //     },
        //   ],
        // },

        {
          menuTitle: "Advanced",
          menuIcon: MdOutlineAccountBalance,
          menuItem: [
            {
              menulabel: "Chart of account",
              href: "/app/payroll/ChartOfAccount",
              accessKey: "accounting.chartOfAccounts",
            },
            {
              menulabel: "Tax Rates",
              href: "/app/payroll/TaxRates",
              accessKey: "accounting.taxRates",
            },
          ],
        },
      ],
    },
    {
      display: currentBusinessId ? true : false,
      btnIcon: <MdOutlineUpcoming />,
      btnlabel: "Upcoming Features",
      menu: [
        {
          menuTitle: "Organizational Hierachy",
          menuIcon: TbHierarchy3,
        },
        {
          menuTitle: "Applicant Tracking System",
          menuIcon: GrSystem,
        },
        {
          menuTitle: "Data Analytics | Reports",
          menuIcon: SiSimpleanalytics,
        },
        {
          menuTitle: "Primary Source Verification",
          menuIcon: MdDomainVerification,
        },
        {
          menuTitle: "Performance Management",
          menuIcon: GrDocumentPerformance,
        },
        {
          menuTitle: "Training & Consultancy",
          menuIcon: MdOutlineOnDeviceTraining,
        },
      ],
    },
  ];

  const isMedScreen = useBreakpointValue({
    xs: false,
    lg: true,
  });

  const handleNavigation = (event: any) => {
    if (!event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      const path = event.currentTarget.getAttribute("href");
      navigate(path);
      setActiveMenu(path);
    }
  };

  return (
    <Stack
      padding={"15px 0px"}
      spacing="0px"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      width={isHovered || isSidebarOpen ? "250px" : "80px"} // Change width on hover
      transition="width 0.3s ease"
    >
      <>
        <Box padding={"15px 20px 10px"}>
          <Text
            fontSize="10px"
            letterSpacing="1.28px"
            textTransform="uppercase"
            fontWeight="600"
            // opacity="0.6"
            // color={"customColor.gray.7587A7"}
            // color={"#0070C0"}
            opacity="0.9"
            color={"primary.950"}
          >
            menu
          </Text>
        </Box>
        <Accordion allowToggle>
          <AccordionItem
            key={1}
            alignItems="center"
            border={"none"}
            color={"#a8b0c5"}
            padding={"10px 15px"}
            margin={"3px 15px"}
            _hover={{
              bgColor: "#EAEBEF",
              borderRadius: "5px",
              transition: "all .3s ease",
            }}
          >
            <AccordionButton
              as={"a"}
              p={0}
              alignItems="center"
              justifyContent="center"
              fontSize="13px"
              fontWeight={400}
              href="/app/payroll/Dashboard"
              onClick={handleNavigation}
              borderRadius="3px"
              _hover={{ backgroundColor: "none" }}
              padding={0}
              style={{
                color:
                  location.pathname === "/app/payroll/Dashboard"
                    ? "#283C50"
                    : "#001327",

                backgroundColor:
                  location.pathname === "/app/payroll/Dashboard"
                    ? "customColor.blue.10"
                    : "initial",
              }}
            >
              <Icon
                as={IoHomeOutline}
                color="#283C50"
                fontSize={isHovered || isSidebarOpen ? "18px" : "27px"}
              />
              {(isHovered || isSidebarOpen) && (
                <Box
                  as="span"
                  flex={"1"}
                  textAlign="left"
                  ml={2}
                  fontWeight={"700"}
                >
                  Dashboard
                </Box>
              )}
            </AccordionButton>
          </AccordionItem>
          <AccordionItem
            key={1}
            alignItems="center"
            border={"none"}
            color={"#a8b0c5"}
            padding={"10px 15px"}
            margin={"3px 15px"}
            _hover={{
              bgColor: "#EAEBEF",
              borderRadius: "5px",
              transition: "all .3s ease",
            }}
          >
            <AccordionButton
              as={"a"}
              p={0}
              alignItems="center"
              justifyContent="center"
              fontSize="13px"
              fontWeight={400}
              href="/app/TimeAttendance/employeeTracking"
              onClick={handleNavigation}
              borderRadius="3px"
              _hover={{ backgroundColor: "none" }}
              padding={0}
              style={{
                color:
                  location.pathname === "/app/TimeAttendance/employeeTracking"
                    ? "#283C50"
                    : "#001327",

                backgroundColor:
                  location.pathname === "/app/TimeAttendance/employeeTracking"
                    ? "customColor.blue.10"
                    : "initial",
              }}
            >
              <Icon
                as={IoIosStats}
                color="#283C50"
                fontSize={isHovered || isSidebarOpen ? "18px" : "27px"}
              />
              {(isHovered || isSidebarOpen) && (
                <Box
                  as="span"
                  flex={"1"}
                  textAlign="left"
                  ml={2}
                  fontWeight={"700"}
                >
                  Statistics
                </Box>
              )}
            </AccordionButton>
          </AccordionItem>
          <AccordionItem
            key={1}
            alignItems="center"
            border={"none"}
            color={"#a8b0c5"}
            padding={"10px 15px"}
            margin={"3px 15px"}
            _hover={{
              bgColor: "#EAEBEF",
              borderRadius: "5px",
              transition: "all .3s ease",
            }}
          >
            <AccordionButton
              as={"a"}
              p={0}
              alignItems="center"
              justifyContent="center"
              fontSize="13px"
              fontWeight={400}
              href="/app/payroll/employeenotification"
              onClick={handleNavigation}
              borderRadius="3px"
              _hover={{ backgroundColor: "none" }}
              padding={0}
              style={{
                color:
                  location.pathname === "/app/payroll/employeenotification"
                    ? "#283C50"
                    : "#001327",

                backgroundColor:
                  location.pathname === "/app/payroll/employeenotification"
                    ? "customColor.blue.10"
                    : "initial",
              }}
            >
              <Icon
                as={IoMdNotifications}
                color="#283C50"
                w={"18px"}
                h={"18px"}
              />
              {(isHovered || isSidebarOpen) && (
                <Box
                  as="span"
                  flex={"1"}
                  textAlign="left"
                  ml={2}
                  fontWeight={"700"}
                >
                  On-demand Notifications
                </Box>
              )}
            </AccordionButton>
          </AccordionItem>

          {SideBarMenu.map((menu, index) => {
            return (
              <AccordionItem
                border={"none"}
                alignItems="center"
                margin={"3px 15px"}
                color={"#a8b0c5"}
                _hover={{ color: "customColor.gray.300" }}
                key={index}
              >
                {(isHovered || isSidebarOpen) && (
                  <Box padding={"15px 20px 10px"}>
                    <Text
                      fontSize="10px"
                      letterSpacing="1.28px"
                      textTransform="uppercase"
                      fontWeight="600"
                      opacity="0.9"
                      color={"primary.950"}
                    >
                      {menu?.btnlabel}
                    </Text>
                  </Box>
                )}

                {menu.menu.map((subMenu, index) => {
                  return (
                    <AccordionItem
                      border={"none"}
                      color={"#283C50"}
                      margin={0}
                      p={0}
                      key={index}
                    >
                      <AccordionButton
                        as={"a"}
                        marginBottom={"12px"}
                        padding={"10px 11px"}
                        fontSize="13px"
                        fontWeight={700}
                        borderRadius="3px"
                        _hover={{
                          bgColor: "#EAEBEF",
                          borderRadius: "5px",
                          transition: "all .3s ease",
                        }}
                        _expanded={{
                          bgColor: "#EAEBEF",
                          borderRadius: "5px",
                          transition: "all .3s ease",
                        }}
                        // onClick={() =>
                        //   handleAccordionToggle(
                        //     subMenu?.menuTitle
                        //   )
                        // }
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          w={"100%"}
                        >
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            gap={0}
                            flex={1}
                          >
                            <Icon
                              as={subMenu?.menuIcon}
                              color="#283C50"
                              fontSize={
                                isHovered || isSidebarOpen ? "18px" : "27px"
                              }
                              // w={isHovered || isSidebarOpen ? "18px" : "32px"}
                              // h={isHovered || isSidebarOpen ? "18px" : "32px"}
                            />
                            {(isHovered || isSidebarOpen) && (
                              <Box
                                marginLeft={"12px"}
                                as="span"
                                flex={"1"}
                                textAlign="left"
                                fontWeight={"700"}
                              >
                                {subMenu?.menuTitle}{" "}
                              </Box>
                            )}
                          </Stack>
                          {(isHovered || isSidebarOpen) && (
                            <AccordionIcon justifyItems={"flex-end"} />
                          )}
                        </Flex>
                      </AccordionButton>
                      {(isHovered || isSidebarOpen) && (
                        <AccordionPanel
                          p={0}
                          hidden={openItem !== subMenu.menuTitle}
                        >
                          <VStack spacing={0}>
                            {subMenu.menuItem?.map((item, index) => {
                              return (
                                <Stack
                                  paddingLeft={"30px"}
                                  width={"100%"}
                                  marginBottom={"5px"}
                                >
                                  <Button
                                    borderRadius="3px"
                                    key={index}
                                    alignItems={"center"}
                                    padding={"10px 20px 10px 15px"}
                                    as={"a"}
                                    style={{
                                      color:
                                        activeMenu === item?.href
                                          ? "#E97132"
                                          : "#283C50",
                                      backgroundColor:
                                        activeMenu === item?.href
                                          ? "#EAEBEF"
                                          : "initial",

                                      transition: "all .3s ease", // Adding transition for smooth hover effect
                                    }}
                                    variant="unstyled"
                                    isDisabled={
                                      item?.accessKey
                                        ? !access[item.accessKey]
                                        : false
                                    }
                                    w={"100%"}
                                    href={item?.href}
                                    fontSize="12px"
                                    fontWeight="600"
                                    textAlign="left"
                                    h={"33px"}
                                    onClick={handleNavigation}
                                    _selected={{
                                      bgColor: "#EAEBEF",
                                      borderRadius: "5px",
                                      transition: "all .3s ease",
                                      color: "#E97132",
                                    }}
                                    _hover={{
                                      bgColor: "#EAEBEF",
                                      borderRadius: "5px",
                                      transition: "all .3s ease",
                                      color: "#E97132", // Change text color on hover
                                    }}
                                  >
                                    {item.menulabel}
                                  </Button>
                                </Stack>
                              );
                            })}
                          </VStack>
                        </AccordionPanel>
                      )}
                    </AccordionItem>
                  );
                })}
              </AccordionItem>
            );
          })}
        </Accordion>
      </>
    </Stack>
  );
}
