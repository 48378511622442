import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PayrollHoliday } from "../../Api/PayRollHoliday";
import { PayrollHolidayGroup } from "../../Api/PayrollHolidayGroup";

import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import { AddHolidayGroup } from "./HolidayGroupModal/AddHolidayGroup";
import { DeleteGroup } from "./HolidayGroupModal/DeleteGroup";
import { AddHoliday } from "./HolidayModal/AddHoliday";
import { DeleteHoliday } from "./HolidayModal/DeleteHoliday";
interface HolidayProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Holiday({ setSelectedTab, selectedTab }: HolidayProps) {
  const addHolidayGroup = useDisclosure();
  const addHoliday = useDisclosure();
  const [holidayGroup, setHolidayGroup] = useState<
    PayrollHolidayGroup.HolidayGroup[]
  >([]);
  const [holiday, setHoliday] = useState<PayrollHoliday.Holiday[]>([]);

  const [lastUpdated, setLastUpdated] = useState<number>();
  const [selectedHolidayGroupId, setSelectedHolidayGroupId] =
    useState<string>();
  const [selectedHolidayId, setSelectedHolidayId] = useState<string>();

  const deleteModal = useDisclosure();
  const deleteHoliday = useDisclosure();
  const onEditGroup = (holidaysGroupId: string) => {
    const HolidayGroup = holidayGroup.find(
      (item) => item.id === holidaysGroupId
    );
    if (HolidayGroup) {
      holidayGroupForm.reset({
        groupName: HolidayGroup.groupName,
        id: HolidayGroup.id,
        businessId: HolidayGroup.businessId,
      });

      addHolidayGroup.onOpen();
    }
  };
  useEffect(() => {
    PayrollHoliday.FetchHolidayThroughGroupId(
      selectedHolidayGroupId!,
      (data) => {
        setHoliday(data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [selectedHolidayGroupId, lastUpdated]);
  useEffect(() => {
    PayrollHolidayGroup.FetchHolidayGroup(
      (data) => {
        setHolidayGroup(data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [lastUpdated]);
  const holidayGroupForm = useForm<PayrollHolidayGroup.HolidayGroup>({
    defaultValues: {
      groupName: "",
      id: "",
      businessId: "",
    },
  });
  const holidayForm = useForm<PayrollHoliday.Holiday>({
    defaultValues: {
      id: "",
      holidaysGroupId: "",
      name: "",
      date: "",
    },
  });
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const sortData = (field: string) => {
    const sortedData = [...holiday].sort((a, b) => {
      const aValue = a[field as keyof PayrollHoliday.Holiday];
      const bValue = b[field as keyof PayrollHoliday.Holiday];
      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setHoliday(sortedData);
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <Fragment>
      <Stack
        marginTop={"15px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <FormProvider {...holidayForm}>
          <AddHoliday
            isOpen={addHoliday.isOpen}
            onClose={addHoliday.onClose}
            selectedHolidayId={selectedHolidayId}
            setLastUpdated={setLastUpdated}
          />

          <DeleteHoliday
            isOpen={deleteHoliday.isOpen}
            onClose={deleteHoliday.onClose}
            setLastUpdated={setLastUpdated}
          />
        </FormProvider>
        <FormProvider {...holidayGroupForm}>
          <AddHolidayGroup
            isOpen={addHolidayGroup.isOpen}
            onClose={addHolidayGroup.onClose}
            setLastUpdated={setLastUpdated}
          />
          <DeleteGroup
            isOpen={deleteModal.isOpen}
            onClose={deleteModal.onClose}
            setLastUpdated={setLastUpdated}
            onDelete={() => {
              setSelectedHolidayGroupId(holidayGroup?.[0]?.id);
            }}
          />
        </FormProvider>
        <Box>
          <Stack>
            <Flex p="10px" alignItems={"center"}>
              <VStack alignItems="start" spacing="0px">
                <Text
                  as="b"
                  fontSize="18px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                >
                  Set up your holidays
                </Text>
                <Text
                  fontSize="12px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                >
                  Holidays help us be more accurate when calculating leave for
                  your employees.
                </Text>
              </VStack>
              <Tooltip
                label="
Adding holidays typically means incorporating specific dates designated as non-working days into a schedule or calendar."
                fontSize="sm"
                padding={"10px"}
                bg={"#fff"}
                border={"1px solid #ddd"}
                _light={{
                  color: "customColor.black7",
                }}
                maxW={"300px"}
                placement="auto"
              >
                <Button
                  position="absolute"
                  right="15px"
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    holidayForm.reset({
                      holidaysGroupId: "",
                      date: "",
                      id: "",
                      name: "",
                    });
                    setSelectedHolidayId(undefined);
                    addHoliday.onOpen();
                  }}
                >
                  Add Holidays
                </Button>
              </Tooltip>
            </Flex>
            <Divider marginTop="10px" orientation="horizontal" />

            <Stack
              direction={"row"}
              gap={0}
              w={"100%"}
              marginTop={"30px"}
              px="15px"
            >
              <Stack gap={0}>
                <Button
                  justifyContent="start"
                  textAlign="left"
                  border="1px solid #ddd"
                  fontSize="12px"
                  h={"40px"}
                  w={"268px"}
                  bg={"#FFF"}
                  padding={"10px 15px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.200",
                  }}
                  _hover={{
                    bg: "#FAFBFB",
                  }}
                  _selected={{
                    bg: "#e6f5fa",
                    border: "1px solid #ddd",
                  }}
                >
                  All Holidays
                </Button>
                {holidayGroup.length > 0 ? (
                  holidayGroup.map((holidayGroup) => (
                    <Button
                      key={holidayGroup.id}
                      justifyContent="left"
                      border="1px solid #ddd"
                      fontSize="12px"
                      h={"40px"}
                      w={"268px"}
                      bg={"#FFF"}
                      padding={"10px 15px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.200",
                      }}
                      _hover={{
                        bg: "#FAFBFB",
                      }}
                      _selected={{
                        bg:
                          selectedHolidayGroupId === holidayGroup.id
                            ? "#e6f5fa"
                            : "#FFF",
                        border: "1px solid #ddd",
                      }}
                      onClick={() => {
                        setSelectedHolidayGroupId(holidayGroup.id);
                      }}
                    >
                      {holidayGroup.groupName}
                    </Button>
                  ))
                ) : (
                  <></>
                )}
                <Tooltip
                  label="
Adding a group of holidays involves including a set of designated non-working days, often related to cultural, religious, or national events, into a schedule or calendar."
                  fontSize="sm"
                  padding={"10px"}
                  bg={"#fff"}
                  border={"1px solid #ddd"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  maxW={"300px"}
                  placement="auto"
                >
                  <Box
                    as="button"
                    paddingLeft="15px"
                    textAlign="left"
                    border="1px solid #ddd"
                    fontSize="12px"
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                    }}
                    color="#3454D1"
                    h="40px"
                    _hover={{ bg: "#3454D1" }}
                    _selected={{ bg: "#e6f5fa" }}
                    onClick={() => {
                      holidayGroupForm.reset({
                        groupName: "",
                        id: "",
                        businessId: "",
                      });
                      setSelectedHolidayGroupId(undefined);
                      addHolidayGroup.onOpen();
                    }}
                  >
                    Add Group
                  </Box>
                </Tooltip>
              </Stack>

              <Stack
                paddingLeft={{ base: "0", md: "16px" }}
                paddingRight={{ base: "0", md: "16px" }}
                width="100%"
              >
                {holidayGroup.length > 0 ? (
                  <Box bg="#F8FAFA" borderRadius="5px" border="1px solid #ddd">
                    <HStack gap={0} alignItems="baseline">
                      <Heading
                        bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                        padding="10px"
                        paddingTop="15px"
                        fontSize="18px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{ color: "customColor.white" }}
                      >
                        {holidayGroup.length > 0
                          ? holidayGroup.find(
                              (item) => item.id === selectedHolidayGroupId
                            )?.groupName
                          : "All Holidays"}
                      </Heading>
                      <Button
                        variant={"Link"}
                        fontSize={"12px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        padding={"5px"}
                        _hover={{
                          _light: { color: "blue" },
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          onEditGroup(selectedHolidayGroupId!);
                        }}
                      >
                        Edit
                      </Button>
                      <Text
                        fontSize={"12px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        |
                      </Text>
                      <Button
                        variant={"Link"}
                        fontSize={"12px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        padding={"5px"}
                        _hover={{
                          _light: { color: "#EA494C" },

                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          holidayGroupForm.reset({
                            id: selectedHolidayGroupId,
                          });

                          deleteModal.onOpen();
                        }}
                      >
                        Delete
                      </Button>
                    </HStack>

                    <Divider marginTop="10px" orientation="horizontal" />
                    <Table
                      variant="simple"
                      w="100%"
                      overflowX="auto"
                      _light={{
                        bg: "#fff",
                      }}
                    >
                      <Thead h={"36.8px"}>
                        <Tr
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                        >
                          {[
                            {
                              name: "Name",
                              value: "45%",
                              paddingValue: "8px 26px 8px 30px",
                              field: "name",
                            },
                            {
                              name: "Date",
                              value: "45%",
                              paddingValue: "8px 26px 8px 15px",
                              field: "date",
                            },
                            {
                              name: "",
                              value: "10%",
                              field: "",
                              paddingValue: "8px 26px 8px 15px",
                            },
                          ].map((item, i) => {
                            return (
                              <Th
                                key={i}
                                padding={item.paddingValue}
                                borderBottomColor="borders.tableBorder"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                fontWeight={700}
                                whiteSpace="nowrap"
                                fontSize="12px"
                                textTransform="none"
                                width={item.value}
                                cursor="pointer"
                                onClick={() =>
                                  item.field && sortData(item.field)
                                } // Call sort function on click
                              >
                                {item.name}
                                {sortField === item.field && (
                                  <Icon
                                    as={
                                      sortOrder === "asc"
                                        ? AiOutlineSortAscending
                                        : AiOutlineSortDescending
                                    }
                                    ml="5px"
                                  />
                                )}
                              </Th>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody color="#555" padding="10px">
                        {holiday.length > 0
                          ? holiday.map((item, i) => {
                              return (
                                <Tr
                                  key={i}
                                  _hover={{
                                    bgColor: "#f2f3f8",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    holidayForm.reset({
                                      holidaysGroupId: item.holidaysGroupId,
                                      date: item.date,
                                      id: item.id,
                                      name: item.name,
                                    });

                                    setSelectedHolidayId(item.id);
                                    addHoliday.onOpen();
                                  }}
                                >
                                  <Td
                                    padding="15px 15px 15px 30px"
                                    fontSize="13.44px"
                                    color={"customColor.black7"}
                                    _dark={{
                                      color: "customColor.dark.150",
                                    }}
                                    lineHeight={1.5}
                                    fontWeight={500}
                                    borderTopWidth="1px"
                                    borderBottomWidth="0px"
                                    borderTopColor={"borders.tableBorder"}
                                  >
                                    {item.name}
                                  </Td>
                                  <Td
                                    padding="15px"
                                    fontWeight={500}
                                    fontSize="13.44px"
                                    color={"customColor.black7"}
                                    _dark={{
                                      color: "customColor.dark.150",
                                    }}
                                    lineHeight={1.5}
                                    borderTopWidth="1px"
                                    borderBottomWidth="0px"
                                    borderTopColor={"borders.tableBorder"}
                                  >
                                    {moment(item.date).format(
                                      "dddd, DD MMM YYYY"
                                    )}
                                  </Td>
                                  <Td
                                    padding="15px"
                                    textAlign={"right"}
                                    fontWeight={500}
                                    fontSize="13.44px"
                                    color={"customColor.black7"}
                                    _dark={{
                                      color: "customColor.dark.150",
                                    }}
                                    lineHeight={1.5}
                                    borderTopWidth="1px"
                                    borderBottomWidth="0px"
                                    borderTopColor={"borders.tableBorder"}
                                    onClick={() => {
                                      holidayForm.reset({
                                        holidaysGroupId: item.holidaysGroupId,
                                        date: item.date,
                                        id: item.id,
                                        name: item.name,
                                      });
                                      deleteHoliday.onOpen();
                                    }}
                                  >
                                    <Icon
                                      fontSize={"20px"}
                                      as={IoIosCloseCircleOutline}
                                      color={"grey"}
                                      _hover={{
                                        color: "red.500",
                                      }}
                                    />
                                  </Td>
                                </Tr>
                              );
                            })
                          : ""}
                      </Tbody>
                    </Table>
                  </Box>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems={"flex-end"} margin={"20px 20px 0px 0px"}>
            <Button
              colorScheme={"green"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.white"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        </Box>

        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Stack>
    </Fragment>
  );
}
