import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";
import SetupGuideComponent from "./SetupGuideComponent";

interface SetupGuideProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function SetupGuide({ isOpen, onClose }: SetupGuideProps) {
  const { onToggle } = useDisclosure();

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody maxW={"360px"} padding={0}>
          <Stack border={"1px solid #ddd"}>
            <Stack
              direction={"row"}
              h={"56px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"8px"}
              _light={{
                bg: "primary.950",
              }}
              _dark={{
                bg: "#002e50",
              }}
            >
              <Text
                fontSize={"18px"}
                padding={"0px 0px 0px 16px"}
                _light={{
                  color: "customColor.white",
                }}
                _dark={{
                  color: "custom.pearlWhite",
                }}
              >
                Setup Guide
              </Text>
              <Stack
                _hover={{
                  bg: "#004c81",
                }}
                boxSize={"40px"}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={onToggle}
              >
                <Icon as={FaChevronDown} color={"#fff"} onClick={onClose} />
              </Stack>
            </Stack>
            <SetupGuideComponent />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
