import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { GiOfficeChair } from "react-icons/gi";
import {
  MdOutlineCalendarMonth,
  MdOutlineEmojiObjects,
  MdOutlineHolidayVillage,
  MdOutlinePayments,
} from "react-icons/md";
import Calendartab from "../Calendar/Calendartab";
import Holiday from "../Holiday/Holiday";
import PayItem from "../PayRollSetting/PayItems/PayItem";
import OrgTab from "./OrgtabPanel";
import "./tablist.css";
import { TaxSlabs } from "./TaxSlabs/TabSlabs";

export default function Orgtab() {
  const tabList: Array<{
    label: string;
    index: number;
    icon?: any;
    labelText?: string;
  }> = [
    {
      label: "Account setup",
      icon: <GiOfficeChair />,
      index: 0,
      labelText:
        "An Account setup is a structured entity that operates with a specific purpose, such as a company, non-profit, or governmental body.",
    },
    {
      label: "Calendars",
      icon: <MdOutlineCalendarMonth />,
      index: 1,
      labelText:
        "Calendars are tools used to organize and track time, events, and schedules, facilitating planning and coordination.",
    },
    {
      label: "Holiday",
      icon: <MdOutlineHolidayVillage />,
      index: 2,
      labelText:
        "A holiday is a designated day of rest or celebration, typically observed by individuals, communities, or nations, often with specific cultural or religious significance.",
    },
    {
      label: "Pay Items",
      icon: <MdOutlineEmojiObjects />,
      index: 3,
      labelText:
        "Pay items refer to specific components of an employee's compensation, such as salary, wages, bonuses, or allowances, delineated for payroll processing and accounting purposes.",
    },
    // { label: "Provident/Gratuity", icon: <AiOutlineFundView />, index: 4 },
    // { label: "EOBI", icon: <AiOutlineFundView />, index: 5 },
    // { label: "Social Security", icon: <AiOutlineFundView />, index: 6 },
    {
      label: "Tax Slabs",
      icon: <MdOutlinePayments />,
      index: 4,
      labelText:
        "Tax slabs are predefined ranges of income levels set by tax authorities, each corresponding to a specific tax rate, used to determine the amount of tax owed by individuals or businesses.",
    },
  ];

  const [isLargerThanMD] = useMediaQuery("(min-width: 1000px)");
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <Box
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Container
        justifyContent="center"
        maxW={"100%"}
        alignItems="center"
        textAlign={"left"}
        p="30px 20px"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Heading
          padding="10px"
          fontSize="24px"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{ color: "customColor.white" }}
          fontWeight="bold"
        >
          Payroll settings
        </Heading>
        <Divider orientation="horizontal" />
        <HStack>
          <Image
            borderRadius="0"
            w="179px"
            h="114px"
            margin="20px 20px 20px 10px"
            src="https://edge.xero.com/people/payroll/assets/images/video-thumbs/settings-video.png"
            alt="Payroll Settings"
          />
          <VStack
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            fontSize="18px"
            align="left"
          >
            <Text
              fontWeight="bold"
              _light={{
                color: "customColor.black7",
              }}
            >
              Add bank details, accounts, calendars & pay items via the tabs
              below.
            </Text>
            <Divider orientation="horizontal" />
            <Text
              _light={{
                color: "customColor.black7",
              }}
            >
              You can also set up your Provident Fund funds too.
            </Text>
            <Divider orientation="horizontal" />
            <Text
              _light={{
                color: "customColor.black7",
              }}
            >
              There are plenty of handy tips in the{" "}
              <Link
                isExternal
                href="https://central.xero.com/s/article/Set-up-your-organisation-with-payroll"
                color="#3454d1"
              >
                help guide for HRIS 360 Payroll.
              </Link>
            </Text>
          </VStack>
        </HStack>
      </Container>
      <Container
        maxW={"100%"}
        mt="10px"
        paddingRight={"20px"}
        paddingLeft={"1px"}
      >
        <Box>
          <Tabs
            index={selectedTab}
            orientation={isLargerThanMD ? "vertical" : "horizontal"}
            onChange={setSelectedTab}
            isLazy
          >
            {isLargerThanMD ? (
              <Stack
                w="29%"
                spacing={0}
                marginTop={"15px"}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
                marginBottom={"1.25rem"}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                borderWidth="1px"
                borderStyle={"solid"}
                borderColor="#f6f6f6"
                padding={"20px"}
              >
                {/* <Box
                  position="sticky"
                  top="10px"
                  // zIndex="sticky"
                  _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
                > */}
                {tabList.map((label, i) => {
                  return (
                    <Box key={i}>
                      <Tab
                        marginLeft="0px"
                        justifyContent="flex-start"
                        w="100%"
                        p={"5px 15px"}
                        key={i}
                        marginBottom={"5px"}
                        fontSize="13px"
                        borderRadius={"3px"}
                        cursor="pointer"
                        color="#74788D"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _selected={{
                          bgColor: "#EAEBEF",
                          color: "customColor.black8",
                          borderRadius: "5px",
                          transition: "all .3s ease",
                        }}
                        _hover={{
                          bgColor: "#EAEBEF",
                          color: "customColor.black8",
                          borderRadius: "5px",
                          transition: "all .3s ease",
                        }}
                        onClick={() => setSelectedTab(label.index)}
                      >
                        <Tooltip
                          label={label.labelText}
                          fontSize="sm"
                          padding="10px"
                          bg="#fff"
                          border="1px solid #ddd"
                          color="black"
                          maxW="300px"
                          placement="auto"
                        >
                          <Stack
                            direction={"row"}
                            gap={0}
                            alignItems={"center"}
                            _hover={{
                              color: "customColor.black8",
                            }}
                          >
                            <Button
                              as={IconButton}
                              color={
                                selectedTab === label.index
                                  ? "#001327"
                                  : "#283C50"
                              }
                              icon={label.icon}
                              variant={"ghost"}
                              padding={"0px"}
                              _hover={{
                                bg: "transparent",
                              }}
                            />

                            <Text
                              fontSize={"13px"}
                              fontWeight={"600"}
                              _hover={{
                                color: "customColor.black8",
                              }}
                            >
                              {label.label}
                            </Text>
                          </Stack>
                        </Tooltip>
                      </Tab>
                    </Box>
                  );
                })}
                {/* </Box> */}
              </Stack>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<AiOutlineCaretDown />}
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      color: "primary.950",
                      backgroundColor: "#fff",
                    }}
                    cursor={"pointer"}
                    _active={{
                      backgroundColor: "#fff",
                      color: "primary.950",
                    }}
                  >
                    {tabList.find((tab) => tab.index === selectedTab)?.label ??
                      ""}
                  </MenuButton>
                  <MenuList zIndex={"overlay"}>
                    {tabList.map((tab, i) => (
                      <MenuItem
                        key={i}
                        fontSize={"13.44px"}
                        onClick={() => {
                          setSelectedTab(tab.index);
                        }}
                      >
                        {tab.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
            <Stack padding={"10px"}></Stack>
            <TabPanels overflowY="auto">
              <TabPanel padding={0}>
                <OrgTab
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel padding={0}>
                <Calendartab
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>

              <TabPanel padding={0}>
                <Holiday
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel padding={0}>
                <PayItem
                  setSelectedTabForStatutory={setSelectedTab}
                  selectedTabForStatutory={selectedTab}
                />
              </TabPanel>
              {/* <TabPanel padding={0}>
                <PayrollProvidentFund />
              </TabPanel>
              <TabPanel padding={0}>
                <EOBI />
              </TabPanel>
              <TabPanel padding={0}>
                <EmployeeSocialSecurity />
              </TabPanel> */}
              <TabPanel padding={0}>
                <TaxSlabs />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    </Box>
  );
}
