import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { BusinessTaxRate } from "../../../../Api/BusinessTaxRate";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
  setLastUpdated: (time: number) => void;
}
export default function DeleteModal({
  isOpen,
  onClose,
  id,
  setLastUpdated,
}: DeleteProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          fontSize={"0.8125rem"}
          fontWeight={"600"}
          _light={{
            color: "customColor.black7",
          }}
        >
          <p>Are you sure you want to delete this tax Rate</p>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              BusinessTaxRate.DeleteTaxRates(
                id ?? "",
                () => {
                  toast({
                    title: "Tax Rate Deleted Successfully",
                    status: "success",
                  });
                  setIsLoading(false);
                  setLastUpdated(Date.now());
                  onClose();
                },
                () => {
                  toast({
                    title: "Error Deleting Tax Rate",
                    status: "error",
                  });
                  setIsLoading(false);
                }
              );
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
