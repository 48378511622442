import {
  Button,
  Heading,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ATOConnection } from "./ATOConnections";
import { OpeningBalance } from "./OpeningBalance";

export default function SingleTouchSettings() {
  const tabsOrientation: "horizontal" | "vertical" | undefined =
    useBreakpointValue({
      xs: "horizontal",
      md: "vertical",
    });
  const navigate = useNavigate();
  return (
    <Stack paddingBottom={"15px"}>
      <Stack bg={"#f4f5f6"}>
        <Stack
          h="76px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderBottom={"1px solid #d6dade"}
        >
          <HStack padding={"15px"}>
            <Stack display={"flex"} flex={1}>
              <Link
                padding={"0px 8px 0px 0px"}
                marginBottom={"-5px"}
                fontSize={"13px"}
                lineHeight={"1.25rem"}
                color={"#0078C8"}
                href="http://localhost:3000/app/payroll/PayrollEmployee"
              >
                Single Touch Payroll
              </Link>
              <Heading
                fontWeight={"bold"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black",
                }}
                fontSize={"17px"}
                padding={"0px 12px 0px 0px"}
              >
                Settings
              </Heading>
            </Stack>

            <Menu>
              <MenuButton
                as={Button}
                padding={"6px 10px"}
                backgroundColor={"transparent"}
                h={"34.5px"}
                marginLeft={"8px"}
                color={"rgba(0,10,30,.65)"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                _hover={{
                  backgroundColor: "#f2f3f4",
                  color: "black",
                }}
                borderRadius={"full"}
                _active={{
                  backgroundColor: "transparent",
                }}
              >
                <Icon as={FiMoreVertical} />
              </MenuButton>
              <MenuList style={{ minWidth: "140px" }}>
                {[
                  {
                    name: "Pay Runs",
                    onClick: () => navigate("/app/payroll/PayEmployee"),
                  },
                  {
                    name: "STP Settings",
                    onClick: () => window.location.reload(),
                  },
                ].map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"1px 0px 1px 20px"}
                      _light={{
                        color: "customColor.black2",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                    >
                      <Button
                        variant={"ghost"}
                        fontWeight={"normal"}
                        fontSize={"13px"}
                        padding={"0px 20px 0px 0px"}
                        _light={{
                          color: "customColor.black2",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        _hover={{
                          backgroundColor: "transparent",
                          color: "#32465A",
                        }}
                        onClick={item.onClick}
                      >
                        {item.name}
                      </Button>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
        <Stack
          minHeight="auto"
          marginTop={"15px"}
          marginLeft={"auto"}
          marginRight={"auto"}
          backgroundColor={"#F5F6F7"}
          // width={"940px"}
          w={{ base: "100%", md: "100%", lg: "940px" }}
          background={"none"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Tabs
            border={"1px solid #ccced2"}
            variant={"unstyled"}
            tabIndex={0}
            // orientation="vertical"
            orientation={tabsOrientation}
          >
            <TabList
              padding={"12px 0px"}
              border={"1px solid #ccced2"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
              display="flex"
              justifyContent="flex-start"
              minW={"200px"}
              alignItems={"flex-start"}
            >
              <Tab
                fontSize={"13px"}
                _selected={{
                  color: "primary.950",
                }}
                _hover={{
                  bg: "rgba(0,10,30,.05);",
                  textAlign: "left",
                }}
              >
                ATO connection
              </Tab>
              <Tab
                fontSize={"13px"}
                _selected={{
                  color: "primary.950",
                }}
                _hover={{
                  bg: "rgba(0,10,30,.05);",
                  textAlign: "left",
                }}
              >
                Opening balances
              </Tab>
            </TabList>
            <TabIndicator
              border="2px solid #2196F3"
              borderRadius="1px"
              height="2px"
            />
            <TabPanels>
              <TabPanel padding={0}>
                <ATOConnection />
              </TabPanel>
              <TabPanel padding={0}>
                <OpeningBalance />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Stack>
    </Stack>
  );
}
