import {
  Button,
  ButtonGroup,
  HStack,
  Image,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import HRISLOGO from "../../../assets/hris-logo.png";

export default function MainNavBar() {
  const navigate = useNavigate();
  return (
    <HStack
      marginLeft={{ xl: "30px", lg: "20px", md: "25px", xs: "20px" }}
      marginRight={"28px"}
      bg={"transparent"}
      height={"60px"}
      // boxSizing="content-box"
    >
      <Image
        objectFit="fill"
        w="100px"
        src={HRISLOGO}
        alt="Logo"
        onClick={() => navigate("/#")}
      />
      <HStack flex={1} display={{ xs: "none", xl: "flex" }}>
        <UnorderedList display={"flex"} styleType={"none"}>
          {[
            "Features",
            "Pricing",
            "For small business",
            "For accountants and bookkeepers",
            "Support",
          ].map((item, i) => {
            return (
              <ListItem
                key={i}
                as={Button}
                variant={"link"}
                fontSize={"15.6px"}
                fontWeight={"normal"}
                marginX={{ lg: "3px", xl: "10px" }}
                // letterSpacing={"0px"}
                lineHeight={"1.25"}
                iconSpacing="2px"
                rightIcon={<MdKeyboardArrowDown color="rgb(0, 137, 220)" />}
                marginBottom={"0px"}
                color={"black"}
                backgroundPosition={"0px 1.41667rem"}
                backgroundSize={"0.0833333rem 0.166667rem"}
                _hover={{
                  textDecoration: "2px underline rgb(0, 137, 220)",
                  textUnderlineOffset: "0.2rem",
                }}
              >
                {item}
              </ListItem>
            );
          })}
        </UnorderedList>
      </HStack>
      <HStack flex={1}></HStack>
      <Button
        rightIcon={<FiMenu />}
        display={{ lg: "flex", xl: "none" }}
        variant={"link"}
        color={"black"}
        onClick={() => navigate("/menu")}
      >
        Menu
      </Button>

      <ButtonGroup display={{ xs: "none", lg: "flex" }}>
        <Button
          size={"sm"}
          // backgroundColor={"rgb(80,220,170)"}
          background={"primary.500"}
          borderRadius={"3px"}
          fontSize={"15px"}
          height={"35.2px"}
          variant={"solid"}
          textColor={"#172C40s"}
          lineHeight={"2.66667rem"}
          // border={'0.166667rem solid rgb(80, 220, 170)'}
          padding={"0px 1.16667rem"}
          fontWeight={"bold"}
          // _hover={{
          //   // backgroundColor: "rgb(74, 196, 157)",
          //   color: "white",
          //   bgColor: "primary.950",

          //   // border: "0.2rem solid rgb(74, 196, 157)"
          // }}
          onClick={() => {
            navigate("/signup");
          }}
        >
          Sign Up
        </Button>
        <Button
          size={"sm"}
          // variant={"outline"}
          backgroundColor={"primary.500"}
          color={"white"}
          borderRadius={"3px"}
          fontSize={"15px"}
          height={"35.2px"}
          width={"73.09px"}
          lineHeight={"2.66667rem"}
          textColor={"#213B55"}
          border={"0.166667rem solid primary.400"}
          // _hover={{
          //   backgroundColor: "primary.950",
          //   color: "white",
          //   border: "none",
          // }}
          onClick={() => {
            navigate("/login");
          }}
        >
          Log in
        </Button>
      </ButtonGroup>
    </HStack>
  );
}
