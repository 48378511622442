import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { BsBook } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";

interface TableForReportProps {
  options: string[];
  headers: { label: string }[];
  tableBody: {
    employee: string;
    employeeGroup: string;
    grossPayment: string;
    rfba: string;
    resc: string;
    lumpsum: string;
    allowanace: string | undefined;
    taxWithheld: string;
    workplace: string;
    unionFee: string;
  }[];
  colBtns: {
    colItem: {
      colLabel: string;
    }[];
  }[];

  isLoading?: boolean;
  // tabRef: any;
}

export function TableForReport({
  headers,
  isLoading,
  tableBody,
  options,
  colBtns,
}: TableForReportProps) {
  const [selectedOptions, setSelectedOptions] = useState("Standard View");

  const handleOptionSelect = (option: any) => {
    setSelectedOptions(option);
  };
  return (
    <Stack paddingBottom={"30px"}>
      {/* <Skeleton height="100%" isLoaded={!isLoading}> */}
      <Skeleton height="100%" isLoaded={!isLoading}>
        <Stack
          borderRadius={"3px"}
          boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
          padding={"24px 32px"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
        >
          {/* <Table variant="simple" w="100%" ref={tabRef}> */}

          <Box overflowX="auto">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text paddingLeft={"12px"} fontSize={"20px"} fontWeight={"700"}>
                37 Results
              </Text>
              <Stack direction={"row"}>
                <Stack>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      w={"100%"}
                      textAlign={"start"}
                      h={"40px"}
                      _light={{
                        bgColor: "customColor.white",
                      }}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      color={"black"}
                      fontWeight={"400"}
                      border={"1px solid #b7bec5"}
                      borderRadius={"5px"}
                      _hover={{
                        color: "black",
                        bg: "#f2f3f4",
                        border: "1px solid #a6a9b0",
                      }}
                      _active={{
                        color: "black",
                        bg: "#f2f3f4",
                        border: "1px solid #a6a9b0",
                      }}
                    >
                      {selectedOptions}
                    </MenuButton>
                    <MenuList
                      overflowY="auto"
                      zIndex={"overlay"}
                      overflow={"auto"}
                      maxH={"300px"}
                    >
                      {options.map((item, i) => {
                        return (
                          <MenuItem
                            key={1}
                            padding={"0px 12px"}
                            _hover={{
                              backgroundColor: "rgba(0,10,30,.1)",
                            }}
                            onClick={() => handleOptionSelect(item)}
                          >
                            <Text
                              padding={"8px"}
                              margin={"0px 12px 0px 0px"}
                              _light={{
                                color: "customColor.black5",
                              }}
                              _dark={{
                                color: "customColor.pearlWhite",
                              }}
                              fontSize={"15px"}
                            >
                              {item}
                            </Text>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </Stack>
                <Stack>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={IconButton}
                      icon={<BsBook />}
                      minWidth={"40px"}
                      textAlign={"start"}
                      h={"40px"}
                      color={"black"}
                      fontWeight={"400"}
                      border={"1px solid #b7bec5"}
                      borderRadius={"5px"}
                      bgColor={"#fff"}
                      _hover={{
                        color: "black",
                        bg: "#f2f3f4",
                        border: "1px solid #a6a9b0",
                      }}
                      _active={{
                        color: "black",
                        bg: "#f2f3f4",
                        border: "1px solid #a6a9b0",
                      }}
                    ></MenuButton>
                    <MenuList
                      w={"200px"}
                      maxH="300px"
                      overflowY="auto"
                      zIndex={"overlay"}
                    >
                      {colBtns.map((items, k) => {
                        return (
                          <MenuGroup
                            fontSize={"13px"}
                            color={"#404756"}
                            fontWeight={"normal"}
                            whiteSpace={"nowrap"}
                            key={k}
                          >
                            {items.colItem.map((list, j) => {
                              return (
                                <MenuItem
                                  key={j}
                                  fontSize={"15px"}
                                  padding={"9px 15px"}
                                  _dark={{
                                    color: "customColor.dark.150",
                                  }}
                                  _light={{
                                    color: "customColor.black",
                                  }}
                                >
                                  <Checkbox paddingRight={"10px"} size={"lg"} />

                                  {list.colLabel}
                                </MenuItem>
                              );
                            })}

                            {k !== colBtns.length - 1 && <MenuDivider />}
                          </MenuGroup>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </Stack>
                <Stack>
                  <InputGroup ml={[0, "0px"]}>
                    <InputLeftElement>
                      <Icon as={FiSearch} />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder="Search"
                      borderRadius={"3px"}
                      h={["auto", "40px"]}
                      _focusVisible={{
                        border: "1px solid grey",
                      }}
                    />
                  </InputGroup>
                </Stack>
              </Stack>
            </Stack>
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h="45.4px">
                <Tr
                  color={"customColor.tableHeaderColor"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {headers.map((column, index) => {
                    return (
                      <Th
                        key={index}
                        padding="12px 30px 12px 12px"
                        borderBottomColor="inherit"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={600}
                        whiteSpace="nowrap"
                        fontSize="14px"
                        textTransform="none"
                        flex={1}
                        width={"100px"}
                      >
                        {column.label}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody borderTop={"1.5px solid rgba(230,231,239,0.85)"}>
                {tableBody.map((item, i) => {
                  return (
                    <Tr
                      key={i}
                      _hover={{
                        bgColor: "rgba(0,0,0,.075)",
                        cursor: "pointer",
                      }}
                    >
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.employee}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.employeeGroup}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.grossPayment}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.rfba}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.resc}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.lumpsum}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.allowanace}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.taxWithheld}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.workplace}
                      </Td>
                      <Td
                        padding="12px"
                        fontSize="14px"
                        color={"customColor.tableHeaderColor"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="inherit"
                        flex={1}
                      >
                        {item.unionFee}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </Skeleton>
    </Stack>
  );
}
