import {
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Controller, useForm } from "react-hook-form";
import { GoLock } from "react-icons/go";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import Hrislogo from "../../../assets/HRISMainLogo.png";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Authentication } from "../../Api/Authentication";

export function ChangePassword() {
  const toast = useToast();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const { currentEmployeeId } = useBusinessContext();
  const [queryParams] = useSearchParams();
  const { control, handleSubmit, watch } =
    useForm<Authentication.ResetPassword>({
      defaultValues: {
        password: "",
        token: queryParams.get("token") ?? "",
      },
    });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  // Watch the password field to compare with confirm password
  const password = watch("password");
  const passwordsMatch = password === confirmPassword;

  return (
    <Stack
      gap={0}
      _dark={{ bgColor: "customColor.dark.100" }}
      _light={{ bgColor: "customColor.gray.400" }}
      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Container maxW="container.sm" padding="40px 20px">
        <Stack
          gap={0}
          transition="all .3s ease"
          boxShadow="0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
          borderRadius="10px"
          _light={{ bg: "#fff" }}
          padding="50px"
        >
          <Flex
            justifyContent="space-between"
            align="center"
            justify="center"
            // w={"254.33px"}
            // h="70px"
          >
            <Image
              objectFit="fill"
              w="115px"
              margin={"0px 0px 24px"}
              src={Hrislogo}
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
            />
          </Flex>
          <Text
            fontSize="24px"
            _light={{ color: "customColor.black7" }}
            _dark={{ color: "customColor.white" }}
            fontWeight="700"
            marginBottom="24px"
          >
            Change Password
          </Text>
          <Text
            fontSize="13.44px"
            _light={{ color: "customColor.gray.64748B" }}
            _dark={{ color: "customColor.white" }}
            fontWeight="500"
          >
            To change your password, please fill in the fields below.
          </Text>
          <Text
            fontSize="13.44px"
            _light={{ color: "customColor.gray.64748B" }}
            _dark={{ color: "customColor.white" }}
            fontWeight="500"
            marginBottom="24px"
          >
            Your password must contain at least 8 characters, it must also
            include at least one upper case letter, one lower case letter, one
            number, and one special character.
          </Text>
          <Stack gap={0} marginBottom="24px">
            <Text
              fontSize="13.44px"
              _light={{ color: "customColor.black7" }}
              _dark={{ color: "customColor.white" }}
              fontWeight="600"
              marginBottom="8px"
            >
              Enter New Password
            </Text>
            <InputGroup>
              <InputLeftAddon h="40px">
                <GoLock />
              </InputLeftAddon>
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState: { invalid } }) => (
                  <Stack width="100%">
                    <Input
                      placeholder="Enter New Password"
                      borderRadius="0px"
                      borderRightRadius="5px"
                      type={showPassword ? "text" : "password"}
                      h="40px"
                      fontSize=".845rem"
                      fontWeight={400}
                      _light={{ color: "customColor.black7" }}
                      padding="12px 15px"
                      border="1px solid #e5e7eb"
                      _hover={{ border: "1px solid hsl(0, 0%, 70%)" }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focus={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      isInvalid={invalid}
                      {...field}
                    />
                    <InputRightElement>
                      <IconButton
                        fontWeight="900"
                        fontStyle="normal"
                        color="#555b6d"
                        _hover={{ bg: "none" }}
                        aria-label="Toggle password visibility"
                        icon={showPassword ? <IoIosEyeOff /> : <IoMdEye />}
                        variant="outline"
                        borderWidth="0px"
                        h="24px"
                        w="20px"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                  </Stack>
                )}
              />
            </InputGroup>
          </Stack>

          {/* Confirm Password */}
          <Stack gap={0} marginBottom="24px">
            <Text
              fontSize="13.44px"
              _light={{ color: "customColor.black7" }}
              _dark={{ color: "customColor.white" }}
              fontWeight="600"
              marginBottom="8px"
            >
              Confirm New Password
            </Text>
            <InputGroup>
              <InputLeftAddon h="40px">
                <GoLock />
              </InputLeftAddon>
              <Stack width="100%">
                <Input
                  placeholder="Confirm New Password"
                  borderRadius="0px"
                  borderRightRadius="5px"
                  type={showConfirmPassword ? "text" : "password"}
                  h="40px"
                  fontSize=".845rem"
                  fontWeight={400}
                  _light={{ color: "customColor.black7" }}
                  padding="12px 15px"
                  border="1px solid #e5e7eb"
                  _hover={{ border: "1px solid hsl(0, 0%, 70%)" }}
                  _focusVisible={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _focus={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isInvalid={!passwordsMatch && confirmPassword !== ""}
                />
                <InputRightElement>
                  <IconButton
                    fontWeight="900"
                    fontStyle="normal"
                    color="#555b6d"
                    _hover={{ bg: "none" }}
                    aria-label="Toggle confirm password visibility"
                    icon={showConfirmPassword ? <IoIosEyeOff /> : <IoMdEye />}
                    variant="outline"
                    borderWidth="0px"
                    h="24px"
                    w="20px"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </InputRightElement>
              </Stack>
            </InputGroup>
            {!passwordsMatch && confirmPassword !== "" && (
              <Text color="red.500" fontSize="12px" mt="2">
                Passwords do not match
              </Text>
            )}
          </Stack>

          {/* Submit and Cancel Buttons */}
          <HStack gap={0} w="100%">
            <Button
              type="submit"
              height="40px"
              size="md"
              w="100%"
              fontSize="13px"
              borderRadius="3px"
              marginRight="8px"
              fontWeight={700}
              colorScheme="Cornflower"
              padding="15px 20px"
              lineHeight="1rem"
              onClick={handleSubmit((data) => {
                if (passwordsMatch) {
                  Authentication.Reset(
                    data,
                    () => {
                      toast({
                        title: "Password Change Successful",
                        description:
                          "Your password has been changed successfully.",
                        status: "success",
                      });
                      decodedToken.accessLevel !== "EMPLOYEE" &&
                        navigate("/app/payroll/Dashboard");
                      decodedToken.accessLevel === "EMPLOYEE" &&
                        navigate(
                          `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                        );
                    },
                    (error) => {
                      toast({
                        title: "Password Change Failed",
                        description:
                          error ||
                          "An error occurred while changing the password.",
                        status: "error",
                      });
                    }
                  );
                } else {
                  toast({
                    title: "Password Mismatch",
                    description: "The passwords you entered do not match.",
                    status: "error",
                  });
                }
              })}
              isDisabled={!passwordsMatch}
            >
              Change Password
            </Button>
            <Button
              backgroundColor="#fff"
              borderRadius="3px"
              color="#ea4d4d"
              w="100%"
              border="1px solid #dcdee4"
              fontSize="13px"
              marginLeft="8px"
              h="40px"
              variant="solid"
              padding="12px 16px"
              fontWeight={700}
              _hover={{ backgroundColor: "#fff" }}
              onClick={() => {
                decodedToken.accessLevel !== "EMPLOYEE" &&
                  navigate("/app/payroll/Dashboard");
                decodedToken.accessLevel === "EMPLOYEE" &&
                  navigate(
                    `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                  );
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
}
