import {
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { Fragment, useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useFormContext } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { RiArrowUpDownFill, RiFilterFill } from "react-icons/ri";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../../../Api/EmployeeLeave";
import { EmployeeLeaveEntitlement } from "../../../../Api/EmployeeLeaveEntitlement";
import { PayItemLeaveApi } from "../../../../Api/PayItemLeave";
import LeaveTag from "../../../Leave/LeaveTag";
interface LeaveTableProps {
  AllLeaves: Array<EmployeeLeave.Leave>;
  fetchLeaves: Array<PayItemLeaveApi.LeaveById>;
  empId: string;
  setLastUpdated: (timeStamp: number) => void;
  lastUpdated: number;
  FetchLeavesEligibility?: Array<EmployeeLeaveEntitlement.LeaveEntitlement>;
}
export default function LeaveTable({
  AllLeaves,
  fetchLeaves,
  empId,
  setLastUpdated,
  lastUpdated,
  FetchLeavesEligibility,
}: LeaveTableProps) {
  const form = useFormContext<EmployeeLeave.Leave>();
  const [selectedLeaveId, setSelectedLeaveId] = useState<string>();

  const [leaveAdded, setLeaveAdded] = useState<Array<any>>([]);
  const { employeesList } = useBusinessContext();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const [pendingLeaves, setPendingLeaves] = useState<
    Array<EmployeeLeave.Leave>
  >([]);
  const [approvedLeaves, setApprovedLeaves] = useState<
    Array<EmployeeLeave.Leave>
  >([]);
  const [rejectedLeaves, setRejectedLeaves] = useState<
    Array<EmployeeLeave.Leave>
  >([]);

  useEffect(() => {
    setPendingLeaves(AllLeaves.filter((leave) => leave.status === "PENDING"));
    setApprovedLeaves(AllLeaves.filter((leave) => leave.status === "APPROVED"));
    setRejectedLeaves(AllLeaves.filter((leave) => leave.status === "REJECTED"));
  }, [AllLeaves]);
  return (
    <Fragment>
      {decodedToken.accessLevel !== "EMPLOYEE" && (
        <HStack p="30px 20px">
          <Stack display={"flex"} flex={1}>
            <InputGroup bg={"rgba(0,10,30,.05)"}>
              <InputLeftElement>
                <Icon as={FiSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
                value={searchQuery}
                onChange={handleSearch}
              />
            </InputGroup>
          </Stack>
          <HStack>
            <Button
              leftIcon={<RiFilterFill />}
              alignItems={"center"}
              verticalAlign={"top"}
              lineHeight={"1rem"}
              minH={"40px"}
              minW={"40px"}
              padding={"11px 16px"}
              bg={"transparent"}
              borderRadius={"3px"}
              color={"rgba(0,10,30,.65)"}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"700"}
              fontSize={"15px"}
              _hover={{
                bg: "rgba(0,10,30,.05)",
                color: "rgba(0,10,30,.75)",
              }}
            >
              All
            </Button>
            <Button
              leftIcon={<RiArrowUpDownFill />}
              alignItems={"center"}
              verticalAlign={"top"}
              lineHeight={"1rem"}
              minH={"40px"}
              minW={"40px"}
              padding={"11px 16px"}
              bg={"transparent"}
              borderRadius={"3px"}
              color={"rgba(0,10,30,.65)"}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"700"}
              fontSize={"15px"}
              _hover={{
                bg: "rgba(0,10,30,.05)",
                color: "rgba(0,10,30,.75)",
              }}
            >
              Status
            </Button>
          </HStack>
        </HStack>
      )}
      {decodedToken.accessLevel !== "EMPLOYEE" && (
        <Stack>
          {AllLeaves.length > 0 ? (
            <Stack p="30px 20px" gap={0}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Upcoming
              </Heading>

              {AllLeaves?.map((leave: any, i: any) => {
                return (
                  <LeaveTag
                    FetchLeaves={fetchLeaves}
                    leave={leave}
                    setlastUpdated={setLastUpdated}
                    lastUpdated={lastUpdated}
                    key={i}
                    FetchLeavesEligibility={FetchLeavesEligibility}
                  />
                );
              })}
            </Stack>
          ) : (
            <Stack padding={"0px 30px"}>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
              >
                No Leave Requested
              </Text>
            </Stack>
          )}
        </Stack>
      )}
      {decodedToken.accessLevel === "EMPLOYEE" && (
        <Stack>
          {pendingLeaves.length > 0 && (
            <Stack p="0px 20px" gap={0}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "orange",
                }}
              >
                Pending
              </Heading>

              {pendingLeaves?.map((leave: any, i: any) => {
                return (
                  <LeaveTag
                    FetchLeaves={fetchLeaves}
                    leave={leave}
                    setlastUpdated={setLastUpdated}
                    lastUpdated={lastUpdated}
                    key={i}
                    FetchLeavesEligibility={FetchLeavesEligibility}
                  />
                );
              })}
            </Stack>
          )}
          {approvedLeaves.length > 0 && (
            <Stack p="0px 20px" gap={0}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "#3454d1",
                }}
              >
                Approved
              </Heading>

              {approvedLeaves?.map((leave: any, i: any) => {
                return (
                  <LeaveTag
                    FetchLeaves={fetchLeaves}
                    leave={leave}
                    setlastUpdated={setLastUpdated}
                    lastUpdated={lastUpdated}
                    key={i}
                    FetchLeavesEligibility={FetchLeavesEligibility}
                  />
                );
              })}
            </Stack>
          )}
          {rejectedLeaves.length > 0 && (
            <Stack p="0px 20px" gap={0}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px 0px 20px 0px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "#ea4d4d",
                }}
              >
                Rejected
              </Heading>

              {rejectedLeaves?.map((leave: any, i: any) => {
                return (
                  <LeaveTag
                    FetchLeaves={fetchLeaves}
                    leave={leave}
                    setlastUpdated={setLastUpdated}
                    lastUpdated={lastUpdated}
                    key={i}
                    FetchLeavesEligibility={FetchLeavesEligibility}
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
      )}
    </Fragment>
  );
}
