import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Business } from "../../Api/Business";

interface DeleteProps {
  isOpen: boolean;
  id: string;
  businessName: string;
  onClose: () => void;
  setDeleteBusiness: React.Dispatch<React.SetStateAction<Boolean>>;

  setLastUpdated: (time: number) => void;
}
export default function Delete({
  isOpen,
  onClose,
  id,
  businessName,
  setDeleteBusiness,
  setLastUpdated,
}: DeleteProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {" "}
            Are you sure you want to delete {businessName}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              Business.DeleteBusiness(
                id,
                (success) => {
                  setLastUpdated(Date.now());
                  onClose();
                  toast({
                    title: "Business Deleted",
                    status: "success",
                  });
                  setDeleteBusiness(true);
                  setIsLoading(false);
                },
                (error) => {
                  toast({
                    title: "Delete business Failed",
                    description: error,
                    status: "error",
                  });
                  setDeleteBusiness(true);
                  setIsLoading(false);
                }
              );
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
