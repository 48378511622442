import { ButtonGroup, IconButton } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface NavigationButtonsProps {
  next: () => void;
  previous: () => void;
}
export default function NavigationButtons({
  next,
  previous,
}: NavigationButtonsProps) {
  const { employees, copiedShifts, lastUpdated, setLastUpdated } =
    useScheduleContext();
  return (
    <ButtonGroup variant="outline" isAttached>
      <IconButton
        aria-label="backward"
        icon={<FiChevronLeft width="6px" height="13px" />}
        w="34px"
        color="#545f70"
        borderColor="borders.accent"
        _hover={{
          borderColor: "primary.500",
          backgroundColor: "transparent",
          zIndex: 1,
        }}
        onClick={() => {
          previous();
          setLastUpdated(Date.now());
        }}
      />
      <IconButton
        aria-label="forward"
        icon={<FiChevronRight width="6px" height="13px" />}
        w="34px"
        color="#545f70"
        borderColor="borders.accent"
        _hover={{
          borderColor: "primary.500",
          backgroundColor: "transparent",
          zIndex: 1,
        }}
        onClick={() => {
          next();
          setLastUpdated(Date.now());
        }}
      />
    </ButtonGroup>
  );
}
