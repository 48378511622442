import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Shifts } from "../../../../TimeAttendance/API/Shifts";
import { ShiftsOperations } from "../../../../TimeAttendance/API/ShiftsOperations";
interface UnscheduledShiftModalProps {
  isOpen: boolean;
  onClose: () => void;
  setSelectedShiftId: (id: string) => void;
  setUpdatedRoasterData: (timestamp: number) => void;
  latitude?: string;
  longitude?: string;
  locationError: boolean;
}
export default function UnscheduledShiftModal({
  isOpen,
  onClose,
  setSelectedShiftId,
  setUpdatedRoasterData,
  latitude,
  longitude,
  locationError,
}: UnscheduledShiftModalProps) {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const form = useForm<Shifts.Shift>({
    defaultValues: {
      areas: [],
      break: 0,
      employeeId: id,
      geoFencingEnabled: false,
      locationId: "",
      notes: "Unscheduled Shift",
      qrRequired: false,
      shiftStartDate: moment().format("YYYY-MM-DD"),
    },
  });
  useEffect(() => {
    if (isOpen) {
      form.reset({
        areas: [],
        break: 0,
        employeeId: id,
        geoFencingEnabled: true,
        locationId: "",
        notes: "Unscheduled Shift",
        qrRequired: false,
        shiftStartDate: moment().format("YYYY-MM-DD"),
      });
    }
  }, [isOpen]);
  const { locationList } = useBusinessContext();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unscheduled Shift</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Location is required",
              },
            }}
            control={form.control}
            name="locationId"
            render={({ field, fieldState }) => {
              return (
                <ReactSelect
                  {...field}
                  withValidation
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Please Select Location"
                  placeholder="Select Location"
                  options={locationList}
                  value={
                    field.value
                      ? {
                          id: field.value,
                          name:
                            locationList?.find(
                              (loc: any) => loc.id === field.value
                            )?.name ?? "",
                        }
                      : undefined
                  }
                  onChange={(val: any) => {
                    field.onChange(val?.id);
                    const selectedLocation = locationList?.find(
                      (loc) => loc.id === val?.id
                    );
                    const areas = selectedLocation
                      ? selectedLocation?.areas?.map((area) => area.id)
                      : [];
                    form.setValue(
                      "areas",
                      (areas as unknown as string[]) ?? []
                    );
                  }}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                />
              );
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="blue"
            onClick={form.handleSubmit((data) => {
              if (locationError) {
                toast({
                  title: "Error",
                  description:
                    "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
                  status: "error",
                });
                return;
              }
              setIsLoading(true);
              const shiftStartTime = moment().format("HH:mm:ss");
              const shiftEndTime = moment().add(8, "hours").format("HH:mm:ss");
              const shiftEndDate = moment()
                .add(8, "hours")
                .format("YYYY-MM-DD");
              data.shiftStartTime = shiftStartTime;
              data.shiftEndTime = shiftEndTime;
              data.shiftEndDate = shiftEndDate;
              Shifts.AddShift(
                data,
                (response) => {
                  ShiftsOperations.StartShift(
                    {
                      id: response?.id,
                      shiftStartTime: moment().format("HH:mm:ss"),
                      shiftStartDate: moment().format("YYYY-MM-DD"),
                      shiftStartCoordinates: {
                        latitude: latitude ?? "0",
                        longitude: longitude ?? "0",
                      },
                    },
                    (data) => {
                      toast({
                        title: "Shift Started",
                        description: data,
                        status: "success",
                      });
                      setSelectedShiftId(response?.id);
                      setUpdatedRoasterData(Date.now());
                      setIsLoading(false);
                      onClose();
                    },
                    (message) => {
                      toast({
                        title: "Error",
                        description: message,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: "Error in starting unscheduled shift",
                    status: "error",
                  });
                  setIsLoading(false);
                }
              );
            })}
          >
            Start Shift
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
