import {
  Box,
  Button,
  ButtonGroup,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import { useRef } from "react";
import { FaCircle } from "react-icons/fa";
import QRCode from "react-qr-code";

interface QRCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: QRArea;
}
export type QRArea = {
  areaName: string;
  locationName: string;
  locationId: string;
  locationAddress: string;
  areaId: string;
};
export default function QRCodeModal({
  isOpen,
  onClose,
  data,
}: QRCodeModalProps) {
  const printRef = useRef<HTMLInputElement>(null);
  const toast = useToast({
    position: "top",
    status: "error",
    title: "File Error",
  });
  const handleDownloadImage = async () => {
    const element = printRef.current;
    if (element) {
      const canvas = await html2canvas(element);
      const QRcontainer = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = QRcontainer;
        link.download = `${data?.locationName}-${data?.areaName}`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(QRcontainer);
      }
    } else {
      toast({ description: "No data to download" });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="80%">
      <ModalOverlay />
      <ModalContent w="80%" h="80%">
        <ModalCloseButton />
        <ModalBody pt="60px" px="60px">
          <Stack
            justifyContent="space-between"
            direction={{ xs: "column", lg: "row" }}
            align="top"
            spacing="16px"
          >
            <Stack spacing="16px" align="top" justify="top" marginTop="10px">
              <Text
                fontSize="22px"
                fontWeight={700}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Scan QR code with QSComply app
              </Text>
              <List spacing={3}>
                <ListItem fontSize="20px" fontWeight={700}>
                  <ListIcon as={FaCircle} color="primary.950" />
                  {data?.areaName}
                </ListItem>
                <ListItem fontSize="20px" fontWeight={700}>
                  <ListIcon as={FaCircle} color="primary.950" />
                  {data?.locationName}
                </ListItem>
                <ListItem fontSize="20px" fontWeight={700}>
                  <ListIcon as={FaCircle} color="primary.950" />
                  {data?.locationAddress}
                </ListItem>
              </List>
            </Stack>
            <Box
              ref={printRef}
              backgroundColor="gray.200"
              boxSize="350px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              margin="10px"
            >
              <Box
                padding={"5px"}
                backgroundColor="white"
                boxSize="70%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <QRCode
                  value={JSON.stringify(data)}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
              <Text
                fontSize="20px"
                fontWeight={700}
                lineHeight={1}
                _light={{
                  color: "customColor.black7",
                }}
                textAlign="center"
                marginTop="10px"
              >
                {data?.areaName} | {data?.locationName} |{" "}
                {data?.locationAddress}
              </Text>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter display="flex" alignItems="center" justifyContent="center">
          <ButtonGroup>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={handleDownloadImage}
            >
              Download QR code now!
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
