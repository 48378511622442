import {
  Box,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TbGridDots } from "react-icons/tb";

import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Billings } from "../../Api/Billings";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";

interface Props {
  form: UseFormReturn<Billings.AddNewBill, any, undefined>;
  billingComponent: UseFieldArrayReturn<
    Billings.AddNewBill,
    "billing_Component",
    "id"
  >;
}
export function CreateBillTable({ form, billingComponent }: Props) {
  const toast = useToast();
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  const [taxRateData, setTaxRateData] = useState<
    BusinessTaxRate.FetchTaxRate[]
  >([]);
  useEffect(() => {
    ChartOfAccounts.GetAllAccounts(
      {
        category: "",

        page: 1,
        limit: 100,
      },
      (res) => {
        setChartOfAccounts(res.data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRateData(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  let countryData = Country.getAllCountries();
  const { businessesList, currentBusinessId } = useBusinessContext();
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);

  return (
    <Box>
      <Stack
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderColor="#f6f6f6"
        borderWidth="1px"
        borderStyle={"solid"}
        bg={"#fff"}
        marginRight={"10px"}
        gap={0}
      >
        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {" "}
                {[
                  {
                    name: "",
                    value: "2%",
                    paddingValue: "8px 10px 8px 10px",
                  },
                  {
                    name: "Item",
                    value: "14%",
                    paddingValue: "8px 26px 8px 5px",
                  },

                  {
                    name: "Description",
                    value: "26%",
                    paddingValue: "8px 26px 8px 5px",
                  },

                  {
                    name: " Qty.",
                    value: "8%",
                    paddingValue: "8px 26px 8px 5px",
                  },
                  {
                    name: " Price.",
                    value: "8%",
                    paddingValue: "8px 26px 8px 5px",
                  },
                  {
                    name: " Account.",
                    value: "10%",
                    paddingValue: "8px 26px 8px 5px",
                  },
                  {
                    name: " Tax rate.",
                    value: "10%",
                    paddingValue: "8px 26px 8px 5px",
                  },
                  {
                    name: " Region.",
                    value: "10%",
                    paddingValue: "8px 26px 8px 5px",
                  },

                  {
                    name: " Amount.",
                    value: "10%",
                    paddingValue: "8px 26px 8px 5px",
                  },
                  {
                    name: " ",
                    value: "2%",
                    paddingValue: "8px 10px 8px 10px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {billingComponent.fields.map((field, index) => (
                <Tr key={field.id}>
                  <Td
                    padding="8px 10px 8px 10px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    whiteSpace="nowrap"
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <TbGridDots />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`billing_Component.${index}.item`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Billing Component's item is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            placeholder: "",
                            borderBottomRadius: "0",
                            padding: "12px 5px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            bgColor: "#fff",
                          }}
                        />
                      )}
                    />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`billing_Component.${index}.desc`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message:
                            "Billing Component's description is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            placeholder: "",

                            padding: "12px 5px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            borderBottomRadius: "0",
                            bgColor: "#fff",
                          }}
                        />
                      )}
                    />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`billing_Component.${index}.qty`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Billing Component's quantity is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            placeholder: "",
                            onChange: (e) => {
                              const qtyValue = Number(e.target.value);
                              field.onChange(qtyValue);
                              const amount =
                                (qtyValue ?? 0) *
                                (form.watch(
                                  `billing_Component.${index}.price`
                                ) ?? 0);
                              form.setValue(
                                `billing_Component.${index}.amount`,
                                amount
                              );
                            },
                            padding: "12px 5px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            borderBottomRadius: "0",
                            bgColor: "#fff",
                          }}
                        />
                      )}
                    />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`billing_Component.${index}.price`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Billing Component's price is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            placeholder: "",
                            onChange: (e) => {
                              const priceValue = Number(e.target.value);

                              field.onChange(priceValue);
                              const amount =
                                (form.watch(`billing_Component.${index}.qty`) ??
                                  0) * (priceValue ?? 0);

                              form.setValue(
                                `billing_Component.${index}.amount`,
                                amount
                              );
                            },
                            padding: "12px 5px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            borderBottomRadius: "0",
                            bgColor: "#fff",
                          }}
                        />
                      )}
                    />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`billing_Component.${index}.account`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Billing Component's account is required",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          placeholder=""
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Select chart of account"
                          withValidation
                          options={chartOfAccounts?.map((acc: any) => ({
                            value: acc.id,
                            label: `${acc.code}: ${acc.name}`, // Including code before name
                          }))}
                          value={chartOfAccounts
                            ?.map((acc: any) => ({
                              value: acc.id,
                              label: `${acc.code}: ${acc.name}`, // Including code before name
                            }))
                            .find(
                              (option: any) => option.value === field.value
                            )}
                          onChange={(newValue: any) =>
                            field.onChange(newValue?.value)
                          }
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      name={`billing_Component.${index}.taxRate`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Billing Component's Tax Rate is required",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          placeholder=""
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Select Tax type"
                          withValidation
                          options={taxRateData}
                          value={
                            field.value
                              ? {
                                  id: field.value,
                                  name:
                                    taxRateData.find(
                                      (taxRateData) =>
                                        taxRateData.id === field.value
                                    )?.name ?? "",
                                }
                              : undefined
                          }
                          onChange={(val: any) => {
                            field.onChange(val?.id);
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.id}
                        />
                      )}
                    />
                  </Td>
                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Controller
                      control={form.control}
                      name={`billing_Component.${index}.region`}
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          options={provinces}
                          ErrorMessage="Please Select Region"
                          placeholder="Select..."
                          value={provinces?.find(
                            (op: any) => op.name === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.name);
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.name}
                        />
                      )}
                    />
                  </Td>

                  <Td
                    padding="0px 0 1px 0px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <CustomInput
                      input={{
                        isReadOnly: true,
                        placeholder: "",
                        padding: "12px 5px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        borderBottomRadius: "0",
                        bgColor: "#fff",
                        value:
                          form.watch(`billing_Component.${index}.qty`) *
                          form.watch(`billing_Component.${index}.price`),
                        onChange: () => {
                          const amount =
                            form.watch(`billing_Component.${index}.qty`) *
                            form.watch(`billing_Component.${index}.price`);
                          form.setValue(
                            `billing_Component.${index}.amount`,
                            amount
                          );
                        },
                      }}
                    />
                  </Td>
                  {billingComponent.fields.length > 1 && (
                    <Td
                      bg={"#fff"}
                      padding={"8px 10px 8px 10px"}
                      onClick={() => {
                        billingComponent.remove(index);
                      }}
                    >
                      <Icon
                        fontSize={"20px"}
                        as={IoIosCloseCircleOutline}
                        color={"grey"}
                        _hover={{
                          color: "red.500",
                        }}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </Box>
  );
}
