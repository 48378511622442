import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthHeader, useAuthUser, useSignOut } from "react-auth-kit";
import ReactCountryFlag from "react-country-flag";
import { AiOutlineSetting } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaArrowRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { GoBell, GoPersonAdd } from "react-icons/go";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import {
  IoIosClose,
  IoIosHelpCircleOutline,
  IoIosSearch,
  IoMdLogOut,
} from "react-icons/io";
import { IoDocumentOutline } from "react-icons/io5";
import { LuMenu } from "react-icons/lu";
import { MdPassword } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import Hrislogo from "../../assets/HRISMainLogo.png";
import { useBusinessContext } from "../../Hooks/BusinessContext";
import SetupGuide from "../NewDashbaord/Guide/SetupGuide";
interface NewHeaderProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
}
export default function NewHeader({
  isSidebarOpen,
  setIsSidebarOpen,
}: NewHeaderProps) {
  const navigate = useNavigate();
  const user = useAuthUser();
  const [userName, setUserName] = useState("");
  const signOut = useSignOut();
  const toast = useToast();
  const { businessesList, currentBusinessId } = useBusinessContext();
  const UserDetail = user();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  useEffect(() => {
    const UserDetail = user();

    if (UserDetail) {
      const fullName = UserDetail.firstName + " " + UserDetail.lastName;
      setUserName(fullName); // Update the state variable
    } else {
      toast({
        title: "Name not found in the user object",
        status: "error",
      });
    }
  }, []);
  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;

  const countries = countryList().getData();

  const countryCode = countries.find(
    (country) => country.label === countryName
  )?.value;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [background, setBackground] = useState("#FFFFFF");
  return (
    <Stack
      w={"100%"}
      top={0}
      position="sticky"
      maxH={"70px"}
      zIndex="1002"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      borderBottom={`1px solid #e5e7eb`}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          {decodedToken.accessLevel === "EMPLOYEE" && (
            <Box
              w={"135px"}
              h="70px"
              position="sticky"
              top={0}
              zIndex="9999"
              ml="10px"
              pl="12px"
            >
              <Flex
                justifyContent="space-between"
                align="center"
                justify="center"
                w="100%"
                h={"100%"}
              >
                <Image src={Hrislogo} display={{ xs: "flex", lg: "flex" }} />
              </Flex>
            </Box>
          )}
          <Flex
            // w="550px"
            py="16px"
            ml="10px"
            display="flex"
            alignItems="center"
            // justifyContent="center"
          >
            {decodedToken.accessLevel !== "EMPLOYEE" && (
              <IconButton
                display="block"
                fontWeight="900"
                fontStyle="normal"
                color={"#283c50"}
                _hover={{ bg: "none" }}
                aria-label={"Sidebar-button"}
                icon={
                  isSidebarOpen ? (
                    <LuMenu size="100%" />
                  ) : (
                    <FaArrowRight size="100%" />
                  )
                }
                variant="outline"
                borderWidth="0px"
                h="24px"
                w="24px"
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
              />
            )}
            <Text
              fontSize="18px"
              fontWeight="700"
              color="#3454d1"
              ml="10px"
              display={{ xs: "none", lg: "flex" }}
            >
              {
                businessesList.find(
                  (business) => business.id === currentBusinessId
                )?.name
              }
            </Text>
          </Flex>
        </Flex>

        <Flex ml="10px" alignItems="center">
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <IconButton
                aria-label="Options"
                alignItems="center"
                border={"none"}
                justifyContent="center"
                borderRadius="50%"
                w="46px"
                h="46px"
                px="12px"
                py="12px"
                fontSize="22px"
                color="#6B7280"
                _hover={{
                  color: "#3454d1",
                  bg: "#eaebef",
                }}
                _active={{
                  color: "#3454d1",
                  bg: "#eaebef",
                }}
                icon={<IoIosSearch />}
                variant="outline"
              />
            </PopoverTrigger>
            <PopoverContent
              w={"425px"}
              border={"1px solid #e5e7eb"}
              paddingTop={""}
              padding={"0px 0 15px 0px"}
              boxShadow={"0 10px 24px 0 rgba(62,57,107,.18)"}
              borderRadius={"0 0 10px 10px"}
              maxH={"calc(100vh - 150px)"}
              overflowX={"hidden"}
              zIndex="1003"
            >
              <Stack borderBottom={"1px solid #e5e7eb"} padding={"7px 10px"}>
                <InputGroup ml={[0, "0px"]} alignItems={"center"}>
                  <InputLeftElement>
                    <Icon as={FiSearch} />
                  </InputLeftElement>
                  <Input
                    type="text"
                    border={"none"}
                    placeholder="Search"
                    borderRadius={"3px"}
                    h={["auto", "40px"]}
                    _focusVisible={{
                      border: "1px solid grey",
                    }}
                  />
                </InputGroup>
              </Stack>
              <Stack
                paddingTop={".5rem"}
                paddingBottom={".5rem"}
                paddingRight={"1.5rem"}
                paddingLeft={"1.5rem"}
                gap={0}
              >
                <Text
                  marginTop={"0.5rem"}
                  fontSize={"12px"}
                  fontWeight={"400"}
                  _light={{
                    color: "customColor.gray.64748B",
                  }}
                  marginBottom={"1rem"}
                >
                  I'm searching for
                </Text>
                <Grid templateColumns="repeat(6, 1fr)" gap={1}>
                  {[
                    "Project",
                    "Leads",
                    "Contract",
                    "Inbox",
                    "Invoices",
                    "Tasks",
                  ].map((item, i) => (
                    <Button
                      key={i}
                      variant={"outline"}
                      borderRadius={"4px"}
                      fontSize={"11px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      textAlign={"center"}
                      padding={".25rem 0.5rem .25rem .5rem"}
                      border={"1px solid #e5e7eb"}
                      _hover={{
                        _light: {
                          color: "#3454d1",
                        },
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </Grid>
                <Grid templateColumns="repeat(5, 1fr)" gap={1} mt={1}>
                  {[
                    "Customers",
                    "Notes",
                    "Affiliate",
                    "Storage",
                    "Calendar",
                  ].map((item, i) => (
                    <Button
                      key={i}
                      fontWeight={"700"}
                      variant={"outline"}
                      borderRadius={"4px"}
                      fontSize={"11px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      textAlign={"center"}
                      padding={".25rem 0.5rem .25rem .5rem"}
                      border={"1px solid #e5e7eb"}
                      _hover={{
                        _light: {
                          color: "#3454d1",
                        },
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </Grid>
              </Stack>
              <Stack
                marginTop={"0.5rem"}
                marginBottom={"0.5rem"}
                borderTop={"1px solid #e5e7eb"}
              ></Stack>
              <Stack gap={0} padding={".5rem 1.5rem .5rem 1.5rem"}>
                <HStack marginBottom={"1rem"}>
                  <Text
                    _light={{
                      color: "customColor.gray.64748B",
                    }}
                    fontSize={"13px"}
                    fontWeight={"400"}
                  >
                    Recent
                  </Text>

                  <Text
                    borderRadius={"4px"}
                    bg={"#e9ecef"}
                    padding={"5px 6px"}
                    fontSize={"11px"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    3
                  </Text>
                </HStack>
                {[
                  {
                    iconName: SlScreenDesktop,
                    title: "CRM dashboard redesign",
                    url: "Home / project / crm",
                    commandName: "/ ⌘",
                  },
                  {
                    iconName: IoDocumentOutline,
                    title: "Create new document",
                    url: "Home / tasks / docs",
                    commandName: "N / ⌘",
                  },
                  {
                    iconName: GoPersonAdd,
                    title: "Invite project colleagues",
                    url: "Home / project / invite",
                    commandName: "P / ⌘",
                  },
                ].map((item, i) => (
                  <Stack
                    key={i}
                    direction={"row"}
                    marginBottom={"1.5rem"}
                    justifyContent={"space-between"}
                    gap={0}
                  >
                    <Stack direction={"row"} gap={3}>
                      <Stack
                        w={"40px"}
                        h={"40px"}
                        maxH={"40px"}
                        maxW={"40px"}
                        minH={"40px"}
                        minW={"40px"}
                        cursor={"pointer"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        bg={"#fff"}
                        border={"1px solid #dcdee4"}
                        borderRadius={"4px"}
                      >
                        <Icon as={item.iconName} h={"16px"} w={"16px"} />
                      </Stack>
                      <Stack gap={0}>
                        <Text
                          fontSize={".84rem"}
                          fontWeight={"700!important"}
                          marginBottom={".25rem!important"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _hover={{
                            _light: {
                              color: "#3454d1",
                            },
                            cursor: "pointer",
                          }}
                        >
                          {item.title}
                        </Text>
                        <Text
                          fontSize={"11px"}
                          _light={{
                            color: "customColor.gray.64748B",
                          }}
                        >
                          {item.url}
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      padding={"5px 6px"}
                      h={"28px"}
                      borderRadius={"4px"}
                      gap={0}
                      border={"1px solid #e5e7eb"}
                      direction={"row"}
                    >
                      <Text
                        fontSize={"11px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={600}
                        _hover={{
                          _light: {
                            color: "#3454d1",
                          },
                        }}
                      >
                        {item.commandName}
                      </Text>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </PopoverContent>
          </Popover>

          <Box
            w="46px"
            h="46px"
            px="12px"
            py="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bgColor={background}
            onClick={onOpen}
          >
            <Icon
              as={IoIosHelpCircleOutline}
              fontSize="22px"
              color="#6B7280"
              w="46px"
              h="46px"
              px="12px"
              py="12px"
              borderRadius="50%"
              _hover={{
                color: "#3454d1",
                bg: "#eaebef",
              }}
              _active={{
                color: "#3454d1",
                bg: "#eaebef",
              }}
            />
          </Box>

          <SetupGuide isOpen={isOpen} onClose={onClose} />

          <Box
            w="47px"
            h="47px"
            px="12px"
            py="12px"
            display="flex"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            _hover={{
              bg: "#eaebef",
            }}
          >
            {/* <Image
              src=
              w="24.375px"
              h="16px"
              alt="flag"
            /> */}
            <ReactCountryFlag
              countryCode={countryCode!}
              svg
              style={{
                width: "24.375px",
                height: "16px",
              }}
              title={countryCode}
            />
          </Box>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <IconButton
                aria-label="Options"
                alignItems="center"
                border={"none"}
                justifyContent="center"
                borderRadius="50%"
                w="46px"
                h="46px"
                px="12px"
                py="12px"
                fontSize="22px"
                color="#6B7280"
                _hover={{
                  color: "#3454d1",
                  bg: "#eaebef",
                }}
                _active={{
                  color: "#3454d1",
                  bg: "#eaebef",
                }}
                icon={<GoBell />}
                variant="outline"
              />
            </PopoverTrigger>
            <PopoverContent
              padding={0}
              borderRadius={"4px"}
              border={"1px solid #e5e7eb"}
              w={"20rem"}
              zIndex="1003"
            >
              <Stack
                direction={"row"}
                gap={0}
                padding={"20px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                borderRadius={"2px 2px 0 0"}
                borderBottom={"1px solid #e5e7eb"}
              >
                <Text
                  fontSize={".9375rem"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Notifications
                </Text>
                <Tooltip hasArrow label="MAKE AS READ" fontSize={"xs"}>
                  <Stack direction={"row"}>
                    <Icon
                      color={"#17c666"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      as={FaCheck}
                    />
                    <Text
                      fontSize={"11px"}
                      fontWeight={"700"}
                      color={"#17c666"}
                    >
                      Make as read
                    </Text>
                  </Stack>
                </Tooltip>
              </Stack>
              {[
                {
                  image: "https://bit.ly/dan-abramov",
                  Title: "Malanie Hanvey We should talk about at lunch!",
                  timeStamp: "2 minutes ago",
                },
                {
                  image: "https://bit.ly/prosper-baba",
                  Title:
                    "Valentine Maton You can download the latest invoices...",
                  timeStamp: "36 minutes ago",
                },
                {
                  image: "https://bit.ly/sage-adebayo",
                  Title:
                    "Archie Cantonese Don't forget to pickup Jeremy after school!",
                  timeStamp: "56 minutes ago",
                },
              ].map((item, i) => (
                <Stack
                  key={i}
                  padding={"15px 20px"}
                  transition={"all .3s ease"}
                  cursor={"pointer"}
                  direction={"row"}
                >
                  <Image
                    boxSize="60px"
                    border={"1px solid #e5e7eb"}
                    marginRight={"1rem!important"}
                    borderRadius={"4px"}
                    objectFit="cover"
                    src={item.image}
                    alt="Notification"
                  />
                  <Stack>
                    <Text
                      fontSize={"0.84rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _hover={{
                        _light: {
                          color: "#3454d1",
                        },
                      }}
                    >
                      {item.Title}
                    </Text>
                    <HStack justifyContent={"space-between"}>
                      <Text
                        fontSize={"11px"}
                        fontWeight={"400"}
                        _light={{
                          color: "customColor.gray.64748B",
                        }}
                      >
                        {item.timeStamp}
                      </Text>
                      <HStack>
                        <Tooltip hasArrow label="MAKE AS READ" fontSize={"xs"}>
                          <Box
                            as="span"
                            display="inline-block"
                            w="8px"
                            h="8px"
                            borderRadius="50%"
                            bg={"#e5e7eb!important"}
                          />
                        </Tooltip>
                        <Icon as={IoIosClose} color={"red"} />
                      </HStack>
                    </HStack>
                  </Stack>
                </Stack>
              ))}
              <Stack
                padding={"15px 20px"}
                borderRadius={"0 0 2px 2px"}
                borderTop={"1px solid #e5e7eb"}
                textAlign={"center"}
              >
                <Text
                  fontSize={"13px"}
                  fontWeight={"600!important"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _hover={{
                    _light: {
                      color: "#3454d1",
                      cursor: "pointer",
                    },
                  }}
                >
                  All Notifications
                </Text>
              </Stack>
            </PopoverContent>
          </Popover>

          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Box
                w="60px"
                h="70px"
                px="12px"
                marginRight={"10px"}
                py="7.520px"
                display="flex"
                alignItems="center"
                cursor="pointer"
              >
                <Avatar w="36px" h="36px" name={userName} />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              w={"300px"}
              padding={"15px 0"}
              border={"1px solid #e5e7eb"}
              boxShadow={"0 10px 24px 0 rgba(62,57,107,.18)"}
            >
              <Stack
                gap={0}
                marginBottom={"10px"}
                direction={"row"}
                paddingBottom={"20px"}
                paddingLeft={"25px"}
                paddingRight={"25px"}
                borderBottom={"1px solid #e5e7eb"}
              >
                <Avatar
                  w="36px"
                  h="36px"
                  marginRight={"15px"}
                  name={userName}
                  src={UserDetail!.photo}
                />
                <Stack>
                  <Text
                    fontSize={".9375rem"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    {userName}
                  </Text>
                  <Text
                    fontSize={"12px"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.gray.64748B",
                    }}
                  >
                    {UserDetail!.email}
                  </Text>
                </Stack>
              </Stack>

              {[
                {
                  label: "View Profile",
                  icon: CgProfile,
                  onClick: () => navigate("/app/payroll/UserProfile"),
                },
                {
                  label: "Change Password",
                  icon: MdPassword,
                  onClick: () => navigate("/app/payroll/ChangePasswordEmail"),
                },
                {
                  label: "Switch Business",
                  icon: HiOutlineSwitchHorizontal,
                  onClick: () => navigate("/app/SwitchBusiness"),
                },
              ].map((item, index) => (
                <Stack
                  key={index}
                  padding={0}
                  _hover={{ bg: "none" }}
                  margin={"0px 10px"}
                >
                  <Stack
                    w={"100%"}
                    borderRadius={"5px"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    padding={"10px 15px"}
                    _hover={{
                      _light: {
                        color: "customColor.black8",
                      },
                      bg: "#eaebef",
                      transition: "all .3s ease",
                    }}
                    direction={"row"}
                    onClick={item.onClick}
                  >
                    <Icon as={item.icon} size="16px" color={"#283C50"} />
                    <Text
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      whiteSpace={"nowrap"}
                      fontSize="13px"
                    >
                      {item.label}
                    </Text>
                  </Stack>
                </Stack>
              ))}
              {decodedToken.accessLevel !== "EMPLOYEE" &&
                decodedToken.accessLevel !== "CLIENT" && (
                  <Stack
                    padding={0}
                    _hover={{ bg: "none" }}
                    margin={"0px 10px"}
                  >
                    <Stack
                      w={"100%"}
                      borderRadius={"5px"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      padding={"10px 15px"}
                      _hover={{
                        _light: {
                          color: "customColor.black8",
                        },
                        bg: "#eaebef",
                        transition: "all .3s ease",
                      }}
                      direction={"row"}
                      onClick={() => {
                        navigate("/app/payroll/organisationSettings");
                      }}
                    >
                      <Icon
                        as={AiOutlineSetting}
                        size="16px"
                        color={"#283C50"}
                      />
                      <Text
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        whiteSpace={"nowrap"}
                        fontSize="13px"
                      >
                        {"Settings"}
                      </Text>
                    </Stack>
                  </Stack>
                )}

              <Stack
                borderTop={"1px solid #e5e7eb"}
                padding={"15px 0 0px 0px"}
                marginTop={"10px"}
              >
                <Stack padding={0} _hover={{ bg: "none" }} margin={"0px 10px"}>
                  <Stack
                    w={"100%"}
                    borderRadius={"5px"}
                    cursor={"pointer"}
                    alignItems={"center"}
                    padding={"10px 15px"}
                    _hover={{
                      _light: {
                        color: "customColor.black8",
                      },
                      bg: "#eaebef",
                      transition: "all .3s ease",
                    }}
                    direction={"row"}
                    onClick={() => {
                      signOut();
                      toast({
                        title: "Logout Successful",
                        status: "success",
                      });
                      navigate("/");
                    }}
                  >
                    <Icon as={IoMdLogOut} size="16px" color={"#283C50"} />
                    <Text
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      whiteSpace={"nowrap"}
                      fontSize="13px"
                    >
                      Sign out
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </Stack>
  );
}
