import { HStack, Link, SimpleGrid, Stack, Tag, Text } from "@chakra-ui/react";
import moment from "moment";
import { Employees } from "../../../Api/Employees";
interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function DocumentHistory({ employeeData }: DetailsProps) {
  return (
    <Stack
      flex={1}
      padding={"0px 5px"}
      margin={
        employeeData?.employeeDocuments &&
        employeeData?.employeeDocuments.length > 0
          ? "0px 0px 20px"
          : "0px 0px 10px"
      }
    >
      <HStack
        borderBottom={"2px solid #dadddf"}
        padding={"0px 0px 10px"}
        margin={
          employeeData?.employeeDocuments &&
          employeeData?.employeeDocuments.length > 0
            ? "14px 0px 20px"
            : "0px"
        }
      >
        <Text
          _dark={{
            color: "customColor.white",
          }}
          _light={{
            color: "#3454d1",
          }}
          fontWeight={"700"}
          _hover={{
            _light: {
              color: "#3454d1",
            },
          }}
        >
          Employee Document History
        </Text>
        {employeeData?.employeeDocuments &&
        employeeData?.employeeDocuments.length > 0 ? (
          <Tag
            padding="5px 6px"
            fontWeight={600}
            fontSize="10px"
            lineHeight={1.5}
            borderRadius={"3px"}
            color={"#2E7D32"} // Dark green color for text
            backgroundColor={"#E8F5E9"} // Light green background color
            _dark={{
              color: "#2E7D32",
              backgroundColor: "#E8F5E9",
            }}
          >
            {"Complete"}
          </Tag>
        ) : (
          <Tag
            padding="5px 6px"
            fontWeight={600}
            fontSize="10px"
            lineHeight={1.5}
            borderRadius={"3px"}
            color={"#EA4D4D"}
            backgroundColor={"#FDEDED"}
          >
            {"Incomplete"}
          </Tag>
        )}
      </HStack>

      {employeeData?.employeeDocuments &&
        employeeData?.employeeDocuments.length > 0 && (
          <SimpleGrid
            columns={{ xs: 1, md: 2 }}
            padding={"0px 5px"}
            margin={"0px 0px 20px"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            spacing={10}
          >
            {employeeData?.employeeDocuments.map((item, i) => {
              return (
                <Stack key={i} flex={1}>
                  <HStack
                    justifyContent={"space-between"}
                    borderBottom={"2px solid #dadddf"}
                    padding={"0px 0px 10px"}
                    margin={"14px 0px 20px"}
                  >
                    <Text
                      fontSize={"14.5px"}
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"700"}
                      _hover={{
                        _light: {
                          color: "#3454d1",
                        },
                      }}
                    >
                      Document {i + 1}
                    </Text>
                  </HStack>
                  <HStack alignItems={"flex-start"}>
                    <Stack flex={1}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                        fontWeight={"700"}
                      >
                        Type
                      </Text>
                    </Stack>
                    <Stack flex={2}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                      >
                        {item?.documentType === "NATIONAL_ID"
                          ? "National ID"
                          : item?.documentType === "EDUCATION"
                          ? "Education"
                          : item?.documentType === "PAST_EMPLOYMENT"
                          ? "Past Employment"
                          : item?.documentType === "OTHER"
                          ? "Others"
                          : item?.documentType}
                      </Text>
                    </Stack>
                  </HStack>
                  <HStack alignItems={"flex-start"}>
                    <Stack flex={1}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                        fontWeight={"700"}
                      >
                        File Name
                      </Text>
                    </Stack>
                    <Stack flex={2}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                      >
                        {item?.fileName
                          ? item.fileName.replace(/\.(pdf|png|jpeg|jpg)$/i, "")
                          : "NA"}
                      </Text>
                    </Stack>
                  </HStack>
                  <HStack alignItems={"flex-start"}>
                    <Stack flex={1}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                        fontWeight={"700"}
                      >
                        Created Date
                      </Text>
                    </Stack>
                    <Stack flex={2}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                      >
                        {moment(item?.createdAt).format("MMM DD, YYYY hh:mm A")}
                      </Text>
                    </Stack>
                  </HStack>
                  <HStack alignItems={"flex-start"}>
                    <Stack flex={1}>
                      <Text
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13px"}
                        fontWeight={"700"}
                      >
                        Download URL
                      </Text>
                    </Stack>
                    <Stack flex={2}>
                      <Link
                        href={item?.fileUrl ?? "#"}
                        download
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "#3454d1",
                        }}
                        fontSize={"13px"}
                      >
                        {/* {item?.fileName ?? "NA"} */}
                        {item?.fileName
                          ? item.fileName.replace(/\.(pdf|png|jpeg|jpg)$/i, "")
                          : "NA"}
                      </Link>
                    </Stack>
                  </HStack>
                </Stack>
              );
            })}
          </SimpleGrid>
        )}
    </Stack>
  );
}
