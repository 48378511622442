import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useDashboardContext } from "../../Hooks/DashboardContext";
import TurnOverChart from "../TurnOverChart";

export default function TurnOver() {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();
  const calculatedTurnover = Number(
    (
      (Number(dashboardAnalytics?.employeesEndedThisMonth) /
        Number(dashboardAnalytics?.totalEmpCount)) *
      100
    ).toFixed(2)
  );

  return (
    <Box
      p="25px"
      color={"customColor.black"}
      _dark={{
        bgColor: "customColor.dark.50",
        color: "customColor.dark.150",
      }}
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Flex justifyContent="space-between">
        <Box>
          <Text
            my="4px"
            as={"h4"}
            fontSize="24"
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={500}
            lineHeight="1.2"
            word-wrap="break-word"
          >
            {isNaN(calculatedTurnover) ? 0 : calculatedTurnover + "%"}
          </Text>
          <HStack spacing={1}>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              word-wrap="break-word"
              fontSize="14.4px"
              fontWeight="500"
              lineHeight={1.5}
            >
              Turnover
            </Text>
            <Text
              fontSize="12px"
              _dark={{
                color: "customColor.dark.250",
              }}
              _light={{
                color: "customColor.black7",
              }}
              word-wrap="break-word"
            >
              (current month)
            </Text>
          </HStack>
        </Box>
        <TurnOverChart
          newHiring={dashboardAnalytics?.employeesStartedThisMonth}
          separation={dashboardAnalytics?.employeesEndedThisMonth}
        />
      </Flex>
      <HStack
        mt="16px"
        divider={
          <Text
            mx="4px"
            _dark={{
              color: "customColor.dark.250",
            }}
            _light={{
              color: "customColor.gray.64748B",
            }}
          >
            |
          </Text>
        }
      >
        <Text
          _light={{
            color: "customColor.gray.64748B",
          }}
          _dark={{
            color: "customColor.dark.250",
          }}
          fontSize="14.4px"
          fontWeight="400px"
          lineHeight={1.5}
        >
          <Text as="span" color="#34C38F" fontSize="14.4px">
            {dashboardAnalytics?.employeesStartedThisMonth}
          </Text>{" "}
          New Hiring
        </Text>
        <Text
          _light={{
            color: "customColor.gray.64748B",
          }}
          _dark={{
            color: "customColor.dark.250",
          }}
          fontSize="14.4px"
          fontWeight="400px"
          lineHeight={1.5}
        >
          <Text as="span" color="#34C38F" fontSize="14.4px">
            {dashboardAnalytics?.employeesEndedThisMonth}
          </Text>{" "}
          Separation
        </Text>
      </HStack>
    </Box>
  );
}
