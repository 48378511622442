import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";

import { AiOutlineCaretDown } from "react-icons/ai";
import { FaInfoCircle, FaMoneyBillWave } from "react-icons/fa";
import { GiOfficeChair } from "react-icons/gi";
import {
  MdMiscellaneousServices,
  MdOutlineCalendarMonth,
  MdOutlineEmojiObjects,
  MdOutlineHolidayVillage,
  MdOutlineLocalHospital,
  MdOutlinePayments,
} from "react-icons/md";
import Calendartab from "../Calendar/Calendartab";
import Holiday from "../Holiday/Holiday";
import PayItem from "../PayRollSetting/PayItems/PayItem";
import OrgTab from "../PayrollSettings/OrgtabPanel";
import { TaxSlabs } from "../PayrollSettings/TaxSlabs/TabSlabs";
import Agreement from "./Agreement";
import Billing from "./Billing";
import Contact from "./Contact";
import { InsuranceParent } from "./InsuranceParent";
import ServiceCharge from "./ServiceCharges";
import OrgDetails from "./orgdetails";

export function OrganizationDetailParent() {
  const [isLargerThanMD] = useMediaQuery("(min-width: 1000px)");
  const tabCategories: Array<{
    categoryName: string;
    tabs: Array<{
      label: string;
      index: number;
      iconName?: any;
      labelText?: string;
    }>;
  }> = [
    {
      categoryName: "Business Setup",
      tabs: [
        {
          label: "Information",
          iconName: <FaInfoCircle />,
          index: 0,
        },
        {
          label: "Contact",
          iconName: <FaInfoCircle />,
          index: 1,
        },

        {
          label: "Agreement",
          iconName: <MdMiscellaneousServices />,
          index: 2,
        },
        {
          label: "Service Charges",
          iconName: <FaInfoCircle />,
          index: 3,
        },
        {
          label: "Billing",
          iconName: <FaMoneyBillWave />,
          index: 4,
        },

        { label: "Insurance", iconName: <MdOutlineLocalHospital />, index: 5 },
      ],
    },
    {
      categoryName: "Payroll Settings",
      tabs: [
        {
          label: "Account setup",
          iconName: <GiOfficeChair />,
          index: 6,
          labelText:
            "An Account setup is a structured entity that operates with a specific purpose, such as a company, non-profit, or governmental body.",
        },
        {
          label: "Calendars",
          iconName: <MdOutlineCalendarMonth />,
          index: 7,
          labelText:
            "Calendars are tools used to organize and track time, events, and schedules, facilitating planning and coordination.",
        },
        {
          label: "Holiday",
          iconName: <MdOutlineHolidayVillage />,
          index: 8,
          labelText:
            "A holiday is a designated day of rest or celebration, typically observed by individuals, communities, or nations, often with specific cultural or religious significance.",
        },
        {
          label: "Pay Items",
          iconName: <MdOutlineEmojiObjects />,
          index: 9,
          labelText:
            "Pay items refer to specific components of an employee's compensation, such as salary, wages, bonuses, or allowances, delineated for payroll processing and accounting purposes.",
        },

        {
          label: "Tax Slabs",
          iconName: <MdOutlinePayments />,
          index: 10,
          labelText:
            "Tax slabs are predefined ranges of income levels set by tax authorities, each corresponding to a specific tax rate, used to determine the amount of tax owed by individuals or businesses.",
        },
      ],
    },
  ];

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      {" "}
      <Container
        maxW={"100%"}
        mt="10px"
        paddingRight={"20px"}
        paddingLeft={"1px"}
      >
        <Box>
          <Tabs
            index={selectedTab}
            orientation={isLargerThanMD ? "vertical" : "horizontal"}
            onChange={setSelectedTab}
            isLazy
            align={isLargerThanMD ? "start" : "center"}
          >
            {isLargerThanMD ? (
              <Stack
                w="29%"
                spacing={0}
                marginTop={"15px"}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
                marginBottom={"1.25rem"}
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                borderWidth="1px"
                borderStyle={"solid"}
                borderColor="#f6f6f6"
                padding={"20px"}
              >
                <Box position="sticky" top="20px">
                  {tabCategories.map((category, i) => (
                    <Box key={i}>
                      <Text
                        fontSize={"14.4px"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        margin={"24px 0px 8px"}
                      >
                        {category.categoryName}
                      </Text>
                      {category.tabs.map((tab, j) => (
                        <Tab
                          marginLeft="0px"
                          justifyContent="flex-start"
                          w="100%"
                          p={"5px 15px"}
                          key={i}
                          marginBottom={"5px"}
                          fontSize="13px"
                          borderRadius={"3px"}
                          cursor="pointer"
                          color="#74788D"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _selected={{
                            bgColor: "#EAEBEF",
                            color: "customColor.black8",
                            borderRadius: "5px",
                            transition: "all .3s ease",
                          }}
                          _hover={{
                            bgColor: "#EAEBEF",
                            color: "customColor.black8",
                            borderRadius: "5px",
                            transition: "all .3s ease",
                          }}
                          onClick={() => setSelectedTab(tab.index)}
                        >
                          {/* <Tooltip
                          label={tab.labelText}
                          fontSize="sm"
                          padding="10px"
                          bg="#fff"
                          border="1px solid #ddd"
                          color="black"
                          maxW="300px"
                          placement="auto"
                        >  */}
                          <Stack
                            direction={"row"}
                            gap={0}
                            alignItems={"center"}
                            _hover={{
                              color: "customColor.black8",
                            }}
                          >
                            <Button
                              as={IconButton}
                              color={
                                selectedTab === tab.index
                                  ? "#001327"
                                  : "#283C50"
                              }
                              icon={tab.iconName}
                              variant={"ghost"}
                              padding={"0px"}
                              _hover={{
                                bg: "transparent",
                              }}
                            />
                            {/* <Icon as={tab.iconName} /> */}
                            <Text
                              fontSize={"13px"}
                              fontWeight={"600"}
                              _hover={{
                                color: "customColor.black8",
                              }}
                            >
                              {tab.label}
                            </Text>
                          </Stack>
                          {/* </Tooltip> */}
                        </Tab>
                      ))}
                      <Divider />
                    </Box>
                  ))}
                </Box>
                {/* </Box> */}
              </Stack>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<AiOutlineCaretDown />}
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      color: "primary.950",
                      backgroundColor: "#fff",
                    }}
                    cursor={"pointer"}
                    _active={{
                      backgroundColor: "#fff",
                      color: "primary.950",
                    }}
                  >
                    {tabCategories
                      .map((tabCategory) =>
                        tabCategory.tabs.find(
                          (tab) => tab.index === selectedTab
                        )
                      )
                      .filter(Boolean)
                      .map((tab: any) => tab.label)}
                  </MenuButton>
                  <MenuList
                    zIndex={"overlay"}
                    maxH={"300px"}
                    overflow={"scroll"}
                  >
                    {tabCategories.map((tabCategory, i) => (
                      <MenuGroup
                        key={i}
                        title={tabCategory.categoryName}
                        padding={"6px 12px"}
                        margin={0}
                      >
                        {tabCategory.tabs.map((tab, j) => (
                          <MenuItem
                            key={j}
                            fontSize={"13.44px"}
                            onClick={() => {
                              setSelectedTab(tab.index);
                            }}
                          >
                            {tab.label}
                          </MenuItem>
                        ))}
                        <Divider />
                      </MenuGroup>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
            <Stack padding={"10px"}></Stack>
            <TabPanels padding={0} overflowY="auto">
              <TabPanel p={0} marginTop={"15px"} bgColor={"#ddd"}>
                <OrgDetails
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel p={0} marginTop={"15px"} bgColor={"#ddd"}>
                <Contact
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>

              <TabPanel p={0} marginTop={"15px"} bgColor={"#ddd"}>
                <Agreement
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel p={0} marginTop={"15px"} bgColor={"#ddd"}>
                <ServiceCharge
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel p={0} marginTop={"15px"} bgColor={"#ddd"}>
                <Billing
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel p={0} marginTop={"15px"} bgColor={"#ddd"}>
                <InsuranceParent
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel padding={0}>
                <OrgTab
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel padding={0}>
                <Calendartab
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>

              <TabPanel padding={0}>
                <Holiday
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              </TabPanel>
              <TabPanel padding={0}>
                <PayItem
                  setSelectedTabForStatutory={setSelectedTab}
                  selectedTabForStatutory={selectedTab}
                />
              </TabPanel>

              <TabPanel padding={0}>
                <TaxSlabs />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    </Stack>
  );
}
