import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Heading,
  HStack,
  Stack,
  Switch,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AiOutlineClockCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { BsBuilding, BsInfoCircle } from "react-icons/bs";
import { IoMdAttach } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TabTitle from "../../../Common/TabTitle";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { NotificationSetting } from "../../API/NotificationSetting";

export default function BusinessWiseNotification() {
  const navigate = useNavigate();
  const [displayPhone, setDisplayPhone] = useState<boolean>(false);
  const [isDisplaySetting, setIsDisplaySetting] = useState<boolean>(false);
  const dividerOrientation: "horizontal" | "vertical" | undefined =
    useBreakpointValue({
      xs: "horizontal",
      md: "vertical",
    });
  const tabs: Array<{
    label?: string;
    icon: any;
  }> = [
    {
      label: "Actions",
      icon: BsInfoCircle,
    },
    {
      label: "Issues",
      icon: BsBuilding,
    },
    {
      label: "Inspections",
      icon: AiOutlineClockCircle,
    },
    {
      label: "Sensors",
      icon: IoMdAttach,
    },
    {
      label: "Schedules",
      icon: IoMdAttach,
    },
    {
      label: "Heads Up",
      icon: IoMdAttach,
    },
  ];

  const { currentBusinessId } = useBusinessContext();

  const toast = useToast({
    position: "top",
  });
  const [eventTypes, setEventTypes] = useState<
    Array<NotificationSetting.EventType>
  >([]);
  const [selectedEventTypeId, setSelectedEventTypeId] = useState<number>(0);

  const [notificationTypes, setNotificationTypes] =
    useState<Array<NotificationSetting.NotificationType>>();

  const form = useForm<NotificationSetting.NotificationPayload>({
    defaultValues: {
      business_notification_settings: {
        email: true,
        sms: true,
        push: true,
        business: currentBusinessId ?? "",
      },
      event_notification_settings: [],
    },
  });

  const notificationArr = useFieldArray({
    control: form.control,
    name: "event_notification_settings",
  });
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  useEffect(() => {
    NotificationSetting.Fetch(
      (notifications) => {
        form.reset(notifications);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [lastUpdated]);

  useEffect(() => {
    NotificationSetting.GetEventTypes(
      (events) => {
        setEventTypes(events);
        setSelectedEventTypeId(events?.[0]?.id);
        NotificationSetting.GetNotificationTypes(
          (notificationsTypes) => {
            setNotificationTypes(notificationsTypes);
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
            });
          }
        );
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <Container maxW="container.md" pb="20px" h="100%" overflowY="scroll">
        <Box
          border={isDisplaySetting ? "1px" : "0px"}
          borderColor="border.accent"
          borderRadius="16px"
          px="16px"
          py={isDisplaySetting ? "24px" : "0px"}
          mb="16px"
        >
          <HStack mb="16px">
            <Heading flex={1}>Notification Settings</Heading>
            {!isDisplaySetting ? (
              <Button
                variant="outline"
                px="20px"
                py="12px"
                h="44px"
                fontSize="14px"
                fontWeight={500}
                borderRadius="8px"
                leftIcon={<MdEdit size={17} />}
                onClick={() => {
                  setIsDisplaySetting(true);
                }}
              >
                Edit
              </Button>
            ) : (
              <Stack
                pt="16px"
                pb="3px"
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: "2", md: "8" }}
                paddingBottom="3px"
                paddingTop="16px"
              >
                <Button
                  variant="solid"
                  borderRadius="8px"
                  h="43px"
                  px="20px"
                  py="12px"
                  onClick={form.handleSubmit(
                    (data) => {
                      NotificationSetting.BulkCreateBusinessNotifications(
                        data,
                        (success) => {
                          toast({
                            title: "Success",
                            description: "You've successfully updated",
                          });
                          setIsDisplaySetting(false);
                          setLastUpdated(Date.now());
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                          });
                        }
                      );
                    },
                    (error) => {}
                  )}
                >
                  Save Changes
                </Button>
                <Button
                  variant="outline"
                  borderRadius="8px"
                  h="43px"
                  px="20px"
                  py="12px"
                  onClick={() => {
                    setIsDisplaySetting(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </HStack>
          {isDisplaySetting && (
            <Stack spacing={0} py="8px">
              <Heading as="h4" size="md" pb="16px" pr="8px">
                Enable notifications via
              </Heading>
              <Stack spacing="0px" px="8px">
                <Heading as="h3" size="sm">
                  Type of notifications you want to receive
                </Heading>
                <Stack align="center" direction="row">
                  {notificationTypes?.map(({ name, id }) => (
                    <Box key={id}>
                      <Heading as="h3" size="sm" padding="0px">
                        {name}
                      </Heading>

                      <Switch
                        isChecked={form.watch(
                          `business_notification_settings.${name.toLowerCase()}` as any
                        )}
                        size="md"
                        onChange={(e) => {
                          form.setValue(
                            `business_notification_settings.${name.toLowerCase()}` as any,
                            e.target.checked
                          );
                        }}
                      />
                    </Box>
                  ))}

                  {/* <Box>
                    <ComponentAnswer padding="0px">Email</ComponentAnswer>
                    <Switch size="md" />
                  </Box>
                  <Box>
                    <ComponentAnswer padding="0px">SMS</ComponentAnswer>
                    <Switch size="md" />
                  </Box> */}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
        <Stack
          direction={{ xs: "column", md: "row" }}
          minH={{ sm: "-webkit-fit-content", md: "300px" }}
          // orientation={tabsOrientation}
          borderWidth="1px"
          borderColor="border.accent"
          borderRadius="16px"
          boxShadow={{ xs: "sm", lg: "none" }}
          align="flex-start"
          spacing={0}
        >
          <Stack
            minH={{ xs: "auto", md: "full" }}
            spacing={0}
            minW={{ md: "20%", xs: "100%" }}
            mt="24px"
            backgroundColor="white"
            zIndex={5}
            direction={{ xs: "row", md: "column" }}
          >
            {eventTypes.map((menu, i) => {
              return (
                <Fragment key={i}>
                  <Box
                    cursor="pointer"
                    borderWidth="0px"
                    p="15px"
                    __css={
                      menu.id === selectedEventTypeId
                        ? {
                            backgroundColor: "gray.100",
                            textColor: "primary.500",
                          }
                        : undefined
                    }
                    _hover={{
                      backgroundColor: "gray.100",
                      textColor: "primary.500",
                    }}
                    margin="0px"
                    w="100%"
                    onClick={() => {
                      setSelectedEventTypeId(menu.id);
                    }}
                  >
                    <HStack
                      w="100%"
                      justify={{ xs: "center", lg: "flex-start" }}
                    >
                      <Text
                        flex={1}
                        fontSize="14px"
                        fontWeight={500}
                        textAlign="start"
                        _selected={{
                          color: "primary",
                        }}
                        lineHeight={1.2}
                      >
                        {menu.name}
                      </Text>
                    </HStack>
                  </Box>
                  {i !== tabs.length - 1 && (
                    <Divider
                      display={{
                        xs: "none",
                        lg: "block",
                      }}
                      borderColor="border.primary"
                    />
                  )}
                </Fragment>
              );
            })}
          </Stack>
          <Divider
            orientation={dividerOrientation}
            minH={{ xs: "1px", md: "300px" }}
            w="1px"
          />
          <Box
            h="full"
            flex={1}
            ml={{ xs: "0px", lg: "10px" }}
            // _hover={{boxShadow: 'lg'}}
            // border="1px"
            // borderColor="border.accent"
            // borderEndRadius="16px"
          >
            <Box
              // maxH="320px"
              overflowY="scroll"
              px="16px"
              py="24px"
              css={{ "&::-webkit-scrollbar": { display: "none" } }}
            >
              <TabTitle pb="16px">
                {eventTypes.find((x) => x.id === selectedEventTypeId)?.name}
              </TabTitle>
              {eventTypes
                .find((x) => x.id === selectedEventTypeId)
                ?.events.map((event) => (
                  <Stack spacing={0} key={event.id}>
                    <HStack align="top" spacing="4px">
                      <Heading as="h3" size="sm">
                        {event.title}
                      </Heading>
                      <AiOutlineInfoCircle size={13} color="#545f70" />
                    </HStack>
                    {!isDisplaySetting && (
                      <Heading as="h4" size="md">
                        {notificationTypes
                          ?.filter((notType) =>
                            form
                              .getValues("event_notification_settings")
                              ?.find(
                                (x) =>
                                  x.eventId === event.id &&
                                  x.notificationTypeId === notType.id
                              )
                          )
                          .map((notType) => notType.name)
                          .join(", ")}
                      </Heading>
                    )}

                    {isDisplaySetting && (
                      <HStack pb="24px" spacing="24px">
                        {notificationTypes?.map((notificationType, index) => {
                          return (
                            <Checkbox
                              key={index}
                              isDisabled={
                                !form.watch(
                                  `business_notification_settings.${notificationType.name.toLowerCase()}` as any
                                )
                              }
                              defaultChecked={
                                form
                                  .getValues("event_notification_settings")
                                  ?.findIndex(
                                    (x) =>
                                      x.eventId === event.id &&
                                      x.notificationTypeId ===
                                        notificationType.id
                                  ) > -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  notificationArr.append({
                                    businessId: currentBusinessId ?? "",
                                    eventId: event.id,
                                    notificationTypeId: notificationType.id,
                                  });
                                } else {
                                  notificationArr.remove(
                                    notificationArr.fields.findIndex(
                                      (x) =>
                                        x.notificationTypeId ===
                                          notificationType.id &&
                                        x.eventId === event.id
                                    )
                                  );
                                }
                              }}
                            >
                              {notificationType.name}
                            </Checkbox>
                          );
                        })}
                      </HStack>
                    )}
                  </Stack>
                ))}
            </Box>

            {/* <Box p={0}>
              <Actions isDisplaySetting={isDisplaySetting} />
            </Box> */}
            {/* <TabPanel p={0}>
              <Issues isDisplaySetting={isDisplaySetting} />
            </TabPanel>
            <TabPanel p={0}>
              <InspectionsTab isDisplaySetting={isDisplaySetting} />
            </TabPanel>
            <TabPanel p={0}>
              <Sensors isDisplaySetting={isDisplaySetting} />
            </TabPanel>
            <TabPanel p={0}>
              <Schedules isDisplaySetting={isDisplaySetting} />
            </TabPanel>
            <TabPanel p={0}>
              <HeadsUp isDisplaySetting={isDisplaySetting} />
            </TabPanel> */}
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
}
