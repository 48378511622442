import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { FaCaretDown, FaPlus } from "react-icons/fa";

import { BsArrowReturnRight } from "react-icons/bs";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { AddInvoices } from "../../Api/Invoices";
import AddModal from "./AddModal";
import { TableComponent } from "./Components/TableComponent";
import ShowAndHideModal from "./showAndHideModal";

export function CreateInvoicesTable() {
  const form = useForm<AddInvoices.AddNewInvoice>({
    defaultValues: {
      id: "",
      to: "",
      issueDate: "",
      dueDate: "",
      invoiceNumber: "",
      reference: "",
      brandingTheme: "",
      onlinePayment: "",
      currency: "",
      amount: "",
      invoice_Component: [
        {
          item: "",
          desc: "",
          qty: 0,
          price: 0,
          account: "",
          taxRate: "",
          region: "",
          project: "",
          amount: 0,
        },
      ],
    },
  });
  const invoiceComponent = useFieldArray({
    control: form.control,
    name: "invoice_Component",
  });

  const watchedInvoiceComponent = useWatch({
    control: form.control,
    name: "invoice_Component",
  });

  const totalAmount = watchedInvoiceComponent
    .reduce((prev, curr) => {
      return prev + Number(curr.amount);
    }, 0)
    .toFixed(2);

  // Find 10% of the total amount
  const tenPercent = (Number(totalAmount) * 0.1).toFixed(2);
  const totalSum = (Number(totalAmount) + Number(tenPercent)).toFixed(2);
  const amountOptions = ["Tax exclusive", "Tax inclusive", "No Tax"].map(
    (amount) => ({
      label: amount,
      value: amount,
    })
  );
  const brandingOptions = [
    "Standard",
    "Special Projects",
    "Very orange invoices",
  ].map((brand) => ({
    label: brand,
    value: brand,
  }));
  const showHideModal = useDisclosure();
  const addModal = useDisclosure();
  const currencyOptions = [
    "AED: United Arab Emirates Dirham",
    "AFN: Afghan Afghani",
    "ALL: Albanian Lek",
    "AMD: Armenian Dram",
    "ANG: Netherlands Antillean Guilder",
    "AOA: Angolan Kwanza",
    "ARS: Argentine Peso",
    "AUD: Australian Dollar",
    "AWG: Aruban Florin",
    "AZN: Azerbaijani Manat",
    "BAM: Bosnia-Herzegovina Convertible Mark",
    "BBD: Barbadian Dollar",
    "BDT: Bangladeshi Taka",
    "BGN: Bulgarian Lev",
    "BHD: Bahraini Dinar",
    "BIF: Burundian Franc",
    "BMD: Bermudan Dollar",
    "BND: Brunei Dollar",
    "BOB: Bolivian Boliviano",
    "BRL: Brazilian Real",
    "BSD: Bahamian Dollar",
    "BTC: Bitcoin",
    "BTN: Bhutanese Ngultrum",
    "BWP: Botswanan Pula",
    "BYN: Belarusian Ruble",
    "BZD: Belize Dollar",
    "CAD: Canadian Dollar",
    "CDF: Congolese Franc",
    "CHF: Swiss Franc",
    "CLF: Chilean Unit of Account (UF)",
    "CLP: Chilean Peso",
    "CNH: Chinese Yuan (Offshore)",
    "CNY: Chinese Yuan",
    "COP: Colombian Peso",
    "CRC: Costa Rican Colón",
    "CUC: Cuban Convertible Peso",
    "CUP: Cuban Peso",
    "CVE: Cape Verdean Escudo",
    "CZK: Czech Republic Koruna",
    "DJF: Djiboutian Franc",
    "DKK: Danish Krone",
    "DOP: Dominican Peso",
    "DZD: Algerian Dinar",
    "EGP: Egyptian Pound",
    "ERN: Eritrean Nakfa",
    "ETB: Ethiopian Birr",
    "EUR: Euro",
    "FJD: Fijian Dollar",
    "FKP: Falkland Islands Pound",
    "GBP: British Pound Sterling",
    "GEL: Georgian Lari",
    "GGP: Guernsey Pound",
    "GHS: Ghanaian Cedi",
    "GIP: Gibraltar Pound",
    "GMD: Gambian Dalasi",
    "GNF: Guinean Franc",
    "GTQ: Guatemalan Quetzal",
    "GYD: Guyanaese Dollar",
    "HKD: Hong Kong Dollar",
    "HNL: Honduran Lempira",
    "HRK: Croatian Kuna",
    "HTG: Haitian Gourde",
    "HUF: Hungarian Forint",
    "IDR: Indonesian Rupiah",
    "ILS: Israeli New Sheqel",
    "IMP: Manx pound",
    "INR: Indian Rupee",
    "IQD: Iraqi Dinar",
    "IRR: Iranian Rial",
    "ISK: Icelandic Króna",
    "JEP: Jersey Pound",
    "JMD:Jamaican Dollar",
    "JOD: Jordanian Dinar",
    "JPY: Japanese Yen",
    "KES: Kenyan Shilling",
    "KGS:Kyrgystani Som",
    "KHR: Cambodian Riel",
    "KMF: Comorian Franc",
    "KPW: North Korean Won",
    "KRW: South Korean Won",
    "KWD: Kuwaiti Dinar",
    "KYD: Cayman Islands Dollar",
    "KZT: Kazakhstani Tenge",
    "LAK: Laotian Kip",
    "LBP: Lebanese Pound",
    "LKR: Sri Lankan Rupee",
    "LRD: Liberian Dollar",
    "LSL: Lesotho Loti",
    "LYD: Libyan Dinar",
    "MAD: Moroccan Dirham",
    "MDL: Moldovan Leu",
    "MGA: Malagasy Ariary",
    "MKD: Macedonian Denar",
    "MMK: Myanma Kyat",
    "MNT: Mongolian Tugrik",
    "MOP: Macanese Pataca",
    "MRU: Mauritanian Ouguiya",
    "MUR: Mauritian Rupee",
    "MVR: Maldivian Rufiyaa",
    "MWK: Malawian Kwacha",
    "MXN: Mexican Peso",
    "MYR: Malaysian Ringgit",
    "MZN: Mozambican Metical",
    "NAD: Namibian Dollar",
    "NGN: Nigerian Naira",
    "NIO: Nicaraguan Córdoba",
    "NOK: Norwegian Krone",
    "NPR: Nepalese Rupee",
    "NZD: New Zealand Dollar",
    "OMR: Omani Rial",
    "PAB: Panamanian Balboa",
    "PEN: Peruvian Nuevo Sol",
    "PGK: Papua New Guinean Kina",
    "PHP: Philippine Peso",
    "PKR: Pakistani Rupee",
    "PLN: Polish Zloty",
    "PYG: Paraguayan Guarani",
    "QAR: Qatari Rial",
    "RON: Romanian Leu",
    "RSD: Serbian Dinar",
    "RUB: Russian Ruble",
    "RWF: Rwandan Franc",
    "SAR: Saudi Riyal",
    "SBD: Solomon Islands Dollar",
    "SCR: Seychellois Rupee",
    "SDG: Sudanese Pound",
    "SEK: Swedish Krona",
    "SGD: Singapore Dollar",
    "SHP: Saint Helena Pound",
    "SLL: Sierra Leonean Leone",
    "SOS: Somali Shilling",
    "SRD: Surinamese Dollar",
    "SSP: South Sudanese Pound",
    "STD: São Tomé and Príncipe Dobra (pre-2018)",
    "STN: São Tomé and Príncipe Dobra",
    "SVC: Salvadoran Colón",
    "SYP: Syrian Pound",
    "SZL: Swazi Lilangeni",
    "THB: Thai Baht",
    "TJS: Tajikistani Somoni",
    "TMT: Turkmenistani Manat",
    "TND: Tunisian Dinar",
    "TOP: Tongan Pa'anga",
    "TRY: Turkish Lira",
    "TTD: Trinidad and Tobago Dollar",
    "TWD: New Taiwan Dollar",
    "TZS: Tanzanian Shilling",
    "UAH: Ukrainian Hryvnia",
    "UGX: Ugandan Shilling",
    "USD: United States Dollar",
    "UYU: Uruguayan Peso",
    "UZS: Uzbekistan Som",
    "VEF:Venezuelan Bolívar Fuerte (Old)",
    "VES:Venezuelan Bolívar Soberano",
    "VND:Vietnamese Dong",
    "VUV:Vanuatu Vatu",
    "WST: Samoan Tala",
    "XAF: CFA Franc BEAC",
    "XAG: Silver Ounce",
    "XAU: Gold Ounce",
    "XCD: East Caribbean Dollar",
    "XDR: Special Drawing Rights",
    "XOF: CFA Franc BCEAO",
    "XPD: Palladium Ounce",
    "XPF: CFP Franc",
    "XPT: Platinum Ounce",
    "YER: Yemeni Rial",
    "ZAR: South African Rand",
    "ZMW: Zambian Kwacha",
    "ZWL: Zimbabwean Dollar",
  ].map((currency) => ({ label: currency, value: currency }));
  return (
    <FormProvider {...form}>
      <Stack
        _light={{
          bgColor: "customColor.white",
        }}
        marginTop={"20px"}
        padding={"32px"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
      >
        <SimpleGrid columns={{ xs: 2, md: 3, lg: 5 }} spacing={5}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "To is required",
              },
            }}
            control={form.control}
            name="to"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "text",
                  marginBottom: "20px",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="To"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
          <Controller
            rules={{
              required: {
                value: true,
                message: "Issue Date is required",
              },
            }}
            control={form.control}
            name="issueDate"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "date",
                  marginBottom: "20px",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Issue Date"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
          <Controller
            rules={{
              required: {
                value: true,
                message: "Due Date is required",
              },
            }}
            control={form.control}
            name="dueDate"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "date",
                  marginBottom: "20px",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Due Date"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
          <Controller
            rules={{
              required: {
                value: true,
                message: "Invoice number is required",
              },
            }}
            control={form.control}
            name="invoiceNumber"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "text",
                  marginBottom: "20px",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Invoice Number"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
          <Controller
            rules={{
              required: {
                value: true,
                message: "Reference is required",
              },
            }}
            control={form.control}
            name="reference"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "text",
                  marginBottom: "20px",
                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Reference"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
          <Stack>
            <Text
              fontSize=".845rem"
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Branding Theme
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Branding Theme  is required",
                },
              }}
              // control={form.control}
              control={form.control}
              name="brandingTheme"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  // marginBottom="30px"
                  // width={"48em"}
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Branding Theme  "
                  withValidation
                  options={brandingOptions}
                  value={amountOptions.find(
                    (amountOptions) => amountOptions.value === field.value
                  )}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                fontSize=".845rem"
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Online Payments
              </Text>
              <Button
                variant={"ghost"}
                h={"20px"}
                fontSize={".845rem"}
                color={"#3454d1"}
                leftIcon={<FaPlus fontSize={".845rem"} />}
                _hover={{
                  bg: "rgba(0,10,30,.05)",
                }}
                onClick={() => {
                  addModal.onOpen();
                }}
              >
                Add
              </Button>
            </Stack>
            <Controller
              control={form.control}
              name="onlinePayment"
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  withValidation
                  validationMessage={error?.message}
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",

                    _light: {
                      color: "customColor.black7",
                    },
                    value: "None",
                    isDisabled: true,
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    padding: "12px 15px",

                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                />
              )}
            />
          </Stack>
          <Stack>
            <Text
              fontSize=".845rem"
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Currency:
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Currency is required",
                },
              }}
              // control={form.control}
              control={form.control}
              name="currency"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  // marginBottom="30px"
                  // width={"48em"}
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Currency "
                  withValidation
                  options={currencyOptions}
                  value={currencyOptions.find(
                    (currencyOptions) => currencyOptions.value === field.value
                  )}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack>
            <Text
              fontSize=".845rem"
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Account are
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Amounts is required",
                },
              }}
              // control={form.control}
              control={form.control}
              name="amount"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  // marginBottom="30px"
                  // width={"48em"}
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Amount "
                  withValidation
                  options={amountOptions}
                  value={amountOptions.find(
                    (amountOptions) => amountOptions.value === field.value
                  )}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
        </SimpleGrid>
        <TableComponent form={form} invoiceComponent={invoiceComponent} />
        <Stack
          direction={"row"}
          marginTop={"15px"}
          alignItems={"baseline"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} flex={3}>
            <Button
              leftIcon={<BsArrowReturnRight fontSize={"20px"} />}
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                invoiceComponent.append({
                  item: "",
                  desc: "",
                  qty: 0,
                  price: 0,
                  account: "",
                  taxRate: "",
                  region: "",
                  project: "",
                  amount: 0,
                });
              }}
            >
              New Invoice Line
            </Button>
            <Button
              borderRadius={"3px"}
              borderRightRadius={0}
              backgroundColor={"#fff"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                showHideModal.onOpen();
              }}
            >
              Show/hide fields
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                borderRadius={"3px"}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  color: "customColor.black7",
                  bg: "#fff",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "#3545d1",
                  bg: "#fff",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                rightIcon={<FaCaretDown />}
              >
                Files
              </MenuButton>
              <MenuList
                overflowY="auto"
                zIndex={"overlay"}
                w={"500px"}
                boxShadow={
                  "0 0 0 1px rgba(0,10,30,.2),0 3px 6px 0 rgba(0,10,30,.2)"
                }
                padding={"12px"}
                borderRadius={"3px"}
              >
                <Stack gap={0}>
                  <Text
                    paddingBottom={"4px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"13.44px"}
                    fontWeight={"600"}
                  >
                    Attach Files
                  </Text>
                  <Stack
                    gap={0}
                    backgroundColor={"#000A1E1A"}
                    border={"1px dashed rgba(0,10,30,.5)"}
                    cursor={"not-allowed"}
                    padding={"20px"}
                    alignItems={"center"}
                    justifyItems={"center"}
                  >
                    <Text
                      fontSize={"15"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Drag and drop files or select manually
                    </Text>
                    <Button
                      marginTop={"12px"}
                      border={"1px solid #80858f "}
                      cursor={"not-allowed"}
                      borderRadius={"3px"}
                      backgroundColor={"#fff"}
                      fontSize={"13px"}
                      variant={"solid"}
                      textColor={"customColor.6b7885"}
                      padding={"12px 16px"}
                      _hover={{
                        border: "1px solid #80858f ",
                        bgColor: "#fff",
                      }}
                    >
                      Upload File
                    </Button>
                  </Stack>
                </Stack>
              </MenuList>
            </Menu>
          </Stack>
          <Stack flex={1}>
            <HStack justifyContent={"space-between"}>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                Subtotal
              </Text>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                {totalAmount}
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"}>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                Total GST
              </Text>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                {tenPercent}
              </Text>
            </HStack>
            <Stack borderTop={"2px solid #e5e7eb"}>
              <HStack
                justifyContent={"space-between"}
                paddingTop={"10px"}
                paddingBottom={"10px"}
              >
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"400"}
                  fontSize={"15px"}
                >
                  Total
                </Text>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"700"}
                  fontSize={"21px"}
                >
                  {totalSum}
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ShowAndHideModal
        isOpen={showHideModal.isOpen}
        onClose={showHideModal.onClose}
      />
      <AddModal isOpen={addModal.isOpen} onClose={addModal.onClose} />
    </FormProvider>
  );
}
