import {
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from "@chakra-ui/react";

export var themeConfig: ThemeOverride = {
  config: {
    cssVarPrefix: "hris",
    initialColorMode: "light",
    useSystemColorMode: true,
  },
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
  },

  colors: {
    default: "#495057",
    primary: {
      "50": "#E6F7FC",
      "100": "#BFEFFA",
      "200": "#99E7F8",
      "300": "#73DFF6",
      "400": "#4DD7F3",
      "500": "#47C5E8",
      "600": "#3DAACE",
      "700": "#338FB4",
      "800": "#28649A",
      "900": "#1E3A80",
      "950": "#3454d1",
    },
    secondary: {
      "50": "#FFF8E6",
      "100": "#FFEABF",
      "200": "#FFDC99",
      "300": "#FFCF73",
      "400": "#FFC14D",
      "500": "#FDB913",
      "600": "#E5A610",
      "700": "#CC930D",
      "800": "#B3800A",
      "900": "#996D07",
    },
    customColor: {
      defaultBlack: "#1A202C",
      black: "#495057",
      black2: "#000A1EBF",
      black3: "#212529",
      black4: "#333333",
      black5: "#000A1E",
      black6: "#000000",
      black7: "#283C50",
      black8: "#001327",
      charcoal: "#32465A",
      "32465aBF": "#32465aBF",
      "555": "#555555",
      tableHeaderColor: "#747A80",
      tableHeaderBlue: "#0590C3",
      gray: {
        "50": "#74788D",
        "100": "#5A5A5A",
        "150": "#7b8190",
        "200": "#808080",
        "300": "#383c40",
        "400": "#f5f6f7",
        "450": "#F6F6F6",
        "700": "#2D3748",
        "800": "#444",
        "850": "#666666",
        "900": "#FBFCFE",
        "950": "#FBFBFB",
        "64748B": "#64748B",
        "7587A7": "#7587A7",
        "6b7885": "#6b7885",
      },
      dark: {
        50: "#30303a",
        100: "#2b2b35",
        150: "#92929C",
        200: "#B0B0B0",
        250: "#6C757D",
      },
      blue: {
        "10": "#f3f8fb",
        "20": "#5b73e8",
      },
      Blueberry: {
        50: "#e6ecff",
        100: "#bbc6fa",
        200: "#8ea0f0",
        300: "#6279e9",
        400: "#3753e2",
        500: "#1e3ac8",
        600: "#162d9d",
        700: "#0e2071",
        800: "#051346",
        900: "#01061c",
      },

      white: "#fff",
      pearlWhite: "#F8F6F0",
      cream: "#FFFDD0",
      calendar: {
        50: "#000000E6",
        100: "#272C33",
        150: "#0000008A",
      },
    },

    borders: {
      primary: "#000000",
      accent: "#e6e7efd9",
      tableBorder: "#e5e7eb",
      hover: "#80858f",
      focus: "#3454d1",
    },
    solidBlue: {
      50: "#dafbff",
      100: "#b3ebfb",
      200: "#89dcf3",
      300: "#5ccdec",
      400: "#33bfe5",
      500: "#1aa5cc",
      600: "#0880a0",
      700: "#005c73",
      800: "#003847",
      900: "#00141d",
    },
    solidTeal: {
      50: "#d8ffff",
      100: "#acfeff",
      200: "#7dfbff",
      300: "#4df9ff",
      400: "#27f8ff",
      500: "#17dee6",
      600: "#00adb3",
      700: "#007c80",
      800: "#004b4e",
      900: "#001b1d",
    },
    customGray: {
      50: "#e9f3fe",
      100: "#d1dae0",
      200: "#b9bfc7",
      300: "#9ea6ad",
      400: "#848c94",
      500: "#6b737b",
      600: "#525961",
      700: "#394046",
      800: "#21262d",
      900: "#020e17",
    },
    Cornflower: {
      50: "#e6eeff",
      100: "#becbf6",
      200: "#95a9eb",
      300: "#6b86e0",
      400: "#4264d6",
      500: "#294abd",
      600: "#1e3a94",
      700: "#14296b",
      800: "#091943",
      900: "#02081c",
    },
    Pantone: {
      50: "#e9f3fe",
      100: "#d0dae1",
      200: "#b7bfc8",
      300: "#9ca5af",
      400: "#818c97",
      500: "#68727e",
      600: "#505963",
      700: "#393f48",
      800: "#20262e",
      900: "#020e17",
    },
    MutedLavender: {
      50: "#e7f0ff",
      100: "#c5d1ef",
      200: "#a1b3df",
      300: "#7c94cf",
      400: "#5876c0",
      500: "#3f5ca7",
      600: "#304883",
      700: "#21335f",
      800: "#111f3c",
      900: "#030a1b",
    },
    Frog: {
      50: "#ebffdf",
      100: "#d1fcb2",
      200: "#b4f984",
      300: "#97f654",
      400: "#7af425",
      500: "#61da0b",
      600: "#4aaa05",
      700: "#347901",
      800: "#1c4a00",
      900: "#051900",
    },
    UltraRed: {
      50: "#ffe3ea",
      100: "#ffb1c3",
      200: "#fe809a",
      300: "#fd4f72",
      400: "#fb1f4a",
      500: "#e20932",
      600: "#b00226",
      700: "#7f001b",
      800: "#4e000f",
      900: "#1f0004",
    },
    BlueMartina: {
      50: "#dbfffc",
      100: "#b4f7f0",
      200: "#8bf0e5",
      300: "#61e8da",
      400: "#37e1d0",
      500: "#1ec8b6",
      600: "#0d9b8e",
      700: "#007065",
      800: "#00443d",
      900: "#001815",
    },
    HotSun: {
      50: "#fff7db",
      100: "#ffe6ae",
      200: "#fed57f",
      300: "#fcc54e",
      400: "#fbb41e",
      500: "#e19a04",
      600: "#af7800",
      700: "#7e5600",
      800: "#4c3300",
      900: "#1d1000",
    },
    customColor2: {
      // Your other color definitions
      offTrackColor: "#E5E7EB", // Color when switch is off
      onTrackColor: "#3454d1", // Color when switch is on
      thumbColor: "#fff", // Thumb color for both states
      thumbColorChecked: "#fff", // Thumb color when checked
    },
  },
  breakpoints: {
    xs: "1rem",
  },
  components: {
    Modal: {
      baseStyle: {
        zIndex: 2000,
      },
    },
    Button: {
      defaultProps: {
        size: "sm",
        fontSize: 14,
      },
      baseStyle: { borderRadius: "none" },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: "customColor2.offTrackColor", // Default track color
          _checked: {
            bg: "customColor2.onTrackColor", // Track color when checked
          },
        },
        thumb: {
          bg: "customColor2.thumbColor", // Default thumb color
          _checked: {
            bg: "customColor2.thumbColorChecked", // Thumb color when checked
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: "primary.950", // Default checked color for the radio
            borderColor: "primary.950", // Default border color for the radio
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: "primary.950", // Background color when checked
            borderColor: "primary.950",
            _hover: {
              bg: "primary.950",
              borderColor: "primary.950",
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "primary.600",
      },
    },
    Heading: {
      variants: {
        page: {
          fontSize: "22px",
          fontWeight: "bold",
          lineHeight: "26.4px",
          boxSizing: "border-box",
        },
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: "primary.100",
        endColor: "primary.500",
      },
    },
    Tabs: {
      defaultProps: {
        colorScheme: "#5A5A5A",
      },
    },
  },
};

var lightTheme = extendTheme(
  // extendTheme(theme),
  // withDefaultColorScheme({ colorScheme: "primary" })
  extendTheme(themeConfig),
  withDefaultColorScheme({ colorScheme: "primary" })
);

export default lightTheme;
