import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";
import { PayItemEarningApi } from "../../../Api/PayItemEarning";
import { PayTemp } from "../../../Api/PayTemp";

interface AddEarningLineProps {
  isOpen: boolean;
  onClose: () => void;
  resetData: {
    payItemEarningId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum;
    index?: number;
  };
  onPressOK: (data: {
    payItemEarningId: string;
    rateType: PayTemp.PayTemplateRateTypeEnum;
    category: PayTemp.PayTemplateItemTypeEnum;
    calcType: PayTemp.PayTemplateCalcTypeEnum;
    isTaxable?: boolean;
    index?: number;
  }) => void;
  account: PayItemEarningApi.EarningById[];
}
export default function AddEarningLine({
  isOpen,
  onClose,
  onPressOK,
  account,
  resetData,
}: AddEarningLineProps) {
  const [payItemId, setPayItemId] = useState("");
  const [calculationType, setCalculationType] =
    useState<PayTemp.PayTemplateCalcTypeEnum>(
      PayTemp.PayTemplateCalcTypeEnum.MANUAL_EARNING
    );
  const [index, setIndex] = useState<number | undefined>(resetData.index);
  const handlePayItemChange = (newValue: any) => {
    setPayItemId(newValue?.id);
    const selectedAccount = account.find((acc) => acc.id === newValue?.id);
    const newRateType: any =
      selectedAccount?.rateType || PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT;
    setRateType(newRateType);
  };
  const [rateType, setRateType] = useState<PayTemp.PayTemplateRateTypeEnum>(
    PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT
  );
  useEffect(() => {
    setPayItemId(resetData.payItemEarningId);
    setCalculationType(resetData.calculationType);
    setIndex(resetData.index);
  }, [resetData]);
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          h="50px"
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.dark.250",
          }}
        >
          Add an Earnings Line
        </ModalHeader>

        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack gap={0} maxW={"300px"}>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"bold"}
            >
              Earning Rate <Icon color="teal.500" as={QuestionIcon} />
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={account}
              value={{
                id: payItemId,
                name:
                  account.find((account) => account.id === payItemId)?.name ??
                  "",
              }}
              onChange={handlePayItemChange}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </Stack>
          <Stack gap={0} maxW={"300px"}>
            <FormControl>
              <FormLabel
                mb={0}
                fontWeight="bold"
                marginTop="20px"
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Expense Account
              </FormLabel>

              <Text
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                marginTop={"5px"}
                marginBottom={"5px"}
                borderTop={"1px solid #999"}
                borderBottom={"1px solid #ccc"}
              >
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.code
                }
                {":"}
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.name
                }
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onPressOK({
                payItemEarningId: payItemId,
                rateType,
                category: PayTemp.PayTemplateItemTypeEnum.EARNING,
                calcType: calculationType,
                index: index,
                isTaxable: account.find((acc) => acc.id === payItemId)
                  ?.isTaxable,
              });

              onClose();
            }}
          >
            Ok
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
