import { axiosInstance } from "./axiosInstance-hris";
declare module PayItemLeaveApi {
  type AddNewLeave = {
    category: string;
    leaveType: string;
    name: string;
    unitType: string;
    normalEntitlement: number;
    showBalancePayslip: boolean;
    maxThreshold?: number;
    deductionPayItemId?: string;
  };
  type LeaveById = {
    id?: string;
    category: string;
    leaveType: string;
    name: string;
    unitType: string;
    normalEntitlement: number;
    showBalancePayslip: boolean;
    active: boolean;
    businessId: string;
    maxThreshold?: number;
    deductionPayItemId?: string;
  };
}
module PayItemLeaveApi {
  const API_URL = "/payroll-pay-items-leaves";
  export function NewLeaveAdded(
    data: AddNewLeave,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchLeave(
    success: (data: Array<LeaveById>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateLeave(
    id: string,
    data: Partial<AddNewLeave>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function IsActive(
    id: string,
    isActive: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/status/${id}/${isActive}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteLeave(
    id: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
}
export { PayItemLeaveApi };
