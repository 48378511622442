import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StatutoryBenefitsApi } from "../../Api/StautoryBenefitsApi";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
  setLastUpdated: (time: number) => void;
}
export default function DeleteSuperannuation({
  isOpen,
  onClose,
  id,
  setLastUpdated,
}: DeleteProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {" "}
            Are you sure you want to delete this
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              StatutoryBenefitsApi.DeletePaymentsDetails(
                id!,
                (success) => {
                  toast({
                    title: "Payment Deleted Successfully",
                    status: "success",
                  });
                  setIsLoading(false);
                  setLastUpdated(Date.now());
                  navigate("/app/payroll/Superannuation");
                  onClose();
                },
                () => {
                  toast({
                    title: "Error Deleting Payment",
                    status: "error",
                  });
                  setIsLoading(false);
                }
              );
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
