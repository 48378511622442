import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import ManageNotificationGroup from "./Components/ManageNotificationGroup";
import NotificationSetting from "./Components/NotificationSetting";

export function EmployeeNotifications() {
  return (
    <Stack
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Tabs isLazy>
        <TabList>
          <Tab>Notifications</Tab>
          <Tab>Groups</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <NotificationSetting />
          </TabPanel>
          <TabPanel>
            <ManageNotificationGroup />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
