import { axiosInstance } from "./axiosInstance-hris";

declare module ProductivityTracker {
  type DayHours = {
    [key: string]: number;
  };

  type PayRateData = {
    timesheetId?: string;
    id?: string;
    employeeId: string;
    dayHours: DayHours;
  };
  type AddNewDetail = {
    earningRateId: string;
    calenderId: string;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    period: string;
    status: string;
    totalQuantity: number;
    employeesData: PayRateData[];
  };
  type UpdateTimesheetToApprove = {
    status: string;
    ids: string[];
  };
  // type UpdateTimeSheetPayHours = {
  //   id: string;
  //   payItemsEarningsId: string;
  //   dayHours: DayHours;
  // };

  // type UpdateTimeSheet = {
  //   employeeId: string;
  //   dateRange: {
  //     startDate: string;
  //     endDate: string;
  //   };
  //   period: string;
  //   status: string;
  //   totalHours: number;
  //   payRatesData: Array<UpdateTimeSheetPayHours>;
  // };

  type FetchTimeSheetData = {
    id: string;
    earningRateId: string;
    dateRange: {
      endDate: string;
      startDate: string;
    };
    period: string;
    status: string;
    totalQuantity: number;
    calenderId: string;
    earningRate: {
      id: string;

      category: string;
      name: string;
      displayName: string;
      rateType: string;
      fixedAmount: number;
      chartAccountId: string;
      isTaxable: boolean;
      retirementFundExcemption: boolean;
      reportableActivityStatement: boolean;
      multiple: string | null;
      rateUnitType: string;
      unitRate: string;
      active: boolean;
      allowanceType: string;
      businessId: string;
    };
    employeesData: [
      {
        dayHours: DayHours;
        employeeId: string;
        totalQuantity: number;
        id: string;
        shiftTimesheetId: string;
        employee?: {
          id: string;
          firstName: string;
          lastName: string;
          email: string;
        };
      }
    ];
    updatedAt?: string;
  };
}
module ProductivityTracker {
  const API_URL = "/shifts-timesheet";
  export function AddTimesheet(
    data: AddNewDetail,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateTimesheetToApproved(
    data: UpdateTimesheetToApprove,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/status/update`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchTimeSheets(
    params: {
      earningRateId?: string;
      startDate?: string;
      endDate?: string;
      status: string;

      page?: number;
      limit?: number;
    },
    success: (response: Array<FetchTimeSheetData>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, {
        params,
      })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchTimeSheetById(
    id: string,
    success: (data: FetchTimeSheetData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function PatchTimeSheet(
    id: string,
    data: AddNewDetail,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteTimeSheet(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { ProductivityTracker };
