import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FaChevronLeft, FaChevronRight, FaRegClock } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { MdDelete, MdFreeBreakfast } from "react-icons/md";
import { PiBagFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { EmployeeDashboardTimesheet } from "../../../Api/EmployeeDashboardTimesheet";

interface AddTimesheetProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddTimesheet({ isOpen, onClose }: AddTimesheetProps) {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const maxLength = 200;
  const onSubmit = (data: any) => {};
  const handleChange = (e: any) => {
    const inputText = e.target.value;
    if (inputText.length <= maxLength) {
      setText(inputText);
    }
  };

  const form = useFormContext<EmployeeDashboardTimesheet.AddNewTimesheet>();
  const workComponent = useFieldArray({
    control: form.control,
    name: "work",
  });

  // Watch changes in startTime and endTime
  const workFields = useWatch({
    control: form.control,
    name: "work",
  });

  useEffect(() => {
    workFields.forEach((field, index) => {
      if (field.startTime && field.endTime) {
        const startTime = moment(field.startTime, "HH:mm");
        const endTime = moment(field.endTime, "HH:mm");
        const duration = moment.duration(endTime.diff(startTime));
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const durationString = `${hours}h ${minutes}m`;

        form.setValue(`work.${index}.duration`, durationString);
      }
    });
  }, [workFields, form]);

  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
          maxWidth={"600px"}
        >
          <ModalHeader
            justifyItems={"center"}
            fontSize={"19px"}
            padding={"24px 20px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Fri 7 Jun 2024
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={"0px"}>
            {workComponent.fields.length === 0 ? (
              <Stack
                padding={"32px 0px 20px"}
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"21px"}
                  fontWeight={"700"}
                >
                  No Time has been recorded this day
                </Text>
                <Icon
                  as={FaRegClock}
                  _light={{
                    color: "customColor.black7",
                  }}
                />
              </Stack>
            ) : (
              <>
                {workComponent.fields.map((field, index) => (
                  <Stack
                    key={field.id}
                    margin={"12px 0px 0px"}
                    padding={"0px 20px 0px 20px"}
                    direction={"row"}
                    spacing={3}
                  >
                    <Stack gap={0} flex={2}>
                      <Text
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        padding={"0px 0px 4px"}
                        fontSize={"13.44px"}
                        fontWeight={"600"}
                      >
                        Time Entry
                      </Text>
                      <Stack
                        border={"1px solid #a6a9b0"}
                        borderRadius={"3px"}
                        cursor={"not-allowed"}
                        bg={"rgba(0,10,30,.1)"}
                      >
                        <Controller
                          name={`work.${index}.timeEntry`}
                          control={form.control}
                          render={({ field, fieldState: { error } }) => (
                            <Stack alignItems={"center"} direction={"row"}>
                              {form.getValues(`work.${index}.timeEntry`) ===
                                "Work" && (
                                <Stack
                                  bg={"#A6D0EC"}
                                  border={".5px solid #0078c8"}
                                  margin={"8px"}
                                  h={"24px"}
                                  w={"24px"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Icon as={PiBagFill} />
                                </Stack>
                              )}
                              {form.getValues(`work.${index}.timeEntry`) ===
                                "Break" && (
                                <Stack
                                  bg={"#fdd3a6"}
                                  border={".5px solid #fa8200"}
                                  margin={"8px"}
                                  h={"24px"}
                                  w={"24px"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Icon as={MdFreeBreakfast} />
                                </Stack>
                              )}
                              <Text
                                fontWeight={"400"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.white",
                                }}
                                fontSize={"13.44px"}
                                padding={"7px 16px 7px 2px"}
                              >
                                {form.getValues(`work.${index}.timeEntry`)}
                              </Text>
                            </Stack>
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} flex={2} gap={0}>
                      <Stack gap={0}>
                        <Text
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          padding={"0px 12px 4px 0px"}
                          fontSize={"13.44px"}
                          fontWeight={"600"}
                        >
                          Start time
                        </Text>
                        <Controller
                          name={`work.${index}.startTime`}
                          control={form.control}
                          rules={{
                            required: {
                              value: true,
                              message: "Start Time  is required",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                padding: "12px 15px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "time",

                                borderLeftRadius: "3px",
                              }}
                              placeholder=""
                            />
                          )}
                        />
                      </Stack>
                      <Stack gap={0}>
                        <Text
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          padding={"0px 12px 4px 0px"}
                          fontSize={"13.44px"}
                          fontWeight={"600"}
                        >
                          End time
                        </Text>
                        <Controller
                          name={`work.${index}.endTime`}
                          control={form.control}
                          rules={{
                            required: {
                              value: true,
                              message: "End Time  is required",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                padding: "12px 15px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "time",

                                borderLeftRadius: "3px",
                              }}
                              placeholder=""
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack gap={0} flex={2}>
                      <Text
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        padding={"0px 0px 4px"}
                        fontSize={"13.44px"}
                        fontWeight={"600"}
                      >
                        Duration
                      </Text>
                      <Controller
                        name={`work.${index}.duration`}
                        control={form.control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              isDisabled: true,
                              fontSize: "15px",
                              bg: "rgba(0,10,30,.1)",
                              cursor: "not-allowed",
                              variant: "outline",
                              type: "text",
                              border: "1px solid #a6a9b0",
                              borderLeftRadius: "3px",
                            }}
                            placeholder=""
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      gap={0}
                      flex={1}
                      marginTop={"10px"}
                      borderRadius={"full"}
                      _hover={{
                        bg: "#ddd",
                      }}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Icon
                        as={MdDelete}
                        fontSize={"18px"}
                        onClick={() => {
                          workComponent.remove(index);
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </>
            )}
            <Box margin={"12px 0px 0px"} padding={"0px 20px 0px 20px"}>
              <Button
                variant={"link"}
                color={"primary.950"}
                leftIcon={<FiPlus />}
                padding={0}
                onClick={() => {
                  workComponent.append({
                    timeEntry: "Work",
                    startTime: "",
                    endTime: "",
                    duration: "",
                  });
                }}
              >
                Add Work
              </Button>
            </Box>
            <Box margin={"12px 0px 0px"} padding={"0px 20px 0px 20px"}>
              <Button
                variant={"link"}
                color={"primary.950"}
                leftIcon={<FiPlus />}
                padding={0}
                onClick={() => {
                  workComponent.append({
                    timeEntry: "Break",
                    startTime: "",
                    endTime: "",
                    duration: "",
                  });
                }}
              >
                Add unpaid break
              </Button>
            </Box>
            <Box margin={"12px 0px 0px"} padding={"0px 20px 0px 20px"}>
              <HStack gap={0} justifyContent={"space-between"}>
                <Text
                  _light={{
                    color: "customColor.black5",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  padding={"0px 12px 4px 0px"}
                  fontSize={"13.44px"}
                  fontWeight={"600"}
                >
                  Notes
                </Text>
                <Text
                  _light={{ color: "customColor.black5" }}
                  _dark={{ color: "customColor.white" }}
                  padding="0px 12px 4px 0px"
                  fontSize="13px"
                >
                  {text.length}/{maxLength}
                </Text>
              </HStack>
              <Textarea
                value={text}
                onChange={handleChange}
                fontSize={"13.44px"}
                border="1px solid #e5e7eb"
                _hover={{
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _focus={{
                  borderWidth: "1px",

                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                _focusVisible={{
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
              />
            </Box>
            <HStack
              direction={"row"}
              padding={"0px 20px"}
              margin={"12px 0px 0px"}
            >
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize={"15px"}
                fontWeight={"bold"}
              >
                {" "}
                Daily Hours
              </Text>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize={"17px"}
                fontWeight={"bold"}
              >
                {" "}
                7h
              </Text>
            </HStack>
          </ModalBody>

          <ModalFooter padding={"20px"}>
            <HStack justifyContent={"space-between"} width="100%">
              <Button
                variant={"link"}
                onClick={() => {
                  workComponent.replace([]);
                }}
                color={"primary.950"}
                fontSize={"14px"}
                padding={"11px 15px"}
                minH={"40px"}
                borderRadius={"3px"}
              >
                Clear day
              </Button>
              <HStack spacing={3}>
                <Button
                  leftIcon={<FaChevronLeft fontSize={"12px"} />}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                >
                  Save & previous
                </Button>
                <Button
                  rightIcon={<FaChevronRight fontSize={"12px"} />}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"#3454d1"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                >
                  Save & Next
                </Button>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={form.handleSubmit(onSubmit)}
                >
                  Save
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
