import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Select,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import {
  MdDelete,
  MdEdit,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { LocationApi } from "../../API/LocationAPi";
import DeleteLocation from "./Modal/Delete";

export function LocationMain() {
  const deleteModal = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const [locations, setLocations] = useState<Array<LocationApi.Location>>([]);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(locations);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };
  useEffect(() => {
    LocationApi.FetchLocations(
      (data) => {
        setLocations(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Container padding={"0px 20px"} maxW={"container.xl"}>
        <Stack gap={0} flex={3}>
          <Stack
            gap={0}
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
            padding={"15px 8px 0px 0px"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={0}>
              <Stack
                padding={"7px"}
                _hover={{
                  bg: "gray.200",
                  borderRadius: "full",
                }}
                onClick={() => navigate("/app/payroll/Dashboard")}
              >
                <Icon
                  as={MdOutlineKeyboardDoubleArrowLeft}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontSize={"16px"}
                  onClick={() => navigate("/app/payroll/Dashboard")}
                />
              </Stack>

              <Heading
                fontSize={"17px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.150",
                }}
                paddingLeft={"3px"}
              >
                Locations
              </Heading>
            </Stack>

            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              leftIcon={<FiPlus />}
              onClick={() => navigate("/app/TimeAttendance/addLocation")}
            >
              Add Location
            </Button>
          </Stack>
          <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
            <Stack paddingTop={"15px"}>
              <Stack
                borderRadius="4px"
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                border="1px"
                borderColor="#f6f6f6"
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
              >
                <Box w="100%" overflowX="auto" padding={"0"}>
                  <Table variant="simple" w="100%" overflowX="auto">
                    <Thead h={"36.8px"}>
                      <Tr
                        borderTopWidth={"1px"}
                        borderTopColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                      >
                        <Th
                          padding="8px 26px 8px 30px"
                          width={"20%"}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          LOCATION NAME
                        </Th>
                        <Th
                          padding="8px 26px 8px 15px"
                          width={"30%"}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          LOCATION ADDRESS
                        </Th>
                        <Th
                          width={"30%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          LOCATION CODE
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          AREA
                        </Th>
                        <Th
                          width={"10%"}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        ></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {paginatedData &&
                        paginatedData.map((location: any, index: any) => (
                          <Tr
                            key={index}
                            _hover={{
                              bgColor: "#f2f3f8",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate(
                                `/app/TimeAttendance/addLocation/${location.id}`
                              )
                            }
                          >
                            <Td
                              padding="15px 15px 15px 30px"
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              fontWeight={500}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {location.name}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {location.address}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {location.code}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {location?.areas?.length}
                            </Td>
                            <Td
                              padding="10px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              onClick={(e) => e.stopPropagation()}
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <HStack>
                                <Tooltip
                                  label="Edit"
                                  fontSize={"14px"}
                                  _dark={{
                                    bgColor: "customColor.dark.50",
                                  }}
                                  _light={{
                                    color: "customColor.black7",
                                    bgColor: "customColor.white",
                                  }}
                                  border={"1px solid #E2E8F0"}
                                  borderRadius={"3px"}
                                >
                                  <Icon
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="50%"
                                    borderStyle={"solid"}
                                    borderWidth={"1px"}
                                    borderColor={"borders.tableBorder"}
                                    bg={"#fff"}
                                    w="25px"
                                    h="25px"
                                    px="5px"
                                    py="5px"
                                    as={MdEdit}
                                    fontSize="22px"
                                    color="#6B7280"
                                    _hover={{
                                      color: "#3454d1",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(
                                        `/app/TimeAttendance/addLocation/${location.id}`
                                      );
                                    }}
                                  />
                                  {/* <Button
                                  as={IconButton}
                                  // onClick={() => {
                                  //   onOpen();
                                  //   form.reset(item);
                                  // }}
                                  icon={<AiFillEdit size="16px" />}
                                  colorScheme="primary"
                                  variant="outline"
                                  size="xs"
                                  borderRadius="5px"
                                  borderColor={"gray.400"}
                                  onClick={() =>
                                    navigate(
                                      `/app/TimeAttendance/addLocation/${location.id}`
                                    )
                                  }
                                /> */}
                                </Tooltip>
                                <Tooltip
                                  label="Delete"
                                  fontSize={"14px"}
                                  _dark={{
                                    bgColor: "customColor.dark.50",
                                  }}
                                  _light={{
                                    bgColor: "customColor.white",
                                    color: "customColor.black7",
                                  }}
                                  border={"1px solid #E2E8F0"}
                                  borderRadius={"3px"}
                                >
                                  <Icon
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="50%"
                                    borderStyle={"solid"}
                                    borderWidth={"1px"}
                                    borderColor={"borders.tableBorder"}
                                    bg={"#fff"}
                                    w="25px"
                                    h="25px"
                                    px="5px"
                                    py="5px"
                                    as={MdDelete}
                                    fontSize="22px"
                                    color="#6B7280"
                                    _hover={{
                                      color: "#3454d1",
                                    }}
                                    onClick={(e) => {
                                      deleteModal.onOpen();
                                      e.stopPropagation();
                                      setSelectedLocationId(location?.id);
                                    }}
                                  />
                                  {/* <Button
                                  size="xs"
                                  as={IconButton}
                                  icon={<MdDeleteForever size="16px" />}
                                  // onClick={() => {
                                  //   setSelectedId(item.id);
                                  //   deleteModal.onOpen();
                                  // }}
                                  colorScheme="primary"
                                  variant="outline"
                                  borderRadius="5px"
                                  borderColor={"gray.400"}
                                  onClick={() => {
                                    deleteModal.onOpen();
                                    setSelectedLocationId(location?.id);
                                  }}
                                /> */}
                                </Tooltip>
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0}
                  paddingLeft={"12px"}
                  paddingRight={"5px"}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    marginTop="10px"
                    flex={1}
                    gap={0}
                  >
                    <Stack direction={"row"} gap={0}>
                      <Text
                        color={"customColor.gray.800"}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        padding={"5px 0px"}
                        fontSize={"12px"}
                      >{`Page`}</Text>
                      <Select
                        h={"25px"}
                        w={"50px"}
                        paddingLeft={"3px"}
                        paddingRight={"3px"}
                        style={{
                          borderRadius: "3px",
                          fontWeight: "normal",
                          padding: "0px 0px 0px 3px",
                        }}
                        borderWidth={"1px"}
                        borderStyle={"solid"}
                        borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                        fontSize={"12px"}
                        placeholder=""
                        value={selectedPage}
                        onChange={(event: any) => {
                          const newPage = parseInt(event.target.value, 10);
                          setSelectedPage(newPage);
                          setCurrentPage(newPage);
                        }}
                      >
                        {getPageNumbers(
                          Math.ceil((locations?.length ?? 0) / itemsPerPage)
                        ).map((pageNumber) => (
                          <option
                            key={pageNumber}
                            value={pageNumber}
                            style={{
                              width: "70px",
                              height: "42px",
                              padding: "13px 10px 12px",
                              fontSize: "12px",
                              color: "#048ABB",
                            }}
                          >
                            {pageNumber}
                          </option>
                        ))}
                      </Select>
                      <Text
                        color={"customColor.gray.800"}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        padding={"5px 0px"}
                        fontSize={"12px"}
                      >{` of ${Math.ceil(
                        locations?.length ?? 0 / itemsPerPage
                      )}`}</Text>
                      <Text
                        color={"customColor.gray.800"}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        padding={"5px 0px"}
                        fontSize={"12px"}
                        marginLeft={"3px"}
                      >
                        ({locations?.length ?? 0} total items)
                      </Text>
                    </Stack>

                    <Text
                      marginLeft="10px"
                      color={"customColor.gray.800"}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      padding={"5px 0px"}
                      fontSize={"12px"}
                      marginRight={"5px"}
                    >
                      Showing
                    </Text>
                    {/* <ReactSelectTimeSheet
                    placeholder=""
                    options={[5, 10, 15, 25, 50].map((value) => ({
                      value,
                      label: String(value),
                    }))}
                    value={{ value: itemsPerPage, label: String(itemsPerPage) }}
                    onChange={(newValue: any) => {
                      setItemsPerPage(newValue.value);
                      setCurrentPage(1); // Reset to the first page when changing items per page
                    }}
                  /> */}
                    <Select
                      h={"25px"}
                      w={"50px"}
                      style={{
                        borderRadius: "3px",
                        fontWeight: "normal",
                        padding: "0px 0px 0px 3px",
                      }}
                      borderWidth={"1px"}
                      borderStyle={"solid"}
                      borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                      fontSize={"12px"}
                      placeholder=""
                      value={itemsPerPage}
                      onChange={(event: any) => {
                        const newValue = parseInt(event.target.value, 10);
                        setItemsPerPage(newValue);
                        setCurrentPage(1);
                      }}
                    >
                      {[25, 50, 100, 200, 500, 1000].map((value) => (
                        <option
                          key={value}
                          value={value}
                          style={{
                            width: "70px",
                            height: "42px",
                            padding: "13px 10px 12px",
                            fontSize: "12px",
                            color: "#048ABB",
                          }}
                        >
                          {String(value)}
                        </option>
                      ))}
                    </Select>

                    <Text
                      color={"customColor.gray.800"}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      padding={"5px 0px"}
                      fontSize={"12px"}
                      marginLeft={"5px"}
                    >
                      Items per page:
                    </Text>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    paddingY="10px"
                    gap={0}
                  >
                    {currentPage > 1 && (
                      <>
                        <Button
                          variant={"link"}
                          color={"#3454d1"}
                          fontSize={"12px"}
                          fontWeight={"normal"}
                          padding={0}
                          margin={"0px 0px 0px 5px"}
                          onClick={() => setCurrentPage(1)}
                        >
                          <Icon as={MdKeyboardDoubleArrowLeft} />
                          Start
                        </Button>
                        <Button
                          variant={"link"}
                          color={"#3454d1"}
                          fontSize={"12px"}
                          fontWeight={"normal"}
                          padding={0}
                          margin={"0px 0px 0px 5px"}
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                        >
                          <Icon as={MdKeyboardArrowLeft} />
                          Prev
                        </Button>
                      </>
                    )}
                    {getPageNumbers(
                      Math.ceil((locations?.length ?? 0) / itemsPerPage)
                    ).map((pageNumber) => (
                      <Button
                        key={pageNumber}
                        variant={"link"}
                        color={"#3454d1"}
                        fontSize={"12px"}
                        fontWeight={"normal"}
                        padding={0}
                        margin={"0px 0px 0px 5px"}
                        onClick={() => {
                          setSelectedPage(pageNumber);
                          setCurrentPage(pageNumber);
                        }}
                        isActive={pageNumber === selectedPage}
                        _active={{
                          color: "black",
                        }}
                      >
                        {pageNumber}
                      </Button>
                    ))}
                    {currentPage <
                      Math.ceil((locations?.length ?? 0) / itemsPerPage) && (
                      <>
                        <Button
                          variant={"link"}
                          color={"#3454d1"}
                          fontSize={"12px"}
                          fontWeight={"normal"}
                          padding={0}
                          margin={"0px 0px 0px 5px"}
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(
                                prev + 1,
                                Math.ceil(
                                  (locations?.length ?? 0) / itemsPerPage
                                )
                              )
                            )
                          }
                        >
                          Next
                          <Icon as={MdKeyboardArrowRight} />
                        </Button>
                        <Button
                          variant={"link"}
                          color={"#3454d1"}
                          fontSize={"12px"}
                          fontWeight={"normal"}
                          padding={0}
                          margin={"0px 0px 0px 5px"}
                          onClick={() =>
                            setCurrentPage(
                              Math.ceil((locations?.length ?? 0) / itemsPerPage)
                            )
                          }
                        >
                          End
                          <Icon as={MdKeyboardDoubleArrowRight} />
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Skeleton>
        </Stack>
        <DeleteLocation
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          selectedLocationId={selectedLocationId!}
          setLastUpdated={setLastUpdated}
        />
      </Container>
    </Stack>
  );
}
