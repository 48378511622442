import axios from "axios";
const axiosInstanceHris = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstanceHris.interceptors.request.use(function (config) {
  config.headers["Accept"] = "application/json";
  const token = localStorage.getItem("_auth");

  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["ngrok-skip-browser-warning"] = true;
    }
  }
  return config;
});

export default axiosInstanceHris;
export { axiosInstanceHris as axiosInstance };
