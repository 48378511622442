import { SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { ReactSelect } from "../../../../Common/ReactSelect";
import { MappingModuleApi } from "../../../Api/MappingModule";
interface PayTempProps {
  selectedPayTempFilters: {
    earning: boolean;
    deduction: boolean;
    statutoryBenefits: boolean;
    reimbursement: boolean;
  };

  earningAdded: any[];
  deductionAdded: any[];
  reimbursementAdded: any[];
  statutoryAdded: any[];

  PayTempForm: UseFormReturn<
    MappingModuleApi.PayTemplateFilters,
    any,
    undefined
  >;
}
export function PayTempComponent({
  selectedPayTempFilters,
  earningAdded,
  deductionAdded,

  reimbursementAdded,
  statutoryAdded,
  PayTempForm,
}: PayTempProps) {
  return (
    <SimpleGrid columns={3} spacing={3}>
      {selectedPayTempFilters.earning && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Earning
          </Text>
          <Controller
            control={PayTempForm.control}
            name="earning"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage=" Select Ordinary Earning"
                placeholder="Select..."
                options={earningAdded.map((earning) => ({
                  value: earning.id,
                  label: earning.name,
                }))}
                value={
                  earningAdded.find((earning) => earning.id === field.value)
                    ? {
                        value: field.value,
                        label: earningAdded.find(
                          (earning) => earning.id === field.value
                        ).name,
                      }
                    : null
                }
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedPayTempFilters.deduction && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Deduction
          </Text>
          <Controller
            control={PayTempForm.control}
            name="deduction"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage=" Select Ordinary Earning"
                placeholder="Select..."
                options={deductionAdded.map((deduction) => ({
                  value: deduction.id,
                  label: deduction.name,
                }))}
                value={
                  deductionAdded.find(
                    (deduction) => deduction.id === field.value
                  )
                    ? {
                        value: field.value,
                        label: deductionAdded.find(
                          (deduction) => deduction.id === field.value
                        ).name,
                      }
                    : null
                }
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedPayTempFilters.statutoryBenefits && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Statutory Benefits
          </Text>
          <Controller
            control={PayTempForm.control}
            name="retirement"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                withValidation
                isInvalid={fieldState.invalid}
                placeholder="Select..."
                options={statutoryAdded.map((statutory) => ({
                  value: statutory.id,
                  label: statutory.name,
                }))}
                value={
                  statutoryAdded.find(
                    (statutory) => statutory.id === field.value
                  )
                    ? {
                        value: field.value,
                        label: statutoryAdded.find(
                          (statutory) => statutory.id === field.value
                        ).name,
                      }
                    : null
                }
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedPayTempFilters.reimbursement && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Reimbursement
          </Text>
          <Controller
            control={PayTempForm.control}
            name="reimbursement"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                withValidation
                isInvalid={fieldState.invalid}
                placeholder="Select..."
                options={reimbursementAdded.map((reimbursement) => ({
                  value: reimbursement.id,
                  label: reimbursement.name,
                }))}
                value={
                  reimbursementAdded.find(
                    (reimbursement) => reimbursement.id === field.value
                  )
                    ? {
                        value: field.value,
                        label: reimbursementAdded.find(
                          (reimbursement) => reimbursement.id === field.value
                        ).name,
                      }
                    : null
                }
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
    </SimpleGrid>
  );
}
