import {Text, TextProps} from '@chakra-ui/react';

export default function TabTitle({children, ...props}: TextProps) {
  return (
    <Text
      as="h6"
      color="textcolors.primary"
      textTransform="uppercase"
      fontSize="14px"
      fontWeight={700}
      lineHeight={1.2}
      {...props}>
      {children}
    </Text>
  );
}
