import axiosInstanceGateway from "./axiosInstance-gateway";

declare module UserProfileApi {
  type UserDetail = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    country: string;
  };
  type FetchUserDetails = {
    firstName: string;
    lastName: string;
    email: string;
    isVerified: boolean;
    phone: string;
    country: string;
    defaultBusinessId: string;
  };
}
module UserProfileApi {
  const API_URL = "/users";
  export function GetUserProfile(
    success: (data: UserDetail) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateUserProfile(
    data: Partial<UserDetail>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}

export { UserProfileApi };
