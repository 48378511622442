import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { InvoiceTable } from "./InvoiceTable";

export function InvoicesParent() {
  const navigate = useNavigate();

  const tabCategories = [
    {
      label: "All",
      index: 0,
      flexValue: 1,
      component: <InvoiceTable />,
    },
    {
      label: "Draft",
      index: 1,
      flexValue: 1,
      component: <InvoiceTable />,
    },
    {
      label: "Awaiting Approval",
      index: 2,
      flexValue: 3,
      component: <InvoiceTable />,
    },
    {
      label: "Awaiting Payment",
      index: 3,
      flexValue: 3,
      component: <InvoiceTable />,
    },
    {
      label: "Paid",
      index: 4,
      flexValue: 1,
      component: <InvoiceTable />,
    },
    {
      label: "Repeating",
      index: 5,
      flexValue: 1,
      component: <InvoiceTable />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const isMediumScreen = useBreakpointValue({ base: false, md: true });
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        h={"60px"}
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"container.xl"} p={{ xs: "16px", lg: "16px 50px" }}>
          <Heading
            fontSize="24px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            fontWeight="bold"
          >
            Invoices
          </Heading>
        </Container>
      </Stack>
      <Stack
        justifyContent={"center"}
        borderBottom={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Container maxW={"container.xl"} p={{ xs: "16px", lg: "0px 50px" }}>
          <SimpleGrid
            columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}
            spacing={5}
            paddingTop={"10px"}
          >
            <HStack gap={0}>
              <Box width={"100%"}>
                <Button
                  width={"100%"}
                  borderRadius={"3px"}
                  borderRightRadius={0}
                  backgroundColor={"#fff"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"38px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "#3454D1",
                  }}
                  onClick={() => {
                    navigate("/app/payroll/Invoices");
                  }}
                >
                  New Invoices
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  h={"38px"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  borderTop={"1px solid #e5e7eb"}
                  borderRight={"1px solid #e5e7eb"}
                  borderBottom={"1px solid #e5e7eb"}
                  borderRightRadius={"4px"}
                  _hover={{
                    color: "customColor.black7",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "customColor.black7",
                    bg: "#f2f3f4",
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  icon={<IoIosArrowDown />}
                />

                <MenuList overflowY="auto" zIndex={"overlay"}>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                      onClick={() => {
                        navigate("/app/payroll/Invoices");
                      }}
                    >
                      New Invoices
                    </Text>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      New Repeating Invoices
                    </Text>
                  </MenuItem>
                  <Stack
                    borderBottom="1px solid #ccced2"
                    marginBottom={"12px"}
                    padding={" 12px"}
                    marginTop={"12px"}
                    borderTop="1px solid #ccced2"
                  >
                    <Text fontSize={"17px"}>INVOICE TO...</Text>
                  </Stack>

                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Contractors
                    </Text>
                  </MenuItem>

                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Support Clients (monthly)
                    </Text>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      + Add contact group
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>

            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"38px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                navigate("/app/payroll/NewCreditNotes");
              }}
            >
              New Credit Note
            </Button>
            <Button
              fontSize={"13px"}
              h={"38px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
            >
              Send Statements
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"38px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                navigate("/app/payroll/ImportForInvoices");
              }}
            >
              Import
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"38px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
            >
              Export
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"38px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
            >
              Invoices Remainder:off
            </Button>
          </SimpleGrid>
          <Stack
            marginTop={"24px"}
            borderTopRadius={"10px"}
            borderTop={isMediumScreen ? "1px solid #ddd" : 0}
            borderLeft={isMediumScreen ? "1px solid #ddd" : 0}
            borderRight={isMediumScreen ? "1px solid #ddd" : 0}
            transition={"all .3s ease"}
          >
            {isMediumScreen ? (
              <Tabs
                index={selectedTab}
                orientation="horizontal"
                onChange={setSelectedTab}
                isLazy
              >
                <TabList>
                  {tabCategories.map((tab, i) => (
                    <Tab
                      key={i}
                      flex={tab.flexValue}
                      borderRight={tab.index !== 5 ? "1px solid #ddd" : "none"}
                      fontSize={"13.44px"}
                      padding={"20px 15px"}
                      fontWeight={"600"}
                      _light={{ color: "#283c50" }}
                      onClick={() => setSelectedTab(tab.index)}
                      _selected={{
                        _light: { color: "#3454d1" },
                        fontWeight: 600,
                        bg: "rgba(52,84,209,.075)",
                        borderBottom: "3px solid #3454d1",
                      }}
                      _hover={{ _light: { color: "#3454d1" }, fontWeight: 600 }}
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </TabList>

                {/* <TabPanels p={0}>
                  {tabCategories.map((tab, i) => (
                    <TabPanel key={i} padding={0}>
                      {" "}
                      {tab.component}
                    </TabPanel>
                  ))}
                </TabPanels> */}
              </Tabs>
            ) : (
              <Box alignContent={"flex-end"} justifyContent={"flex-end"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<AiOutlineCaretDown />}
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    justifyContent="flex-end"
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    minW={"170px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      color: "primary.950",
                      backgroundColor: "#fff",
                    }}
                    cursor={"pointer"}
                    _active={{
                      backgroundColor: "#fff",
                      color: "primary.950",
                    }}
                  >
                    {tabCategories[selectedTab].label}
                  </MenuButton>
                  <MenuList>
                    {tabCategories.map((tab) => (
                      <MenuItem
                        fontSize={"13.44px"}
                        key={tab.index}
                        onClick={() => setSelectedTab(tab.index)}
                      >
                        {tab.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
          </Stack>
        </Container>
      </Stack>
      <Container
        maxW={"container.xl"}
        padding={{ xs: "16px", lg: "30px 50px" }}
      >
        <Box>{tabCategories[selectedTab].component}</Box>
      </Container>
    </Stack>
  );
}
