import {
  Box,
  Button,
  Collapse,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { FaChevronDown } from "react-icons/fa";
import { LuMenu } from "react-icons/lu";
import SetupGuideComponent from "./SetupGuideComponent";

export default function CollapseSetupGuide() {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box
      maxW={"360px"}
      zIndex={1051}
      pos={"absolute"}
      bottom={isOpen ? "5" : "20"}
      right="0"
    >
      {!isOpen && (
        <Button
          position="sticky"
          bottom="100"
          bg={"primary.950"}
          _hover={{
            bg: "primary.950",
          }}
          h={"40px"}
          w={isOpen ? "100%" : "auto"}
          borderLeftRadius={"50px"}
          transition="scale 0.2s"
          onClick={() => {
            onToggle();
          }}
        >
          <AnimatePresence>
            <Stack direction="row" align="center">
              {isOpen ? (
                <Text
                  key="text"
                  textAlign={"left"}
                  display={{
                    base: "none",
                    md: "none",
                    lg: "inline-block",
                  }}
                  marginLeft="3"
                  padding={"0px 0px 0px 16px"}
                  fontSize={"18px"}
                ></Text>
              ) : (
                <Icon
                  as={LuMenu}
                  fontSize={"18px"}
                  margin={"0px 0px 0px 16px"}
                  key="backIcon"
                />
              )}
              {/* {true && !isOpen && (
                <Text
                  key="text"
                  display={{
                    base: "none",
                    md: "none",
                    lg: "inline-block",
                  }}
                  marginLeft="3"
                  padding={"0px 0px 0px 16px"}
                  fontSize={"18px"}
                >
                  Setup Guide
                </Text>
              )} */}
            </Stack>
          </AnimatePresence>
        </Button>
      )}

      <Collapse in={isOpen} animateOpacity>
        <Stack
          border={"1px solid #ddd"}
          bg={"#fff"}
          maxH={"700px"}
          overflowX={"auto"}
        >
          <Stack
            direction={"row"}
            h={"56px"}
            bg={"#fff"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"8px"}
            _light={{
              bg: "primary.950",
            }}
            _dark={{
              bg: "#002e50",
            }}
          >
            <Text
              fontSize={"18px"}
              padding={"0px 0px 0px 16px"}
              _light={{
                color: "customColor.white",
              }}
              _dark={{
                color: "custom.pearlWhite",
              }}
            >
              Setup Guide
            </Text>
            <Stack
              _hover={{
                bg: "#004c81",
              }}
              boxSize={"40px"}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={onToggle}
            >
              <Icon as={FaChevronDown} color={"#fff"} />
            </Stack>
          </Stack>
          <SetupGuideComponent />
        </Stack>
      </Collapse>
    </Box>
  );
}
