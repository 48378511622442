import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
interface CheckedItemsState {
  [key: string]: string[];
}
interface FiltersModalProps {
  isOpen: boolean;
  onClose: () => void;
  filters: { [key: string]: string[] };
  checkedItems: { [key: string]: string[] };
  setCheckedItems: React.Dispatch<React.SetStateAction<CheckedItemsState>>;
}

export default function FiltersModal({
  isOpen,
  onClose,
  filters,
  checkedItems,
  setCheckedItems,
}: FiltersModalProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e: any) => {
    const searchLower = e.target.value.toLowerCase();
    setSearchTerm(searchLower);
  };

  const filteredFilters = searchTerm
    ? Object.fromEntries(
        Object.entries(filters).map(([key, values]) => [
          key,
          values.filter((value) => value.toLowerCase().includes(searchTerm)),
        ])
      )
    : filters;

  const toggleAll = (key: string) => {
    const isAllSelected = checkedItems[key]?.length === filters[key]?.length;
    setCheckedItems((prevState: CheckedItemsState) => {
      if (isAllSelected) {
        const { [key]: _, ...newState } = prevState;
        return newState;
      } else {
        return {
          ...prevState,
          [key]: [...(filters[key] ?? [])],
        };
      }
    });
  };

  const toggleCheckbox = (key: string, value: string) => {
    setCheckedItems((prevState) => {
      const currentChecked = prevState[key] ?? [];
      const newChecked = currentChecked.includes(value)
        ? currentChecked.filter((item) => item !== value)
        : [...currentChecked, value];

      if (newChecked.length === 0) {
        const { [key]: _, ...newState } = prevState;
        return newState;
      } else {
        return {
          ...prevState,
          [key]: newChecked,
        };
      }
    });
  };

  const getToggleButtonText = (key: string) => {
    const isAllSelected = checkedItems[key]?.length === filters[key]?.length;
    return isAllSelected ? "Deselect All" : "Select All";
  };
  const isCheckboxChecked = (key: string, value: string) => {
    return checkedItems[key]?.includes(value) ?? false;
  };
  const filterLabels: { [key: string]: string } = {
    LOCATION_NAME: "Location Name",
    CONTRACTOR_ABN: "Contractor ABN",
    CONTRACTOR_ACN: "Contractor ACN",
    CONTRACTOR_NAME: "Contractor Name",
    EMPLOYEE_ABN: "ABN",
    EMPLOYEE_NAME: "Full Name",
    EMPLOYEE_TFN: "TFN",
    LOCATION_ADDRESS: "Location Address",
    LOCATION_AREA: "Area",
    LOCATION_CODE: "Location Code",
    LOCATION_CONTACT_NAME: "Location Contact Name",
    LOCATION_EMAIL: "Location Contact Email",
    LOCATION_INDUSTRY: "Industry",
    LOCATION_PHONE: "Location Contact Phone",
    LOCATION_WEBSITE: "Website",
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Filter
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<AiOutlineSearch color="#545f70" size="18px" />}
              />
              <Input
                h="38px"
                minW={{ xs: "100%", md: "400px" }}
                placeholder="Search Filter"
                border="1px solid"
                borderColor="borders.accent"
                fontSize="16px"
                lineHeight="1.5rem"
                fontWeight={400}
                _light={{
                  color: "customColor.black7",
                }}
                py="8px"
                pl="38px"
                _focusVisible={{
                  boxShadow: "none",
                  border: "1px sloid",
                  borderColor: "#3454d1",
                }}
                onChange={handleSearchChange}
              />
            </InputGroup>
            <Text
              fontWeight="bold"
              _light={{
                color: "customColor.black7",
              }}
            >
              Columns
            </Text>
            <Accordion
              allowToggle
              allowMultiple
              border="1px solid #ccced2"
              borderRadius="3px"
            >
              {Object.entries(filteredFilters).map(([key, value]) => (
                <AccordionItem border="0px" key={key}>
                  <AccordionButton
                    minH="56px"
                    _hover={{ bg: "#f2f3f4" }}
                    borderBottom="1px solid #e6e7e9"
                    borderTop="1px solid #e6e7e9"
                    py="8px"
                    px="8px"
                  >
                    <Box
                      w="40px"
                      h="40px"
                      mr="8px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="50%"
                      _hover={{ background: "#000a1e0d" }}
                      _focus={{
                        background: "#000a1e0d",
                      }}
                    >
                      <AccordionIcon
                        color="#000a1ea6"
                        _hover={{
                          color: "##000a1e",
                        }}
                      />
                    </Box>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="15px"
                      fontWeight="bold"
                      py="8px"
                    >
                      {filterLabels[key] || key}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Button
                      size="sm"
                      mb="10px"
                      variant="link"
                      fontWeight="normal"
                      onClick={() => toggleAll(key)}
                    >
                      {getToggleButtonText(key)}
                    </Button>
                    <Stack>
                      {value.map((val, i) => (
                        <Checkbox
                          size="lg"
                          key={`${i}-${val}`}
                          isChecked={isCheckboxChecked(key, val)}
                          onChange={() => toggleCheckbox(key, val)}
                        >
                          {val}
                        </Checkbox>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            //   isLoading={isLoading}
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              onClose();
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
