import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { StatutoryBenefitsApi } from "../../Api/StautoryBenefitsApi";

interface Props {
  status: string | undefined;
  total: number | undefined;
  liability?: number | undefined;
}

export default function Header({ status, total, liability }: Props) {
  // Determine the status label based on the status
  const statusLabel =
    status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.APPROVED
      ? "Approved"
      : status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PENDING
      ? "Pending"
      : status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.REJECTED
      ? "Rejected"
      : status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PROCESSED
      ? "Processed"
      : status ===
        StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PENDING_APPROVAL
      ? "Pending Approval"
      : status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.WITHDRAWN
      ? "Withdrawn"
      : status ===
        StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PENDING_PROCESSING
      ? "Pending Processing"
      : "Unknown";

  // Define columns and items based on the status
  const columns =
    status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PENDING ? 3 : 2;

  const items = [
    {
      name: "Total",
      width: 1,
      number: total?.toLocaleString() || 0,
      nameColor: "#999999",
      numberColor: "#283C50",
    },
    ...(status === StatutoryBenefitsApi.StatutoryBenefitStatusEnum.PENDING
      ? [
          {
            name: "Liability",
            number: liability?.toLocaleString() || 0,
            width: 1,
            nameColor: "#999999",
            numberColor: "#283C50",
          },
        ]
      : []),
    {
      name: "Status",
      width: 2,
      number: statusLabel || "-",
      nameColor: "#999999",
      numberColor: "#AAAAAA",
    },
  ];

  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack padding="20px 20px 30px 20px">
        <Container
          justifyContent="center"
          alignItems="center"
          maxW={"container.xl"}
          padding="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          border="1px solid #d6dade"
          borderRadius="md"
        >
          <SimpleGrid columns={columns}>
            {items.map((item) => (
              <Stack
                flex={item.width}
                key={item.name}
                borderRight="1px solid #e8ebee"
                padding="20px 10px 20px 10px"
                gap="0rem"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                <Heading
                  fontSize="12px"
                  fontWeight="bold"
                  _light={{
                    color: item.nameColor,
                  }}
                  _dark={{
                    color: "customColor.cream",
                  }}
                >
                  {item.name}
                </Heading>
                <Stack
                  padding={0}
                  gap="0.2rem"
                  direction={{ xs: "column", lg: "row" }}
                >
                  <Tooltip label={item.number} fontSize="md">
                    <Text
                      _light={{
                        color: item.numberColor,
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontWeight="bold"
                      fontSize="24px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {item.number}
                    </Text>
                  </Tooltip>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Container>
      </Stack>
    </Stack>
  );
}
