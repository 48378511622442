import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

interface AccountWithoutBankFeedProps {
  isOpen: boolean;
  onClose: () => void;
}
export function AccountWithoutBankFeed({
  isOpen,
  onClose,
}: AccountWithoutBankFeedProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"}>
        <ModalHeader
          padding={"12px 20px"}
          fontSize=".9975rem"
          lineHeight={"1.75rem"}
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
        >
          Add an account without a bank feed
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"8px 20px"}
          _light={{
            color: "customColor.black7",
          }}
        >
          <Text
            fontSize=".845rem"
            _light={{
              color: "customColor.black7",
            }}
          >
            You may want to do this if:
          </Text>
          <UnorderedList
            marginTop={"15px"}
            marginBottom={"15px"}
            paddingLeft={"40px"}
          >
            <ListItem
              fontSize=".845rem"
              fontWeight="500"
              _light={{
                color: "customColor.black7",
              }}
            >
              Your bank doesn't currently provide a bank feed
            </ListItem>
            <ListItem
              fontSize=".845rem"
              fontWeight="500"
              _light={{
                color: "customColor.black7",
              }}
            >
              Your bank doesn't provide a bank feed for your account type
            </ListItem>
            <ListItem
              fontSize=".845rem"
              fontWeight="500"
              _light={{
                color: "customColor.black7",
              }}
            >
              You need a petty cash account
            </ListItem>
            <ListItem
              fontSize=".845rem"
              fontWeight="500"
              _light={{
                color: "customColor.black7",
              }}
            >
              Your HRIS 360 subscription doesn't include bank feeds
            </ListItem>
          </UnorderedList>
          <Text
            fontSize=".845rem"
            _light={{
              color: "customColor.black7",
            }}
          >
            Transactions downloaded from your bank can be manually imported into
            this account
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            marginRight={"8px"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
