import {
  Box,
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { Employees } from "../../Api/Employees";
import { TimeSheetNewDetail } from "../../Api/TimeSheetDetail";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
import { AddTimeSheet } from "./Modal/AddTimeSheet";
import { DownloadTimesheetTemplate } from "./Modal/DownloadTimesheetTemplate";
import UploadBulkTimesheet from "./Modal/UploadBulkTimesheet";

export function TimeSheet() {
  const employeeOptions = [{ value: "all", label: "all" }];
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState("");
  const [employeesList, setEmployeesList] = useState<
    Employees.timeSheetEmployees[]
  >([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const toast = useToast();
  useEffect(() => {
    Employees.FetchTimeSheetEmployees(
      (data) => {
        setEmployeesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);

  const form = useForm<TimeSheetNewDetail.AddNewDetail>({
    defaultValues: {
      employeeId: "",
      period: "",
      status: "",
      totalHours: 0,

      payRatesData: [
        {
          payItemsEarningsId: "",
          dayHours: {},
        },
      ],
    },
  });
  const form2 = useForm<TimeSheetNewDetail.UpdateTimesheetToApprove>({
    defaultValues: {
      status: "",

      ids: [],
    },
  });
  const addTimeSheet = useDisclosure();
  const AddShift = useDisclosure();
  const navigate = useNavigate();
  const [allTimesheets, setAllTimesheets] = useState<
    TimeSheetNewDetail.FetchTimeSheetData[]
  >([]);

  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  useEffect(() => {
    const initialCheckboxes = allTimesheets.reduce((acc: any, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [allTimesheets]);

  const handleCheckboxChange = (id: string) => {
    const updatedCheckboxes = {
      ...checkboxes,
      [id]: !checkboxes[id],
    };
    setCheckboxes(updatedCheckboxes);

    // Add or remove ID from selectedIds
    if (updatedCheckboxes[id]) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };
  const handleCheckAll = () => {
    const updatedCheckboxes: { [key: string]: boolean } = {};
    allTimesheets.forEach((item) => {
      if (item.status !== "APPROVED") {
        updatedCheckboxes[item.id] = !checkboxes[item.id];
      }
    });
    setCheckboxes(updatedCheckboxes);

    // Update selectedIds based on updatedCheckboxes
    const selectedIds = Object.keys(updatedCheckboxes).filter(
      (id) => updatedCheckboxes[id]
    );
    setSelectedIds(selectedIds);
  };
  const length = Object.values(checkboxes).filter((value) => value).length;
  const statusFilter: Array<{ value: string; label: string }> = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Approved",
      value: "APPROVED",
    },
    {
      label: "Awaiting Approval",
      value: "AWAITING_APPROVAL",
    },
    {
      label: "Pending Approval",
      value: "PENDING_APPROVAL",
    },
    {
      label: "Processed",
      value: "PROCESSED",
    },
    {
      label: "Overdue",
      value: "OVERDUE",
    },
    {
      label: "Draft",
      value: "DRAFT",
    },
  ];
  const [selectedTimesheet, setSelectedTimesheet] = useState<string>(
    statusFilter[0].value
  );
  const [selectedFilterStartDate, setSelectedFilterStartDate] =
    useState<string>();
  const [selectedFilterEndDate, setSelectedFilterEndDate] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const pageCount = Math.ceil(count / pageLimit);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const downloadTemplate = useDisclosure();
  const uploadBulk = useDisclosure();
  useEffect(() => {
    TimeSheetNewDetail.FetchTimeSheets(
      {
        status: selectedTimesheet,
        startDate: selectedFilterStartDate,
        endDate: selectedFilterEndDate,
        employeeId: selectedEmployee,
        page: pageNumber,
        limit: pageLimit,
      },
      (data) => {
        setAllTimesheets(data?.data);
        setCount(data?.count);
        setIsLoading(false);
        //  setCount(data?.count);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated, pageNumber, pageLimit]);

  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        h="calc(100vh - 154.61px)"
        overflowY="auto"
      >
        <Container maxW={"container.xl"} padding={0}>
          <Box
            _dark={{
              bgColor: "customColor.dark.100",
            }}
            _light={{
              bgColor: "customColor.gray.400",
            }}
            padding={"0"}
          >
            <Heading
              paddingTop={"24px"}
              paddingLeft={"20px"}
              fontWeight={"bold"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"18px"}
              margin={"0px 4px 0px 0px"}
            >
              Timesheets
            </Heading>

            <Stack
              _dark={{
                bgColor: "customColor.dark.100",
              }}
              _light={{
                bgColor: "customColor.gray.400",
              }}
              padding={"20px"}
              borderBottom={"1px solid #dadddf"}
            >
              <Container
                justifyContent="center"
                alignItems="center"
                p="0px"
                maxW={"100%"}
                borderRadius="md"
              >
                <Stack
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"4px"}
                  paddingTop={"10px"}
                  paddingLeft={"10px"}
                  margin={"0px 1px 20px"}
                  border="1px solid #f6f6f6"
                  padding={"7.52px 12px"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  gap={0}
                >
                  <SimpleGrid
                    columns={{
                      xs: 3,
                      md: 4,
                      lg: 5,
                    }}
                    spacing={3}
                  >
                    <Stack flex={3} gap={0}>
                      <Text
                        fontSize={"12px"}
                        margin={"0px 0px 5px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontWeight={"bold"}
                      >
                        Timesheet Filter
                      </Text>
                      <ReactSelect
                        placeholder=""
                        options={statusFilter}
                        value={statusFilter.find(
                          (option) => option.value === selectedTimesheet
                        )}
                        onChange={(newValue: any) => {
                          setSelectedTimesheet(newValue?.value);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    </Stack>
                    <Stack flex={2} gap={0}>
                      <CustomInput
                        withValidation
                        input={{
                          isInvalid: false,
                          w: "100%",
                          fontSize: "12px",
                          value: selectedFilterStartDate,
                          variant: "outline",
                          type: "date",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                          height: "38px",
                          borderRadius: "4px",
                          onChange: (e: any) => {
                            setSelectedFilterStartDate(e.target.value);
                          },
                        }}
                        placeholder="dd/mm/yyyy"
                        label="Start Date"
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "700",
                          margin: "0px 0px 5px",
                          color: "#444",
                        }}
                      />
                    </Stack>
                    <Stack flex={2} gap={0}>
                      <CustomInput
                        withValidation
                        input={{
                          isInvalid: false,
                          w: "100%",
                          fontSize: "12px",
                          variant: "outline",
                          value: selectedFilterEndDate,
                          type: "date",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",

                          height: "38px",
                          borderRadius: "4px",
                          onChange: (e: any) => {
                            setSelectedFilterEndDate(e.target.value);
                          },
                        }}
                        placeholder="dd/mm/yyyy"
                        label="End Date"
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "700",
                          margin: "0px 0px 5px",
                          color: "#444",
                        }}
                      />
                    </Stack>
                    <Stack flex={3} gap={0}>
                      <Text
                        fontSize={"12px"}
                        margin={"0px 0px 5px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontWeight={"bold"}
                      >
                        Employee Group
                      </Text>
                      <ReactSelect
                        placeholder=""
                        options={employeeOptions}
                        value={selectedEmployeeGroup}
                        onChange={(newValue: any) => {
                          setSelectedEmployeeGroup(newValue);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    </Stack>
                    <Stack flex={3} gap={0}>
                      <Text
                        fontSize={"12px"}
                        margin={"0px 0px 5px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontWeight={"bold"}
                      >
                        Employee/s
                      </Text>

                      <ReactSelect
                        placeholder=""
                        options={employeesList.map((employee: any) => ({
                          value: employee.id,
                          label: `${employee.firstName} ${employee.lastName}`,
                        }))}
                        value={
                          selectedEmployee
                            ? {
                                value: selectedEmployee,
                                label: `${
                                  employeesList.find(
                                    (employee) =>
                                      employee.id === selectedEmployee
                                  )?.firstName
                                } ${
                                  employeesList.find(
                                    (employee) =>
                                      employee.id === selectedEmployee
                                  )?.lastName
                                }`,
                              }
                            : ""
                        }
                        onChange={(newValue: any) => {
                          setSelectedEmployee(newValue?.value);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    </Stack>
                  </SimpleGrid>

                  <Stack alignItems={"flex-end"} padding={"10px"}>
                    <Stack direction={"row"}>
                      <Button
                        margin={"10px 10px 15px 0px"}
                        backgroundColor={"transparent"}
                        borderRadius={"3px"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        variant={"solid"}
                        textColor={"customColor.black7"}
                        padding={"12px 16px"}
                        marginRight={"5px"}
                        fontWeight={600}
                        _hover={{
                          backgroundColor: "#fff",
                        }}
                        onClick={() => {
                          setSelectedTimesheet("");
                          setSelectedFilterStartDate("");
                          setSelectedFilterEndDate("");
                          setSelectedEmployee("");
                        }}
                      >
                        Reset Filter
                      </Button>
                      <Button
                        margin={"10px 10px 15px 0px"}
                        fontSize={"13px"}
                        bgColor="#3454D1"
                        borderRadius={"3px"}
                        borderWidth={"1px"}
                        borderColor={"#3454d1!important"}
                        variant={"solid"}
                        padding={"12px 16px"}
                        fontWeight={700}
                        _hover={{
                          bgColor: "#3454D1",
                        }}
                        onClick={() => {
                          setLastUpdated(Date.now());
                        }}
                      >
                        Apply Filter
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Container>
            </Stack>
          </Box>

          <Container maxW={"100%"} padding={"0px 20px"}>
            <Stack paddingTop={"20px"} paddingBottom={"20px"} gap={0}>
              <Stack align={"flex-start"}>
                <HStack>
                  <Button
                    margin={"0px 10px 15px 0px"}
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    onClick={addTimeSheet.onOpen}
                  >
                    + Add Timesheet
                  </Button>
                  <Button
                    margin={"0px 10px 15px 0px"}
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    onClick={downloadTemplate.onOpen}
                  >
                    Download Template
                  </Button>
                  <Button
                    margin={"0px 10px 15px 0px"}
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    onClick={uploadBulk.onOpen}
                  >
                    Upload Bulk Timesheet
                  </Button>

                  <AddTimeSheet
                    employeesList={employeesList}
                    isOpen={addTimeSheet.isOpen}
                    onClose={addTimeSheet.onClose}
                  />
                  <DownloadTimesheetTemplate
                    employeesList={employeesList}
                    isOpen={downloadTemplate.isOpen}
                    onClose={downloadTemplate.onClose}
                  />
                  <UploadBulkTimesheet
                    isOpen={uploadBulk.isOpen}
                    onClose={uploadBulk.onClose}
                    setLastUpdated={setLastUpdated}
                  />
                </HStack>
              </Stack>
              <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
                <Stack
                  _light={{
                    color: "customColor.black7",
                    bgColor: "customColor.white",
                  }}
                  fontSize={"16px"}
                  _dark={{
                    bgColor: "customColor.dark.50",
                    color: "customColor.dark.250",
                  }}
                  gap={0}
                  border="1px solid #f6f6f6"
                  borderRadius="4px"
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                >
                  {" "}
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    margin={"5px 0px 0px 5px"}
                    padding={"5px 20px 5px 20px"}
                  >
                    <Box w={"100%"}>
                      <PaginationControl
                        limit={pageLimit}
                        setLimit={setPageLimit}
                        setPageNumber={setPageNumber}
                      />
                    </Box>
                    <Stack
                      direction={"row"}
                      fontSize={"12px"}
                      color={"#888"}
                      align={"center"}
                      gap={0}
                    >
                      <Button
                        color="#999"
                        backgroundColor="#fff"
                        borderRadius="3px"
                        marginRight="3px"
                        border="1px solid #dcdee4"
                        fontSize="13px"
                        height="25px"
                        variant="solid"
                        textColor="customColor.black7"
                        padding="12px 16px"
                        fontWeight={600}
                        _hover={{
                          backgroundColor: "#fff",
                          color: "#3454D1",
                        }}
                        onClick={(e) => {
                          form2.handleSubmit(
                            (data) => {
                              data.ids = selectedIds;
                              data.status = "APPROVED";
                              TimeSheetNewDetail.UpdateTimesheetToApproved(
                                data,
                                () => {
                                  toast({
                                    title: "Timesheet Updated",
                                    status: "success",
                                  });
                                  setLastUpdated(Date.now());
                                },
                                (error) => {
                                  toast({
                                    title: "Error",
                                    description: error,
                                    status: "error",
                                  });
                                }
                              );
                            },
                            (error) => {
                              toast({
                                title: "Error",
                                status: "error",
                              });
                            }
                          )(e);
                        }}
                      >
                        Approve
                      </Button>

                      <Text
                        _light={{
                          color: "customColor.black7",
                        }}
                        whiteSpace={"nowrap"}
                      >
                        {length} timesheets selected
                      </Text>
                    </Stack>
                  </Stack>
                  <Box overflowX="auto" p="0px">
                    <Table variant="simple" w="100%" overflowX="auto">
                      <Thead h={"36.8px"}>
                        <Tr
                          borderTopWidth={"1px"}
                          borderTopColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                        >
                          <Th
                            padding="8px 26px 8px 30px"
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={Object.values(checkboxes).every(
                                (value) => value
                              )}
                              onChange={handleCheckAll}
                            />
                          </Th>
                          {[
                            {
                              name: "Unique Id",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "First Name",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "Surname",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "Period",
                              value: "30%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "Status",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "last edited",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "Total Days",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "Unit",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                            {
                              name: "View Details",
                              value: "10%",
                              paddingValue: "8px 26px 8px 15px",
                            },
                          ].map((item, i) => {
                            return (
                              <Th
                                key={i}
                                padding={item.paddingValue}
                                borderBottomColor="borders.tableBorder"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                fontWeight={700}
                                whiteSpace="nowrap"
                                fontSize="12px"
                                textTransform="none"
                                width={item.value}
                              >
                                {item.name}
                              </Th>
                            );
                          })}
                        </Tr>
                      </Thead>

                      <Tbody>
                        {allTimesheets.map((item: any, i: any) => {
                          let startDateMoment = moment(
                            item?.dateRange?.startDate,
                            "YYYY-MM-DD"
                          );
                          let endDateMoment = moment(
                            item?.dateRange?.endDate,
                            "YYYY-MM-DD"
                          );
                          let numberOfDays = endDateMoment.diff(
                            startDateMoment,
                            "days"
                          );
                          let days = numberOfDays + 1;

                          return (
                            <Tr
                              key={i}
                              _hover={{
                                bgColor: "#f2f3f8",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (item.status === "DRAFT") {
                                  navigate(
                                    "/app/payroll/TimesheetsEnterDetail",
                                    {
                                      state: {
                                        timesheetId: item.id,
                                        employeeId: item.employeeId,
                                        period: item.period,
                                        status: item.status,
                                        dateRange: item.dateRange,
                                      },
                                    }
                                  );
                                } else {
                                  navigate(
                                    `/app/payroll/TimesheetsDetail/${item.id}`
                                  );
                                }
                              }}
                            >
                              <Td
                                padding="15px 15px 15px 30px"
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                whiteSpace="nowrap"
                                lineHeight={1.5}
                                fontWeight={500}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Checkbox
                                  size="md"
                                  borderColor="#adb5bd"
                                  bgColor="white"
                                  isChecked={checkboxes[item.id]}
                                  isDisabled={
                                    item.status === "APPROVED" ? true : false
                                  }
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{item?.employee?.uniqueId}</Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{item?.employee?.firstName}</Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{item?.employee?.lastName}</Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{item?.period}</Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Tag
                                  padding="5px 6px"
                                  fontWeight={500}
                                  fontSize="13.44px"
                                  lineHeight={1.5}
                                  borderRadius={"3px"}
                                  color={
                                    item?.status === "APPROVED"
                                      ? "#3454D1"
                                      : item?.status === "DRAFT"
                                      ? "#EA4D4D"
                                      : "#000000"
                                  }
                                  backgroundColor={
                                    item?.status === "APPROVED"
                                      ? "#EBEEFA"
                                      : item?.status === "DRAFT"
                                      ? "#FDEDED"
                                      : "#F0F0F0"
                                  }
                                  _dark={{
                                    color:
                                      item?.status === "APPROVED"
                                        ? "#3454D1"
                                        : item?.status === "DRAFT"
                                        ? "customColor.dark.150"
                                        : "#FFFFFF",
                                    backgroundColor:
                                      item?.status === "APPROVED"
                                        ? "#EBEEFA"
                                        : item?.status === "DRAFT"
                                        ? "customColor.dark.bg"
                                        : "#333333",
                                  }}
                                >
                                  {item?.status === "APPROVED"
                                    ? "Approved"
                                    : item?.status === "DRAFT"
                                    ? "Draft"
                                    : "Others"}
                                </Tag>

                                {/* <Text>{item?.status}</Text> */}
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{item?.updatedAt}</Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                textAlign={"center"}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text marginRight={"25px"}>{days}</Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{item?.totalHours}</Text>
                              </Td>
                              <Td
                                padding="10px 21px 10px 10px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <HStack
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Icon
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="50%"
                                    borderStyle={"solid"}
                                    borderWidth={"1px"}
                                    borderColor={"borders.tableBorder"}
                                    bg={"#fff"}
                                    w="25px"
                                    h="25px"
                                    px="5px"
                                    py="5px"
                                    as={MdOutlineRemoveRedEye}
                                    fontSize="22px"
                                    color="#6B7280"
                                    _hover={{
                                      color: "#3454d1",
                                    }}
                                    onClick={() => {
                                      if (item.status === "DRAFT") {
                                        navigate(
                                          "/app/payroll/TimesheetsEnterDetail",
                                          {
                                            state: {
                                              timesheetId: item.id,
                                              employeeId: item.employeeId,
                                              period: item.period,
                                              status: item.status,
                                              dateRange: item.dateRange,
                                            },
                                          }
                                        );
                                      } else {
                                        navigate(
                                          `/app/payroll/TimesheetsDetail/${item.id}`
                                        );
                                      }
                                    }}
                                  />
                                </HStack>
                                {/* <Button
                                  h="28.89"
                                  colorScheme="customColor.Blueberry"
                                  fontSize="12.6px"
                                  fontWeight={400}
                                  lineHeight="1.5"
                                  textAlign="center"
                                  borderWidth="1px"
                                  borderRadius="30px"
                                  px={{ sm: "0.5px", md: "8px" }}
                                  py={{ sm: "4px", md: "4px" }}
                                  onClick={() => {
                                    if (item.status === "DRAFT") {
                                      navigate(
                                        "/app/payroll/TimesheetsEnterDetail",
                                        {
                                          state: {
                                            timesheetId: item.id,
                                            employeeId: item.employeeId,
                                            period: item.period,
                                            status: item.status,
                                            dateRange: item.dateRange,
                                          },
                                        }
                                      );
                                    } else {
                                      navigate(
                                        `/app/payroll/TimesheetsDetail/${item.id}`
                                      );
                                    }
                                  }}
                                >
                                  View Details
                                </Button> */}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Box>
                  <Stack borderTop={"1px solid #dcdee4 "}>
                    <HStack
                      marginTop={"9.35px"}
                      padding={"25px"}
                      fontSize={"13px"}
                      _light={{
                        color: "customColor.black2",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      justifyContent={"space-between"}
                    >
                      <Text
                        fontWeight={"normal"}
                        color={"customColor.6b7885"}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        fontSize={"11px"}
                      >
                        Showing {pageLimit * (pageNumber - 1) + 1} to{" "}
                        {pageLimit + pageLimit * (pageNumber - 1)} of{" "}
                        {allTimesheets?.length} entries
                      </Text>
                      <HStack
                        spacing="13px"
                        justify={{ xs: "center", md: "end" }}
                      >
                        <IconButton
                          aria-label="back"
                          border={"1px solid #e8eaf2"}
                          borderRadius={"full"}
                          backgroundColor="transparent"
                          _hover={{
                            backgroundColor: "#3454D1",
                            color: "white",
                          }}
                          onMouseEnter={() => setIsHovered2(true)}
                          onMouseLeave={() => setIsHovered2(false)}
                          icon={
                            <FaArrowLeft
                              size={15}
                              color={
                                isHovered2
                                  ? "#FFFFFF"
                                  : pageNumber <= 1 || pageNumber > pageCount
                                  ? "#828ea0"
                                  : "#283c50"
                              }
                            />
                          }
                          isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                          onClick={() => {
                            if (pageNumber > 1 && pageNumber <= pageCount) {
                              pageNumberInputRef.current?.value &&
                                (pageNumberInputRef.current.value = String(
                                  pageNumber - 1
                                ));
                              setPageNumber(pageNumber - 1);
                            }
                          }}
                        />
                        <HStack spacing="13px">{renderPageNumbers()}</HStack>
                        <IconButton
                          aria-label="right"
                          backgroundColor="transparent"
                          border={"1px solid #e8eaf2"}
                          borderRadius={"full"}
                          _hover={{
                            backgroundColor: "#3454D1",
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          icon={
                            <FaArrowRight
                              size={15}
                              color={
                                isHovered
                                  ? "#FFFFFF"
                                  : pageNumber >= pageCount || pageNumber < 1
                                  ? "#828ea0"
                                  : "#283c50"
                              }
                              style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                            />
                          }
                          isDisabled={pageNumber >= pageCount || pageNumber < 1}
                          onClick={() => {
                            if (pageNumber < pageCount && pageNumber >= 1) {
                              pageNumberInputRef.current?.value &&
                                (pageNumberInputRef.current.value = String(
                                  pageNumber + 1
                                ));
                              setPageNumber(pageNumber + 1);
                            }
                          }}
                        />
                      </HStack>
                    </HStack>
                  </Stack>
                </Stack>
              </Skeleton>
            </Stack>
          </Container>
        </Container>
      </Stack>
    </FormProvider>
  );
}
