import {
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

interface AdvancedTaxRateProps {
  isOpen: boolean;
  onClose: () => void;
}
export function AdvancedTaxRate({ isOpen, onClose }: AdvancedTaxRateProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={"550px"}>
        <ModalHeader
          padding={"12px 15px"}
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Advanced tax rates
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"15px"} borderTop={"1px solid #dfe8f6"}>
          <Text
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            margin={"0px 0px 10px"}
          >
            You can change to advanced tax rates if your bookkeeping requires
            it. If you're unsure about how this will affect your business,
            please contact your HRIS-360 certified advisor before changing tax
            rates. You can find an advisor on the{" "}
            <Link fontSize={"0.8125rem"}> advisor directory</Link>
          </Text>
          <Text
            _dark={{ color: "customColor.white" }}
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            margin={"0px 0px 10px"}
          >
            If you change, you'll need to manually update these places in
            HRIS-360 to use the advanced rates:
          </Text>
          <UnorderedList
            marginTop={"15px"}
            marginBottom={"15px"}
            paddingLeft={"30px"}
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            <ListItem>Chart of accounts</ListItem>
            <ListItem>Inventory</ListItem>
            <ListItem>
              Unapproved invoices, quotes, purchase orders, bills, manual
              journals and expense claims
            </ListItem>
            <ListItem>Contact defaults</ListItem>
            <ListItem>Repeat transactions</ListItem>
            <ListItem>Bank rules</ListItem>
          </UnorderedList>
          <Text
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            margin={"0px 0px 10px"}
          >
            The tax rates for previously coded transactions will not change.
          </Text>
          <Text
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            margin={"0px 0px 10px"}
          >
            For more information, see our <Link>Help center</Link>
          </Text>
          <Stack direction={"row"} justify={"end"} gap={0}>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              marginRight={"8px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Change to advanced tax rates
            </Button>
            <Button
              onClick={onClose}
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
