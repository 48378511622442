import { Divider, HStack, Stack, Text } from "@chakra-ui/react";

export function OverviewTable() {
  return (
    <Stack padding={"24px 24px 0"} bg={"#fff"}>
      <HStack gap={0}>
        <Stack padding={"12px 8px 12px 0px"} flex={2}>
          <Text
            fontSize={"17px"}
            fontWeight={"700"}
            _light={{
              color: "customColor.black2",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Submitted Time
          </Text>
        </Stack>
        <Stack padding={0} flex={7} direction={"row"} gap={0}>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Sat 1
            </Text>
          </Stack>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Sun 2
            </Text>
          </Stack>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Mon 3
            </Text>
          </Stack>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Tue 4
            </Text>
          </Stack>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Web 5
            </Text>
          </Stack>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Thr 6
            </Text>
          </Stack>
          <Stack flex={1} padding={"12px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Fri 7
            </Text>
          </Stack>
        </Stack>

        <Text
          flex={1}
          fontSize={"17px"}
          fontWeight={"700"}
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Total
        </Text>
      </HStack>
      <HStack gap={0}>
        <Stack padding={"16px 8px 16px 0px"} flex={2}>
          <Text
            fontSize={"15px"}
            fontWeight={"400"}
            _light={{
              color: "customColor.black2",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Work & break hours
          </Text>
        </Stack>
        <Stack padding={0} flex={7} direction={"row"} gap={0}>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Work
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              8:00 am - 3:00 pm
            </Text>
          </Stack>
        </Stack>

        <Text
          flex={1}
          fontSize={"17px"}
          fontWeight={"700"}
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        ></Text>
      </HStack>
      <Divider bg={"#A6A9B0"} margin={"12px 0px"} height={"1px"} />
      <HStack gap={0}>
        <Stack padding={"16px 8px 16px 0px"} flex={2}>
          <Text
            fontSize={"15px"}
            fontWeight={"400"}
            _light={{
              color: "customColor.black2",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Total hours
          </Text>
        </Stack>
        <Stack padding={0} flex={7} direction={"row"} gap={0}>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              7h
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              0h
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              0h
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              0h
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              0h
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              0h
            </Text>
          </Stack>
          <Stack flex={1} padding={"16px 8px"}>
            <Text
              fontSize={"17px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              0h
            </Text>
          </Stack>
        </Stack>

        <Text
          flex={1}
          fontSize={"17px"}
          fontWeight={"700"}
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          7h
        </Text>
      </HStack>
    </Stack>
  );
}
