import {
  Box,
  Progress,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useDashboardContext } from "../../Hooks/DashboardContext";

type AttributeType = "gender" | "department" | "province" | "employeementType";
type CalculationType = "sum" | "average" | "median" | "min" | "max";

interface DynamicProgressBarProps {
  attribute: AttributeType;
  calculation?: CalculationType;
  colors?: string[]; // Array of colors or gradients
  fontStyle?: "normal" | "italic" | "oblique";
  fontWeight?: "normal" | "bold";
  fontSize?: number;
  showLabel?: boolean; // Whether to show label inside progress bar
}

// Utility function to calculate values based on calculation type
const calculateValues = (
  values: number[],
  calculation: CalculationType
): number => {
  switch (calculation) {
    case "min":
      return Math.min(...values);
    case "max":
      return Math.max(...values);
    case "average":
      return values.reduce((a, b) => a + b, 0);
    case "median":
      values.sort((a, b) => a - b);
      const mid = Math.floor(values.length / 2);
      return values.length % 2 !== 0
        ? values[mid]
        : (values[mid - 1] + values[mid]) / 2;
    case "sum":
    default:
      return values.reduce((a, b) => a + b, 0);
  }
};

// Main component
export default function DynamicProgressChart({
  attribute,
  calculation = "sum",
  colors = ["#54a0ff", "#1dd1a1"], // Default colors array
  fontStyle = "normal",
  fontWeight = "normal",
  fontSize = 14,
  showLabel = true,
}: DynamicProgressBarProps) {
  const { dashboardAnalytics } = useDashboardContext();

  let labels: string[] = [];
  let dataValues: number[] = [];

  // Prepare data based on the attribute prop
  if (attribute === "gender") {
    labels = ["Male", "Female"];
    dataValues = [
      dashboardAnalytics?.maleGenderEmpCount || 0,
      dashboardAnalytics?.femaleGenderEmpCount || 0,
    ];
  } else if (attribute === "department") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInDepartments || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInDepartments || {}
    );
  } else if (attribute === "province") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInProvinces || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInProvinces || {}
    );
  } else if (attribute === "employeementType") {
    labels = ["Full Time", "Part Time", "Daily Wagers"];
    dataValues = [
      dashboardAnalytics?.fullTimeEmployees || 0,
      dashboardAnalytics?.partTimeEmployees || 0,
      dashboardAnalytics?.casualEmployees || 0,
    ];
  }

  // Calculate the total value for percentage calculation based on the selected calculation type
  const totalValue = calculateValues(dataValues, calculation);

  // Use the hook at the top level of the component
  const textColor = useColorModeValue("gray.800", "gray.200");
  const bgColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      minW={"100%"}
      p="20px"
      overflowY="auto"
    >
      <Text
        fontSize={fontSize + 2}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        mb={4}
        color={textColor}
      >
        {`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Progress`}
      </Text>
      <VStack width="100%" spacing={4}>
        {labels.map((label, index) => {
          const value = dataValues[index];
          const percentageValue =
            totalValue > 0 ? (value / totalValue) * 100 : 0;

          // Determine the color or gradient for the current progress bar
          const currentColor = colors[index] || colors[0]; // Use the specific color or fallback to the first color
          const isGradient = /gradient/i.test(currentColor); // Enhanced gradient check

          return (
            <Box width="100%" key={label}>
              <Text
                fontSize={fontSize}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                mb={1}
                color={textColor}
                textAlign="left"
              >
                {label} ({value}/{totalValue})
              </Text>
              <Progress
                value={percentageValue}
                size="lg"
                borderRadius="md"
                bg={bgColor}
                colorScheme="blue"
                sx={{
                  "& > div": {
                    bgGradient: isGradient ? currentColor : undefined, // Apply gradient if true
                    backgroundColor: !isGradient ? currentColor : undefined, // Apply solid color if not gradient
                  },
                }}
              />
              {showLabel && (
                <Text
                  mt={1}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  fontStyle={fontStyle}
                  color={textColor}
                  textAlign="right"
                >
                  {percentageValue.toFixed(2)}%
                </Text>
              )}
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
}
