import { Box, HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { Fragment, useEffect } from "react";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemRetirement } from "../../../Api/PayItemRetirement";
import { PayTemp } from "../../../Api/PayTemp";

interface RetirementProps {
  form: any;
  retirementFieldArray: any;
  retirementLine: any;
  accountRetirement: PayItemRetirement.FetchRetirementFund[];
  setAccountRetirement: (accountRetirement: any) => void;
  setResetData: (resetData: any) => void;
  resetPaySlip: Function;
}
export function Retirement({
  form,
  retirementFieldArray,
  accountRetirement,
  retirementLine,
  setAccountRetirement,
  setResetData,
  resetPaySlip,
}: RetirementProps) {
  const toast = useToast();
  useEffect(() => {
    PayItemRetirement.FetchRetirementFund(
      (res) => {
        setAccountRetirement(res);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Box gap={0}>
      {retirementFieldArray?.fields.map(
        (item: PayTemp.RetirementRate & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                fontSize={"12px "}
                margin={"0px 0px 4px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"700"}
              >
                Statutory Benefit{" "}
              </Text>
              <Text
                color={"#3454d1"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  retirementLine.onOpen();
                  setResetData({ ...item, index: i });
                }}
              >
                {accountRetirement.find(
                  (accountRetirement) =>
                    accountRetirement.id === item.payItemRetirementId
                )?.name ?? ""}
                {/* {form.getValues(`reimbursments.${i}.description`) && ": "}
                {form.getValues(`reimbursments.${i}.description`)} */}
              </Text>
            </Stack>

            <Stack alignItems={"flex-end"}>
              <HStack>
                {[
                  PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_EOBI,
                  PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY,
                  PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_PROVIDENT_FUND,
                ].includes(
                  form.getValues(`retirements.${i}.calculationType`)
                ) && (
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                      onBlur(event) {
                        resetPaySlip();
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <Fragment>
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(Number(e.target.valueAsNumber));
                            },
                            isDisabled: [
                              PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_EOBI,
                              PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY,
                              PayTemp.PayTemplateCalcTypeEnum
                                .EMPLOYER_PROVIDENT_FUND,
                            ].includes(
                              form.getValues(`retirements.${i}.calculationType`)
                            ),
                            maxW: "91.33px",
                            height: "26.33px",
                            padding: "12px 15px",

                            placeholder: "",
                          }}
                          label="Percentage "
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "customColor.black7",
                            margin: "0px 0px 4px",
                          }}
                        />
                      </Fragment>
                    )}
                  />
                )}
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Amount is required",
                    },
                    onBlur(event) {
                      resetPaySlip();
                    },
                  }}
                  control={form.control}
                  name={`retirements.${i}.amount`}
                  render={({ field, fieldState: { error } }) => (
                    <Fragment>
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",

                          placeholder: "",
                          isDisabled: [
                            PayTemp.PayTemplateCalcTypeEnum.EMPLOYER_EOBI,
                            PayTemp.PayTemplateCalcTypeEnum.SOCIAL_SECURITY,
                            PayTemp.PayTemplateCalcTypeEnum
                              .EMPLOYER_PROVIDENT_FUND,
                          ].includes(
                            form.getValues(`retirements.${i}.calculationType`)
                          ),
                        }}
                        label="Amount "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "customColor.black7",
                          margin: "0px 0px 4px",
                        }}
                      />
                    </Fragment>
                  )}
                />
              </HStack>
            </Stack>

            <Stack>
              <Icon
                fontSize={"20px"}
                as={IoIosCloseCircleOutline}
                marginTop={"15px"}
                color={"grey"}
                _hover={{
                  color: "red.500",
                }}
                onClick={() => {
                  retirementFieldArray.remove(i);
                  resetPaySlip();
                }}
              />
            </Stack>
          </Stack>
        )
      )}
    </Box>
  );
}
