import {
  AbsoluteCenter,
  Box,
  Center,
  Image,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BusinessInvitation } from "../../../api/BusinessInvitation";
import HRISLOGO from "./../../../assets/hris-logo.png";
export default function WelcomePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get("inviteToken");
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    if (inviteToken) {
      BusinessInvitation.AcceptInvitation(
        inviteToken!,
        (res) => {
          if (res.action === "REDIRECT_TO_SIGNUP") {
            navigate(
              `/signup/?returnUrl=${window.location.pathname}${window.location.search}`
            );
          } else {
            navigate("/");
          }
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
        }
      );
    }
  }, [inviteToken]);

  return (
    <Box bgColor="white" height="100vh">
      <AbsoluteCenter>
        <Image src={HRISLOGO} />
        <Box>
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
