import {
  Checkbox,
  HStack,
  Icon,
  Link,
  Radio,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";

export function Standard() {
  return (
    <Stack>
      <Stack
        bg={"rgb(242, 243, 244)"}
        margin={"16px 0px 20px"}
        padding={"4px"}
        direction={"row"}
        gap={0}
      >
        <Stack padding={"12px 0px 0px 8px"}>
          {" "}
          <Icon as={AiFillInfoCircle} />
        </Stack>
        <Stack padding={"8px 12px"}>
          <Text
            lineHeight={"1.5rem"}
            fontSize={"13px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            margin={"-5px 0px 0px 12px"}
          >
            This role suits business owners and admin staff that manage the
            day-to-day business and accounting.
            <Link
              textDecoration={"underline"}
              color={"#000A1EBF"}
              _hover={{
                color: "#0078C8",
              }}
            >
              Learn more about user roles
              <Icon as={BsBoxArrowUpRight} />
            </Link>
          </Text>
        </Stack>
      </Stack>
      <Stack
        padding={"12px 0px"}
        borderTop={"1px solid  #ccced2"}
        borderBottom={"1px solid #ccced2"}
        direction={"row"}
      >
        <Text
          fontSize={"13px"}
          padding={"8px 0px"}
          color={"customColor.black2"}
          _dark={{
            color: "customColor.dark.200",
          }}
          fontWeight={"bold"}
          width={"50%"}
        >
          Sales and purchase
        </Text>
        <Icon as={FaCheck} alignSelf={"center"} />
      </Stack>
      <Stack
        padding={"12px 0px"}
        borderBottom={"1px solid #ccced2"}
        direction={"row"}
      >
        <Stack width={"50%"}>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
          >
            Bank accounts and balance
          </Text>
          <Text
            marginTop={{ xs: "110px", lg: "35px" }}
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
          >
            Bank account admin
          </Text>
        </Stack>
        <Stack boxSize={"50%"}>
          <HStack>
            <Radio
              size="lg"
              name="1"
              colorScheme="blue"
              defaultChecked
              alignSelf={"flex-start"}
              marginTop={"4px"}
            ></Radio>
            <Text fontSize={"15px"}>
              Reconcile and edit statement lines (non-cash coding)
            </Text>
          </HStack>

          <HStack>
            <Radio
              size="lg"
              name="1"
              colorScheme="blue"
              alignSelf={"flex-start"}
            />
            <Text fontSize={"15px"}>
              Bulk reconciliation and statement line editing (cash coding)
            </Text>
          </HStack>

          <HStack>
            <Checkbox size={"lg"} alignSelf={"flex-start"} marginTop={"4px"} />
            <Text fontSize={"0.9375rem"} lineHeight={"1.5rem"}>
              Can add edit bank account details held for customers and
              suppliers.
            </Text>
          </HStack>
        </Stack>
      </Stack>

      <Stack padding={"12px 0px"} borderBottom={"1px solid #ccced2"}>
        <Stack direction={"row"}>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
            w={"50%"}
          >
            Report
          </Text>
          <HStack boxSize={"50%"}>
            <Checkbox
              size={"lg"}
              alignSelf={"flex-start"}
              marginTop={"4px"}
              defaultChecked
            />
            <Text fontSize={"0.9375rem"} lineHeight={"1.5rem"}>
              View and return reports.
            </Text>
          </HStack>
        </Stack>
        <Stack direction={"row"}>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
            w={"50%"}
          >
            Publish reports
          </Text>
          <Checkbox
            size={"lg"}
            alignSelf={"flex-start"}
            isDisabled
            marginTop={"4px"}
            boxSize={"50%"}
          />
        </Stack>
        <Stack direction={"row"}>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
            w={"50%"}
          >
            Set lock dates
          </Text>
          <Checkbox
            size={"lg"}
            alignSelf={"flex-start"}
            isDisabled
            marginTop={"4px"}
            boxSize={"50%"}
          />
        </Stack>
        <Stack direction={"row"}>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
            w={"50%"}
          >
            Submit BAS
          </Text>
          <HStack boxSize={"50%"}>
            <Checkbox
              size={"lg"}
              alignSelf={"flex-start"}
              marginTop={"4px"}
              defaultChecked
            />
            <Text fontSize={"0.9375rem"} lineHeight={"1.5rem"}>
              Can send tax obligations to the government directly from HRIS-360.
            </Text>
          </HStack>
        </Stack>
      </Stack>

      <Stack padding={"12px 0px"} direction={"row"}>
        <Stack width={"50%"}>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
          >
            Edit settings
          </Text>
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            fontWeight={"bold"}
          >
            Manage users
          </Text>
        </Stack>
        <Stack boxSize={"50%"}>
          <Icon as={FaCheck} marginTop={"5px"} />
          <Icon as={FaCheck} marginTop={"25px"} />
        </Stack>
      </Stack>
    </Stack>
  );
}
