import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  MdImportExport,
  MdOutlineArrowDownward,
  MdOutlineArrowUpward,
} from "react-icons/md";

export function SortingFilter() {
  const [selectedSort, setSelectedSort] = useState("Name");
  const [isReversed, setIsReversed] = useState(false);
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={
          isReversed ? <MdOutlineArrowUpward /> : <MdOutlineArrowDownward />
        }
        alignItems={"center"}
        verticalAlign={"top"}
        lineHeight={"1rem"}
        minH={"40px"}
        minW={"40px"}
        padding={"11px 16px"}
        bg={"transparent"}
        borderRadius={"3px"}
        color={"rgba(0,10,30,.65)"}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
        fontSize={"15px"}
        _hover={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
        _active={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
      >
        {selectedSort}
      </MenuButton>
      <MenuList>
        {["Name", "Email", "Date added", "you owe", "They owe"].map(
          (sortOption) => (
            <MenuItem
              key={sortOption}
              fontSize={"15px"}
              fontWeight={"600"}
              color={"customColor.black7"}
              onClick={() => setSelectedSort(sortOption)}
            >
              {sortOption}
            </MenuItem>
          )
        )}
        <Stack borderTop={"1px solid #e5e7eb"}>
          {" "}
          <MenuItem
            fontSize={"15px"}
            marginTop={"5px"}
            fontWeight={"600"}
            color={"customColor.black7"}
            icon={<MdImportExport fontSize={"17px"} />}
            onClick={() => {
              setIsReversed(!isReversed);
            }}
          >
            Reverse
          </MenuItem>
        </Stack>
      </MenuList>
    </Menu>
  );
}
