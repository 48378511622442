import { Box, Button, Flex, HStack, Icon, Image, Text } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import ImageSvg from "../../../assets/setup-analytics-amico.svg";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export default function UpgradeAccount() {
  const { editDashboard } = useDashboardContext();
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   boxShadow={"0 4px 8px rgba(15,34,58,.65)"}
    //   bgColor="#5b73e8"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   // display={
    //   //   editDashboard ||
    //   //   form.getValues("hideOrShowTiles.UpgradeAccountData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    //   overflowY="auto"
    // >
    <Box
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      w="100%"
      px="25px"
      py="10px"
      borderRadius="4px"
      word-wrap="break-word"
      bgColor="#5b73e8"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
    >
      <HStack>
        <Box w="66.66%">
          <Flex align="center" mb="16px">
            <Text as="span" color="customColor.white">
              Enhance your{" "}
              <Box as="span" fontSize="18px" fontWeight="600">
                Campaign
              </Box>{" "}
              for better outreach
              <Icon ml="4px">
                <FaArrowRight size="19px" color="customColor.white" />
              </Icon>
            </Text>
          </Flex>
          <Button
            h={"38.64px"}
            py="7.520px"
            borderWidth="1px"
            borderColor="#34c38f"
            bgColor="#34c38f"
            colorScheme="green"
            _hover={{
              bgColor: "#2ca67a",
              borderColor: "#2ca67a",
            }}
            fontSize="14.4px"
            fontWeight={400}
            borderRadius="4px"
            lineHeight="1.5"
          >
            Upgrade Account!
          </Button>
        </Box>
        <Box w="33.33%">
          <Image src={ImageSvg}></Image>
        </Box>
      </HStack>
    </Box>
    // </Card>
  );
}
