import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Authentication } from "../../../Api/Authentication";

export function AddAccount() {
  const [optionalData, setOptionalData] = useState(false);

  const accountType = ["EveryDay", "Loan", "Term deposit", "Other"].map(
    (options) => ({ label: options, value: options })
  );
  const currencyType = ["AUD - Australian Dollar"].map((options) => ({
    label: options,
    value: options,
  }));

  const { control, handleSubmit } = useForm<Authentication.AddAccount>({
    defaultValues: {
      name: "",
      type: "",
      currency: "",
      accountCode: "",
      userId: "",
    },
  });

  const navigate = useNavigate();
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "#f2f3f4",
      }}
    >
      <Stack
        h="60px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderBottom={"1px solid #d6dade"}
      >
        <Heading
          fontWeight={"bold"}
          _dark={{
            color: "customColor.dark.150",
          }}
          alignItems={"center"}
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"0.9375rem"}
          paddingTop={"16px"}
          paddingBottom={"16px"}
          marginLeft={"16px"}
          marginRight={"4px"}
          h="60px"
        >
          Add Account
        </Heading>
      </Stack>
      <Stack padding={"17px"}>
        <Container
          justifyContent="center"
          alignItems="center"
          p="0px"
          maxW="780px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          border="1px solid #d6dade"
          borderRadius="md"
        >
          <Heading
            textAlign="left"
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"0.9375rem"}
            fontWeight="bold"
            padding="20px"
            as="h2"
            size="md"
          >
            Account details for ANZ (AU)
          </Heading>
          <Divider orientation="horizontal" />
          <Container
            maxW="400px"
            marginTop="15px"
            paddingTop="20px"
            pb="8px"
            marginX="auto"
            color={"rgb(0,10,30,.75)"}
            px="0px"
          >
            <Box
              fontSize={"0.8125rem"}
              fontWeight={"bold"}
              lineHeight={"1.3077"}
            >
              <Stack spacing="35px" marginTop={"20px"}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Account Name is required",
                    },
                  }}
                  control={control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                      }}
                      placeholder="e.g Business Account"
                      label="Account name"
                      labelProps={{
                        fontSize: ".845rem",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
                <Text
                  fontSize="0.845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-30px"
                >
                  Account type
                </Text>
                {/* <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Type is required",
                    },
                  }}
                  control={control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Account Type"
                      withValidation
                      options={accountType}
                      value={selectAccountType}
                      onChange={(newValue: any) =>
                        field.onChange(setSelectAccountType(newValue))
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                /> */}
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Account type is required",
                    },
                  }}
                  control={control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Account type"
                      withValidation
                      options={accountType}
                      value={accountType.find(
                        (accountType) => accountType.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="accountCode"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                      }}
                      placeholder=""
                      label="Account code (optional)"
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "500",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
                <Text
                  marginTop={"-30px"}
                  fontSize={"0.6875rem"}
                  lineHeight={"1rem"}
                  fontWeight={"400"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  A unique code or number for this account
                </Text>
                <Text
                  fontSize="0.845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-30px"
                >
                  Currency
                </Text>
                {/* <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Currency Type is required",
                    },
                  }}
                  control={control}
                  name="currency"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Currency Type"
                      withValidation
                      options={currencyType}
                      value={selectCurrency}
                      onChange={(newValue: any) => setSelectCurrency(newValue)}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                /> */}
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Currency type is required",
                    },
                  }}
                  control={control}
                  name="currency"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Currency type"
                      withValidation
                      options={currencyType}
                      value={currencyType.find(
                        (currencyType) => currencyType.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                <Text
                  fontSize="0.845rem"
                  fontWeight="500"
                  _light={{
                    color: "customColor.black7",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-30px"
                >
                  Batch payments (optional)
                </Text>
                <Stack padding={"8px"} border="1px solid #a6a9b0">
                  <Checkbox
                    size={"md"}
                    onChange={() => setOptionalData(!optionalData)}
                  >
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"0.845rem"}
                      lineHeight={"1.5rem"}
                      fontWeight={"400"}
                    >
                      Batch payments are set up
                    </Text>
                  </Checkbox>
                </Stack>
                {optionalData && (
                  <Stack marginTop={"20px"}>
                    <Controller
                      control={control}
                      name="userId"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          ref={field.ref}
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "38px",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                          }}
                          placeholder="e.g 000000"
                          label="Direct Entry User ID (optional)"
                          labelProps={{
                            fontSize: ".845rem",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontWeight: "500",
                          }}
                        />
                      )}
                    />

                    <Text
                      fontSize={"0.6875rem"}
                      lineHeight={"1rem"}
                      fontWeight={"400"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                    >
                      Supplied by your bank for batch payments
                    </Text>
                    <Text
                      fontSize="0.845rem"
                      fontWeight="500"
                      _light={{
                        color: "customColor.black7",
                      }}
                      lineHeight={"1.3077"}
                    >
                      Self-balancing transaction (optional)
                    </Text>
                    <Stack padding={"8px"} border="1px solid #a6a9b0">
                      <Checkbox size={"md"}>
                        <Text
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontSize={"0.845rem"}
                          lineHeight={"1.5rem"}
                          fontWeight={"400"}
                        >
                          Include self-balancing transaction from ABA file
                        </Text>
                      </Checkbox>
                    </Stack>
                    <Link
                      textAlign="center"
                      color={"#3454d1"}
                      marginTop={"25px"}
                      fontWeight={"400"}
                      fontSize={"16px"}
                      _hover={{
                        textDecoration: "underline",
                      }}
                    >
                      Learn about adding accounts
                    </Link>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Container>
          <Stack
            h={"80px"}
            borderTop={"2px solid #d6dade"}
            alignItems={"center"}
            padding={"20px"}
            direction={"row"}
            justifyContent={"space-between"}
            marginTop={"20px"}
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate("/app/payroll/AddBankAccount");
              }}
            >
              Back
            </Button>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Save & add another
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={handleSubmit(
                  (data) => {
                    // Authentication.LoginUser(
                    //   data,
                    //   () => {
                    //     toast({
                    //       title: "login Successfull",
                    //       status: "success",
                    //     });
                    //     navigate("/ResetPassword");
                    //   },
                    //   (error) => {
                    //     toast({
                    //       title: "Login Failed",
                    //       description: error,
                    //       status: "error",
                    //     });
                    //   }
                    // );
                  },
                  (error) => {
                    console.log(error);
                  }
                )}
              >
                Save & continue
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
