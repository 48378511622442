import {
  Button,
  Container,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import moment from "moment";
import { useState } from "react";
import { RiArrowDownSFill } from "react-icons/ri";

interface HeaderProps {
  selectedEmployee: any;
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}

export function Header({
  selectedEmployee,
  setSelectedTab,
  selectedTab,
}: HeaderProps) {
  const setTerminationDate = useDisclosure();
  const toast = useToast();

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  return (
    <Stack
      _dark={{ bgColor: "customColor.dark.100" }}
      _light={{ bgColor: "customColor.gray.400" }}
      borderTop={"1px solid #d6dade"}
      direction={"row"}
    >
      <Stack w={"23%"} display={{ xs: "none", lg: "flex" }}></Stack>
      <Stack
        padding={"17px"}
        marginBottom={"30px"}
        w={{ xs: "100%", lg: "77%" }}
      >
        <Container
          p="0px"
          _dark={{ bgColor: "customColor.dark.50" }}
          _light={{ bgColor: "customColor.white" }}
          maxW={"100%"}
          borderRadius="4px"
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
          overflowY={"auto"}
        >
          <SimpleGrid columns={5} spacing={0}>
            {/* Stack 1 */}
            <Stack
              borderRight="1px solid #a6a9b0"
              h={{
                xs: "120px",
                lg: "100px",
              }}
              padding={{ xs: "8px 15px 15px", lg: "17px 15px 15px" }}
              justifyContent={"center"}
              _light={{ color: "customColor.black4" }}
              _dark={{ color: "customColor.white" }}
              gap={0}
              _hover={{
                bg: "#f2f3f4",
              }}
            >
              <Tooltip
                hasArrow
                label="Employee Name"
                padding={"6px 8px"}
                display={{ sm: "inline", md: "none" }}
              >
                <Heading
                  color={"#9A9A9A"}
                  fontSize={"12px"}
                  fontWeight={"bold"}
                  isTruncated
                >
                  Employee Name
                </Heading>
              </Tooltip>
              <Tooltip
                hasArrow
                label={
                  selectedEmployee?.firstName && selectedEmployee?.lastName
                    ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
                    : "-"
                }
                padding={"6px 8px"}
              >
                <Text
                  _light={{ color: "customColor.black4" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"bold"}
                  fontSize={"18px"}
                  isTruncated
                >
                  {selectedEmployee?.firstName && selectedEmployee?.lastName
                    ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
                    : "-"}
                </Text>
              </Tooltip>
            </Stack>

            {/* Stack 2 */}
            <Stack
              borderRight="1px solid #a6a9b0"
              h={{
                xs: "120px",
                lg: "100px",
              }}
              padding={{ xs: "8px 15px 15px", lg: "17px 15px 15px" }}
              justifyContent={"center"}
              gap={0}
              _hover={{
                bg: "#f2f3f4",
              }}
            >
              <Tooltip
                hasArrow
                label=" Annual Salary"
                padding={"6px 8px"}
                display={{ sm: "inline", md: "none" }}
              >
                <Heading
                  color={"#9A9A9A"}
                  fontSize={"12px"}
                  fontWeight={"bold"}
                  isTruncated
                >
                  Annual Salary
                </Heading>
              </Tooltip>

              <Stack
                color={"#AAAAAA"}
                backgroundColor={isHovered ? "transparent" : "#eee"}
                marginTop={"3px"}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {isHovered ? (
                  <Tooltip
                    hasArrow
                    label={selectedEmployee?.annualSalary}
                    padding={"6px 8px"}
                  >
                    <Text
                      textAlign={"left"}
                      fontSize={"17px"}
                      isTruncated
                      fontWeight={"bold"}
                      color={"black"}
                    >
                      {selectedEmployee?.annualSalary}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text
                    textAlign={"center"}
                    fontSize={"12px"}
                    isTruncated
                    fontWeight={"bold"}
                  >
                    View
                  </Text>
                )}
              </Stack>
            </Stack>

            {/* Stack 3 */}
            <Stack
              borderRight="1px solid #a6a9b0"
              h={{
                xs: "120px",
                lg: "100px",
              }}
              padding={{ xs: "8px 15px 15px", lg: "17px 15px 15px" }}
              justifyContent={"center"}
              gap={0}
              _hover={{
                bg: "#f2f3f4",
              }}
            >
              <Tooltip
                hasArrow
                label="Earnings YTD"
                padding={"6px 8px"}
                display={{ sm: "inline", md: "none" }}
              >
                <Heading
                  color={"#9A9A9A"}
                  fontSize={"12px"}
                  fontWeight={"bold"}
                  isTruncated
                >
                  Earnings YTD
                </Heading>
              </Tooltip>

              <Stack
                color={"#AAAAAA"}
                backgroundColor={isHovered2 ? "transparent" : "#eee"}
                marginTop={"3px"}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                {isHovered2 ? (
                  <Tooltip
                    hasArrow
                    label={selectedEmployee?.ytdSalary}
                    padding={"6px 8px"}
                  >
                    <Text
                      textAlign={"left"}
                      fontSize={"17px"}
                      fontWeight={"bold"}
                      isTruncated
                      color={"black"}
                    >
                      {selectedEmployee?.ytdSalary}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text
                    textAlign={"center"}
                    fontSize={"12px"}
                    isTruncated
                    fontWeight={"bold"}
                  >
                    View
                  </Text>
                )}
              </Stack>
            </Stack>

            {/* Stack 4 */}
            <Stack
              borderRight="1px solid #a6a9b0"
              h={{
                xs: "120px",
                lg: "100px",
              }}
              padding={{ xs: "8px 15px 15px", lg: "17px 15px 15px" }}
              justifyContent={"center"}
              gap={0}
              _hover={{
                bg: "#f2f3f4",
              }}
            >
              <Tooltip
                hasArrow
                label="Next Pay Day"
                padding={"6px 8px"}
                display={{ sm: "inline", md: "none" }}
              >
                <Heading
                  color={"#9A9A9A"}
                  fontSize={"12px"}
                  fontWeight={"bold"}
                  isTruncated
                >
                  Next Pay Day
                </Heading>
              </Tooltip>

              <Stack>
                {selectedEmployee?.nextPayPeriod?.map((item: any, i: any) => {
                  return (
                    <Tooltip
                      hasArrow
                      label={moment(item.endDate).format("DD MMM YYYY")}
                      padding={"6px 8px"}
                    >
                      <Text
                        key={i}
                        textAlign={"left"}
                        isTruncated
                        fontSize={"15px"}
                        fontWeight={"bold"}
                      >
                        {moment(item.endDate).format("DD MMM YYYY")}
                      </Text>
                    </Tooltip>
                  );
                })}
              </Stack>
            </Stack>

            {/* Stack 5 */}
            <Stack
              h={{
                xs: "120px",
                lg: "100px",
              }}
              alignItems="center" /* Center vertically */
              justifyContent={"center"}
              _hover={{
                bg: "#f2f3f4",
              }}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<RiArrowDownSFill fontSize={"20px"} />}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={{ sm: "6px", md: "12px 16px" }}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "primary.950",
                  }}
                  _active={{
                    backgroundColor: "#fff",
                    color: "primary.950",
                  }}
                  display="flex"
                  justifyContent={{ base: "center", md: "space-between" }} // Center icon on small screens, space-between on larger
                >
                  <Text display={{ base: "none", md: "inline" }}>Option</Text>{" "}
                  {/* Hide text on small screens */}
                </MenuButton>

                <MenuList width={"140px"}>
                  {[
                    {
                      name: "Set Termination Date",
                      onClick: () => {
                        setSelectedTab(17);
                      },
                    },
                    {
                      name: "Send Employee Invite",
                      onclick: () => {
                        ("");
                      },
                    },
                    {
                      name: "Delete Employee",
                      onclick: () => {
                        ("");
                      },
                    },
                  ].map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        padding={"1px 0px 1px 20px"}
                        color={"#32465A"}
                        onClick={item.onClick}
                        _hover={{
                          backgroundColor: "#F5F7F8",
                          color: "#1890C0",
                        }}
                      >
                        <Button
                          variant={"ghost"}
                          fontWeight={"normal"}
                          fontSize={"13px"}
                          padding={"0px 20px 0px 0px"}
                          color={"#32465A"}
                          _hover={{
                            backgroundColor: "#F5F7F8",
                            color: "#3454d1",
                          }}
                        >
                          {item.name}
                        </Button>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    </Stack>
  );
}
