import {
  Checkbox,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Advisor } from "../Tabs/Advisor";
import { Standard } from "../Tabs/Standard";

const tabList = [
  {
    name: "Standard",
  },
  {
    name: "Advisor",
  },
];
export function Business() {
  return (
    <Stack direction={"row"} display={"none"}>
      <Stack
        padding={"24px"}
        border={"1px solid lightgrey"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius={"5px"}
        w={{ md: "100%", lg: "850px" }}
      >
        <HStack gap={0}>
          <Checkbox size={"lg"} isDisabled={true} isChecked={true}></Checkbox>
          <Text
            fontSize={"15px"}
            fontWeight={"700"}
            color={"#000A1E80"}
            margin={"0px 8px"}
            cursor={"not-allowed"}
          >
            Business and accounting
          </Text>
        </HStack>
        <Text padding={"8px 0px"} fontSize={"15px"} fontWeight={"700"}>
          How much access do you need?
        </Text>

        <Tabs variant="enclosed">
          <TabList justifyContent="center" alignItems="center" fontSize="14px">
            {tabList.map((label, i) => {
              return (
                <Tab
                  key={i}
                  h="40px"
                  minH={"40px"}
                  bg={"#FFF"}
                  flex={1}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black",
                  }}
                  textAlign={"center"}
                  padding={"7px 12px"}
                  border="1px solid #ddd"
                  borderRadius="3px"
                  fontSize={"15px"}
                  lineHeight={"1.2"}
                  cursor={"pointer"}
                  _selected={{
                    bg: "customColor.black",
                    color: "#FFF",
                    fontWeight: "bold",
                    border: "0",
                    _hover: {
                      bg: "#404756",
                      color: "#FFF",
                    },
                  }}
                  _hover={{
                    bg: "#f2f3f4",
                    color: "customColor.black",
                  }}
                >
                  {label.name}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            <TabPanel padding={0}>
              <Standard />
            </TabPanel>
            <TabPanel padding={0}>
              <Advisor />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
}
