import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { BusinessTaxRate } from "../../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../../Api/ChartOfAccounts";

interface ChangeOfTaxModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedIds: string[];
  setLastUpdated: (timestamp: number) => void;
}

export function ChangeOfTaxModal({
  isOpen,
  onClose,
  selectedIds,
  setLastUpdated,
}: ChangeOfTaxModalProps) {
  const FormChartOfAccounts = useFormContext<ChartOfAccounts.ChangeTaxRate>();
  const [taxRate, setTaxRate] = useState<BusinessTaxRate.FetchTaxRate[]>([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRate(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  useEffect(() => {
    FormChartOfAccounts.setValue("ids", selectedIds);
  }, [selectedIds]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Change Tax Rate
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <FormLabel
            m={0}
            padding={"0px 0px 3px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.pearlWhite",
            }}
          >
            Select a Tax Rate
          </FormLabel>

          <Controller
            rules={{
              required: {
                value: true,
                message: "Tax is required",
              },
            }}
            control={FormChartOfAccounts.control}
            name="businesTaxRateId"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder="Select Tax type"
                isInvalid={fieldState.invalid}
                ErrorMessage="Select Tax type"
                withValidation
                options={taxRate}
                value={
                  field.value
                    ? {
                        id: field.value,
                        name:
                          taxRate.find((taxrate) => taxrate.id === field.value)
                            ?.name ?? "",
                      }
                    : undefined
                }
                onChange={(val: any) => {
                  field.onChange(val?.id);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            )}
          />
          <Stack justifyContent="flex-start" gap={0}>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              margin={"0px 0px 0px 10px"}
              w={"104.6px"}
              isLoading={isLoading}
              onClick={(e) => {
                FormChartOfAccounts.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    ChartOfAccounts.UpdatedTaxRate(
                      data,
                      (success) => {
                        toast({
                          title: "Tax Rate Updated",
                          status: "success",
                        });
                        setLastUpdated(Date.now());
                        setIsLoading(false);

                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Update Tax Rate Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Add Account Failed",

                      status: "error",
                    });
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              Ok
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              margin={"0px 0px 0px 10px"}
              w={"104.6px"}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
