import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { CustomInput } from "../../../../../Common/CustomInput";

interface MergeProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MergeModal({ isOpen, onClose }: MergeProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Merge transaction history
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <Text
            padding={"0px 0px 10px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            <b>0 contacts </b> will be archived and their transaction history
            will be merged with the contact below
          </Text>
          <CustomInput
            withValidation
            input={{
              w: "100%",

              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              fontSize: ".845rem",
              variant: "outline",
              type: "text",

              borderTopRadius: "3px",
            }}
            placeholder=""
            label="Contact to merge with"
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            h={"40px"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            margin={"0px 0px 0px 10px"}
            w={"104.6px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            borderRadius={"3px"}
            h={"40px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#2c48b5",
            }}
            margin={"0px 0px 0px 10px"}
            w={"104.6px"}
            isLoading={isLoading}
          >
            Merge
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
