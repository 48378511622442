import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";

interface DefaultSettingModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
}
export default function DefaultSettingModal({
  isOpen,
  onClose,
}: DefaultSettingModalProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [billDueDate, setBillDueDate] = useState("");
  const [salesDueDate, setSalesDueDate] = useState("");
  const [quoteExpiryDate, setQuoteExpiryDate] = useState("");
  const DueDateOptions: Array<{ label: string; value: string }> = [
    {
      label: "of the following month",
      value: "OF_THE_FOLLOWING_MONTH",
    },
    {
      label: "day(s) after the bill date",
      value: "DAYS_AFTER_THE_BILL_DATE",
    },
    {
      label: "day(s) after the end of the bill month",
      value: "DAYS_AFTER_THE_END_OF_THE_BILL_MONTH",
    },
    {
      label: "of the current month",
      value: "OF_THE_CURRENT_MONTH",
    },
  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxW={"500px"} overflowX={"auto"} h={"600px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          padding={"12px 15px"}
          fontSize="18px"
        >
          Default Settings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          bg={"customColor.gray.400"}
          padding={"15px"}
          borderTop={"1px solid #e5e7eb"}
          borderBottom={"1px solid #e5e7eb"}
        >
          <Box>
            <Text
              fontSize={"14px"}
              fontWeight={"700"}
              color={"customColor.black7"}
              padding={"0px 0px 5px"}
              margin={"0px 0px 10px"}
              borderBottom={"1px solid #e5e7eb"}
            >
              Payment Terms
            </Text>
            <Text
              fontSize={"11.5px"}
              color={"customColor.black7"}
              margin={"0px 0px 10px"}
            >
              These terms will be used to set the default Due Dates on all bills
              and sales invoices. You can always override these terms when you
              create bills and sales invoices. You can also set custom payment
              terms for individual contacts.
            </Text>
            <Stack gap={0}>
              <Text
                fontSize={"11.5px"}
                fontWeight={"700"}
                color={"customColor.black7"}
                margin={"0px 0px 4px"}
                padding={"5px 0px 0px"}
              >
                Bills Default Due Date (Optional)
              </Text>
              <Stack direction={"row"} gap={0} alignItems={"center"}>
                <Text
                  fontSize={"11.5px"}
                  fontWeight={"700"}
                  color={"customColor.black7"}
                  margin={"0px 5px 0px 0px"}
                  padding={"2px 0px 10px"}
                >
                  Due
                </Text>
                <Stack margin={"0px 5px 0px 0px"} padding={"0px 0px 10px"}>
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 10px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: "11px",
                      backgroundColor: "#fff",
                      variant: "outline",
                      lineHeight: "1.2",
                      h: "40px",
                      w: "40px",
                      type: "number", // Numeric input
                      borderLeftRadius: "0px",
                      borderRightRadius: "0px",
                      borderRadius: "0px",
                      onChange: (e) => {
                        const value = parseInt(e.target.value, 10); // Convert the string to a number
                        if (value > 31) {
                          e.target.value = "31"; // Reset the value to 31 if it exceeds the max limit
                        }
                      },
                    }}
                    placeholder=""
                  />
                </Stack>
                <Stack
                  margin={"0px 5px 0px 0px"}
                  padding={"0px 0px 10px"}
                  w={"200px"}
                >
                  <ReactSelect
                    withValidation
                    options={DueDateOptions}
                    value={DueDateOptions.find(
                      (op) => op.value === billDueDate
                    )}
                    onChange={(newValue: any) => {
                      setBillDueDate(newValue?.value);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={0}>
              <Text
                fontSize={"11.5px"}
                fontWeight={"700"}
                color={"customColor.black7"}
                margin={"0px 0px 4px"}
                padding={"5px 0px 0px"}
              >
                Sales Invoices Default Due Date (Optional)
              </Text>
              <Stack direction={"row"} gap={0} alignItems={"center"}>
                <Text
                  fontSize={"11.5px"}
                  fontWeight={"700"}
                  color={"customColor.black7"}
                  margin={"0px 5px 0px 0px"}
                  padding={"2px 0px 10px"}
                >
                  Due
                </Text>
                <Stack margin={"0px 5px 0px 0px"} padding={"0px 0px 10px"}>
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 10px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: "11px",
                      backgroundColor: "#fff",
                      variant: "outline",
                      lineHeight: "1.2",
                      h: "40px",
                      w: "40px",
                      type: "number", // Numeric input
                      borderLeftRadius: "0px",
                      borderRightRadius: "0px",
                      borderRadius: "0px",
                      onChange: (e) => {
                        const value = parseInt(e.target.value, 10); // Convert the string to a number
                        if (value > 31) {
                          e.target.value = "31"; // Reset the value to 31 if it exceeds the max limit
                        }
                      },
                    }}
                    placeholder=""
                  />
                </Stack>
                <Stack
                  margin={"0px 5px 0px 0px"}
                  padding={"0px 0px 10px"}
                  w={"200px"}
                >
                  <ReactSelect
                    withValidation
                    options={DueDateOptions}
                    value={DueDateOptions.find(
                      (op) => op.value === salesDueDate
                    )}
                    onChange={(newValue: any) => {
                      setSalesDueDate(newValue?.value);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Text
              fontSize={"14px"}
              fontWeight={"700"}
              color={"customColor.black7"}
              padding={"0px 0px 5px"}
              margin={"0px 0px 10px"}
              borderBottom={"1px solid #e5e7eb"}
            >
              Automatic Sequencing
            </Text>
            <Text
              fontSize={"11.5px"}
              color={"customColor.black7"}
              margin={"0px 0px 10px"}
            >
              Define the number to be used when creating your next invoice,
              credit note or purchase order. The number will automatically
              increment with each new document you create.
            </Text>
            <Stack w={"300px"} gap={0}>
              <Stack direction={"row"} gap={0}>
                <Stack
                  margin={"0px 10px 0px 0px"}
                  padding={"0px 0px 10px"}
                  flex={1}
                >
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 2px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      backgroundColor: "#fff",
                      variant: "outline",
                      // color: "#ccc",

                      lineHeight: "1.2",
                      h: "20px",
                      w: "120px",
                      type: "text",
                      borderLeftRadius: "none",
                      borderRightRadius: "none",
                      borderRadius: "none",
                    }}
                    placeholder=""
                    label="Invoices Prefix"
                    labelProps={{
                      fontSize: "11.5px",
                      margin: "0px 0px 3px",
                      fontWeight: "700",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                </Stack>
                <Stack
                  margin={"0px 10px 0px 0px"}
                  padding={"0px 0px 10px"}
                  flex={1}
                >
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 2px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      backgroundColor: "#fff",
                      variant: "outline",
                      // color: "#ccc",

                      lineHeight: "1.2",
                      h: "20px",
                      w: "120px",
                      type: "text",
                      borderLeftRadius: "none",
                      borderRightRadius: "none",
                      borderRadius: "none",
                    }}
                    placeholder=""
                    label="Next Number"
                    labelProps={{
                      fontSize: "11.5px",
                      margin: "0px 0px 3px",
                      fontWeight: "700",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack
                margin={"0px 10px 0px 0px"}
                padding={"0px 0px 10px"}
                flex={1}
              >
                <CustomInput
                  withValidation
                  input={{
                    padding: "0px 2px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontSize: ".845rem",
                    backgroundColor: "#fff",
                    variant: "outline",
                    // color: "#ccc",

                    lineHeight: "1.2",
                    h: "20px",
                    w: "120px",
                    type: "text",
                    borderLeftRadius: "none",
                    borderRightRadius: "none",
                    borderRadius: "none",
                  }}
                  placeholder=""
                  label="Credit Note Prefix"
                  labelProps={{
                    fontSize: "11.5px",
                    margin: "0px 0px 3px",
                    fontWeight: "700",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              </Stack>
              <Stack direction={"row"} gap={0}>
                <Stack
                  margin={"0px 10px 0px 0px"}
                  padding={"0px 0px 10px"}
                  flex={1}
                >
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 2px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      backgroundColor: "#fff",
                      variant: "outline",
                      // color: "#ccc",

                      lineHeight: "1.2",
                      h: "20px",
                      w: "120px",
                      type: "text",
                      borderLeftRadius: "none",
                      borderRightRadius: "none",
                      borderRadius: "none",
                    }}
                    placeholder=""
                    label="Purchase Order Prefix"
                    labelProps={{
                      fontSize: "11.5px",
                      margin: "0px 0px 3px",
                      fontWeight: "700",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                </Stack>
                <Stack
                  margin={"0px 10px 0px 0px"}
                  padding={"0px 0px 10px"}
                  flex={1}
                >
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 2px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      backgroundColor: "#fff",
                      variant: "outline",
                      // color: "#ccc",

                      lineHeight: "1.2",
                      h: "20px",
                      w: "120px",
                      type: "text",
                      borderLeftRadius: "none",
                      borderRightRadius: "none",
                      borderRadius: "none",
                    }}
                    placeholder=""
                    label="Next Number"
                    labelProps={{
                      fontSize: "11.5px",
                      margin: "0px 0px 3px",
                      fontWeight: "700",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction={"row"} gap={0}>
                <Stack
                  margin={"0px 10px 0px 0px"}
                  padding={"0px 0px 10px"}
                  flex={1}
                >
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 2px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      backgroundColor: "#fff",
                      variant: "outline",
                      // color: "#ccc",

                      lineHeight: "1.2",
                      h: "20px",
                      w: "120px",
                      type: "text",
                      borderLeftRadius: "none",
                      borderRightRadius: "none",
                      borderRadius: "none",
                    }}
                    placeholder=""
                    label="Quote Prefix"
                    labelProps={{
                      fontSize: "11.5px",
                      margin: "0px 0px 3px",
                      fontWeight: "700",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                </Stack>
                <Stack
                  margin={"0px 10px 0px 0px"}
                  padding={"0px 0px 10px"}
                  flex={1}
                >
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 2px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      backgroundColor: "#fff",
                      variant: "outline",
                      // color: "#ccc",

                      lineHeight: "1.2",
                      h: "20px",
                      w: "120px",
                      type: "text",
                      borderLeftRadius: "none",
                      borderRightRadius: "none",
                      borderRadius: "none",
                    }}
                    placeholder=""
                    label="Next Number"
                    labelProps={{
                      fontSize: "11.5px",
                      margin: "0px 0px 3px",
                      fontWeight: "700",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Text
              fontSize={"14px"}
              fontWeight={"700"}
              color={"customColor.black7"}
              padding={"0px 0px 5px"}
              margin={"0px 0px 10px"}
              borderBottom={"1px solid #e5e7eb"}
            >
              Show Outstanding Bills
            </Text>
            <Checkbox margin={"0px 0px 10px"} size={"md"}>
              <Text fontSize={"11.5px"} color={"customColor.black7"}>
                Include a link on online invoices to show all outstanding bills
                for a contact
              </Text>
            </Checkbox>
            <Stack gap={0}>
              <Text
                fontSize={"14px"}
                fontWeight={"700"}
                color={"customColor.black7"}
                padding={"0px 0px 5px"}
                margin={"0px 0px 10px"}
                borderBottom={"1px solid #e5e7eb"}
              >
                Quote Expiry Date
              </Text>
              <Stack direction={"row"} gap={0} alignItems={"center"}>
                <Text
                  fontSize={"11.5px"}
                  fontWeight={"700"}
                  color={"customColor.black7"}
                  margin={"0px 5px 0px 0px"}
                  padding={"2px 0px 10px"}
                >
                  Due
                </Text>
                <Stack margin={"0px 5px 0px 0px"} padding={"0px 0px 10px"}>
                  <CustomInput
                    withValidation
                    input={{
                      padding: "0px 10px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: "11px",
                      backgroundColor: "#fff",
                      variant: "outline",
                      lineHeight: "1.2",
                      h: "40px",
                      w: "40px",
                      type: "number", // Numeric input
                      borderLeftRadius: "0px",
                      borderRightRadius: "0px",
                      borderRadius: "0px",
                      onChange: (e) => {
                        const value = parseInt(e.target.value, 10); // Convert the string to a number
                        if (value > 31) {
                          e.target.value = "31"; // Reset the value to 31 if it exceeds the max limit
                        }
                      },
                    }}
                    placeholder=""
                  />
                </Stack>
                <Stack
                  margin={"0px 5px 0px 0px"}
                  padding={"0px 0px 10px"}
                  w={"200px"}
                >
                  <ReactSelect
                    withValidation
                    options={DueDateOptions}
                    value={DueDateOptions.find(
                      (op) => op.value === quoteExpiryDate
                    )}
                    onChange={(newValue: any) => {
                      setQuoteExpiryDate(newValue?.value);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            marginRight={"8px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
          >
            {isLoading ? "Saving" : "Save"}
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
