import {
  Box,
  HStack,
  Input,
  InputGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export function InvoiceTable() {
  return (
    <Stack
      _light={{
        bg: "customColor.white",
      }}
    >
      <Stack
        gap={0}
        // maxWidth={"1164px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius="4px"
        bg={"#fff"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderWidth="1px"
        borderStyle={"solid"}
        borderColor="#f6f6f6"
        p={"0px"}
      >
        <Stack
          p={"25px"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box w={"100%"}>
            {/* <PaginationControl
              limit={limit}
              setLimit={setLimit}
              setPageNumber={setPageNumber}
            /> */}
          </Box>
          <Stack gap={0} direction={"row"} alignItems={"center"}>
            <Text
              fontSize={"13.44px"}
              _light={{
                color: "customColor.gray.6b7885",
              }}
            >
              Search:
            </Text>
            <InputGroup ml={[0, "7px"]}>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
                // onChange={(e) => {
                //   debounced(e.target.value);
                // }}
              />
            </InputGroup>
          </Stack>
        </Stack>
        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                borderTopWidth={"1px"}
                borderTopColor="borders.tableBorder"
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {[
                  {
                    name: "Number",
                    value: "10%",
                    paddingValue: "8px 26px 8px 30px",
                  },
                  {
                    name: "Ref",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "",
                    value: "5%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "To",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Date",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Due Date",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Paid",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Due",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Status",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Sent",
                    value: "5%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {[
                {
                  number: "ORC1042",
                  ref: "Support",
                  file: "",
                  to: "Boom Fm",
                  date: "29 Jul 2024",
                  dueDate: "29 Aug 2024",
                  paid: "0.00",
                  due: "1690.58",
                  status: "Draft",
                  sent: "",
                },
                {
                  number: "ORC1043",
                  ref: "Support",
                  file: "",
                  to: "Bayside Club",
                  date: "29 Jul 2024",
                  dueDate: "23 Aug 2024",
                  paid: "0.00",
                  due: "785.36",
                  status: "Draft",
                  sent: "Sent",
                },
                {
                  number: "ORC1044",
                  ref: "Monthly Support",
                  file: "",
                  to: "Marine Systems",
                  date: "29 Jul 2024",
                  dueDate: "21 Aug 2024",
                  paid: "0.00",
                  due: "786.69",
                  status: "Awaiting Approval",
                  sent: "Sent",
                },
                {
                  number: "ORC1045",
                  ref: "Monthly Support",
                  file: "",
                  to: "Basket Case",
                  date: "29 Jul 2024",
                  dueDate: "17 Aug 2024",
                  paid: "0.00",
                  due: "11025.78",
                  status: "Awaiting Approval",
                  sent: "",
                },
                {
                  number: "ORC1046",
                  ref: "Financial Support",
                  file: "",
                  to: "Cube Land",
                  date: "29 Jul 2024",
                  dueDate: "19 Aug 2024",
                  paid: "0.00",
                  due: "1111.96",
                  status: "Awaiting Payment",
                  sent: "",
                },
                {
                  number: "ORC1047",
                  ref: "Financial Support",
                  file: "",
                  to: "Club Basket",
                  date: "29 Jul 2024",
                  dueDate: "11 Aug 2024",
                  paid: "0.00",
                  due: "783.96",
                  status: "Awaiting Payment",
                  sent: "Sent",
                },
              ].map((item, i) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item.number}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.ref}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.file}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.to}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.date}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.dueDate}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.paid}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.due}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.status}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.sent}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Stack borderTop={"1px solid #dcdee4 "}>
          <HStack
            marginTop={"9.35px"}
            padding={"25px"}
            fontSize={"13px"}
            _light={{
              color: "customColor.black2",
            }}
            _dark={{
              color: "customColor.white",
            }}
            justifyContent={"space-between"}
          ></HStack>
        </Stack>
      </Stack>
    </Stack>
  );
}
