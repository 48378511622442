import { Container, HStack, Stack, Text, useToast } from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { BusinessTaxRate } from "../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../Api/ChartOfAccounts";

interface PurchaseProp {
  purchaseDetailsRef: React.RefObject<HTMLDivElement>;
}
export function PurchaseDetails({ purchaseDetailsRef }: PurchaseProp) {
  const [provinceValue, setProvincesValue] = useState();
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  let countryData = Country.getAllCountries();
  const { businessesList, currentBusinessId } = useBusinessContext();
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);

  const [taxRate, setTaxRate] = useState("");
  const [chartOfAccount, setChartOfAccount] = useState("");
  const toast = useToast();
  const [taxRateData, setTaxRateData] = useState<
    BusinessTaxRate.FetchTaxRate[]
  >([]);
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  useEffect(() => {
    ChartOfAccounts.GetAllAccounts(
      {
        category: "",

        page: 1,
        limit: 100,
      },
      (res) => {
        setChartOfAccounts(res.data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRateData(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  const [invoiceDate, setInvoiceDate] = useState("");
  const billingOptions: Array<{ label: string; value: string }> = [
    {
      label: "of the following month",
      value: "FOLLOWING_MONTH",
    },
    {
      label: "day after the bill date",
      value: "ONE_DAY_AFTER_BILLING_MONTH",
    },
    {
      label: "day after the bill month",
      value: "ONE_DAY_AFTER_DUE_DATE",
    },
    {
      label: "of the current month",
      value: "BILLING_MONTH",
    },
  ];

  const [amount, setAmount] = useState("");
  const amountOptions = [
    "Use organization settings",
    "Tax exclusive",
    "Tax inclusive",
    "No Tax",
  ].map((amount) => ({
    label: amount,
    value: amount,
  }));

  return (
    <Stack
      ref={purchaseDetailsRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"30px"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          margin={"14px 0px"}
          fontSize={"17px"}
          color={"customColor.black7"}
          fontWeight={"700"}
        >
          Purchase Defaults
        </Text>
        <Stack spacing={"25px"}>
          <Text fontSize={"15px"}>
            Defaults can be overridden on individual spend money, bills and
            purchase orders
          </Text>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.black7"}
              margin={"0px 12px 8px 0px"}
            >
              Purchase account
            </Text>
            <ReactSelect
              withValidation
              options={chartOfAccounts?.map((acc: any) => ({
                value: acc.id,
                label: `${acc.code}: ${acc.name}`, // Including code before name
              }))}
              value={chartOfAccounts
                ?.map((acc: any) => ({
                  value: acc.id,
                  label: `${acc.code}: ${acc.name}`, // Including code before name
                }))
                .find((option: any) => option.value === chartOfAccount)}
              onChange={(newValue: any) => {
                setChartOfAccount(newValue?.value);
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.black7"}
              margin={"0px 12px 8px 0px"}
            >
              Bill due date
            </Text>
            <HStack gap={"0.5"} alignItems={"baseline"}>
              <Stack flex={1}>
                <CustomInput
                  // ref={field.ref}
                  // validationMessage={error?.message}
                  withValidation
                  input={{
                    // ...field,
                    // isInvalid: error !== undefined,
                    fontSize: ".845rem",
                    h: "38px",
                    variant: "outline",
                    type: "text",
                    // onChange: (e) => {
                    //   field.onChange(Number(e.target.value));
                    // },

                    borderTopRadius: "3px",
                    borderRightRadius: "0px",
                    alignContent: "center",
                  }}
                  placeholder=""
                />
              </Stack>
              <Stack flex={6}>
                <ReactSelect
                  withValidation
                  options={billingOptions}
                  value={billingOptions.find((op) => op.value === invoiceDate)}
                  onChange={(newValue: any) => {
                    setInvoiceDate(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
            </HStack>
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.black7"}
              margin={"0px 12px 8px 0px"}
            >
              Amount are
            </Text>
            <ReactSelect
              // {...field}
              placeholder=""
              // marginBottom="30px"
              // width={"48em"}
              // isInvalid={fieldState.invalid}
              ErrorMessage="Select Amount "
              withValidation
              options={amountOptions}
              value={amountOptions.find(
                (amountOptions) => amountOptions.value === amount
              )}
              onChange={(newValue: any) => setAmount(newValue?.value)}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.black7"}
              margin={"0px 12px 8px 0px"}
            >
              Purchase GST
            </Text>
            <ReactSelect
              placeholder="Select Tax Rate"
              ErrorMessage="Select Tax Rate"
              withValidation
              options={taxRateData.map((item) => ({
                value: item.id, // Set value as the tax rate id
                label: item.name, // Set label as the tax rate name
              }))} // Transform options for ReactSelect
              value={
                taxRate
                  ? taxRateData
                      .map((item) => ({ value: item.id, label: item.name }))
                      .find((option) => option.value === taxRate) // Match the selected value with id
                  : undefined
              }
              onChange={(newValue: any) => setTaxRate(newValue?.value)} // Update only the value (id) on selection
              getOptionLabel={(option: any) => option.label} // Display label
              getOptionValue={(option: any) => option.value} // Track by value
            />
          </Stack>

          <Stack gap={0}>
            <Text
              fontSize={".845rem"}
              fontWeight={"600"}
              color={"customColor.black7"}
              margin={"0px 12px 8px 0px"}
            >
              Region
            </Text>
            <ReactSelect
              withValidation
              options={provinces}
              value={provinces.find((op) => op.name === provinceValue)}
              onChange={(newValue: any) => {
                setProvincesValue(newValue?.name);
              }}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.name}
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
