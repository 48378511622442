import {
  Avatar,
  Box,
  Container,
  HStack,
  Progress,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { MdOutlineLocationOn } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Shifts } from "../../../TimeAttendance/API/Shifts";
import { ShiftsOperations } from "../../../TimeAttendance/API/ShiftsOperations";
import { Employees } from "../../Api/Employees";
import { fetchLocation } from "../TimeSheet/utils";

interface EmployeeInfoProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}

export function EmployeeInfo({ employeeData }: EmployeeInfoProps) {
  const param = useParams();
  const user = useAuthUser();
  const toast = useToast();
  const [userName, setUserName] = useState("");
  const [updateRoaster, setUpdateRoaster] = useState(Date.now());
  const [latitude, setLatitude] = useState<string>();
  const [longitude, setLongitude] = useState<string>();
  const [timeDifference, setTimeDifference] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [startisLoad, setStartIsLoad] = useState(false);
  const [endisLoad, setEndIsLoad] = useState(false);
  useEffect(() => {
    const UserDetail = user();

    if (UserDetail) {
      const fullName = UserDetail.firstName + " " + UserDetail.lastName;
      setUserName(fullName); // Update the state variable
    } else {
      toast({
        title: "Name not found in the user object",
        status: "error",
      });
    }
  }, []);
  // const form = useFormContext<Analytics.DashBoardData>();
  const [getRoaster, setGetRoaster] = useState<any>();
  useEffect(() => {
    Shifts.FetchRoaster(
      moment().format("YYYY-MM-DD"),
      moment().format("HH:mm:ss"),
      (data) => {
        setGetRoaster(data);
      },
      (message) => {
        // console.log(message);
      }
    );
  }, [updateRoaster]);
  useEffect(() => {
    const interval = setInterval(() => {
      updateTimeDifference();
    }, 1000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [getRoaster]);

  function getTimeDifference(shiftStartTime: any) {
    if (!shiftStartTime) {
      return "Shift start time is not available.";
    }

    // Parse the shift start time and current time
    const shiftStartMoment = moment(shiftStartTime, "HH:mm:ss");
    const currentMoment = moment();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentMoment.diff(shiftStartMoment);

    // Convert the difference to a more readable format (e.g., hours, minutes, seconds)
    const duration = moment.duration(differenceInMilliseconds);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Format the difference as a string
    return `${hours}:${minutes}:${seconds}`;
  }

  function updateTimeDifference() {
    const shiftStartTime = getRoaster?.shiftRecord?.shiftStartTime;
    const timeDifference = getTimeDifference(shiftStartTime);
    setTimeDifference(timeDifference);
  }
  const handleFetchLocation = async () => {
    try {
      const { latitude, longitude }: any = await fetchLocation();
      setLatitude(latitude.toString());
      setLongitude(longitude.toString());
      setLocationError(false);
    } catch (error) {
      setLocationError(true);
      toast({
        title: "Error fetching location",
        description:
          "An error occurred while fetching the location. enable location services and try again or your system does not support location services.",
        status: "error",
      });
    }
  };
  useEffect(() => {
    handleFetchLocation();
  }, [latitude, longitude]);
  const handleStartShift = async () => {
    await handleFetchLocation();
    if (!locationError) {
      setStartIsLoad(true);
      ShiftsOperations.StartShift(
        {
          id: getRoaster?.id,
          shiftStartTime: moment().format("HH:mm:ss"),
          shiftStartDate: moment().format("YYYY-MM-DD"),
          shiftStartCoordinates: {
            latitude: latitude ?? "0",
            longitude: longitude ?? "0",
          },
        },
        (data) => {
          toast({
            title: "Shift Started",
            description: data,
            status: "success",
          });
          setStartIsLoad(false);
          setUpdateRoaster(Date.now());
        },
        (message) => {
          toast({
            title: "Error",
            description: message,
            status: "error",
          });
        }
      );
    }
  };
  const handleEndShift = async () => {
    await handleFetchLocation();
    if (!locationError) {
      setEndIsLoad(true);
      ShiftsOperations.EndShift(
        {
          id: getRoaster?.id,
          shiftEndTime: moment().format("HH:mm:ss"),
          shiftEndDate: moment().format("YYYY-MM-DD"),
          shiftEndCoordinates: {
            latitude: latitude ?? "0",
            longitude: longitude ?? "0",
          },
        },
        (data) => {
          toast({
            title: "Shift Ended",
            description: data,
            status: "success",
          });
          setEndIsLoad(false);
          setUpdateRoaster(Date.now());
        },
        (message) => {
          toast({
            title: "Error",
            description: message,
            status: "error",
          });
        }
      );
    }
  };

  return (
    <Container
      bg={"#fff"}
      marginBottom={"24px"}
      maxW={"100%"}
      padding={0}
      borderRadius={"10px"}
      transition={"all .3s ease"}
      boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
      // Add this line to make it sticky
      // Adjust the top position as needed
    >
      <Stack padding={"25px"} gap={0} w={"100%"}>
        <Stack alignItems={"center"} justifyContent={"center"} gap={0}>
          <Avatar
            w={"140px"}
            h={"140px"}
            marginBottom={"24px"}
            name={`${employeeData?.firstName ?? "NA"} ${
              employeeData?.lastName ?? "NA"
            }`}
            size="2xl"
            //   src="https://bit.ly/dan-abramov"
          />
        </Stack>
        <Text
          textAlign={"center"}
          fontSize={"15px"}
          fontWeight={"600"}
          _light={{ color: "customColor.black7" }}
          _hover={{ _light: { color: "#3454d1" } }}
        >
          {employeeData?.firstName ?? "NA"} {employeeData?.lastName ?? "NA"}
        </Text>
        <Text
          textAlign={"center"}
          fontSize={"13px"}
          fontWeight={"400"}
          _light={{ color: "customColor.gray.64748B" }}
          marginBottom={"24px"}
        >
          {employeeData?.email ?? "NA"}
        </Text>
        <Box mb="24px">
          <Text
            _light={{ color: "customColor.gray.64748B" }}
            fontSize={"13.44px"}
            fontWeight={"500"}
            mb="8px"
          >
            Profile Completion
          </Text>
          <HStack>
            <Progress
              hasStripe
              colorScheme="blue"
              value={employeeData?.completed ?? 0}
              borderRadius="5px"
              w="100%"
            />
            <Text
              _light={{ color: "customColor.black7" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
            >
              {employeeData?.completed ?? 0}%
            </Text>
          </HStack>
        </Box>
        <Stack
          marginBottom={"24px"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <MdOutlineLocationOn
              width={"16px"}
              height={"16px"}
              color="#64748B"
            />
            <Text
              _light={{ color: "customColor.gray.64748B" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
            >
              Job Title
            </Text>
          </Stack>
          {/* <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"13.44px"}
            fontWeight={"500"}
          >
            {employeeData?.employeeEmployementDetails?.jobTitle ?? "NA"}
          </Text> */}
          <Tooltip
            label={employeeData?.employeeEmployementDetails?.jobTitle}
            placement="top"
          >
            <Text
              _light={{ color: "customColor.black7" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
              maxWidth="100%" // Set a fixed width to trigger the ellipsis
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {employeeData?.employeeEmployementDetails?.jobTitle ?? "NA"}
            </Text>
          </Tooltip>
        </Stack>
        <Stack
          marginBottom={"24px"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <MdOutlineLocationOn
              width={"16px"}
              height={"16px"}
              color="#64748B"
            />
            <Text
              _light={{ color: "customColor.gray.64748B" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
            >
              Mobile Number
            </Text>
          </Stack>
          {/* <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"13.44px"}
            fontWeight={"500"}
          >
            {employeeData?.mobileNumber}
          </Text> */}
          <Tooltip label={employeeData?.mobileNumber} placement="top">
            <Text
              _light={{ color: "customColor.black7" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
              maxWidth="100%" // Set a fixed width to trigger the ellipsis
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {employeeData?.mobileNumber ?? "NA"}
            </Text>
          </Tooltip>
        </Stack>
        <Stack
          marginBottom={"24px"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <MdOutlineLocationOn
              width={"16px"}
              height={"16px"}
              color="#64748B"
            />
            <Text
              _light={{ color: "customColor.gray.64748B" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
            >
              Email
            </Text>
          </Stack>
          <Tooltip label={employeeData?.email} placement="top">
            <Text
              _light={{ color: "customColor.black7" }}
              fontSize={"13.44px"}
              fontWeight={"500"}
              maxWidth="100%" // Set a fixed width to trigger the ellipsis
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {employeeData?.email ?? "NA"}
            </Text>
          </Tooltip>
        </Stack>

        {/* <Stack
          padding={"16px"}
          word-wrap="break-word"
          overflowY="auto"
          _dark={{
            bgColor: "customColor.dark.50",
          }}>
          <Center>
            <Stack alignItems={"center"}>
              <Stack
                alignItems={"center"}
                display={
                  getRoaster?.status === "pending" ||
                  getRoaster?.status === "started"
                    ? "block"
                    : "none"
                }
              >
                <Text
                  fontSize={"13px"}
                  color="customColor.gray.64748B"
                  _dark={{
                    color: "customColor.dark.200",
                  }}
                  fontWeight={"600"}
                >
                  Roaster
                </Text>
                <Text
                  fontSize={"13px"}
                  color="customColor.gray.64748B"
                  _dark={{
                    color: "customColor.dark.200",
                  }}
                  fontWeight={"600"}
                >
                  Location: {getRoaster?.location?.name}
                </Text>
                <Text
                  fontSize={"13px"}
                  color="customColor.gray.64748B"
                  _dark={{
                    color: "customColor.dark.200",
                  }}
                  fontWeight={"600"}
                >
                  Time :{" "}
                  {moment(getRoaster?.shiftStartTime, "HH:mm:ss").format(
                    "hh:mm A"
                  )}{" "}
                  -{" "}
                  {moment(getRoaster?.shiftEndTime, "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                </Text>
                <Text
                  fontSize={"13px"}
                  color="customColor.gray.64748B"
                  _dark={{
                    color: "customColor.dark.200",
                  }}
                  fontWeight={"600"}
                >
                  Address: {getRoaster?.location?.address}
                </Text>
              </Stack>
            </Stack>
          </Center>
          <Center>
            <Stack marginTop={"1.5rem!important"} gap={0} direction={"row"}>
              <ButtonGroup w={"100%"}>
                <Button
                  isLoading={startisLoad}
                  display={getRoaster?.status === "pending" ? "block" : "none"}
                  w={"100%"}
                  fontSize={"13px"}
                  bgColor="#4CBB17"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#48B016",
                    borderColor: "#48B016",
                  }}
                  marginBottom={"16px"}
                  onClick={handleStartShift}
                >
                  Start Shift
                </Button>

                <Button
                  isLoading={endisLoad}
                  display={getRoaster?.status === "started" ? "block" : "none"}
                  w={"100%"}
                  fontSize={"13px"}
                  bgColor="#FF0000"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#E60000",
                    borderColor: "#E60000",
                  }}
                  marginBottom={"16px"}
                  onClick={handleEndShift}
                >
                  End Shift
                </Button>
              </ButtonGroup>
            </Stack>
          </Center>
          <Stack
            display={getRoaster?.shiftRecord?.shiftStartTime ? "block" : "none"}
          >
            <Center>
              <Stack direction={"row"} alignItems={"center"}>
                <Text
                  fontSize={"14px"}
                  color="customColor.black7"
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontWeight={"600"}
                >
                  Shift Started :{" "}
                  {moment(
                    getRoaster?.shiftRecord?.shiftStartTime,
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                </Text>
              </Stack>
            </Center>
            <Center>
              <Stack direction={"row"} alignItems={"center"}>
                <Text
                  fontSize={"14px"}
                  color="customColor.black7"
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontWeight={"600"}
                >
                  Shift Duration : {timeDifference}
                </Text>
              </Stack>
            </Center>
          </Stack>
        </Stack> */}
        {/* </Stack> */}
      </Stack>
    </Container>
  );
}
