import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { TbMinusVertical } from "react-icons/tb";
interface LeaveProps {
  selectedLeaveFilters: {
    payrollPayItemLeaveId: boolean;
    calculationMethod: boolean;
    quantity: boolean;
    balance: boolean;
    terminationUnusedBalance: boolean;
    etpLeaveType: boolean;
    includeRetirementContribution: boolean;
    accruedQuantity: boolean;
    openingBalance: boolean;
  };
  setSelectedLeaveFilters: React.Dispatch<
    React.SetStateAction<{
      payrollPayItemLeaveId: boolean;
      calculationMethod: boolean;
      quantity: boolean;
      balance: boolean;
      terminationUnusedBalance: boolean;
      etpLeaveType: boolean;
      includeRetirementContribution: boolean;
      accruedQuantity: boolean;
      openingBalance: boolean;
    }>
  >;
  events: string;
}
export function LeaveFilter({
  setSelectedLeaveFilters,
  selectedLeaveFilters,
  events,
}: LeaveProps) {
  // const toggleFilter = (filterName: keyof typeof selectedLeaveFilters) => {
  //   setSelectedLeaveFilters((prev) => ({
  //     ...prev,
  //     [filterName]: !prev[filterName],
  //   }));
  // };
  // const selectedCount =
  //   Object.values(selectedLeaveFilters).filter(Boolean).length;
  const form = useFormContext();
  const toggleFilter = (filterName: keyof typeof selectedLeaveFilters) => {
    setSelectedLeaveFilters((prev) => {
      const isCurrentlyChecked = prev[filterName];
      const updatedFilters = {
        ...prev,
        [filterName]: isCurrentlyChecked ? undefined : true,
      };

      if (!updatedFilters[filterName]) {
        form.setValue(filterName, undefined);
      }

      return updatedFilters;
    });
  };

  const selectedCount =
    Object.values(selectedLeaveFilters).filter(Boolean).length;
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              <Flex>
                <TbMinusVertical fontSize={"22px"} color={"hsl(0,0%,80%)"} />
                <ChevronDownIcon
                  fontSize={"22px"}
                  color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                  _hover={{
                    color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                  }}
                />
              </Flex>
            } //5A5A5A //383c40
            width={"100%"}
            textAlign={"start"}
            h={"38px"}
            _light={{
              bgColor: "customColor.white",
              color: "customColor.black7",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            fontWeight={"400"}
            border={"1px solid #e5e7eb"}
            borderRadius={"4px"}
            _hover={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _active={{
              color: "black",
              bg: "#f2f3f4",
              borderWidth: "1px",

              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
          >
            {selectedCount > 0 ? `${selectedCount} Selected` : "Filters"}
          </MenuButton>

          <MenuList minWidth="200px" maxH={"300px"} overflowX={"auto"}>
            {[
              {
                name: "Leave",
                onChange: () => toggleFilter("payrollPayItemLeaveId"),
                isChecked: !!selectedLeaveFilters.payrollPayItemLeaveId,
              },
              {
                name: "Calculation Method",
                onChange: () => toggleFilter("calculationMethod"),
                isChecked: !!selectedLeaveFilters.calculationMethod,
              },
              {
                name: "Quantity",
                onChange: () => toggleFilter("quantity"),
                isChecked: !!selectedLeaveFilters.quantity,
              },
              {
                name: "Balance",
                onChange: () => toggleFilter("balance"),
                isChecked: !!selectedLeaveFilters.balance,
              },
              {
                name: "Termination Unused Balance",
                onChange: () => toggleFilter("terminationUnusedBalance"),
                isChecked: !!selectedLeaveFilters.terminationUnusedBalance,
              },
              {
                name: "ETP Leave Type",
                onChange: () => toggleFilter("etpLeaveType"),
                isChecked: !!selectedLeaveFilters.etpLeaveType,
              },
              {
                name: "Include Retirement Contribution",
                onChange: () => toggleFilter("includeRetirementContribution"),
                isChecked: !!selectedLeaveFilters.includeRetirementContribution,
              },
              {
                name: "Accrued Quantity",
                onChange: () => toggleFilter("accruedQuantity"),
                isChecked: !!selectedLeaveFilters.accruedQuantity,
              },
              {
                name: "Opening Balance",
                onChange: () => toggleFilter("openingBalance"),
                isChecked: !!selectedLeaveFilters.openingBalance,
              },
            ].map((item, i) => {
              return (
                <MenuItem>
                  <Checkbox onChange={item.onChange} isChecked={item.isChecked}>
                    <Text
                      fontSize={".845rem"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"600"}
                    >
                      {item.name}
                    </Text>
                  </Checkbox>
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
}
