import {
  Box,
  Button,
  Collapse,
  Icon,
  IconButton,
  Input,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useRef, useState } from "react";
import { MdAdd, MdDelete, MdRemove } from "react-icons/md";
import { useParams } from "react-router-dom";
import { UploadAttachments } from "../../../../Api/UploadAttachments";
import { DeleteDocumentModal } from "./Modals/DeleteDocumentModal";

interface DocumentRowProps {
  type: string;
  progress: string;
  docType: string;
  GetAllDocuments: Array<UploadAttachments.Document>;
  setGetAllDocuments: (value: Array<UploadAttachments.Document>) => void;
  filteredDocuments: Array<UploadAttachments.Document>;
  remainingDocuments: number;
  setLastUpdated: (value: number) => void;
}
export default function Document({
  type,
  progress,
  docType,
  GetAllDocuments,
  setGetAllDocuments,
  filteredDocuments,
  remainingDocuments,
  setLastUpdated,
}: DocumentRowProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const handleAttachClick = () => {
    fileInputRef.current?.click();
  };
  const toast = useToast();
  const { id } = useParams();
  const { isOpen, onToggle } = useDisclosure();
  const deleteModal = useDisclosure();
  const [document, setDocument] = useState<UploadAttachments.Document>();
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Stack gap={0}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={0}
        border={"1px solid #ddd"}
        bg={"#f2f3f4"}
        onClick={onToggle}
      >
        <IconButton
          aria-label={"sum-button"}
          icon={isOpen ? <MdRemove /> : <MdAdd />}
          bg={"transparent"}
          color={"black"}
          _hover={{
            bg: "transparent",
          }}
        />
        <Text
          fontWeight={"600"}
          fontSize=".9975rem"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.black",
          }}
        >
          {type}
        </Text>
      </Stack>
      <Stack marginBottom={"10px"} border={"1px solid #ddd"}>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p=" 20px 30px "
            color="black"
            mt="2"
            rounded="md"
            shadow="md"
            border={"none"}
          >
            <Stack direction={"row"} justifyContent={"space-between"} gap={0}>
              <Text
                fontSize=".8125rem"
                fontWeight="700"
                _light={{
                  color: "customColor.black7",
                }}
              >
                {progress}
              </Text>

              <Stack>
                <Input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => {
                    if (e.target.files) {
                      const file = e.target.files[0];
                      const formData = new FormData();
                      formData.append("docType", "EMPLOYEE_DOCUMENT");
                      formData.append("file", file);
                      formData.append("empId", id!);
                      setIsUploading(true);
                      UploadAttachments.UploadAttachment(
                        formData,
                        (success) => {
                          setFileUrl(success.url);
                          setFileName(file.name);
                          UploadAttachments.PostDocument(
                            id!,
                            {
                              documentType: docType,
                              fileName: file.name,
                              fileUrl: success.url,
                            },
                            (success) => {
                              setGetAllDocuments([...GetAllDocuments, success]);
                              toast({
                                title: "Document uploaded successfully",
                                status: "success",
                              });
                              setIsUploading(false);
                              setLastUpdated(Date.now());
                            },
                            (error) => {
                              toast({
                                title: "Error uploading document",
                                status: "error",
                                description: error,
                              });
                              setIsUploading(false);
                            }
                          );
                        },
                        (err) => {
                          toast({
                            title: "Error uploading document",
                            status: "error",
                            description: err,
                          });
                          setIsUploading(false);
                        }
                      );
                      e.target.value = "";
                    }
                  }}
                  style={{ display: "none" }}
                />
                <Button
                  variant="link"
                  _light={{
                    color: "primary.950",
                  }}
                  onClick={handleAttachClick}
                  display={remainingDocuments > 0 ? "block" : "none"}
                  isDisabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Attach document"}
                </Button>
              </Stack>
            </Stack>
            <Stack padding={"10px"}></Stack>
            {filteredDocuments.length > 0 && (
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h="29.5px">
                  <Tr
                    backgroundColor="#f5f6f8"
                    py="0"
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    _light={{
                      color: "customColor.black",
                    }}
                  >
                    <Th
                      px="12px"
                      py="0"
                      borderBottomColor="#f5f6f8"
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="14.4px"
                      textTransform="none"
                    >
                      Type
                    </Th>
                    <Th
                      px="12px"
                      py="0"
                      borderBottomColor="#f5f6f8"
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="14.4px"
                      textTransform="none"
                    >
                      File Name
                    </Th>

                    <Th
                      px="12px"
                      py="0"
                      borderBottomColor="#f5f6f8"
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="14.4px"
                      textTransform="none"
                    >
                      Date Received
                    </Th>
                    <Th
                      px="12px"
                      py="0"
                      borderBottomColor="#f5f6f8"
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="14.4px"
                      textTransform="none"
                      textAlign={"center"}
                    >
                      Download Document
                    </Th>
                    <Th
                      px="12px"
                      py="0"
                      borderBottomColor="#f5f6f8"
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="14.4px"
                      textTransform="none"
                    ></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr py="12px">
                    <Td
                      px="12px"
                      fontSize="14.4px"
                      fontWeight={600}
                      lineHeight={1.5}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor="#f6f6f6"
                    >
                      {type}
                    </Td>
                    <Td
                      px="12px"
                      fontSize="14.4px"
                      fontWeight={600}
                      lineHeight={1.5}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor="#f6f6f6"
                    >
                      {filteredDocuments.map((doc, index) => (
                        <Stack key={index}>
                          <Text flex={1}>{doc.fileName}</Text>
                        </Stack>
                      ))}
                    </Td>

                    <Td
                      px="12px"
                      fontSize="14.4px"
                      fontWeight={600}
                      lineHeight={1.5}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor="#f6f6f6"
                    >
                      {filteredDocuments?.map((doc, index) => (
                        <Stack key={index}>
                          <Text flex={1}>
                            {moment(doc?.createdAt).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </Text>
                        </Stack>
                      ))}
                    </Td>
                    <Td
                      px="12px"
                      fontSize="14.4px"
                      fontWeight={600}
                      lineHeight={1.5}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor="#f6f6f6"
                    >
                      {filteredDocuments.map((doc, index) => (
                        <Stack key={index}>
                          {/* <Link
                            href={doc.fileUrl}
                            isExternal
                            flex={1}
                            _light={{
                              color: "primary.950",
                            }}
                          >
                            {doc.fileName}
                          </Link> */}
                          <Button
                            variant="link"
                            _light={{
                              color: "primary.950",
                            }}
                            onClick={() => {
                              window.open(doc.fileUrl, "_blank");
                            }}
                          >
                            Download
                          </Button>
                        </Stack>
                      ))}
                    </Td>
                    <Td
                      px="12px"
                      fontSize="14.4px"
                      fontWeight={600}
                      lineHeight={1.5}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      _light={{
                        color: "customColor.black",
                      }}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor="#f6f6f6"
                    >
                      {filteredDocuments.map((doc, index) => (
                        <Stack>
                          <Icon
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                            borderColor={"borders.tableBorder"}
                            bg={"#fff"}
                            w="25px"
                            h="25px"
                            px="5px"
                            py="5px"
                            as={MdDelete}
                            fontSize="22px"
                            color="#6B7280"
                            _hover={{
                              color: "#3454d1",
                            }}
                            onClick={() => {
                              setDocument(doc);
                              deleteModal.onOpen();
                            }}
                          />
                          {/* <IconButton
                            aria-label={"delete-doc"}
                            bg="transparent"
                            icon={<MdDelete size={24} color="#A0AEC0" />}
                            _hover={{ bg: "transparent" }}
                            onClick={() => {
                              setDocument(doc);
                              deleteModal.onOpen();
                            }}
                          /> */}
                        </Stack>
                      ))}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            )}
          </Box>
        </Collapse>
      </Stack>
      <DeleteDocumentModal
        GetAllDocuments={GetAllDocuments}
        empId={document?.empId!}
        fileName={document?.fileName!}
        id={document?.id!}
        setGetAllDocuments={setGetAllDocuments}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        setLastUpdated={setLastUpdated}
      />
    </Stack>
  );
}
