import { axiosInstanceTime } from "./axiosInstanceTime";

declare module LocationApi {
  type area = {
    id?: string;
    name: string;
    color?: string;
    locationId?: string;
  };
  type Coordinates = {
    latitude: string;
    longitude: string;
  };
  type Location = {
    id?: string;
    name: string;
    business_id: string;
    code: string;
    address: string;
    addressCoordinates: Coordinates;
    areas: Array<area>;
  };
}
module LocationApi {
  const API_BASE_URL = "/locations";
  export function AddLocation(
    data: Location,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_BASE_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchLocations(
    success: (data: Array<Location>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_BASE_URL}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchLocationById(
    id: string,
    success: (data: Location) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateLocation(
    id: string,
    data: Partial<Location>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .put(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteLocation(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { LocationApi };
