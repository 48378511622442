import {
  Box,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import GradientPickerPopover from "react-best-gradient-color-picker";
import { FaTrash } from "react-icons/fa";
import { MdAdd } from "react-icons/md";

interface ColorGradientPickerProps {
  onChange: (colors: string[]) => void;
  initialMode?: "color" | "gradient";
}

export default function ColorGradientPicker({
  onChange,
  initialMode = "gradient",
}: ColorGradientPickerProps) {
  const [isGradientMode, setIsGradientMode] = useState(true);
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [value, setValue] = useState(
    "linear-gradient(90deg, #ff9a9e 0%, #fad0c4 100%)"
  );

  // Add color/gradient to the list
  const addColorToPalette = () => {
    setSelectedColors([...selectedColors, value]); // Add the current value to the selected colors array
    onChange([...selectedColors, value]);
  };

  // Delete a color/gradient from the list
  const deleteColor = (index: number) => {
    const newColors = selectedColors.filter((_, i) => i !== index);
    setSelectedColors(newColors); // Update the selected colors array
    onChange(newColors);
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="md" w={282}>
      {isGradientMode && (
        <VStack spacing={4} align="stretch" width="100%">
          <GradientPickerPopover
            value={value} // Use 'value' prop to represent the current state of the gradient
            onChange={setValue} // Update value on change
            width={250}
            height={150}
          />
        </VStack>
      )}
      <HStack justifyContent="space-between" mt={4} w={230} mb={4}>
        <Text fontWeight="bold" fontSize="14px">
          Selected Colors/Gradients:
        </Text>
        <Box>
          <IconButton
            borderRadius="50%"
            aria-label="Add Gradient"
            icon={<MdAdd />}
            onClick={addColorToPalette}
            size="sm"
            isRound={true}
          />
        </Box>
      </HStack>

      {selectedColors.length > 0 ? (
        <SimpleGrid columns={6} spacing={2} w={230}>
          {selectedColors.map((color, index) => (
            <Flex
              key={index}
              w="30px"
              bgGradient={color.includes("gradient") ? color : undefined} // For gradients
              bg={color.includes("gradient") ? undefined : color} // For solid colors
              borderRadius="md"
              border="1px solid #ccc"
              p={1}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center" // Adjust to put delete icon on the right side
              color="white"
              fontSize="12px"
              height="30px"
            >
              {/* {color} */}
              <IconButton
                aria-label="Delete color"
                icon={<FaTrash />}
                size="18px"
                colorScheme="red"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
                onClick={() => deleteColor(index)} // Delete the color
              />
            </Flex>
          ))}
        </SimpleGrid>
      ) : (
        <Box>
          <Text>No colors or gradients selected</Text>
        </Box>
      )}
    </Box>
  );
}
