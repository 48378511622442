import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ResponsiveValue,
  SimpleGrid,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { TextAlign } from "chart.js";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PayrollCalendar } from "../../Api/PayRollCalendar";
import { PayRun } from "../../Api/PayRun";
import { PayslipsModule } from "../../Api/PaySlips";
import { PaginationControl } from "../PayrollEmployee/PaginationControl/PaginationControl";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import { Header } from "./Common/Header";
import { DeleteRun } from "./Modal/DeleteRun";
import { PostPayRun } from "./Modal/PostPayRun";
import { ResetPayRun } from "./Modal/ResetPayRun";

export function PayRunDetail() {
  let location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const nextPayDate = location.state?.nextPayDate;
  const endDate = location.state?.endDate;
  const calenderName = location.state?.calenderName;
  const payPeriod = location.state?.payPeriod;
  const status = location.state?.status;
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [employeePayRun, setEmployeePayRun] =
    useState<Array<PayRun.PayRunById>>();
  const [currentPage, setCurrentPage] = useState<number>(
    defaultPage ? +defaultPage : 1
  );
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [isLeftHovered, setIsLeftHovered] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    defaultLimit ? +defaultLimit : 10
  );
  const [isRightHovered, setIsRightHovered] = useState(false);
  const totalPages = Math.ceil((employeePayRun?.length ?? 0) / itemsPerPage);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let filteredData = data?.filter((emp: any) =>
      `${emp.employee.firstName} ${emp.employee.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    return filteredData?.slice(startIndex, endIndex);
  };

  const paginatedData = paginateData(employeePayRun);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };

  // Handlers
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const [calendarData, setCalendarData] = useState<
    Array<PayrollCalendar.Calendar>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [paymentDate, setPaymentDate] = useState<string>("");
  const [fetchPayRunData, setFetchPayRunData] = useState<PayRun.payRunData>();
  const [note, setNote] = useState<string>("");
  useEffect(() => {
    PayRun.FetchPayRunById(
      id!,
      (data) => {
        setFetchPayRunData(data);
        setEmployeePayRun(data?.payslips);
        setPaymentDate(data?.paymentDate);
        setNote(data?.note);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated, itemsPerPage, currentPage]);
  useEffect(() => {
    navigate(`?page=${currentPage}&limit=${itemsPerPage}`);
  }, [itemsPerPage, currentPage]);
  useEffect(() => {
    PayrollCalendar.FetchCalendar(
      (data) => {
        setCalendarData(data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  const postPayRun = useDisclosure();
  const deletePayRun = useDisclosure();
  const resetPayRun = useDisclosure();
  const [isLoadingInclude, setIsLoadingInclude] = useState<boolean>(false);
  const calculatedNetPay = (index: number) => {
    if (employeePayRun && employeePayRun[index]) {
      const totalEarnings = employeePayRun[index].totalEarnings ?? 0;
      const totalTax = employeePayRun[index].totalTax ?? 0;
      const totalRetirements = employeePayRun[index].totalRetirements ?? 0;
      const totalReimbursements =
        employeePayRun[index].totalReimbursements ?? 0;

      const totalDeductions = employeePayRun[index].totalDeductions ?? 0;
      return totalEarnings - totalDeductions - totalTax + totalReimbursements;
    }
  };

  const totalEarningsCalc = useCallback(() => {
    let totalEarnings = 0;
    employeePayRun?.forEach((emp, i) => {
      if (emp?.isIncludeInPayRun) {
        totalEarnings += emp.totalEarnings;
      } else {
        totalEarnings += 0;
      }
    });
    return totalEarnings;
  }, [employeePayRun]);

  const totalTaxsCalc = useCallback(() => {
    let totalTaxs = 0;
    employeePayRun?.forEach((emp, i) => {
      if (emp?.isIncludeInPayRun) {
        totalTaxs += emp.totalTax;
      } else {
        totalTaxs += 0;
      }
    });
    return totalTaxs;
  }, [employeePayRun]);

  const totalDeductionsCalc = useCallback(() => {
    let totalDeductions = 0;
    employeePayRun?.forEach((emp, i) => {
      if (emp?.isIncludeInPayRun) {
        totalDeductions += emp.totalDeductions;
      } else {
        totalDeductions += 0;
      }
    });
    return totalDeductions;
  }, [employeePayRun]);

  const totalReimbursementsCalc = useCallback(() => {
    let totalReimbursements = 0;
    employeePayRun?.forEach((emp, i) => {
      if (emp?.isIncludeInPayRun) {
        totalReimbursements += emp.totalReimbursements;
      } else {
        totalReimbursements += 0;
      }
    });
    return totalReimbursements;
  }, [employeePayRun]);
  const totalRetirementsCalc = useCallback(() => {
    let totalRetirements = 0;
    employeePayRun?.forEach((emp, i) => {
      if (emp?.isIncludeInPayRun) {
        totalRetirements += emp.totalRetirements;
      } else {
        totalRetirements += 0;
      }
    });
    return totalRetirements;
  }, [employeePayRun]);
  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
    setSelectedPage(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            marginRight={"10px"}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={currentPage === i ? "#3454D1" : "#fff"}
            color={currentPage === i ? "white" : "black"}
            _hover={{
              backgroundColor: "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      // Always show the first page
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          marginRight={"10px"}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={currentPage === 1 ? "#3454D1" : "#fff"}
          color={currentPage === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: currentPage === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      // Show ellipsis if currentPage is greater than 2
      if (currentPage > 2) {
        pageButtons.push(
          <Text key="left-dots" marginRight={"10px"}>
            ...
          </Text>
        );
      }

      // Show the currentPage and its adjacent pages
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            marginRight={"10px"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={currentPage === i ? "#3454D1" : "#fff"}
            color={currentPage === i ? "white" : "black"}
            _hover={{
              backgroundColor: "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      // Show ellipsis if currentPage is less than totalPages - 1
      if (currentPage < totalPages - 1) {
        pageButtons.push(
          <Text key="right-dots" marginRight={"10px"}>
            ...
          </Text>
        );
      }

      // Always show the last page
      pageButtons.push(
        <IconButton
          key={totalPages}
          border={"1px solid #e8eaf2"}
          marginRight={"10px"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${totalPages}`}
          backgroundColor={currentPage === totalPages ? "#3454D1" : "#fff"}
          color={currentPage === totalPages ? "white" : "black"}
          _hover={{
            backgroundColor: currentPage === totalPages ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{totalPages}</Text>}
          onClick={() => handlePageChange(totalPages)}
        />
      );
    }

    return pageButtons;
  };
  const TotalNetPay =
    Number(totalEarningsCalc()) -
    Number(totalTaxsCalc()) -
    Number(totalDeductionsCalc()) +
    Number(totalReimbursementsCalc());
  return (
    <Box
      paddingBottom={"50px"}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Container
        h="64px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        maxW="100%"
        justifyContent={"center"}
        padding={"0px 20px"}
      >
        <Breadcrumb
          separator={<ChevronRightIcon color="gray.500" />}
          paddingTop={"5px"}
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/app/payroll/PayEmployee"
              fontSize={"11px"}
              color={"#3454d1"}
            >
              Pay Employee
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              href=""
              fontSize={"11px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              padding={"0px 20px 0px 0px"}
            >
              {`${fetchPayRunData?.payrollCalendar?.name} ending ${moment(
                fetchPayRunData?.endDate
              ).format("D MMM YYYY")}`}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"24px"}
          margin={"0px 4px 0px 0px"}
        >
          {`${fetchPayRunData?.payrollCalendar?.name} ending ${moment(
            fetchPayRunData?.endDate
          ).format("D MMM YYYY")}`}
        </Heading>
        {/* <Divider padding="10px" orientation="horizontal" /> */}
      </Container>
      <Header
        totalEarnings={totalEarningsCalc()}
        totalTaxs={totalTaxsCalc()}
        totalRetirementFunds={totalRetirementsCalc()}
        totalDeductions={totalDeductionsCalc()}
        totalNetPay={TotalNetPay}
        status={fetchPayRunData?.status}
        itemWidth={"100%"}
        setPaymentDate={setPaymentDate}
        paymentDate={paymentDate}
      />
      <Stack padding="20px 20px 0px 20px">
        <Container maxW="100%" padding={"0px"}>
          <Heading
            fontSize={"16px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 15px"}
          >
            {employeePayRun?.length} employee
          </Heading>

          <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
            <Stack
              boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              border="1px"
              borderColor="#f6f6f6"
              borderRadius={"4px"}
              gap={0}
            >
              <Stack
                p={"25px"}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _light={{
                  bgColor: "customColor.white",
                }}
              >
                <Box w={"100%"}>
                  <PaginationControl
                    limit={itemsPerPage}
                    setLimit={setItemsPerPage}
                    setPageNumber={setCurrentPage}
                  />
                </Box>
                <Stack gap={0} direction={"row"} alignItems={"center"}>
                  <Text
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.gray.6b7885",
                    }}
                  >
                    Search:
                  </Text>
                  <InputGroup ml={[0, "7px"]}>
                    <Input
                      type="text"
                      placeholder="Search"
                      borderRadius={"3px"}
                      h={["auto", "40px"]}
                      _focusVisible={{
                        border: "1px solid grey",
                      }}
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                </Stack>
              </Stack>
              <Box
                overflowX={"auto"}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
              >
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      borderTopWidth={"1px"}
                      borderTopColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      {/* <Th
                        w="20px"
                        borderBottomColor="#f5f6f8"
                        px="12px"
                        minW="50px"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                        />
                      </Th> */}
                      {[
                        {
                          name: "Employee ID",
                          textAlign: "left",
                          paddingValue: "8px 26px 8px 30px",
                        },
                        {
                          name: "First Name",
                          textAlign: "left",
                          paddingValue: "8px 26px 8px 30px",
                        },
                        {
                          name: "Last Name",
                          textAlign: "left",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        // {
                        //   name: "Employee Group",
                        //   textAlign: "left",
                        //   paddingValue: "8px 26px 8px 15px",
                        // },
                        {
                          name: "Last Edited",
                          textAlign: "left",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Earnings",
                          textAlign: "center",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Deductions",
                          textAlign: "center",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Tax",
                          textAlign: "center",
                          paddingValue: "8px 26px 8px 15px",
                        },

                        {
                          name: "Net Pay",
                          textAlign: "center",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "View Details",
                          textAlign: "center",
                          paddingValue: "8px 26px 8px 15px",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            w={item?.name === "Employee ID" ? "50px" : "auto"}
                            key={i}
                            padding={item.paddingValue}
                            textAlign={
                              item.textAlign as
                                | ResponsiveValue<TextAlign>
                                | undefined
                            }
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            fontSize="12px"
                            textTransform="none"
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                      <Th
                        padding={"8px 26px 8px 15px"}
                        textAlign={"center"}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<IoMdArrowDropdown />}
                            padding={"3px 5px 6px 6px"}
                            margin={0}
                            fontSize={"11px"}
                            height={"21px"}
                            maxW={"70px"}
                            _dark={{
                              bgColor: "customColor.dark.50",
                            }}
                            _light={{
                              bgColor: "customColor.white",
                            }}
                            color={"primary.950"}
                            borderRadius="3px"
                            border={"1px solid"}
                            borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                            _hover={{
                              bg: "#f4f5f6",
                              color: "primary.800",
                            }}
                            _active={{
                              bg: "#f4f5f6",
                              color: "primary.800",
                            }}
                          >
                            Included
                          </MenuButton>
                          <MenuList padding={0} w={"184px"}>
                            <MenuItem
                              fontSize={"12px"}
                              color={"primary.950"}
                              padding={"13px 30px 12px 10px"}
                              h={"41px"}
                              _hover={{
                                bg: "#e6f5fa",
                              }}
                              onClick={() => {
                                const allIncludedIds = employeePayRun?.map(
                                  (emp) => emp.id
                                );
                                PayslipsModule.IncludeInPayRun(
                                  {
                                    payRunId: id!,
                                    payslipIds: allIncludedIds,
                                    isIncludeInPayRun: true,
                                  },
                                  (res) => {
                                    setLastUpdated(Date.now());
                                  },
                                  (err) => {
                                    toast({
                                      title: err,
                                      status: "error",
                                    });
                                  }
                                );
                              }}
                            >
                              Include all
                            </MenuItem>
                            <MenuItem
                              fontSize={"12px"}
                              color={"primary.950"}
                              padding={"13px 30px 12px 10px"}
                              h={"41px"}
                              _hover={{
                                bg: "#e6f5fa",
                              }}
                              onClick={() => {
                                const allIncludedIds = employeePayRun?.map(
                                  (emp) => emp.id
                                );
                                PayslipsModule.IncludeInPayRun(
                                  {
                                    payRunId: id!,
                                    payslipIds: allIncludedIds,
                                    isIncludeInPayRun: false,
                                  },
                                  (res) => {
                                    setLastUpdated(Date.now());
                                  },
                                  (err) => {
                                    toast({
                                      title: err,
                                      status: "error",
                                    });
                                  }
                                );
                              }}
                            >
                              Exclude all
                            </MenuItem>
                            <MenuItem
                              fontSize={"12px"}
                              color={"primary.950"}
                              padding={"13px 30px 12px 10px"}
                              h={"41px"}
                              _hover={{
                                bg: "#e6f5fa",
                              }}
                              // onClick={() => handleSelectOption("PaySlips")}
                            >
                              Exclude All Empty PaySlips
                            </MenuItem>
                            <MenuItem
                              fontSize={"12px"}
                              color={"primary.950"}
                              padding={"13px 30px 12px 10px"}
                              h={"41px"}
                              _hover={{
                                bg: "#e6f5fa",
                              }}
                              onClick={() => {
                                setEmployeePayRun((prev) => {
                                  const sortedArray =
                                    prev?.sort(
                                      (a, b) =>
                                        Number(b.isIncludeInPayRun) -
                                        Number(a.isIncludeInPayRun)
                                    ) ?? [];
                                  return [...sortedArray];
                                });
                              }}
                            >
                              Sort by included
                            </MenuItem>
                            <MenuItem
                              fontSize={"12px"}
                              color={"primary.950"}
                              padding={"13px 30px 12px 10px"}
                              h={"41px"}
                              _hover={{
                                bg: "#e6f5fa",
                              }}
                              onClick={() => {
                                setEmployeePayRun((prev) => {
                                  const sortedArray =
                                    prev?.sort(
                                      (a, b) =>
                                        Number(a.isIncludeInPayRun) -
                                        Number(b.isIncludeInPayRun)
                                    ) ?? [];
                                  return [...sortedArray];
                                });
                              }}
                            >
                              Sort by excluded
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Th>
                    </Tr>
                  </Thead>
                  <>
                    {/* <Tbody>
                      {employeePayRun ? (
                        employeePayRun.map((emp, i) => { */}
                    <Tbody>
                      {paginatedData ? (
                        paginatedData.map((emp: any, i: any) => {
                          return (
                            <Tr
                              key={i}
                              _hover={{
                                bgColor: "#f2f3f8",
                                cursor: "pointer",
                              }}
                              style={{
                                opacity: emp?.isIncludeInPayRun ? 1 : 0.6,
                                backgroundColor: emp?.isIncludeInPayRun
                                  ? "none"
                                  : "gray.200",
                              }}
                              onClick={(e) => {
                                navigate(
                                  `/app/payroll/PayRunPaySlip/?payslipId=${emp.id}&employeeId=${emp.employeeId}&payRunId=${id}`,
                                  {
                                    state: {
                                      totalEarnings: emp.totalEarnings,
                                      totalTaxs: emp.totalTax,
                                      totalProvidentFunds: emp.totalRetirements,
                                      totalNetPay: 0,
                                      calenderName: calenderName,
                                      endDate: endDate,
                                      nextPayDate: nextPayDate,
                                      firstName: emp.employee.firstName,
                                      lastName: emp.employee.lastName,
                                      employeePayRun: employeePayRun,
                                      payPeriod: payPeriod,
                                      // allNames: allNames,
                                    },
                                  }
                                );
                              }}
                            >
                              {/* <Td
                                px="12px"
                                fontSize="14.4px"
                                fontWeight={400}
                               _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black",
                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor="#f6f6f6"
                              >
                                <Checkbox
                                  size="md"
                                  borderColor="#adb5bd"
                                  bgColor="white"
                                  isDisabled={!emp?.isIncludeInPayRun}
                                />
                              </Td> */}
                              <Td
                                padding="15px 15px 15px 30px"
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                fontWeight={500}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>{emp?.employee?.uniqueId}</Text>
                              </Td>
                              <Td
                                padding="15px 15px 15px 30px"
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                fontWeight={500}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text
                                  textDecoration={
                                    !emp?.isIncludeInPayRun
                                      ? "line-through"
                                      : "none"
                                  }
                                >
                                  {emp.employee.firstName}
                                </Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text
                                  textDecoration={
                                    !emp?.isIncludeInPayRun
                                      ? "line-through"
                                      : "none"
                                  }
                                >
                                  {emp.employee.lastName}
                                </Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>
                                  {moment(emp?.updatedAt).format(
                                    "DD MMM YYYY HH:mm:ss"
                                  )}
                                </Text>
                              </Td>
                              {/* <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text></Text>
                              </Td> */}
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                textAlign={"center"}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>
                                  {emp?.isIncludeInPayRun
                                    ? Math.round(
                                        emp?.totalEarnings
                                      ).toLocaleString()
                                    : 0}
                                </Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                textAlign={"center"}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>
                                  {emp?.isIncludeInPayRun
                                    ? Math.round(
                                        emp.totalDeductions
                                      ).toLocaleString()
                                    : 0}
                                </Text>
                              </Td>
                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                textAlign={"center"}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>
                                  {emp?.isIncludeInPayRun
                                    ? Math.round(emp.totalTax).toLocaleString()
                                    : 0}
                                </Text>
                              </Td>

                              <Td
                                padding="15px"
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                textAlign={"center"}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <Text>
                                  {emp?.isIncludeInPayRun
                                    ? Math.round(
                                        calculatedNetPay(i)!
                                      ).toLocaleString()
                                    : 0}
                                </Text>
                              </Td>
                              <Td
                                padding="10px"
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                textAlign={"center"}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                              >
                                <HStack
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="50%"
                                    // isDisabled={!emp?.isIncludeInPayRun}
                                    borderStyle={"solid"}
                                    borderWidth={"1px"}
                                    borderColor={"borders.tableBorder"}
                                    bg={"#fff"}
                                    w="25px"
                                    h="25px"
                                    px="5px"
                                    py="5px"
                                    as={MdOutlineRemoveRedEye}
                                    fontSize="22px"
                                    color="#6B7280"
                                    _hover={{
                                      color: "#3454d1",
                                    }}
                                    onClick={(e) => {
                                      navigate(
                                        `/app/payroll/PayRunPaySlip/?payslipId=${emp.id}&employeeId=${emp.employeeId}&payRunId=${id}`,
                                        {
                                          state: {
                                            totalEarnings: emp.totalEarnings,
                                            totalTaxs: emp.totalTax,
                                            totalProvidentFunds:
                                              emp.totalRetirements,
                                            totalNetPay: 0,
                                            calenderName: calenderName,
                                            endDate: endDate,
                                            nextPayDate: nextPayDate,
                                            firstName: emp.employee.firstName,
                                            lastName: emp.employee.lastName,
                                            employeePayRun: employeePayRun,
                                            payPeriod: payPeriod,
                                            // allNames: allNames,
                                          },
                                        }
                                      );
                                    }}
                                  />
                                </HStack>
                              </Td>
                              <Td
                                padding="15px"
                                textAlign={"center"}
                                fontWeight={500}
                                fontSize="13.44px"
                                color={"customColor.black7"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                lineHeight={1.5}
                                borderTopWidth="1px"
                                borderBottomWidth="0px"
                                borderTopColor={"borders.tableBorder"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {isLoadingInclude ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <Checkbox
                                    size="md"
                                    borderColor="#adb5bd"
                                    bgColor="white"
                                    isChecked={emp?.isIncludeInPayRun}
                                    onChange={(e) => {
                                      setIsLoadingInclude(true);
                                      PayslipsModule.IncludeInPayRun(
                                        {
                                          payRunId: id!,
                                          payslipIds: [emp.id],
                                          isIncludeInPayRun: e.target.checked,
                                        },
                                        (res) => {
                                          setLastUpdated(Date.now());

                                          setIsLoadingInclude(false);
                                        },
                                        (err) => {
                                          toast({
                                            title: err,
                                            status: "error",
                                          });
                                          setIsLoadingInclude(false);
                                        }
                                      );
                                    }}
                                  />
                                )}
                              </Td>
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td>No data found!</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </>
                </Table>
                <Stack borderTop={"1px solid #dcdee4 "}>
                  <HStack
                    marginTop={"9.35px"}
                    padding={"25px"}
                    fontSize={"13px"}
                    _light={{
                      color: "customColor.black2",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontWeight={"normal"}
                      color={"customColor.6b7885"}
                      _dark={{
                        color: "customColor.dark.200",
                      }}
                      fontSize={"11px"}
                    >
                      Showing {itemsPerPage * (selectedPage - 1) + 1} to{" "}
                      {itemsPerPage + itemsPerPage * (selectedPage - 1)} of{" "}
                      {employeePayRun?.length} entries
                    </Text>
                    <HStack
                      spacing="13px"
                      justify={{ xs: "center", md: "end" }}
                    >
                      <IconButton
                        aria-label="left"
                        backgroundColor="transparent"
                        border={"1px solid #e8eaf2"}
                        borderRadius={"full"}
                        _hover={{
                          backgroundColor: "#3454D1",
                        }}
                        onMouseEnter={() => setIsLeftHovered(true)}
                        onMouseLeave={() => setIsLeftHovered(false)}
                        icon={
                          <FaArrowLeft
                            size={15}
                            color={
                              isLeftHovered
                                ? "#FFFFFF"
                                : selectedPage <= 1 ||
                                  selectedPage > currentPage
                                ? "#828ea0"
                                : "#283c50"
                            }
                            style={{ transition: "color 0.2s" }}
                          />
                        }
                        marginRight={"10px"}
                        onClick={() => {
                          setCurrentPage((prev) => Math.max(prev - 1, 1));
                          setSelectedPage((prev) => Math.max(prev - 1, 1));
                        }}
                        isDisabled={
                          currentPage <= 1 || currentPage > selectedPage
                        }
                      />

                      {renderPageNumbers()}

                      <IconButton
                        aria-label="right"
                        backgroundColor="transparent"
                        border={"1px solid #e8eaf2"}
                        borderRadius={"full"}
                        _hover={{
                          backgroundColor: "#3454D1",
                        }}
                        onMouseEnter={() => setIsRightHovered(true)}
                        onMouseLeave={() => setIsRightHovered(false)}
                        icon={
                          <FaArrowRight
                            size={15}
                            color={
                              isRightHovered
                                ? "#FFFFFF"
                                : currentPage === totalPages
                                ? "#828ea0"
                                : "#283c50"
                            }
                            style={{ transition: "color 0.2s" }}
                          />
                        }
                        marginRight={"10px"}
                        onClick={() => {
                          setCurrentPage((prev) =>
                            Math.min(prev + 1, totalPages)
                          );
                          setSelectedPage((prev) =>
                            Math.min(prev + 1, totalPages)
                          );
                        }}
                        isDisabled={currentPage === totalPages}
                      />
                    </HStack>
                  </HStack>
                </Stack>
              </Box>
            </Stack>
          </Skeleton>
        </Container>
        <Container maxW="100%" padding={"0px"} paddingTop={"40px"}>
          <Stack padding={"20px 0px 20px 0px"}>
            <Stack
              border={"1px solid #e5e5e5"}
              padding={"15px 10px"}
              bg={"#fff"}
            >
              <Button
                variant={"link"}
                color={"#3454d1"}
                alignSelf={"flex-start"}
                leftIcon={<AiOutlinePlus />}
                fontWeight={"400"}
                fontSize={"12px"}
                padding={0}
              >
                Add a payslip message
              </Button>

              <Textarea
                placeholder="Add a note"
                fontSize={"12px"}
                value={note}
                border="1px solid #e5e7eb"
                _hover={{
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _focus={{
                  borderWidth: "1px",

                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                _focusVisible={{
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                onChange={(e) => setNote(e.target.value)}
                padding={"10px"}
                borderRadius={"3px"}
                color={"customColor.black7"}
              />
            </Stack>
          </Stack>

          <SimpleGrid columns={{ xs: 1, md: 2 }} spacing={0}>
            <HStack justifyContent="flex-start">
              <Button
                margin={"0px 10px 15px 0"}
                variant="solid"
                fontSize={"13px"}
                padding={"10px 15px"}
                borderRadius={"3px"}
                border={"1px solid #FDEDED"}
                bg={"#FDEDED"}
                color={"#EA4D4D"}
                minW={"75px"}
                _hover={{
                  border: "1px solid #EA4D4D",
                  bg: "#EA4D4D",
                  color: "#fff",
                }}
                onClick={() => deletePayRun.onOpen()}
              >
                Delete Pay Run
              </Button>
              <Button
                margin={"0px 10px 15px 0"}
                backgroundColor={"white"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454d1",
                }}
                onClick={() => resetPayRun.onOpen()}
              >
                Reset Pay Run
              </Button>
            </HStack>
            <HStack justifyContent={{ xs: "flex-Start", md: "flex-end" }}>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => postPayRun.onOpen()}
              >
                Post pay run
              </Button>

              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => navigate("/app/payroll/PayEmployee")}
              >
                Close
              </Button>
            </HStack>
          </SimpleGrid>
        </Container>
      </Stack>
      <Box maxW="100%" padding={"0px 20px"}>
        <Stack
          marginX={"auto"}
          border={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          _hover={{
            _light: {
              bgColor: "customColor.white",
            },
          }}
          maxW="100%"
          marginRight={"auto"}
          h={"56px"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
          borderRadius={"5px"}
        >
          <HistoryAccordion />
        </Stack>
      </Box>

      <PostPayRun
        isOpen={postPayRun.isOpen}
        onClose={postPayRun.onClose}
        payrunId={id}
        calenderName={fetchPayRunData?.payrollCalendar?.name}
        endDate={fetchPayRunData?.endDate}
        nextPayDate={nextPayDate}
        note={note}
      />
      <DeleteRun
        isOpen={deletePayRun.isOpen}
        onClose={deletePayRun.onClose}
        id={id!}
      />
      <ResetPayRun
        isOpen={resetPayRun.isOpen}
        onClose={resetPayRun.onClose}
        id={id!}
        setLastUpdated={setLastUpdated}
      />
    </Box>
  );
}
