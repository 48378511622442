import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { PayRun } from "../../../../Api/PayRun";

interface EmailPaySlipProps {
  isOpen: boolean;
  onClose: () => void;
  //   empId?: string;
  //   setLastUpdated?: (time: number) => void;
}
interface CheckedItems {
  [key: string]: boolean;
}
export default function EmailPaySlip({ isOpen, onClose }: EmailPaySlipProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const toast = useToast();
  const { id } = useParams();
  const { employeesList } = useBusinessContext() || { employeesList: [] };
  const [employeePayRun, setEmployeePayRun] =
    useState<Array<PayRun.PayRunById>>();

  useEffect(() => {
    PayRun.FetchPayRunById(
      id!,
      (data) => {
        setEmployeePayRun(data?.payslips);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);

  const toggleAllCheckboxes = () => {
    if (employeePayRun) {
      const newCheckedState: CheckedItems = {};
      const newState = !allChecked;
      employeePayRun.forEach((paySlip, index) => {
        newCheckedState[index.toString()] = newState;
      });
      setCheckedItems(newCheckedState);
      setAllChecked(newState);
    }
  };

  const toggleCheckbox = (index: string) => {
    const newCheckedState = { ...checkedItems };
    newCheckedState[index] = !newCheckedState[index];
    setCheckedItems(newCheckedState);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxW={"600px"}>
        <Stack gap={0} alignItems={"center"} direction={"row"} padding={0}>
          <ModalHeader
            padding={"15px 5px 15px 15px"}
            flex={1}
            _light={{
              color: "customColor.gray.800",
              bg: "customColor.white",
            }}
            _dark={{ color: "customColor.white", bg: "customColor.dark.100" }}
          >
            Email PaySlips
          </ModalHeader>
        </Stack>
        <ModalCloseButton />
        <ModalBody
          borderTop="1px solid #E5E5E5"
          borderBottom="1px solid #E5E5E5"
          padding={"15px"}
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bg: "customColor.dark.50",
          }}
        >
          <Box>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.gray.800",
                bg: "customColor.white",
              }}
            >
              <b>The selected pay run has been posted.</b> Select which
              employees will receive their Payslip via email..
            </Text>
            <Box overflowX="auto">
              <Table
                variant="simple"
                w="100%"
                border={"1px solid #ddd"}
                borderRadius={"5px"}
              >
                <Thead h="28.4px">
                  <Tr
                    color={"customColor.tableHeaderColor"}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    <Th
                      padding="7px 0px 7px 5px"
                      borderBottomColor="inherit"
                      color={"customColor.tableHeaderColor"}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      maxW="24px"
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        isChecked={allChecked}
                        onChange={toggleAllCheckboxes}
                      />
                    </Th>
                    <Th
                      padding="7px"
                      borderBottomColor="inherit"
                      color={"customColor.tableHeaderBlue"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      minW={"100px"}
                      borderLeft={"1px solid #ddd"}
                    >
                      First Name
                    </Th>
                    <Th
                      padding="7px"
                      borderBottomColor="inherit"
                      color={"customColor.tableHeaderBlue"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      minW={"100px"}
                      borderLeft={"1px solid #ddd"}
                    >
                      Last Name
                    </Th>
                    <Th
                      padding="7px"
                      borderBottomColor="inherit"
                      color={"customColor.tableHeaderBlue"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={600}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      flex={1}
                      borderLeft={"1px solid #ddd"}
                    >
                      Email
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {employeePayRun?.map((paySlip, index) => {
                    const matchedEmployee = employeesList?.find(
                      (emp) =>
                        emp.firstName === paySlip.employee.firstName &&
                        emp.lastName === paySlip.employee.lastName
                    );

                    if (matchedEmployee) {
                      return (
                        <Tr
                          key={index}
                          bgColor={"#E1FFC2"}
                          _hover={{
                            _light: {
                              bgColor: "rgba(0,0,0,.075)",
                            },
                            _dark: {
                              bgColor: "#E1FFC2",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="7px 5px 11px"
                            fontSize="14px"
                            color={"customColor.555"}
                            _dark={{
                              color: "customColor.black",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor="inherit"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={checkedItems[index.toString()]}
                              onChange={() => toggleCheckbox(index.toString())}
                            />
                          </Td>
                          <Td
                            flexWrap={"wrap"}
                            padding="7px 5px 11px"
                            fontSize="14px"
                            color={"customColor.555"}
                            _dark={{
                              color: "customColor.black",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor="inherit"
                            maxW={"100px"} // Update max width here
                          >
                            {matchedEmployee.firstName}
                          </Td>
                          <Td
                            whiteSpace="normal"
                            overflowWrap="break-word"
                            padding="7px 5px 11px"
                            fontSize="14px"
                            color={"customColor.555"}
                            _dark={{
                              color: "customColor.black",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor="inherit"
                            maxW={"100px"} // Update max width here
                          >
                            {matchedEmployee.lastName}
                          </Td>
                          <Td
                            whiteSpace="normal"
                            overflowWrap="break-word"
                            padding="7px 5px 11px"
                            fontSize="14px"
                            color={"customColor.555"}
                            _dark={{
                              color: "customColor.black",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor="inherit"
                          >
                            {matchedEmployee.email}
                          </Td>
                        </Tr>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            margin={"0px 10px 15px 0"}
            padding={"7.52px 12px"}
            fontSize={"12px"}
            colorScheme="primary"
            borderRadius="3px"
            border={"1px solid #3a9101"}
            bg={"linear-gradient(#4fc601,#44aa01)"}
            _hover={{
              border: "1px solid #327d01",
              bg: "linear-gradient(#47b201,#3c9601)",
            }}
            onClick={onClose}
          >
            {isLoading ? "Sending Email" : "Email PaySlips"}
          </Button>
          <Button
            w={"82px"}
            h={"30px"}
            margin={"0px 10px 15px 0px"}
            padding={"7.52px 12px"}
            fontSize={"12px"}
            border={"1px solid #949a9e"}
            bg={"linear-gradient(#b9bcc0,#9fa4a8)"}
            _hover={{
              bg: "linear-gradient(#aeb2b6,#949a9e)",
              border: "1px solid #899094",
            }}
            borderRadius="3px"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
