import { Icon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { CustomInput } from "../../../Common/CustomInput";

export function Contacts() {
  return (
    <Flex justifyContent="center" marginTop="0px">
      <VStack>
        <Box w="100%" h="70px">
          <Text fontSize="24px" fontWeight="bold" paddingTop="40px">
            Contacts
          </Text>
        </Box>
        <Box bg="gray.100" w="100%" h="80px">
          <Stack direction="row" spacing={3} fontSize="13px">
            <ButtonGroup
              isAttached
              variant="outline"
              color="#048abb"
              bgColor="white"
            >
              <Button>Import</Button>
              <Button>Export</Button>
            </ButtonGroup>
            <Button bgColor="white" variant="outline">
              Send statement
            </Button>
            <Button bgColor="#57c40a">Add contact</Button>
          </Stack>
        </Box>

        <Flex alignItems="flex-start" marginTop="2px">
          <Box>
            <HStack>
              <Box h="600px" w="200px" border="1px" borderColor="teal.100">
                <VStack
                  align="start"
                  fontSize="13px"
                  padding="10px"
                  margin="15px"
                  spacing={4}
                >
                  <Link color="#333" href="#">
                    All
                  </Link>
                  <Link color="#333" href="#">
                    Customers
                  </Link>
                  <Link color="#333" href="#">
                    Suppliers
                  </Link>
                  <Link color="#333" href="#">
                    Archived
                  </Link>
                  <Text fontWeight="bold">
                    Groups{" "}
                    <Link paddingLeft="70px" href="#">
                      New
                    </Link>
                  </Text>
                  <Link color="#333" href="#">
                    Training customers
                  </Link>
                  <Text fontWeight="bold">
                    Smart lists{" "}
                    <Link paddingLeft="50px" href="#">
                      New
                    </Link>
                  </Text>
                  <Link color="#333" href="#">
                    Have purchased an item
                  </Link>
                  <Link color="#333" href="#">
                    Outstanding {">"} 30 days
                  </Link>
                  <Link color="#333" href="#">
                    Overdue {">"} 7 days
                  </Link>
                  <Link color="#333" href="#">
                    Paid us (in the last year)
                  </Link>
                </VStack>
              </Box>
              <Box h="600px" w="700px" border="1px" borderColor="teal.100">
                <HStack fontSize="10px" paddingLeft="20px">
                  <Link color="#8296aa">Archive</Link>
                  <Link color="#8296aa">Edit</Link>
                  <InputGroup fontSize="10px">
                    <InputLeftElement>
                      <Icon as={SearchIcon} />
                    </InputLeftElement>
                    <CustomInput
                      input={{
                        w: "150px",
                        size: "sm",
                        fontSize: "15px",
                        variant: "outline",
                        type: "text",
                        border: "1px solid #a6a9b0",
                        borderTopRadius: "3px",
                        alignContent: "center",
                      }}
                      placeholder="Search "
                    />
                  </InputGroup>
                  <Text fontSize="10px" textColor="#8296aa">
                    Sort&nbsp;by{" "}
                  </Text>
                </HStack>

                <Table>
                  <Thead fontWeight="bold" textColor="#999">
                    <Tr>
                      <Th fontSize="10px">CONTACT</Th>
                      <Th fontSize="10px">Email</Th>
                      <Th fontSize="10px">YOU&nbsp;OWE&nbsp;THEM</Th>
                      <Th fontSize="10px">THEY&nbsp;OWE&nbsp;YOU</Th>
                    </Tr>
                  </Thead>
                  <Tbody></Tbody>
                </Table>
                <VStack paddingTop="50px">
                  {/* <Image
             src='https://go.xero.com/assets/6b10fab5a8ecb0f0dff51a07eb9a763c.svg'
             alt='No contacts'/> */}
                  <Text textAlign="center" fontSize="13px" textColor="#8296aa">
                    You don't have any contacts yet.
                    <br /> Add a contact or import a list of existing contacts.
                  </Text>
                  <ButtonGroup
                    flexDirection="row"
                    variant="outline"
                    color="#2baaed"
                    colorScheme="teal.100"
                  >
                    <Button>Add contacts</Button>
                    <Button>Import contacts</Button>
                  </ButtonGroup>
                </VStack>
              </Box>
            </HStack>
          </Box>
        </Flex>
      </VStack>
    </Flex>
  );
}
