import {
  Box,
  Checkbox,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { ReactSelectForInvoiceSettings } from "../../../../Common/ReactSelectForInvoiceSettings";

export function LeftSideComponent() {
  const [showTax, setShowTax] = useState("");
  const ShowTaxOption: Array<{ label: string; value: string }> = [
    {
      label: "tax rates",
      value: "tax rates",
    },
    {
      label: "tax rates over 0%",
      value: "tax rates over 0%",
    },
    {
      label: "tax components",
      value: "tax components",
    },
    {
      label: "tax components over 0%",
      value: "tax components over 0%",
    },
    {
      label: "a single tax subtotal",
      value: "a single tax subtotal",
    },
  ];
  const [showCurrencyConversion, setShowCurrencyConversion] = useState("");
  const ShowCurrencyConversionOption: Array<{
    label: string;
    value: string;
  }> = [
    {
      label: "net amount with tax total",
      value: "net amount with tax total",
    },
    {
      label: "a single tax total",
      value: "a single tax total",
    },
    {
      label: "don't show anything",
      value: "don't show anything",
    },
  ];
  const [showPaymentServicesCreditCard, setShowPaymentServicesCreditCard] =
    useState("");
  const ShowPaymentServicesCreditCardOption: Array<{
    label: string;
    value: string;
  }> = [
    {
      label: "None",
      value: "NONE",
    },
  ];
  const [showPaymentServicesPayPal, setShowPaymentServicesPayPal] =
    useState("");
  const ShowPaymentServicesPayPalOption: Array<{
    label: string;
    value: string;
  }> = [
    {
      label: "DONOTPAYTHISFAKE@fakedemoaccount.com",
      value: "DONOTPAYTHISFAKE@fakedemoaccount.com",
    },
    {
      label: "democo@for-demo-purpose-only.co",
      value: "democo@for-demo-purpose-only.co",
    },
  ];
  const [showPaymentServicesBank, setShowPaymentServicesBank] = useState("");
  const ShowPaymentServicesBankOption: Array<{
    label: string;
    value: string;
  }> = [
    {
      label: "None",
      value: "NONE",
    },
  ];
  return (
    <Stack gap={0} flex={1} paddingRight={"10px"}>
      <Stack direction={"row"} gap={0} paddingTop={"10px"}>
        <Stack
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
          bg={"#fff"}
          padding={"10px"}
          flex={1}
          gap={0}
          borderRight={"1px solid #ddd"}
          marginRight={"10px"}
        >
          {[
            { name: "Show tax number", value: true },
            { name: "Show columns headings", value: true },
            { name: "Show item code", value: false },
            { name: "Show unit price & quantity", value: true },
            { name: "Show payments advice cut-away", value: true },

            { name: "Show tax columns", value: true },
            { name: "Show registered address", value: false },
            { name: "Show logo", value: true },
            { name: "Hide Discount", value: false },
            { name: "Show Contact Account Number", value: false },
          ].map((item, i) => {
            return (
              <Checkbox
                margin={"0px 0px 10px"}
                size={"md"}
                isChecked={item.value}
              >
                <Text fontSize={"11px"} color={"customColor.black7"}>
                  {item.name}
                </Text>
              </Checkbox>
            );
          })}
        </Stack>
        <Stack
          flex={1}
          paddingLeft={"16px"}
          gap={0}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
          bg={"#fff"}
          padding={"10px"}
        >
          <Stack gap={0} marginBottom={"5px"}>
            <Text
              fontSize={"11px"}
              color={"customColor.black7"}
              marginBottom={"3px"}
              fontWeight={"700"}
            >
              Logo alignment
            </Text>
            <RadioGroup defaultValue="1">
              <Stack gap={0}>
                {[
                  { name: "Left", value: "1" },
                  { name: "Center", value: "2" },
                  { name: "Right", value: "3" },
                ].map((item, i) => {
                  return (
                    <Radio value={item.value} size="sm" marginBottom={"5px"}>
                      <Text
                        textAlign={"right"}
                        fontSize={"11px"}
                        color={"customColor.black7"}
                      >
                        {item.name}
                      </Text>
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={"11px"}
              color={"customColor.black7"}
              marginBottom={"3px"}
              fontWeight={"700"}
            >
              Show taxes as
            </Text>
            <RadioGroup defaultValue="1">
              <Stack gap={0}>
                {[
                  { name: "Exclusive", value: "1" },
                  { name: "Inclusive", value: "2" },
                ].map((item, i) => {
                  return (
                    <Radio value={item.value} size="sm" marginBottom={"5px"}>
                      <Text
                        textAlign={"right"}
                        fontSize={"11px"}
                        color={"customColor.black7"}
                      >
                        {item.name}
                      </Text>
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
          </Stack>
          <Text fontSize={"11px"} color={"customColor.black7"}>
            Enter your contact details as they should appear at the top of all
            PDFs you print or send
          </Text>
          <Textarea
            bg={"#fff"}
            defaultValue={`Demo Company (AU)
23 Main Street
MARINEVILLE NSW 2000`}
            borderRadius={"0"}
            border="1px solid #e5e7eb"
            marginBottom={"10px"}
            _hover={{
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _focus={{
              borderWidth: "1px",

              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            _focusVisible={{
              borderWidth: "1px",
              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            fontSize={"11px"}
            _light={{
              color: "customColor.black7",
            }}
          />
        </Stack>
      </Stack>
      <SimpleGrid
        columns={2}
        spacingX={4}
        spacingY={4}
        marginTop={"10px"}
        marginBottom={"10px"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        bg={"#fff"}
        padding={"10px"}
      >
        <Box>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Show tax subtotals by
          </Text>
          <ReactSelectForInvoiceSettings
            withValidation
            options={ShowTaxOption}
            value={ShowTaxOption.find((op) => op.value === showTax)}
            onChange={(newValue: any) => {
              setShowTax(newValue?.value);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
        </Box>

        <Box>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Show currency conversion as
          </Text>
          <ReactSelectForInvoiceSettings
            withValidation
            options={ShowCurrencyConversionOption}
            value={ShowCurrencyConversionOption.find(
              (op) => op.value === showCurrencyConversion
            )}
            onChange={(newValue: any) => {
              setShowCurrencyConversion(newValue?.value);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
        </Box>

        <Box>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Payment Services (credit Card)
          </Text>
          <ReactSelectForInvoiceSettings
            withValidation
            options={ShowPaymentServicesCreditCardOption}
            value={ShowPaymentServicesCreditCardOption.find(
              (op) => op.value === showPaymentServicesCreditCard
            )}
            onChange={(newValue: any) => {
              setShowPaymentServicesCreditCard(newValue?.value);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
        </Box>

        <Box>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Payment Services (Paypal)
          </Text>
          <ReactSelectForInvoiceSettings
            withValidation
            options={ShowPaymentServicesPayPalOption}
            value={ShowPaymentServicesPayPalOption.find(
              (op) => op.value === showPaymentServicesPayPal
            )}
            onChange={(newValue: any) => {
              setShowPaymentServicesPayPal(newValue?.value);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
        </Box>

        <Box>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Payment Services (Bank Payments)
          </Text>
          <ReactSelectForInvoiceSettings
            withValidation
            options={ShowPaymentServicesBankOption}
            value={ShowPaymentServicesBankOption.find(
              (op) => op.value === showPaymentServicesBank
            )}
            onChange={(newValue: any) => {
              setShowPaymentServicesBank(newValue?.value);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
        </Box>
      </SimpleGrid>

      <Stack
        gap={0}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        bg={"#fff"}
        padding={"10px"}
      >
        <Stack gap={0}>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Terms & Payment Advice (Invoice and Statement)
          </Text>
          <Textarea
            bg={"#fff"}
            borderRadius={"0"}
            border="1px solid #e5e7eb"
            _hover={{
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _focus={{
              borderWidth: "1px",

              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            _focusVisible={{
              borderWidth: "1px",
              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            fontSize={"11px"}
            _light={{
              color: "customColor.black7",
            }}
          />
        </Stack>
        <Stack gap={0}>
          <Text
            fontSize={"11px"}
            color={"customColor.black7"}
            marginBottom={"3px"}
            fontWeight={"700"}
          >
            Terms (Quotes)
          </Text>
          <Textarea
            bg={"#fff"}
            borderRadius={"0"}
            border="1px solid #e5e7eb"
            _hover={{
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _focus={{
              borderWidth: "1px",

              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            _focusVisible={{
              borderWidth: "1px",
              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            fontSize={"11px"}
            _light={{
              color: "customColor.black7",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
