import {
  Box,
  HStack,
  Progress,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { Pie } from "react-chartjs-2";
import { useFormContext } from "react-hook-form";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export default function DepartmentalStats() {
  const { editDashboard, dashboardAnalytics } = useDashboardContext();
  const form = useFormContext<Analytics.DashBoardData>();
  let dataFromBackend = dashboardAnalytics?.departments;
  const departments = Object.keys(dataFromBackend).map(
    (key: any, index: any) => ({
      serialNumber: (index + 1).toString(),
      department: key,
      headCount: dataFromBackend[key].headCount.toString(),
      male: dataFromBackend[key].males.toString(),
      female: dataFromBackend[key].females.toString(),
      others: dataFromBackend[key].others.toString(),
      turnover: (
        (dataFromBackend[key].empsLeft / dataFromBackend[key].headCount) *
        100
      ).toFixed(2),
      separationCount: dataFromBackend[key].empsLeft.toString(),
    })
  );
  return (
    <Box
      word-wrap="break-word"
      maxHeight="600px"
      w="100%"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      sx={{
        "::-webkit-scrollbar": {
          width: "0px", // Initially, the scrollbar is hidden
          background: "transparent",
        },
        ":hover::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on hover
        },
        ":active::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible when active
        },
        ":focus::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on focus
        },
        // Add for Firefox and other browsers if needed
        scrollbarWidth: "none",
        ":hover": {
          scrollbarWidth: "thin",
        },
        ":active": {
          scrollbarWidth: "thin",
        },
        ":focus": {
          scrollbarWidth: "thin",
        },
      }}
    >
      <Stack p={"25px"} borderBottom={"1px solid #e5e7eb"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight="1.2"
          fontSize="16px"
          fontWeight={600}
        >
          Departmental Statistics
        </Text>
      </Stack>

      <Box overflowX="auto" p="0px">
        <Text
          color={"customColor.black"}
          _dark={{
            color: "customColor.dark.150",
          }}
          textAlign={"center"}
          lineHeight="1.2"
          fontSize="24px"
          fontWeight={600}
          mb="10px"
        >
          {moment(dashboardAnalytics?.activeFY?.startDate).format("MMM YYYY")} -{" "}
          {moment(dashboardAnalytics?.activeFY?.endDate).format("MMM YYYY")}
        </Text>
        <Table variant="simple" w="100%" overflowX="auto">
          <Thead h={"36.8px"}>
            <Tr
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
            >
              {[
                {
                  name: "Serial Number",
                  value: "10%",
                  paddingValue: "8px 26px 8px 30px",
                },
                {
                  name: "Department",
                  value: "20%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "HeadCount",
                  value: "10%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Turnover %",
                  value: "15%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Separation Count",
                  value: "10%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Gender",
                  value: "25%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Label",
                  value: "10%",
                  paddingValue: "8px 26px 8px 15px",
                },
              ].map((item, i) => {
                return (
                  <Th
                    key={i}
                    padding={item.paddingValue}
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    textAlign={"center"}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                    width={item.value}
                  >
                    {item.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {departments?.map((item, i) => (
              <Tr
                key={i}
                _hover={{
                  bgColor: "#f2f3f8",
                  cursor: "pointer",
                }}
              >
                <Td
                  padding="15px 15px 15px 30px"
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  textAlign={"center"}
                  fontWeight={500}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  {item.serialNumber}
                </Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  textAlign={"center"}
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                  _hover={{
                    color: "#3454d1",
                  }}
                >
                  {item.department}
                </Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  textAlign={"center"}
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  {item.headCount}
                </Td>

                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  textAlign={"center"}
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  <Box>
                    <Text fontSize="12px" mb="2px">
                      {item.turnover}%
                    </Text>
                    <Progress
                      value={parseInt(item.turnover)}
                      size="sm"
                      colorScheme="blue"
                      borderRadius="4px"
                    />
                  </Box>
                </Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  textAlign={"center"}
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  {item?.separationCount}
                </Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  <HStack justifyContent={"center"}>
                    <Box h={"80px"} w={"150px"}>
                      <Pie
                        data={{
                          labels: ["Male", "Female"],
                          datasets: [
                            {
                              data: [item.male, item.female],
                              backgroundColor: ["#36A2EB", "#FF6384"],
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                    </Box>
                  </HStack>
                </Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                  justifyContent="center"
                >
                  <HStack>
                    <Box h="10px" w="10px" bg="#36A2EB" borderRadius="50%" />
                    <Text fontSize="12px" ml="2">
                      Male: {item.male}
                    </Text>
                  </HStack>
                  <HStack mt="2">
                    <Box h="10px" w="10px" bg="#FF6384" borderRadius="50%" />
                    <Text fontSize="12px" ml="2">
                      Female: {item.female}
                    </Text>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
