import { Icon, Link, Stack, Text } from "@chakra-ui/react";
import { BsBoxArrowUpRight } from "react-icons/bs";

export function XeroApp() {
  return (
    <Stack
      gap={0}
      margin={"20px 0px 0px"}
      padding={"20px 0px 0px"}
      display={"none"}
    >
      <Text
        fontSize={"17px"}
        fontWeight={"700"}
        _dark={{
          color: "customColor.dark.150",
        }}
        _light={{
          color: "customColor.black",
        }}
      >
        Xero apps available for your business
      </Text>
      <Stack
        gap={0}
        marginTop={"20px"}
        padding={"24px"}
        border={"1px solid lightgrey"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius={"5px"}
        w={{ md: "100%", lg: "850px" }}
      >
        <Text
          fontSize={"13px"}
          fontWeight={"700"}
          color={"#00A1E"}
          margin={"0px 0px 8px"}
        >
          Expenses
        </Text>

        <Text fontSize={"15px"} fontWeight={"400"}>
          Easily record, claim and manage expenses & receipts online.
          <Link
            textDecoration={"underline"}
            color={"#000A1EBF"}
            _hover={{
              color: "#0078C8",
            }}
          >
            Learn more about Expenses <Icon as={BsBoxArrowUpRight} />
          </Link>
        </Text>
      </Stack>
    </Stack>
  );
}
