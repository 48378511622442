import {
  Box,
  Button,
  Checkbox,
  Container,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCarAlt, FaCaretDown } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ProductAndService } from "../../Api/ProductAndServices";
import { AddItem } from "./AddNewItem";
import { Delete } from "./Delete";

export function ProductAndServices() {
  const [filter, setFilter] = useState("All");
  const deleteModal = useDisclosure();
  const addNewItem = useDisclosure();
  const navigate = useNavigate();
  const form = useForm<ProductAndService.AddProductAndService>({
    defaultValues: {
      code: 0,
      name: "",
      trackItem: false,
      inventoryAccount: "",
      purchase: false,
      purchaseCost: 0,
      purchaseAccount: "",
      purchaseTaxRate: "",
      purchaseDescription: "",
      sell: false,
      sellSale: 0,
      sellSaleAccount: "",
      sellTaxRate: "",
      sellDescription: "",
    },
  });
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        h={"calc(100vh - 70px)"}
        // h="calc(100vh - 130px)"
        overflowY="auto"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          padding={"16px"}
          gap={0}
        >
          <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"17px"}
            fontWeight={"700"}
          >
            Products and services
          </Text>
          <Stack direction={"row"} gap={0}>
            <Menu>
              <MenuButton
                as={Button}
                borderRadius={"3px"}
                marginLeft={"16px"}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  color: "customColor.black7",
                  bg: "#fff",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "#3545d1",
                  bg: "#fff",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                rightIcon={<FaCaretDown />}
              >
                Import
              </MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                    onClick={() => {
                      navigate("/app/payroll/ImportForItems");
                    }}
                  >
                    Items
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                    onClick={() => {
                      navigate("/app/payroll/OpeningBalances");
                    }}
                  >
                    Opening Balances
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                borderRadius={"3px"}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                marginLeft={"12px"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  color: "customColor.black7",
                  bg: "#fff",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "#3545d1",
                  bg: "#fff",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                rightIcon={<FaCaretDown />}
              >
                Export
              </MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    CSV
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              marginLeft={"12px"}
              borderRightRadius={0}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                addNewItem.onOpen();
              }}
            >
              New item
            </Button>
            <Menu>
              <MenuButton
                as={IconButton}
                padding={0}
                backgroundColor={"transparent"}
                h={"34.5px"}
                marginLeft={"12px"}
                color={"black"}
                display={{
                  lg: "flex",
                  md: "flex",
                  sm: "flex",
                  xs: "flex",
                }}
                borderRadius={"full"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.05)",
                  color: "#000a1e",
                }}
                _active={{
                  backgroundColor: "rgba(0,10,30,.05)",
                  color: "#000a1e",
                }}
                icon={<FiMoreVertical fontSize={"19px"} />}
              ></MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Stack alignItems={"center"} direction={"row"}>
                    <FaCarAlt fontSize="17px" color="customColor.black7" />
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Give feedBack
                    </Text>
                  </Stack>
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
        <Container maxW={"container.lg"} padding={"25px"}>
          <Stack
            gap={0}
            // maxWidth={"1164px"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            borderRadius="4px"
            bg={"#fff"}
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            borderWidth="1px"
            borderStyle={"solid"}
            borderColor="#f6f6f6"
            p={"0"}
          >
            <Stack direction={"row"} p={"16px"} alignItems={"center"} gap={0}>
              <Stack gap={0} direction={"row"} alignItems={"center"} flex={1}>
                <Text
                  fontSize={"13.44px"}
                  _light={{
                    color: "customColor.gray.6b7885",
                  }}
                >
                  Search:
                </Text>
                <InputGroup ml={[0, "7px"]}>
                  <Input
                    type="text"
                    placeholder="Search"
                    borderRadius={"3px"}
                    h={["auto", "40px"]}
                    _focusVisible={{
                      border: "1px solid grey",
                    }}
                    //   onChange={(e) => {
                    //     debounced(e.target.value);
                    //   }}
                  />
                </InputGroup>
              </Stack>
              <Menu>
                <MenuButton
                  as={Button}
                  variant={"ghost"}
                  h={"40px"}
                  fontSize={".845rem"}
                  marginLeft={"12px"}
                  color={"#3454d1"}
                  rightIcon={<IoMdArrowDropdown fontSize={"18px"} />}
                  _hover={{
                    bg: "#f2f3f4",
                  }}
                  _active={{
                    bg: "#f2f3f4",
                  }}
                >
                  {filter}
                </MenuButton>
                <MenuList overflowY="auto" zIndex={"overlay"}>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                    onClick={() => {
                      setFilter("All");
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      All
                    </Text>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                    onClick={() => {
                      setFilter("Active");
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Active
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
            <Stack
              direction={"row"}
              p={"16px"}
              gap={0}
              borderTop={"1px solid #e5e7eb"}
              borderBottom={"1px solid #e5e7eb"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                No selected items
              </Text>
              <Stack alignItems={"center"} direction={"row"}>
                <Button
                  variant={"ghost"}
                  color={"red"}
                  _hover={{
                    bg: "rgba(0,10,30,.05)",
                  }}
                  onClick={() => {
                    deleteModal.onOpen();
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant={"ghost"}
                  color={"#3454d1"}
                  _hover={{
                    bg: "rgba(0,10,30,.05)",
                  }}
                >
                  Restore
                </Button>
                <Button
                  variant={"ghost"}
                  color={"#3454d1"}
                  _hover={{
                    bg: "rgba(0,10,30,.05)",
                  }}
                >
                  Archive
                </Button>
              </Stack>
            </Stack>
            <Box overflowX="auto" p="0px">
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h={"36.8px"}>
                  <Tr
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                  >
                    <Th
                      padding="8px 26px 8px 30px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        //   isChecked={isCheckedAll}
                        //   onChange={handleHeaderCheckboxChange}
                      />
                    </Th>
                    {[
                      {
                        name: "Code",
                        value: "15%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Name",
                        value: "40%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Cost price",
                        value: "15%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Sale price",
                        value: "15%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Quantity",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },

                      {
                        name: "",
                        value: "5%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                    ].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding={item.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={item.value}
                        >
                          {item.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody padding={0}>
                  {[
                    {
                      code: "DevD",
                      name: "Development work -developer onsite per day",
                      costPrice: "$520",
                      salePrice: "$650",
                      quantity: "1 ",
                    },
                    {
                      code: "DevH",
                      name: "Development work -developer onsite per day",
                      costPrice: "$520",
                      salePrice: "$650",
                      quantity: "1 ",
                    },
                    {
                      code: "GB1-White",
                      name: "Golf balls -white single",
                      costPrice: "$520",
                      salePrice: "$650",
                      quantity: "1 ",
                    },
                    {
                      code: "GB3-White",
                      name: "Golf balls -white 3-pack",
                      costPrice: "$520",
                      salePrice: "$650",
                      quantity: "1 ",
                    },
                    {
                      code: "GB6-White",
                      name: "Golf balls -white 6-pack",
                      costPrice: "$520",
                      salePrice: "$650",
                      quantity: "1 ",
                    },
                    {
                      code: "GB9-White",
                      name: "Golf balls -white 9-pack",
                      costPrice: "$520",
                      salePrice: "$650",
                      quantity: "1 ",
                    },
                  ].map((item, i) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          whiteSpace="nowrap"
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          <Checkbox
                            size="md"
                            borderColor="#adb5bd"
                            bgColor="white"

                            // Stop propagation here
                          />
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tag
                            fontSize={"11px"}
                            borderRadius={"2px"}
                            bg={"#fff"}
                            border={"1px solid #e5e7eb"}
                          >
                            {item?.code}
                          </Tag>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {item?.name}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {item?.costPrice}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {item?.salePrice}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {item?.quantity}
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Menu>
                            <MenuButton
                              as={Button}
                              padding={0}
                              backgroundColor={"transparent"}
                              h={"34.5px"}
                              marginLeft={"8px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              display={{
                                lg: "flex",
                                md: "flex",
                                sm: "flex",
                                xs: "flex",
                              }}
                              borderRadius={"full"}
                              _hover={{
                                backgroundColor: "primary.50",
                              }}
                              _active={{
                                backgroundColor: "primary.100",
                              }}
                            >
                              <Icon as={FiMoreVertical} />
                            </MenuButton>
                            <MenuList style={{ minWidth: "140px" }}>
                              {[
                                {
                                  name: "Edit",
                                },
                                {
                                  name: "Archive",
                                },
                                {
                                  name: "Delete",
                                  onClick: () => {
                                    deleteModal.onOpen();
                                  },
                                },
                              ].map((menuItem, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    padding={"1px 0px 1px 20px"}
                                    onClick={menuItem.onClick}
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.white",
                                    }}
                                  >
                                    <Button
                                      variant={"ghost"}
                                      fontWeight={"normal"}
                                      fontSize={"13px"}
                                      padding={"0px 20px 0px 0px"}
                                      _light={{
                                        color: "customColor.black7",
                                      }}
                                      _dark={{
                                        color: "customColor.white",
                                      }}
                                      _hover={{ bg: "none" }}
                                    >
                                      {menuItem.name}
                                    </Button>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Stack>
        </Container>
        <Delete isOpen={deleteModal.isOpen} onClose={deleteModal.onClose} />
        <AddItem isOpen={addNewItem.isOpen} onClose={addNewItem.onClose} />
      </Stack>
    </FormProvider>
  );
}
