import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { MdDelete, MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { DependentsApi } from "../../../../Api/Dependents";
import AddDependents from "./Modal/AddDependents";
import DeleteDependents from "./Modal/DeleteDependents";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
type SortOrder = "asc" | "desc" | "";
export default function Dependents({ setSelectedTab, selectedTab }: TabProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteId, setDeleteId] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>(""); // Track the current column
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [selectedId, setSelectedId] = useState<string>();
  const { id } = useParams();

  const form = useForm<DependentsApi.AddDependents>({
    defaultValues: {
      name: "",
      dateOfBirth: "",
      gender: "",
      cnic: "",
      relationship: "",
      martialStatus: "",
      empId: id,
    },
  });
  const [dependentsData, setDependentsData] = useState<
    Array<DependentsApi.FetchDependents>
  >([]);
  const toast = useToast();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const deleteModal = useDisclosure();
  const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  useEffect(() => {
    const initialCheckboxes = dependentsData.reduce((acc: any, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [dependentsData]);

  const handleCheckboxChange = (id: string) => {
    setCheckboxes({
      ...checkboxes,
      [id]: !checkboxes[id],
    });
  };

  const handleCheckAll = () => {
    const updatedCheckboxes = Object.keys(checkboxes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: !checkboxes[key],
      }),
      {}
    );
    setCheckboxes(updatedCheckboxes);
  };
  const handleSort = (column: string) => {
    let order: SortOrder = "asc";

    // Toggle between asc and desc
    if (sortColumn === column && sortOrder === "asc") {
      order = "desc";
    }

    setSortColumn(column);
    setSortOrder(order);

    const sortedData = [...dependentsData].sort((a, b) => {
      const aValue = a[column as keyof DependentsApi.FetchDependents];
      const bValue = b[column as keyof DependentsApi.FetchDependents];

      if (order === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setDependentsData(sortedData);
  };

  // Render sorting icons
  const renderSortIcon = (column: string) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <AiOutlineSortAscending style={{ marginLeft: "8px" }} />
      ) : (
        <AiOutlineSortDescending style={{ marginLeft: "8px" }} />
      );
    }
    return null;
  };
  useEffect(() => {
    DependentsApi.GetDependentsDetails(
      id!,
      (data) => {
        setDependentsData(data);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Fragment>
        <Stack
          marginBottom={"30px"}
          border="1px"
          borderColor="#f6f6f6"
          borderRadius="4px"
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <HStack justifyContent="space-between">
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight="bold"
                padding="20px"
                as="h2"
                size="md"
              >
                Dependents
              </Heading>
              {dependentsData.length !== 4 && (
                <Button
                  variant="link"
                  _light={{
                    color: "primary.950",
                  }}
                  mr="20px"
                  onClick={() => {
                    onOpen();
                    setSelectedId(undefined);
                    form.reset({
                      name: "",
                      dateOfBirth: "",
                      gender: "",
                      cnic: "",
                      relationship: "",
                      martialStatus: "",
                      empId: id,
                    });
                  }}
                >
                  Add Dependents
                </Button>
              )}
            </HStack>

            <Box overflowX="auto" p="0px" w={"100%"}>
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h={"36.8px"}>
                  <Tr
                    borderTopWidth={"1px"}
                    borderTopColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                  >
                    <Th
                      padding="8px 26px 8px 30px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        isChecked={Object.values(checkboxes).every(
                          (value) => value
                        )}
                        onChange={handleCheckAll}
                      />
                    </Th>
                    {[
                      { name: "Name", value: "name" },
                      { name: "Date Of Birth", value: "dateOfBirth" },
                      { name: "Gender", value: "gender" },
                      { name: "CNIC No.", value: "cnic" },
                      { name: "Relationship", value: "relationship" },
                      { name: "Marital Status", value: "martialStatus" },
                    ].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding="15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          // w={item.value}
                          fontWeight={700}
                          fontSize="12px"
                          textTransform="none"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          onClick={() => handleSort(item.value)}
                          cursor="pointer"
                        >
                          <HStack>
                            <Text>{item.name}</Text>
                            <Text>{renderSortIcon(item.value)}</Text>
                          </HStack>
                        </Th>
                      );
                    })}
                    <Th
                      padding="15px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      // w={item.value}
                      fontWeight={700}
                      fontSize="12px"
                      textTransform="none"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      cursor="pointer"
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dependentsData.map((item, i) => (
                    <Tr
                      key={i}
                      _hover={{
                        bgColor: "#f2f3f8",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onOpen();
                        setSelectedId(item.empId);
                        form.reset(item);
                      }}
                    >
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={checkboxes[item.id]}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.name}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.dateOfBirth}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.gender === "MALE"
                          ? "Male"
                          : item.gender === "FEMALE"
                          ? "Female"
                          : item.gender}
                      </Td>

                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.cnic}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.relationship}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.martialStatus === "SINGLE"
                          ? "Single"
                          : item.martialStatus === "MARRIED"
                          ? "Married"
                          : item.martialStatus === "DIVORCED"
                          ? "Divorced"
                          : item.martialStatus === "WIDOWED"
                          ? "Widowed"
                          : item.martialStatus}
                      </Td>

                      <Td
                        padding="10px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                            borderColor={"borders.tableBorder"}
                            bg={"#fff"}
                            w="25px"
                            h="25px"
                            px="5px"
                            py="5px"
                            as={MdEdit}
                            fontSize="22px"
                            color="#6B7280"
                            _hover={{
                              color: "#3454d1",
                            }}
                            onClick={(e) => {
                              onOpen();
                              e.stopPropagation();
                              form.reset(item);
                            }}
                          />
                          <Icon
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                            borderColor={"borders.tableBorder"}
                            bg={"#fff"}
                            w="25px"
                            h="25px"
                            px="5px"
                            py="5px"
                            as={MdDelete}
                            fontSize="22px"
                            color="#6B7280"
                            _hover={{
                              color: "#3454d1",
                            }}
                            onClick={(e) => {
                              setDeleteId(item.id);
                              e.stopPropagation();
                              deleteModal.onOpen();
                            }}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
              <Button
                colorScheme="green"
                border={"1px solid green"}
                textColor={"customColor.white"}
                borderRadius={"3px"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                onClick={() => setSelectedTab(selectedTab + 1)}
              >
                Next
              </Button>
            </Stack>
          </Box>
        </Stack>

        <AddDependents
          isOpen={isOpen}
          selectedId={selectedId}
          onClose={onClose}
          setLastUpdated={setLastUpdated}
        />
        <DeleteDependents
          id={deleteId}
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          setLastUpdated={setLastUpdated}
        />
      </Fragment>
    </FormProvider>
  );
}
