import { HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useAuthHeader } from "react-auth-kit";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayTemp } from "../../../Api/PayTemp";

interface DeductionFileProps {
  form?: any;
  deductionFieldArray?: any;
  deductionLine?: any;

  setResetData?: (resetData: any) => void;
  deductionAdded: any[];
  currentEmployeeId?: string | undefined;
}
export function DeductionFile({
  form,
  deductionFieldArray,
  deductionLine,
  deductionAdded,

  setResetData,
  currentEmployeeId,
}: DeductionFileProps) {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const toast = useToast();
  // useEffect(() => {
  //   if (setdeductionAdded) {
  //     setdeductionAdded(deductionAdded);
  //   }
  // }, []);

  return (
    <Stack>
      <Text
        fontSize={"12px "}
        margin={"0px 0px 4px"}
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
      >
        Deductions
      </Text>
      {deductionFieldArray.fields.map(
        (item: PayTemp.DeductionRate & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                color={"primary.950"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (decodedToken.accessLevel !== "EMPLOYEE") {
                    deductionLine.onOpen();
                  }

                  if (setResetData) {
                    setResetData({ ...item, index: i });
                  }
                }}
              >
                {(deductionAdded &&
                  deductionAdded.find(
                    (deductionAdded) =>
                      deductionAdded.id === item.payItemDeductionId
                  )?.name) ??
                  ""}
              </Text>
            </Stack>

            {form.getValues(`deductions.${i}.calculationType`) ===
              "FIXED_AMOUNT" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Amount is required",
                      },
                    }}
                    control={form.control}
                    name={`deductions.${i}.amount`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          variant: "outline",
                          bg: "#fff",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          placeholder: "",
                        }}
                        label="Amount "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`deductions.${i}.calculationType`) ===
              "PRE-TAX" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`deductions.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "number",
                          _light: {
                            color: "customColor.black7",
                          },
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`deductions.${i}.calculationType`) ===
              "POST-TAX" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`deductions.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },
                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          _light: {
                            color: "customColor.black7",
                          },
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`deductions.${i}.calculationType`) ===
              "EMPLOYEE_PROVIDENT_FUND" && (
              <HStack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Percentage is required",
                    },
                  }}
                  control={form.control}
                  name={`deductions.${i}.percentage`}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "12px",
                        variant: "outline",
                        bg: "#fff",
                        type: "number",
                        isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                        onChange: (e) => {
                          field.onChange(Number(e.target.valueAsNumber));
                        },
                        borderWidth: "1px",
                        _light: {
                          color: "customColor.black7",
                        },

                        maxW: "91.33px",
                        height: "26.33px",

                        padding: "12px 15px",

                        placeholder: "",
                      }}
                      label="Percentage "
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        _light: {
                          color: "customColor.black7",
                        },
                        margin: "0px 0px 4px",
                      }}
                    />
                  )}
                />
              </HStack>
            )}
            {form.getValues(`deductions.${i}.calculationType`) ===
              "EMPLOYEE_EOBI" && (
              <HStack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Percentage is required",
                    },
                  }}
                  control={form.control}
                  name={`deductions.${i}.percentage`}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "12px",
                        variant: "outline",
                        bg: "#fff",
                        type: "number",
                        onChange: (e) => {
                          field.onChange(Number(e.target.valueAsNumber));
                        },
                        isDisabled: decodedToken.accessLevel === "EMPLOYEE",

                        height: "26.33px",
                        padding: "12px 15px",
                        placeholder: "",
                      }}
                      label="Percentage "
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        _light: {
                          color: "customColor.black7",
                        },
                        margin: "0px 0px 4px",
                      }}
                    />
                  )}
                />

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Minimum Wage is required",
                    },
                  }}
                  control={form.control}
                  name={`deductions.${i}.minimumWage`}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "12px",
                        variant: "outline",
                        bg: "#fff",
                        type: "text",
                        value: field.value
                          ? Number(field.value).toLocaleString()
                          : "",
                        onChange: (e: any) => {
                          const value = e.target.value.replace(/,/g, "");
                          field.onChange(Number(value));
                        },
                        padding: "12px 15px",
                        isDisabled: true,

                        maxW: "91.33px",
                        height: "26.33px",

                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                      label="Minimum Wage "
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        _light: {
                          color: "customColor.black7",
                        },
                        margin: "0px 0px 4px",
                      }}
                    />
                  )}
                />
                <CustomInput
                  withValidation
                  input={{
                    fontSize: "12px",
                    variant: "outline",
                    bg: "#fff",
                    type: "text",
                    value: Number(
                      (form.watch(`deductions.${i}.minimumWage`) *
                        form.watch(`deductions.${i}.percentage`)) /
                        100
                    ).toLocaleString(),

                    maxW: "91.33px",
                    _light: {
                      color: "customColor.black7",
                    },
                    height: "26.33px",
                    isDisabled: true,

                    placeholder: "",
                    padding: "12px 15px",
                  }}
                  label="Amount"
                  labelProps={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    _light: {
                      color: "customColor.black7",
                    },
                    margin: "0px 0px 4px",
                  }}
                />
              </HStack>
            )}
            {decodedToken.accessLevel !== "EMPLOYEE" && (
              <Stack>
                <Icon
                  fontSize={"20px"}
                  as={IoIosCloseCircleOutline}
                  marginTop={"15px"}
                  color={"grey"}
                  _hover={{
                    color: "red.500",
                  }}
                  onClick={() => deductionFieldArray.remove(i)}
                />
              </Stack>
            )}
          </Stack>
        )
      )}
    </Stack>
  );
}
