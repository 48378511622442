import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeesReport } from "../../../Api/EmployeeReport";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { DownloadExcel } from "../../TimeSheet/utils";
import { ColumnsComponent } from "../Components/Columns";
import { Date } from "../Components/Date";
import { FilterComponent } from "../Components/FilterComponent";
import ListLimit from "../Components/ListLimit";
import PaginationComponent from "../Components/PaginationComponent";
import { ReportingTable } from "../Tables/ReportingTable";
import { Column } from "./Columns";
import ReportsHeader from "./ReportsHeader";
interface GenericObject {
  [key: string]: any;
}
export function LeaveBalanceReport() {
  const [selectedGroupBy, setSelectedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const [selectedClonedGroupBy, setSelectedClonedGroupBy] = useState<
    | EmployeesReport.GroupingEmployeeRemunerationReport["groupBy"]
    | EmployeesReport.GroupingLeaveBalanceReport["groupBy"]
    | EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]
    | EmployeesReport.GroupingTimesheetDetailsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationAccrualsReport["groupBy"]
    | EmployeesReport.GroupingSuperannuationPaymentsReport["groupBy"]
    | EmployeesReport.GroupingLeaveTransactionsReport["groupBy"]
    | EmployeesReport.GroupingLeaveRequestReport["groupBy"]
    | "EMPLOYEE"
    | "EMPLOYEE_GROUP"
    | undefined
  >(undefined);
  const GroupingBtn: Array<{ label: string; value?: string }> = [
    {
      label: "None",
      value: undefined,
    },
    {
      label: "Employee",
      value: "EMPLOYEE",
    },
    {
      label: "Leave Type",
      value: "LEAVE_TYPE",
    },
  ];
  const name = "Leave Balance Report";
  const FilterData: Array<{
    name: string;
    startRate?: string;
    endRate?: string;
    dataType?: string;
    endDate?: string;
    startDate?: string;
    StartKeyName?: string;
    EndKeyName?: string;
    singleProperty?: string;
    PayLoadDataType?: any;
  }> = [
    {
      name: "Units",
      startRate: "text",
      endRate: "text",
      StartKeyName: "filters.units.from",
      EndKeyName: "filters.units.to",
      PayLoadDataType: Number,
    },
  ];
  const toast = useToast();
  const reportForm = useForm<EmployeesReport.LeaveBalanceReport>({
    defaultValues: {
      columns: {
        employee: true,
        leaveType: true,
        units: true,
      },
      filters: {},
      pagination: {
        page: 1,
        limit: 10,
      },
    },
  });
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0],
    colBtns[0].colItem[1],
    colBtns[0].colItem[2],
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem]);

  const [selectedData, setSelectedData] = useState<
    EmployeesReport.fetchPayrollActivitySummaryReport | GenericObject
  >();
  const { allEmployeesList } = useBusinessContext();
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [excelData, setExcelData] = useState<any>(undefined);
  const postPayload = reportForm.handleSubmit((data) => {
    let payloadData = { ...data };
    const isEmptyObject = (obj: any) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const removeEmpty = (obj: any) => {
      let newObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
          newObj[key] = removeEmpty(obj[key]);
          if (isEmptyObject(newObj[key]) && key !== "filters") {
            delete newObj[key];
          }
        } else if (obj[key] !== undefined) {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    };
    const payload = {
      ...payloadData,
      filters: {
        ...payloadData.filters,
        employeeId: selectedEmployee,
        leaveType: leaveType,
      },
      groupBy: selectedGroupBy,

      columns: colBtns[0].colItem.reduce((acc, item) => {
        return {
          ...acc,
          [item.column]: selectedColumns.some(
            (col) => col.column === item.column
          ),
        };
      }, {} as any),
    };
    const cleanedPayload = removeEmpty(payload);
    EmployeesReport.CreateLeaveBalanceReport(
      cleanedPayload,
      (success: any) => {
        setSelectedData(success);
        setTotalCount(success.count);
        setClonedSelectedColumns(selectedColumns);
        setExcelData(cleanedPayload);
        toast({
          title: "Report Created",
          description: "Report Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedClonedGroupBy(selectedGroupBy);
      },
      (error: any) => {
        toast({
          title: "Report Creation Failed",
          description: "Report Creation Failed",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  });
  const watchedLimit = useWatch({
    control: reportForm.control,
    name: "pagination.limit",
  });
  const watchedPage = useWatch({
    control: reportForm.control,
    name: "pagination.page",
  });
  useEffect(() => {
    if (selectedData !== undefined) {
      if (selectedData?.data.length > 0) {
        postPayload();
      }
    }
  }, [watchedLimit, watchedPage]);
  const [leaveType, setLeaveType] = useState<string | undefined>(undefined);
  const [leaveAdded, setLeaveAdded] = useState<Array<any>>([]);
  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (data) => {
        setLeaveAdded(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  const leaveTypeOptions: Array<{ label: string; value: string }> = [
    { label: "All", value: undefined },
    ...leaveAdded.map((leave) => ({
      label: leave.name,
      value: leave.id,
    })),
  ];
  return (
    <FormProvider {...reportForm}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          direction={"row"}
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          alignItems={"center"}
          justifyContent={"space-between"} // Add this line
          width={"100%"}
        >
          <ReportsHeader name={name} />
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingLeft={"10px"}
            marginRight={"16px"}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                h={"40px"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                _active={{
                  backgroundColor: "#fff",
                }}
                rightIcon={<ChevronDownIcon />}
                marginRight={"8px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  // onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => {
                    if (excelData === undefined) {
                      toast({
                        title: "Report",
                        description: "Fetch Data First",
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                      });
                    } else {
                      EmployeesReport.CreateLeaveBalanceReportExport(
                        excelData,
                        (success) => {
                          DownloadExcel(
                            success.data,
                            "LeaveBalanceReport.xlsx"
                          );
                          toast({
                            title: "Report Downloaded",
                            description: "Report Downloaded Successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        },
                        () => {
                          toast({
                            title: "Report Creation Failed",
                            description: "Report Creation Failed",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        }
                      );
                    }
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                reportForm.reset({
                  columns: {
                    employee: true,
                    leaveType: true,
                    units: true,
                  },
                  filters: {},
                  pagination: { page: 1, limit: 10 },
                });
                setSelectedColumns([
                  colBtns[0].colItem[0],
                  colBtns[0].colItem[1],
                  colBtns[0].colItem[2],
                ]);
                setSelectedEmployee(undefined);
                setLeaveType(undefined);
                reportForm.setValue("pagination", { page: 1, limit: 10 });
                reportForm.setValue(
                  "filters.date",
                  moment().format("YYYY-MM-DD")
                );
              }}
            >
              Clear Filters
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              marginLeft={"8px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={postPayload}
            >
              Upload
            </Button>
          </Box>
        </Stack>
        <Container padding={"0px 12px"} maxW={"1150px"} marginTop={"10px"}>
          <Stack padding={"0px"}>
            <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Employee
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Employee"
                  options={[
                    { value: undefined, label: "All" }, // Add 'All' option
                    ...(allEmployeesList?.map((employee: any) => ({
                      value: employee.id,
                      label: `${employee.firstName} ${employee.lastName}`,
                    })) || []),
                  ]}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            allEmployeesList?.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : { value: undefined, label: "All" }
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              {/* <LeaveType leaveType={leaveType} /> */}
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Leave Type (Required)
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Leave Type"
                  options={leaveTypeOptions}
                  value={
                    leaveTypeOptions.find((op) => op.value === leaveType) ||
                    leaveTypeOptions[0]
                  }
                  onChange={(newValue: any) => {
                    setLeaveType(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <Date fixedDate="filters.date" />

              <ColumnsComponent
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colBtns={colBtns}
              />
              <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                <HStack gap={0} mb="8px">
                  <Text
                    fontSize="13px"
                    display={"block"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Group By
                  </Text>
                </HStack>

                <ReactSelect
                  placeholder="Group By"
                  options={GroupingBtn}
                  value={
                    GroupingBtn.find((btn) => btn.value === selectedGroupBy) ||
                    GroupingBtn[0]
                  }
                  onChange={(newValue: any) => {
                    setSelectedGroupBy(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Box>
              <FilterComponent FilterData={FilterData} />
            </SimpleGrid>

            {/* <Button onClick={downloadExcel} borderRadius={"3px"}>
              Download as Excel
            </Button> */}
          </Stack>
        </Container>
        {(selectedData?.data?.length > 0 || selectedData !== undefined) && (
          <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
            <Box w={"100%"} display="flex" justifyContent="flex-end">
              <ListLimit totalCount={totalCount} />
            </Box>
            <ReportingTable
              name={name}
              Data={selectedData?.data}
              DataWithKey={selectedData?.data}
              selectedColumns={clonedSelectedColumns}
              selectedData={selectedData}
              // groupBy={clonedSelectedGroupBy}
              groupBy={selectedClonedGroupBy}

              // tabRef={tabRef}
            />
            <Box w={"100%"}>
              <PaginationComponent totalCount={totalCount} />
            </Box>
          </Container>
        )}
      </Stack>
    </FormProvider>
  );
}

const colBtns: Array<{
  colItem: Array<Column>;
}> = [
  {
    colItem: [
      {
        colLabel: "Employee",
        column: "employee",
        position: 1,
        groupingKey: "EMPLOYEE",
        dataKey: "employee",
      },
      {
        colLabel: "Leave Type",
        column: "leaveType",
        groupingKey: "LEAVE_TYPE",
        position: 2,
        dataKey: "leaveType",
      },
      { colLabel: "Units", column: "units", position: 3, dataKey: "units" },
    ],
  },
];
const DataWithKey = {
  NONE: [
    {
      amount: "5000",
      employee: "James Lebron",
      employeeGroup: "none",
      leaveType: "Casual Leave",
      unit: "days",
    },
    {
      amount: "7000",
      employee: "Danish ",
      employeeGroup: "none",
      leaveType: "Annual Leave",
      unit: "days",
    },
    {
      amount: "10000",
      employee: "Sauod Nazir",
      employeeGroup: "none",
      leaveType: "Sick Leave",
      unit: "days",
    },
  ],
};
const Data = [
  {
    amount: "5000",
    employee: "James Lebron",
    employeeGroup: "none",
    leaveType: "Casual Leave",
    unit: "days",
  },
  {
    amount: "7000",
    employee: "Danish ",
    employeeGroup: "none",
    leaveType: "Annual Leave",
    unit: "days",
  },
  {
    amount: "10000",
    employee: "Sauod Nazir",
    employeeGroup: "none",
    leaveType: "Sick Leave",
    unit: "days",
  },
];
