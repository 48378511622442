import { axiosInstanceTime } from "./axiosInstanceTime";

declare module Attendence {
  type attendanceReport = {
    fromDate: string;
    toDate: string;
    employeeIds?: Array<string>;
    locationIds?: Array<string>;
    page: number;
    limit: number;
  };
  type getAttendenceReport = {
    data: attendanceReport;
    total: number;
  };
}
module Attendence {
  const API_URL = "/attendance";
  export function generateReport(
    data: attendanceReport,
    success: (data: getAttendenceReport) => void,
    error: (data: any) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/generate-report`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Attendence };
