import axiosInstanceGateway from "./axiosInstance-gateway";

declare module BusinessServiceChargeApi {
  type BusinessServiceChargeData = {
    id?: string;
    serviceCharges?: number;
    currency: string;
    chargesHead: string;
    fixedAmount?: number;
  };
}
module BusinessServiceChargeApi {
  const API_URL = "/business-service-charges";
  export function AddBusinessServiceCharges(
    data: BusinessServiceChargeData,
    success: (data: BusinessServiceChargeData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetBusinessServiceCharges(
    businessId: string | undefined,
    success: (data: BusinessServiceChargeData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/${businessId}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { BusinessServiceChargeApi };
