import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../../../Api/EmployeeLeave";
import { EmployeeLeaveEntitlement } from "../../../../Api/EmployeeLeaveEntitlement";
import { PayItemLeaveApi } from "../../../../Api/PayItemLeave";
import HistoryAccordion from "../../../Superannuation/History/HistoryAccordion";
import { AssignType } from "../../Modal/AssignType";
import CashOutLeave from "../../Modal/NewRequest/CashOutLeave";
import Leave from "../../Modal/NewRequest/Leave";
import { RejectLeave } from "../../Modal/RejectLeave";
import LeaveEligibilityBalance from "./LeaveEligibilityBalance";
import LeaveTable from "./LeaveTable";

interface TabProps {
  setSelectedTab?: (tab: number) => void;
  selectedTab?: number | undefined;
  payFrequencyAnnually?: number;
}

export function LeaveAdded({
  setSelectedTab,
  selectedTab,
  payFrequencyAnnually,
}: TabProps) {
  const rejectLeave = useDisclosure();
  const cashOutLeave = useDisclosure();
  const assignType = useDisclosure();
  const leave = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [leaveAdded, setLeaveAdded] = useState<Array<any>>([]);
  const [selectedLeaveId, setSelectedLeaveId] = useState<string>();
  const { id } = useParams();
  const [fetchLeaves, setFetchLeaves] = useState<
    Array<PayItemLeaveApi.LeaveById>
  >([]);
  const toast = useToast();
  const [FetchLeavesEligibility, setFetchLeavesEligibility] = useState<
    Array<EmployeeLeaveEntitlement.LeaveEntitlement>
  >([]);
  const { employeesList } = useBusinessContext();
  const [FetchAllLeaves, setFetchAllLeaves] = useState<
    Array<EmployeeLeave.Leave>
  >([]);
  const LeaveEligibilityForm =
    useForm<EmployeeLeaveEntitlement.LeaveEntitlement>({
      defaultValues: {
        empId: "",
        payrollPayItemLeaveId: "",
        calculationMethod: "",
        quantity: 0,
        quantityPerPayPeriod: 0,
        balance: 0,
        terminationUnusedBalance: "",
        etpLeaveType: false,
        includeRetirementContribution: false,
        accruedQuantity: 0,
      },
    });

  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (data) => {
        setFetchLeaves(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, []);
  useEffect(() => {
    EmployeeLeaveEntitlement.FetchLeaveEntitlements(
      id!,
      (data) => {
        setFetchLeavesEligibility(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    EmployeeLeave.FetchLeave(
      id!,
      (data) => {
        setFetchAllLeaves(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);

  const form = useForm<EmployeeLeave.NewLeave>({
    defaultValues: {
      description: "",
      endDate: "",
      quantity: undefined,
      leaveCategory: "NORMAL_LEAVE",
      notes: "",
      payoutDate: "",
      startDate: "",
      payrollPayItemLeaveId: "",
    },
  });

  const OpenLeaveModal = () => {
    leave.onOpen();
  };

  const OpenCashOutModal = () => {
    cashOutLeave.onOpen();
  };

  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      marginBottom={"30px"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Stack>
        <Box justifyContent="center" alignItems="center" p={0}>
          <HStack borderBottom={"1px solid #ddd"} paddingRight={"10px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              _light={{
                color: "customColor.black7",
              }}
              fontWeight="bold"
              padding="20px"
              as="h2"
              size="md"
              flex={1}
            >
              Leave Eligibly Balance
            </Heading>
            <Button
              variant={"ghost"}
              color={"primary.950"}
              bg={"#FFFFFF"}
              padding={"7.52px 12px"}
              borderRadius="3px"
              fontSize={"15px"}
              border={"1px solid #a6a9b0"}
              fontWeight={"700"}
              textAlign={"center"}
              minH={"35.6px"}
              pr={"20px"}
              onClick={() => (
                LeaveEligibilityForm.reset({
                  balance: undefined,
                  calculationMethod: undefined,
                  empId: id,
                  etpLeaveType: false,
                  quantity: undefined,
                  quantityPerPayPeriod: undefined,
                  id: undefined,
                  includeRetirementContribution: false,
                  payrollPayItemLeaveId: undefined,
                  terminationUnusedBalance: undefined,
                  accruedQuantity: undefined,
                  openingBalance: undefined,
                }),
                assignType.onOpen()
              )}
              _hover={{
                color: "#primary.950",
                bg: "#f2f3f4",
              }}
            >
              Assign Leave Type
            </Button>
          </HStack>
          <FormProvider {...LeaveEligibilityForm}>
            <LeaveEligibilityBalance
              LeavesEligibilityBalance={FetchLeavesEligibility}
              payItemLeaves={fetchLeaves}
              empId={id!}
              setLastUpdated={setLastUpdated}
            />
          </FormProvider>
        </Box>
        {/* <LeaveUsed /> */}
        <FormProvider {...form}>
          <Box justifyContent="center" alignItems="center" borderRadius="md">
            <HStack borderBottom={"1px solid #ddd"} paddingRight={"10px"}>
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                fontWeight="bold"
                padding="20px"
                as="h2"
                size="md"
                display={"flex"}
                flex={1}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Leave
              </Heading>
              <Menu>
                <MenuButton
                  color={"primary.950"}
                  bg={"#FFFFFF"}
                  padding={"7.52px 12px"}
                  borderRadius="3px"
                  fontSize={"15px"}
                  border={"1px solid #a6a9b0"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  minH={"35.6px"}
                  _hover={{
                    color: "#primary.950",
                    bg: "#f2f3f4",
                  }}
                >
                  New Request <ChevronDownIcon />
                </MenuButton>
                <MenuList width={"144px"} padding={"10px 0px"}>
                  {[
                    {
                      name: "Leave",
                      onClick: () => {
                        form.reset({
                          description: undefined,
                          endDate: undefined,
                          quantity: undefined,
                          leaveCategory: "NORMAL_LEAVE",
                          notes: undefined,
                          payoutDate: undefined,
                          startDate: undefined,
                          payrollPayItemLeaveId: undefined,
                        });
                        setSelectedLeaveId(undefined);

                        leave.onOpen();
                      },
                    },
                    {
                      name: "Cash Out Leave",
                      onClick: () => {
                        form.reset({
                          description: undefined,
                          endDate: undefined,
                          quantity: undefined,
                          leaveCategory: "CASHOUT_LEAVE",
                          notes: undefined,
                          payoutDate: undefined,
                          startDate: undefined,
                          payrollPayItemLeaveId: undefined,
                        });
                        setSelectedLeaveId(undefined);
                        cashOutLeave.onOpen();
                      },
                    },
                  ].map((item) => (
                    <MenuItem
                      padding={"0px 12px"}
                      onClick={() => {
                        item.onClick();
                      }}
                    >
                      <Button
                        padding={"8px"}
                        variant={"ghost"}
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        fontWeight={"normal"}
                        _light={{
                          color: "black",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        onClick={item.onClick}
                        _hover={{
                          bg: "transparent",
                        }}
                      >
                        {item.name}
                      </Button>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </HStack>
            <LeaveTable
              AllLeaves={FetchAllLeaves}
              fetchLeaves={fetchLeaves}
              empId={id!}
              setLastUpdated={setLastUpdated}
              lastUpdated={lastUpdated}
            />

            {selectedTab !== undefined && (
              <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
                <Button
                  colorScheme="green"
                  border={"1px solid green"}
                  textColor={"customColor.white"}
                  borderRadius={"3px"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  marginRight={"5px"}
                  fontWeight={600}
                  onClick={() =>
                    setSelectedTab && setSelectedTab(selectedTab + 1)
                  }
                >
                  Next
                </Button>
              </Stack>
            )}
          </Box>
        </FormProvider>
        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Stack>
      <FormProvider {...form}>
        <CashOutLeave
          isOpen={cashOutLeave.isOpen}
          onClose={cashOutLeave.onClose}
          setLastUpdated={setLastUpdated}
          FecthedLeaves={fetchLeaves}
          employeeId={id!}
        />
        <RejectLeave
          isOpen={rejectLeave.isOpen}
          onClose={rejectLeave.onClose}
          setLastUpdated={setLastUpdated}
        />
        <Leave
          isOpen={leave.isOpen}
          onClose={leave.onClose}
          setLastUpdated={setLastUpdated}
          FecthedLeaves={fetchLeaves}
          employeeId={id!}
          lastUpdated={lastUpdated}
        />
      </FormProvider>
      <FormProvider {...LeaveEligibilityForm}>
        <AssignType
          isOpen={assignType.isOpen}
          onClose={assignType.onClose}
          FecthedLeaves={fetchLeaves}
          empId={id!}
          setlastUpdated={setLastUpdated}
          payFrequencyAnnually={payFrequencyAnnually}
        />
      </FormProvider>
    </Stack>
  );
}
