import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AddEmployeeNotifications } from "../../../Api/EmployeeNotifications";
import EmployeeNotificationModal from "../Modal/AddEmployeeNotifications";
import { DeleteNotification } from "../Modal/DeleteModal";

export default function NotificationSetting() {
  const form = useForm<AddEmployeeNotifications.AddNotification>({
    defaultValues: {
      employeeIds: undefined,
      message: undefined,
      notificationUserGroupId: undefined,
      title: undefined,
      action: undefined,
      scheduledTime: undefined,
      scheduleDate: undefined,
      type: undefined,
    },
  });
  const employeeNotificationModal = useDisclosure();
  const [deleteId, setDeleteId] = useState<string>("");
  const deleteModal = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [allNotifications, setAllNotifications] = useState<
    Array<AddEmployeeNotifications.getNotification>
  >([]);
  const [selectedNotificationId, setSelectedNotificationId] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    AddEmployeeNotifications.GetAllNotifications(
      {
        page: 1,
        limit: 1000,
      },
      (data) => {
        setAllNotifications(data?.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Stack
        bgColor="customColor.gray.400"
        p={"20px"}
        // h={"calc(100vh - 70px)"}
        // overflowY="auto"
      >
        <Container maxW="container.xl" p={0}>
          <Box
            justifyContent="center"
            alignItems="center"
            p="10px"
            borderBottom={"1px solid #d6dade"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <HStack justifyContent="space-between">
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight="bold"
                as="h2"
                size="md"
              >
                Employee Notifications
              </Heading>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                h={"40px"}
                borderRadius={"3px"}
                border={"primary.950"}
                variant={"solid"}
                padding={"12px 16px"}
                //   marginRight={"20px"}
                textAlign={"center"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  employeeNotificationModal.onOpen();
                  setSelectedNotificationId(undefined);
                  form.reset({
                    employeeIds: undefined,
                    message: undefined,
                    notificationUserGroupId: undefined,
                    title: undefined,
                    action: undefined,
                    scheduledTime: undefined,
                    scheduleDate: undefined,
                    type: undefined,
                  });
                }}
              >
                Add Notifications
              </Button>
            </HStack>
          </Box>
          <Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              borderRadius="4px"
              bg={"#fff"}
              boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              borderWidth="1px"
              borderStyle={"solid"}
              borderColor="#f6f6f6"
              p={"0px"}
            >
              <Box w="100%" overflowX="auto">
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      {/* <Th
                      padding="8px 26px 8px 30px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                      />
                    </Th> */}
                      {[
                        {
                          name: "Title",
                          value: "30%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "left",
                        },
                        {
                          name: "Total Employees",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "center",
                        },
                        {
                          name: "Recipients",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "center",
                        },
                        {
                          name: "Scheduled Time",
                          value: "20%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "center",
                        },
                        {
                          name: "Type",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "center",
                        },
                        {
                          name: "View Details",
                          value: "5%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "center",
                        },
                        {
                          name: "",
                          value: "5%",
                          paddingValue: "8px 26px 8px 15px",
                          align: "center",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            padding={item.paddingValue}
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                            width={item.value}
                            textAlign={item.align as any}
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allNotifications?.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          {/* <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            size="md"
                            borderColor="#adb5bd"
                            bgColor="white"
                          />
                        </Td> */}
                          <Td
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            padding="8px 26px 8px 15px"
                          >
                            {item?.title}
                          </Td>
                          <Td
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            textAlign="center"
                            padding="8px 26px 8px 15px"
                          >
                            {item?.employeeIds?.length}
                          </Td>
                          <Td
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            textAlign="center"
                            padding="8px 26px 8px 15px"
                          >
                            {item?.recipients?.length}
                          </Td>
                          <Td
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            textAlign="center"
                            padding="8px 26px 8px 15px"
                          >
                            {!!item?.scheduledTime
                              ? moment(item?.scheduledTime).format(
                                  "DD MMM YYYY hh:mm a"
                                )
                              : "NA"}
                          </Td>
                          <Td
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            textAlign="center"
                            padding="8px 26px 8px 15px"
                          >
                            {item?.type}
                          </Td>
                          <Td
                            alignItems="center"
                            justifyContent="center"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            padding="8px 26px 8px 15px"
                          >
                            <HStack alignItems="center" justifyContent="center">
                              {" "}
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                as={MdOutlineRemoveRedEye}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                //   onClick={() => {
                                //     if (item.active) {
                                //       setSelectedEarningId(item.id);

                                //       item.category === "ORDINARY_TIME_EARNINGS"
                                //         ? ordinaryTimeEarningModal.onOpen()
                                //         : item.category === "OVERTIME_EARNINGS"
                                //         ? overTimeEarningModal.onOpen()
                                //         : item.category === "ALLOWANCES"
                                //         ? allowanceModal.onOpen()
                                //         : item.category ===
                                //           "EMPLOYMENT_TERMINATION_PAYMENTS"
                                //         ? employmentTerminationModal.onOpen()
                                //         : item.category === "LUMP_SUM_E"
                                //         ? lumpSumEModal.onOpen()
                                //         : item.category === "BONUSES_AND_COMMISSIONS"
                                //         ? bonusesModal.onOpen()
                                //         : item.category === "LUMP_SUM_W"
                                //         ? wLumpSum.onOpen()
                                //         : item.category === "DIRECTORS_FEES"
                                //         ? directorsFeeModal.onOpen()
                                //         : item.category === "PAID_PARENTAL_LEAVE"
                                //         ? paidParentalLeaveModal.onOpen()
                                //         : workersModal.onOpen();
                                //     }
                                //   }}
                                onClick={() => {
                                  employeeNotificationModal.onOpen();
                                  setSelectedNotificationId(item.id);
                                }}
                              />
                            </HStack>
                          </Td>
                          <Td
                            padding="15px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Menu>
                              <MenuButton
                                as={Button}
                                backgroundColor={"transparent"}
                                h={"34.5px"}
                                marginLeft={"8px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                display={{
                                  lg: "flex",
                                  md: "flex",
                                  sm: "flex",
                                  xs: "flex",
                                }}
                                borderRadius={"full"}
                                _hover={{
                                  backgroundColor: "primary.50",
                                }}
                                _active={{
                                  backgroundColor: "primary.100",
                                }}
                              >
                                <Icon as={FiMoreVertical} />
                              </MenuButton>
                              <MenuList style={{ minWidth: "140px" }}>
                                {[
                                  {
                                    name: "Edit",
                                    onClick: () => {
                                      setSelectedNotificationId(item.id);
                                      employeeNotificationModal.onOpen();
                                    },
                                  },
                                  {
                                    name: "Delete",
                                    onClick: () => {
                                      setDeleteId(item.id);
                                      deleteModal.onOpen();
                                    },
                                  },
                                ].map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      as={Button}
                                      padding={"1px 0px 1px 20px"}
                                      _light={{
                                        color: "customColor.black7",
                                      }}
                                      _dark={{
                                        color: "customColor.white",
                                      }}
                                      onClick={item.onClick}
                                      _hover={{}}
                                    >
                                      <Button
                                        variant={"ghost"}
                                        fontWeight={"normal"}
                                        fontSize={"13px"}
                                        padding={"0px 20px 0px 0px"}
                                        _light={{
                                          color: "customColor.black7",
                                        }}
                                        _dark={{
                                          color: "customColor.white",
                                        }}
                                        _hover={{
                                          bg: "none",
                                        }}
                                      >
                                        {item.name}
                                      </Button>
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
                <EmployeeNotificationModal
                  isOpen={employeeNotificationModal.isOpen}
                  onClose={employeeNotificationModal.onClose}
                  setLastUpdated={setLastUpdated}
                  selectedNotificationId={selectedNotificationId}
                />
                <DeleteNotification
                  isOpen={deleteModal.isOpen}
                  onClose={deleteModal.onClose}
                  selectedId={deleteId}
                  setLastUpdated={setLastUpdated}
                />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </FormProvider>
  );
}
