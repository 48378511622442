import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import { isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Employees } from "../../Api/Employees";

export function AddEmployee() {
  const genderType: Array<{ label: string; value: string }> = [
    {
      label: "Male",
      value: "MALE",
    },
    {
      label: "Female",
      value: "FEMALE",
    },
  ];
  const titleOptions: Array<{ label: string; value: string }> = [
    {
      label: "Mr",
      value: "MR",
    },
    {
      label: "Ms",
      value: "Ms",
    },
    {
      label: "Mrs",
      value: "MRS",
    },
    {
      label: "Miss",
      value: "MISS",
    },
  ];
  const relationOptions: Array<{ label: string; value: string }> = [
    {
      label: "Father",
      value: "FATHER",
    },
    {
      label: "Mother",
      value: "MOTHER",
    },
    {
      label: "Spouse",
      value: "SPOUSE",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ];

  const martialStatusOptions = [
    { value: "MARRIED", label: "Married" },

    { value: "SINGLE", label: "Single" },
    { value: "WIDOWED", label: "Widowed" },
    { value: "DIVORCED", label: "Divorced" },
    { value: "SEPARATED", label: "Separated" },
    { value: "DEFACTO", label: "Defacto" },
    { value: "OTHER", label: "Other" },
    { value: "N/A", label: "N/A" },
  ];
  const bloodGroupOption = [
    { value: "O-", label: "O-" },
    { value: "O+", label: "O+" },
    { value: "A-", label: "A-" },
    { value: "A+", label: "A+" },
    { value: "B-", label: "B-" },
    { value: "B+", label: "B+" },
    { value: "AB-", label: "AB-" },
    { value: "AB+", label: "AB+" },
    { value: "N/A", label: "N/A" },
  ];
  const religionOption = [
    { value: "CHRISTIANITY", label: "Christianity" },
    { value: "ISLAM", label: "Islam" },
    { value: "JUDAISM", label: "Judaism" },
    { value: "ZOROASTRIANISM", label: "Zoroastrianism" },
    { value: "HINDUISM", label: "Hinduism" },
    { value: "BUDDHISM", label: "Buddhism" },
    { value: "ATHEIST", label: "Atheist/NonReligious" },
    { value: "SIKHISM", label: "Sikhism" },
    { value: "JAINISM", label: "Jainism" },
    { value: "OTHER", label: "Other Religions" },
    { value: "N/A", label: "N/A" },
  ];
  // const addressRegex = /^(?!\d+$)[A-Za-z0-9\s#,]+$/; old regex
  const addressRegex = /^(?!\d+$)[A-Za-z0-9\s#,\-\.]+$/;

  const form = useForm<Employees.AddNewEmployee>({
    defaultValues: {
      title: "",
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      mobileNumber: "",
      photo: "",
      dateOfBirth: "",
      gender: "",
      address: "",
      parentOrPartner: "",
      relationType: "",
      cnic: "",
      phoneNumber: "",
      sendInvitation: false,

      religion: "",
      maritalStatus: "",
      nationality: "",
      bloodGroup: "",
      passportNumber: undefined,
      passportExpiry: undefined,
      passportIssuance: undefined,
      driverLicenseNumber: undefined,
      driverLicenseExpiry: undefined,
      driverLicenseIssuance: undefined,
      province: "",
      city: "",
      country: "",
      postalCode: "",
    },
  });
  const today = new Date();
  const navigate = useNavigate();
  const toast = useToast();
  const { setLastUpdated } = useBusinessContext();
  const [displayValue, setDisplayValue] = useState("");
  useEffect(() => {
    setDisplayValue(formatDisplayValue(form.watch("cnic")));
  }, [form.watch("cnic")]);
  const formatDisplayValue = (value: any) => {
    const numericValue = value?.replace(/\D/g, "");
    if (numericValue?.length <= 5) {
      return numericValue;
    } else if (numericValue?.length <= 12) {
      return `${numericValue?.slice(0, 5)}-${numericValue?.slice(5, 12)}`;
    } else {
      return `${numericValue?.slice(0, 5)}-${numericValue?.slice(
        5,
        12
      )}-${numericValue?.slice(12, 13)}`;
    }
  };
  let countryData = Country.getAllCountries();
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find((country) => country.name === form.getValues("country"))
        ?.isoCode
    );
    const province = provinceOptions.map((province) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, [form.watch("country")]);
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <FormProvider {...form}>
      <Stack
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        h="calc(100vh - 70px)"
        overflowY="auto"
        gap={0}
      >
        <Stack padding={"30px"}>
          <Container
            padding={0}
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            border="1px"
            borderColor="#f6f6f6"
            borderRadius="md"
          >
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              borderBottom={"1px solid #e5e7eb"}
              fontWeight="700"
              padding="20px"
              _light={{
                color: "customColor.black7",
              }}
            >
              Personal Information
            </Heading>

            <Container
              maxW="800px"
              marginTop="15px"
              p="20px 20px 0px 20px"
              marginX="auto"
              color={"rgb(0,10,30,.75)"}
            >
              <Box
                fontSize={"0.8125rem"}
                fontWeight={"bold"}
                lineHeight={"1.3077"}
              >
                <Heading
                  marginBottom={"48px"}
                  alignContent={"left"}
                  fontSize={"0.9375rem"}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  Basic information
                </Heading>
                <Stack spacing="48px">
                  <HStack align="baseline">
                    <Stack flex={2}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Employee Id is required",
                          },
                        }}
                        control={form.control}
                        name="uniqueId"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            validationMessage={error?.message}
                            withValidation
                            ref={field.ref}
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",

                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "number",
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                            label="Employee Id"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={2}>
                      <FormControl>
                        <FormLabel
                          fontSize=".845rem"
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Title
                        </FormLabel>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Title is required",
                            },
                          }}
                          control={form.control}
                          name="title"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              options={titleOptions}
                              withValidation
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Please Select title"
                              placeholder="Select..."
                              value={titleOptions.find(
                                (op) => op.value === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.value);
                              }}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <Stack flex={3}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "First Name is required",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Only alphabetic characters are allowed",
                          },
                        }}
                        control={form.control}
                        name="firstName"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            validationMessage={error?.message}
                            withValidation
                            ref={field.ref}
                            input={{
                              ...field,

                              isInvalid: error !== undefined,
                              w: "100%",

                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                            label="First name"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={3}>
                      <Controller
                        control={form.control}
                        name="middleName"
                        rules={{
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Only alphabetic characters are allowed",
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            withValidation
                            validationMessage={error?.message}
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "40px",

                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",
                              padding: "12px 15px",

                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                            label="Middle name (optional)"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                      {/* <CustomInput
                      input={{
                        w: "100%",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        border: "1px solid #a6a9b0",
                        borderTopRadius: "3px",
                      }}
                      placeholder=""
                      label="Middle name (optional)"
                    /> */}
                    </Stack>
                    <Stack flex={3}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Last Name is required",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Only alphabetic characters are allowed",
                          },
                        }}
                        control={form.control}
                        name="lastName"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "40px",
                              fontSize: ".845rem",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                            label="Last name"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <HStack align="baseline">
                    <Stack flex={1}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Date of Birth is required",
                          },
                          validate: {
                            isNotFuture: (value) =>
                              isAfter(today, new Date(value)) ||
                              "Date cannot be a future date",
                            isNot18: (value) => {
                              const dob = new Date(value);
                              const today = new Date();
                              let age = today.getFullYear() - dob.getFullYear();
                              const monthDiff =
                                today.getMonth() - dob.getMonth();
                              if (
                                monthDiff < 0 ||
                                (monthDiff === 0 &&
                                  today.getDate() < dob.getDate())
                              ) {
                                age--;
                              }
                              return (
                                age >= 18 || "You must be at least 18 years old"
                              );
                            },
                          },
                        }}
                        control={form.control}
                        name="dateOfBirth"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "date",

                              borderTopRadius: "3px",
                              max: "9999-12-31",
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Date of birth"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack gap={0} flex={1}>
                      <Text
                        fontSize=".845rem"
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px 0px"}
                      >
                        Gender
                      </Text>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Gender type is required",
                          },
                        }}
                        control={form.control}
                        name="gender"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            withValidation
                            isInvalid={fieldState.invalid}
                            key={field.value}
                            options={genderType}
                            ErrorMessage="Please Select gender"
                            placeholder="Select..."
                            value={genderType.find(
                              (op) => op.value === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={1}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Nationality is required",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Only alphabetic characters are allowed",
                          },
                        }}
                        control={form.control}
                        name="nationality"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder="Pakistani"
                            label="Nationality"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <HStack align={"baseline"}>
                    <Stack gap={0} flex={1}>
                      <Text
                        fontSize=".845rem"
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px 0px"}
                      >
                        Religion
                      </Text>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Religion is required",
                          },
                        }}
                        control={form.control}
                        name="religion"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            options={religionOption}
                            withValidation
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Please Select religion"
                            placeholder="Select..."
                            value={religionOption.find(
                              (op) => op.value === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </Stack>

                    <Stack gap={0} flex={1}>
                      <Text
                        fontSize=".845rem"
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px 0px"}
                      >
                        Blood Group
                      </Text>
                      <Controller
                        control={form.control}
                        name="bloodGroup"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            options={bloodGroupOption}
                            withValidation
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Please Select blood Group"
                            placeholder="Select..."
                            value={bloodGroupOption.find(
                              (op) => op.value === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </Stack>

                    <Stack gap={0} flex={1}>
                      <Text
                        fontSize=".845rem"
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px 0px"}
                      >
                        Martial status
                      </Text>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Marital status is required",
                          },
                        }}
                        control={form.control}
                        name="maritalStatus"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            placeholder="Select marital status Type"
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Select marital status Type"
                            withValidation
                            options={martialStatusOptions}
                            value={martialStatusOptions.find(
                              (op) => op.value === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.value);
                            }}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <HStack align="baseline">
                    <Stack flex={2}>
                      <FormControl>
                        <FormLabel
                          fontSize=".845rem"
                          fontWeight="600"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          margin={"0px 0px 8px 0px"}
                        >
                          Select Relation
                        </FormLabel>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Select relation is required",
                            },
                          }}
                          control={form.control}
                          name="relationType"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              options={relationOptions}
                              withValidation
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Please Select title"
                              placeholder="Select..."
                              value={relationOptions.find(
                                (op) => op.value === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.value);
                              }}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <Stack flex={5}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Only alphabetic characters are allowed",
                          },
                        }}
                        control={form.control}
                        name="parentOrPartner"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                            }}
                            placeholder=""
                            label="Name"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <HStack alignItems={"baseline"}>
                    <Stack flex={4}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "CNIC is required",
                          },
                          minLength: {
                            value: 13,
                            message: "CNIC must be 13 characters",
                          },
                          maxLength: {
                            value: 13,
                            message: "CNIC must be 13 characters",
                          },
                        }}
                        control={form.control}
                        name="cnic"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "40px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",

                              borderTopRadius: "3px",
                              value: displayValue,
                              minLength: 15,

                              maxLength: 15,
                              onChange: (e) => {
                                const formattedValue = formatDisplayValue(
                                  e.target.value
                                );
                                setDisplayValue(formattedValue);
                                field.onChange(
                                  e.target.value.replace(/\D/g, "")
                                );
                              },
                            }}
                            placeholder="361**-833****-*"
                            label="CNIC"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>

                    <Stack flex={2}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Issue Date is required",
                          },
                          validate: {
                            isNotFuture: (value) =>
                              isAfter(today, new Date(value)) ||
                              "Date cannot be a future date",
                            isBeforeCnicExpiry: (value) =>
                              !form.getValues("cnicExpiry") ||
                              isAfter(
                                new Date(form.getValues("cnicExpiry")),
                                new Date(value)
                              ) ||
                              "Cnic Issue must be before Cnic Expiry",
                            isAfterDOB: (value) => {
                              const dob = new Date(
                                form.getValues("dateOfBirth")
                              );
                              const issuanceDate = new Date(value);
                              const minIssuanceDate = new Date(
                                dob.getFullYear() + 18,
                                dob.getMonth(),
                                dob.getDate()
                              );
                              return (
                                (isAfter(issuanceDate, dob) ||
                                  "Issue Date must be after Date of Birth") &&
                                (isAfter(issuanceDate, minIssuanceDate) ||
                                  "Issuance Date must be at least 18 years after Date of Birth")
                              );
                            },
                          },
                        }}
                        control={form.control}
                        name="cnicIssuance"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              fontSize: ".845rem",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              variant: "outline",
                              type: "date",
                              borderTopRadius: "3px",
                              max: "9999-12-31",
                              onChange: (e) => {
                                // Set to null if the input is empty
                                const newValue =
                                  e.target.value === "" ? null : e.target.value;
                                field.onChange(newValue);
                              },
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Issue date"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={2}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Expiry Date is required",
                          },
                          validate: {
                            isAfterCnicIssue: (value) =>
                              !form.getValues("cnicIssuance") ||
                              isAfter(
                                new Date(value),
                                new Date(form.getValues("cnicIssuance"))
                              ) ||
                              "Cnic Expiry date must be after Cnic Issue",

                            isAfterDOB: (value) =>
                              isAfter(
                                new Date(value),
                                new Date(form.getValues("dateOfBirth"))
                              ) || "Expiry Date must be after Date of Birth",

                            hasTenYearDifference: (value) => {
                              const cnicIssueDate = new Date(
                                form.getValues("cnicIssuance")
                              );
                              const cnicExpiryDate = new Date(value);

                              // Calculate the expected expiry date which is 10 years minus 1 day from the issue date
                              const expectedExpiryDate = new Date(
                                cnicIssueDate.getFullYear() + 10,
                                cnicIssueDate.getMonth(),
                                cnicIssueDate.getDate() - 1
                              );

                              return (
                                isAfter(cnicExpiryDate, expectedExpiryDate) ||
                                "CNIC Expiry and CNIC Issue must have a difference of at least 10 years "
                              );
                            },
                          },
                        }}
                        control={form.control}
                        name="cnicExpiry"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              fontSize: ".845rem",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              onChange: (e) => {
                                // Set to null if the input is empty
                                const newValue =
                                  e.target.value === "" ? null : e.target.value;
                                field.onChange(newValue);
                              },
                              variant: "outline",
                              type: "date",
                              borderTopRadius: "3px",
                              max: "9999-12-31",
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Expiry date"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  {/* <HStack alignItems={"baseline"}>
                    <Stack flex={4}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "CNIC is required",
                          },
                          minLength: {
                            value: 13,
                            message: "CNIC must be 13 characters",
                          },
                          maxLength: {
                            value: 13,
                            message: "CNIC must be 13 characters",
                          },
                        }}
                        control={form.control}
                        name="cnic"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              h: "38px",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "text",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },

                              borderTopRadius: "3px",
                              value: displayValue,
                              minLength: 15,
                              maxLength: 15,
                              onChange: (e) => {
                                const formattedValue = formatDisplayValue(
                                  e.target.value
                                );
                                setDisplayValue(formattedValue);
                                field.onChange(
                                  e.target.value.replace(/\D/g, "")
                                );
                              },
                            }}
                            placeholder="361**-833****-*"
                            label="CNIC"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={2}>
                      <Controller
                        rules={{
                          validate: {
                            isNotFuture: (value) => {
                              if (!value) return true; // Skip validation if value is undefined, null, or empty string
                              return (
                                isAfter(today, new Date(value)) ||
                                "Date cannot be a future date"
                              );
                            },
                            isBeforeCnicExpiry: (value) => {
                              if (!value) return true; // Skip validation if value is undefined, null, or empty string
                              return (
                                !form.getValues("cnicExpiry") ||
                                isAfter(
                                  new Date(form.getValues("cnicExpiry")),
                                  new Date(value)
                                ) ||
                                "CNIC Issue must be before CNIC Expiry"
                              );
                            },
                            isAfterDOB: (value) => {
                              if (!value) return true; // Skip validation if value is undefined, null, or empty string
                              return (
                                isAfter(
                                  new Date(value),
                                  new Date(form.getValues("dateOfBirth"))
                                ) || "Issue Date must be after Date of Birth"
                              );
                            },
                          },
                        }}
                        control={form.control}
                        name="cnicIssuance"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              onChange: (e) => {
                                // Set to null if the input is empty
                                const newValue =
                                  e.target.value === "" ? null : e.target.value;
                                field.onChange(newValue);
                              },
                              w: "100%",
                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "date",

                              borderTopRadius: "3px",
                              max: "9999-12-31",
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Issue date"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={2}>
                      <Controller
                        rules={{
                          validate: {
                            isAfterCnicIssue: (value) => {
                              if (!value) return true; // Skip validation if value is undefined, null, or empty string
                              return (
                                !form.getValues("cnicIssuance") ||
                                isAfter(
                                  new Date(value),
                                  new Date(form.getValues("cnicIssuance"))
                                ) ||
                                "CNIC Expiry date must be after CNIC Issue"
                              );
                            },
                            isAfterDOB: (value) => {
                              if (!value) return true; // Skip validation if value is undefined, null, or empty string
                              return (
                                isAfter(
                                  new Date(value),
                                  new Date(form.getValues("dateOfBirth"))
                                ) || "Expiry Date must be after Date of Birth"
                              );
                            },
                            hasTenYearDifference: (value) => {
                              if (!value) return true; // Skip validation if value is undefined, null, or empty string
                              const cnicIssueDate = new Date(
                                form.getValues("cnicIssuance")
                              );
                              const cnicExpiryDate = new Date(value);
                              const differenceInYears =
                                cnicExpiryDate.getFullYear() -
                                cnicIssueDate.getFullYear();
                              const monthsDifference =
                                cnicExpiryDate.getMonth() -
                                cnicIssueDate.getMonth();
                              if (
                                monthsDifference < 0 ||
                                (monthsDifference === 0 &&
                                  cnicExpiryDate.getDate() <
                                    cnicIssueDate.getDate())
                              ) {
                                return (
                                  differenceInYears - 1 >= 10 ||
                                  "CNIC Expiry and CNIC Issue must have a difference of at least 10 years"
                                );
                              }
                              return (
                                differenceInYears >= 10 ||
                                "CNIC Expiry and CNIC Issue must have a difference of at least 10 years"
                              );
                            },
                          },
                        }}
                        control={form.control}
                        name="cnicExpiry"
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              onChange: (e) => {
                                // Set to null if the input is empty
                                const newValue =
                                  e.target.value === "" ? null : e.target.value;
                                field.onChange(newValue);
                              },
                              w: "100%",
                              h: "40px",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "date",

                              borderTopRadius: "3px",
                              max: "9999-12-31",
                            }}
                            placeholder="dd/mm/yyyy"
                            label="Expiry date"
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </HStack> */}
                </Stack>
              </Box>
              <Divider orientation="horizontal" paddingTop={"20px"} />
              <Stack paddingTop={"30px"} spacing="48px">
                <HStack alignItems={"baseline"}>
                  <Stack flex={4}>
                    <Controller
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Passport Number is required",
                      //   },
                      // }}
                      control={form.control}
                      name="driverLicenseNumber"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            h: "40px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                          }}
                          placeholder="Enter Driving License Number"
                          label="Driver License (optional)"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={2}>
                    <Controller
                      control={form.control}
                      name="driverLicenseIssuance"
                      rules={{
                        validate: {
                          isNotFuture: (value) =>
                            !value ||
                            isAfter(today, new Date(value)) ||
                            "Date cannot be a future date",

                          isBeforeDriverLicenseExpiry: (value) =>
                            !value ||
                            !form.getValues("driverLicenseExpiry") ||
                            isAfter(
                              new Date(form.getValues("driverLicenseExpiry")!),
                              new Date(value)
                            ) ||
                            "Driving License Issue Date must be before Driver License Expiry",

                          isAfterDOB: (value) => {
                            if (!value) return true; // Allow empty value to pass validation

                            const dob = new Date(form.getValues("dateOfBirth"));
                            const issuanceDate = new Date(value);
                            const minIssuanceDate = new Date(
                              dob.getFullYear() + 18,
                              dob.getMonth(),
                              dob.getDate()
                            );

                            if (!isAfter(issuanceDate, dob)) {
                              return "Issue Date must be after Date of Birth";
                            }

                            if (!isAfter(issuanceDate, minIssuanceDate)) {
                              return "Issue Date must be at least 18 years after Date of Birth";
                            }

                            return true;
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "38px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "date",
                            borderTopRadius: "3px",
                            max: "9999-12-31",
                            onChange: (e) => {
                              // Set to null if the input is empty
                              const newValue =
                                e.target.value === "" ? null : e.target.value;
                              field.onChange(newValue);
                            },
                          }}
                          placeholder="dd/mm/yyyy"
                          label="Issue date"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={2}>
                    <Controller
                      control={form.control}
                      name="driverLicenseExpiry"
                      rules={{
                        validate: {
                          isAfterDrivingLicenseIssue: (value) =>
                            value === undefined ||
                            value === "" ||
                            value === null ||
                            !form.getValues("driverLicenseIssuance") ||
                            isAfter(
                              new Date(value),
                              new Date(form.getValues("driverLicenseIssuance")!)
                            ) ||
                            "Driver License Expiry date must be after Driver License Issuance",

                          isAfterDOB: (value) =>
                            value === undefined ||
                            value === "" ||
                            value === null ||
                            isAfter(
                              new Date(value),
                              new Date(form.getValues("dateOfBirth"))
                            ) ||
                            "Expiry Date must be after Date of Birth",

                          hasFiveYearDifference: (value) => {
                            if (!value) {
                              return true;
                            }

                            const drivingLicenseDate = new Date(
                              form.getValues("driverLicenseIssuance")!
                            );
                            const expiryDate = new Date(value);

                            // Calculate the expected expiry date (5 years minus 1 day from the issuance date)
                            const expectedExpiryDate = new Date(
                              drivingLicenseDate.getFullYear() + 5,
                              drivingLicenseDate.getMonth(),
                              drivingLicenseDate.getDate() - 1
                            );

                            return (
                              isAfter(expiryDate, expectedExpiryDate) ||
                              "Driver License Expiry and Driver License Issue must have a difference of at least 5 years "
                            );
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "38px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "date",

                            borderTopRadius: "3px",
                            max: "9999-12-31",
                            onChange: (e) => {
                              // Set to null if the input is empty
                              const newValue =
                                e.target.value === "" ? null : e.target.value;
                              field.onChange(newValue);
                            },
                          }}
                          placeholder="dd/mm/yyyy"
                          label="Expiry date"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </HStack>
              </Stack>
              <Divider orientation="horizontal" paddingTop={"20px"} />
              <Stack paddingTop={"30px"} spacing="35px">
                <HStack alignItems={"baseline"}>
                  <Stack flex={4}>
                    <Controller
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Passport Number is required",
                      //   },
                      // }}
                      control={form.control}
                      name="passportNumber"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                          }}
                          placeholder="Enter Passport Number (Optional)"
                          label="Passport"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack flex={2}>
                    <Controller
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Issue Date is required",
                      //   },
                      // }}
                      control={form.control}
                      name="passportIssuance"
                      rules={{
                        validate: {
                          isNotFuture: (value: any) =>
                            value === undefined ||
                            value === "" ||
                            value === null ||
                            isAfter(today, new Date(value)) ||
                            "Date cannot be a future date",
                          isBeforePassportExpiry: (value) =>
                            value === undefined ||
                            value === "" ||
                            value === null ||
                            !form.getValues("passportExpiry") ||
                            isAfter(
                              new Date(form.getValues("passportExpiry")!),
                              new Date(value!)
                            ) ||
                            "Passport Issue Date must be before Passport Expiry",
                          // isAfterDOB: (value) =>
                          //   value === undefined ||
                          //   value === "" ||
                          //   value === null ||
                          //   isAfter(
                          //     new Date(value!),
                          //     new Date(form.getValues("dateOfBirth"))
                          //   ) ||
                          //   "Expiry Date must be after Date of Birth",
                          isAfterDOB: (value) => {
                            if (!value) return true; // Allow empty value to pass validation

                            const dob = new Date(form.getValues("dateOfBirth"));
                            const issuanceDate = new Date(value);
                            const minIssuanceDate = new Date(
                              dob.getFullYear() + 18,
                              dob.getMonth(),
                              dob.getDate()
                            );

                            if (!isAfter(issuanceDate, dob)) {
                              return "Issue Date must be after Date of Birth";
                            }

                            if (!isAfter(issuanceDate, minIssuanceDate)) {
                              return "Issue Date must be at least 18 years after Date of Birth";
                            }

                            return true;
                          },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "38px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "date",
                            onChange: (e) => {
                              // Set to null if the input is empty
                              const newValue =
                                e.target.value === "" ? null : e.target.value;
                              field.onChange(newValue);
                            },
                            borderTopRadius: "3px",
                            max: "9999-12-31",
                          }}
                          placeholder="dd/mm/yyyy"
                          label="Issue date"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={2}>
                    <Controller
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Expiry Date is required",
                      //   },
                      // }}
                      control={form.control}
                      name="passportExpiry"
                      rules={{
                        validate: {
                          isAfterPassportIssue: (value) =>
                            value === undefined ||
                            value === "" ||
                            value === null ||
                            !form.getValues("passportIssuance") ||
                            isAfter(
                              new Date(value!),
                              new Date(form.getValues("passportIssuance")!)
                            ) ||
                            "Passport Expiry date must be after Passport Issuance",

                          isAfterDOB: (value) =>
                            value === undefined ||
                            value === "" ||
                            value === null ||
                            isAfter(
                              new Date(value!),
                              new Date(form.getValues("dateOfBirth"))
                            ) ||
                            "Expiry Date must be after Date of Birth",
                          hasFiveYearDifference: (value) => {
                            if (!value) {
                              return true;
                            }

                            const passportDate = new Date(
                              form.getValues("driverLicenseIssuance")!
                            );
                            const expiryDate = new Date(value);

                            // Calculate the expected expiry date (5 years minus 1 day from the issuance date)
                            const expectedExpiryDate = new Date(
                              passportDate.getFullYear() + 5,
                              passportDate.getMonth(),
                              passportDate.getDate() - 1
                            );

                            return (
                              isAfter(expiryDate, expectedExpiryDate) ||
                              "Passport Expiry and Passport Issue must have a difference of at least 5 years "
                            );
                          },
                          // hasfiveYearDifference: (value) => {
                          //   if (
                          //     value === undefined ||
                          //     value === "" ||
                          //     value === null
                          //   ) {
                          //     // Handle the case where value is undefined or an empty string
                          //     return true; // or return a message specific to this case if necessary
                          //   }
                          //   const passportDate = new Date(
                          //     form.getValues("passportIssuance")!
                          //   );
                          //   const passportExpiry = new Date(value!);
                          //   const differenceInYears =
                          //     passportExpiry.getFullYear() -
                          //     passportDate.getFullYear();
                          //   const monthsDifference =
                          //     passportExpiry.getMonth() - passportDate.getMonth();
                          //   if (
                          //     monthsDifference < 0 ||
                          //     (monthsDifference === 0 &&
                          //       passportExpiry.getDate() < passportDate.getDate())
                          //   ) {
                          //     return (
                          //       differenceInYears - 1 >= 5 ||
                          //       "Driver License Expiry and Driver License Issue must have a difference of at least 5 years"
                          //     );
                          //   }
                          //   return (
                          //     differenceInYears >= 5 ||
                          //     "Driver License Expiry and Driver License Issue must have a difference of at least 5 years"
                          //   );
                          // },
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "date",

                            borderTopRadius: "3px",
                            max: "9999-12-31",
                            onChange: (e) => {
                              // Set to null if the input is empty
                              const newValue =
                                e.target.value === "" ? null : e.target.value;
                              field.onChange(newValue);
                            },
                          }}
                          placeholder="dd/mm/yyyy"
                          label="Expiry date"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </HStack>
              </Stack>
              <Divider orientation="horizontal" paddingTop={"20px"} />
              <Stack paddingTop={"30px"} spacing="48px">
                <Heading
                  alignContent={"left"}
                  fontSize={"0.9375rem"}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  Contact information
                </Heading>
                <HStack alignItems={"baseline"}>
                  <Stack flex={1}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Mobile number is required",
                        },
                        minLength: {
                          value: 11,
                          message: "Mobile Number must be 11 characters",
                        },
                        maxLength: {
                          value: 14,
                          message: "Mobile Number must be 14 characters",
                        },
                      }}
                      control={form.control}
                      name="mobileNumber"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "number",

                            borderTopRadius: "3px",
                            minLength: 11,
                            maxLength: 14,
                          }}
                          placeholder=""
                          label="Mobile number"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={1}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Phone number is required",
                        },
                        minLength: {
                          value: 11,
                          message: "Phone number must be 11 characters",
                        },
                        maxLength: {
                          value: 14,
                          message: "Phone number must be 14 characters",
                        },
                      }}
                      control={form.control}
                      name="phoneNumber"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "number",

                            borderTopRadius: "3px",
                            minLength: 11,
                            maxLength: 14,
                          }}
                          placeholder=""
                          label="Phone number"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={2}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Email is required",
                        },
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email format",
                        },
                      }}
                      control={form.control}
                      name="email"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "email",

                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                          label="Email"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </HStack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Address is required",
                    },
                    // validate: {
                    //   notOnlyNumbers: (value) =>
                    //     addressRegex.test(value) ||
                    //     "Address shouldn't only contain numbers",
                    // },
                  }}
                  control={form.control}
                  name="address"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "40px",
                        fontSize: ".845rem",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Address"
                      label="Address"
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
                <HStack alignItems={"baseline"}>
                  <Stack flex={2} gap={0}>
                    <Text
                      fontSize=".845rem"
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      margin={"0px 0px 8px 0px"}
                    >
                      Country
                    </Text>

                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Country is required",
                        },
                      }}
                      control={form.control}
                      name="country"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          placeholder="Select Country"
                          // marginBottom="30px"
                          // width={"48em"}
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Select Country"
                          withValidation
                          options={countryData}
                          value={countryData.find(
                            (op) => op.name === field.value
                          )}
                          onChange={(newValue: any) =>
                            field.onChange(newValue?.name)
                          }
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.name}
                        />
                      )}
                    />
                  </Stack>
                  <Stack flex={1}>
                    <FormControl>
                      <FormLabel
                        fontSize=".845rem"
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        margin={"0px 0px 8px 0px"}
                      >
                        Province
                      </FormLabel>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Province is required",
                          },
                        }}
                        control={form.control}
                        name="province"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            key={field.value}
                            placeholder="Select..."
                            // marginBottom="30px"
                            // width={"48em"}
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Select Province"
                            withValidation
                            options={provinces}
                            value={provinces.find(
                              (op) => op.name === field.value
                            )}
                            onChange={(newValue: any) =>
                              field.onChange(newValue?.name)
                            }
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.name}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack flex={2}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "City is required",
                        },
                        pattern: {
                          value: /^[A-Za-z ]+$/,
                          message: "Only alphabetic characters are allowed",
                        },
                      }}
                      control={form.control}
                      name="city"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                          label="City"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack flex={1}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "ZipCode is required",
                        },
                      }}
                      control={form.control}
                      name="postalCode"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            h: "40px",
                            fontSize: ".845rem",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            variant: "outline",
                            type: "number",

                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                          label="Zip Code"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </HStack>

                <Controller
                  control={form.control}
                  name="sendInvitation"
                  render={({ field }) => (
                    <Checkbox
                      isChecked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    >
                      <Text
                        fontSize={".845rem"}
                        fontWeight={"600"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                      >
                        Invite employee to HRIS 360 Me
                      </Text>
                    </Checkbox>
                  )}
                />
              </Stack>
            </Container>
            <Stack
              h={"80px"}
              borderTop={"1px solid #e5e7eb"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingRight={"20px"}
              direction={"row"}
              marginTop={"30px"}
            >
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  navigate("/app/payroll/PayrollEmployee");
                }}
              >
                Cancel
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                border={"primary.950"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  setIsLoading(true);
                  form.handleSubmit(
                    (data) => {
                      Employees.AddEmployee(
                        data,
                        (res) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Employee Added Successfully",
                            status: "success",
                          });
                          setIsLoading(false);

                          navigate(`/app/payroll/EmployeeDetail/${res?.id}`);
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                Save
              </Button>
            </Stack>
          </Container>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
