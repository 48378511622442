import { axiosInstance } from "./axiosInstance-hris";

declare module PayItemRetirement {
  type AddRetirementFund = {
    id?: string;
    category: string;
    name: string;
    province?: string;
    minimumWage?: number;
    percentage?: number;
    chartOfAccountId?: string;
    showOnPayslip: boolean;
    earningPayItems?: Array<string>;
  };
  type FetchRetirementFund = {
    id: string;
    category: string;
    name: string;
    province: string;
    minimumWage: number | null;
    percentage: number | null;
    active: boolean;
    businessId: string;
    chartOfAccountId: string;
    earningPayItems: Array<string>;
    showOnPayslip: boolean;
  };
}
module PayItemRetirement {
  const API_URL = "/payroll-pay-items-retirements";

  export function AddRetirementFund(
    data: AddRetirementFund,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }

  export function UpdateRetirementFund(
    id: string,
    data: Partial<AddRetirementFund>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
  export function FetchRetirementFund(
    success: (data: Array<FetchRetirementFund>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
  export function FetchRetirementFundById(
    id: string,
    success: (data: FetchRetirementFund) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
  export function DeleteRetirementFund(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
}
export { PayItemRetirement };
