import { Box, Button, Stack, useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useParams } from "react-router-dom";
import { UploadAttachments } from "../../../../Api/UploadAttachments";
import Document from "./Document";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function DocumentTable({
  setSelectedTab,
  selectedTab,
}: TabProps) {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());

  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [requiredDocuments, setRequiredDocuments] = useState<
    Array<UploadAttachments.requiredDocument>
  >([]);
  const { id } = useParams();
  const [GetAllDocuments, setGetAllDocuments] = useState<
    Array<UploadAttachments.Document>
  >([]);
  const toast = useToast();
  useEffect(() => {
    if (id) {
      UploadAttachments.GetAllDocuments(
        id,
        (success) => {
          setGetAllDocuments(success?.uploadedDocuments ?? []);
          setRequiredDocuments(success?.requiredDocuments ?? []);
        },
        () => {
          toast({
            title: "Error",
            description: "Error fetching documents",
            status: "error",
          });
        }
      );
    }
  }, [lastUpdated]);
  const filteredDocuments = GetAllDocuments.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.documentType]: [...(acc?.[curr.documentType] ?? []), curr],
    };
  }, {} as { [key: string]: UploadAttachments.Document[] });
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.50",
        color: "customColor.black7",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      padding="20px"
      marginBottom={"1.25rem"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Box overflowX="auto">
        {requiredDocuments.map((doc, index) => (
          <Document
            key={index}
            type={doc?.title}
            setLastUpdated={setLastUpdated}
            remainingDocuments={doc?.remainingDocuments}
            progress={`Upload max ${doc?.maxDocs} documents`}
            docType={doc?.docType}
            GetAllDocuments={GetAllDocuments}
            setGetAllDocuments={setGetAllDocuments}
            filteredDocuments={filteredDocuments[doc?.docType] ?? []}
          />
        ))}
        {/* </Tbody>
        </Table> */}
        {decodedToken.accessLevel !== "EMPLOYEE" && (
          <Stack alignItems={"flex-end"}>
            <Button
              colorScheme="green"
              border={"1px solid green"}
              textColor={"customColor.white"}
              borderRadius={"3px"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}
