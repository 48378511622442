import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { PayrollHoliday } from "../../../Api/PayRollHoliday";
import { PayrollHolidayGroup } from "../../../Api/PayrollHolidayGroup";

interface AddHolidayProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (time: number) => void;
  selectedHolidayId: string | undefined;
}

export function AddHoliday({
  isOpen,
  onClose,
  setLastUpdated,
  selectedHolidayId,
}: AddHolidayProps) {
  const form = useFormContext<PayrollHoliday.AddHoliday>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [holidayGroupOptions, setHolidayGroupOptions] = useState<
    PayrollHolidayGroup.HolidayGroup[]
  >([]);
  useEffect(() => {
    PayrollHolidayGroup.FetchHolidayGroup(
      (data) => {
        setHolidayGroupOptions(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Failed",
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);
  const toast = useToast();

  return (
    <FormProvider {...form}>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="18px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
          >
            {selectedHolidayId ? "Edit Holiday" : "Add Holiday"}
          </ModalHeader>
          <Divider orientation="horizontal" />
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel
                marginTop="10px"
                fontSize="12px"
                fontWeight={600}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
              >
                Name
              </FormLabel>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "name is required",
                  },
                }}
                control={form.control}
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      fontSize: ".845rem",
                      borderRadius: "3px",
                      padding: "12px 15px",
                      variant: "outline",
                      type: "text",

                      // value: watchPayPeriod,
                    }}
                    placeholder="Enter Name"
                  />
                )}
              />
              <FormLabel
                marginTop="10px"
                fontSize="12px"
                fontWeight={600}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
              >
                Date
              </FormLabel>
              <Controller
                name="date"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Select date",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      defaultValue: "",
                      fontSize: ".845rem",
                      borderRadius: "3px",
                      padding: "12px 15px",
                      variant: "outline",
                      type: "date",

                      alignContent: "center",
                    }}
                    placeholder=" "
                  />
                )}
              />

              <FormLabel
                marginTop="10px"
                fontSize="12px"
                fontWeight={600}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
              >
                Add to Group{" "}
              </FormLabel>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Select Holiday Group type",
                  },
                }}
                control={form.control}
                name="holidaysGroupId"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select"
                    defaultValue={"804: Wages Payable - Payroll"}
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Holiday Group"
                    withValidation
                    options={holidayGroupOptions}
                    value={{
                      id: field.value,
                      groupName:
                        holidayGroupOptions.find(
                          (holidayGroupOptions) =>
                            holidayGroupOptions.id === field.value
                        )?.groupName ?? "",
                    }}
                    onChange={(newValue: any) => field.onChange(newValue?.id)}
                    getOptionLabel={(option: any) => option.groupName}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex direction="row" justifyContent="end">
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (selectedHolidayId) {
                      PayrollHoliday.UpdateHoliday(
                        selectedHolidayId,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Holiday is updated",
                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Holiday Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayrollHoliday.CreateHoliday(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Holiday is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Holiday Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {selectedHolidayId ? "Edit" : "Add"}
              </Button>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                marginLeft={"8px"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
