import { Button, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import JoyRide from "react-joyride";
export default function SampleForOnBoarding() {
  const [start, setStart] = useState(false);
  const [steps] = useState([
    {
      content: <h2>Let's begin our journey!</h2>,
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      target: ".body",
    },
    {
      content: <h2>Here is first step!</h2>,
      placement: "bottom",
      target: "#steps-1",
      title: "First step",
    },
    {
      content: <h2>Here is second step!</h2>,
      placement: "bottom",
      target: ".steps-2",
      title: "Second step",
    },
    {
      content: <h2>Here is third step!</h2>,
      placement: "bottom",
      target: ".steps-3",
      title: "Third step",
    },
    {
      content: <h2>Here is fourth step!</h2>,
      placement: "bottom",
      target: ".steps-4",
      title: "Fourth step",
    },
    {
      content: <h2>Here is fifth step!</h2>,
      placement: "bottom",
      target: ".steps-5",
      title: "Fifth step",
    },
    {
      content: <h2>Here is sixth step!</h2>,
      placement: "bottom",
      target: ".steps-6",
      title: "Sixth step",
    },
  ]);

  return (
    <Stack
      bg={"#797979"}
      h={"100vh"}
      display={"flex"}
      gap={"8px"}
      padding={10}
      color={"white"}
      direction={"row"}
    >
      <JoyRide
        continuous
        callback={() => {}}
        run={start}
        // steps={steps}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
      />
      <Button onClick={() => setStart(!start)}>Start Tour</Button>
      {[1, 2, 3, 4, 5, 6].map((item) => {
        return (
          <Stack
            key={item}
            id={`step-${item}`}
            bg={"#0c1d2b"}
            border={"1px solid white"}
            width={"100px"}
            h={"100px"}
            borderRadius={"8px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            textColor={"white"}
          >
            <Text>{item}</Text>
          </Stack>
        );
      })}
    </Stack>
  );
}
