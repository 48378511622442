import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { NextOfKinApi } from "../../../../../Api/NextOfKin";

interface DeleteKinProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  setLastUpdated: (time: number) => void;
}
export default function DeleteKin({
  isOpen,
  onClose,
  id,
  setLastUpdated,
}: DeleteKinProps) {
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {" "}
            Are you sure you want to delete this
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fontSize={"13px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            onClick={() => {
              NextOfKinApi.DeleteNextOfKin(
                id,
                (success) => {
                  toast({
                    title: "Next Of Kin Deleted Successfully",
                    status: "success",
                  });

                  setLastUpdated(Date.now());
                  onClose();
                },
                (err) => {
                  toast({
                    title: "Error Deleting Next Of Kin",
                    description: "err",
                    status: "error",
                  });
                }
              );
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
