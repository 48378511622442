import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { EmployeeLeaveEntitlement } from "../../../Api/EmployeeLeaveEntitlement";

interface DeleteLeaveEntitlementProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  setLastUpdated: (timestamp: number) => void;
  CloseEntitlementModal?: () => void;
}
export function DeleteLeaveEntitlement({
  isOpen,
  onClose,
  id,
  setLastUpdated,
  CloseEntitlementModal,
}: DeleteLeaveEntitlementProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"350px"}>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text fontSize={"18px"} padding={"15px 0px"}>
            Delete leave type
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack
            color={"customColor.gray.800"}
            _dark={{
              color: "customColor.dark.250",
            }}
            fontSize={"12px"}
            gap={0}
          >
            <Text fontWeight={"normal"}>
              Are you sure you want to delete this leave type?
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              variant="solid"
              fontSize={"13px"}
              margin={"0px 0px 0px 10px"}
              padding={"10px 15px"}
              borderRadius={"3px"}
              minH={"40px"}
              border={"1px solid #FDEDED"}
              bg={"#FDEDED"}
              color={"#EA4D4D"}
              minW={"75px"}
              _hover={{
                border: "1px solid #EA4D4D",
                bg: "#EA4D4D",
                color: "#fff",
              }}
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                EmployeeLeaveEntitlement.DeleteLeaveEntitlement(
                  id,
                  () => {
                    toast({
                      title: "Success",
                      description: "Leave type deleted successfully",
                      status: "success",
                    });
                    setIsLoading(false);
                    setLastUpdated(Date.now());
                    onClose();
                    if (CloseEntitlementModal) {
                      CloseEntitlementModal();
                    }
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      description: err,
                      status: "error",
                    });
                    setIsLoading(false);
                    onClose();
                  }
                );
              }}
            >
              Delete
            </Button>
            <Button
              variant={"ghost"}
              onClick={onClose}
              fontSize={"13px"}
              padding={"10px 15px"}
              border={"1px solid #dcdee4"}
              borderRadius={"3px"}
              minH={"40px"}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
