import DocumentTable from "./DocumentTable";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function DocumentUpload({
  setSelectedTab,
  selectedTab,
}: TabProps) {
  return (
    <DocumentTable setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
  );
}
