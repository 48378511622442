import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoWarningOutline } from "react-icons/io5";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DeleteModal({ isOpen, onClose }: DeleteProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          fontSize={"18px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Delete Tracking
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <HStack alignItems={"center"} padding={"0px 0px 10px"}>
            <Icon
              fontSize={"20px"}
              as={IoWarningOutline}
              color={"red.500"}
              _hover={{
                color: "red.700",
              }}
            />
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              This option will be permanently deleted.
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            marginRight={"8px"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            isLoading={isLoading}
          >
            Delete
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            marginRight={"8px"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
