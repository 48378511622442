import {
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Employees } from "../../../Api/Employees";
import { PayRun } from "../../../Api/PayRun";
import { TimeSheetNewDetail } from "../../../Api/TimeSheetDetail";

interface AddTimeSheetProps {
  isOpen: boolean;
  onClose: () => void;
  currentEmployeeId: string | undefined;
}
export function AddTimeSheet({
  isOpen,
  onClose,
  currentEmployeeId,
}: AddTimeSheetProps) {
  const [PayrollCalendarId, setPayrollCalendarId] = useState<string>();
  const [payPeriods, setPayPeriods] = useState<PayRun.PayPeriodByCalenderID[]>(
    []
  );

  const navigate = useNavigate();

  const form = useFormContext<TimeSheetNewDetail.AddNewDetail>();
  const toast = useToast();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const [isLoading, setLoading] = useState<boolean>(true);
  const [employeesList, setEmployeesList] = useState<
    Employees.timeSheetEmployees[]
  >([]);
  useEffect(() => {
    Employees.FetchTimeSheetEmployees(
      (data) => {
        setEmployeesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  useEffect(() => {
    if (decodedToken.accessLevel === "EMPLOYEE") {
      const matchedEmployee = employeesList.find(
        (employee) => employee.id === currentEmployeeId
      );
      if (matchedEmployee) {
        setPayrollCalendarId(
          matchedEmployee?.employeeEmployementDetails?.payrollPayCalenderId
        );
      }
    }
  }, [currentEmployeeId, employeesList]);

  useEffect(() => {
    if (PayrollCalendarId) {
      PayRun.FetchPayPeriodByCalenderID(
        PayrollCalendarId,
        (data) => {
          setPayPeriods(data);
          setLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
          setLoading(false);
        }
      );
    }
  }, [PayrollCalendarId]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
            padding={"15px 0px"}
          >
            Add Timesheet
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack margin={"0px 0px 0px 15px"}>
            <Stack maxW={"330px"} gap={0}>
              <Text
                margin={"0px 0px 5px"}
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Period
              </Text>

              <Controller
                control={form.control}
                name="period"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select "
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Pay Period"
                    withValidation
                    options={payPeriods}
                    value={
                      field.value
                        ? {
                            string: field.value,
                          }
                        : undefined
                    }
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.string);
                    }}
                    getOptionLabel={(option: any) => option?.string}
                    getOptionValue={(option: any) => option}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack margin={"15px 0px 0px 15px"}>
            <Stack maxW={"330px"} gap={0}>
              {" "}
              <Text
                margin={"0px 0px 5px"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Enter time as
              </Text>
              <RadioGroup>
                <HStack gap={0}>
                  <Stack
                    h={"32px"}
                    border={"1px solid #ddd"}
                    flex={1}
                    padding={"3px 5px"}
                    bg={"white"}
                    justifyContent={"center"}
                  >
                    <Radio value="1">
                      <Text
                        fontSize={"0.8125rem"}
                        fontWeight={"400"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                      >
                        {" "}
                        Total hours worked
                      </Text>
                    </Radio>
                  </Stack>
                  <Stack
                    h={"32px"}
                    border={"1px solid #ddd"}
                    borderLeft={"none"}
                    bg={"white"}
                    padding={"3px 5px"}
                    flex={1}
                    justifyContent={"center"}
                  >
                    <Radio value="2">
                      <Text
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontSize={"0.8125rem"}
                        fontWeight={"400"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        {" "}
                        Start & end hours
                      </Text>
                    </Radio>
                  </Stack>
                </HStack>
              </RadioGroup>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px" paddingRight={"15px"}>
            <Button
              w="100px"
              colorScheme="green"
              borderRadius={"3px"}
              border={"1px solid green"}
              fontSize={"13px"}
              h={"40px"}
              marginLeft={"12px"}
              variant={"solid"}
              textColor={"customColor.white"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              //   isLoading={isLoading}

              onClick={() => {
                setLoading(true);
                navigate("/app/payroll/Timesheet", {
                  // state: {
                  //   employeeId: form.getValues("employeeId"),
                  //   period: form.getValues("period"),
                  //   PayrollCalendarId: selectedEmployee,
                  //   dateRange: {
                  //     startDate: payPeriods.find(
                  //       (payPeriod) =>
                  //         payPeriod.string === form.getValues("period")
                  //     )?.startDate,
                  //     endDate: payPeriods.find(
                  //       (payPeriod) =>
                  //         payPeriod.string === form.getValues("period")
                  //     )?.endDate,
                  //   },
                  // },
                });
                setLoading(false);
              }}
            >
              Next
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
