import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { AiFillWarning, AiOutlineClose } from "react-icons/ai";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Shift } from "../../../API/Shift";
import { Shifts } from "../../../API/Shifts";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface CopyShiftModalProps {
  isOpen: boolean;
  onClose: () => void;
  startDate: Moment;
  selectedTab: number;
}
export default function CopyShiftModal({
  isOpen,
  onClose,
  startDate,
  selectedTab,
}: CopyShiftModalProps) {
  const { setLastUpdated } = useScheduleContext();
  const selectedShift = useFormContext<Shift.FetchShift>();
  const previousWeeks = Array.from(Array(7), (_, i) => {
    const startOfWeek = moment(startDate).startOf("week").subtract(i, "week");
    const endOfWeek = moment(startDate).endOf("week").subtract(i, "week");
    const label = `${startOfWeek.format("MMM D")} - ${endOfWeek.format(
      "MMM D"
    )}`;
    const value = i + 1;
    return { label, value };
  });
  const toast = useToast();
  const nextWeeks = Array.from(Array(7), (_, i) => {
    const startOfWeek = moment(startDate).startOf("week").add(i, "week");
    const endOfWeek = moment(startDate).endOf("week").add(i, "week");
    const label = `${startOfWeek.format("MMM D")} - ${endOfWeek.format(
      "MMM D"
    )}`;
    const value = i + 1;
    return { label, value };
  });
  const [btnValue, setBtnValue] = useState("1");
  const { control, setValue } = useForm<
    Shift.ShiftsInBulk & {
      areas: Shift.FetchShift["areas"];
    }
  >();
  const radiobtns: Array<{
    title: string;
    value: "COPY_TO_NEXT_SCREEN" | "COPY_FROM_PREVIOUS_SCREEN";
  }> = [
    {
      title: "Copy to next screen",
      value: "COPY_TO_NEXT_SCREEN",
    },
    {
      title: "Copy from previous screen",
      value: "COPY_FROM_PREVIOUS_SCREEN",
    },
    // {
    //   title: "Advance",
    //   value: "ADVANCE",
    // },
  ];
  interface CheckboxOptionProps {
    innerProps: any;
    isSelected: boolean;
  }
  const { employees, locations } = useScheduleContext();
  // const [areasList, setAreasList] = useState<Location.ILocationMin["areas"]>(
  //   []
  // );
  const [areasList, setAreasList] = useState<any>([]); //temporary
  const CheckboxOption = ({ innerProps, isSelected }: CheckboxOptionProps) => (
    <div {...innerProps}>
      <input type="checkbox" checked={isSelected} onChange={() => {}} />
    </div>
  );
  return (
    <form>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px="32px" pt="32px" pb="0px">
            <HStack>
              <Text
                flex={1}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize=".9975rem"
              >
                Copy Shifts
              </Text>
              <IconButton
                aria-label="close"
                variant="ghost"
                icon={<AiOutlineClose color="#3454d1" size="20px" />}
                onClick={onClose}
              />
            </HStack>
          </ModalHeader>

          <ModalBody px="32px" pt="28px" pb="24px">
            <Text
              fontSize={"0.8125rem"}
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Dates
            </Text>
            <RadioGroup onChange={setBtnValue} value={btnValue} pb="4px">
              <Stack spacing={0}>
                {radiobtns.map((btns, i) => {
                  return (
                    <Radio key={i} value={btns.value} pt="14px">
                      {btns.title}
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>

            {btnValue === "3" && (
              <Stack
                w="100%"
                p="24px"
                backgroundColor="gray.50"
                borderRadius="8px"
                spacing="16px"
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Range is required",
                    },
                  }}
                  control={control}
                  name="startDates"
                  render={({ field, fieldState }) => {
                    return (
                      <ReactSelect
                        {...field}
                        withValidation
                        isInvalid={fieldState.invalid}
                        options={previousWeeks}
                        ErrorMessage="Please Select Range"
                        label="Copy shifts from"
                        placeholder="Select Week"
                      />
                    );
                  }}
                />
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Range is required",
                    },
                  }}
                  control={control}
                  name="endTime"
                  render={({ field, fieldState }) => {
                    return (
                      <ReactSelect
                        {...field}
                        isMulti
                        withValidation
                        options={nextWeeks.slice(1)}
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select Range"
                        label="Copy shifts to"
                        placeholder="Select Week"
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="locationId"
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder="Select Location"
                        options={locations}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        value={
                          field.value
                            ? {
                                id: field.value,
                                name:
                                  locations.find(
                                    (loc: any) => loc.id === field.value
                                  )?.name ?? "",
                              }
                            : undefined
                        }
                        onChange={(val: any) => {
                          field.onChange(val?.id);
                          setAreasList(val?.areas ?? []);
                          setValue("areas", []);
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="mealBreak"
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        isMulti
                        placeholder="Select Area"
                        options={areasList}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        value={field.value}
                        onChange={(val: any) => {
                          field.onChange(val);
                          setValue(
                            "areaList",
                            val.map((area: any) => area.id)
                          );
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="employeeId"
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder="Select Employee"
                        options={employees}
                        getOptionLabel={(option: any) => option.preferredTitle}
                        getOptionValue={(option: any) => option.id}
                        value={
                          field.value
                            ? {
                                id: field.value,
                                preferredTitle:
                                  employees.find(
                                    (emp: any) => emp.id === field.value
                                  )?.preferredTitle ?? "",
                              }
                            : undefined
                        }
                        onChange={(val: any) => {
                          field.onChange(val?.id);
                        }}
                      />
                    );
                  }}
                />
              </Stack>
            )}
            <Tag size="md" variant="subtle" colorScheme="red" mt="24px">
              <Stack px="8px" py="12px" direction="row" spacing="0px">
                <TagLeftIcon boxSize="12px" as={AiFillWarning} />

                <Text
                  fontSize={"0.8125rem"}
                  fontWeight={"400"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  There aren't any shifts to copy from this period. You can't
                  copy without any areas selected.
                </Text>
              </Stack>
            </Tag>
          </ModalBody>

          <ModalFooter>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                const selectedDate = moment(startDate);

                //Week
                const startOfPreviousWeek = selectedDate
                  .clone()
                  .subtract(1, "week")
                  .startOf("week")
                  .add(1, "days");
                const endOfPreviousWeek = selectedDate
                  .clone()
                  .subtract(1, "week")
                  .endOf("week")
                  .add(1, "days");
                const startofCurrentweek = selectedDate
                  .clone()
                  .startOf("week")
                  .add(1, "days");
                const endofCurrentweek = selectedDate
                  .clone()
                  .endOf("week")
                  .add(1, "days");
                const startOfNextWeek = selectedDate
                  .clone()
                  .add(1, "week")
                  .startOf("week")
                  .add(1, "days");
                const endOfNextWeek = selectedDate
                  .clone()
                  .add(1, "week")
                  .endOf("week")
                  .add(1, "days");

                // Fortnight
                const startOfPreviousFortnight = selectedDate
                  .clone()
                  .subtract(2, "week")
                  .startOf("week")
                  .add(1, "days");
                const endOfPreviousFortnight = selectedDate
                  .clone()
                  .startOf("week");
                const startofCurrentFortnight = selectedDate
                  .clone()
                  .startOf("week")
                  .add(1, "days");
                const endofCurrentFortnight = selectedDate
                  .clone()
                  .add(14, "days");
                const startOfNextFortnight = selectedDate
                  .clone()
                  .add(2, "week")
                  .startOf("week")
                  .add(1, "days");
                const endOfNextFortnight = selectedDate.clone().add(28, "days");

                // Month
                const startOfPreviousMonth = selectedDate
                  .clone()
                  .subtract(1, "month")
                  .startOf("month");
                const endOfPreviousMonth = selectedDate
                  .clone()
                  .subtract(1, "month")
                  .endOf("month");
                const startofCurrentMonth = selectedDate
                  .clone()
                  .startOf("month");
                const endofCurrentMonth = selectedDate.clone().endOf("month");
                const startOfNextMonth = selectedDate
                  .clone()
                  .add(1, "month")
                  .startOf("month");
                const endOfNextMonth = selectedDate
                  .clone()
                  .add(1, "month")
                  .endOf("month");
                if (btnValue === "COPY_FROM_PREVIOUS_SCREEN") {
                  let data: Shift.CopyBulkShift;

                  if (selectedTab === 2) {
                    data = {
                      copyFrom: {
                        startDate:
                          startOfPreviousFortnight.format("YYYY-MM-DD"),
                        endDate: endOfPreviousFortnight.format("YYYY-MM-DD"),
                      },
                      copyTo: {
                        startDate: startofCurrentFortnight.format("YYYY-MM-DD"),
                        endDate: endofCurrentFortnight.format("YYYY-MM-DD"),
                      },
                    };
                  } else if (selectedTab === 0) {
                    let diffDays =
                      endofCurrentMonth.date() - endOfPreviousMonth.date();
                    if (diffDays !== 0) {
                      if (diffDays > 0) {
                        // The current month has more days than the previous month
                        data = {
                          copyFrom: {
                            startDate:
                              startOfPreviousMonth.format("YYYY-MM-DD"),
                            endDate: endOfPreviousMonth.format("YYYY-MM-DD"),
                          },
                          copyTo: {
                            startDate: startofCurrentMonth.format("YYYY-MM-DD"),
                            endDate: endofCurrentMonth
                              .subtract(diffDays, "days")
                              .format("YYYY-MM-DD"),
                          },
                        };
                      } else {
                        // The previous month has more days than the current month
                        data = {
                          copyFrom: {
                            startDate:
                              startOfPreviousMonth.format("YYYY-MM-DD"),
                            endDate: endOfPreviousMonth
                              .subtract(Math.abs(diffDays), "days")
                              .format("YYYY-MM-DD"),
                          },
                          copyTo: {
                            startDate: startofCurrentMonth.format("YYYY-MM-DD"),
                            endDate: endofCurrentMonth.format("YYYY-MM-DD"),
                          },
                        };
                      }
                    } else {
                      data = {
                        copyFrom: {
                          startDate: startOfPreviousMonth.format("YYYY-MM-DD"),
                          endDate: endOfPreviousMonth.format("YYYY-MM-DD"),
                        },
                        copyTo: {
                          startDate: startofCurrentMonth.format("YYYY-MM-DD"),
                          endDate: endofCurrentMonth.format("YYYY-MM-DD"),
                        },
                      };
                    }
                  } else {
                    data = {
                      copyFrom: {
                        startDate: startOfPreviousWeek.format("YYYY-MM-DD"),
                        endDate: endOfPreviousWeek.format("YYYY-MM-DD"),
                      },
                      copyTo: {
                        startDate: startofCurrentweek.format("YYYY-MM-DD"),
                        endDate: endofCurrentweek.format("YYYY-MM-DD"),
                      },
                    };
                  }

                  Shifts.CopyShiftsBulk(
                    data,
                    () => {
                      toast({
                        title: " Shifts Created",
                        status: "success",
                      });
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        description: error,
                        status: "error",
                      });
                      setLastUpdated(Date.now());
                      onClose();
                    }
                  );
                } else if (btnValue === "COPY_TO_NEXT_SCREEN") {
                  let data: Shift.CopyBulkShift;
                  if (selectedTab === 2) {
                    data = {
                      copyFrom: {
                        startDate: startofCurrentFortnight.format("YYYY-MM-DD"),
                        endDate: endofCurrentFortnight.format("YYYY-MM-DD"),
                      },
                      copyTo: {
                        startDate: startOfNextFortnight.format("YYYY-MM-DD"),
                        endDate: endOfNextFortnight.format("YYYY-MM-DD"),
                      },
                    };
                  } else if (selectedTab === 0) {
                    let diffDays =
                      endofCurrentMonth.date() - endOfNextMonth.date();
                    if (diffDays !== 0) {
                      if (diffDays > 0) {
                        // The current month has more days than the next month
                        data = {
                          copyFrom: {
                            startDate: startofCurrentMonth.format("YYYY-MM-DD"),
                            endDate: endofCurrentMonth
                              .subtract(diffDays, "days")
                              .format("YYYY-MM-DD"),
                          },
                          copyTo: {
                            startDate: startOfNextMonth.format("YYYY-MM-DD"),
                            endDate: endOfNextMonth.format("YYYY-MM-DD"),
                          },
                        };
                      } else {
                        // The next month has more days than the current month
                        data = {
                          copyFrom: {
                            startDate: startofCurrentMonth.format("YYYY-MM-DD"),
                            endDate: endofCurrentMonth.format("YYYY-MM-DD"),
                          },
                          copyTo: {
                            startDate: startOfNextMonth.format("YYYY-MM-DD"),
                            endDate: endOfNextMonth
                              .subtract(Math.abs(diffDays), "days")
                              .format("YYYY-MM-DD"),
                          },
                        };
                      }
                    } else {
                      data = {
                        copyFrom: {
                          startDate: startofCurrentMonth.format("YYYY-MM-DD"),
                          endDate: endofCurrentMonth.format("YYYY-MM-DD"),
                        },
                        copyTo: {
                          startDate: startOfNextMonth.format("YYYY-MM-DD"),
                          endDate: endOfNextMonth.format("YYYY-MM-DD"),
                        },
                      };
                    }
                  } else {
                    data = {
                      copyFrom: {
                        startDate: startofCurrentweek.format("YYYY-MM-DD"),
                        endDate: endofCurrentweek.format("YYYY-MM-DD"),
                      },
                      copyTo: {
                        startDate: startOfNextWeek.format("YYYY-MM-DD"),
                        endDate: endOfNextWeek.format("YYYY-MM-DD"),
                      },
                    };
                  }
                  Shifts.CopyShiftsBulk(
                    data,
                    () => {
                      toast({
                        title: " Shifts Created",
                        status: "success",
                      });
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        description: error,
                        status: "error",
                      });
                      setLastUpdated(Date.now());
                      onClose();
                    }
                  );
                }
              }}
            >
              Copy
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}
