import { Box, HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemReimbursementApi } from "../../../Api/PayItemReinbursement";
import { PayRun } from "../../../Api/PayRun";

interface AddReimbursementRateProps {
  form: any;
  reimbursementFieldArray: any;
  reimbursementLine: any;
  accountReimbursement: PayItemReimbursementApi.ReimbursementById[];
  setAccountReimbursement: (accountReimbursement: any) => void;
  setResetData: (resetData: any) => void;
  resetPaySlip: Function;
}
export default function AddReimbursementRate({
  accountReimbursement,
  form,
  reimbursementFieldArray,
  reimbursementLine,
  setAccountReimbursement,
  setResetData,
  resetPaySlip,
}: AddReimbursementRateProps) {
  const toast = useToast();
  useEffect(() => {
    PayItemReimbursementApi.FetchReimbursement(
      (res) => {
        setAccountReimbursement(res);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Box gap={0}>
      {reimbursementFieldArray.fields.map(
        (item: PayRun.PayrunItem & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                fontSize={"12px "}
                margin={"0px 0px 4px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"700"}
              >
                Reimbursement Rate{" "}
              </Text>
              <Text
                color={"#3454d1"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  reimbursementLine.onOpen();
                  setResetData({ ...item, index: i });
                }}
              >
                {accountReimbursement.find(
                  (accountReimbursement) =>
                    accountReimbursement.id === item.payItemReimbursementId
                )?.name ?? ""}
                {form.getValues(`reimbursments.${i}.description`) && ": "}
                {form.getValues(`reimbursments.${i}.description`)}
              </Text>
            </Stack>

            {form.getValues(`reimbursments.${i}.payItemReimbursementId`) ===
              item.payItemReimbursementId && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Amount is required",
                      },
                      onBlur(event) {
                        resetPaySlip();
                      },
                    }}
                    control={form.control}
                    name={`reimbursments.${i}.amount`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Amount "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "customColor.black7",
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}

            <Stack>
              <Icon
                fontSize={"20px"}
                as={IoIosCloseCircleOutline}
                marginTop={"15px"}
                color={"grey"}
                _hover={{
                  color: "red.500",
                }}
                onClick={() => {
                  reimbursementFieldArray.remove(i);
                }}
              />
            </Stack>
          </Stack>
        )
      )}
    </Box>
  );
}
