import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { PayrollHoliday } from "../../../Api/PayRollHoliday";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (time: number) => void;
}
export function DeleteHoliday({
  isOpen,
  onClose,

  setLastUpdated,
}: //   holidayGroup,
DeleteProps) {
  const form = useFormContext<PayrollHoliday.Holiday>();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="340px">
        <ModalHeader
          padding={"15px 15px 14px"}
          fontSize={"18px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{ color: "customColor.white" }}
        >
          Delete Holiday
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          borderTop={"1px solid #E8E8E8"}
          borderBottom={"1px solid #E8E8E8"}
          padding={"10px 15px"}
        >
          <Text
            fontSize={"12px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
          >
            Are you sure you want to delete <b>{form.getValues("name")}</b>
            ?This will remove the group.but not the holidays within it.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            onClick={() => {
              const id = form.getValues("id");
              setIsLoading(true);
              if (id) {
                PayrollHoliday.DeleteHoliday(
                  id,
                  (success) => {
                    setIsLoading(false);
                    setLastUpdated(Date.now());
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                );
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={onClose}
            marginLeft={"8px"}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
