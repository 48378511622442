import {
  Box,
  ButtonGroup,
  HStack,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { Moment } from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Analytics } from "../../../../Payroll/Api/DashboardAnalytics";
import { Employees } from "../../../../Payroll/Api/Employees";
import { Shifts } from "../../../../TimeAttendance/API/Shifts";
import ShiftTag from "../../../../TimeAttendance/pages/Schedule/Common/ShiftTag";
import { useScheduleContext } from "../../../../TimeAttendance/pages/Schedule/Hooks/ScheduleContext";
import { useDashboardContext } from "../../../Hooks/DashboardContext";

interface ShiftRosterProps {
  selectedShiftId?: (shiftId: string) => void;
  selectedTimestamp?: (timestamp: number) => void;
  setViewShiftsIds?: (ids: string[]) => void;
}

export default function ShiftRosters({
  selectedTimestamp,
  selectedShiftId,
  setViewShiftsIds,
}: ShiftRosterProps) {
  const { editDashboard } = useDashboardContext();
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [daysOfWeek, setDaysOfWeek] = useState<Array<moment.Moment>>([]);
  const { copiedShifts, lastUpdated } = useScheduleContext();
  const { employeesList } = useBusinessContext();

  const toast = useToast();
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState<number>(25);
  const employees = employeesList ?? [];
  const pageCount = Math.ceil(employees.length / limit);
  // useEffect(() => {
  //   Employees.FetchEmployees(
  //     {
  //       empStatus: "CURRENT",
  //       page: pageNumber,
  //       quantity: limit,
  //       search: searchText,
  //     },
  //     (data) => {
  //       setEmployeesList(data);
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //       });
  //     }
  //   );
  // }, [pageNumber, searchText]);
  // useEffect(() => {
  //   Employees.FetchEmployees(
  //     {
  //       empStatus: "ALL",
  //     },
  //     (data) => {
  //       setAllEmployeesList(data);
  //       setPageCount(Math.ceil(data.length / limit)); // Update pageCount
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //       });
  //     }
  //   );
  // }, [limit]);
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [shiftsData, setShiftsData] = useState<
    Array<Shifts.FetchByDatesResponse>
  >([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(employeesList);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };

  const next = () => {
    setCurrentDate(currentDate.clone().add(1, "week"));
  };

  const previous = () => {
    setCurrentDate(currentDate.clone().subtract(1, "week"));
  };

  const findShiftsForEmployeeAndDate = (empId: string, date: Moment) => {
    const dayShifts = shiftsData.find(
      (shift) => shift.date === date.format("YYYY-MM-DD")
    );
    const employeeShifts = dayShifts?.shifts.filter(
      (shift) => shift.employeeId === empId
    );
    return employeeShifts || [];
  };
  const form = useFormContext<Analytics.DashBoardData>();
  useEffect(() => {
    const newDaysOfWeek = Array.from(Array(7), (_, i) =>
      moment(currentDate).startOf("week").add(i, "days")
    );
    setDaysOfWeek(newDaysOfWeek);
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    Shifts.FetchShiftsByDate(
      {
        startDate: startOfWeek?.clone().format("YYYY-MM-DD") ?? "",
        endDate: endOfWeek?.clone().format("YYYY-MM-DD") ?? "",
      },
      (allShifts) => {
        setShiftsData(allShifts);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [currentDate]);
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   boxShadow="0 4px 8px rgba(15,34,58,.35)"
    //   _hover={{
    //     boxShadow: "0 4px 8px rgba(15,34,58,.65)",
    //   }}
    //   // display={
    //   //   editDashboard ||
    //   //   form.getValues("hideOrShowTiles.shiftRosterData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    //   overflowX="auto"
    // >
    <Box
      p="25px"
      word-wrap="break-word"
      w={"100%"}
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      sx={{
        "::-webkit-scrollbar": {
          width: "0px", // Initially, the scrollbar is hidden
          background: "transparent",
        },
        ":hover::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on hover
        },
        ":active::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible when active
        },
        ":focus::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on focus
        },
        // Add for Firefox and other browsers if needed
        scrollbarWidth: "none",
        ":hover": {
          scrollbarWidth: "thin",
        },
        ":active": {
          scrollbarWidth: "thin",
        },
        ":focus": {
          scrollbarWidth: "thin",
        },
      }}
    >
      <Box>
        <ButtonGroup variant="outline" isAttached>
          <IconButton
            aria-label="backward"
            icon={<FiChevronLeft width="6px" height="13px" />}
            w="34px"
            color="#545f70"
            borderColor="borders.tableBorder"
            _hover={{
              borderColor: "primary.500",
              backgroundColor: "transparent",
              zIndex: 1,
            }}
            onClick={previous}
          />
          <IconButton
            aria-label="forward"
            icon={<FiChevronRight width="6px" height="13px" />}
            w="34px"
            color="#545f70"
            borderColor="borders.tableBorder"
            _hover={{
              borderColor: "primary.500",
              backgroundColor: "transparent",
              zIndex: 1,
            }}
            onClick={next}
          />
        </ButtonGroup>
        <Text
          textAlign="center"
          fontSize="15px"
          fontWeight="bold"
          _light={{
            color: "customColor.black7",
          }}
        >
          {currentDate.format("MMMM YYYY")}
        </Text>

        <Table variant="simple" w="100%" overflow="auto">
          <Thead
            h={"36.8px"}
            position="sticky"
            top="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            zIndex={8}
          >
            <Tr
              zIndex={6}
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
            >
              <Th
                // minW="150px"
                h="48px"
                fontWeight={500}
                textAlign="center"
                textColor="white"
                backgroundColor="primary.500"
                position="sticky"
                left={0}
                borderRight="1px"
                borderRightColor="borders.tableBorder"
                fontSize="14px"
                bg={"#fff"}
                _light={{
                  color: "customColor.black7",
                }}
                padding={"8px 26px 8px 15px"}
              >
                Employees
              </Th>
              {daysOfWeek.map((day, index) => (
                <Th
                  key={index}
                  p={0}
                  // minW="18vh"
                  borderColor="borders.tableBorder"
                  backgroundColor="white"
                  borderRight="1px"
                  bg={"#fff"}
                  borderRightColor="borders.tableBorder"
                >
                  <Stack
                    h="48px"
                    spacing="1px"
                    fontSize="14px"
                    bg={"#fff"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding={"8px 12px"}
                    textAlign="center"
                  >
                    <Text
                      fontSize="12px"
                      fontWeight={700}
                      bg={"#fff"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      textTransform="uppercase"
                    >
                      {day.format("dddd")}
                    </Text>
                    <Text
                      fontSize="14px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={500}
                      bg={"#fff"}
                      letterSpacing="-1.5px"
                    >
                      {day.format("D")}
                    </Text>
                  </Stack>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody position="relative">
            {paginatedData &&
              paginatedData.map((emp: Employees.FetchEmployees, i: number) => (
                <Tr key={emp.id}>
                  <Td
                    fontSize="13px"
                    fontWeight={500}
                    px="4px"
                    py={0}
                    minH="21px"
                    borderColor="borders.tableBorder"
                    textAlign="center"
                    _light={{
                      color: "customColor.black7",
                    }}
                    position="sticky"
                    backgroundColor="white"
                    left={0}
                    zIndex={5}
                  >
                    {emp.firstName + " " + emp.lastName}
                  </Td>
                  {daysOfWeek.map((date, k) => (
                    <Td
                      key={k}
                      p={0}
                      borderColor="borders.tableBorder"
                      role="group"
                    >
                      <Box
                        textAlign="right"
                        alignItems="center"
                        borderLeft="1px"
                        borderColor="borders.tableBorder"
                        h="15vh"
                        overflowY="auto"
                      >
                        {findShiftsForEmployeeAndDate(emp.id, date)?.map(
                          (shift) => (
                            <ShiftTag
                              key={shift.id}
                              shift={shift}
                              onClicked={() => {
                                if (selectedShiftId) {
                                  selectedShiftId(shift.id);
                                }
                              }}
                              // backgroundColor="#DF362D66"
                            />
                          )
                        )}
                      </Box>
                    </Td>
                  ))}
                </Tr>
              ))}
          </Tbody>
        </Table>
        <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
          <IconButton
            aria-label="back"
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            backgroundColor="transparent"
            _hover={{
              backgroundColor: "#3454D1",
              color: "white",
            }}
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            icon={
              <FaArrowLeft
                size={15}
                color={
                  isHovered2
                    ? "#FFFFFF"
                    : pageNumber <= 1 || pageNumber > pageCount
                    ? "#828ea0"
                    : "#283c50"
                }
              />
            }
            isDisabled={pageNumber <= 1 || pageNumber > pageCount}
            onClick={() => {
              if (pageNumber > 1 && pageNumber <= pageCount) {
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber - 1));
                setPageNumber(pageNumber - 1);
              }
            }}
          />
          <HStack spacing="13px">{renderPageNumbers()}</HStack>
          <IconButton
            aria-label="right"
            backgroundColor="transparent"
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            _hover={{
              backgroundColor: "#3454D1",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            icon={
              <FaArrowRight
                size={15}
                color={
                  isHovered
                    ? "#FFFFFF"
                    : pageNumber >= pageCount || pageNumber < 1
                    ? "#828ea0"
                    : "#283c50"
                }
                style={{ transition: "color 0.2s" }} // Add smooth transition for color change
              />
            }
            isDisabled={pageNumber >= pageCount || pageNumber < 1}
            onClick={() => {
              if (pageNumber < pageCount && pageNumber >= 1) {
                pageNumberInputRef.current?.value &&
                  (pageNumberInputRef.current.value = String(pageNumber + 1));
                setPageNumber(pageNumber + 1);
              }
            }}
          />
        </HStack>
        {/* <Stack
          direction="row"
          alignItems="center"
          gap={0}
          paddingLeft={"12px"}
          paddingRight={"5px"}
        >
          <Stack
            direction="row"
            alignItems="center"
            marginTop="10px"
            flex={1}
            gap={0}
          >
            <Stack direction={"row"} gap={0}>
              <Text
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                padding={"5px 0px"}
                fontSize={"12px"}
              >{`Page`}</Text>
              <Select
                h={"25px"}
                w={"50px"}
                paddingLeft={"3px"}
                paddingRight={"3px"}
                style={{
                  borderRadius: "3px",
                  fontWeight: "normal",
                  padding: "0px 0px 0px 3px",
                }}
                borderWidth={"1px"}
                borderStyle={"solid"}
                borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                fontSize={"12px"}
                placeholder=""
                value={selectedPage}
                onChange={(event: any) => {
                  const newPage = parseInt(event.target.value, 10);
                  setSelectedPage(newPage);
                  setCurrentPage(newPage);
                }}
              >
                {getPageNumbers(
                  Math.ceil((employeesList?.length ?? 0) / itemsPerPage)
                ).map((pageNumber) => (
                  <option
                    key={pageNumber}
                    value={pageNumber}
                    style={{
                      width: "70px",
                      height: "42px",
                      padding: "13px 10px 12px",
                      fontSize: "12px",
                      color: "#048ABB",
                    }}
                  >
                    {pageNumber}
                  </option>
                ))}
              </Select>
              <Text
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                padding={"5px 0px"}
                fontSize={"12px"}
              >{` of ${Math.ceil(
                employeesList?.length ?? 0 / itemsPerPage
              )}`}</Text>
              <Text
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                padding={"5px 0px"}
                fontSize={"12px"}
                marginLeft={"3px"}
              >
                ({employeesList?.length ?? 0} total items)
              </Text>
            </Stack>

            <Text
              marginLeft="10px"
              color={"customColor.gray.800"}
              _dark={{
                color: "customColor.dark.250",
              }}
              padding={"5px 0px"}
              fontSize={"12px"}
              marginRight={"5px"}
            >
              Showing
            </Text>
           
            <Select
              h={"25px"}
              w={"50px"}
              style={{
                borderRadius: "3px",
                fontWeight: "normal",
                padding: "0px 0px 0px 3px",
              }}
              borderWidth={"1px"}
              borderStyle={"solid"}
              borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
              fontSize={"12px"}
              placeholder=""
              value={itemsPerPage}
              onChange={(event: any) => {
                const newValue = parseInt(event.target.value, 10);
                setItemsPerPage(newValue);
                setCurrentPage(1);
              }}
            >
              {[25, 50, 100, 200, 500, 1000].map((value) => (
                <option
                  key={value}
                  value={value}
                  style={{
                    width: "70px",
                    height: "42px",
                    padding: "13px 10px 12px",
                    fontSize: "12px",
                    color: "#048ABB",
                  }}
                >
                  {String(value)}
                </option>
              ))}
            </Select>

            <Text
              color={"customColor.gray.800"}
              _dark={{
                color: "customColor.dark.250",
              }}
              padding={"5px 0px"}
              fontSize={"12px"}
              marginLeft={"5px"}
            >
              Items per page:
            </Text>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingY="10px"
            gap={0}
          >
            {currentPage > 1 && (
              <>
                <Button
                  variant={"link"}
                  color={"#3454d1"}
                  fontSize={"12px"}
                  fontWeight={"normal"}
                  padding={0}
                  margin={"0px 0px 0px 5px"}
                  onClick={() => setCurrentPage(1)}
                >
                  <Icon as={MdKeyboardDoubleArrowLeft} />
                  Start
                </Button>
                <Button
                  variant={"link"}
                  color={"#3454d1"}
                  fontSize={"12px"}
                  fontWeight={"normal"}
                  padding={0}
                  margin={"0px 0px 0px 5px"}
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                >
                  <Icon as={MdKeyboardArrowLeft} />
                  Prev
                </Button>
              </>
            )}
            {getPageNumbers(
              Math.ceil((employeesList?.length ?? 0) / itemsPerPage)
            ).map((pageNumber) => (
              <Button
                key={pageNumber}
                variant={"link"}
                color={"#3454d1"}
                fontSize={"12px"}
                fontWeight={"normal"}
                padding={0}
                margin={"0px 0px 0px 5px"}
                onClick={() => {
                  setSelectedPage(pageNumber);
                  setCurrentPage(pageNumber);
                }}
                isActive={pageNumber === selectedPage}
                _active={{
                  color: "black",
                }}
              >
                {pageNumber}
              </Button>
            ))}
            {currentPage <
              Math.ceil((employeesList?.length ?? 0) / itemsPerPage) && (
              <>
                <Button
                  variant={"link"}
                  color={"#3454d1"}
                  fontSize={"12px"}
                  fontWeight={"normal"}
                  padding={0}
                  margin={"0px 0px 0px 5px"}
                  onClick={() =>
                    setCurrentPage((prev) =>
                      Math.min(
                        prev + 1,
                        Math.ceil((employeesList?.length ?? 0) / itemsPerPage)
                      )
                    )
                  }
                >
                  Next
                  <Icon as={MdKeyboardArrowRight} />
                </Button>
                <Button
                  variant={"link"}
                  color={"#3454d1"}
                  fontSize={"12px"}
                  fontWeight={"normal"}
                  padding={0}
                  margin={"0px 0px 0px 5px"}
                  onClick={() =>
                    setCurrentPage(
                      Math.ceil((employeesList?.length ?? 0) / itemsPerPage)
                    )
                  }
                >
                  End
                  <Icon as={MdKeyboardDoubleArrowRight} />
                </Button>
              </>
            )}
          </Stack>
        </Stack> */}
      </Box>
    </Box>
    // </Card>
  );
}
