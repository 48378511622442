import { HStack, SimpleGrid, Stack, Tag, Text } from "@chakra-ui/react";
import moment from "moment";
import { Employees } from "../../../Api/Employees";
interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function PreviousExperience({ employeeData }: DetailsProps) {
  return (
    <Stack
      flex={1}
      padding={"0px 5px"}
      margin={
        employeeData?.experiences && employeeData?.experiences.length > 0
          ? "0px 0px 20px"
          : "0px 0px 10px"
      }
    >
      <HStack
        borderBottom={"2px solid #dadddf"}
        padding={"0px 0px 10px"}
        margin={
          employeeData?.experiences && employeeData?.experiences.length > 0
            ? "14px 0px 20px"
            : "0px"
        }
      >
        <Text
          _dark={{
            color: "customColor.white",
          }}
          _light={{
            color: "#3454d1",
          }}
          fontWeight={"700"}
          _hover={{
            _light: {
              color: "#3454d1",
            },
          }}
        >
          Previous Experiences
        </Text>
        {employeeData?.experiences && employeeData?.experiences.length > 0 ? (
          <Tag
            padding="5px 6px"
            fontWeight={600}
            fontSize="10px"
            lineHeight={1.5}
            borderRadius={"3px"}
            color={"#2E7D32"} // Dark green color for text
            backgroundColor={"#E8F5E9"} // Light green background color
            _dark={{
              color: "#2E7D32",
              backgroundColor: "#E8F5E9",
            }}
          >
            {"Complete"}
          </Tag>
        ) : (
          <Tag
            padding="5px 6px"
            fontWeight={600}
            fontSize="10px"
            lineHeight={1.5}
            borderRadius={"3px"}
            color={"#EA4D4D"}
            backgroundColor={"#FDEDED"}
          >
            {"Incomplete"}
          </Tag>
        )}
        {/* <Button
                  variant={"link"}
                  color={"primary.950"}
                  onClick={() => {
                    emergencyContact.onOpen();
                  }}
                >
                  Edit
                </Button>
                <EmergencyContactModal
                  isOpen={emergencyContact.isOpen}
                  onClose={emergencyContact.onClose}
                /> */}
      </HStack>
      {employeeData?.experiences && employeeData?.experiences.length > 0 && (
        <SimpleGrid
          columns={{ xs: 1, md: 2 }}
          padding={"0px 5px"}
          margin={"0px 0px 20px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          spacing={10}
        >
          {employeeData?.experiences.map((item, i) => {
            return (
              <Stack key={i} flex={1}>
                <HStack
                  justifyContent={"space-between"}
                  borderBottom={"2px solid #dadddf"}
                  padding={"0px 0px 10px"}
                  margin={"14px 0px 20px"}
                >
                  <Text
                    fontSize={"14.5px"}
                    _dark={{
                      color: "customColor.white",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontWeight={"700"}
                    _hover={{
                      _light: {
                        color: "#3454d1",
                      },
                    }}
                  >
                    Job {i + 1}
                  </Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Company Name
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.companyName ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Designation
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.position ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Joining Date
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {moment(item.startDate).format("D MMMM YYYY")}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Job Ending Date
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {moment(item.endDate).format("D MMMM YYYY")}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Duration
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {calculateDuration(item?.startDate, item?.endDate)}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
            );
          })}
        </SimpleGrid>
      )}
    </Stack>
  );
}
const calculateDuration = (
  startDate: string | undefined,
  endDate: string | undefined
) => {
  if (!startDate || !endDate) return ""; // Handle missing dates gracefully

  const fromDate = new Date(startDate);
  const toDate = new Date(endDate);

  if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) return ""; // Invalid date format

  const diffInMs = toDate.getTime() - fromDate.getTime();
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  const years = Math.floor(diffInDays / 365);
  const months = Math.floor((diffInDays % 365) / 30); // Approximate months

  return `${years} years ${months} months`;
};
