import { Box, Text } from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDashboardContext } from "../../Hooks/DashboardContext";
import AnalogClock from "./AnalogClock";
// interface AttendenceProps {
//   // editDashboard: boolean;
// }
export function Attendence() {
  // const toast = useToast();
  // const getCurrentTime = () => {
  //   const currentTime = new Date();
  //   const hours = currentTime.getHours();
  //   const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  //   const seconds = currentTime.getSeconds().toString().padStart(2, "0");
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   const formattedHours = (hours % 12 || 12).toString().padStart(2, "0"); // Convert hours to 12-hour format
  //   return `${formattedHours}:${minutes}:${seconds}${ampm}`;
  // };
  const { editDashboard } = useDashboardContext();
  // // const form = useFormContext<Analytics.DashBoardData>();
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const time = getCurrentTime();
  //     setFormattedTime(time);
  //   }, 1000);
  //   // Clear the interval on component unmount to prevent memory leaks
  //   return () => clearInterval(interval);
  // }, []);
  // const [checkinTime, setCheckinTime] = useState("");
  // const [checkoutTime, setCheckoutTime] = useState("");
  // const handleCheckin = () => {
  //   const time = getCurrentTime();
  //   setCheckinTime(time);
  //   toast({
  //     title: "Checked in",
  //     description: `You have checked in at ${time}`,
  //     status: "success",
  //   });
  // };

  // const handleCheckout = () => {
  //   const time = getCurrentTime();
  //   setCheckoutTime(time);
  //   toast({
  //     title: "Checked out",
  //     description: `You have checked out at ${time}`,
  //     status: "success",
  //   });
  // };
  // const [formattedTime, setFormattedTime] = useState(getCurrentTime());
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss A"));

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss A"));
    }, 1000); // Update the time every second
    return () => clearInterval(timerId);
  }, []);
  return (
    <Box
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      w="100%"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      dir={"column"}
    >
      <Box>
        <AnalogClock />
        <Text
          fontSize="4xl"
          fontFamily="digital-clock, monospace"
          color="#3454d1"
          textAlign="center"
        >
          {currentTime}
        </Text>
      </Box>
      {/* <Box p={"25px"} borderBottom={"1px solid #e5e7eb"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight="1.2"
          fontSize="16px"
          fontWeight={600}
        >
          Attendance
        </Text>
      </Box>
      <Box padding={"25px"}>
        <Center>
          <Icon as={FaRegClock} fontSize={"36px"} color={"gray"} />
        </Center>
        <Center>
          <Stack direction={"row"} alignItems={"center"}>
            <Text
              fontSize={"31.984px"}
              color="customColor.black7"
              _dark={{
                color: "customColor.dark.150",
              }}
              fontWeight={"600"}
            >
              {formattedTime}
            </Text>
          </Stack>
        </Center>
        <Center>
          <Stack marginTop={"1.5rem!important"} gap={0} direction={"row"}>
            <Stack marginRight={"20px"} gap={0}>
              <Button
                fontSize={"13px"}
                bgColor="#00bcc2"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"##00bcc2!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#00979c",
                  borderColor: "#00979c",
                }}
                marginBottom={"16px"}
                onClick={handleCheckin}
              >
                CHECKIN
              </Button>
              <Text
                textAlign={"left"}
                fontWeight={"600"}
                fontSize={"13px"}
                color="customColor.gray.64748B"
                _dark={{
                  color: "customColor.dark.200",
                }}
              >
                Time
              </Text>
              <Text
                textAlign={"left"}
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                {checkinTime}
              </Text>
            </Stack>
            <Stack gap={0}>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                marginBottom={"16px"}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={handleCheckout}
              >
                CHECKOUT
              </Button>
              <Text
                textAlign={"left"}
                fontWeight={"600"}
                fontSize={"13px"}
                color="customColor.gray.64748B"
                _dark={{
                  color: "customColor.dark.200",
                }}
              >
                Time
              </Text>
              <Text
                textAlign={"left"}
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                {checkoutTime}
              </Text>
            </Stack>
          </Stack>
        </Center>
      </Box> */}
    </Box>
  );
}
