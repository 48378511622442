import { useToast } from "@chakra-ui/react";

import { createContext, useContext, useState } from "react";
import { Shifts } from "../../../API/Shifts";

interface ScheduleContextProps {
  employees: any;
  locations: any;
  copiedShifts?: Shifts.copyPasteShift | undefined;
  setCopiedShifts: React.Dispatch<
    React.SetStateAction<Shifts.copyPasteShift | undefined>
  >;
  copiedBulkShifts?: Shifts.copyPasteShift | undefined;
  setBulkCopiedShifts: React.Dispatch<
    React.SetStateAction<Shifts.copyPasteShift | undefined>
  >;
  monthlyCopiedShifts?: Shifts.CopyBulkShift | undefined;
  setMonthlyCopiedShifts?: React.Dispatch<
    React.SetStateAction<Shifts.CopyBulkShift | undefined>
  >;
  lastUpdated: number;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
}
const ScheduleContext = createContext<ScheduleContextProps>({
  employees: [],
  locations: [],
  setCopiedShifts: () => {},
  lastUpdated: 0,
  setLastUpdated: () => {},
  setBulkCopiedShifts: () => {},
  setMonthlyCopiedShifts: () => {},
});
interface ScheduleContextProviderProps {
  children: any;
}
const ScheduleContextProvider = ({
  children,
}: ScheduleContextProviderProps) => {
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [copiedShifts, setCopiedShifts] = useState<Shifts.copyPasteShift>();
  const [copiedBulkShifts, setBulkCopiedShifts] =
    useState<Shifts.copyPasteShift>();
  const [monthlyCopiedShifts, setMonthlyCopiedShifts] =
    useState<Shifts.CopyBulkShift>();
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const toast = useToast();
  // useEffect(() => {
  //   Employee.FetchEmployees(setEmployees, message => {
  //     toast({
  //       title: 'Employees',
  //       description: message,
  //     });
  //   });
  //   Location.FetchLocations(
  //     data => {
  //       setLocations(data);
  //     },
  //     error => {
  //       toast({
  //         title: 'Error',
  //         description: error,
  //         status: 'error',
  //       });
  //     },
  //   );
  // }, []);

  return (
    <ScheduleContext.Provider
      value={{
        employees,
        locations,
        setCopiedShifts,
        copiedShifts,
        lastUpdated,
        setLastUpdated,
        copiedBulkShifts,
        setBulkCopiedShifts,
        monthlyCopiedShifts,
        setMonthlyCopiedShifts,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
function useScheduleContext() {
  return useContext<ScheduleContextProps>(ScheduleContext);
}
export { ScheduleContext, ScheduleContextProvider, useScheduleContext };
