import {Box} from "@chakra-ui/react";
import React from "react";
import {useDrop} from "react-dnd";

interface DragContainerProps<T> {
  accept: string;
  onDrop: (item: T) => void;

  children: React.ReactNode;
}
export function DragContainer<T>({
  accept,
  onDrop,

  children,
}: DragContainerProps<T>) {
  const [{isOver}, drop] = useDrop({
    accept,
    drop: (
      item: T & {
        dateIndex: number;
        shiftIndex: number;
      }
    ) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Box ref={drop} bgColor={isOver ? "gray.100" : "transparent"}>
      {children}
    </Box>
  );
}
