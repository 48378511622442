import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { Employees } from "../../Api/Employees";
import EmployeePayslips from "./EmployeePayslips";
import { LeaveParent } from "./LeaveParent";
import { MyDetails } from "./MyDetails";
import PayHistory from "./PayHistory";
import { Schedule } from "./Schedule";
import { TimeSheet } from "./Timesheet";
import TimesheetReport from "./TimesheetReport";
interface EmployeeStatisticsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}

export function EmployeeStatistics({ employeeData }: EmployeeStatisticsProps) {
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const toast = useToast();

  const tabCategories = [
    {
      label: "My Details",
      index: 0,
      component: <MyDetails employeeData={employeeData} />,
    },
    {
      label: "Schedule",
      index: 1,
      component: <Schedule />,
    },
    {
      label: "Attendance Report",
      index: 2,
      component: <TimesheetReport />,
    },
    {
      label: "PaySlips",
      index: 3,
      component: <EmployeePayslips />,
    },
    {
      label: "Leave",
      index: 4,
      component: (
        <LeaveParent
          setLastUpdated={setLastUpdated}
          lastUpdated={lastUpdated}
        />
      ),
    },
    {
      label: "Timesheet",
      index: 5,
      component: <TimeSheet />,
    },
    {
      label: "Compensation",
      index: 6,
      component: <PayHistory />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const isMediumScreen = useBreakpointValue({ base: false, md: true });

  return (
    <Stack
      h={"fit-content"}
      bg={"#fff"}
      marginBottom={"24px"}
      borderRadius={"10px"}
      transition={"all .3s ease"}
      boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
    >
      {isMediumScreen ? (
        <Tabs
          index={selectedTab}
          orientation="horizontal"
          onChange={setSelectedTab}
          isLazy
        >
          <TabList>
            <SimpleGrid columns={7} w={"100%"}>
              {tabCategories.map((tab, i) => (
                <Tab
                  key={i}
                  flex={1}
                  fontSize={"13.44px"}
                  padding={"20px 30px"}
                  fontWeight={"600"}
                  _light={{ color: "#283c50" }}
                  onClick={() => setSelectedTab(tab.index)}
                  _selected={{
                    _light: { color: "#3454d1" },
                    fontWeight: 600,
                    bg: "rgba(52,84,209,.075)",
                    borderBottom: "3px solid #3454d1",
                  }}
                  _hover={{ _light: { color: "#3454d1" }, fontWeight: 600 }}
                >
                  {tab.label}
                </Tab>
              ))}
            </SimpleGrid>
          </TabList>

          <TabPanels p={0}>
            {tabCategories.map((tab, i) => (
              <TabPanel key={i} padding={0}>
                {tab.component}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Box alignItems={"flex-end"} justifyItems={"flex-end"}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<AiOutlineCaretDown />}
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              justifyContent="flex-end"
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              minW={"170px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                color: "primary.950",
                backgroundColor: "#fff",
              }}
              cursor={"pointer"}
              _active={{
                backgroundColor: "#fff",
                color: "primary.950",
              }}
            >
              {tabCategories[selectedTab].label}
            </MenuButton>
            <MenuList zIndex={"overlay"} maxH={"300px"} overflowX={"scroll"}>
              {tabCategories.map((tab) => (
                <MenuItem
                  fontSize={"13.44px"}
                  key={tab.index}
                  onClick={() => setSelectedTab(tab.index)}
                >
                  {tab.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Box mt={4}>{tabCategories[selectedTab].component}</Box>
        </Box>
      )}
    </Stack>
  );
}
