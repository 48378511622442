import { axiosInstance } from "./axiosInstance-hris";

declare module EmergencyContactApi {
  type AddEmergencyContact = {
    id?: string;
    name: string;
    email: string;
    contact: string;
    address: string;
    relationship: string;
  };
  type EmergencyContact = {
    id: string;
    name: string;
    email: string;
    contact: string;
    address: string;
    relationship: string;
    empId: string;
  };
}
module EmergencyContactApi {
  const URL = "/employee-emergency-contacts";

  export function AddEmergencyContact(
    empId: string,
    data: AddEmergencyContact,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/${empId}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function FetchEmergencyContact(
    empId: string,
    success: (response: Array<EmergencyContact>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${empId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateEmergencyContact(
    id: string,
    data: Partial<AddEmergencyContact>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteEmergencyContact(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { EmergencyContactApi };
