import { Checkbox, HStack, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { BsBoxArrowUpRight } from "react-icons/bs";

export function Payroll() {
  return (
    <Stack
      gap={0}
      marginTop={"20px"}
      padding={"24px"}
      border={"1px solid lightgrey"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      borderRadius={"5px"}
      w={{ md: "100%", lg: "850px" }}
      display={"none"}
    >
      <HStack gap={0}>
        <Checkbox size={"lg"} defaultChecked={true}></Checkbox>
        <Text
          fontSize={"15px"}
          fontWeight={"700"}
          color={"#00A1E"}
          margin={"0px 8px"}
        >
          Payroll admin
        </Text>
      </HStack>
      <Text padding={"4px 0px"} fontSize={"15px"} fontWeight={"400"}>
        Allow this user full payroll access, including preparing and posting pay
        runs and payroll reporting.
        <Link
          textDecoration={"underline"}
          color={"#000A1EBF"}
          _hover={{
            color: "#0078C8",
          }}
        >
          Read about Payroll <Icon as={BsBoxArrowUpRight} />
        </Link>
      </Text>
    </Stack>
  );
}
