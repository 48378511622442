import { Heading, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AddressInput } from "../../../../Common/AddressInput";
import { CustomInput } from "../../../../Common/CustomInput";
import { loadGoogleMapsScript } from "../../../../Payroll/Pages/TimeSheet/utils";
import { LocationApi } from "../../../API/LocationAPi";
import Map from "../Components/Map";
const libraries = ["places"];
export function General() {
  const industry = [
    { value: "Building Services", label: "Building Services" },
    {
      value: "Business equipment industry",
      label: "Business equipment industry",
    },
    { value: "Cemetery operations", label: "Cemetery operations" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cleaning services", label: "Cleaning services" },
    {
      value: "Cement and concrete products",
      label: "Cement and concrete products",
    },
    { value: "Children's services", label: "Children's services" },
    {
      value: "Building, metal and civil constructions industries",
      label: "Building, metal and civil constructions industries",
    },
  ];
  useEffect(() => {
    loadGoogleMapsScript(() => {
      // The callback will be executed when the Google Maps API has been loaded
    });
  }, []);
  const form = useFormContext<LocationApi.Location>();
  return (
    <Stack paddingRight={"8px"}>
      <Stack borderWidth={"1px "} spacing={5}>
        <Stack padding={"12px 20px"} borderBottomWidth={"1px "}>
          <Heading
            fontSize={"14px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            General
          </Heading>
        </Stack>

        <Stack padding={"0px 20px"} direction={"row"} alignItems={"center"}>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Name is required",
              },
            }}
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  height: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Name"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />

          <Controller
            rules={{
              required: {
                value: true,
                message: "Location Code is required",
              },
            }}
            control={form.control}
            name="code"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  height: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Location Code"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
        </Stack>

        <Stack padding={"0px 20px"} mb="5px">
          <Controller
            rules={{
              required: {
                value: true,
                message: "Address is required",
              },
            }}
            control={form.control}
            name="address"
            render={({ field, fieldState: { error } }) => (
              <AddressInput
                onPlaceSelected={(place) => {
                  field.onChange(place.formatted_address);
                  form.setValue(
                    "addressCoordinates.longitude",
                    String(place.location.lng)
                  );
                  form.setValue(
                    "addressCoordinates.latitude",
                    String(place.location.lat)
                  );
                }}
                input={{
                  ...field,
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",

                  padding: "12px 15px",
                  variant: "outline",
                  isInvalid: (error?.message?.length ?? 0) > 0,
                  type: "text",
                }}
                label="Address"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                withValidation
                validationMessage={error?.message}
              />
            )}
          />
          <Map />
        </Stack>
      </Stack>
    </Stack>
  );
}
