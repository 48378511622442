import axiosInstanceGateway from "./axiosInstance-gateway";

declare module BusinessDetail {
  type BusinessDetails = {
    displayName: string;
    legalName: string;
    legalCode: string; // new added yet to be added in backend
    industry: string;
    organizationType: string;
    taxNumber: string;
    branch: string;
    organizationDescription: string;
    contactName: string;
    contactEmail?: string;
    contactNumber: string;
    website: string;
    streetAddress: string;
    city?: string;
    state?: string;
    postcode?: string;
    country: string;
    postalStreetAddress: string;
    postalCity?: string;
    postalState?: string;
    postalPostcode?: string;
    postalCountry: string;
    mobileNumber: string;
    faxNumber: string;
    landlineNumber: string;
    facebookLink: string;
    twitterLink: string;
    linkedinLink: string;
    invoiceInclusionFields: Array<string>;
    logo: string;
  };
}
module BusinessDetail {
  export enum BusinessOrganizationTypeEnums {
    "CLUB_OR_SOCIETY" = "CLUB_OR_SOCIETY",
    "COMPANY" = "COMPANY",
    "INDIVIDUAL" = "INDIVIDUAL",
    "NOT_FOR_PROFIT" = "NOT_FOR_PROFIT",
    "PARTNERSHIP" = "PARTNERSHIP",
    "SELF_MANAGED_SUPER_FUND" = "SELF_MANAGED_SUPER_FUND",
    "SOLE_TRADER" = "SOLE_TRADER",
    "SUPERANNUATION_FUND" = "SUPERANNUATION_FUND",
    "TRUST" = "TRUST",
    "PRIVATE_LIMITED" = "PRIVATE_LIMITED",
    "NGO" = "NGO",
    "SINGLE_MEMBER_COMPANY" = "SINGLE_MEMBER_COMPANY",
  }
  const API_URL = "/business-details";
  export function AddBusinessDetail(
    data: BusinessDetails,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetBusinessDetail(
    success: (data: BusinessDetails) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { BusinessDetail };
