import { Box, Heading, HStack, Stack } from "@chakra-ui/react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { Leaves } from "../../Api/Leaves";

interface BulkPayPeriodProps {
  index: number;
}
export default function BulkPayPeriod({ index }: BulkPayPeriodProps) {
  const form = useFormContext<Leaves.CreateLeavesBulk>();
  const LeavePayPeriodsQuantity = useFieldArray({
    control: form.control,
    name: `employeesPayPeriods.${index}.payPeriods`,
  });
  return (
    <Box>
      {LeavePayPeriodsQuantity.fields.map((field, i) => {
        return (
          <HStack marginTop={"10px"} key={field.id}>
            <Heading
              display={"flex"}
              flex={1}
              fontSize={"17px"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
              paddingBottom={"5px"}
            >
              {form.getValues(
                `employeesPayPeriods.${index}.payPeriods.${i}.string`
              )}
            </Heading>
            <Stack>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Days is required",
                  },
                }}
                control={form.control}
                name={`employeesPayPeriods.${index}.payPeriods.${i}.quantity`}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      alignItems: "flex-end",
                      w: "100px",
                      fontSize: "15px",
                      textAlign: "right",
                      variant: "outline",
                      type: "number",
                      border: "1px solid #a6a9b0",
                      bg: "#fff",
                      borderTopRadius: "3px",
                      onChange: (e) => {
                        field.onChange(Number(e.target.value));
                      },
                    }}
                    placeholder=""
                  />
                )}
              />
            </Stack>
          </HStack>
        );
      })}
    </Box>
  );
}
