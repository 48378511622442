import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useState } from "react";
import { RiArrowUpDownFill } from "react-icons/ri";

export function Sorting() {
  const [selectedSort, setSelectedSort] = useState("Status");

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<RiArrowUpDownFill />}
        alignItems={"center"}
        verticalAlign={"top"}
        lineHeight={"1rem"}
        minH={"40px"}
        minW={"40px"}
        padding={"11px 16px"}
        bg={"transparent"}
        borderRadius={"3px"}
        color={"rgba(0,10,30,.65)"}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
        fontSize={"15px"}
        _hover={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
        _active={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
      >
        {selectedSort}
      </MenuButton>
      <MenuList>
        {[
          "Status",
          "Newest first",
          "Oldest first",
          "Employee surname (A-Z)",
        ].map((sortOption) => (
          <MenuItem
            key={sortOption}
            fontSize={"15px"}
            color={"customColor.black7"}
            onClick={() => setSelectedSort(sortOption)}
          >
            {sortOption}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
