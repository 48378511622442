import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface TimesheetEditProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function TimesheetEdit({ isOpen, onClose }: TimesheetEditProps) {
  const navigate = useNavigate();

  // Step 1: Create state for selected approver

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        _light={{
          color: "customColor.black2",
        }}
        _dark={{
          color: "customColor.white",
        }}
        maxWidth={"300px"}
      >
        <ModalHeader
          justifyItems={"center"}
          fontSize={"19px"}
          padding={"12px 20px"}
          _light={{
            color: "customColor.black5",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Edit TimeSheet
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"8px 20px"} marginBottom={"16px"}>
          <Text
            _light={{
              color: "customColor.black5",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"15px"}
          >
            Are you sure? Editing this timesheet will revert the time back to
            draft.
          </Text>
        </ModalBody>

        <ModalFooter padding={"20px"}>
          <Button
            variant={"ghost"}
            onClick={onClose}
            fontSize={"13px"}
            padding={"11px 15px"}
            minH={"40px"}
            border={"2px solid lightgrey"}
            borderRadius={"3px"}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"11px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            minW={"75px"}
            onClick={() => {
              navigate("/app/payroll/Timesheet");
            }}
          >
            Edit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
