import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Employee } from "../Components/Employee";
import { TableForReport } from "../Tables/TableForReports";
import { FinancialYear } from "./FinancialYear";

export function PaymentSummaryDetails() {
  const name = "Payment Summary Details";
  const options = [
    "Standard View",
    "Group by employee",
    "Group by employee Group",
  ];
  const colBtns: Array<{
    colItem: Array<{ colLabel: string }>;
  }> = [
    {
      colItem: [
        { colLabel: "Employee" },
        { colLabel: "Employee Group " },
        { colLabel: "Employment Basis" },
        { colLabel: "Gross Payment" },
        { colLabel: "RFBA" },
        { colLabel: "CDEP" },
        { colLabel: "RESC" },
        { colLabel: "Lump Sum Items" },
        { colLabel: "Allowances" },
        { colLabel: "Tax Withheld" },
        { colLabel: "Workplace Givings" },
        { colLabel: "Union Fees" },
      ],
    },
  ];
  const headers: Array<{ label: string }> = [
    {
      label: "Employee",
    },
    {
      label: "Employee Group",
    },
    {
      label: "Gross Payment",
    },
    {
      label: "RFBA",
    },
    {
      label: "RESC",
    },
    {
      label: "Lump Sum Items ",
    },
    {
      label: "Allowance",
    },
    {
      label: "Tax Withheld",
    },
    {
      label: "Workplace Giving",
    },
    {
      label: "Union Fees",
    },
  ];
  const tableBody = [
    {
      employee: "James Lebron",
      employeeGroup: "None",
      grossPayment: "16,301.00",
      rfba: "0.00",
      resc: "100.00",
      lumpsum: "0.00",
      allowanace: "0.00",
      taxWithheld: "4356.00",
      workplace: "0.00",
      unionFee: "0.00",
    },
    {
      employee: "Odette Garrison",
      employeeGroup: "None",
      grossPayment: "16,301.00",
      rfba: "0.00",
      resc: "100.00",
      lumpsum: "0.00",
      allowanace: "0.00",
      taxWithheld: "4356.00",
      workplace: "0.00",
      unionFee: "0.00",
    },
    {
      employee: "OLiver Gray",
      employeeGroup: "None",
      grossPayment: "16,301.00",
      rfba: "0.00",
      resc: "100.00",
      lumpsum: "0.00",
      allowanace: "0.00",
      taxWithheld: "4356.00",
      workplace: "0.00",
      unionFee: "0.00",
    },
  ];
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _light={{
        bgColor: "customColor.white",
      }}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
    >
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.gray.400",
          }}
        >
          <Container padding={"0px 12px"} maxW="980px">
            <Stack gap={0} padding={"16px 0px"} margin={"0px 4px 0px 16px"}>
              <Link
                color={"#0078C8"}
                fontWeight={"400;"}
                fontSize={"13px"}
                href="organisationSettings"
              >
                Reports
              </Link>
              <Text
                fontWeight={"bold"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black",
                }}
                fontSize={"17px"}
                padding={"0px 12px 0px 0px"}
              >
                {name}
              </Text>
            </Stack>
          </Container>
        </Stack>
        <Container padding={"0px 12px"} maxW="980px">
          <Stack padding={"0px"}>
            <HStack>
              <Employee />

              <FinancialYear />
            </HStack>

            <Box
              display="flex"
              justifyContent="flex-end"
              margin={"20px 0px"}
              paddingLeft={"10px"}
            >
              <Button
                borderRadius={"3px"}
                fontSize={"15px"}
                h={"40px"}
                padding={"11px 12px"}
              >
                Upload
              </Button>
            </Box>
          </Stack>
        </Container>
      </Stack>
      <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
        <TableForReport
          headers={headers}
          tableBody={tableBody}
          options={options}
          colBtns={colBtns}
        />
      </Container>
      <Box
        borderTop="1px solid #ddd"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        position="fixed"
        left={0}
        bottom="0"
        w="full"
        h="50px"
        // zIndex={"overlay"}
      >
        <Container
          justifyContent="center"
          alignItems="flex-end"
          textAlign={"right"}
          maxW={"90%"}
        >
          <Menu>
            <MenuButton
              as={Button}
              variant="outline"
              border={"1px solid #a6a9b0"}
              rightIcon={<ChevronDownIcon />}
              borderRadius={"3px"}
              marginTop={"10px"}
            >
              Export
            </MenuButton>
            <MenuList style={{ minWidth: "120px" }}>
              <MenuItem
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
              >
                <Text
                  padding={"8px"}
                  margin={"0px 12px 0px 0px"}
                  _light={{
                    color: "customColor.black5",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontSize={"15px"}
                >
                  Excel
                </Text>
              </MenuItem>

              <MenuItem
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
              >
                <Text
                  padding={"8px"}
                  margin={"0px 12px 0px 0px"}
                  _light={{
                    color: "customColor.black5",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontSize={"15px"}
                >
                  PDF
                </Text>
              </MenuItem>
              <MenuItem
                padding={"0px 12px"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.1)",
                }}
              >
                <Text
                  padding={"8px"}
                  margin={"0px 12px 0px 0px"}
                  _light={{
                    color: "customColor.black5",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontSize={"15px"}
                >
                  Google Sheet
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Container>
      </Box>
    </Stack>
  );
}
