import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { GiGraduateCap } from "react-icons/gi";
import { IoIosArrowDown, IoIosEye } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AddDetailsDrawer from "./AddDetailsDrawer";
import { CreateInvoicesTable } from "./CreateInvoiceTable";

export function Invoices() {
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [state, setState] = useState(false);
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      overflowY="auto"
    >
      <Stack
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        padding={"16px"}
        gap={0}
      >
        <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="" fontSize={"13px"} color={"#3454d1"}>
              Sales overview
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="" fontSize={"13px"} color={"#3454d1"}>
              invoices
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"17px"}
            fontWeight={"700"}
          >
            New Invoice
          </Text>
          <Stack direction={"row"}>
            <Button
              variant={"ghost"}
              h={"38px"}
              color={"#3454d1"}
              leftIcon={<GiGraduateCap fontSize={"20px"} />}
              _hover={{
                bg: "rgba(0,10,30,.05)",
              }}
            >
              Tips and Tricks
            </Button>
            <Button
              color={"#3454d1"}
              h={"38px"}
              variant={"ghost"}
              leftIcon={<IoIosEye fontSize={"20px"} />}
              _hover={{
                bg: "rgba(0,10,30,.05)",
              }}
            >
              Preview
            </Button>
            <HStack gap={0}>
              <Box width={"100%"}>
                <Button
                  borderRadius={"3px"}
                  borderRightRadius={0}
                  backgroundColor={"#fff"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"38px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "#3454D1",
                  }}
                >
                  Save and close
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  h={"38px"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  borderTop={"1px solid #e5e7eb"}
                  borderRight={"1px solid #e5e7eb"}
                  borderBottom={"1px solid #e5e7eb"}
                  borderRightRadius={"4px"}
                  _hover={{
                    color: "customColor.black7",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "customColor.black7",
                    bg: "#f2f3f4",
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  icon={<IoIosArrowDown />}
                />

                <MenuList overflowY="auto" zIndex={"overlay"}>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Save & add another
                    </Text>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      Submit for approval
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
            <HStack gap={0}>
              <Box width={"100%"}>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"38px"}
                  borderRadius={"3px"}
                  borderRightRadius={0}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                >
                  Approve & email
                </Button>
              </Box>

              <Menu>
                <MenuButton
                  as={IconButton}
                  h={"38px"}
                  _light={{
                    bgColor: "#3454d1",
                    color: "customColor.white",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  borderLeft={"1px solid #ffffff"}
                  borderTop={"1px solid #3454d1"}
                  borderRight={"1px solid #3454d1"}
                  borderBottom={"1px solid #3454d1"}
                  borderRightRadius={"4px"}
                  icon={<IoIosArrowDown />}
                />

                <MenuList overflowY="auto" zIndex={"overlay"}>
                  {[
                    "Approve",
                    "Approve & add another",
                    "Approve & view next",
                    "Approve & get link",
                    "Approve & print PDF",
                  ].map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        padding={"0px 12px"}
                        _hover={{
                          backgroundColor: "rgba(0,10,30,.1)",
                        }}
                      >
                        {" "}
                        <Text
                          padding={"8px"}
                          margin={"0px 12px 0px 0px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.pearlWhite",
                          }}
                          fontSize={"15px"}
                        >
                          {item}
                        </Text>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </HStack>
            <Menu>
              <MenuButton
                as={IconButton}
                padding={0}
                backgroundColor={"transparent"}
                h={"34.5px"}
                marginLeft={"8px"}
                color={"black"}
                display={{
                  lg: "flex",
                  md: "flex",
                  sm: "flex",
                  xs: "flex",
                }}
                borderRadius={"full"}
                _hover={{
                  backgroundColor: "rgba(0,10,30,.05)",
                  color: "#000a1e",
                }}
                _active={{
                  backgroundColor: "rgba(0,10,30,.05)",
                  color: "#000a1e",
                }}
                icon={<FiMoreVertical fontSize={"19px"} />}
              ></MenuButton>
              <MenuList overflowY="auto" zIndex={"overlay"} w={"300px"}>
                {[
                  "Print PDF",
                  "Email",
                  "Copy to draft invoices",
                  "Copy to draft quote",
                  "Delete",
                ].map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"0px 12px"}
                      _hover={{
                        backgroundColor: "rgba(0,10,30,.1)",
                      }}
                    >
                      {" "}
                      <Text
                        padding={"8px"}
                        margin={"0px 12px 0px 0px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        fontSize={"15px"}
                      >
                        {item}
                      </Text>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
      </Stack>
      <Container maxW={"container.xl"} padding={"20px"}>
        <Stack
          display={state ? "none" : "flex"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          _light={{
            bgColor: "customColor.white",
          }}
          padding={"16px"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px solid #e5e7eb"
        >
          <Text
            fontSize={"17px"}
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"700"}
          >
            Set up invoice template and payments
          </Text>
          <Stack direction={"row"}>
            <Button
              variant={"ghost"}
              h={"38px"}
              color={"#3454d1"}
              _hover={{
                bg: "rgba(0,10,30,.05)",
              }}
              onClick={() => {
                setState(!state);
              }}
            >
              Skip for now
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"38px"}
              borderRadius={"3px"}
              borderRightRadius={0}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                onOpen();
              }}
            >
              Add Details
            </Button>
            <Button
              bg={"#fff"}
              h={"34px"}
              color={"customColor.black7"}
              padding={""}
              borderRadius={"full"}
              _hover={{
                bg: "rgba(0,10,30,.05)",
              }}
              onClick={() => {
                setState(!state);
              }}
            >
              <IoClose fontSize={"18px"} />
            </Button>
          </Stack>
        </Stack>
        <CreateInvoicesTable />
        <Stack
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Button
            variant={"ghost"}
            padding={"12px 16px"}
            h={"38px"}
            color={"#3454d1"}
            _hover={{
              bg: "rgba(0,10,30,.05)",
            }}
            onClick={() => {
              navigate("/app/payroll/ClassicInvoice");
            }}
          >
            Switch to classic invoicing
          </Button>
        </Stack>
      </Container>
      <AddDetailsDrawer isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
}
