import { axiosInstance } from "./axiosInstance-hris";
declare module EmployeeLeave {
  enum LeaveCategory {
    NORMAL_LEAVE = "NORMAL_LEAVE",
    CASHOUT_LEAVE = "CASHOUT_LEAVE",
  }
  type payperiodsQuantity = {
    id?: string;
    startDate: string;
    endDate: string;
    quantity: number;
    string?: string;
  };
  type NewLeave = {
    id?: string;
    empId?: string;
    leaveCategory: "NORMAL_LEAVE" | "CASHOUT_LEAVE" | undefined;
    payrollPayItemLeaveId: string;
    startDate?: string;
    endDate?: string;
    description: string;
    status?: string;
    payoutDate?: string;
    notes: string;
    quantity?: number;
    //yet to be added from backend
    employeeName?: string;
    payPeriods: Array<payperiodsQuantity>;
  };
  type Leave = {
    id: string;
    empId: string;
    leaveCategory: string;
    payrollPayItemLeaveId: string;
    leaveEntitlementId: string;
    startDate: string;
    endDate: string;
    quantity: number;
    description: string;
    status: string;
    note: string;
    payoutDate: String;
    //yet to be added from backend
    employeeName?: string;
    payPeriods: Array<payperiodsQuantity>;
  };
  type RejectLeave = {
    note?: string;
  };
  type LeavePayPeriods = {
    empId: string;
    startDate: string;
    endDate: string;
  };
  type BulkLeavePayPeriods = {
    empIds: Array<string>;
    startDate: string;
    endDate: string;
  };
  type ResponsePayPeriods = {
    startDate: string;
    endDate: string;
    string?: string;
    quantity: number;
    id?: string;
  };
  type FetchBulkPayPeriods = {
    empId: string;
    payPeriods: Array<ResponsePayPeriods>;
  };
}
module EmployeeLeave {
  const API_URL = "/employee-leaves";
  export function CreateLeave(
    empId: string,
    data: NewLeave,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function LeaveRejected(
    id: string,
    data: RejectLeave,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/reject/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function LeavePayPeriods(
    data: LeavePayPeriods,
    success: (data: Array<ResponsePayPeriods>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/leave-payperiods`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function BulkPayPeriods(
    data: BulkLeavePayPeriods,
    success: (data: Array<FetchBulkPayPeriods>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/bulk-leave-payperiods`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchLeave(
    empId: string,
    success: (data: Array<Leave>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateLeave(
    id: string,
    empId: string,
    data: Partial<NewLeave>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
}
export { EmployeeLeave };
