import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { Statutory } from "../../../Api/Statutory";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Insurance } from "../../../Api/Insurance";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export function StatutoryBenefits({ setSelectedTab, selectedTab }: TabProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { currentBusinessId } = useBusinessContext();
  const [insuranceData, setInsuranceData] = useState<
    Insurance.FetchInsurance[]
  >([]);
  const { id } = useParams<{ id: string }>();
  const form = useForm<Statutory.StatutoryBenefitsData>({
    defaultValues: {
      socialSecurityNumber: "",
      eobiNumber: "",
      businessInsuranceId: "",
    },
  });
  useEffect(() => {
    if (id) {
      Statutory.FetchStatutoryBenefits(
        id,
        (data) => {
          form.reset(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [id]);
  useEffect(() => {
    Insurance.GetInsuranceDetails(
      (data) => {
        const gliData = data?.filter((item) => item?.type === "GROUP_LIFE");
        setInsuranceData(gliData);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  const watchBusinessInsuranceId = useWatch({
    control: form.control,
    name: "businessInsuranceId",
  });
  return (
    <FormProvider {...form}>
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginBottom={"30px"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderRadius={"5px"}
        border={"1px solid #f6f6f6"}
      >
        <Stack gap={0}>
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              as="h2"
              size="md"
              _light={{
                color: "customColor.black7",
              }}
              borderBottom="1px solid #d6dade"
            >
              Statutory Benefits
            </Heading>

            <Container
              maxW="400px"
              marginTop="15px"
              paddingTop="20px"
              pb="8px"
              marginX="auto"
              px="0px"
            >
              <Box
                fontSize={"0.8125rem"}
                fontWeight={"bold"}
                lineHeight={"1.3077"}
                padding={"16px 0px 0px"}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Social Security Number is required",
                    },
                  }}
                  control={form.control}
                  name="socialSecurityNumber"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Enter Security Number"
                      label="Social Security Number"
                      labelProps={{
                        fontSize: ".845rem",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                fontSize={"0.8125rem"}
                fontWeight={"bold"}
                lineHeight={"1.3077"}
                padding={"25px 0px 0px"}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "EOBI Number is required",
                    },
                  }}
                  control={form.control}
                  name="eobiNumber"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Enter EOBI"
                      label="EOBI Number"
                      labelProps={{
                        fontSize: ".845rem",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Container>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              as="h2"
              size="md"
              _light={{
                color: "customColor.black7",
              }}
              borderBottom="1px solid #d6dade"
            >
              Group life Insurance
            </Heading>

            <Container
              maxW="400px"
              marginTop="15px"
              paddingTop="20px"
              pb="8px"
              marginX="auto"
              px="0px"
            >
              <Box
                fontSize={"0.8125rem"}
                fontWeight={"bold"}
                lineHeight={"1.3077"}
              >
                <Heading
                  alignContent={"left"}
                  fontSize=".9975rem"
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  margin={"15px 0px"}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  textDecor={"underline"}
                >
                  Life Insurance
                </Heading>
                <Stack>
                  <Controller
                    control={form.control}
                    name="businessInsuranceId"
                    render={({ field }) => (
                      <RadioGroup
                        onChange={(value) => {
                          form.setValue(
                            "businessInsuranceId",
                            value === "NA" ? null : value
                          );
                        }}
                        value={
                          form.getValues("businessInsuranceId") ?? undefined
                        }
                      >
                        <Stack spacing={5} direction="row">
                          {insuranceData?.map((item) => (
                            <Radio
                              key={item.id}
                              value={item.id}
                              colorScheme="blue"
                            >
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                              >
                                {item.name}
                              </Text>
                            </Radio>
                          ))}
                          <Radio value="NA" colorScheme="blue">
                            <Text
                              fontSize={"14px"}
                              fontWeight={"700"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              _light={{
                                color: "customColor.black7",
                              }}
                            >
                              NA
                            </Text>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </Stack>
                {watchBusinessInsuranceId && (
                  <Stack
                    borderBottom={"2px solid #dadddf"}
                    borderTop={"2px solid #dadddf"}
                    margin={"25px 0px 20px"}
                  >
                    <Stack paddingTop={"15px"} paddingBottom={"15px"}>
                      <HStack>
                        <Stack flex={2}>
                          {[
                            "Name:",
                            "Plan:",
                            "Natural Death Coverage:",
                            "Accidental Death Coverage:",
                            "Coverage:",
                          ].map((item, i) => {
                            return (
                              <Stack key={i}>
                                <Text
                                  _dark={{
                                    color: "customColor.white",
                                  }}
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  fontSize={"13px"}
                                  fontWeight={"700"}
                                >
                                  {item}
                                </Text>
                              </Stack>
                            );
                          })}
                        </Stack>
                        <Stack flex={1}>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"500"}
                            fontSize={"13px"}
                          >
                            {insuranceData?.find(
                              (item) => item.id === watchBusinessInsuranceId
                            )?.name ?? "NA"}
                          </Text>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"500"}
                            fontSize={"13px"}
                          >
                            {insuranceData?.find(
                              (item) => item.id === watchBusinessInsuranceId
                            )?.plan ?? "NA"}
                          </Text>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"500"}
                            fontSize={"13px"}
                          >
                            {insuranceData
                              ?.find(
                                (item) => item.id === watchBusinessInsuranceId
                              )
                              ?.naturalDeathAmount?.toLocaleString() ?? "NA"}
                          </Text>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"500"}
                            fontSize={"13px"}
                          >
                            {insuranceData
                              ?.find(
                                (item) => item.id === watchBusinessInsuranceId
                              )
                              ?.accidentalDeathAmount?.toLocaleString() ?? "NA"}
                          </Text>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"500"}
                            fontSize={"13px"}
                          >
                            {insuranceData?.find(
                              (item) => item.id === watchBusinessInsuranceId
                            )?.coverage === "SINGLE"
                              ? "Single"
                              : insuranceData?.find(
                                  (item) => item.id === watchBusinessInsuranceId
                                )?.coverage === "MARRIED_WITH_CHILDREN"
                              ? "Spouse,Children"
                              : insuranceData?.find(
                                  (item) => item.id === watchBusinessInsuranceId
                                )?.coverage === "WITH_PARENTS"
                              ? "Parents"
                              : insuranceData?.find(
                                  (item) => item.id === watchBusinessInsuranceId
                                )?.coverage ?? "NA"}
                          </Text>
                        </Stack>
                      </HStack>
                      {/* <HStack>
                        <Text>Name:</Text>
                        <Text>
                          {insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.name ?? "NA"}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text>Plan:</Text>
                        <Text>
                          {insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.plan ?? "NA"}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text>Natural Death Coverage:</Text>
                        <Text>
                          {insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.naturalDeathAmount ?? "NA"}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text>Accidental Death Coverage:</Text>
                        <Text>
                          {insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.accidentalDeathAmount ?? "NA"}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text>Covergae:</Text>
                        <Text>
                          {insuranceData?.find(
                            (item) => item.id === watchBusinessInsuranceId
                          )?.coverage ?? "NA"}
                        </Text>
                      </HStack> */}
                    </Stack>
                  </Stack>
                )}
              </Box>
            </Container>
          </Box>

          <Box
            px="20px"
            marginTop={"15px"}
            display="flex"
            justifyContent="flex-end"
            alignItems={"baseline"}
          >
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              View PDF
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              isLoading={loading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setLoading(true);
                    Statutory.CreateStatutoryBenefits(
                      id!,
                      data,
                      (success) => {
                        toast({
                          title: "Statutory Benefits Added/Updated",
                          status: "success",
                          duration: 2000,
                          isClosable: true,
                        });
                        form.reset(success);
                        setLoading(false);
                        setSelectedTab(selectedTab + 1);
                      },
                      (error) => {
                        toast({
                          title: error,
                          status: "error",
                          duration: 2000,
                          isClosable: true,
                        });
                        setLoading(false);
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      status: "error",
                    });
                    setLoading(false);
                  }
                )(e);
              }}
            >
              {loading ? "Saving..." : "Save and Next"}
            </Button>

            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={loading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setLoading(true);
                    Statutory.CreateStatutoryBenefits(
                      id!,
                      data,
                      (success) => {
                        toast({
                          title: "Medical Benefits Added/Updated",
                          status: "success",
                          duration: 2000,
                          isClosable: true,
                        });
                        setLoading(false);
                      },
                      (error) => {
                        toast({
                          title: error,
                          status: "error",
                          duration: 2000,
                          isClosable: true,
                        });
                        setLoading(false);
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      status: "error",
                    });
                    setLoading(false);
                  }
                )(e);
              }}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Stack>

        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
