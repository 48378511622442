import { axiosInstance } from "./axiosInstance-hris";

declare module BusinessTaxRate {
  type AddNewTax = {
    name: string;
    tax_type: string;
    tax_components: Array<taxComponents>;
  };

  type taxComponents = {
    name: string;
    tax: number;
  };
  type FetchTaxRate = {
    id: string;
    name: string;
    tax_type: string;
    tax_components: Array<taxComponents>;
    businessId: string;
    chartOfAccountsCount: number;
  };
}
module BusinessTaxRate {
  export enum Types {
    SALES = "SALES",
    PURCHASES = "PURCHASES",
    GST_FREE_SALES = "GST_FREE_SALES",
    EXEMPT_INCOME = "EXEMPT_INCOME",
    BAS_EXCLUDED = "BAS_EXCLUDED",
    GST_FREE_EXPENSES = "GST_FREE_EXPENSES",
  }
  const API_URL = "/businessTaxRates";
  export function NewTaxAdded(
    data: AddNewTax,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateTaxRate(
    id: string,
    data: Partial<AddNewTax>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function GetTaxRates(
    success: (data: Array<FetchTaxRate>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
  export function DeleteTaxRates(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
  export function BulkDeleteTaxRates(
    ids: Array<string>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/bulkRemove`, { data: { ids } })
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
}
export { BusinessTaxRate };
