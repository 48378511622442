import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Employees } from "../../../Api/Employees";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";
import { PayRun } from "../../../Api/PayRun";
import { TimeSheetNewDetail } from "../../../Api/TimeSheetDetail";
import { DownloadExcel } from "../utils";

interface DownloadTimesheetTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  employeesList: Array<Employees.timeSheetEmployees>;
}
export function DownloadTimesheetTemplate({
  isOpen,
  onClose,
  employeesList,
}: DownloadTimesheetTemplateProps) {
  const [calenderId, setCalenderId] = useState<string>();
  const [payPeriods, setPayPeriods] = useState<PayRun.PayPeriodByCalenderID[]>(
    []
  );

  const navigate = useNavigate();

  const form = useFormContext<TimeSheetNewDetail.AddNewDetail>();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [payOptions, setPayOptions] = useState<PayrollCalendar.Calendar[]>([]);
  const [selectedPaycalenderId, setSelectedPaycalenderId] = useState<string>();
  const [selectedPayperiod, setSelectedPayperiod] = useState<any>();
  useEffect(() => {
    PayrollCalendar.FetchCalendar(
      (data) => {
        setPayOptions(data);
      },
      (error) => {
        toast({
          title: "Error",
          status: "error",
        });
      }
    );
  }, []);
  useEffect(() => {
    if (selectedPaycalenderId) {
      PayRun.FetchPayPeriodByCalenderID(
        selectedPaycalenderId,
        (data) => {
          setPayPeriods(data);
        },
        (error) => {
          toast({
            title: "Error",
            status: "error",
          });
        }
      );
    }
  }, [selectedPaycalenderId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text
            fontSize={"18px"}
            padding={"15px 0px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Download Template
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack direction={"row"}>
            <Stack flex={1}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Calender
              </Text>

              <ReactSelect
                placeholder=""
                withValidation
                options={payOptions}
                value={
                  payOptions.find(
                    (option) => option.id === selectedPaycalenderId
                  ) || undefined
                }
                onChange={(newValue: any) => {
                  setSelectedPaycalenderId(newValue?.id);
                }}
                getOptionLabel={(option: any) => option?.name}
                getOptionValue={(option: any) => option?.id}
              />
            </Stack>
            <Stack flex={1}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Pay Period
              </Text>

              <ReactSelect
                placeholder="Select "
                ErrorMessage="Select Pay Period"
                withValidation
                options={payPeriods}
                value={
                  payPeriods.find(
                    (option) => option?.string === selectedPayperiod?.string
                  ) || undefined
                }
                onChange={(newValue: any) => {
                  setSelectedPayperiod(newValue);
                }}
                getOptionLabel={(option: any) => option?.string}
                getOptionValue={(option: any) => option}
              />
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                if (selectedPaycalenderId && selectedPayperiod) {
                  Employees.downloadTimesheetTemplate(
                    selectedPaycalenderId,
                    {
                      startDate: selectedPayperiod?.startDate,
                      endDate: selectedPayperiod?.endDate,
                    },
                    (success) => {
                      DownloadExcel(success.data, "TimesheetTemplate.xlsx");
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        status: "error",
                      });
                    }
                  );
                }
              }}
            >
              Download
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
