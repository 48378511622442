import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";

interface TaxLineModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export function TaxLineModal({ isOpen, onClose }: TaxLineModalProps) {
  const payPeriodOptions = [
    { value: "Manual Adjustment - PAYG", label: "Manual Adjustment - PAYG" },
    {
      value: "Manual Adjustment - PAYG on Schedule 4",
      label: "Manual Adjustment - PAYG on Schedule 4",
    },
    {
      value: "Manual Adjustment - PAYG on Schedule 5",
      label: "Manual Adjustment - PAYG on Schedule 5",
    },
    {
      value: "Manual Adjustment - STSL Components on Schedule 5 ",
      label: "Manual Adjustment - STSL Components on Schedule 5",
    },
  ];
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          h="50px"
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.dark.250",
          }}
        >
          Add an Tax Line
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack maxW={"300px"} gap={0}>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"bold"}
            >
              Tax Rate <Icon color="teal.500" as={QuestionIcon} />
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={payPeriodOptions}
              value={payPeriodOptions.find((option) => option)}
              onChange={(option: any) => {}}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
            <CustomInput
              withValidation
              input={{
                isInvalid: false,
                fontSize: "12px",
                variant: "outline",
                type: "text",
                bg: "#fff",
                height: "25px",
                padding: "12px 16px",
                boxShadow: "none",
                placeholder: "",
              }}
              label="Description"
              labelProps={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#444",
                marginBottom: "5px",
              }}
            />

            <FormControl>
              <FormLabel
                mb={0}
                fontWeight="bold"
                marginTop="20px"
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Liability Account
              </FormLabel>

              <Text
                textAlign={"left"}
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                marginTop={"5px"}
                marginBottom={"5px"}
                borderTop={"1px solid #999"}
                borderBottom={"1px solid #ccc"}
              >
                825: PAYG Withholdings Payable
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onClose();
            }}
          >
            Ok
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
