import { axiosInstance } from "./axiosInstance-hris";
declare module EmployeeTaxes {
  type NewTax = {
    id: string;
    empId: string;
    taxScaleType: string;
    taxNumber: string;
    withholdingVariation: boolean;
    studyLoan: boolean;
    exemptionReason: string;
    claimTaxFreeThreshold: boolean;
    claimOtherTaxOffset: boolean;
    increaseTaxEachPeriod: boolean;
    leaveLoadingEligibility: boolean;
    residencyStatus: "PAKISTANI" | "FOREIGN";
  };
  type Tax = {
    id: string;
    empId: string;
    taxScaleType: string;
    taxNumber: string;
    withholdingVariation: boolean;
    studyLoan: boolean;
    claimTaxFreeThreshold: boolean;
    claimOtherTaxOffset: boolean;
    increaseTaxEachPeriod: boolean;
    leaveLoadingEligibility: boolean;
    residencyStatus: "PAKISTANI" | "FOREIGN";
  };
}
module EmployeeTaxes {
  const API_URL = "/employee-taxes-details";
  export function CreateTax(
    data: NewTax,
    success: (data: NewTax) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchTax(
    empId: string | undefined,
    success: (data: Tax) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { EmployeeTaxes };
