import {useDrag} from "react-dnd";

interface DraggableProps<T> {
  type: string;
  item: T;
  children: React.ReactNode;
}
export function Draggable<T>({type, item, children}: DraggableProps<T>) {
  const [{isDragging}, drag] = useDrag(
    () => ({
      type,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [type, item]
  );
  return <span ref={drag}>{children}</span>;
}
