import {
  AbsoluteCenter,
  Box,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { AccessControl } from "../api/AccessControl";
import { flattenNestedObject } from "../utils/flattenNestedObject";
import { useBusinessContext } from "./BusinessContext";

const INIT_PERMISSIONS: AccessControlContextProps = {
  "accounting.chartOfAccounts": false,
  "accounting.taxRates": false,
  "employee.financialDetails": false,
  "employee.personalDetails": false,
  "payroll.payrollSettings": false,
  "payroll.payRuns": false,
  "payroll.timesheets": false,
  "reports.employeeCheckIn": false,
  "reports.employeeDetails": false,
  "timeAndAttendance.locations": false,
  "timeAndAttendance.notifications": false,
  "timeAndAttendance.schedules": false,
};

export interface AccessControlContextProps {
  "payroll.payRuns": boolean;
  "payroll.timesheets": boolean;
  "payroll.payrollSettings": boolean;
  "reports.employeeCheckIn": boolean;
  "reports.employeeDetails": boolean;
  "employee.personalDetails": boolean;
  "employee.financialDetails": boolean;
  "accounting.taxRates": boolean;
  "accounting.chartOfAccounts": boolean;
  "timeAndAttendance.locations": boolean;
  "timeAndAttendance.schedules": boolean;
  "timeAndAttendance.notifications": boolean;
}
export const AccessControlContext =
  createContext<AccessControlContextProps>(INIT_PERMISSIONS);

interface AccessControlProviderProps {
  children: React.ReactNode;
}
export function AccessControlProvider({
  children,
}: AccessControlProviderProps) {
  const toast = useToast({
    position: "top",
    status: "error",
    title: "Error",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [flattenPermissions, setFlattenPermissions] =
    useState<AccessControlContextProps>(INIT_PERMISSIONS);
  const { currentBusinessId } = useBusinessContext();

  useEffect(() => {
    AccessControl.GetLoggedInUserPermissions(
      (permission) => {
        const plainPermissions = flattenNestedObject(
          permission.allowedFeatures
        );

        setFlattenPermissions(plainPermissions);
        setIsLoaded(true);
      },
      (error) => {
        toast({ description: error });
        setIsLoaded(true);
      }
    );
  }, [currentBusinessId]);
  return (
    <AccessControlContext.Provider value={flattenPermissions}>
      {isLoaded ? (
        children
      ) : (
        <Box w="100vw" h="100vh">
          <AbsoluteCenter>
            <VStack>
              <Spinner size="xl" />
              <Text>Checking Permissions</Text>
            </VStack>
          </AbsoluteCenter>
        </Box>
      )}
    </AccessControlContext.Provider>
  );
}

export function useAccessControl() {
  const context = useContext<AccessControlContextProps>(AccessControlContext);

  return context;
}
