import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useBusinessContext } from "../../Hooks/BusinessContext";
import { Analytics } from "../../Payroll/Api/DashboardAnalytics";
import { TaxFinancialYear } from "../../Payroll/Api/TaxFinancialYear";

interface DashboardContextProps {
  //   businessesList: Business.GetBusinessDetails[];
  //   employeesList?: Employees.FetchEmployees[];
  //   locationList?: LocationApi.Location[];
  //   lastUpdated: number;
  //   refereshEmployees: boolean;
  //   setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  //   setRefereshEmployees: React.Dispatch<React.SetStateAction<boolean>>;
  //   setCurrentBusinessId: React.Dispatch<React.SetStateAction<string>>;
  //   currentBusinessId?: string;
  editDashboard: boolean;
  setEditDashboard: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardAnalytics: any;
  taxFinancialYear: TaxFinancialYear.FetchTax[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // setDashboardAnalytics: any;
}

const DashboardContext = createContext<DashboardContextProps>({
  //   businessesList: [],
  //   employeesList: [],

  //   locationList: [],
  //   lastUpdated: 0,
  //   setLastUpdated: () => {},
  //   setCurrentBusinessId: () => {},
  //   currentBusinessId: undefined,
  //   refereshEmployees: false,
  //   setRefereshEmployees: () => {},
  dashboardAnalytics: undefined,
  taxFinancialYear: [],
  editDashboard: false,
  setEditDashboard: () => {},
  isLoading: false,
  setIsLoading: () => {},
});
interface DashboardContextProviderProps {
  children: any;
}
const DashboardContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  //   const [businessesList, setBusinessesList] = useState<
  //     Array<Business.GetBusinessDetails>
  //   >([]);
  //   const [employeesList, setEmployeesList] = useState<
  //     Array<Employees.FetchEmployees>
  //   >([]);

  //   const [locationList, setLocationList] = useState<Array<LocationApi.Location>>(
  //     []
  //   );
  //   const [lastUpdated, setLastUpdated] = useState<number>(0);
  //   const [refereshEmployees, setRefereshEmployees] = useState<boolean>(false);
  //   const auth = useAuthHeader();
  //   const decodedToken = jwtDecode<any>(auth());
  //   const [currentBusinessId, setCurrentBusinessId] = useState<string>(
  //     decodedToken?.businessId
  //   );
  const [editDashboard, setEditDashboard] = useState<boolean>(false);
  const [dashboardAnalytics, setDashboardAnalytics] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxFinancialYear, setTaxFinancialYear] = useState<
    TaxFinancialYear.FetchTax[]
  >([]);
  const { currentBusinessId } = useBusinessContext();
  const toast = useToast();
  useEffect(() => {
    if (currentBusinessId) {
      setIsLoading(true);
      Analytics.FetchAnalytics(
        (data) => {
          setDashboardAnalytics(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
          setIsLoading(false);
        }
      );
      TaxFinancialYear.GetTaxFinancialYear(
        (data) => {
          setTaxFinancialYear(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
          });
          setIsLoading(false);
        }
      );
    }
  }, [currentBusinessId]);
  return (
    <DashboardContext.Provider
      value={{
        dashboardAnalytics,
        editDashboard,
        setEditDashboard,
        taxFinancialYear,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
function useDashboardContext() {
  return useContext<DashboardContextProps>(DashboardContext);
}
export { DashboardContext, DashboardContextProvider, useDashboardContext };
