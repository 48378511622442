import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface AddModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function AddModal({ isOpen, onClose }: AddModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxH={"650px"} overflowX={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize="17px"
        >
          Add a contact
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={400}
            fontSize={"15px"}
          >
            To manage online payments, add a contact to this invoice.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
          >
            {"Ok"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
