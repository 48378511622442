import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSelect } from "../../../../Common/ReactSelect";

interface SubmitProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function Submit({ isOpen, onClose }: SubmitProps) {
  const navigate = useNavigate();
  const Approver: Array<{ label: string; value: string }> = [
    {
      label: "Irsam Hashmi",
      value: "IRSAM_HASHMI",
    },
    {
      label: "Danish Khowaja",
      value: "DANISH_KHOWAJA",
    },
    {
      label: "Sauod Mirza",
      value: "SAOUD_MIRZA",
    },
  ];

  // Step 1: Create state for selected approver
  const [selectedApprover, setSelectedApprover] = useState(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        _light={{
          color: "customColor.black2",
        }}
        _dark={{
          color: "customColor.white",
        }}
        maxWidth={"400px"}
      >
        <ModalHeader
          justifyItems={"center"}
          padding={"12px 20px"}
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
        >
          Confirm TimeSheet
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"8px 20px"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"15px"}
          >
            You are submitting for <strong>1 - 30 Jun 2024</strong>
          </Text>
          <Stack margin={"24px 0px 40px"} gap={0}>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"13px"}
              fontWeight={"bold"}
              padding={"0px 0px 4px"}
            >
              Approver
            </Text>
            <ReactSelect
              placeholder=""
              ErrorMessage="Select Charges Head"
              withValidation
              options={Approver}
              // Step 3: Pass state variable to value
              value={selectedApprover}
              // Step 4: Update state on change
              onChange={(newValue: any) => {
                setSelectedApprover(newValue);
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </Stack>
          <Stack marginTop={"32px"}>
            {[
              {
                period: "Sat 1 - Fri 7",
                tagName: "Current Week",
                hours: "7h",
              },
              {
                period: "Sat 8 - Fri 14",

                hours: "0h",
              },
              {
                period: "Sat 15 - Fri 21",

                hours: "0h",
              },
              {
                period: "Sat 22 - Fri 28",

                hours: "0h",
              },
              {
                period: "Sat 29 - Sun 30",

                hours: "0h",
              },
            ].map((item, i) => {
              return (
                <Stack
                  key={i}
                  direction={"row"}
                  padding={"8px 0px"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} alignItems={"center"}>
                    <Text
                      fontSize={"15px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                    >
                      {item.period}
                    </Text>
                    {item.tagName && (
                      <Tag
                        boxShadow={"inset 0 0 0 1px rgba(0,10,30,.5)"}
                        bg={"#fff"}
                        padding={"0 4px"}
                        borderRadius={"3px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        {item.tagName}
                      </Tag>
                    )}
                  </Stack>

                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize={"15px"}
                  >
                    {item.hours}
                  </Text>
                </Stack>
              );
            })}
            <Divider bg={"#A6A9B0"} margin={"12px 0px"} height={"1px"} />
            <HStack
              direction={"row"}
              paddingTop={"8px"}
              justifyContent={"space-between"}
            >
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize={"18px"}
                fontWeight={"bold"}
              >
                {" "}
                Total
              </Text>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize={"21px"}
                fontWeight={"bold"}
              >
                {" "}
                7h
              </Text>
            </HStack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              navigate("/app/payroll/TimesheetOver");
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
