import {
  Box,
  Button,
  Container,
  HStack,
  Input,
  InputGroup,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { PaginationControl } from "../../../PayrollEmployee/PaginationControl/PaginationControl";
import { Archive } from "../Modal/Archive";
import { ChangeOfTaxModal } from "../Modal/ChangeOfTaxModal";
import { Delete } from "../Modal/Delete";

interface HeaderProps {
  selectedIds: string[];
  setLastUpdated: (timestamp: number) => void;
  searchQuery: string;
  debounced: (e: any) => void;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageLimit: number;
  setPageLimit: React.Dispatch<React.SetStateAction<number>>;
}
export function Header({
  selectedIds,
  setLastUpdated,
  searchQuery,
  debounced,
  pageLimit,
  setPageNumber,
  setPageLimit,
}: HeaderProps) {
  const buttonSize = useBreakpointValue({ base: "sm", sm: "md" }); // Adjust button size based on screen size
  const deleteModal = useDisclosure();
  const achieveModal = useDisclosure();
  const changeOfTaxModal = useDisclosure();

  return (
    <Container
      justifyContent="center"
      alignItems="center"
      p={"10px 25px"}
      margin={0}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      maxW={"100%"}
    >
      <Stack
        direction={["column", "row"]}
        justifyContent={"space-between"}
        alignItems="center"
        gap={0}
      >
        <Stack direction={"row"}>
          <Button
            color={"#999"}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#EA4D4D",
            }}
            size={buttonSize}
            onClick={() => deleteModal.onOpen()}
          >
            Delete
          </Button>
          <Delete
            isOpen={deleteModal.isOpen}
            onClose={deleteModal.onClose}
            selectedIds={selectedIds}
            setLastUpdated={setLastUpdated}
            numberOfCheckedRows={selectedIds.length}
          />
          <Button
            color={"#999"}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "teal.500",
            }}
            size={buttonSize}
            onClick={() => achieveModal.onOpen()}
          >
            Archive
          </Button>
          <Archive
            isOpen={achieveModal.isOpen}
            onClose={achieveModal.onClose}
            status={true}
            setLastUpdated={setLastUpdated}
            numberOfCheckedRows={selectedIds.length}
            selectedIds={selectedIds}
          />
          <Button
            color={"#999"}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#3454D1",
            }}
            size={buttonSize}
            onClick={() => changeOfTaxModal.onOpen()}
          >
            Change Tax Rate
          </Button>
          <ChangeOfTaxModal
            isOpen={changeOfTaxModal.isOpen}
            onClose={changeOfTaxModal.onClose}
            setLastUpdated={setLastUpdated}
            selectedIds={selectedIds}
          />
        </Stack>
        <Text color={"#868686"} fontSize={"11px"}>
          {selectedIds.length} account selected
        </Text>
      </Stack>

      <Stack
        justifyContent={"space-between"}
        alignItems="center"
        direction={"row"}
      >
        <Box w={"100%"}>
          <PaginationControl
            limit={pageLimit}
            setLimit={setPageLimit}
            setPageNumber={setPageNumber}
          />
        </Box>
        <Stack direction={["row"]} alignItems="center" gap={0}>
          <HStack height={"60px"}>
            <Text
              fontSize={"13.44px"}
              _light={{
                color: "customColor.gray.6b7885",
              }}
            >
              Search:
            </Text>
            <InputGroup ml={[0, "7px"]}>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
                onChange={(e) => {
                  debounced(e.target.value);
                }}
              />
            </InputGroup>
          </HStack>
        </Stack>
      </Stack>
    </Container>
  );
}
