import { axiosInstance } from "./axiosInstance-hris";

declare module StatutoryBenefitsApi {
  type StatutoryBenefitsData = {
    totalAmount: number;
    data: Array<{
      id: string;
      createdAt: string;
      dueDate: string;
      payrun: {
        endDate: string;
        payrollCalendar: {
          name: string;
        };
      };
      type: string;
      amount: number;
      status: string;
      employee: {
        firstName: string;
        lastName: string;
      };
    }>;
  };
  type GetPaymentData = {
    id: string;
    createdAt: string;
    updatedAt: string;
    businessId: string;
    status: string;
    amount: number;
    approvalDate: string;
    processedDate: string;
    statutoryBenefits: Array<{
      dueDate: string;
      type: string;
      amount: number;
      employee: {
        firstName: string;
        lastName: string;
      };
      payrun: {
        endDate: string;
        payrollCalendar: {
          name: string;
        };
      };
    }>;
  };
  type FetchPayments = {
    id: string;
    createdAt: string;
    updatedAt: string;
    businessId: string;
    status: string;
    amount: number;
    approvalDate: string;
    processedDate: string;
  };
  type SubmitForApproval = {
    ids: string[];
  };
  type Approve = {
    id: string | undefined;
    code: string;
  };
}
module StatutoryBenefitsApi {
  const API_URL = "/statutory-benefits";

  export function PostStatutoryBenefits(
    params: {
      status: StatutoryBenefitStatusEnum[];
      empId?: string;
      startDate?: string;
      endDate?: string;
      type?: string;
    },

    success: (data: StatutoryBenefitsData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, params)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetPayments(
    success: (response: Array<FetchPayments>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/payments`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function GetPaymentsDetails(
    id: string,
    success: (response: GetPaymentData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/payment/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function DeletePaymentsDetails(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/payment/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function SubmitForApprovalApi(
    data: SubmitForApproval,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/submit-for-approval`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function RequestSentForApproval(
    id: string,
    code: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/approve/${id}/${code}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function GenerateApprovalCodeApi(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/generate-approval-code/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }

  export enum StatutoryBenefitStatusEnum {
    APPROVED = "approved",
    PENDING = "pending",
    REJECTED = "rejected",
    PROCESSED = "processed",
    PENDING_APPROVAL = "pending_approval",
    WITHDRAWN = "withdrawn",
    PENDING_PROCESSING = "pending_processing",
  }
  export enum PayrollPayItemRetirementCategoryEnum {
    EMPLOYER_EOBI = "EMPLOYER_EOBI",
    EMPLOYER_PROVIDENT_FUND = "EMPLOYER_PROVIDENT_FUND",
    SOCIAL_SECURITY = "SOCIAL_SECURITY",
    GRATUITY = "GRATUITY",
  }
}
export { StatutoryBenefitsApi };
