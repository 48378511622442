import {
  Container,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export function LoginHistory() {
  const height = window.innerHeight - 60 + "px";
  return (
    <Stack
      padding={"24px 16px"}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        // bgColor: "customColor.gray.400",
        bgColor: "customColor.white",
      }}
      h={height}
    >
      <Container
        maxW={"container.xl"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"0px 60px"}
      >
        <Table
          variant="simple"
          w="100%"
          overflowX="auto"
          bg={"#fff"}
          marginBottom={"20px"}
        >
          <Thead h={"36.8px"}>
            <Tr
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
            >
              {[
                { name: "Name", width: "45%" },
                { name: "Login date", width: "55%" },
              ].map((item, i) => {
                return (
                  <Th
                    key={i}
                    width={item.width}
                    padding="8px 26px 8px 15px"
                    borderWidth={"1px"}
                    borderColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                  >
                    {item.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {[
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 13, 2023 at 10:02 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 13, 2023 at 09:02 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 12, 2023 at 02:02 Pm",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 12, 2023 at 08:02 Pm",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 11, 2023 at 10:45 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 11, 2023 at 12:30 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 10, 2023 at 7:59 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 10, 2023 at 09:52 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 02, 2023 at 06:08 Pm",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Nov 01, 2023 at 04:55 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 30, 2023 at 5:02 Pm",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 28, 2023 at 10:42 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "OCt 27, 2023 at 07:18 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 25, 2023 at 11:42 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 20, 2023 at 07:27 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 19, 2023 at 03:43 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 17, 2023 at 07:56 Am",
              },
              {
                Name: "Syed Irsam Hashmi ",
                Login: "Oct 17, 2023 at 07:54 Am",
              },
            ].map((item, i) => {
              return (
                <Tr
                  key={i}
                  _hover={{
                    bgColor: "#f2f3f8",
                    cursor: "pointer",
                  }}
                >
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderWidth="1px"
                    borderColor={"borders.tableBorder"}
                  >
                    <Text>{item.Name}</Text>
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderWidth="1px"
                    borderColor={"borders.tableBorder"}
                  >
                    <Text>{item.Login}</Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Container>
    </Stack>
  );
}
