import { Box, HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { PayRun } from "../../../Api/PayRun";

interface AddLeavesProps {
  leavesFieldArray: any;
  LeavesLine: any;
  accountLeaves: Array<PayItemLeaveApi.LeaveById>;
  setAccountLeaves: (accountLeaves: any) => void;
  resetPaySlip: Function;
  setResetData: (resetData: any) => void;
}
export default function AddLeaves({
  accountLeaves,
  leavesFieldArray,
  LeavesLine,
  setAccountLeaves,
  resetPaySlip,
  setResetData,
}: AddLeavesProps) {
  const toast = useToast();
  const form = useFormContext<PayRun.PayrunByEmpId>();
  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (res) => {
        setAccountLeaves(res);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Box gap={0}>
      {leavesFieldArray.fields.map(
        (item: PayRun.PayrunItem & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                fontSize={"12px "}
                margin={"0px 0px 4px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"700"}
              >
                Leaves Type
              </Text>
              <Text
                color={"#3454d1"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  LeavesLine.onOpen();
                  setResetData({ ...item, index: i });
                }}
              >
                {accountLeaves.find(
                  (accountLeaves) => accountLeaves.id === item.payItemLeaveId
                )?.name ?? ""}
                {/* {form.getValues(`reimbursments.${i}.description`) && ": "}
                {form.getValues(`reimbursments.${i}.description`)} */}
              </Text>
            </Stack>

            {form.getValues(`leaves.${i}.payItemLeaveId`) ===
              item.payItemLeaveId && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Hours are required",
                      },
                      onBlur(event) {
                        resetPaySlip();
                      },
                    }}
                    control={form.control}
                    name={`leaves.${i}.hours`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          height: "26.33px",

                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Hours "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "customColor.black7",
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}

            <Stack>
              <Icon
                fontSize={"20px"}
                as={IoIosCloseCircleOutline}
                marginTop={"15px"}
                color={"grey"}
                _hover={{
                  color: "red.500",
                }}
                onClick={() => {
                  leavesFieldArray.remove(i);
                }}
              />
            </Stack>
          </Stack>
        )
      )}
    </Box>
  );
}
