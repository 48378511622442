import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeeBankAccountsModule {
  type AddEmployeeBankDetails = {
    id?: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
    iban: string;
    branchCode: string;
  };
  type AddAccounts = {
    bankAccounts: Array<AddEmployeeBankDetails>;
    deltedBankAccounts: Array<{
      accountName: string;
      accountNumber: string;
      bankName: string;
      iban: string;
      branchCode: string;
    }>;
  };
  type FetchBankAccounts = {
    id: string;
    empId: string;
    accountName: string;
    accountNumber: string;

    branchCode: string;
    bankName: string;
    iban: string;
  };
}
module EmployeeBankAccountsModule {
  const API_URL_BANK_DETAILS = "/employee-bank-details";

  export function AddEmployeeBankDetails(
    id: string,
    data: Array<AddEmployeeBankDetails>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL_BANK_DETAILS}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchBankAccounts(
    id: string,
    success: (data: Array<FetchBankAccounts>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL_BANK_DETAILS}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteBankAccounts(
    id: string,
    data: Array<AddEmployeeBankDetails>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL_BANK_DETAILS}/${id}`, { data })
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { EmployeeBankAccountsModule };
