import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../../Hooks/BusinessContext";
import { ChartOfAccounts } from "../../../../../Api/ChartOfAccounts";
import { PayItemDeductionApi } from "../../../../../Api/PayItemDeduction";

interface BenefitsModalProps {
  isOpen: boolean;
  onClose: () => void;
  earningAdded?: any;
  chartOfAccounts?: ChartOfAccounts.FetchChartOfAccounts[];
  setLastUpdate: (lastUpdate: number) => void;
  selectedDeductionId: string | undefined;
}
export default function BenefitsModal({
  isOpen,
  onClose,
  earningAdded,
  setLastUpdate,
  chartOfAccounts,
  selectedDeductionId,
}: BenefitsModalProps) {
  const form = useFormContext<PayItemDeductionApi.AddNewDeduction>();
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  const [isLoading, setIsLoading] = React.useState(false);
  let countryData = Country.getAllCountries();
  const { businessesList, currentBusinessId } = useBusinessContext();
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) =>
          country.name ===
          businessesList.find((business) => business.id === currentBusinessId)
            ?.country
      )?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, []);
  useEffect(() => {
    if (form.getValues("id") || selectedDeductionId) {
      PayItemDeductionApi.FetchDeductionById(
        form.getValues("id")!,
        (data) => {
          form.reset(data);
        },
        (error) => {
          toast({
            title: "Fetch Deduction Failed",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, []);
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
      // _light={{
      //   color: "customColor.black2",
      // }}
      // _dark={{
      //   color: "customColor.white",
      // }}
      >
        <ModalHeader
          padding={"12px 15px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"18px"}
          lineHeight={"1.75rem"}
          fontWeight={"600"}
        >
          {selectedDeductionId ? " Edit " : "Add "}
          Retirement Fund
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody
          padding={"15px"}
          borderTop={"1px solid #dfe8f6"}
          borderBottom={"1px solid #dfe8f6"}
        >
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  mb: "-2px",
                  mt: "0px",

                  borderRadius: "5px",
                  padding: "12px 15px",
                  boxShadow: "none",
                }}
                placeholder="Name of fund"
                label="Name"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />
          {form.getValues("category") === "EMPLOYEE_EOBI" && (
            <Controller
              control={form.control}
              name="minimumWage"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    h: "38px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    mb: "-2px",
                    mt: "0px",

                    borderRadius: "5px",
                    padding: "12px 15px",
                    boxShadow: "none",

                    onChange: (e) => {
                      field.onChange(Number(e.target.value));
                    },
                  }}
                  placeholder="Enter minimum wage"
                  label="Minimum Wage"
                  labelProps={{
                    paddingTop: "12px",
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
          )}

          <Controller
            control={form.control}
            name="percentage"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "number",
                  mb: "-2px",
                  mt: "0px",

                  borderRadius: "5px",
                  padding: "12px 15px",
                  boxShadow: "none",
                  min: 0,
                  max: 100,

                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                }}
                placeholder="0.00"
                label="Percentage"
                labelProps={{
                  paddingTop: "12px",
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            )}
          />

          <Text
            paddingTop={"12px"}
            fontWeight="bold"
            fontSize="12px"
            margin={"0px 12px 8px 0px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Chart of Account
          </Text>
          <Controller
            control={form.control}
            name="chartAccountId"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder="Select chart of account"
                isInvalid={fieldState.invalid}
                ErrorMessage="Select chart of account"
                withValidation
                options={chartOfAccounts?.map((acc: any) => ({
                  value: acc.id,
                  label: `${acc.code}: ${acc.name}`, // Including code before name
                }))}
                value={chartOfAccounts
                  ?.map((acc: any) => ({
                    value: acc.id,
                    label: `${acc.code}: ${acc.name}`, // Including code before name
                  }))
                  .find((option: any) => option.value === field.value)}
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />

          {form.getValues("category") === "EMPLOYEE_PROVIDENT_FUND" && (
            <Fragment>
              <Text
                fontWeight="bold"
                fontSize="12px"
                margin={"0px 12px 8px 0px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Earning Pay Items
              </Text>
              <Controller
                control={form.control}
                name="earningPayItems"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    isMulti
                    placeholder="Select Earning type"
                    // marginBottom="30px"
                    // width={"48em"}

                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Earning type"
                    withValidation
                    options={earningAdded?.map((earning: any) => ({
                      value: earning.id,
                      label: earning.name,
                    }))}
                    value={earningAdded
                      ?.map((earning: any) => ({
                        value: earning.id,
                        label: earning.name,
                      }))
                      .filter((option: any) =>
                        field?.value?.includes(option.value)
                      )}
                    onChange={(newValue: any) =>
                      field.onChange(
                        newValue?.map((option: any) => option.value)
                      )
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </Fragment>
          )}
          <Stack>
            <Stack>
              <Controller
                control={form.control}
                name="isTaxable"
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    {...field}
                    isChecked={value}
                    isInvalid={error !== undefined}
                    colorScheme="rgb(50,70,90,.35)"
                    margin={"1px 7px 0px 0px"}
                    iconColor={"#fff"}
                    // isChecked={value}
                  >
                    <Text
                      fontSize={"13px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Apply Tax
                    </Text>
                  </Checkbox>
                )}
              />
              <Controller
                control={form.control}
                name="showOnPayslip"
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    {...field}
                    isChecked={value}
                    isInvalid={error !== undefined}
                    colorScheme="rgb(50,70,90,.35)"
                    margin={"1px 7px 0px 0px"}
                    iconColor={"#fff"}
                    // isChecked={value}
                  >
                    <Text
                      fontSize={"13px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Show on Payslip
                    </Text>
                  </Checkbox>
                )}
              />
            </Stack>
          </Stack>

          {/* <Controller
              control={form.control}
              name="reduceRetirementContribution"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isChecked={value}
                  isInvalid={error !== undefined}
                  colorScheme="rgb(50,70,90,.35)"
                  margin={"1px 7px 0px 0px"}
                  iconColor={"#fff"}
                  display={"none"}
                  // isChecked={value}
                >
                  <Text fontSize={"13px"} display={"none"}>
                    {" "}
                    Reduces Retirement Guarentee Contribution
                  </Text>
                </Checkbox>
              )}
            />
            <Text
              lineHeight={"16px"}
              fontSize={"11px"}
              padding={"0px 0px 15px 15px"}
              display={"none"}
            >
              If selected,this will reduce the earnings amount that's subject to
              superannuation guarentee (SG).
            </Text>

            <Controller
              control={form.control}
              name="excludeActivityStatement"
              render={({
                field: { value, ...field },
                fieldState: { error },
              }) => (
                <Checkbox
                  {...field}
                  isChecked={value}
                  isInvalid={error !== undefined}
                  colorScheme="rgb(50,70,90,.35)"
                  margin={"1px 7px 0px 0px"}
                  iconColor={"#fff"}
                  display={"none"}
                  // isChecked={value}
                >
                  <Text fontSize={"13px"} display={"none"}>
                    {" "}
                    Excluded from W1 Activity Statement
                  </Text>
                </Checkbox>
              )}
            />
            <Text
              lineHeight={"16px"}
              fontSize={"11px"}
              padding={"0px 0px 15px 15px"}
              display={"none"}
            >
              If selected,this will reduce the reportable amount at W1 on your
              activity statements.
            </Text>
          </Stack>

          <Text
            fontSize={"11px"}
            padding={"5px 0px 0px"}
            margin={"0px 12px 8px 0px"}
            _light={{
                color: "customColor.black6",
              }}
              _dark={{
                color: "customColor.white",
              }}
            display={"none"}
          >
            Some funds assign a unique number to each employer. If you do not
            have one, leave this box blank.
          </Text> */}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            marginLeft={"8px"}
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (selectedDeductionId) {
                  PayItemDeductionApi.UpdateDeduction(
                    selectedDeductionId,
                    data,
                    (success) => {
                      setLastUpdate(Date.now());
                      toast({
                        title: "Deduction is updated",

                        status: "success",
                      });
                      setIsLoading(false);
                      onClose();
                    },
                    (error) => {
                      toast({
                        title: "Update Deduction Failed",
                        description: error,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                } else {
                  PayItemDeductionApi.NewDeductionAdded(
                    data,
                    (success) => {
                      setLastUpdate(Date.now());
                      toast({
                        title: "Deduction is added",

                        status: "success",
                      });
                      setIsLoading(false);
                      onClose();
                    },
                    (error) => {
                      toast({
                        title: "Add Deduction Failed",
                        description: error,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {selectedDeductionId ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
