import {
  Box,
  Divider,
  HStack,
  Heading,
  Icon,
  Link,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { FaPhoneAlt } from "react-icons/fa";

export function Finish() {
  const steps = [{ title: "Organisation details" }, { title: "Finish" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  return (
    <Box>
      <Stack padding={{ md: "0px 100px", lg: "0px 160px" }}>
        <Stepper size="lg" index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack
        padding={"40px 0px 0px"}
        textAlign={"center"}
        gap={"-0.5rem"}
        align={"center"}
      >
        <Heading
          fontSize={"17px"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black",
          }}
          margin={"14.11px 0px 16px"}
        >
          Connect HRIS-360 to the ATO
        </Heading>
        <Text
          margin={"15px"}
          fontSize={"15px"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black",
          }}
          w={"400px"}
        >
          Establish a connection between your HRIS-360 account and the ATO to
          ensure your payroll information is reported securely.
        </Text>
        <Stack
          border={"1px solid #e6e7e9"}
          borderRadius={"3px"}
          w={"400px"}
          paddingTop={"20px"}
          gap={0}
        >
          <Stack gap={"2px"} textAlign={"left"}>
            <HStack paddingLeft={"7px"}>
              <Icon as={FaPhoneAlt} />
              <Heading fontSize={"17px"} fontWeight={"700"}>
                Step 1:
              </Heading>
              <Text fontSize={"17px"} fontWeight={"400"}>
                Contact the ATO
              </Text>
            </HStack>
            <Stack paddingLeft={"64px"} paddingRight={"20px"} gap={0}>
              <Text margin={"20px 0px"} fontSize={"15px"}>
                You will need to be an authorised person or a delegate for the
                business before the ATO can update your details.
              </Text>
              <Divider />
              <Text
                margin={"20px 0px"}
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight={"700"}
                fontSize={".9375rem"}
                lineHeight={"1.5rem"}
              >
                This step is not required in the demo
              </Text>
              <Text
                marginBottom={"20px"}
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight={"700"}
                fontSize={".9375rem"}
                lineHeight={"1.5rem"}
              >
                Call the ATO on 1300 852 232
              </Text>
              <Text
                marginBottom={"20px"}
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight={"400"}
                fontSize={".9375rem"}
                lineHeight={"1.5rem"}
              >
                Alternatively, visit{" "}
                <Link
                  textDecoration={"1px underline"}
                  _hover={{
                    color: "primary.800",
                  }}
                >
                  ATO Access Manager
                </Link>{" "}
                to update your details. You will be required to log in using
                MyGovID.
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          border={"1px solid #e6e7e9"}
          borderRadius={"3px"}
          minW={"400px"}
          paddingTop={"20px"}
          gap={0}
        >
          <Stack gap={"0"} textAlign={"left"}>
            <HStack paddingLeft={"7px"}>
              <Icon as={FaPhoneAlt} />
              <Heading fontSize={"17px"} fontWeight={"700"}>
                Step 2:
              </Heading>
              <Text fontSize={"17px"} fontWeight={"400"}>
                Provide proof of ownership
              </Text>
            </HStack>
            <Stack paddingLeft={"64px"} paddingRight={"20px"} gap={"2px"}>
              <Stack margin={"20px 0px"} gap={"0"}>
                <Text fontSize={"13px"}>Australian Business Number</Text>
                <Heading fontSize={"15px"}>11 111 111 138</Heading>
              </Stack>
              <Stack marginBottom={"20px"} gap={"2px"}>
                <Text fontSize={"13px"}>Software provider</Text>
                <Heading fontSize={"15px"}>XERO AUSTRALIA PTY LTD</Heading>
                <Heading fontSize={"15px"}>89 124 215 247</Heading>
              </Stack>
              <Stack marginBottom={"20px"} gap={"0"}>
                <Text fontSize={"13px"}>Software ID (SSID)</Text>
                <Heading fontSize={"15px"}>Demo Only</Heading>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
