import {
  Box,
  Button,
  Heading,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function OrganisationDetail() {
  const steps = [{ title: "Organisation details" }, { title: "Finish" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const navigate = useNavigate();
  return (
    <Box>
      <Stack padding={{ md: "0px 100px", lg: "0px 160px" }}>
        <Stepper size="lg" index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack
        padding={"40px 0px 0px"}
        textAlign={"center"}
        gap={"-0.5rem"}
        align={"center"}
      >
        <Heading
          fontSize={"17px"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black",
          }}
          margin={"14.11px 0px 16px"}
        >
          Review organisation details
        </Heading>
        <Text
          margin={"15px"}
          fontSize={"15px"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black",
          }}
        >
          These details will be sent to the AtTO each pay run.
        </Text>
        <Stack
          border={"1px solid #e6e7e9"}
          borderRadius={"3px"}
          minW={"400px"}
          padding={"0px 20px"}
          marginBottom={"20px"}
          gap={0}
        >
          <Stack margin={"20px 0px"} textAlign={"left"} gap={"2px"}>
            <Text
              fontSize={"13px"}
              color={"customColor.black2"}
              _dark={{
                color: "customColor.dark.200",
              }}
            >
              Legal/Trading Name
            </Text>
            <Heading
              fontSize={"15px"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black",
              }}
            >
              Demo Company (AU)
            </Heading>
          </Stack>
          <Stack margin={"20px 0px"} textAlign={"left"} gap={"2px"}>
            <Text
              fontSize={"13px"}
              color={"customColor.black2"}
              _dark={{
                color: "customColor.dark.200",
              }}
            >
              Australian Business Number (ABN)
            </Text>
            <Heading
              fontSize={"15px"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black",
              }}
            >
              11 111 111 138
            </Heading>
          </Stack>
          <Stack margin={"20px 0px"} textAlign={"left"} gap={"2px"}>
            <Text
              fontSize={"13px"}
              color={"customColor.black2"}
              _dark={{
                color: "customColor.dark.200",
              }}
            >
              Branch Number
            </Text>
            <Heading
              fontSize={"15px"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black",
              }}
            >
              1
            </Heading>
          </Stack>
          <Stack margin={"20px 0px"} textAlign={"left"} gap={"2px"}>
            <Text
              fontSize={"13px"}
              color={"customColor.black2"}
              _dark={{
                color: "customColor.dark.200",
              }}
            >
              Postal Address
            </Text>
            <Heading
              fontSize={"15px"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black",
              }}
            >
              23 Main Street, Marineville NSW 2000
            </Heading>
          </Stack>
          <Stack margin={"20px 0px"} textAlign={"left"} gap={"2px"}>
            <Text
              fontSize={"13px"}
              color={"customColor.black2"}
              _dark={{
                color: "customColor.dark.200",
              }}
            >
              Phone Number
            </Text>
            <Heading
              fontSize={"15px"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black",
              }}
            >
              02 99998888
            </Heading>
          </Stack>
        </Stack>
        <Button
          variant={"ghost"}
          w={"400px"}
          fontSize={"15px"}
          padding={"11px 16px"}
          margin={"0px 0px 16px"}
          _hover={{
            bg: "rgba(0,10,30,.05)",
          }}
          onClick={() => {
            navigate("/app/payroll/orgdetails");
          }}
        >
          Update Organisation details
        </Button>
      </Stack>
    </Box>
  );
}
