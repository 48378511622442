import {
  AbsoluteCenter,
  Button,
  ButtonGroup,
  Container,
  Heading,
  HStack,
  Input,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSelect } from "../../../Common/ReactSelect";
import { Notifications } from "../../API/notifications";
import NotificationDeleteModal from "./deleteModal";

export default function Notification() {
  const deleteModal = useDisclosure();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [locations, setLocations] = useState<Array<any>>();

  const toast = useToast({
    position: "top",
  });

  // useEffect(() => {
  //   Employee.FetchEmployees(setEmployees, (message:string) => {
  //     toast({
  //       title: "Employees Error",
  //       description: message,
  //     });
  //   });
  //   Location.FetchLocations(setLocations, (message:string) => {
  //     toast({
  //       title: "Locations Error",
  //       description: message,
  //     });
  //   });
  // }, []);

  const { control, handleSubmit, reset } = useForm<Notifications.CreatePayload>(
    {
      defaultValues: {
        employees: [],
        locations: [],
        message: "",
        notify: "START_END_SHIFT",
        reminders: [],
        turnOn: true,
        email: true,
        push: true,
        sms: false,
        title: "",
      },
    }
  );
  const watchNotifyTime = useWatch({
    control,
    name: "notify",
  });
  const notifyTime = [
    {
      label: "Start and End Shift",
      value: "START_END_SHIFT",
    },
    {
      label: "Start Shift",
      value: "START_SHIFT",
    },
    {
      label: "End Shift",
      value: "END_SHIFT",
    },
    {
      label: "Start and End Break",
      value: "START_END_BREAK",
    },
    {
      label: "Start/End Shifts and Breaks",
      value: "START_END_SHIFT_BREAK",
    },
    {
      label: "Shift Transfer",
      value: "SHIFT_TRANSFER",
    },
    {
      label: "Transfer Response",
      value: "TRANSFER_RESPONSE",
    },
  ];

  const remider = [
    {
      label: "15 minutes before",
      value: 15,
    },
    {
      label: "30 minutes before",
      value: 30,
    },
    {
      label: "45 minutes before",
      value: 45,
    },
    {
      label: "1 hour before",
      value: 60,
    },
    {
      label: "2 hour before",
      value: 120,
    },
    {
      label: "3 hour before",
      value: 180,
    },
    {
      label: "4 hour before",
      value: 240,
    },
    {
      label: "5 hour before",
      value: 300,
    },
  ];

  useEffect(() => {
    if (id) {
      Notifications.FetchById(
        id,
        (res) => {
          reset(res);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
          navigate(-1);
        }
      );
    }
  }, []);

  if (id && isLoading) {
    return (
      <AbsoluteCenter>
        <Spinner size="lg" color="secondary.500" />
      </AbsoluteCenter>
    );
  }

  return (
    <Stack h="calc(100vh - 70px)" overflowY="auto">
      <Fragment>
        <NotificationDeleteModal
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          onDelete={() => {
            id &&
              Notifications.Delete(
                id,
                () => {
                  deleteModal.onClose();
                  toast({
                    title: "Success",
                    description: "Notification Deleted",
                    status: "success",
                  });
                  navigate(-1);
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: error,
                    status: "error",
                  });
                }
              );
          }}
        />

        <Container maxW="container.md" backgroundColor="white">
          <Heading
            fontSize={"25px"}
            textAlign={"left"}
            padding={"10px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {id ? "Update" : "Create"} Notification
          </Heading>
          {/* <Stack>
            <Text
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              When to Notify?
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Notify is required",
                },
              }}
              control={control}
              name="notify"
              render={({ field, fieldState: { invalid } }) => (
                <ReactSelect
                  withValidation
                  ErrorMessage="Please Select When to Notify"
                  isInvalid={invalid}
                  options={notifyTime}
                  // label="When to Nofity?"
                  // labelProps={{
                  //   fontSize: "15px",
                  //   fontWeight: 500,
                  //   color: "#373857",
                  // }}
                  {...field}
                  value={
                    notifyTime?.find(
                      (option) => option.value === field.value
                    ) as any
                  }
                  onChange={(val: any) => {
                    field.onChange(val.value);
                  }}
                />
              )}
            />
          </Stack> */}
          <Stack mt="20px" p="10px" spacing="12px">
            <Controller
              control={control}
              name="title"
              render={() => (
                <Stack>
                  <Text
                    fontSize=".845rem"
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Title
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Message is required",
                      },
                    }}
                    control={control}
                    name="title"
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        placeholder="Name of the Notification"
                        border="1px solid"
                        borderColor="borders.tableBorder"
                        borderRadius={"3px"}
                        fontSize=".845rem"
                        fontWeight={400}
                        _light={{
                          color: "customColor.black7",
                        }}
                        padding="12px 15px"
                        _focus={{
                          boxShadow: "none",
                          borderColor: "#3454d1",
                        }}
                        _focusVisible={{
                          boxShadow: "none",
                          border: "1px solid",
                          borderColor: "#3454d1",
                        }}
                        isInvalid={invalid}
                        {...field}
                      />
                    )}
                  />
                </Stack>
              )}
            />
            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                When to Notify?
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Notify is required",
                  },
                }}
                control={control}
                name="notify"
                render={({ field, fieldState: { invalid } }) => (
                  <ReactSelect
                    withValidation
                    ErrorMessage="Please Select When to Notify"
                    isInvalid={invalid}
                    options={notifyTime}
                    // label="When to Nofity?"
                    // labelProps={{
                    //   fontSize: "15px",
                    //   fontWeight: 500,
                    //   color: "#373857",
                    // }}
                    {...field}
                    value={
                      notifyTime?.find(
                        (option) => option.value === field.value
                      ) as any
                    }
                    onChange={(val: any) => {
                      field.onChange(val.value);
                    }}
                  />
                )}
              />
            </Stack>
            {/* <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Who to Notify?
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Notify is required",
                  },
                }}
                control={control}
                name="notify"
                render={({ field, fieldState: { invalid } }) => (
                  <ReactSelect
                    withValidation
                    ErrorMessage="Please Select When to Notify"
                    isInvalid={invalid}
                    options={notifyTime}
                    // label="When to Nofity?"
                    // labelProps={{
                    //   fontSize: "15px",
                    //   fontWeight: 500,
                    //   color: "#373857",
                    // }}
                    {...field}
                    value={
                      notifyTime?.find(
                        (option) => option.value === field.value
                      ) as any
                    }
                    onChange={(val: any) => {
                      field.onChange(val.value);
                    }}
                  />
                )}
              />
            </Stack> */}

            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Employee
              </Text>

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Employee is required ",
                  },
                }}
                control={control}
                name="employees"
                render={({ field, fieldState }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Employee"
                      placeholder="Select Employee"
                      options={employees}
                      getOptionLabel={(option: any) => option.preferredTitle}
                      getOptionValue={(option: any) => option.id}
                      value={
                        field.value
                          ? employees?.filter((emp) =>
                              field.value.includes(emp.id)
                            )
                          : undefined
                      }
                      onChange={(val: any) => {
                        const options = val as unknown as Array<any>;
                        field.onChange(options.map((emp) => emp.id));
                      }}
                      // label="Employee"
                    />
                  );
                }}
              />
            </Stack>
            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Location
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                control={control}
                name="locations"
                render={({ field, fieldState }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Location"
                      placeholder="Select Location"
                      options={locations}
                      value={
                        field.value
                          ? locations?.filter((location) =>
                              field.value.includes(location.id)
                            )
                          : undefined
                      }
                      onChange={(val: any) => {
                        const options = val as unknown as Array<any>;
                        field.onChange(options.map((location) => location.id));
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      // label="Location"
                    />
                  );
                }}
              />
            </Stack>
            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Reminder
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Reminder is required",
                  },
                }}
                control={control}
                name="reminders"
                render={({ field, fieldState: { invalid } }) => (
                  <ReactSelect
                    isMulti
                    withValidation
                    isInvalid={invalid}
                    ErrorMessage="Please Select Employee"
                    placeholder="Select Reminder Time"
                    // label="Reminder"
                    options={remider}
                    {...field}
                    value={
                      remider?.filter((option) =>
                        field.value.includes(option.value)
                      ) as any
                    }
                    onChange={(val: any) => {
                      field.onChange(val.map((option: any) => option.value));
                    }}
                  />
                )}
              />
            </Stack>

            <Stack>
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Message
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Message is required",
                  },
                }}
                control={control}
                name="message"
                render={({ field, fieldState: { invalid } }) => (
                  <Textarea
                    placeholder="Write a message for employee"
                    fontSize=".845rem"
                    fontWeight={400}
                    border="1px solid #e5e7eb"
                    _hover={{
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _focus={{
                      borderWidth: "1px",

                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _focusVisible={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding="12px 15px"
                    isInvalid={invalid}
                    {...field}
                  />
                )}
              />
            </Stack>
            <VStack w="full">
              <HStack justifyContent="space-between" w="full">
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Push Notification
                </Text>
                <Controller
                  control={control}
                  name="push"
                  render={({ field }) => (
                    <Switch
                      size="md"
                      {...field}
                      value={undefined}
                      isChecked={field.value}
                    />
                  )}
                />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  SMS
                </Text>
                <Controller
                  control={control}
                  name="sms"
                  render={({ field }) => (
                    <Switch
                      size="md"
                      {...field}
                      value={undefined}
                      isChecked={field.value}
                    />
                  )}
                />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Email
                </Text>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Switch
                      size="md"
                      {...field}
                      value={undefined}
                      isChecked={field.value}
                    />
                  )}
                />
              </HStack>
            </VStack>
            <HStack justifyContent="space-between">
              <Text
                fontSize=".845rem"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Turn on this notification
              </Text>
              <Controller
                control={control}
                name="turnOn"
                render={({ field }) => (
                  <Switch
                    size="md"
                    {...field}
                    value={undefined}
                    isChecked={field.value}
                  />
                )}
              />
            </HStack>
            <HStack
              justifyContent="space-between"
              paddingTop={"10px"}
              paddingBottom={"10px"}
            >
              <Button
                variant="solid"
                h={"40px"}
                fontSize={"13px"}
                padding={"10px 15px"}
                borderRadius={"3px"}
                border={"1px solid #FDEDED"}
                bg={"#FDEDED"}
                color={"#EA4D4D"}
                minW={"75px"}
                _hover={{
                  border: "1px solid #EA4D4D",
                  bg: "#EA4D4D",
                  color: "#fff",
                }}
                display={id ? "block" : "none"}
                onClick={() => {
                  deleteModal.onOpen();
                }}
              >
                Delete
              </Button>
              <Spacer />
              <ButtonGroup variant="outline" spacing="3">
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  h={"40px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  type="submit"
                  onClick={handleSubmit(
                    (data) => {
                      if (id) {
                        Notifications.Update(
                          id,
                          data,
                          (res) => {
                            navigate(-1);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                          }
                        );
                      } else {
                        Notifications.Create(
                          data,
                          (res) => {
                            navigate(-1);
                          },
                          (error) => {
                            toast({
                              title: "Error",
                              description: error,
                              status: "error",
                            });
                          }
                        );
                      }
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        description: "Please fill all required information",
                        status: "error",
                      });
                    }
                  )}
                >
                  {id ? "Update" : "Save"}
                </Button>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"#ea4d4d"}
                  padding={"12px 16px"}
                  marginRight={"5px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </HStack>
          </Stack>
        </Container>
      </Fragment>
    </Stack>
  );
}
