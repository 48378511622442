import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { InviteUser } from "../../../../../../api/InviteUser";

interface EditProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Edit({ isOpen, onClose }: EditProps) {
  const form = useFormContext<InviteUser.UserInviteDetail>();
  const toast = useToast();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w={"400px"}>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            {`Edit details for ${form.getValues(
              "user.firstName"
            )} ${form.getValues("user.lastName")}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            padding={"15px 20px"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "#FBFBFB",
            }}
            border={"1px solid #E5E5E5"}
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "First Name is required",
                },
              }}
              control={form.control}
              name="user.firstName"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    height: "38px",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    variant: "outline",
                    type: "email",

                    borderRightRadius: "none",
                    borderTopLeftRadius: "3px",
                  }}
                  placeholder=""
                  label="First Name"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              control={form.control}
              name="user.lastName"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    height: "38px",

                    variant: "outline",
                    type: "email",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    borderTopLeftRadius: "3px",
                  }}
                  placeholder=""
                  label="Last Name"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
          </ModalBody>

          <ModalFooter padding={"20px"}>
            <ButtonGroup size="sm" gap={0}>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  if (form.getValues("id")) {
                    InviteUser.UpdateUserById(
                      form.getValues("id")!,
                      form.getValues(),
                      (res) => {
                        toast({
                          title: "Success",
                          description: "User Updated",
                          status: "success",
                        });
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          description: err,
                          status: "error",
                        });
                        onClose();
                      }
                    );
                  }
                }}
              >
                Update
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
