import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

interface ArchiveProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Archive({ isOpen, onClose }: ArchiveProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Archive Accounts
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <Text
            padding={"0px 0px 10px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Are you sure you wants to archive 0 contacts
            {/* You have selected{" "}
            {numberOfCheckedRows > 0 ? numberOfCheckedRows : "0"}
            {numberOfCheckedRows === 0 ? " account" : " accounts"} to be
            Archived. */}
          </Text>
          <Text
            padding={"0px 0px 10px"}
            fontSize={"0.8125rem"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            The transaction history with this contact will not be deleted
          </Text>
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            h={"40px"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            margin={"0px 0px 0px 10px"}
            w={"104.6px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            borderRadius={"3px"}
            h={"40px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#2c48b5",
            }}
            margin={"0px 0px 0px 10px"}
            w={"104.6px"}
            isLoading={isLoading}
          >
            Archive
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
