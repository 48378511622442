import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Collapse,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useController,
  useForm,
} from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoTime } from "react-icons/io5";
import { MdAccountBalance, MdAdd, MdPeople, MdRemove } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { InviteUser } from "../../../../../api/InviteUser";
import { CustomInput } from "../../../../../Common/CustomInput";
import AccountingPermissions from "./AccountingPermissions";
import CustomRadio from "./CustomRadio";
import EmployeesPermission from "./EmployeesPermsion";
import PayrollPermissions from "./PayrollPermissions";
import ReportPermissions from "./ReportPermissions";
import TimeAttendancePermissions from "./Time&AttendancePermissions";

export function Invite() {
  const options = [
    {
      name: "Admin",
      value: "ADMIN",
    },
    {
      name: "Manager",
      value: "MANAGER",
    },
    {
      name: "Supervisor",
      value: "SUPERVISOR",
    },
    {
      name: "Client",
      value: "CLIENT",
    },
  ];

  const inviteForm = useForm<InviteUser.UserInviteDetail>({
    defaultValues: {
      email: "",
      user: {
        firstName: "",
        lastName: "",
        email: "",
      },
      allowedFeatures: {
        reports: {
          employeeCheckIn: false,
          employeeDetails: false,
        },
        accounting: {
          chartOfAccounts: false,
          taxRates: false,
        },
        payroll: {
          timesheets: false,
          payRuns: false,
          payrollSettings: false,
        },
        timeAndAttendance: {
          locations: false,
          notifications: false,
          schedules: false,
        },
        employee: {
          financialDetails: false,
          personalDetails: false,
        },
      },
      accessLevel: "ADMIN",
    },
  });

  // const report = useDisclosure();
  // const accounting = useDisclosure();
  // const payroll = useDisclosure();
  // const timesheet = useDisclosure();
  // const employee = useDisclosure();
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  const [show2, setShow2] = useState(false);
  const handleToggle2 = () => setShow2(!show2);

  const [show3, setShow3] = useState(false);
  const handleToggle3 = () => setShow3(!show3);

  const [show4, setShow4] = useState(false);
  const handleToggle4 = () => setShow4(!show4);

  const [show5, setShow5] = useState(false);
  const handleToggle5 = () => setShow5(!show5);
  const AccessLevelController = useController({
    name: "accessLevel",
    defaultValue: "ADMIN",
    control: inviteForm.control,
  });
  const { getRootProps, getRadioProps } = useRadioGroup({
    ...AccessLevelController.field,
  });

  const group = getRootProps();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FormProvider {...inviteForm}>
      <Stack h="calc(100vh - 70px)" overflowY="auto">
        <Stack gap={0} marginBottom={"70px"}>
          <HStack
            justifyContent="space-between"
            h={"auto"}
            borderBottom={"1px solid #d6dade"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <Stack flex={1} direction={{ xs: "column", md: "row" }}>
              <Stack gap={0} padding={"16px 0px"} margin={"0px 4px 0px 32px"}>
                <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      href="/app/payroll/organisationSettings"
                      fontSize={"13px"}
                      color={"primary.950"}
                      padding={"0px 8px 0px 0px"}
                    >
                      Organisation Settings
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      href="/app/payroll/user"
                      fontSize={"13px"}
                      color={"primary.950"}
                      padding={"0px 8px"}
                    >
                      User
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>

                <Text
                  fontWeight={"bold"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"17px"}
                  padding={"0px 12px 0px 0px"}
                >
                  Invite a user
                </Text>
              </Stack>
            </Stack>
          </HStack>
          <Stack
            padding={"24px 16px"}
            _dark={{
              bgColor: "customColor.dark.100",
            }}
            _light={{
              bgColor: "customColor.gray.400",
            }}
            direction={"row"}
          >
            <Container
              maxW={"container.xl"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                gap={0}
                borderBottom={"1px solid customColor.black "}
                paddingBottom={"24px"}
              >
                <Heading
                  fontSize={"17px"}
                  margin={"0px 0px 14.11px"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Personal details
                </Heading>
                <Stack
                  gap={0}
                  padding={"24px"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  borderWidth="1px"
                  borderStyle={"solid"}
                  borderColor="#f6f6f6"
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"5px"}
                >
                  <HStack gap={0} alignItems={"baseline"} marginBottom={"5px"}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "First Name is required",
                        },
                      }}
                      control={inviteForm.control}
                      name="user.firstName"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            height: "38px",

                            variant: "outline",
                            type: "text",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            borderRightRadius: "0px",
                            borderTopLeftRadius: "3px",
                          }}
                          placeholder=""
                          label="First Name"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />{" "}
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Last Name is required",
                        },
                      }}
                      control={inviteForm.control}
                      name="user.lastName"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            height: "38px",

                            variant: "outline",
                            type: "text",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            borderLeftRadius: "0px",
                            borderTopRightRadius: "3px",
                          }}
                          placeholder=""
                          label="Last Name"
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />{" "}
                  </HStack>

                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    }}
                    control={inviteForm.control}
                    name="email"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          height: "38px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "email",

                          borderTopRadius: "3px",
                        }}
                        placeholder=""
                        label="Email"
                        labelProps={{
                          fontSize: ".845rem",
                          fontWeight: "600",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack gap={0} paddingTop={"24px"}>
                <Heading
                  fontSize={"17px"}
                  margin={"0px 0px 14.11px"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Permissions
                </Heading>

                <Stack
                  gap={0}
                  padding={"24px"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  borderWidth="1px"
                  borderStyle={"solid"}
                  borderColor="#f6f6f6"
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"5px"}
                >
                  <Text
                    fontSize={"15px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    flex={1}
                    textAlign={"left"}
                  >
                    Module Access
                  </Text>

                  <Text
                    padding={"8px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"15px"}
                    fontWeight={"400"}
                  >
                    It allows admin,manager,supervisor and client full access to
                    the module.
                  </Text>

                  <Heading
                    padding={"8px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"15px"}
                    fontWeight={"700"}
                  >
                    How much access do you need?
                  </Heading>
                  <HStack {...group}>
                    {options.map((value) => {
                      const radio = getRadioProps({
                        value: value.value,
                      });
                      return (
                        <CustomRadio
                          key={value.value}
                          {...radio}
                          padding="7.52px 12px"
                          borderRadius={"3px"}
                        >
                          {value.name}
                        </CustomRadio>
                      );
                    })}
                  </HStack>

                  <Stack
                    bg={"rgb(242, 243, 244)"}
                    margin={"16px 0px 20px"}
                    padding={"4px"}
                    direction={"row"}
                    alignItems={"center"}
                    gap={0}
                  >
                    <Stack padding={"0px 0px 0px 8px"}>
                      {" "}
                      <Icon
                        as={AiFillInfoCircle}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.defaultBlack",
                        }}
                      />
                    </Stack>
                    <Stack padding={"8px 12px"}>
                      <Text
                        lineHeight={"1.5rem"}
                        fontSize={"13px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.defaultBlack",
                        }}
                      >
                        This role suits business owners and admin staff that
                        manage the day-to-day business and accounting.
                        <Link
                          textDecoration={"underline"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.defaultBlack",
                          }}
                          _hover={{
                            color: "#0078C8",
                          }}
                        >
                          Learn more about user roles
                          <Icon as={BsBoxArrowUpRight} />
                        </Link>
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack spacing={6}>
                    <Stack
                      flex={1}
                      gap={0}
                      boxShadow={
                        "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                      }
                      _hover={{
                        boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                      }}
                      borderWidth="1px"
                      borderStyle={"solid"}
                      borderColor="#f6f6f6"
                      padding={"20px"}
                    >
                      <Stack
                        onClick={handleToggle}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={TbReportSearch}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"20px"}
                            width={"20px"}
                          />
                          <Text
                            fontSize={"14px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Report
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>

                      <Collapse in={show} animateOpacity>
                        <ReportPermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                    <Stack
                      flex={1}
                      gap={0}
                      boxShadow={
                        "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                      }
                      _hover={{
                        boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                      }}
                      borderWidth="1px"
                      borderStyle={"solid"}
                      borderColor="#f6f6f6"
                      padding={"20px"}
                    >
                      <Stack
                        onClick={handleToggle2}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={MdAccountBalance}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"20px"}
                            width={"20px"}
                          />
                          <Text
                            fontSize={"14px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Accounting
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show2 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show2} animateOpacity>
                        <AccountingPermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>

                    <Stack
                      flex={1}
                      gap={0}
                      boxShadow={
                        "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                      }
                      _hover={{
                        boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                      }}
                      borderWidth="1px"
                      borderStyle={"solid"}
                      borderColor="#f6f6f6"
                      padding={"20px"}
                    >
                      <Stack
                        onClick={handleToggle3}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={GiTakeMyMoney}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"20px"}
                            width={"20px"}
                          />
                          <Text
                            fontSize={"14px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            PayRoll
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show3 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show3} animateOpacity>
                        <PayrollPermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                    <Stack
                      flex={1}
                      gap={0}
                      boxShadow={
                        "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                      }
                      _hover={{
                        boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                      }}
                      borderWidth="1px"
                      borderStyle={"solid"}
                      borderColor="#f6f6f6"
                      padding={"20px"}
                    >
                      <Stack
                        onClick={handleToggle4}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={MdPeople}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"20px"}
                            width={"20px"}
                          />
                          <Text
                            fontSize={"14px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Employees
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show4 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show4} animateOpacity>
                        <EmployeesPermission isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                    <Stack
                      flex={1}
                      gap={0}
                      boxShadow={
                        "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
                      }
                      _hover={{
                        boxShadow: "0 2px 4px rgba(15,34,58,.12)",
                      }}
                      borderWidth="1px"
                      borderStyle={"solid"}
                      borderColor="#f6f6f6"
                      padding={"20px"}
                    >
                      <Stack
                        onClick={handleToggle5}
                        direction={"row"}
                        gap={0}
                        alignItems={"center"}
                        cursor="pointer"
                        justifyContent={"space-between"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            as={IoTime}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            height={"20px"}
                            width={"20px"}
                          />
                          <Text
                            fontSize={"14px"}
                            padding={"8px 0px 8px 3px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            fontWeight={"bold"}
                          >
                            Timesheet
                          </Text>
                        </HStack>
                        <IconButton
                          fontSize={"20px"}
                          aria-label={"sum-button"}
                          icon={show5 ? <MdRemove /> : <MdAdd />}
                          bg={"transparent"}
                          color={"black"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </Stack>
                      <Collapse in={show5} animateOpacity>
                        <TimeAttendancePermissions isLoading={isLoading} />
                      </Collapse>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
            <Box
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              position="fixed"
              left={0}
              bottom="0"
              w="full"
              h="72px"
              borderTop={"1px solid #d6dade"}
              zIndex={"overlay"}
              boxShadow={
                "1 -1px 0 0 rgba(0,10,30,.2), 0 -3px 0 0 rgba(0,10,30,.05)"
              }
            >
              <Stack
                justifyContent="center"
                alignItems="flex-end"
                textAlign={"right"}
              >
                <ButtonGroup size="sm" mt="10px" margin={"16px"}>
                  <Button
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    color={"#ea4d4d"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    onClick={() => navigate("/app/payroll/user")}
                  >
                    Cancel
                  </Button>
                  <Button
                    backgroundColor={"transparent"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    h={"40px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    isLoading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      InviteUser.InviteUser(
                        {
                          ...inviteForm.getValues(),
                          user: {
                            firstName: inviteForm.getValues("user.firstName"),
                            lastName: inviteForm.getValues("user.lastName"),
                            email: inviteForm.getValues("email"),
                          },
                        },

                        (res) => {
                          toast({
                            title: "User Invited",
                            description: "User has been invited",
                            status: "success",
                          });
                          setIsLoading(false);
                          navigate("/app/payroll/user");
                        },
                        (err) => {
                          toast({
                            title: "Error",
                            description: err,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }}
                  >
                    Send invite
                  </Button>
                </ButtonGroup>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
