import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { LuFileText } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { Billings } from "../../Api/Billings";
import { CreateBillTable } from "./CreateBillTable";

export function CreateBill() {
  const toast = useToast();

  const form = useForm<Billings.AddNewBill>({
    defaultValues: {
      id: "",
      from: "",
      date: "",

      dueDate: "",

      reference: "",
      total: "",
      billing_Component: [
        {
          item: "",
          desc: "",
          qty: 0,
          price: 0,
          account: "",
          taxRate: "",
          region: "",
          project: "",
          amount: 0,
        },
      ],
    },
  });

  const amountOptions = ["Tax exclusive", "Tax inclusive", "No Tax"].map(
    (amount) => ({
      label: amount,
      value: amount,
    })
  );
  const billingComponent = useFieldArray({
    control: form.control,
    name: "billing_Component",
  });
  const watchedBillingComponent = useWatch({
    control: form.control,
    name: "billing_Component",
  });

  const totalAmount = watchedBillingComponent
    .reduce((prev: any, curr: any) => {
      return prev + Number(curr.amount);
    }, 0)
    .toFixed(2);

  // Find 10% of the total amount
  const tenPercent = (Number(totalAmount) * 0.1).toFixed(2);
  const totalSum = (Number(totalAmount) + Number(tenPercent)).toFixed(2);
  const currencyOptions = [
    "AED: United Arab Emirates Dirham",
    "AFN: Afghan Afghani",
    "ALL: Albanian Lek",
    "AMD: Armenian Dram",
    "ANG: Netherlands Antillean Guilder",
    "AOA: Angolan Kwanza",
    "ARS: Argentine Peso",
    "AUD: Australian Dollar",
    "AWG: Aruban Florin",
    "AZN: Azerbaijani Manat",
    "BAM: Bosnia-Herzegovina Convertible Mark",
    "BBD: Barbadian Dollar",
    "BDT: Bangladeshi Taka",
    "BGN: Bulgarian Lev",
    "BHD: Bahraini Dinar",
    "BIF: Burundian Franc",
    "BMD: Bermudan Dollar",
    "BND: Brunei Dollar",
    "BOB: Bolivian Boliviano",
    "BRL: Brazilian Real",
    "BSD: Bahamian Dollar",
    "BTC: Bitcoin",
    "BTN: Bhutanese Ngultrum",
    "BWP: Botswanan Pula",
    "BYN: Belarusian Ruble",
    "BZD: Belize Dollar",
    "CAD: Canadian Dollar",
    "CDF: Congolese Franc",
    "CHF: Swiss Franc",
    "CLF: Chilean Unit of Account (UF)",
    "CLP: Chilean Peso",
    "CNH: Chinese Yuan (Offshore)",
    "CNY: Chinese Yuan",
    "COP: Colombian Peso",
    "CRC: Costa Rican Colón",
    "CUC: Cuban Convertible Peso",
    "CUP: Cuban Peso",
    "CVE: Cape Verdean Escudo",
    "CZK: Czech Republic Koruna",
    "DJF: Djiboutian Franc",
    "DKK: Danish Krone",
    "DOP: Dominican Peso",
    "DZD: Algerian Dinar",
    "EGP: Egyptian Pound",
    "ERN: Eritrean Nakfa",
    "ETB: Ethiopian Birr",
    "EUR: Euro",
    "FJD: Fijian Dollar",
    "FKP: Falkland Islands Pound",
    "GBP: British Pound Sterling",
    "GEL: Georgian Lari",
    "GGP: Guernsey Pound",
    "GHS: Ghanaian Cedi",
    "GIP: Gibraltar Pound",
    "GMD: Gambian Dalasi",
    "GNF: Guinean Franc",
    "GTQ: Guatemalan Quetzal",
    "GYD: Guyanaese Dollar",
    "HKD: Hong Kong Dollar",
    "HNL: Honduran Lempira",
    "HRK: Croatian Kuna",
    "HTG: Haitian Gourde",
    "HUF: Hungarian Forint",
    "IDR: Indonesian Rupiah",
    "ILS: Israeli New Sheqel",
    "IMP: Manx pound",
    "INR: Indian Rupee",
    "IQD: Iraqi Dinar",
    "IRR: Iranian Rial",
    "ISK: Icelandic Króna",
    "JEP: Jersey Pound",
    "JMD:Jamaican Dollar",
    "JOD: Jordanian Dinar",
    "JPY: Japanese Yen",
    "KES: Kenyan Shilling",
    "KGS:Kyrgystani Som",
    "KHR: Cambodian Riel",
    "KMF: Comorian Franc",
    "KPW: North Korean Won",
    "KRW: South Korean Won",
    "KWD: Kuwaiti Dinar",
    "KYD: Cayman Islands Dollar",
    "KZT: Kazakhstani Tenge",
    "LAK: Laotian Kip",
    "LBP: Lebanese Pound",
    "LKR: Sri Lankan Rupee",
    "LRD: Liberian Dollar",
    "LSL: Lesotho Loti",
    "LYD: Libyan Dinar",
    "MAD: Moroccan Dirham",
    "MDL: Moldovan Leu",
    "MGA: Malagasy Ariary",
    "MKD: Macedonian Denar",
    "MMK: Myanma Kyat",
    "MNT: Mongolian Tugrik",
    "MOP: Macanese Pataca",
    "MRU: Mauritanian Ouguiya",
    "MUR: Mauritian Rupee",
    "MVR: Maldivian Rufiyaa",
    "MWK: Malawian Kwacha",
    "MXN: Mexican Peso",
    "MYR: Malaysian Ringgit",
    "MZN: Mozambican Metical",
    "NAD: Namibian Dollar",
    "NGN: Nigerian Naira",
    "NIO: Nicaraguan Córdoba",
    "NOK: Norwegian Krone",
    "NPR: Nepalese Rupee",
    "NZD: New Zealand Dollar",
    "OMR: Omani Rial",
    "PAB: Panamanian Balboa",
    "PEN: Peruvian Nuevo Sol",
    "PGK: Papua New Guinean Kina",
    "PHP: Philippine Peso",
    "PKR: Pakistani Rupee",
    "PLN: Polish Zloty",
    "PYG: Paraguayan Guarani",
    "QAR: Qatari Rial",
    "RON: Romanian Leu",
    "RSD: Serbian Dinar",
    "RUB: Russian Ruble",
    "RWF: Rwandan Franc",
    "SAR: Saudi Riyal",
    "SBD: Solomon Islands Dollar",
    "SCR: Seychellois Rupee",
    "SDG: Sudanese Pound",
    "SEK: Swedish Krona",
    "SGD: Singapore Dollar",
    "SHP: Saint Helena Pound",
    "SLL: Sierra Leonean Leone",
    "SOS: Somali Shilling",
    "SRD: Surinamese Dollar",
    "SSP: South Sudanese Pound",
    "STD: São Tomé and Príncipe Dobra (pre-2018)",
    "STN: São Tomé and Príncipe Dobra",
    "SVC: Salvadoran Colón",
    "SYP: Syrian Pound",
    "SZL: Swazi Lilangeni",
    "THB: Thai Baht",
    "TJS: Tajikistani Somoni",
    "TMT: Turkmenistani Manat",
    "TND: Tunisian Dinar",
    "TOP: Tongan Pa'anga",
    "TRY: Turkish Lira",
    "TTD: Trinidad and Tobago Dollar",
    "TWD: New Taiwan Dollar",
    "TZS: Tanzanian Shilling",
    "UAH: Ukrainian Hryvnia",
    "UGX: Ugandan Shilling",
    "USD: United States Dollar",
    "UYU: Uruguayan Peso",
    "UZS: Uzbekistan Som",
    "VEF:Venezuelan Bolívar Fuerte (Old)",
    "VES:Venezuelan Bolívar Soberano",
    "VND:Vietnamese Dong",
    "VUV:Vanuatu Vatu",
    "WST: Samoan Tala",
    "XAF: CFA Franc BEAC",
    "XAG: Silver Ounce",
    "XAU: Gold Ounce",
    "XCD: East Caribbean Dollar",
    "XDR: Special Drawing Rights",
    "XOF: CFA Franc BCEAO",
    "XPD: Palladium Ounce",
    "XPF: CFP Franc",
    "XPT: Platinum Ounce",
    "YER: Yemeni Rial",
    "ZAR: South African Rand",
    "ZMW: Zambian Kwacha",
    "ZWL: Zimbabwean Dollar",
  ].map((currency) => ({ label: currency, value: currency }));
  const navigate = useNavigate();
  return (
    <FormProvider {...form}>
      <Stack
        _light={{
          // bgColor: "#f8f8f8",
          bgColor: "#fafafa",
        }}
        padding={0}
        gap={0}
        // boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #ddd"
      >
        <Stack
          direction={"row"}
          margin={"10px 10px 0 10px"}
          paddingBottom={"5px"}
          borderBottom={"1px solid #e1e1e1"}
          justifyContent={"space-between"}
        >
          <SimpleGrid columns={{ xs: 2, md: 3, lg: 4 }} spacing={2} flex={2}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "From is required",
                },
              }}
              control={form.control}
              name="from"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    bg: "#fff",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    variant: "outline",
                    type: "text",
                    marginBottom: "20px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="From"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Date is required",
                },
              }}
              control={form.control}
              name="date"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    bg: "#fff",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    variant: "outline",
                    type: "date",
                    marginBottom: "20px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Date"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Due Date is required",
                },
              }}
              control={form.control}
              name="dueDate"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    bg: "#fff",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    variant: "outline",
                    type: "date",
                    marginBottom: "20px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Due Date"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Reference is required",
                },
              }}
              control={form.control}
              name="reference"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    bg: "#fff",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    variant: "outline",
                    type: "text",
                    marginBottom: "20px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Reference"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
          </SimpleGrid>
          <Button
            marginTop={"22px"}
            borderRadius={"3px"}
            borderRightRadius={0}
            backgroundColor={"#fff"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"38px"}
            variant={"solid"}
            textColor={"#3454d1"}
            padding={"12px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
              color: "#3454D1",
            }}
          >
            <LuFileText fontSize={"16px"} />
          </Button>
          <Stack flex={1}></Stack>
          <Stack
            direction={"row"}
            flex={1}
            alignItems={"center"}
            padding={0}
            justify={"flex-end"}
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Reference is required",
                },
              }}
              control={form.control}
              name="reference"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    bg: "#fff",
                    padding: "12px 15px",
                    _light: {
                      color: "customColor.black7",
                    },
                    variant: "outline",
                    type: "text",
                    marginBottom: "20px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                  label="Total"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          margin={"10px 0 0 10px"}
          paddingBottom={"5px"}
          justifyContent={"space-between"}
        >
          <Stack w={"150px"}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Currency is required",
                },
              }}
              // control={form.control}
              control={form.control}
              name="currency"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  // marginBottom="30px"
                  // width={"48em"}
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Currency "
                  withValidation
                  options={currencyOptions}
                  value={currencyOptions.find(
                    (currencyOptions) => currencyOptions.value === field.value
                  )}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} paddingRight={"10px"}>
            <Text
              fontSize=".845rem"
              fontWeight={"600"}
              whiteSpace={"nowrap"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Account are
            </Text>
            <Stack w={"150px"}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Amounts is required",
                  },
                }}
                // control={form.control}
                control={form.control}
                name="amount"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Amount "
                    withValidation
                    options={amountOptions}
                    value={amountOptions.find(
                      (amountOptions) => amountOptions.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        <CreateBillTable form={form} billingComponent={billingComponent} />
        <Stack
          direction={"row"}
          marginTop={"10px"}
          marginLeft={"20px"}
          marginRight={"20px"}
          paddingBottom={"35px"}
          alignItems={"baseline"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} flex={3}>
            <Stack direction={"row"} gap={0}>
              <Box width={"100%"}>
                <Button
                  leftIcon={<BsArrowReturnRight fontSize={"20px"} />}
                  fontSize={"12px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderRightRadius={0}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    billingComponent.append({
                      item: "",
                      desc: "",
                      qty: 0,
                      price: 0,
                      account: "",
                      taxRate: "",
                      region: "",
                      project: "",
                      amount: 0,
                    });
                  }}
                >
                  Add a New line
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  h={"32px"}
                  _light={{
                    bgColor: "#3454d1",
                    color: "customColor.white",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  borderLeft={"1px solid #ffffff"}
                  borderTop={"1px solid #3454d1"}
                  borderRight={"1px solid #3454d1"}
                  borderBottom={"1px solid #3454d1"}
                  borderRightRadius={"4px"}
                  icon={<IoIosArrowDown />}
                />

                <MenuList overflowY="auto" zIndex={"overlay"}>
                  <MenuItem
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                      onClick={() => {
                        for (let i = 0; i < 5; i++) {
                          billingComponent.append({
                            item: "",
                            desc: "",
                            qty: 0,
                            price: 0,
                            account: "",
                            taxRate: "",
                            region: "",
                            project: "",
                            amount: 0,
                          });
                        }
                      }}
                    >
                      Add 5
                    </Text>
                  </MenuItem>
                  <MenuItem
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                      onClick={() => {
                        for (let i = 0; i < 10; i++) {
                          billingComponent.append({
                            item: "",
                            desc: "",
                            qty: 0,
                            price: 0,
                            account: "",
                            taxRate: "",
                            region: "",
                            project: "",
                            amount: 0,
                          });
                        }
                      }}
                    >
                      Add 10
                    </Text>
                  </MenuItem>
                  <MenuItem
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                  >
                    {" "}
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                      onClick={() => {
                        for (let i = 0; i < 20; i++) {
                          billingComponent.append({
                            item: "",
                            desc: "",
                            qty: 0,
                            price: 0,
                            account: "",
                            taxRate: "",
                            region: "",
                            project: "",
                            amount: 0,
                          });
                        }
                      }}
                    >
                      Add 20
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
            <Button
              bg={"linear-gradient(#fff,#e6eaec)"}
              fontSize={"12px"}
              borderRadius={"3px"}
              color={"#808080"}
              transition={"opacity .2s ease-out"}
              border={"1px solid #cfd2d4"}
              cursor={"not-allowed"}
              _hover={{
                color: "#808080",
                bg: "linear-gradient(#fff,#e6eaec)",
                border: "1px solid #cfd2d4",
              }}
            >
              Assigns expense to the customer or project
            </Button>
          </Stack>
          <Stack flex={1}>
            <HStack justifyContent={"space-between"}>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                Subtotal
              </Text>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                {totalAmount}
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"}>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                Total GST
              </Text>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"400"}
                fontSize={"15px"}
              >
                {tenPercent}
              </Text>
            </HStack>
            <Stack borderTop={"2px solid #e5e7eb"}>
              <HStack
                justifyContent={"space-between"}
                paddingTop={"10px"}
                paddingBottom={"10px"}
              >
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"400"}
                  fontSize={"15px"}
                >
                  Total
                </Text>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"700"}
                  fontSize={"21px"}
                >
                  {totalSum}
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          padding={"8px 10px"}
          direction={"row"}
          _light={{
            bg: "customColor.white",
          }}
          justifyContent={"space-between"}
        >
          <HStack gap={0}>
            <Box width={"100%"}>
              <Button
                borderRadius={"3px"}
                borderRightRadius={0}
                backgroundColor={"#fff"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"32px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
              >
                Save
              </Button>
            </Box>
            <Menu>
              <MenuButton
                as={IconButton}
                h={"32px"}
                _light={{
                  bgColor: "customColor.white",
                  color: "customColor.black7",
                }}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                fontWeight={"400"}
                borderTop={"1px solid #e5e7eb"}
                borderRight={"1px solid #e5e7eb"}
                borderBottom={"1px solid #e5e7eb"}
                borderRightRadius={"4px"}
                _hover={{
                  color: "customColor.black7",
                  bg: "#f2f3f4",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "customColor.black7",
                  bg: "#f2f3f4",
                  borderWidth: "1px",
                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
                icon={<IoIosArrowDown />}
              />

              <MenuList overflowY="auto" zIndex={"overlay"}>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Save as draft
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Submit (continue editing)
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Submit & submit for approval
                  </Text>
                </MenuItem>
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  {" "}
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Submit & add another
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <HStack>
            <HStack gap={0}>
              <Box width={"100%"}>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"32px"}
                  borderRadius={"3px"}
                  borderRightRadius={0}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                >
                  Approve & email
                </Button>
              </Box>

              <Menu>
                <MenuButton
                  as={IconButton}
                  h={"32px"}
                  _light={{
                    bgColor: "#3454d1",
                    color: "customColor.white",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  borderLeft={"1px solid #ffffff"}
                  borderTop={"1px solid #3454d1"}
                  borderRight={"1px solid #3454d1"}
                  borderBottom={"1px solid #3454d1"}
                  borderRightRadius={"4px"}
                  icon={<IoIosArrowDown />}
                />

                <MenuList overflowY="auto" zIndex={"overlay"}>
                  {[
                    "Approve",
                    "Approve & add another",
                    "Approve & view next",

                    "Approve & print",
                  ].map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        padding={"0px 12px"}
                        _hover={{
                          backgroundColor: "rgba(0,10,30,.1)",
                        }}
                      >
                        {" "}
                        <Text
                          padding={"8px"}
                          margin={"0px 12px 0px 0px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.pearlWhite",
                          }}
                          fontSize={"15px"}
                        >
                          {item}
                        </Text>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </HStack>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"32px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate("/app/payroll/InvoicesParent");
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
