import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { AiFillCloseSquare } from "react-icons/ai";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";
import { PiArrowSquareRightFill } from "react-icons/pi";
import { CustomInput } from "../../../Common/CustomInput";
import { DeleteModal } from "./DeleteModal";
import { EditModal } from "./EditModal";
export function TrackingCategories() {
  const form = useForm({
    defaultValues: {
      category: "",

      category_options: [
        {
          options: "",
        },
      ],
    },
  });
  const [tabs, setTabs] = useState([{ name: "Region" }]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const addNewTab = () => {
    setTabs([...tabs, { name: "Untitled" }]);
    setSelectedTabIndex(tabs.length); // Set the new tab as active
  };
  const deleteModal = useDisclosure();
  const editModal = useDisclosure();
  const [data, setData] = useState("");
  const categoryOptions = useFieldArray({
    control: form.control,
    name: "category_options",
  });
  return (
    <FormProvider {...form}>
      <Box
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Container
          h="64px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          maxW={"100%"}
          padding={"0px 20px"}
          justifyContent={"center"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderBottom={"1px solid #e5e7eb"}
        >
          <Container maxW={"940px"} padding={"10px 0px 0px"}>
            <Breadcrumb
              separator={<MdChevronRight color="gray.500" />}
              paddingTop={"5px"}
            >
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="/app/payroll/advancedSettings"
                  fontSize={"11px"}
                  color={"#3454d1"}
                >
                  Advanced Accounting
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href=""
                  fontSize={"11px"}
                  _light={{
                    color: "customColor.black6",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  padding={"0px 20px 0px 0px"}
                ></BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Heading
              fontWeight={"bold"}
              _light={{
                color: "customColor.black",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"24px"}
              margin={"0px 4px 0px 0px"}
            >
              Tracking Categories
            </Heading>
          </Container>
        </Container>

        <Container maxW={"980px"} padding={"21.12px"}>
          {!tabs.some((tab) => tab.name === "Untitled") && (
            <Button
              leftIcon={<BsFillPlusSquareFill size={"15px"} />}
              padding={"7.52px 12px"}
              cursor={"pointer"}
              color={"#3454d1"}
              size={"sm"}
              fontSize={"12px"}
              fontWeight={"700"}
              textAlign={"center"}
              border={"1px solid #e6e6e6"}
              borderRadius={"3px"}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              _hover={{
                color: "#2c48b5",
                bg: "#fff",
              }}
              onClick={addNewTab}
            >
              Add Tracking Category
            </Button>
          )}

          <Tabs
            variant="enclosed"
            marginTop={"30px"}
            index={selectedTabIndex}
            onChange={(index) => setSelectedTabIndex(index)}
          >
            <TabList>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  _selected={{
                    _dark: {
                      bgColor: "customColor.dark.50",
                      color: "custom.white",
                    },
                    _light: {
                      bgColor: "customColor.white",
                      color: "customColor.black4",
                      _hover: {
                        color: "#3454d1",
                      },
                    },
                  }}
                  color="#3454d1"
                  fontSize={"12px"}
                  fontWeight={"bold"}
                >
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <Stack
                  marginTop={"20px"}
                  w={data ? "285px" : "350px"}
                  padding={"0"}
                  bgColor={"#fff1eb"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  border={"1px solid #c1c1c1"}
                >
                  <Stack padding={"10px"}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black6",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Tracking category name
                    </Text>
                    <Stack
                      padding={"5px"}
                      margin={"3px 0px 0px"}
                      bgColor={"#fee4d8"}
                      gap={0}
                      marginBottom={"5px"}
                    >
                      <Stack direction={"row"} padding={"3px 0px 4px 0px"}>
                        <Text
                          _dark={{
                            color: "customColor.white",
                          }}
                          _light={{
                            color: "customColor.black6",
                          }}
                          fontSize={"12px"}
                          flex={1}
                        >
                          Region
                        </Text>
                        <HStack gap={0} alignItems={"center"}>
                          <Icon
                            fontSize={"20px"}
                            as={AiFillCloseSquare}
                            color={"grey"}
                            _hover={{
                              color: "red.500",
                            }}
                            onClick={() => deleteModal.onOpen()}
                          />
                          <Button
                            variant={"link"}
                            color={"#3454d1"}
                            fontSize={"12px"}
                            margin={"0px 0px 0px 10px"}
                            onClick={() => editModal.onOpen()}
                          >
                            Rename
                          </Button>
                        </HStack>
                      </Stack>
                    </Stack>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black6",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Category options
                    </Text>

                    <Stack
                      border={"1px solid #d0d8e5"}
                      margin={"3px 0px 0px"}
                      padding={"5px"}
                    >
                      {["Eastside", "North", "South", "West Coast"].map(
                        (item, i) => {
                          return (
                            <Stack key={i} direction={"row"}>
                              <Text
                                _dark={{
                                  color: "customColor.white",
                                }}
                                _light={{
                                  color: "customColor.black6",
                                }}
                                fontSize={"12px"}
                                flex={1}
                              >
                                {item}
                              </Text>
                              <HStack gap={0} alignItems={"center"}>
                                <Icon
                                  fontSize={"20px"}
                                  as={AiFillCloseSquare}
                                  color={"grey"}
                                  _hover={{
                                    color: "red.500",
                                  }}
                                  onClick={() => deleteModal.onOpen()}
                                />
                                <Button
                                  variant={"link"}
                                  fontSize={"12px"}
                                  color={"#3454d1"}
                                  margin={"0px 0px 0px 10px"}
                                  onClick={() => editModal.onOpen()}
                                >
                                  Rename
                                </Button>
                              </HStack>
                            </Stack>
                          );
                        }
                      )}
                    </Stack>
                    {categoryOptions.fields.length > 0 && (
                      <Stack>
                        {categoryOptions.fields.map((field, index) => (
                          <Stack
                            padding={"6px 0px 8px"}
                            key={field.id}
                            direction={"row"}
                            alignContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Controller
                              name={`category_options.${index}.options`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "option name is required",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  ref={field.ref}
                                  validationMessage={error?.message}
                                  withValidation
                                  input={{
                                    ...field,
                                    isInvalid: error !== undefined,
                                    placeholder: "",
                                    w: "100%",
                                    fontSize: "12px",
                                    backgroundColor: "#fff",
                                    variant: "outline",
                                    fontWeight: "500",
                                    lineHeight: "1.2",
                                    h: "19px",
                                    padding: "0px 0px 0px px",
                                    type: "text",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                    borderRadius: "1px",
                                  }}
                                />
                              )}
                            />
                            {categoryOptions.fields.length > 1 && (
                              <Icon
                                fontSize={"20px"}
                                as={IoIosCloseCircleOutline}
                                color={"grey"}
                                _hover={{
                                  color: "red.500",
                                }}
                                onClick={() => categoryOptions.remove(index)}
                              />
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    )}

                    <Stack padding={"10px 0px"}>
                      <Button
                        leftIcon={<PiArrowSquareRightFill size={"15px"} />}
                        padding={"7.52px 12px"}
                        cursor={"pointer"}
                        color={"#3454d1"}
                        size={"sm"}
                        fontSize={"12px"}
                        w={"75%"}
                        fontWeight={"700"}
                        textAlign={"left"}
                        border={"1px solid #e6e6e6"}
                        borderRadius={"3px"}
                        _dark={{
                          bgColor: "customColor.dark.50",
                        }}
                        _light={{
                          bgColor: "customColor.white",
                        }}
                        _hover={{
                          color: "#2c48b5",
                          bg: "#fff",
                        }}
                        onClick={() => {
                          categoryOptions.append({
                            options: "",
                          });
                        }}
                      >
                        Add another option
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack
                    borderTop={"1px solid #c1c1c1"}
                    bgColor={"#fee4d8"}
                    padding={"8px 0px 10px"}
                  >
                    <HStack
                      justifyContent={{ xs: "flex-Start", md: "flex-end" }}
                      padding={"0px 10px"}
                      gap={0}
                    >
                      <Button
                        fontSize={"13px"}
                        bgColor="#3454D1"
                        h={"40px"}
                        borderRadius={"3px"}
                        borderWidth={"1px"}
                        borderColor={"#3454d1!important"}
                        variant={"solid"}
                        marginRight={"8px"}
                        padding={"12px 16px"}
                        fontWeight={700}
                        _hover={{
                          bgColor: "#2c48b5",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        backgroundColor={"#fff"}
                        borderRadius={"3px"}
                        color={"#ea4d4d"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        h={"40px"}
                        variant={"solid"}
                        padding={"12px 16px"}
                        fontWeight={700}
                        _hover={{
                          backgroundColor: "#fff",
                        }}
                      >
                        Cancel
                      </Button>
                    </HStack>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel p={0}>
                <Stack
                  marginTop={"20px"}
                  w={data ? "285px" : "350px"}
                  padding={"0"}
                  bgColor={"#fff1eb"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  border={"1px solid #c1c1c1"}
                >
                  <Stack padding={"10px"}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black6",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Tracking category name
                    </Text>
                    <Stack
                      padding={"5px"}
                      margin={"3px 0px 0px"}
                      bgColor={"#fee4d8"}
                      gap={0}
                      marginBottom={"5px"}
                    >
                      <Stack padding={"6px 0px 8px"}>
                        <Controller
                          name={`category`}
                          control={form.control}
                          rules={{
                            required: {
                              value: true,
                              message: "Category name is required",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                placeholder: "",
                                w: "100%",
                                fontSize: "12px",
                                backgroundColor: "#fff",
                                variant: "outline",
                                fontWeight: "500",
                                lineHeight: "1.2",
                                h: "19px",

                                padding: "0px 0px 0px px",
                                type: "text",
                                border: "1px solid",
                                borderColor: "gray.200",
                                borderRadius: "1px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black6",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Category options
                    </Text>

                    <Stack
                      border={"1px solid #d0d8e5"}
                      margin={"3px 0px 0px"}
                      padding={"5px"}
                      gap={0}
                    >
                      {categoryOptions.fields.map((field, index) => (
                        <Stack
                          padding={"6px 0px 8px"}
                          key={field.id}
                          direction={"row"}
                          alignContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Controller
                            name={`category_options.${index}.options`}
                            control={form.control}
                            rules={{
                              required: {
                                value: true,
                                message: "option name is required",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  placeholder: "",
                                  w: "100%",
                                  fontSize: "12px",
                                  backgroundColor: "#fff",
                                  variant: "outline",
                                  fontWeight: "500",
                                  lineHeight: "1.2",
                                  h: "19px",
                                  padding: "0px 0px 0px px",
                                  type: "text",
                                  border: "1px solid",
                                  borderColor: "gray.200",
                                  borderRadius: "1px",
                                }}
                              />
                            )}
                          />
                          {categoryOptions.fields.length > 1 && (
                            <Icon
                              fontSize={"20px"}
                              as={IoIosCloseCircleOutline}
                              color={"grey"}
                              _hover={{
                                color: "red.500",
                              }}
                              onClick={() => categoryOptions.remove(index)}
                            />
                          )}
                        </Stack>
                      ))}
                    </Stack>

                    <Stack padding={"10px 0px"}>
                      <Button
                        leftIcon={<PiArrowSquareRightFill size={"15px"} />}
                        padding={"7.52px 12px"}
                        cursor={"pointer"}
                        color={"#3454d1"}
                        size={"sm"}
                        fontSize={"12px"}
                        w={"75%"}
                        fontWeight={"700"}
                        textAlign={"left"}
                        border={"1px solid #e6e6e6"}
                        borderRadius={"3px"}
                        _dark={{
                          bgColor: "customColor.dark.50",
                        }}
                        _light={{
                          bgColor: "customColor.white",
                        }}
                        _hover={{
                          color: "#2c48b5",
                          bg: "#fff",
                        }}
                        onClick={() => {
                          categoryOptions.append({
                            options: "",
                          });
                        }}
                      >
                        Add another option
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack
                    borderTop={"1px solid #c1c1c1"}
                    bgColor={"#fee4d8"}
                    padding={"8px 0px 10px"}
                  >
                    <HStack
                      justifyContent={{ xs: "flex-Start", md: "flex-end" }}
                      padding={"0px 10px"}
                      gap={0}
                    >
                      <Button
                        fontSize={"13px"}
                        bgColor="#3454D1"
                        h={"40px"}
                        borderRadius={"3px"}
                        borderWidth={"1px"}
                        borderColor={"#3454d1!important"}
                        variant={"solid"}
                        marginRight={"8px"}
                        padding={"12px 16px"}
                        fontWeight={700}
                        _hover={{
                          bgColor: "#2c48b5",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        backgroundColor={"#fff"}
                        borderRadius={"3px"}
                        color={"#ea4d4d"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        h={"40px"}
                        variant={"solid"}
                        marginRight={"8px"}
                        padding={"12px 16px"}
                        fontWeight={700}
                        _hover={{
                          backgroundColor: "#fff",
                        }}
                      >
                        Cancel
                      </Button>
                    </HStack>
                  </Stack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
        <DeleteModal
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
        />
        <EditModal isOpen={editModal.isOpen} onClose={editModal.onClose} />
      </Box>
    </FormProvider>
  );
}
