import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { Leaves } from "../../Api/Leaves";
interface RejectLeaveProps {
  isOpen: boolean;
  onClose: () => void;
  leaveId: string;
  setlastUpdated: (lastUpdated: number) => void;
}
export default function RejectLeave({
  isOpen,
  onClose,
  leaveId,
  setlastUpdated,
}: RejectLeaveProps) {
  const form = useFormContext<{
    status: string;
    note: string;
  }>();
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Reject Leave
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text
              fontSize={"0.8125rem"}
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Are you sure you want to reject this leave?
            </Text>
            <Controller
              control={form.control}
              name="note"
              rules={{
                required: {
                  value: true,
                  message: "Please add reason for rejection",
                },
              }}
              render={({ field, fieldState }) => (
                <Textarea
                  {...field}
                  //   isInvalid={fieldState.invalid}
                  //   errorBorderColor="Red"
                  border="1px solid #e5e7eb"
                  _hover={{
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _focus={{
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _focusVisible={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  mb="15px"
                  overflowX="hidden"
                  maxLength={300}
                  w="400px"
                  shadow="inner"
                  placeholder=""
                  h="80px"
                />
              )}
            />
            {form.formState.errors.note && (
              <Text color="red.500" fontSize="15px">
                {form.formState.errors.note.message}
              </Text>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            border={"1px solid #a6a9b0"}
            colorScheme={"red"}
            fontSize={"13px"}
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            variant={"solid"}
            padding={"12px 16px"}
            onClick={form.handleSubmit((data) => {
              let rejectionData = { ...form.getValues(), status: "REJECTED" };
              Leaves.changeLeaveStatus(
                leaveId,
                rejectionData,
                (message) => {
                  toast({
                    title: "Success",
                    description: message,
                    status: "success",
                  });
                  setlastUpdated(Date.now());
                  onClose();
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: error,
                    status: "error",
                  });
                }
              );
            })}
          >
            Reject
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
