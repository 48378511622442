import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaChartLine } from "react-icons/fa";
import { FaBarsProgress } from "react-icons/fa6";
import { GrItalic } from "react-icons/gr";
import { IoPieChartSharp, IoStatsChartSharp } from "react-icons/io5";
import { MdOutlineFormatBold } from "react-icons/md";
import { TbMinusVertical } from "react-icons/tb";
import { ReactSelect } from "../../../ReactSelect";
import ColorGradientPicker from "../ColorGradientPicker";
import DynamicBarChart from "../DynamicBarChart";
import DynamicLineChart from "../DynamicLineChart";
import DynamicPieChart from "../DynamicPieChart";
import DynamicProgressChart from "../DynamicProgressChart";
interface ChartSettings {
  chartType: "BAR" | "PIE" | "LINE" | "PROGRESS";
  calculation: "sum" | "average" | "median" | "min" | "max";
  label: "gender" | "department" | "province" | "employeementType";
  colors: string[];
  fontStyle: "normal" | "italic" | "oblique";
  fontWeight: "normal" | "bold";
  fontSize: number;
}
interface ChartsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: (chartSettings: ChartSettings) => void;
  setLastUpdate: (timstamp: number) => void;
  chartSettings?: ChartSettings;
}
export default function ChartsModal({
  isOpen,
  onClose,
  onOk,
  setLastUpdate,
  chartSettings,
}: ChartsModalProps) {
  const [chartType, setChartType] = useState<
    "BAR" | "PIE" | "LINE" | "PROGRESS"
  >("BAR");
  const [calculation, setCalculation] = useState<
    "sum" | "average" | "median" | "min" | "max"
  >("sum");
  const [label, setLabel] = useState<
    "gender" | "department" | "province" | "employeementType"
  >("employeementType");
  const [colors, setColors] = useState<string[]>([
    "#FFD480",
    "#5A87E8",
    "#66E58C",
  ]);
  const [fontStyle, setFontStyle] = useState<"normal" | "italic" | "oblique">(
    "normal"
  );
  const [fontWeight, setFontWeight] = useState<"normal" | "bold">("normal");
  const [fontSize, setFontSize] = useState<number>(12);
  useEffect(() => {
    if (chartSettings) {
      setChartType(chartSettings.chartType);
      setCalculation(chartSettings.calculation);
      setLabel(chartSettings.label);
      setColors(chartSettings.colors);
      setFontStyle(chartSettings.fontStyle);
      setFontWeight(chartSettings.fontWeight);
      setFontSize(chartSettings.fontSize);
    }
  }, [chartSettings]);
  const handleOk = () => {
    onOk({
      chartType,
      calculation,
      label,
      colors,
      fontStyle,
      fontSize,
      fontWeight,
    });
    setLastUpdate(Date.now());
    onClose();
  };
  const labelTypes: Array<{ label: string; value: string }> = [
    {
      label: "Employeement Type",
      value: "employeementType",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Gender",
      value: "gender",
    },
    {
      label: "Province",
      value: "province",
    },
  ];
  const colorOptions: Array<{ label: string; value: string }> = [
    { label: "Blue", value: "#54a0ff" },
    { label: "Red", value: "#ff6b6b" },
    { label: "Green", value: "#1dd1a1" },
    { label: "Yellow", value: "#feca57" },
    { label: "Purple", value: "#5f27cd" },
  ];
  const fontSizeOptions: Array<{ label: string; value: number }> = [
    { label: "12", value: 12 },
    { label: "14", value: 14 },
    { label: "16", value: 16 },
    { label: "18", value: 18 },
    { label: "20", value: 20 },
  ];

  const colorPalette = [
    "#FFD480", // Lighter Light Orange
    "#5A87E8", // Lighter Dark Blue
    "#66E58C", // Lighter Light Green

    "#F1B4B4", // Lighter Pink
    "#EA8585", // Lighter Red
    "#D14D4D", // Lighter Dark Red
    "#B36666", // Lighter Maroon
    "#8C4D4D", // Lighter Dark Maroon
    "#4D4D4D", // Lighter Black
    "#9FC6E8", // Lighter Light Blue
    "#7CA4E0", // Lighter Sky Blue
    "#5A7ABC", // Lighter Blue
    "#42648E", // Lighter Dark Blue
    "#486C73", // Lighter Dark Teal
    "#547640", // Lighter Dark Green
    "#74A154", // Lighter Green
    "#D3E3C8", // Lighter Light Green
    "#B5D2A3", // Lighter Grass Green
    "#FFE392", // Lighter Yellow
    "#F7C298", // Lighter Orange
    "#A47A33", // Lighter Dark Brown Orange
    "#C68138", // Lighter Brown
    "#9E6740", // Lighter Dark Brown
    "#BF769B", // Lighter Plum
    "#8E5062", // Lighter Dark Plum
    "#C187FF", // Lighter Purple
    "#8F66E5", // Lighter Dark Purple
    "#E3EDF8", // Lighter Very Light Blue
    "#4D4DB3", // Lighter Navy Blue
    "#5B4575", // Lighter Very Dark Purple
    "#866BBF", // Lighter Violet
    "#E3B8CA", // Lighter Light Pink
    "#D8A8B8", // Lighter Pale Pink
    "#F2BE7F", // Lighter Peach
    "#D5D5D5", // Lighter Gray
    "#6D6D6D", // Lighter Dark Gray
    "#E4E4E4", // Lighter Light Gray
    "#F3D870", // Lighter Gold
  ];

  const toggleColor = (color: string) => {
    if (colors.includes(color)) {
      setColors(colors.filter((c) => c !== color));
    } else {
      setColors([...colors, color]);
    }
  };
  const handleColorChange = (selectedColor: string | string[]) => {
    setColors(Array.isArray(selectedColor) ? selectedColor : [selectedColor]);
  };
  const [showColorPicker, setShowColorPicker] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent height="97%" maxW="70%">
        <ModalHeader>Chart</ModalHeader>
        <ModalCloseButton />
        <ModalBody px={0}>
          <Stack
            w={"100%"}
            h={{ xs: "700px", md: "100%" }}
            spacing={0}
            direction={{ xs: "column", md: "row" }}
          >
            <Box
              w={{ xs: "100%", md: "70%" }}
              h={{ xs: "700px", md: "100%" }}
              sx={{
                "::-webkit-scrollbar": {
                  width: "0px", // Initially, the scrollbar is hidden
                  background: "transparent",
                },
                ":hover::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible on hover
                },
                ":active::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible when active
                },
                ":focus::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible on focus
                },
                // Add for Firefox and other browsers if needed
                scrollbarWidth: "none",
                ":hover": {
                  scrollbarWidth: "thin",
                },
                ":active": {
                  scrollbarWidth: "thin",
                },
                ":focus": {
                  scrollbarWidth: "thin",
                },
              }}
              overflowY="auto"
            >
              {chartType === "PIE" ? (
                <DynamicPieChart
                  attribute={label}
                  calculation={calculation}
                  colors={colors}
                  fontStyle={fontStyle}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                />
              ) : chartType === "BAR" ? (
                <DynamicBarChart
                  attribute={label}
                  calculation={calculation}
                  colors={colors}
                  fontStyle={fontStyle}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                />
              ) : chartType === "LINE" ? (
                <DynamicLineChart
                  attribute={label}
                  calculation={calculation}
                  colors={colors}
                  fontStyle={fontStyle}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                />
              ) : (
                <DynamicProgressChart
                  attribute={label}
                  calculation={calculation}
                  colors={colors}
                  fontStyle={fontStyle}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                />
              )}
              {/* <BarChart /> */}
            </Box>
            <Box
              w={{ xs: "100%", md: "30%" }}
              h={{ xs: "700px", md: "100%" }}
              sx={{
                "::-webkit-scrollbar": {
                  width: "0px", // Initially, the scrollbar is hidden
                  background: "transparent",
                },
                ":hover::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible on hover
                },
                ":active::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible when active
                },
                ":focus::-webkit-scrollbar": {
                  width: "8px", // Scrollbar visible on focus
                },
                // Add for Firefox and other browsers if needed
                scrollbarWidth: "none",
                ":hover": {
                  scrollbarWidth: "thin",
                },
                ":active": {
                  scrollbarWidth: "thin",
                },
                ":focus": {
                  scrollbarWidth: "thin",
                },
              }}
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
              p="8px 16px"
              marginRight={"15px"}
              border={"1px solid #e5e7eb"}
              overflowY="auto"
            >
              <Text
                fontSize="13px"
                display={"block"}
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                marginBottom={"8px"}
              >
                Settings
              </Text>
              <Accordion allowToggle>
                <AccordionItem
                  mb={2}
                  width={"100%"}
                  textAlign={"start"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  border={"1px solid #e5e7eb"}
                  borderRadius={"4px"}
                  _hover={{
                    color: "black",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "black",
                    bg: "#f2f3f4",
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  marginBottom={"8px"}
                >
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="13px"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Chart type
                    </Box>
                    <Flex>
                      <TbMinusVertical
                        fontSize={"22px"}
                        color={"hsl(0,0%,80%)"}
                      />
                      <ChevronDownIcon
                        fontSize={"22px"}
                        color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                        _hover={{
                          color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                        }}
                      />
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <Text
                      fontSize={"12px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"600"}
                    >
                      Most Popular
                    </Text>
                    <HStack mt="8px">
                      <IconButton
                        aria-label="pie"
                        icon={<IoPieChartSharp size="40px" color="#47C5E8" />}
                        color={"black"}
                        boxSize={"50px"}
                        variant="ghost"
                        bg={"transparent"}
                        _hover={{
                          bg: "transparent",
                        }}
                        borderWidth={chartType === "PIE" ? "2px" : "1px"}
                        borderColor={
                          chartType === "PIE" ? "#3454d1" : "gray.200"
                        }
                        borderRadius="8px"
                        onClick={() => {
                          setChartType("PIE");
                        }}
                      />

                      <IconButton
                        aria-label="bar"
                        icon={<IoStatsChartSharp size="40px" color="#47C5E8" />}
                        color={"black"}
                        boxSize={"50px"}
                        variant="ghost"
                        bg={"transparent"}
                        _hover={{
                          bg: "transparent",
                        }}
                        borderWidth={chartType === "BAR" ? "2px" : "1px"}
                        borderColor={
                          chartType === "BAR" ? "#3454d1" : "gray.200"
                        }
                        //  primary.500 previous color in case of selected
                        borderRadius="8px"
                        onClick={() => {
                          setChartType("BAR");
                        }}
                      />
                      <IconButton
                        aria-label="bar"
                        icon={<FaChartLine size="40px" color="#47C5E8" />}
                        color={"black"}
                        boxSize={"50px"}
                        variant="ghost"
                        bg={"transparent"}
                        _hover={{
                          bg: "transparent",
                        }}
                        borderWidth={chartType === "LINE" ? "2px" : "1px"}
                        borderColor={
                          chartType === "LINE" ? "#3454d1" : "gray.200"
                        }
                        //  primary.500 previous color in case of selected
                        borderRadius="8px"
                        onClick={() => {
                          setChartType("LINE");
                        }}
                      />
                      <IconButton
                        aria-label="progress"
                        icon={<FaBarsProgress size="40px" color="#47C5E8" />}
                        color={"black"}
                        boxSize={"50px"}
                        variant="ghost"
                        bg={"transparent"}
                        _hover={{
                          bg: "transparent",
                        }}
                        borderWidth={chartType === "PROGRESS" ? "2px" : "1px"}
                        borderColor={
                          chartType === "PROGRESS" ? "#3454d1" : "gray.200"
                        }
                        //  primary.500 previous color in case of selected
                        borderRadius="8px"
                        onClick={() => {
                          setChartType("PROGRESS");
                        }}
                      />
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem
                  mb={2}
                  width={"100%"}
                  textAlign={"start"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  border={"1px solid #e5e7eb"}
                  borderRadius={"4px"}
                  _hover={{
                    color: "black",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "black",
                    bg: "#f2f3f4",
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  marginBottom={"8px"}
                >
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="13px"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Labels
                    </Box>
                    <Flex>
                      <TbMinusVertical
                        fontSize={"22px"}
                        color={"hsl(0,0%,80%)"}
                      />
                      <ChevronDownIcon
                        fontSize={"22px"}
                        color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                        _hover={{
                          color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                        }}
                      />
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <ReactSelect
                      options={labelTypes}
                      value={labelTypes.find((l) => l.value === label)}
                      onChange={(selectedOption: any) => {
                        setLabel(selectedOption?.value as any);
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem
                  mb={2}
                  width={"100%"}
                  textAlign={"start"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  border={"1px solid #e5e7eb"}
                  borderRadius={"4px"}
                  _hover={{
                    color: "black",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "black",
                    bg: "#f2f3f4",
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  marginBottom={"8px"}
                >
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="13px"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Values
                    </Box>
                    <Flex>
                      <TbMinusVertical
                        fontSize={"22px"}
                        color={"hsl(0,0%,80%)"}
                      />
                      <ChevronDownIcon
                        fontSize={"22px"}
                        color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                        _hover={{
                          color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                        }}
                      />
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <Text
                      fontSize={"12px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"600"}
                    >
                      Calculation Function
                    </Text>
                    <HStack mt="8px">
                      <ButtonGroup
                        size="sm"
                        spacing={0}
                        variant="outline"
                        color="#323338"
                        w={"100%"}
                      >
                        <Button
                          borderLeftRadius="5px"
                          borderColor={
                            calculation === "sum" ? "#3454d1" : "gray.200"
                          }
                          zIndex={calculation === "sum" ? 99 : 1}
                          color="#323338"
                          fontWeight={400}
                          fontSize={"12px"}
                          w={"100%"}
                          onClick={() => {
                            setCalculation("sum");
                          }}
                        >
                          Sum
                        </Button>
                        <Button
                          borderColor={
                            calculation === "average" ? "#3454d1" : "gray.200"
                          }
                          zIndex={calculation === "average" ? 99 : 1}
                          color="#323338"
                          fontWeight={400}
                          fontSize={"12px"}
                          w={"100%"}
                          onClick={() => {
                            setCalculation("average");
                          }}
                        >
                          Average
                        </Button>

                        <Button
                          borderColor={
                            calculation === "median" ? "#3454d1" : "gray.200"
                          }
                          zIndex={calculation === "median" ? 99 : 1}
                          color="#323338"
                          fontWeight={400}
                          fontSize={"12px"}
                          w={"100%"}
                          onClick={() => {
                            setCalculation("median");
                          }}
                        >
                          Median
                        </Button>
                        <Button
                          borderColor={
                            calculation === "min" ? "#3454d1" : "gray.200"
                          }
                          zIndex={calculation === "min" ? 99 : 1}
                          color="#323338"
                          fontWeight={400}
                          fontSize={"12px"}
                          w={"100%"}
                          onClick={() => {
                            setCalculation("min");
                          }}
                        >
                          Min
                        </Button>
                        <Button
                          borderRightRadius="5px"
                          borderColor={
                            calculation === "max" ? "#3454d1" : "gray.200"
                          }
                          zIndex={calculation === "max" ? 99 : 1}
                          color="#323338"
                          fontWeight={400}
                          fontSize={"12px"}
                          w={"100%"}
                          onClick={() => {
                            setCalculation("max");
                          }}
                        >
                          Max
                        </Button>
                      </ButtonGroup>
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
                {/* <AccordionItem
                  mb={2}
                  width={"100%"}
                  textAlign={"start"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  border={"1px solid #e5e7eb"}
                  borderRadius={"4px"}
                  _hover={{
                    color: "black",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "black",
                    bg: "#f2f3f4",
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  marginBottom={"8px"}
                >
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="13px"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Colors
                    </Box>
                    <Flex>
                      <TbMinusVertical
                        fontSize={"22px"}
                        color={"hsl(0,0%,80%)"}
                      />
                      <ChevronDownIcon
                        fontSize={"22px"}
                        color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                        _hover={{
                          color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                        }}
                      />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text
                      fontSize={"12px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"600"}
                    >
                      Select Colors:
                    </Text>
                    <Box display="flex" flexWrap="wrap">
                      {colorPalette.map((color, index) => (
                        <Box
                          key={index}
                          w="24px"
                          h="24px"
                          bg={color}
                          border="1px solid black"
                          borderRadius="50%"
                          cursor="pointer"
                          position="relative"
                          margin="4px"
                          onClick={() => toggleColor(color)}
                        >
                          {colors.includes(color) && (
                            <CheckIcon
                              boxSize={3}
                              color="white"
                              position="absolute"
                              top="4px"
                              left="4px"
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </AccordionPanel>
                </AccordionItem> */}
                <AccordionItem
                  mb={2}
                  width={"100%"}
                  textAlign={"start"}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  fontWeight={"400"}
                  border={"1px solid #e5e7eb"}
                  borderRadius={"4px"}
                  _hover={{
                    color: "black",
                    bg: "#f2f3f4",
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _active={{
                    color: "black",
                    bg: "#f2f3f4",
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  marginBottom={"8px"}
                >
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="13px"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Colors/Gradients
                    </Box>
                    <Flex>
                      <TbMinusVertical
                        fontSize={"22px"}
                        color={"hsl(0,0%,80%)"}
                      />
                      <ChevronDownIcon
                        fontSize={"22px"}
                        color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                        _hover={{
                          color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                        }}
                      />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Flex justifyContent="center">
                      <ColorGradientPicker
                        initialMode="color" // or "gradient" based on your choice
                        onChange={handleColorChange}
                      />
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Stack
                direction={{ xs: "row", md: "column" }}
                justifyContent={"space-evenly"}
              >
                <Box flex={1}>
                  <Text
                    fontSize={"0.8125rem"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    marginBottom={"8px"}
                  >
                    Font Size
                  </Text>
                  <ReactSelect
                    options={fontSizeOptions}
                    value={fontSizeOptions.find(
                      (size) => size.value === fontSize
                    )}
                    onChange={(selectedOption: any) => {
                      setFontSize(selectedOption?.value as any);
                    }}
                  />
                </Box>
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  justifyContent={"space-between"}
                >
                  <Box mt={{ xs: "0", md: "10px" }}>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      marginBottom={"8px"}
                    >
                      Font Weight
                    </Text>
                    <ButtonGroup>
                      <Tooltip label="Normal" aria-label="A tooltip">
                        <Button
                          onClick={() => setFontWeight("normal")}
                          isActive={fontWeight === "normal"}
                          variant="outline"
                          borderRadius={"5px"}
                          fontSize={"16px"}
                          color={"#3454d1"}
                          border={"1px solid #3454d1"}
                          padding={"0px"}
                          width="28px"
                          h="28px"
                          fontWeight={"normal"}
                          _hover={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          _active={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                        >
                          N
                        </Button>
                      </Tooltip>
                      <Tooltip label="Bold" aria-label="A tooltip">
                        <IconButton
                          aria-label="bold"
                          onClick={() => setFontWeight("bold")}
                          isActive={fontWeight === "bold"}
                          icon={<MdOutlineFormatBold size={18} />}
                          color={"#3454d1"}
                          border={"1px solid #3454d1"}
                          padding={"0px"}
                          width="28px"
                          h="28px"
                          fontWeight={"normal"}
                          _hover={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          _active={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          variant="outline"
                          borderRadius={"5px"}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </Box>
                  <Box mt={{ xs: "0", md: "10px" }}>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      marginBottom={"8px"}
                    >
                      Font Style
                    </Text>
                    <ButtonGroup>
                      <Tooltip label="Normal" aria-label="A tooltip">
                        <Button
                          onClick={() => setFontStyle("normal")}
                          isActive={fontStyle === "normal"}
                          variant="outline"
                          borderRadius={"5px"}
                          fontSize={"16px"}
                          color={"#3454d1"}
                          border={"1px solid #3454d1"}
                          width="28px"
                          h="28px"
                          fontWeight={"normal"}
                          _hover={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          _active={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          p="0px"
                          whiteSpace={"nowrap"}
                        >
                          N
                        </Button>
                      </Tooltip>
                      <Tooltip label="Italic" aria-label="A tooltip">
                        <IconButton
                          aria-label="italic"
                          onClick={() => setFontStyle("italic")}
                          isActive={fontStyle === "italic"}
                          icon={<GrItalic size={18} />}
                          color={"#3454d1"}
                          border={"1px solid #3454d1"}
                          padding={"0px"}
                          fontSize={"16px"}
                          width="28px"
                          h="28px"
                          fontWeight={"normal"}
                          _hover={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          _active={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          variant="outline"
                          borderRadius={"5px"}
                        />
                      </Tooltip>
                      <Tooltip label="Oblique" aria-label="A tooltip">
                        <Button
                          onClick={() => setFontStyle("oblique")}
                          isActive={fontStyle === "oblique"}
                          variant="outline"
                          borderRadius={"5px"}
                          fontSize={"16px"}
                          padding={"0px"}
                          width="28px"
                          h="28px"
                          fontWeight={"normal"}
                          fontStyle="oblique"
                          color={"#3454d1"}
                          border={"1px solid #3454d1"}
                          _hover={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                          _active={{
                            bg: "#3454d1",
                            color: "#fff",
                          }}
                        >
                          O
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            borderRadius={"3px"}
            h={"40px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            marginRight={"8px"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={handleOk}
          >
            {chartSettings ? "Update" : "Create"}
          </Button>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            h={"40px"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
