import { Box } from "@chakra-ui/react";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useDashboardContext } from "../../Hooks/DashboardContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Define the possible types for the attribute prop
type AttributeType = "gender" | "department" | "province" | "employeementType";
type CalculationType = "sum" | "average" | "median" | "min" | "max";

interface DynamicLineChartProps {
  attribute: AttributeType;
  calculation?: CalculationType;
  colors: string[]; // Colors or gradients passed from parent
  fontStyle?: "normal" | "italic" | "oblique";
  fontWeight?: "normal" | "bold";
  fontSize?: number;
}

// Function to check if a color is a gradient
function isGradient(color: string): boolean {
  return color?.includes("linear-gradient");
}

// Function to fix the gradient by ensuring `rgba` and `rgb` are lowercase
function normalizeColorCase(color: string): string {
  return color?.replace(/RGB/gi, "rgb").replace(/RGBA/gi, "rgba");
}

// Function to parse the gradient string and extract colors (supporting hex, rgb, rgba)
function extractColorsFromGradient(gradientString: string): string[] {
  const colors: string[] = [];
  const regex =
    /(rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([0-9.]+))?\))|(#([a-fA-F0-9]{3,6}))/g;
  let match;

  while ((match = regex.exec(gradientString)) !== null) {
    if (match[1]) {
      // This is an rgba or rgb color
      const [rgba] = match;
      colors.push(rgba);
    } else if (match[6]) {
      // This is a hex color
      const [hex] = match;
      colors.push(hex);
    }
  }

  return colors;
}

// Function to create gradient from extracted colors
function createGradientFromColors(ctx: any, chartArea: any, colors: string[]) {
  if (!chartArea || !colors || colors.length === 0) {
    // If chartArea is undefined or colors are invalid, return a fallback solid color
    return colors[0] || "#000"; // Return the first color or fallback color
  }

  const gradient = ctx.createLinearGradient(
    0,
    chartArea.top,
    0,
    chartArea.bottom
  );
  const colorStops = colors.length;

  colors.forEach((color, index) => {
    if (!color) return; // Skip undefined colors

    // Calculate the correct position for each color stop between 0 and 1
    const position = index / (colorStops - 1);
    gradient.addColorStop(position, color);
  });

  return gradient;
}

export default function DynamicLineChart({
  attribute,
  calculation = "sum",
  colors = ["#54a0ff"], // Default fallback color
  fontStyle = "normal",
  fontWeight = "normal",
  fontSize = 12,
}: DynamicLineChartProps) {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();

  // Ensure the colors array is not empty
  const safeColors = colors.length > 0 ? colors : ["#54a0ff"];

  let labels: string[] = [];
  let dataValues: number[] = [];

  // Prepare data based on the attribute prop
  if (attribute === "gender") {
    labels = ["Male", "Female"];
    dataValues = [
      dashboardAnalytics?.maleGenderEmpCount || 0,
      dashboardAnalytics?.femaleGenderEmpCount || 0,
    ];
  } else if (attribute === "department") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInDepartments || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInDepartments || {}
    );
  } else if (attribute === "province") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInProvinces || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInProvinces || {}
    );
  } else if (attribute === "employeementType") {
    labels = ["Full Time", "Part Time", "Daily Wagers"];
    dataValues = [
      dashboardAnalytics?.fullTimeEmployees || 0,
      dashboardAnalytics?.partTimeEmployees || 0,
      dashboardAnalytics?.casualEmployees || 0,
    ];
  }

  // Apply the calculation logic (sum, average, median, etc.)
  const calculatedDataValues = dataValues; // Calculation can be added here if needed

  const data = {
    labels,
    datasets: [
      {
        label: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Trend`,
        data: calculatedDataValues,
        borderColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          const color = safeColors[0]; // Use first color for line

          // Ensure chartArea exists before creating the gradient
          if (!chartArea) {
            return color; // Return solid color until chartArea is available
          }

          // If gradient, extract and create it
          if (isGradient(color)) {
            const fixedColor = normalizeColorCase(color);
            const extractedColors = extractColorsFromGradient(fixedColor);
            return createGradientFromColors(ctx, chartArea, extractedColors);
          } else {
            // For solid color
            return color;
          }
        },
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return "rgba(0,0,0,0)"; // Return transparent if chartArea not yet calculated
          }

          const color = safeColors[0];

          // If gradient, handle accordingly
          if (isGradient(color)) {
            const fixedColor = normalizeColorCase(color);
            const extractedColors = extractColorsFromGradient(fixedColor);
            return createGradientFromColors(ctx, chartArea, extractedColors);
          }

          return color; // Return the solid color directly for solid colors
        },
        fill: true,
        pointBackgroundColor: safeColors[0] || "#000", // Default color if invalid
        pointBorderColor: "#000",
        pointHoverRadius: 6,
        pointRadius: 4,
        tension: 0.4, // Smoothing of the line
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // Adjust according to your data range
        },
      },
      x: {
        ticks: {
          font: {
            size: fontSize,
            weight: fontWeight,
            style: fontStyle,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: fontSize + 2,
            weight: fontWeight,
            style: fontStyle,
          },
        },
      },
      tooltip: {
        backgroundColor: "#fff",
        borderColor: "#000",
        borderWidth: 1,
        titleColor: "#000",
        bodyColor: "#000",
        displayColors: false,
        bodyFont: {
          size: fontSize,
          style: fontStyle,
          weight: fontWeight,
        },
      },
      title: {
        display: true,
        text: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Trend`,
        font: {
          size: fontSize + 2,
          style: fontStyle,
          weight: fontWeight,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.4, // Smooths the line
      },
      point: {
        radius: 4,
        hoverRadius: 6,
        hitRadius: 10,
      },
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      minW={"100%"}
      p="20px"
      overflowY="auto"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Line
        data={data}
        options={options}
        style={{ width: "100%", height: "100%", alignSelf: "center" }}
      />
    </Box>
  );
}
