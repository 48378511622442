import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { EmployeeTaxes } from "../../../Api/EmployeeTaxes";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export function Taxes({ setSelectedTab, selectedTab }: TabProps) {
  const [selectedType, setSelectedType] = useState("Select type");

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      EmployeeTaxes.FetchTax(
        id,
        (data) => {
          taxForm.reset(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [id]);
  const options: Array<{ label: string; value: string }> = [
    {
      label: "Not Quoted ",
      value: "NOT_QOUTED",
    },
    {
      label: "Awaiting",
      value: "AWAITING",
    },
  ];
  const taxForm = useForm<EmployeeTaxes.NewTax>({
    defaultValues: {
      // id: undefined,
      empId: id,
      // id: id,

      taxScaleType: "",
      taxNumber: "",
      exemptionReason: "",
      withholdingVariation: false,
      studyLoan: false,
      claimTaxFreeThreshold: false,
      claimOtherTaxOffset: false,
      increaseTaxEachPeriod: false,
      leaveLoadingEligibility: false,
      residencyStatus: "PAKISTANI",
    },
  });
  const watchExemptions = useWatch({
    control: taxForm.control,
    name: "exemptionReason",
  });
  const taxScaleOptions = [
    { value: "REGULAR", label: "Regular" },
    // {
    //   value: "HORTICULTURISTS_OR_SHEARERS",
    //   label: "Horticulturists or Shearers",
    // },
    // {
    //   value: "ACTORS_ARTIST_ENTERTAINERS",
    //   label: "Actors, variety artists and other entertainers",
    // },
    // { value: "SENIORS_PENTSIONERS", label: "Senior or Pensioners" },
  ];
  const martialStatusOptions = [
    { value: "COUPLE", label: "Couple" },
    { value: "ILLNESS_SEPARATED_COUPLE", label: "Illness-separated couple" },
    { value: "SINGLE", label: "Single" },
  ];
  const { setRefereshEmployees } = useBusinessContext();
  const payeeWorkingConditionsOptions = [
    { value: "PROMOTIONAL", label: "Not eligible" },
    {
      value: "3_OR_LESS_PERFORMANCES_PER_WEEK",
      label: "3 or less performances per week",
    },
    { value: "NONE_OF_THESE", label: "None of these" },
  ];

  const watchApprovedWithholdingVariation = useWatch({
    control: taxForm.control,
    name: "withholdingVariation",
  });
  const watchOtherTaxOffsetClaimed = useWatch({
    control: taxForm.control,
    name: "claimOtherTaxOffset",
  });
  const watchTaxWithheld = useWatch({
    control: taxForm.control,
    name: "increaseTaxEachPeriod",
  });
  const watchEligible = useWatch({
    control: taxForm.control,
    name: "leaveLoadingEligibility",
  });
  const watchTaxScaleType = useWatch({
    control: taxForm.control,
    name: "taxScaleType",
  });
  const toast = useToast();
  return (
    <FormProvider {...taxForm}>
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginBottom={"30px"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderRadius={"5px"}
        border={"1px solid #f6f6f6"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px"
            as="h2"
            size="md"
            _light={{
              color: "customColor.black7",
            }}
          >
            Tax details
          </Heading>
          <Divider orientation="horizontal" />
          <Container
            maxW="400px"
            marginTop="15px"
            paddingTop="20px"
            pb="8px"
            marginX="auto"
            px="0px"
          >
            <Box
              fontSize={"0.8125rem"}
              fontWeight={"bold"}
              lineHeight={"1.3077"}
            >
              <HStack alignItems={"end"}>
                <Stack>
                  <Controller
                    rules={
                      watchExemptions === ""
                        ? {
                            required: {
                              value: true,
                              message: "Tax Number is required",
                            },
                          }
                        : {
                            required: {
                              value: false,
                              message: "Tax Number is required",
                            },
                          }
                    }
                    control={taxForm.control}
                    name="taxNumber"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                        }}
                        placeholder=""
                        label=" National Tax Number "
                        labelProps={{
                          fontSize: ".845rem",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Text
                  marginTop={"20px"}
                  marginLeft={"2px"}
                  marginRight={"2px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  OR
                </Text>
                <Stack gap={0}>
                  <Text
                    fontSize=".8125rem"
                    fontWeight="bold"
                    _light={{
                      color: "customColor.black7",
                    }}
                    lineHeight={"1.3077"}
                    margin={"0px 12px 08px 0px"}
                  >
                    NTN exemption
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Title is required",
                      },
                    }}
                    control={taxForm.control}
                    name="exemptionReason"
                    render={({ field }) => (
                      <Menu {...field}>
                        <MenuButton
                          px={5}
                          py={3}
                          transition="all 0.2s"
                          borderRadius="md"
                          w={"180px"}
                          borderWidth="1px"
                          _hover={{
                            bg: "#f2f3f4",
                          }}
                        >
                          {taxForm.getValues("exemptionReason") === "AWAITING"
                            ? "Awaiting"
                            : null}
                          {taxForm.getValues("exemptionReason") === "NOT_QOUTED"
                            ? "Not Quoted"
                            : null}
                          {taxForm.getValues("exemptionReason") !==
                            "AWAITING" &&
                          taxForm.getValues("exemptionReason") !== "NOT_QOUTED"
                            ? "Select Option"
                            : null}

                          <ChevronDownIcon />
                        </MenuButton>

                        <MenuList maxW={{ xs: "400px", xl: "600px" }}>
                          {[
                            // {
                            //   name: "Pensioner",
                            //   desc: "Employee receives a pension, benefit or allowance.",
                            // },
                            // {
                            //   name: "Under 18",
                            //   desc: "Employee is under the age of 18 with an income below the annual tax threshold.",
                            // },
                            {
                              name: "NTN not quoted",
                              value: "NOT_QOUTED",
                              desc: "Employee has not provided a NTN on their declaration.",
                            },
                            {
                              name: "Awaiting NTN",
                              value: "AWAITING",
                              desc: "Employee has applied for, or made an enquiry about, a new or existing NTN with the FBR.",
                            },
                          ].map((item, i) => (
                            <MenuItem
                              key={i}
                              padding={"1px 0px 1px 20px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                              onClick={() => {
                                taxForm.setValue("exemptionReason", item.value);
                              }}
                            >
                              <Stack
                                paddingLeft={"20px"}
                                paddingRight={"20px"}
                                paddingTop={"8px"}
                                paddingBottom={"8px"}
                                gap={0}
                              >
                                <Text
                                  fontWeight={"700"}
                                  lineHeight={"1.5rem"}
                                  fontSize={".9375rem"}
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.white",
                                  }}
                                >
                                  {item.name}
                                </Text>
                                <Text
                                  fontWeight={"400"}
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  fontSize={"0.8125rem"}
                                >
                                  {item.desc}
                                </Text>
                              </Stack>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    )}
                  />
                </Stack>
              </HStack>
              <Stack spacing="35px" marginTop={"20px"}></Stack>
            </Box>
            <Divider orientation="horizontal" paddingTop={"20px"} />
            <Box paddingTop={"30px"}>
              <Heading
                alignContent={"left"}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize={"0.9375rem"}
                fontWeight={"700"}
                lineHeight={"1.5rem"}
              >
                Select residency status for tax purposes
              </Heading>
              <Stack spacing="35px" marginBottom={"15px"}>
                <Text
                  fontSize={"15px"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  margin={"15px 0px"}
                  fontWeight={"400"}
                  lineHeight={"1.5rem"}
                >
                  Your payee's residency status affects how they'll be taxed.
                  <Link>
                    Work out their residency status
                    <Icon as={BsBoxArrowUpRight} />
                  </Link>
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Residency Status is required",
                    },
                  }}
                  name="residencyStatus"
                  control={taxForm.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup {...field}>
                      <Stack spacing={0}>
                        <Stack
                          border={"1px solid #a6a9b0"}
                          w={"100%"}
                          padding={"5px"}
                          marginBottom={"-1px"}
                          borderRadius={"3px"}
                          // Set a fixed height for the first Stack
                        >
                          <Radio
                            size="lg"
                            isInvalid={fieldState.invalid}
                            value="PAKISTANI"
                            colorScheme="blue"
                            fontSize={"0.9375rem"}
                            fontWeight={"700"}
                            lineHeight={"1.5rem"}
                          >
                            <Text
                              fontSize={"0.9375rem"}
                              fontWeight={"600"}
                              _light={{
                                color: "customColor.black7",
                              }}
                            >
                              Pakistani Resident
                            </Text>
                          </Radio>
                        </Stack>
                        <Stack
                          border={"1px solid #a6a9b0"}
                          w={"100%"}
                          padding={"5px"}
                          borderRadius={"3px"}
                          // Set a fixed height for the first Stack
                        >
                          <Radio
                            isInvalid={fieldState.invalid}
                            size="lg"
                            value="FOREIGNER"
                            colorScheme="blue"
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"1.5rem"}
                          >
                            <Text
                              fontSize={"0.9375rem"}
                              fontWeight={"600"}
                              _light={{
                                color: "customColor.black7",
                              }}
                            >
                              Foreign Resident
                            </Text>
                          </Radio>
                        </Stack>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Stack>
            </Box>
            <Divider
              orientation="horizontal"
              paddingTop={"20px"}
              size={"10px"}
              variant={"solid"}
            />
            <Box paddingTop={"20px"} padding={"35px"} cursor={"not-allowed"}>
              <Controller
                control={taxForm.control}
                name="withholdingVariation"
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    {...field}
                    isInvalid={error !== undefined}
                    isChecked={value}
                    isDisabled
                  >
                    <Text
                      fontSize={"1.1375rem"}
                      lineHeight={"1.5rem"}
                      fontWeight={"400"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Has Approved Withholding Variation
                    </Text>
                  </Checkbox>
                )}
              />

              {watchApprovedWithholdingVariation === true && (
                <Stack marginTop={"20px"}>
                  <Text
                    fontSize=".8125rem"
                    fontWeight="bold"
                    _light={{
                      color: "customColor.black7",
                    }}
                    lineHeight={"1.3077"}
                    marginLeft="36px"
                  >
                    Enter withholding variation percentage
                  </Text>
                  <CustomInput
                    input={{
                      w: "332px",
                      h: "38px",

                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                      marginLeft: "36px",
                    }}
                    placeholder="0.00"
                  />
                  <Box
                    backgroundColor={"#f2f3f4"}
                    fontSize={".8125rem"}
                    padding={"12px 16px 12px 16px"}
                    w={"332px"}
                    marginLeft={"36px"}
                  >
                    <HStack alignItems="flex-start">
                      <Icon as={AiFillInfoCircle} />
                      <Text
                        lineHeight={"1.5rem"}
                        fontSize={"13px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        marginTop={"-5px"}
                      >
                        The PAYG withholding amount will decrease based on the
                        amount entered and will be applied in every pay run.
                      </Text>
                    </HStack>
                  </Box>
                </Stack>
              )}
            </Box>
            <Divider
              orientation="horizontal"
              paddingTop={"20px"}
              size={"10px"}
              variant={"solid"}
            />
            <Box paddingTop={"30px"}>
              <Heading
                alignContent={"left"}
                fontSize={"0.9375rem"}
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"700"}
                lineHeight={"1.5rem"}
              >
                Select tax scale
              </Heading>
              <Stack spacing="35px" marginBottom={"15px"}>
                <Text
                  fontSize={"15px"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  margin={"15px 0px"}
                  fontWeight={"400"}
                  lineHeight={"1.5rem"}
                >
                  The tax scale determines the amount of PAYG withheld each pay.
                  <Link>
                    Learn more about tax scales on HRIS 360 Central
                    <Icon as={BsBoxArrowUpRight} />
                  </Link>
                </Text>
                <Text
                  fontSize=".8125rem"
                  fontWeight="bold"
                  _light={{
                    color: "customColor.black7",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom={"-28px"}
                >
                  Tax scale type
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Tax Scale type is required",
                    },
                  }}
                  control={taxForm.control}
                  name="taxScaleType"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Tax Scale Type"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Tax Scale Type"
                      withValidation
                      options={taxScaleOptions}
                      value={taxScaleOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                {watchTaxScaleType === "ACTORS_ARTIST_ENTERTAINERS" && (
                  <Stack>
                    <Text
                      fontSize=".8125rem"
                      fontWeight="bold"
                      _light={{
                        color: "customColor.black7",
                      }}
                      lineHeight={"1.3077"}
                    >
                      Payee's working conditions
                    </Text>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Payee's working conditions is required",
                        },
                      }}
                      control={taxForm.control}
                      name="taxScaleType"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          placeholder="Select Payee's working conditions Type"
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Select Payee's working conditions Type"
                          withValidation
                          options={payeeWorkingConditionsOptions}
                          value={payeeWorkingConditionsOptions.find(
                            (op) => op.value === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Stack>
                )}
                {watchTaxScaleType === "SENIORS_PENTSIONERS" && (
                  <Stack>
                    <Text
                      fontSize=".8125rem"
                      fontWeight="bold"
                      _light={{
                        color: "customColor.black7",
                      }}
                      lineHeight={"1.3077"}
                    >
                      Payee's martial status
                    </Text>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Payee's marital status is required",
                        },
                      }}
                      control={taxForm.control}
                      name="taxScaleType"
                      render={({ field, fieldState }) => (
                        <ReactSelect
                          {...field}
                          key={field.value}
                          placeholder="Select Payee's marital status Type"
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Select Payee's marital status Type"
                          withValidation
                          options={martialStatusOptions}
                          value={martialStatusOptions.find(
                            (op) => op.value === field.value
                          )}
                          onChange={(newValue: any) => {
                            field.onChange(newValue?.value);
                          }}
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                        />
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            </Box>
            <Divider marginTop={"20px"} marginBottom={"20px"} />
            <Box paddingTop={"30px"}>
              <Heading
                alignContent={"left"}
                fontSize={"0.9375rem"}
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"700"}
                lineHeight={"1.5rem"}
              >
                Tax offset and variations
              </Heading>
              <Stack spacing="35px" marginBottom={"15px"}>
                <HStack>
                  <Text
                    fontSize={"13px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    margin={"13px 0px"}
                    fontWeight={"700"}
                    lineHeight={"1.5rem"}
                  >
                    Study training and support loans
                  </Text>
                  <Button
                    variant={"ghost"}
                    fontSize={"11px"}
                    bg={"#f2f3f4"}
                    color={"#0078C8"}
                    fontWeight={"700"}
                    _hover={{
                      color: "#0073c0",
                      backgroundColor: "rgba(0,10,30,.05)",
                    }}
                  >
                    View all
                  </Button>
                </HStack>

                <Controller
                  control={taxForm.control}
                  name="studyLoan"
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      {...field}
                      isInvalid={error !== undefined}
                      isChecked={value}
                      cursor={"not-allowed"}
                      isDisabled
                      iconColor="white"
                      size={"lg"}
                      margin={"0px 8px"}
                    >
                      <Text
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        fontWeight={"400"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        Payee has study or training loans
                      </Text>
                    </Checkbox>
                  )}
                />
                <Divider />
                <Text
                  fontSize={"13px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                >
                  Tax-free threshold
                </Text>

                <Controller
                  control={taxForm.control}
                  name="claimTaxFreeThreshold"
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      {...field}
                      isInvalid={error !== undefined}
                      isChecked={value}
                      iconColor="white"
                      cursor={"not-allowed"}
                      isDisabled
                      size={"lg"}
                      margin={"0px 8px"}
                      fontSize={"11px"}
                      marginTop={"-35px"}
                    >
                      <Text
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={"400"}
                      >
                        Claim the tax-free threshold
                      </Text>
                    </Checkbox>
                  )}
                />
                <Divider />
                <Controller
                  control={taxForm.control}
                  name="claimOtherTaxOffset"
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      {...field}
                      isInvalid={error !== undefined}
                      isChecked={value}
                      cursor={"not-allowed"}
                      isDisabled
                      iconColor="white"
                      size={"lg"}
                      margin={"0px 8px"}
                    >
                      <Text
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={"400"}
                      >
                        Other tax offset claimed
                      </Text>
                    </Checkbox>
                  )}
                />
                {watchOtherTaxOffsetClaimed === true && (
                  <Stack>
                    <Text
                      fontSize=".8125rem"
                      fontWeight="bold"
                      _light={{
                        color: "customColor.black7",
                      }}
                      lineHeight={"1.3077"}
                      marginLeft="36px"
                    >
                      Enter amount
                    </Text>
                    <CustomInput
                      input={{
                        w: "332px",
                        h: "38px",

                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        marginLeft: "36px",
                      }}
                      placeholder="0.00"
                    />
                    <Box
                      backgroundColor={"#f2f3f4"}
                      fontSize={".8125rem"}
                      padding={"12px 16px 12px 16px"}
                      w={"332px"}
                      marginLeft={"36px"}
                    >
                      <HStack alignItems="flex-start">
                        <Icon as={AiFillInfoCircle} />
                        <Text
                          lineHeight={"1.5rem"}
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          marginTop={"-5px"}
                        >
                          The estimated total tax offset amount entered is the
                          offset for the entire financial year, this will be
                          divided by the number of pay periods within the
                          payslip.
                        </Text>
                      </HStack>
                    </Box>
                  </Stack>
                )}

                <Controller
                  control={taxForm.control}
                  name="increaseTaxEachPeriod"
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      {...field}
                      isInvalid={error !== undefined}
                      isChecked={value}
                      cursor={"not-allowed"}
                      isDisabled
                      iconColor="white"
                      size={"lg"}
                      margin={"0px 8px"}
                    >
                      <Text
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={"400"}
                      >
                        Increase amount of tax withheld each pay period
                      </Text>
                    </Checkbox>
                  )}
                />

                {watchTaxWithheld === true && (
                  <Stack>
                    <Text
                      fontSize=".8125rem"
                      fontWeight="bold"
                      _light={{
                        color: "customColor.black7",
                      }}
                      lineHeight={"1.3077"}
                      marginLeft="36px"
                    >
                      Enter amount
                    </Text>
                    <CustomInput
                      input={{
                        w: "332px",
                        h: "38px",

                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        marginLeft: "36px",
                      }}
                      placeholder="0.00"
                    />
                    <Box
                      backgroundColor={"#f2f3f4"}
                      fontSize={".8125rem"}
                      padding={"12px 16px 12px 16px"}
                      w={"332px"}
                      marginLeft={"36px"}
                    >
                      <HStack alignItems="flex-start">
                        <Icon as={AiFillInfoCircle} />
                        <Text
                          lineHeight={"1.5rem"}
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.200",
                          }}
                          marginTop={"-5px"}
                        >
                          The PAYG withholding amount will increase based on the
                          amount entered and will be applied in every pay run.
                        </Text>
                      </HStack>
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Container>
          <Stack
            borderTop={"1px solid #d6dade"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            padding={"48px 16px"}
          >
            <Box w={"400px"}>
              <Stack spacing="35px">
                <Controller
                  control={taxForm.control}
                  name="leaveLoadingEligibility"
                  render={({
                    field: { value, ...field },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      {...field}
                      isInvalid={error !== undefined}
                      isChecked={value}
                      iconColor="white"
                      size={"lg"}
                      margin={"0px 8px"}
                      fontSize={"11px"}
                      cursor={"not-allowed"}
                      isDisabled
                    >
                      <Text
                        fontSize={"0.9375rem"}
                        lineHeight={"1.5rem"}
                        fontWeight={"400"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        Eligible for leave loading
                      </Text>
                    </Checkbox>
                  )}
                />

                {watchEligible === true && (
                  <Stack>
                    <Checkbox
                      iconColor="white"
                      size={"lg"}
                      margin={"0px 8px"}
                      fontSize={"11px"}
                      marginLeft={"32px"}
                    >
                      <Text
                        fontSize={"0.9375rem"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        lineHeight={"1.5rem"}
                        fontWeight={"400"}
                      >
                        Apply SGC on leave loading
                      </Text>
                    </Checkbox>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box display={"flex"} justifyContent="flex-end" px="20px">
          <ButtonGroup>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              textColor={"customColor.black7"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Save and file now
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              textColor={"customColor.black7"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={taxForm.handleSubmit(
                (data) => {
                  EmployeeTaxes.CreateTax(
                    data,
                    (success) => {
                      toast({
                        title: "Tax is Created/Updated Successfully",
                        status: "success",
                      });
                      taxForm.reset(success);

                      setSelectedTab(selectedTab + 1);
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",
                    status: "error",
                  });
                }
              )}
            >
              Save and Next
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={taxForm.handleSubmit(
                (data) => {
                  EmployeeTaxes.CreateTax(
                    data,
                    (success) => {
                      toast({
                        title: "Tax is Created/Updated Successfully",
                        status: "success",
                      });
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",
                    status: "error",
                  });
                }
              )}
            >
              Save
            </Button>
          </ButtonGroup>
        </Box>

        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
