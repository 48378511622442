import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Image,
  ListItem,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useState } from "react";
import { TbGridDots, TbMinusVertical } from "react-icons/tb";
import Hrislogo from "../../../assets/HRISMainLogo.png";
export function InvoicesSettingTable() {
  const [dragging, setDragging] = useState(false);
  const [items, setItems] = useState([
    "Standard",
    "Contact Details",
    "Page: A4",
    "Font: Calibri",
  ]);

  // Handler when dragging starts
  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.dataTransfer.setData("text/plain", index.toString());
    setDragging(true);
  };

  // Handler when the dragged item is over a droppable area
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // Handler when the dragged item is dropped
  const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);

    if (draggedIndex !== index) {
      const newItems = [...items];
      const [movedItem] = newItems.splice(draggedIndex, 1);
      newItems.splice(index, 0, movedItem);

      setItems(newItems);
    }
    setDragging(false);
  };

  return (
    <Stack marginTop={"20px"} gap={0}>
      {items.map((item, index) => (
        <Stack
          key={index}
          draggable={true}
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
          cursor={dragging ? "grabbing" : "pointer"}
          border={"1px solid #e5e7eb"}
          borderRadius={"2px"}
          marginBottom={"20px"}
          bg={"#fff"}
        >
          <Stack
            bg={
              "#fcfcfc url(/assets/a3f438df3e778b0b2204f76124fa443f.png) repeat-x scroll 0 -309px"
            }
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            padding={"4px 8px 0px 8px"}
            marginBottom={"4px"}
            h={"36px"}
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <TbGridDots />
              <Text
                color={"customColor.black7"}
                fontSize={"14px"}
                fontWeight={"700"}
              >
                {item}
              </Text>
            </Stack>
            <Box>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      as={IconButton}
                      icon={
                        <Flex>
                          <TbMinusVertical
                            fontSize={"22px"}
                            color={"hsl(0,0%,80%)"}
                          />
                          <ChevronDownIcon
                            fontSize={"22px"}
                            color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                            _hover={{
                              color: isOpen
                                ? "hsl(0, 0%, 20%)"
                                : "hsl(0,0%,60%)",
                            }}
                          />
                        </Flex>
                      } //5A5A5A //383c40
                      width={"100%"}
                      textAlign={"start"}
                      h={"25px"}
                      _light={{
                        bgColor: "customColor.white",
                        color: "customColor.black7",
                      }}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      fontWeight={"400"}
                      border={"1px solid #e5e7eb"}
                      borderRadius={"4px"}
                      _hover={{
                        color: "black",
                        bg: "#f2f3f4",
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _active={{
                        color: "black",
                        bg: "#f2f3f4",
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                    />

                    <MenuList
                      w={"200px"}
                      maxH="300px"
                      overflowY="auto"
                      zIndex={"overlay"}
                    >
                      <MenuGroup
                        fontSize={"13px"}
                        color={"#404756"}
                        fontWeight={"normal"}
                        whiteSpace={"nowrap"}
                      >
                        {[
                          { name: "Edit" },
                          { name: "Copy" },
                          { name: "Change Logo" },
                          { name: "Delete" },
                          ,
                          { name: "Preview" },
                          ,
                          { name: "Remove logo" },
                        ].map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              fontSize={"13px"}
                              fontWeight={"500"}
                              padding={"9px 15px"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              _light={{
                                color: "customColor.black7",
                              }}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </MenuGroup>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </Stack>

          <Stack padding={"10px"} gap={0} overflowY={"auto"} h={"350px"}>
            <Image
              flex={1}
              w={"350px"}
              borderRadius="0"
              h="70px"
              src={Hrislogo}
              alt="Sample Image"
              marginBottom={"15px"}
            />
            <Stack direction={"row"} gap={0} height={"fit-content"}>
              <Stack flex={1} gap={0} height={"fit-content"}>
                <Stack marginBottom={"10px"}>
                  <Text fontSize={"11px"} color={"customColor.black7"}>
                    Page: <b> A4 </b> Margins: <b> 1.35cm </b> Bottom:{" "}
                    <b> 1.00cm </b> Address Padding: <b> 1.00cm </b>
                  </Text>
                  <Text fontSize={"11px"} color={"customColor.black7"}>
                    Font: <b> Calibri, 9pt </b>
                  </Text>
                </Stack>
                <Stack marginBottom={"10px"}>
                  <Text fontSize={"11px"} color={"customColor.black7"}>
                    Show:
                  </Text>
                  <Stack direction={"row"}>
                    <UnorderedList flex={1}>
                      {[
                        "Tax number",
                        "Columns headings",
                        "Tax columns",
                        "Unit price and quantity",
                        "Payment advice cut-away",
                        "Logo",
                        "Discount",
                      ].map((item, i) => {
                        return (
                          <ListItem
                            key={i}
                            fontSize={"11px"}
                            color={"customColor.black7"}
                          >
                            {item}
                          </ListItem>
                        );
                      })}
                    </UnorderedList>
                    <UnorderedList flex={1}>
                      {[
                        "Logo align:Right",
                        "tax exclusive",
                        "Tax subtotals by tax rates over 0%",
                        "Currency conversion as a single tax total",
                      ].map((item, i) => {
                        return (
                          <ListItem
                            key={i}
                            fontSize={"11px"}
                            color={"customColor.black7"}
                          >
                            {item}
                          </ListItem>
                        );
                      })}
                    </UnorderedList>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                direction={{ xs: "column", md: "row" }}
                height={"fit-content"}
                gap={0}
              >
                <Stack gap={0} paddingRight={"20px"}>
                  {[
                    {
                      name: "Contact Details",
                      fontWeight: "700",
                    },
                    {
                      name: " Demo Company (AU)",
                      fontWeight: "400",
                    },
                    {
                      name: "23 Main Street",
                      fontWeight: "400",
                    },
                    {
                      name: "MARINEVILLE NSW 2000",
                      fontWeight: "400",
                    },
                  ].map((item, i) => {
                    return (
                      <Text
                        key={i}
                        fontSize={"11px"}
                        color={"customColor.black7"}
                        fontWeight={item.fontWeight}
                      >
                        {item.name}
                      </Text>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>

            <Text
              fontSize={"11px"}
              color={"customColor.black7"}
              marginBottom={"10px"}
              w={"70%"}
            >
              Headings: DRAFT INVOICE, TAX INVOICE, TAX INVOICE, CREDIT NOTE,
              STATEMENT, DRAFT PURCHASE ORDER, PURCHASE ORDER, DRAFT QUOTE,
              QUOTE, REMITTANCE ADVICE, RECEIPT
            </Text>
            <Text
              fontSize={"11px"}
              color={"customColor.black7"}
              marginBottom={"10px"}
              w={"70%"}
            >
              <b>Payment service</b> democo@for-demo-purpose-only.co
            </Text>
            <Stack
              fontSize={"11px"}
              color={"customColor.black7"}
              marginBottom={"20px"}
              w={"70%"}
              gap={0}
            >
              <Text>Terms & Payment Advice (Invoice and Statement):</Text>
              <Text>
                When paying by cheque, please complete this payment advice,
                detach and post to the address provided.
              </Text>
            </Stack>
            <Stack
              fontSize={"11px"}
              color={"customColor.black7"}
              marginBottom={"20px"}
              w={"70%"}
              gap={0}
            >
              <Text>
                Online payment preferred - use our account 083-234-12345678 or
                use the 'Pay online now' link to pay via PayPal with your credit
                card.
              </Text>
              <Text>Terms (Quotes):</Text>
              <Text color={"customColor.gray.64748B"}>(None added):</Text>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
