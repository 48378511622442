import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeeReferences {
  type AddReferences = {
    id?: string;
    name: string;
    position: string;
    email: string;
    contact: string;
    referenceType: "PERSONAL" | "PROFESSIONAL" | "OTHERS";
  };
  type FetchReferences = {
    id: string;
    name: string;
    position: string;
    email: string;
    contact: string;
    referenceType: "PERSONAL" | "PROFESSIONAL" | "OTHERS";
  };
}
module EmployeeReferences {
  const URL = "/employee-references";

  export function PostReferences(
    id: string,
    data: AddReferences,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetReferencesDetails(
    id: string,
    success: (response: Array<FetchReferences>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateReferences(
    id: string,
    data: Partial<AddReferences>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteReferences(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { EmployeeReferences };
