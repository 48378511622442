import axiosInstanceGateway from "./axiosInstance-gateway";

declare module Insurance {
  type AddInsurance = {
    id?: string;
    type: string;
    name: string;
    plan: string;
    amount?: number;
    coverage: string;
    roomCharges?: number;
    insuranceType: string;
    otherInformation: string;
    accidentalDeathAmount?: number;
    naturalDeathAmount?: number;
    opdAmount?: number;
  };
  type FetchInsurance = {
    id: string;
    type: string;
    name: string;
    plan: string;
    amount: number;
    coverage: string;
    roomCharges: number;
    insuranceType: string;
    otherInformation: string;
    accidentalDeathAmount?: number;
    naturalDeathAmount?: number;
    opdAmount?: number;
  };
}
module Insurance {
  const URL = "/business-insurances";

  export function PostInsurance(
    data: AddInsurance,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${URL}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetInsuranceDetails(
    success: (response: Array<FetchInsurance>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${URL}`)
      .then((response) => {
        success(response.data.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateInsurance(
    id: string,
    data: Partial<AddInsurance>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteInsurance(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { Insurance };
