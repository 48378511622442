import {
  Container,
  Heading,
  HStack,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { PayItemEarningApi } from "../../Api/PayItemEarning";

interface HeaderProps {
  Status?: string;
  weeklyIndex?: number;
  setWeeklyIndex?: (prevIndex: any) => void | null | undefined;
  splitedDays?: any;
  resetDayHoursForPage?: (newIndex: number) => void | null;
  lastDateOfWeek?: string;
  earningRateId?: string;
  onNavigateChunk?: (direction: "prev" | "next") => void;
  totalWeeksSum?: number;
  earningAdded?: Array<PayItemEarningApi.EarningById>;
}
export function Header({
  Status,
  weeklyIndex,
  setWeeklyIndex,
  splitedDays,
  resetDayHoursForPage,
  lastDateOfWeek,
  earningRateId,
  onNavigateChunk,
  totalWeeksSum,
  earningAdded,
}: HeaderProps) {
  const { employeesList } = useBusinessContext();

  const statusFilter: Array<{ value: string; label: string }> = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Approved",
      value: "APPROVED",
    },
    {
      label: "Awaiting Approval",
      value: "AWAITING_APPROVAL",
    },
    {
      label: "Pending Approval",
      value: "PENDING_APPROVAL",
    },
    {
      label: "Processed",
      value: "PROCESSED",
    },
    {
      label: "Overdue",
      value: "OVERDUE",
    },
    {
      label: "Draft",
      value: "DRAFT",
    },
  ];
  const status = statusFilter.find((item) => item.value === Status)?.label;
  const HeaderArray: Array<{
    title: string;
    name?: string;
    lefIcon?: any;
    rightIcon?: any;
  }> = [
    {
      title: "Earning Rate",
      name:
        earningAdded?.find((earning) => {
          return earning.id === earningRateId;
        })?.name || "--",
    },

    {
      title: "Status",
      name: status || "Draft",
    },
    {
      title: "Week ending",
      name: lastDateOfWeek!,
      lefIcon: <IoMdArrowDropleftCircle />,
      rightIcon: <IoMdArrowDroprightCircle />,
    },
    {
      title: "Total",
      name: String(totalWeeksSum || 0),
    },
  ];
  return (
    <Container
      p="0px"
      marginBottom={"30px"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      maxW={"container.lg"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      borderWidth="1px"
      borderStyle={"solid"}
      borderColor="#f6f6f6"
      overflowY={"auto"}
    >
      <HStack spacing={0}>
        {HeaderArray.map((item, index) => (
          <Stack
            key={index}
            flex={1} // Equal width
            flexBasis={0} // Consume 100% of available space
            borderRight="1px solid #e8ebee"
            padding="10px"
            gap={"0rem"}
          >
            <Heading
              fontSize={"12px"}
              margin={"0px 0px 4px"}
              padding={"1px 0px 0px"}
              fontWeight={"bold"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.cream",
              }}
            >
              {item.title}
            </Heading>
            <HStack gap={0}>
              {item.lefIcon && (
                <Stack>
                  {item.lefIcon && (
                    <IconButton
                      padding={0}
                      justifyContent={"flex-start"}
                      variant="ghost"
                      _hover={{
                        background: "none",
                      }}
                      size={"md"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      aria-label={""}
                      isDisabled={weeklyIndex === 0}
                      onClick={() => {
                        if (setWeeklyIndex) {
                          setWeeklyIndex((prevIndex: any) => {
                            const newIndex = prevIndex - 1;
                            if (resetDayHoursForPage) {
                              resetDayHoursForPage(newIndex);
                            }
                            return newIndex;
                          });
                        }
                      }}
                    >
                      {item.lefIcon}
                    </IconButton>
                  )}
                </Stack>
              )}

              <Heading
                fontSize={"18px"}
                margin={"0px 0px 4px"}
                padding={"1px 0px 0px"}
                fontWeight={"bold"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                {item.name ? item.name : "--"}
              </Heading>
              {item.rightIcon != "" && (
                <Stack>
                  {item.rightIcon && (
                    <IconButton
                      padding={0}
                      variant="ghost"
                      size={"md"}
                      justifyContent={"flex-end"}
                      _hover={{
                        background: "none",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      aria-label={""}
                      isDisabled={weeklyIndex === splitedDays.length - 1}
                      onClick={() => {
                        if (setWeeklyIndex) {
                          setWeeklyIndex((prevIndex: any) => {
                            const newIndex = prevIndex + 1;
                            if (resetDayHoursForPage) {
                              resetDayHoursForPage(newIndex);
                            }
                            return newIndex;
                          });
                        }
                        if (onNavigateChunk) {
                          onNavigateChunk("next");
                        }
                      }}
                    >
                      {item.rightIcon}
                    </IconButton>
                  )}
                </Stack>
              )}
            </HStack>
          </Stack>
        ))}
      </HStack>
    </Container>
  );
}
