import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { TbMinusVertical } from "react-icons/tb";
interface EmploymentProps {
  selectedEmployeeFilters: {
    empType: boolean;
    empBasis: boolean;
    payrollPayCalenderId: boolean;
    employeeGroupId: boolean;
    ratesId: boolean;
    department: boolean;
    jobTitle: boolean;
    isManagementStaff: boolean;
    manager: boolean;
    incomeType: boolean;
    joiningDate: boolean;
  };
  setSelectedEmployeeFilters: React.Dispatch<
    React.SetStateAction<{
      empType: boolean;
      empBasis: boolean;
      payrollPayCalenderId: boolean;
      employeeGroupId: boolean;
      ratesId: boolean;
      department: boolean;
      jobTitle: boolean;
      isManagementStaff: boolean;
      manager: boolean;
      incomeType: boolean;
      joiningDate: boolean;
    }>
  >;
  events: string;
}

export function EmploymentFilters({
  events,
  selectedEmployeeFilters,
  setSelectedEmployeeFilters,
}: EmploymentProps) {
  const form = useFormContext();
  const toggleFilter = (filterName: keyof typeof selectedEmployeeFilters) => {
    setSelectedEmployeeFilters((prev) => {
      const isCurrentlyChecked = prev[filterName];
      const updatedFilters = {
        ...prev,
        [filterName]: isCurrentlyChecked ? undefined : true,
      };

      if (!updatedFilters[filterName]) {
        form.setValue(filterName, undefined);
      }

      return updatedFilters;
    });
  };

  const selectedCount = Object.values(selectedEmployeeFilters).filter(
    Boolean
  ).length;
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              <Flex>
                <TbMinusVertical fontSize={"22px"} color={"hsl(0,0%,80%)"} />
                <ChevronDownIcon
                  fontSize={"22px"}
                  color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                  _hover={{
                    color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                  }}
                />
              </Flex>
            } //5A5A5A //383c40
            width={"100%"}
            textAlign={"start"}
            h={"38px"}
            _light={{
              bgColor: "customColor.white",
              color: "customColor.black7",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            fontWeight={"400"}
            border={"1px solid #e5e7eb"}
            borderRadius={"4px"}
            _hover={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _active={{
              color: "black",
              bg: "#f2f3f4",
              borderWidth: "1px",

              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
          >
            {selectedCount > 0 ? `${selectedCount} Selected` : "Filters"}
          </MenuButton>

          <MenuList minWidth="200px" maxH={"300px"} overflowX={"auto"}>
            {events === "EMPLOYMENT_DETAILS" && (
              <>
                {[
                  {
                    name: "Employment Type",
                    onChange: () => toggleFilter("empType"),
                    isChecked: !!selectedEmployeeFilters.empType,
                  },

                  {
                    name: "Employment Basis",
                    onChange: () => toggleFilter("empBasis"),
                    isChecked: !!selectedEmployeeFilters.empBasis,
                  },
                  {
                    name: "Payroll Calender",
                    onChange: () => toggleFilter("payrollPayCalenderId"),
                    isChecked: !!selectedEmployeeFilters.payrollPayCalenderId,
                  },
                  {
                    name: "Employee Group",
                    onChange: () => toggleFilter("employeeGroupId"),
                    isChecked: !!selectedEmployeeFilters.employeeGroupId,
                  },
                  {
                    name: "Earning Rate",
                    onChange: () => toggleFilter("ratesId"),
                    isChecked: !!selectedEmployeeFilters.ratesId,
                  },
                  {
                    name: "Employee Income Type",
                    onChange: () => toggleFilter("incomeType"),
                    isChecked: !!selectedEmployeeFilters.incomeType,
                  },
                  {
                    name: "start Date",
                    onChange: () => toggleFilter("joiningDate"),
                    isChecked: !!selectedEmployeeFilters.joiningDate,
                  },
                  {
                    name: "Department",
                    onChange: () => toggleFilter("department"),
                    isChecked: !!selectedEmployeeFilters.department,
                  },
                  {
                    name: "Job Title",
                    onChange: () => toggleFilter("jobTitle"),
                    isChecked: !!selectedEmployeeFilters.jobTitle,
                  },
                  {
                    name: "Is Management Staff",
                    onChange: () => toggleFilter("isManagementStaff"),
                    isChecked: !!selectedEmployeeFilters.isManagementStaff,
                  },
                  {
                    name: "Manager",
                    onChange: () => toggleFilter("manager"),
                    isChecked: !!selectedEmployeeFilters.manager,
                  },
                ].map((item, i) => {
                  return (
                    <MenuItem key={i}>
                      <Checkbox
                        onChange={item.onChange}
                        isChecked={item.isChecked}
                      >
                        <Text
                          fontSize={".845rem"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontWeight={"600"}
                        >
                          {item.name}
                        </Text>
                      </Checkbox>
                    </MenuItem>
                  );
                })}
              </>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
}
