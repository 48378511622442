import { Container, FormLabel, Stack, Switch, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Payroll } from "./Payroll";

export function Home() {
  const [showDescription, setShowDescription] = useState(false);
  const handleSwitchChange = () => {
    setShowDescription((prev) => !prev);
  };

  return (
    <Stack gap={0} paddingBottom={"20px"}>
      <Stack boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"} h={"78px"}>
        <Stack maxW={"100%"} padding={0}>
          <Stack direction={"row"} gap={0}>
            <Stack flex={1}>
              <Text
                fontSize={"17px"}
                fontWeight={"bold"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
                margin={"0px 4px 0px 16px"}
                padding={"16px 0px"}
              >
                All Reports
              </Text>
            </Stack>
            <Stack
              direction={"row"}
              gap={0}
              alignItems={"center"}
              marginRight={"16px"}
            >
              <FormLabel
                htmlFor="email-alerts"
                mb="0"
                fontSize={"15px"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
                padding={"0"}
              >
                Show description
              </FormLabel>
              <Switch size="lg" onChange={handleSwitchChange} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Container
        maxW={"1200"}
        padding={"32px 0px 0px 0px"}
        minH={"100px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack padding={0}>
          {/* <FinancialPerformance showDescription={showDescription} />
          <FinancialStatement showDescription={showDescription} />
          <Payable showDescription={showDescription} /> */}
          <Payroll showDescription={showDescription} />
          {/* <Projects showDescription={showDescription} />
          <Reconciliations showDescription={showDescription} />
          <TaxAndBalnace showDescription={showDescription} />
          <Transactions showDescription={showDescription} /> */}
        </Stack>
      </Container>
    </Stack>
  );
}
