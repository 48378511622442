import moment from "moment";
import { useEffect, useState } from "react";

export default function AnalogClock() {
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(moment());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);
  const seconds = time.seconds();
  const minutes = time.minutes();
  const hours = time.hours();

  const secondsDegrees = (seconds / 60) * 360;
  const minutesDegrees = (minutes / 60) * 360;
  const hoursDegrees = ((hours % 12) / 12) * 360;

  return (
    <svg width="200" height="200" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="48"
        fill="white"
        stroke="black"
        strokeWidth="1"
      />
      {Array.from({ length: 60 }).map((_, index) => (
        <line
          x1={50 + 45 * Math.cos((index * 6 * Math.PI) / 180)}
          y1={50 + 45 * Math.sin((index * 6 * Math.PI) / 180)}
          x2={
            50 +
            (index % 5 === 0 ? 40 : 44) * Math.cos((index * 6 * Math.PI) / 180)
          }
          y2={
            50 +
            (index % 5 === 0 ? 40 : 44) * Math.sin((index * 6 * Math.PI) / 180)
          }
          stroke={index % 5 === 0 ? "black" : "lightgrey"}
          strokeWidth={index % 5 === 0 ? "1.5" : "0.5"}
        />
      ))}
      <line
        x1="50"
        y1="50"
        x2="50"
        y2="15"
        transform={`rotate(${hoursDegrees}, 50, 50)`}
        stroke="black"
        strokeWidth="3"
      />
      <line
        x1="50"
        y1="50"
        x2="50"
        y2="10"
        transform={`rotate(${minutesDegrees}, 50, 50)`}
        stroke="black"
        strokeWidth="2"
      />
      <line
        x1="50"
        y1="50"
        x2="50"
        y2="5"
        transform={`rotate(${secondsDegrees}, 50, 50)`}
        stroke="red"
        strokeWidth="1"
      />
      <circle cx="50" cy="50" r="2" fill="black" />
    </svg>
  );
}
