import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { PayRun } from "../../../Api/PayRun";

interface ResetPayRunProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  setLastUpdated: (date: number) => void;
}
export function ResetPayRun({
  isOpen,
  onClose,
  id,
  setLastUpdated,
}: ResetPayRunProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"380px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"15px 5px 15px 15px"}
          fontWeight={"700"}
          lineHeight={"1.75rem"}
          h={"52px"}
        >
          Reset Pay Run
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            color: "customColor.black7",
          }}
          border={"1px solid #E5E5E5"}
          fontSize={"12px"}
        >
          Are you sure you want to reset this pay run?
        </ModalBody>

        <ModalFooter padding={"0px"}>
          <ButtonGroup
            size="sm"
            marginTop="10px"
            marginBottom={"10px"}
            marginRight={"10px"}
          >
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                PayRun.ResetPayRun(
                  id,
                  (success) => {
                    toast({
                      title: "Pay Run has been reset.",
                      status: "success",
                    });
                    setIsLoading(false);
                    setLastUpdated(Date.now());
                    onClose();
                  },
                  (err) => {
                    toast({
                      title: "Pay Run has been reset.",
                      status: "error",
                      description: err,
                    });
                    setIsLoading(false);
                    onClose();
                  }
                );
              }}
            >
              Yes
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              No
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
