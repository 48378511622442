import {
  HStack,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { AllUsers } from "./Tabs/AllUsers/AllUsers";
import { LoginHistory } from "./Tabs/LoginHistory";

export function User() {
  //   const navigate = useNavigate();
  return (
    <Stack h="calc(100vh - 70px)" overflowY="auto">
      <Tabs w="100%">
        <HStack
          justifyContent="space-between"
          borderBottom={"1px solid #d6dade"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
        >
          <Stack flex={1} direction={{ xs: "column", md: "row" }}>
            <Stack gap={0} padding={"16px 0px"} margin={"0px 4px 0px 16px"}>
              <Link
                color={"primary.950"}
                fontWeight={"400;"}
                fontSize={"13px"}
                href="organisationSettings"
              >
                Organisation Settings
              </Link>
              <Text
                fontWeight={"bold"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize={"17px"}
                padding={"0px 12px 0px 0px"}
              >
                Users
              </Text>
            </Stack>

            <TabList padding={0}>
              <Tab fontSize={"15px"} padding={"8px 20px "}>
                Current User
              </Tab>
              <Tab fontSize={"15px"} padding={"8px 20px "}>
                Login History
              </Tab>
            </TabList>
          </Stack>
        </HStack>
        <TabPanels>
          <TabPanel padding={0}>
            <AllUsers />
          </TabPanel>
          <TabPanel padding={0}>
            <LoginHistory />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
