import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight, FaRegClock } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { OverviewTable } from "./OverviewTable";
import TimesheetEdit from "./TimeSheetEdit";

export function TimesheetOver() {
  const navigate = useNavigate();
  const editModal = useDisclosure();
  return (
    <Stack
      gap={0}
      minW={"980px"}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Box
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderBottom={"1px solid #cfd2d4"}
      >
        <Container
          h="76px"
          maxW={"100%"}
          padding={"16px 20px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink
                href="Timesheet"
                fontSize={"13px"}
                color={"primary.950"}
              >
                Timesheet
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href=""
                fontSize={"11px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                padding={"0px 20px 0px 0px"}
              ></BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <HStack justifyContent={"space-between"}>
            <HStack gap={0} alignItems={"center"}>
              <Heading
                fontWeight={"bold"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize={"18px"}
                paddingRight={"16px"}
                margin={"0px 4px 0px 0px"}
              >
                {`Timesheets for 7 - 30 Jun 2024 `}
              </Heading>
              <Tag
                bgColor={"#FDD3A6"}
                _light={{
                  color: "customColor.black7",
                }}
                h={"20px"}
                borderRadius={"4px"}
                textAlign={"center"}
                fontSize={"13px"}
              >
                Pending approval
              </Tag>
            </HStack>
            <HStack>
              <Button
                variant={"outline"}
                borderRadius={"3px"}
                fontSize={"13px"}
                fontWeight={"bold"}
                marginBottom={"5px"}
                border={"1px solid #dcdee4"}
                color={"#DC3246"}
                padding={"7.52px 12px"}
                _hover={{
                  bg: "#f2f3f4",
                }}
                onClick={() => {
                  editModal.onOpen();
                }}
              >
                Edit
              </Button>
              <TimesheetEdit
                isOpen={editModal.isOpen}
                onClose={editModal.onClose}
              />
              <Menu>
                <MenuButton
                  as={Button}
                  padding={"6px 10px"}
                  backgroundColor={"transparent"}
                  w={"40px"}
                  color={"black"}
                  display={{
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  }}
                  _hover={{
                    backgroundColor: "transparent",
                  }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                  borderRadius={"full"}
                  _focus={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Icon as={FiMoreVertical} fontSize={"20px"} />
                </MenuButton>
                <MenuList style={{ minWidth: "140px" }} padding={"12px 0px"}>
                  <MenuItem
                    padding={"8px 20px"}
                    fontSize={"13.44px"}
                    border={"1px solid #dcdee4"}
                    _dark={{
                      color: "customColor.white",
                    }}
                    onClick={() => {
                      navigate(-2);
                    }}
                  >
                    Close
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
        </Container>
      </Box>

      <Container
        maxW={"container.xl"}
        padding={"40px 26px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text
          fontWeight={"700"}
          fontSize={"21px"}
          marginBottom={"24px"}
          _light={{
            color: "customColor.black7",
          }}
        >
          Timesheet overview
        </Text>
        <Stack
          w={"280px"}
          direction={"row"}
          bg={"#fff"}
          gap={0}
          boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
          marginBottom={"16px"}
          borderRadius={"3px"}
        >
          <Stack flex={1} padding={"20px"} gap={0}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Total submitted
            </Text>
            <Text
              fontSize={"21px"}
              marginTop={"8px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              7 h
            </Text>
          </Stack>
          <Stack flex={1} padding={"20px"} gap={0}>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Total reviewed
            </Text>
            <Text
              fontSize={"21px"}
              marginTop={"8px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              -
            </Text>
          </Stack>
        </Stack>
        <HStack gap={0} justifyContent={"space-between"} padding={"24px 0px"}>
          <Text
            fontWeight={"700"}
            fontSize={"21px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Weekly Breakdown
          </Text>

          <HStack gap={0}>
            <Button
              bg={"white"}
              leftIcon={<FaChevronLeft fontSize={"12px"} />}
              _hover={{
                bg: "none",
              }}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              borderLeftRadius={"4px"}
              padding={"12px 16px"}
              h={"32px"}
              textColor={"customColor.black7"}
            >
              Previous
            </Button>
            <Button
              bg={"white"}
              alignItems={"center"}
              rightIcon={<FaChevronRight fontSize={"12px"} />}
              _hover={{
                bg: "none",
              }}
              borderLeft={"none"}
              h={"32px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              padding={"12px 16px"}
              textColor={"#3454d1"}
              borderRightRadius={"4px"}
            >
              Next Week
            </Button>
          </HStack>
        </HStack>
        {/* New component */}

        <Stack
          borderRadius={"3px"}
          boxShadow={"0 0 0 1px rgba(0,10,30,.2)"}
          marginBottom={"40px"}
          gap={0}
        >
          <Stack
            direction={"row"}
            borderBottom={"1px solid #ccced2"}
            justifyContent={"space-between"}
            padding={"16px 20px 0px"}
          >
            <Text
              fontSize={"21px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              margin={"0px 0px 16px"}
            >
              Sat 1 - Fri 7 Jun
            </Text>
            <HStack margin={"0px 0px 16px"} spacing={7}>
              <Stack direction={"row"}>
                <Text
                  fontSize={"17px"}
                  fontWeight={"400"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Submitted:
                </Text>
                <Text
                  fontSize={"17px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  7 h
                </Text>
              </Stack>
              <Stack direction={"row"}>
                <Text
                  fontSize={"17px"}
                  fontWeight={"400"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Reviewed:
                </Text>
                <Text
                  fontSize={"17px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  -
                </Text>
              </Stack>
            </HStack>
          </Stack>
          {/* here will be a component  */}
          <OverviewTable />
          <Stack
            padding={"32px 0px 20px"}
            alignItems="center"
            justifyContent="center"
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontSize={"21px"}
              fontWeight={"700"}
            >
              Reviewed hours will show here
            </Text>
            <Icon
              as={FaRegClock}
              _light={{
                color: "customColor.black7",
              }}
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
