import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";
import { MonthlyCalendar } from "./Calendar";

import moment, { Moment } from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../Api/EmployeeLeave";
import { Leaves } from "../../Api/Leaves";
import BulkPayPeriod from "./BulkPayPeriod";
import { Filter } from "./Components/Filter";
import { Sorting } from "./Components/Sorting";
import LeaveTag from "./LeaveTag";
interface CurrentProps {
  setLastUpdated: (timeStamp: number) => void;
  selectedLeaveId: string | undefined;
  fetchLeaves: Array<PayItemLeaveApi.LeaveById>;
  // allLeaves: Array<Leaves.fetchBusinessLeaves>;
  lastUpdated: number;
}
export function Current({
  setLastUpdated,
  selectedLeaveId,
  fetchLeaves,
  // allLeaves,
  lastUpdated,
}: CurrentProps) {
  const leaveModal = useDisclosure();
  const form = useFormContext<Leaves.CreateLeavesBulk>();

  const watchSelectedLeaveCategory = useWatch({
    control: form.control,
    name: "leaveRequest.leaveCategory",
  });
  const watchSelectedLeaveType = useWatch({
    control: form.control,
    name: "leaveRequest.payrollPayItemLeaveId",
  });
  const [selectedRange, setSelectedRange] = useState<{
    start?: Moment | null;
    end?: Moment | null;
  }>({
    start: null,
    end: null,
  });
  const handleDateRangeSelect = (range: {
    start?: Moment | null;
    end?: Moment | null;
  }) => {
    setSelectedRange(range);
  };

  const numDays =
    selectedRange.start && selectedRange.end
      ? selectedRange.end.diff(selectedRange.start, "days") + 1
      : 0;
  const LeaveCategoryOptions = [
    { value: "NORMAL_LEAVE", label: "Normal Leave" },
    { value: "CASHOUT_LEAVE", label: "Cash out Leave" },
  ];
  const { employeesList } = useBusinessContext();
  const toast = useToast();
  const [selectedPayperiods, setSelectedPayperiods] = useState<
    Array<EmployeeLeave.FetchBulkPayPeriods>
  >([]);
  const employeeIds = useWatch({
    control: form.control,
    name: "empIds",
  });
  const PayoutDate = useWatch({
    control: form.control,
    name: "leaveRequest.payoutDate",
  });
  // const [selectedPayperiodsString, setSelectedPayperiodsStrings] = useState<
  //   Array<{
  //     empId: string;
  //     string: Array<string>;
  //   }>
  // >([]);
  const [pendingLeaveIds, setPendingLeaveIds] = useState<Array<string>>();
  const fetchBulkNormalPayPeriods = () => {
    if (employeeIds.length > 0 && selectedRange.start && selectedRange.end) {
      const startDate = moment(selectedRange.start).clone();
      const endDate = moment(selectedRange.end).clone();
      if (startDate > endDate) {
        toast({
          title: "Error",
          description: "End date should be greater than start date",
          status: "error",
        });
      } else {
        EmployeeLeave.BulkPayPeriods(
          {
            empIds: employeeIds,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
          },
          (success) => {
            form.setValue("employeesPayPeriods", success);
            setSelectedPayperiods(success);
          },
          (error) => {
            toast({
              title: "Error",
              description: error,
              status: "error",
            });
          }
        );
      }
    }
  };
  const fetchBulkCashoutPayPeriods = () => {
    if (employeeIds.length > 0 && PayoutDate) {
      const startDate = moment(PayoutDate).clone();
      const endDate = moment(PayoutDate).clone();

      EmployeeLeave.BulkPayPeriods(
        {
          empIds: employeeIds,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
        (success) => {
          form.setValue("employeesPayPeriods", success);
          setSelectedPayperiods(success);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  };
  useEffect(() => {
    if (watchSelectedLeaveCategory === "NORMAL_LEAVE") {
      fetchBulkNormalPayPeriods();
    } else if (watchSelectedLeaveCategory === "CASHOUT_LEAVE") {
      fetchBulkCashoutPayPeriods();
    }
  }, [
    selectedRange.start,
    selectedRange.end,
    PayoutDate,
    watchSelectedLeaveCategory,
  ]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(25);
  const [count, setCount] = useState<number>(1);
  const [pendingLeaves, setPendingLeaves] = useState<
    Array<Leaves.fetchBusinessLeaves>
  >([]);
  useEffect(() => {
    Leaves.fetchBusinessLeaves(
      {
        status: "PENDING",
        page: pageNumber,
        limit: pageLimit,
      },
      (response) => {
        setPendingLeaves(response?.data);
        setPendingLeaveIds(response?.data.map((leave: any) => leave.id));
        setCount(response?.count);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [pageNumber, lastUpdated]);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const pageCount = Math.ceil(count / pageLimit);
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  return (
    <Stack
      // h={"calc(100vh - 130px)"}
      // overflowY="auto"
      //  h={{ sm: "calc(100vh - 100px)", md: "calc(100vh - 60px)" }}

      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      paddingBottom={"20px"}
    >
      <Container
        maxW={"container.xl"}
        padding={0}
        marginTop="16px"
        paddingRight={"20px"}
        paddingLeft={"20px"}
      >
        <Text
          _light={{
            color: "customColor.black7",
          }}
          fontSize="18px"
          fontWeight={"600"}
          marginBottom={"8px"}
        >
          Select Employees
        </Text>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Employee is required",
            },
            onBlur:
              watchSelectedLeaveType === "NORMAL_LEAVE"
                ? fetchBulkNormalPayPeriods
                : fetchBulkCashoutPayPeriods,
          }}
          control={form.control}
          name="empIds"
          render={({ field, fieldState }) => {
            return (
              <ReactSelect
                {...field}
                withValidation
                isMulti
                isInvalid={fieldState.invalid}
                ErrorMessage="Please Select Employee"
                placeholder="Select Employee"
                options={employeesList}
                value={
                  employeesList?.filter((emp: any) =>
                    field.value?.includes(emp.id)
                  ) ?? []
                }
                onChange={(val: any) => {
                  field.onChange(val.map((emp: any) => emp.id));
                  if (val.length === 0) {
                    form.setValue("employeesPayPeriods", []);
                  }
                }}
                getOptionLabel={(option: any) => {
                  return `${option?.firstName} ${option?.lastName}`;
                }}
                getOptionValue={(option: any) => option.id}
              />
            );
          }}
        />
        <Stack
          marginTop={"10px"}
          alignItems="stretch"
          gap={0}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <Stack flex={1} padding={"0px 12px 0px 0px"}>
            <MonthlyCalendar onDateSelect={handleDateRangeSelect} />
          </Stack>
          <Stack flex={1} padding={"0px  0px 0px 12px"} gap={0}>
            <Text
              marginBottom={".5rem"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={".8125rem"}
              fontWeight={"600"}
            >
              LEAVE CATEGORY
            </Text>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Leave Category is required",
                },
              }}
              control={form.control}
              name="leaveRequest.leaveCategory"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  key={field.value}
                  placeholder="Leave Category status Type"
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Leave Category status Type"
                  withValidation
                  options={LeaveCategoryOptions}
                  value={LeaveCategoryOptions.find(
                    (op) => op.value === field.value
                  )}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value);
                    form.setValue("employeesPayPeriods", []);
                    setSelectedPayperiods([]);
                    form.setValue("leaveRequest.payoutDate", "");
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
            {(watchSelectedLeaveCategory === "CASHOUT_LEAVE" ||
              watchSelectedLeaveCategory === "NORMAL_LEAVE") && (
              <Stack gap={0}>
                <Text
                  marginBottom={".5rem"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={".8125rem"}
                  fontWeight={"600"}
                >
                  LEAVE TYPE
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Employee Status is required",
                    },
                  }}
                  control={form.control}
                  name="leaveRequest.payrollPayItemLeaveId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={fetchLeaves?.map((leave: any) => ({
                        value: leave.id,
                        label: leave.name,
                      }))}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Status"
                      placeholder="Select..."
                      value={
                        fetchLeaves?.find(
                          (leave: any) => leave.id === field.value
                        )
                          ? {
                              value: field.value,
                              label: fetchLeaves?.find(
                                (leave: any) => leave.id === field.value
                              )?.name,
                            }
                          : null
                      }
                      onChange={(selectedOption: any) =>
                        field.onChange(selectedOption.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                <Text
                  marginBottom={".5rem"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={".8125rem"}
                  fontWeight={"600"}
                >
                  REASON
                </Text>
                <Controller
                  control={form.control}
                  name="leaveRequest.description"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        bg: "#fff",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        marginBottom: "8px",
                      }}
                      placeholder="Reason for leave"
                    />
                  )}
                />
                {watchSelectedLeaveCategory === "CASHOUT_LEAVE" && (
                  <Stack gap={0}>
                    <Text
                      marginBottom={".5rem"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={".8125rem"}
                      fontWeight={"600"}
                    >
                      PAYOUT DATE
                    </Text>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Payout Date is required",
                        },
                      }}
                      control={form.control}
                      name="leaveRequest.payoutDate"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            bg: "#fff",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "date",

                            borderTopRadius: "3px",
                            onChange: (e) => {
                              field.onChange(e.target.value);
                            },
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            {(watchSelectedLeaveCategory === "NORMAL_LEAVE" ||
              watchSelectedLeaveCategory === "CASHOUT_LEAVE") && (
              <Stack>
                <HStack marginTop={"20px"} borderBottom={"1px solid grey"}>
                  <Heading
                    display={"flex"}
                    flex={1}
                    fontSize={".8125rem"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    paddingBottom={"5px"}
                  >
                    Pay Period
                  </Heading>

                  <Heading
                    fontSize={".8125rem"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    paddingBottom={"5px"}
                  >
                    {
                      fetchLeaves.find((op) => op.id === watchSelectedLeaveType)
                        ?.unitType
                    }
                  </Heading>
                </HStack>
                {selectedPayperiods.map((payPeriod, i) => (
                  <Box key={i}>
                    <Heading
                      fontSize={".8125rem"}
                      fontWeight={"600"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      paddingBottom={"5px"}
                    >
                      {employeesList?.find((emp) => emp.id === payPeriod.empId)
                        ?.firstName +
                        "" +
                        form.getValues(`employeesPayPeriods.${i}.payPeriods`)
                          ?.length}
                    </Heading>
                    <BulkPayPeriod index={i} />
                  </Box>
                ))}
              </Stack>
            )}
            {watchSelectedLeaveCategory === "NORMAL_LEAVE" && (
              <Stack marginTop={"5px"}>
                <Stack justifyContent={"space-between"} direction={"row"}>
                  <Text
                    fontSize={".8125rem"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    You have selected {selectedRange.start?.format("MMMM D")} -{" "}
                    {selectedRange.end?.format("MMMM D")}
                  </Text>
                  <Text
                    fontSize={".8125rem"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    {numDays}
                  </Text>
                </Stack>
                <Text
                  fontSize={".8125rem"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  That's a total of {numDays} days.
                </Text>
              </Stack>
            )}

            <Stack marginTop={"15px"} alignItems={"flex-end"}>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={form.handleSubmit((data) => {
                  const startDate =
                    form.getValues("leaveRequest.leaveCategory") ===
                    "NORMAL_LEAVE"
                      ? moment(selectedRange.start).clone().format("YYYY-MM-DD")
                      : undefined;
                  const endDate =
                    form.getValues("leaveRequest.leaveCategory") ===
                    "NORMAL_LEAVE"
                      ? moment(selectedRange.end).clone().format("YYYY-MM-DD")
                      : undefined;
                  const totalQuantity = data?.employeesPayPeriods?.reduce(
                    (acc: number, curr: any) => {
                      return (
                        acc +
                        curr.payPeriods.reduce(
                          (a: number, c: any) => a + c.quantity,
                          0
                        )
                      );
                    },
                    0
                  );
                  const bulkData = {
                    empIds: data.empIds,
                    leaveRequest: {
                      ...data.leaveRequest,
                      startDate: startDate,
                      endDate: endDate,
                      quantity: totalQuantity ?? 0,
                      payoutDate: data.leaveRequest.payoutDate,
                    },
                    employeesPayPeriods: data.employeesPayPeriods,
                  };
                  Leaves.CreateLeavesBulk(
                    bulkData,
                    (message) => {
                      setLastUpdated(Date.now());
                      form.reset({
                        empIds: [],
                        employeesPayPeriods: [],
                        leaveRequest: {
                          startDate: "",
                          endDate: "",
                          quantity: 0,
                          description: "",
                          leaveCategory: "NORMAL_LEAVE",
                          payrollPayItemLeaveId: "",
                        },
                      });
                      setSelectedRange({
                        start: null,
                        end: null,
                      });
                      setSelectedPayperiods([]);
                      toast({
                        title: "Success",
                        description: message,
                        status: "success",
                      });
                    },
                    (err) => {
                      toast({
                        title: "Error",
                        description: err,
                        status: "error",
                      });
                    }
                  );
                })}
              >
                SUBMIT REQUEST
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <HStack p="12px 0px 0px">
          <Stack display={"flex"} flex={1}>
            <InputGroup bg={"rgba(0,10,30,.05)"}>
              <InputLeftElement>
                <Icon as={FiSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
              />
            </InputGroup>
          </Stack>
          <HStack>
            <Filter />
            <Sorting />
          </HStack>
        </HStack>

        <Stack gap={0}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p="24px 0px 16px"
            margin={"0px -1px"}
          >
            <Text
              fontSize={"17px"}
              fontWeight={"600"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black7",
              }}
            >
              To review
            </Text>
            <Button
              variant={"link"}
              color={"primary.950"}
              fontSize={"13px"}
              padding={"5px 12px"}
              borderRadius={"3px"}
              lineHeight={"var(--x-line-height-small, 1.25rem)"}
              minH={"var(--x-control-size-small,32px)"}
              fontWeight={"600"}
              onClick={() => {
                Leaves.ApproveAllLeaves(
                  { leaveIds: pendingLeaveIds ?? [], status: "APPROVED" },
                  () => {
                    setLastUpdated(Date.now());
                    toast({
                      title: "Success",
                      description: "All leaves have been approved",
                      status: "success",
                    });
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                  }
                );
              }}
            >
              Approve all
            </Button>
          </Stack>
          {/* {allLeaves.find((leaveData) => leaveData.status === "APPROVED") ? (
              <LeaveTag leave={} />
            ) : (
              <Text>No pending leave requests</Text>
            )} */}
          {pendingLeaves && pendingLeaves.length > 0 ? (
            pendingLeaves.map((leaveData, i) => (
              <LeaveTag
                leave={leaveData}
                key={i}
                setlastUpdated={setLastUpdated}
                FetchLeaves={fetchLeaves}
                lastUpdated={lastUpdated}
              />
            ))
          ) : (
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontSize={".9975rem"}
            >
              No pending leave requests
            </Text>
          )}
        </Stack>
        {pendingLeaves && pendingLeaves.length > 0 && (
          <Stack
            p={"25px"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="back"
                border={"1px solid #e8eaf2"}
                borderRadius={"full"}
                backgroundColor="transparent"
                _hover={{
                  backgroundColor: "#3454D1",
                  color: "white",
                }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
                icon={
                  <FaArrowLeft
                    size={15}
                    color={
                      isHovered2
                        ? "#FFFFFF"
                        : pageNumber <= 1 || pageNumber > pageCount
                        ? "#828ea0"
                        : "#283c50"
                    }
                  />
                }
                isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                onClick={() => {
                  if (pageNumber > 1 && pageNumber <= pageCount) {
                    pageNumberInputRef.current?.value &&
                      (pageNumberInputRef.current.value = String(
                        pageNumber - 1
                      ));
                    setPageNumber(pageNumber - 1);
                  }
                }}
              />
              <HStack spacing="13px">{renderPageNumbers()}</HStack>
              <IconButton
                aria-label="right"
                backgroundColor="transparent"
                border={"1px solid #e8eaf2"}
                borderRadius={"full"}
                _hover={{
                  backgroundColor: "#3454D1",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                icon={
                  <FaArrowRight
                    size={15}
                    color={
                      isHovered
                        ? "#FFFFFF"
                        : pageNumber >= pageCount || pageNumber < 1
                        ? "#828ea0"
                        : "#283c50"
                    }
                    style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                  />
                }
                isDisabled={pageNumber >= pageCount || pageNumber < 1}
                onClick={() => {
                  if (pageNumber < pageCount && pageNumber >= 1) {
                    pageNumberInputRef.current?.value &&
                      (pageNumberInputRef.current.value = String(
                        pageNumber + 1
                      ));
                    setPageNumber(pageNumber + 1);
                  }
                }}
              />
            </HStack>
          </Stack>
        )}
      </Container>
    </Stack>
  );
}
