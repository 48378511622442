import { Checkbox, HStack, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";

export function Project() {
  return (
    <Stack
      padding={"24px"}
      border={"1px solid lightgrey"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      borderRadius={"5px"}
      w={{ md: "100%", lg: "850px" }}
      display={"none"}
    >
      <HStack gap={0}>
        <Checkbox size={"lg"} isDisabled={true} isChecked={true}></Checkbox>
        <Text
          fontSize={"15px"}
          fontWeight={"700"}
          color={"#000A1E80"}
          margin={"0px 8px"}
          cursor={"not-allowed"}
        >
          Projects
        </Text>
      </HStack>
      <Text padding={"8px 0px"} fontSize={"15px"} fontWeight={"700"}>
        How much access do you need?
      </Text>
      <Stack
        gap={0}
        direction={"row"}
        //   columns={{ base: 1, md: 3 }}
        border={"1px solid #80858f"}
        borderRadius={"3px"}
      >
        <Stack
          borderRight={"1px solid #80858f"}
          minH={"40px"}
          bg={"#e6e7e9"}
          flex={1}
          color={"#59606d"}
          padding={"7px 12px"}
          cursor={"not-allowed"}
        >
          <Text>Limited</Text>
        </Stack>
        <Stack
          borderRight={"1px solid #80858f"}
          minH={"40px"}
          flex={1}
          bg={"#e6e7e9"}
          color={"#59606d"}
          cursor={"not-allowed"}
          padding={"7px 12px"}
        >
          <Text>Standard</Text>
        </Stack>
        <Stack flex={1}>
          <Text
            minH={"40px"}
            padding={"7px 12px"}
            bg={"#80858f"}
            color={"#fff"}
            cursor={"not-allowed"}
          >
            Admin
          </Text>
        </Stack>
      </Stack>
      <Stack
        bg={"rgb(242, 243, 244)"}
        margin={"16px 0px 0px"}
        padding={"4px"}
        direction={"row"}
        gap={0}
      >
        <Stack padding={"12px 0px 0px 8px"}>
          {" "}
          <Icon as={AiFillInfoCircle} />
        </Stack>
        <Stack padding={"8px 12px"}>
          <Text
            lineHeight={"1.5rem"}
            fontSize={"13px"}
            color={"customColor.black2"}
            _dark={{
              color: "customColor.dark.200",
            }}
            margin={"-5px 0px 0px 12px"}
          >
            This role has the same access to Projects as Standard and includes
            staff and project cost information. It's ideal for business owners
            and admin staff.
            <Link
              textDecoration={"underline"}
              color={"#000A1EBF"}
              _hover={{
                color: "#0078C8",
              }}
            >
              Learn more about user roles
              <Icon as={BsBoxArrowUpRight} />
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
