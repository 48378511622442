import {
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { forwardRef, Fragment, useMemo } from "react";
import { ShiftReport } from "../../API/ShiftReport";
import { Column } from "./interfaces";

interface ReportingTableProps {
  groupBy?: ShiftReport.GroupingReportPayload["groupBy"];
  startDate: Moment;
  endDate: Moment;
  selectedColumns: Array<Column>;
  data:
    | ShiftReport.WithoutGroupingReportResponse
    | ShiftReport.GroupingReportResponse;
  reportType: string;
}
export const ReportingTable = forwardRef<HTMLDivElement, ReportingTableProps>(
  (
    { endDate, startDate, selectedColumns, groupBy, data, reportType }: any,
    ref: any
  ) => {
    const numOfdays = useMemo(() => {
      return endDate.diff(startDate, "days");
    }, [startDate, endDate]);

    const days = useMemo(() => {
      const days = [];
      for (let i = 0; i <= numOfdays; i++) {
        days.push(startDate.clone().add(i, "days"));
      }
      return days;
    }, [startDate, endDate, numOfdays]);

    const dataType = useMemo(() => {
      if (Array.isArray(data)) {
        return "NOT_GROUPED";
      } else {
        return "GROUPED";
      }
    }, [data]);

    const selectedColumnsFiltered = useMemo(() => {
      const columns = selectedColumns.filter(
        (sc: any) => sc.groupingKey !== groupBy
      );

      return columns;
    }, [selectedColumns, groupBy]);

    const sortedFilteredColumns = useMemo(() => {
      return selectedColumnsFiltered.sort(
        (a: any, b: any) => a.position - b.position
      );
    }, [selectedColumnsFiltered]);

    const sortedColumns = useMemo(() => {
      return selectedColumns.sort((a: any, b: any) => a.position - b.position);
    }, [selectedColumns]);

    return (
      // <Stack>
      <TableContainer
        style={{
          overflowX: "auto",
          overflowY: "auto",
          maxHeight: "calc(100vh - 300px)",
        }}
        ref={ref}
      >
        <Table
          variant="simple"
          w="100%"
          overflowX="auto"
          id={"scrollable-component"}
        >
          <Thead h={"36.8px"}>
            <Tr
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.150",
              }}
              borderTopWidth={"1px"}
              borderTopColor="borders.tableBorder"
            >
              {(dataType === "GROUPED"
                ? sortedFilteredColumns
                : sortedColumns
              ).map((column: any, i: any) => {
                return (
                  <Th
                    key={i}
                    padding="8px 26px 8px 15px"
                    borderBottomColor="borders.tableBorder"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                  >
                    {column.colLabel}
                  </Th>
                );
              })}

              {days.map((day, i) => (
                <Th
                  key={`day-${i}`}
                  // w={"180px"}
                  // // color={'#0000A1EBF'}

                  // h={"40px"}
                  // textAlign={"center"}
                  // padding={"8px 12px"}
                  // px="12px"
                  // borderBottomColor="#f5f6f8"
                  // _dark={{
                  //   color: "customColor.dark.150",
                  // }}
                  // _light={{
                  //   color: "customColor.black",
                  // }}
                  // fontWeight={600}
                  // whiteSpace="nowrap"
                  // fontSize="14.4px"
                  // textTransform="none"
                  padding="8px 26px 8px 15px"
                  borderBottomColor="borders.tableBorder"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontWeight={700}
                  whiteSpace="nowrap"
                  fontSize="12px"
                  textTransform="none"
                >
                  {day.format("ddd")}
                  <br />
                  {day.format("DD/MM/YYYY")}
                </Th>
              ))}
            </Tr>
          </Thead>

          {dataType === "GROUPED" ? (
            <GroupedData
              days={days}
              selectedColumns={sortedFilteredColumns}
              data={data as ShiftReport.GroupingReportResponse}
              groupBy={groupBy}
              reporttype={reportType}
            />
          ) : (
            <UnGroupedData
              data={data as ShiftReport.WithoutGroupingReportResponse}
              days={days}
              selectedColumns={sortedColumns}
              reporttype={reportType}
            />
          )}
        </Table>
      </TableContainer>
    );
  }
);

function GroupedData({
  data,
  days,
  selectedColumns,
  groupBy,
  reporttype,
}: {
  data: ShiftReport.GroupingReportResponse;
  selectedColumns: Array<Column>;
  days: Array<Moment>;
  groupBy: ShiftReport.GroupingReportPayload["groupBy"];
  reporttype?: string;
}) {
  return (
    <Tbody>
      {Object.entries(data).map(([key, record]) => {
        return (
          <Fragment>
            <Tr
              _hover={{
                bgColor: "#f2f3f8",
                cursor: "pointer",
              }}
            >
              <Td
                padding="15px"
                fontWeight={500}
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.150",
                }}
                lineHeight={1.5}
                borderTopWidth="1px"
                borderBottomWidth="0px"
                borderTopColor={"borders.tableBorder"}
                position={"sticky"}
                // left={0}
                // colSpan={selectedColumns.length + days.length}

                // padding={"7px 12px"}
                // bg="gray.200"
              >
                <Text color={"#373857"} fontSize={"13px"} fontWeight={"normal"}>
                  {key}
                </Text>
              </Td>
              <Td
                colSpan={selectedColumns.length + days.length - 1}
                padding="15px"
                fontWeight={500}
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.150",
                }}
                lineHeight={1.5}
                borderTopWidth="1px"
                borderBottomWidth="0px"
                borderTopColor={"borders.tableBorder"}
                // color={"#373857"}
                // fontSize={"13px"}
                // fontWeight={"normal"}
                // bg="gray.200"
              ></Td>
            </Tr>
            {record.map((item, i) => {
              return (
                <Tr
                  key={i}
                  // color={"#373857"}
                  // fontSize={"13px"}
                  // fontWeight={"normal"}
                  // textAlign={"left"}
                  _hover={{
                    bgColor: "#f2f3f8",
                    cursor: "pointer",
                  }}
                >
                  {selectedColumns
                    .filter((sc) => sc.groupingKey !== groupBy)
                    .map((column, i) => {
                      return (
                        <Td
                          key={i}
                          // padding={"7px 12px"}
                          // color={"#373857"}
                          // fontSize={"13px"}
                          // fontWeight={"normal"}
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {(item as any)[column.column]}
                        </Td>
                      );
                    })}
                  {CheckInData({ days, item, reporttype: reporttype })}
                </Tr>
              );
            })}
          </Fragment>
        );
      })}
    </Tbody>
  );
}

function UnGroupedData({
  data,
  days,
  selectedColumns,
  reporttype,
}: {
  data: ShiftReport.WithoutGroupingReportResponse;
  selectedColumns: Array<any>;
  days: Array<Moment>;
  reporttype?: string;
}) {
  return (
    <Tbody

    // border={'1px solid #e8e8e8'}
    >
      {data.map((item, i) => {
        return (
          <Tr
            key={i}
            _hover={{
              bgColor: "#f2f3f8",
              cursor: "pointer",
            }}
            // color={"#373857"}
            // fontSize={"13px"}
            // fontWeight={"normal"}
            // textAlign={"left"}
          >
            {selectedColumns.map((column, i) => {
              return (
                <Td
                  key={i}
                  // padding={"7px 12px"}
                  // color={"#373857"}
                  // fontSize={"13px"}
                  // fontWeight={"normal"}
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderTopWidth="1px"
                  borderBottomWidth="0px"
                  borderTopColor={"borders.tableBorder"}
                >
                  {(item as any)[column.column]}
                </Td>
              );
            })}
            {CheckInData({ days, item, reporttype: reporttype })}
          </Tr>
        );
      })}
    </Tbody>
  );
}

function CheckInData({
  days,
  item,
  reporttype,
}: {
  item: ShiftReport.ReportRecord;
  days: Array<Moment>;
  reporttype?: string;
}) {
  return days?.map((day, i) => {
    const checkIns = item?.shiftsCheckIns?.[day.format("YYYY-MM-DD")];

    const checkInsArray = Array.isArray(checkIns) ? checkIns : [];
    return (
      <Td
        key={`day-${i}`}
        padding="15px"
        fontWeight={500}
        fontSize="13.44px"
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.dark.150",
        }}
        lineHeight={1.5}
        borderTopWidth="1px"
        borderBottomWidth="0px"
        borderTopColor={"borders.tableBorder"}
      >
        {checkInsArray.map((checkin, i) => {
          const startTime = checkin.startTime
            ? moment(checkin.startTime, "HH:mm:ss").format("hh:mm a")
            : null;
          const endTime = checkin.endTime
            ? moment(checkin.endTime, "HH:mm:ss").format("hh:mm a")
            : null;

          return (
            <Fragment key={i}>
              {startTime ? (
                <Text
                  _light={{
                    color: "teal",
                  }}
                  fontWeight="600"
                >
                  Shift Start: {startTime}
                </Text>
              ) : (
                // <Text
                //   color={
                //     checkin.message === "Shift Not Attended"
                //       ? "red"
                //       : checkin.message === "No Shift"
                //       ? "orange"
                //       : checkin.message === "Shift Scheduled"
                //       ? "blue"
                //       : "customColor.black7"
                //   }
                //   fontWeight="600"
                //   fontStyle="normal"
                // >
                //   {checkin.message}
                // </Text>
                <Tag
                  size="md"
                  variant="solid"
                  colorScheme={
                    checkin.message === "Shift Not Attended"
                      ? "red"
                      : checkin.message === "No Shift"
                      ? "orange"
                      : checkin.message === "Shift Scheduled"
                      ? "blue"
                      : "purple"
                  }
                >
                  <Text
                    color="customColor.white"
                    fontWeight="bold"
                    fontStyle="normal"
                  >
                    {checkin.message}
                  </Text>
                </Tag>
              )}
              <br hidden />
              {reporttype === "detailed_checkin_report" &&
                (checkin.areasCheckIns ?? []).map((c, checkInIndex) => {
                  const checkInStartTime = moment(
                    c.startTime,
                    "HH:mm:ss"
                  ).format("hh:mm a");
                  const checkInEndTime = c.endTime
                    ? moment(c.endTime, "HH:mm:ss").format("hh:mm a")
                    : "N/A";

                  return (
                    <Fragment key={checkInIndex}>
                      <Text
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        {checkInIndex + 1}. {checkInStartTime} -{" "}
                        {checkInEndTime}
                      </Text>
                      <br hidden />
                    </Fragment>
                  );
                })}

              {endTime && (
                <Text
                  _light={{
                    color: "teal",
                  }}
                  fontWeight="600"
                >
                  Shift End: {endTime}
                </Text>
              )}
              <br hidden />
            </Fragment>
          );
        })}
      </Td>
    );
  });
}
