import { HStack, SimpleGrid, Stack, Tag, Text } from "@chakra-ui/react";
import { Employees } from "../../../Api/Employees";
interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function NextToKinRecord({ employeeData }: DetailsProps) {
  return (
    <Stack
      flex={1}
      padding={"0px 5px"}
      margin={
        employeeData?.nextOfKins && employeeData?.nextOfKins.length > 0
          ? "0px 0px 20px"
          : "0px 0px 10px"
      }
    >
      <HStack
        borderBottom={"2px solid #dadddf"}
        padding={"0px 0px 10px"}
        margin={
          employeeData?.nextOfKins && employeeData?.nextOfKins.length > 0
            ? "14px 0px 20px"
            : "0px"
        }
      >
        <Text
          _dark={{
            color: "customColor.white",
          }}
          _light={{
            color: "#3454d1",
          }}
          fontWeight={"700"}
          _hover={{
            _light: {
              color: "#3454d1",
            },
          }}
        >
          Next To Kin Record
        </Text>
        {employeeData?.nextOfKins && employeeData?.nextOfKins.length > 0 ? (
          <Tag
            padding="5px 6px"
            fontWeight={600}
            fontSize="10px"
            lineHeight={1.5}
            borderRadius={"3px"}
            color={"#2E7D32"} // Dark green color for text
            backgroundColor={"#E8F5E9"} // Light green background color
            _dark={{
              color: "#2E7D32",
              backgroundColor: "#E8F5E9",
            }}
          >
            {"Complete"}
          </Tag>
        ) : (
          <Tag
            padding="5px 6px"
            fontWeight={600}
            fontSize="10px"
            lineHeight={1.5}
            borderRadius={"3px"}
            color={"#EA4D4D"}
            backgroundColor={"#FDEDED"}
          >
            {"Incomplete"}
          </Tag>
        )}
        {/* <Button
                  variant={"link"}
                  color={"primary.950"}
                  onClick={() => {
                    emergencyContact.onOpen();
                  }}
                >
                  Edit
                </Button>
                <EmergencyContactModal
                  isOpen={emergencyContact.isOpen}
                  onClose={emergencyContact.onClose}
                /> */}
      </HStack>
      {employeeData?.nextOfKins && employeeData?.nextOfKins.length > 0 && (
        <SimpleGrid
          columns={{ xs: 1, md: 2 }}
          padding={"0px 5px"}
          margin={"0px 0px 20px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          spacing={10}
        >
          {employeeData?.nextOfKins.map((item, i) => {
            return (
              <Stack key={i} flex={1}>
                <HStack
                  justifyContent={"space-between"}
                  borderBottom={"2px solid #dadddf"}
                  padding={"0px 0px 10px"}
                  margin={"14px 0px 20px"}
                >
                  <Text
                    fontSize={"14.5px"}
                    _dark={{
                      color: "customColor.white",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontWeight={"700"}
                    _hover={{
                      _light: {
                        color: "#3454d1",
                      },
                    }}
                  >
                    Kin {i + 1}
                  </Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Name
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.name ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Cnic
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.cnic ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Contact No
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.contact ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Address
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.address ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Relationship
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {item?.relationship ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Stack flex={1}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                      fontWeight={"700"}
                    >
                      Amount of Share %
                    </Text>
                  </Stack>
                  <Stack flex={2}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"13px"}
                    >
                      {`${item?.shareInPercent}%` ?? "NA"}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
            );
          })}
        </SimpleGrid>
      )}
    </Stack>
  );
}
