import { HStack, Stack, Text } from "@chakra-ui/react";
import { Employees } from "../../../Api/Employees";
interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function ContactDetail({ employeeData }: DetailsProps) {
  return (
    <Stack flex={1} padding={"0px 5px"} margin={"0px 0px 20px"}>
      <HStack
        justifyContent={"space-between"}
        borderBottom={"2px solid #dadddf"}
        padding={"0px 0px 10px"}
        margin={"14px 0px 20px"}
      >
        <Text
          _dark={{
            color: "customColor.white",
          }}
          _light={{
            color: "#3454d1",
          }}
          fontWeight={"700"}
          _hover={{
            _light: {
              color: "#3454d1",
            },
          }}
        >
          Contact Information
        </Text>
      </HStack>
      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Address
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.address ?? "NA"}
          </Text>
        </Stack>
      </HStack>

      {/* <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Phone
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.phoneNumber ?? "NA"}
          </Text>
        </Stack>
      </HStack> */}
      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Mobile Number
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.mobileNumber ?? "NA"}
          </Text>
        </Stack>
      </HStack>
      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Email
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.email ?? "NA"}
          </Text>
        </Stack>
      </HStack>
    </Stack>
  );
}
