import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";

export function EmployeeMenu() {
  const { employeesList } = useBusinessContext();
  const [selectedMenu, setSelectedOptionMenu] = useState("All");

  const handleOptionSelectMenu = (option: any) => {
    setSelectedOptionMenu(option);
  };

  return (
    <Stack margin={"20px 0px 0px"} padding={"0px 0px 0px 10px"}>
      <HStack gap={0}>
        <Text
          fontSize="13px"
          display={"block"}
          fontWeight="700"
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Employees
        </Text>
        <Text
          paddingLeft={"3px"}
          fontSize="13px"
          display={"block"}
          fontWeight="400"
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          (Required)
        </Text>
      </HStack>

      <Box>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            width={"100%"}
            textAlign={"start"}
            h={"36px"}
            _light={{
              bgColor: "customColor.white",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            color={"black"}
            fontWeight={"400"}
            border={"1px solid #b7bec5"}
            borderRadius={"5px"}
            _hover={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid #a6a9b0",
            }}
            _active={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid #a6a9b0",
            }}
          >
            {selectedMenu}
          </MenuButton>
          <MenuList
            overflow="auto"
            zIndex={"overlay"}
            minW={"300px"}
            maxH={"350px"}
          >
            <MenuItem
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
              onClick={() => handleOptionSelectMenu("All")}
            >
              <Text
                padding={"8px"}
                margin={"0px 12px 0px 0px"}
                _light={{
                  color: "customColor.black5",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"15px"}
              >
                All
              </Text>
            </MenuItem>
            {employeesList?.map((item, i) => {
              return (
                <MenuItem
                  key={i}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() =>
                    handleOptionSelectMenu(`${item.firstName} ${item.lastName}`)
                  }
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {item.firstName} {item.lastName}
                  </Text>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Box>
    </Stack>
  );
}
