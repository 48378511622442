import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export default function TaxSlabs() {
  const form = useFormContext<Analytics.DashBoardData>();
  const { editDashboard, dashboardAnalytics, taxFinancialYear } =
    useDashboardContext();
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   overflowY={"auto"}
    //   boxShadow="0 4px 8px rgba(15,34,58,.35)"
    //   _hover={{
    //     boxShadow: "0 4px 8px rgba(15,34,58,.65)",
    //     cursor: "pointer",
    //   }}
    //   // display={
    //   //   dashboardAnalytics ||
    //   //   form.getValues("hideOrShowTiles.taxSlabsData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    // >
    <Box
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      w="100%"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
      sx={{
        "::-webkit-scrollbar": {
          width: "0px", // Initially, the scrollbar is hidden
          background: "transparent",
        },
        ":hover::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on hover
        },
        ":active::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible when active
        },
        ":focus::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on focus
        },
        // Add for Firefox and other browsers if needed
        scrollbarWidth: "none",
        ":hover": {
          scrollbarWidth: "thin",
        },
        ":active": {
          scrollbarWidth: "thin",
        },
        ":focus": {
          scrollbarWidth: "thin",
        },
      }}
    >
      <Box p={"25px"} borderBottom={"1px solid #e5e7eb"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight="1.2"
          fontSize="16px"
          fontWeight={600}
        >
          Tax Slabs
        </Text>
      </Box>
      <Box
        overflowX="auto"
        p="0px"
        sx={{
          "::-webkit-scrollbar": {
            width: "0px", // Initially, the scrollbar is hidden
            background: "transparent",
          },
          ":hover::-webkit-scrollbar": {
            width: "8px", // Scrollbar visible on hover
          },
          ":active::-webkit-scrollbar": {
            width: "8px", // Scrollbar visible when active
          },
          ":focus::-webkit-scrollbar": {
            width: "8px", // Scrollbar visible on focus
          },
          // Add for Firefox and other browsers if needed
          scrollbarWidth: "none",
          ":hover": {
            scrollbarWidth: "thin",
          },
          ":active": {
            scrollbarWidth: "thin",
          },
          ":focus": {
            scrollbarWidth: "thin",
          },
        }}
      >
        <Table variant="simple" w="100%" overflowX="auto">
          <Thead h={"36.8px"}>
            <Tr
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
            >
              {[
                {
                  name: "Name",
                  value: 1,
                  paddingValue: "8px 26px 8px 30px",
                },
                {
                  name: "Status",
                  value: 3,
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Period",
                  value: 3,
                  paddingValue: "8px 26px 8px 15px",
                },
              ].map((item, i) => {
                return (
                  <Th
                    key={i}
                    padding={item.paddingValue}
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                    flex={item.value}
                  >
                    {item.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {taxFinancialYear?.map((item, index) => {
              return (
                <Tr
                  key={index}
                  _hover={{
                    bgColor: "#f2f3f8",
                    cursor: "pointer",
                  }}
                >
                  <Td
                    padding="15px 15px 15px 30px"
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    fontWeight={500}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    isTruncated={true}
                    _hover={{
                      color: "#3454d1",
                    }}
                  >
                    {item?.name}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    isTruncated={true}
                  >
                    <Box
                      as="span"
                      display="inline-block"
                      w="10px"
                      h="10px"
                      borderRadius="50%"
                      bg={item?.isActivated ? "green" : "red"}
                      mr="8px"
                    ></Box>
                    {item?.isActivated ? "Active" : "Inactive"}
                  </Td>
                  <Td
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                    isTruncated={true}
                  >
                    {item?.startDate} - {item?.endDate}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
    // </Card>
  );
}
