import { axiosInstanceTime } from "./axiosInstanceTime";
import { LocationApi } from "./LocationAPi";

declare module Shifts {
  type Shift = {
    employeeId: string;
    locationId: string;
    areas: Array<string>;
    shiftStartDate: string;
    shiftEndDate: string;
    shiftStartTime: string;
    shiftEndTime: string;
    notes: string;
    qrRequired: boolean;
    shiftLastNextDay: boolean;
    break: number;
    geoFencingEnabled: boolean;
    autoEndShift: boolean;
  };
  type ShiftData = {
    id: string;
    employeeId: string;
    locationId: string;
    areas: Array<string>;
    shiftStartDate: string;
    shiftStartTime: string;
    shiftEndTime: string;
    location: LocationApi.Location;
  };
  type CreateBulkShifts = {
    employeeIds: Array<string>;
    locationId: string;
    areas: Array<string>;
    shiftStartDate: string;
    shiftEndDate: string;
    shiftStartTime: string;
    shiftEndTime: string;
    notes: string;
    qrRequired: boolean;
    break: number;
    multiDates: boolean;
    days?: Array<number>;
    geoFencingEnabled: boolean;
    autoEndShift: boolean;
  };
  type FetchByDatesResponse = {
    date: string;
    shifts: Array<ShiftData>;
  };
  type DateWiseShift = {
    [date: string]: Array<ShiftData>;
  };
  type DateRange = {
    startDate: string;
    endDate: string;
  };

  type CopyBulkShift = {
    copyFrom?: DateRange;
    copyTo?: DateRange;
  };
  type DeleteShifts = {
    startDate?: string;
    endDate?: string;
    shiftIds: Array<string>;
  };

  type copyPasteShift = {
    copyDate?: string;
    pasteDate?: string;
    copyAll?: boolean;
    copyFromEmployeeId?: string;
    copyToEmployeeId?: string;
    shiftId?: string;
  };
  type postAttendance = {
    startDate?: string;
    endDate?: string;
    employeeId?: string;
    locationId?: string;
    limit?: number;
    offset?: number;
    periodOfDay?: string;
    employeeIds?: Array<string>;
  };
  type rosterPayload = {
    startDate: string;
    endDate: string;
  };
}
module Shifts {
  const API_URL = "/employee-shifts";
  export function AddShift(
    data: Shift,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function AddBulksShifts(
    data: CreateBulkShifts,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/create-bulk`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateShift(
    id: string,
    data: Partial<Shift>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .put(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchShiftsByDate(
    dateRange: {
      startDate: string;
      endDate: string;
      employeeId?: string;
      periodOfDay?: string;
    },
    success: (reponse: Array<FetchByDatesResponse>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}`, { params: dateRange })
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchRoaster(
    date: string,
    time: string,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}/today/${date}/${time}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchShiftById(
    id: string,
    success: (data: Shift) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CopyShiftsBulk(
    data: CopyBulkShift,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/bulk-copy`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.reponse?.data.message ?? e?.data);
      });
  }
  export function DeleteShiftBulk(
    data: DeleteShifts,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .delete(`${API_URL}/delete`, { data })
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CopyPasteShift(
    data: copyPasteShift,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/copy`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetAttendance(
    data: postAttendance,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/attendance`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetEmployeeRoster(
    data: rosterPayload,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/roster`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetShiftById(
    id: string,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  // export function CheckinShift(

  // )
}

export { Shifts };
