import { Box, Button, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { FaFilter } from "react-icons/fa";
import { FilterModal } from "../Modal/FilterModal";
interface FilterProps {
  FilterData: {
    name: string;
    dataType?: string | undefined;
    startDate?: string | undefined;
    endDate?: string | undefined;
    startRate?: string | undefined;
    endRate?: string | undefined;
    StartKeyName?: string;
    EndKeyName?: string;
    PayLoadDataType?: any;
  }[];
}
export function FilterComponent({ FilterData }: FilterProps) {
  const filterModal = useDisclosure();
  const form = useFormContext();
  return (
    <Stack margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"} width="100%">
      <Text
        fontSize="13px"
        display={"block"}
        fontWeight="600"
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.white",
        }}
      >
        Filter
      </Text>

      <Box>
        <Button
          width={"100%"}
          textAlign={"left"}
          backgroundColor={"#fff"}
          borderRadius={"4px"}
          border={"1px solid #e5e7eb"}
          fontSize={"13px"}
          h={"38px"}
          justifyContent={"flex-start"}
          variant={"solid"}
          textColor={"#3454d1"}
          padding={"12px 16px"}
          fontWeight={600}
          leftIcon={<FaFilter color="#3454d1" />}
          _hover={{
            bg: "#fff",
            border: "1px solid hsl(0, 0%, 70%)",
          }}
          _active={{
            bg: "#fff",
            borderWidth: "1px",

            boxShadow: "0 0 0 1px #2684FF",
            _hover: {
              borderWidth: "1px",
              borderColor: "#2684FF",
              boxShadow: "0 0 0 1px #2684FF",
            },
          }}
          onClick={() => {
            filterModal.onOpen();
          }}
        >
          Filter
        </Button>

        <FilterModal
          FilterData={FilterData}
          isOpen={filterModal.isOpen}
          onClose={filterModal.onClose}
        />
      </Box>
    </Stack>
  );
}
