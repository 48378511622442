import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { BusinessServiceChargeApi } from "../../Api/BusinessServiceCharge";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function ServiceCharge({
  setSelectedTab,
  selectedTab,
}: TabProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const { currentBusinessId } = useBusinessContext();
  const form = useForm<BusinessServiceChargeApi.BusinessServiceChargeData>({
    defaultValues: {
      serviceCharges: 0,
      currency: "",
      chargesHead: "",
      fixedAmount: 0,
    },
  });
  const watchChargeHead = useWatch({
    control: form.control,
    name: "chargesHead",
  });

  const ServiceChargesHead: Array<{ label: string; value: string }> = [
    {
      label: "Base Salary",
      value: "BASE_SALARY",
    },
    {
      label: "Gross Salary",
      value: "GROSS_SALARY",
    },
    {
      label: "Fix Charges",
      value: "FIX_CHARGES",
    },
  ];
  useEffect(() => {
    if (currentBusinessId) {
      BusinessServiceChargeApi.GetBusinessServiceCharges(
        currentBusinessId,
        (data) => {
          form.reset(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [currentBusinessId, form.reset]);
  const currencyOptions = [
    "AED: United Arab Emirates Dirham",
    "AFN: Afghan Afghani",
    "ALL: Albanian Lek",
    "AMD: Armenian Dram",
    "ANG: Netherlands Antillean Guilder",
    "AOA: Angolan Kwanza",
    "ARS: Argentine Peso",
    "AUD: Australian Dollar",
    "AWG: Aruban Florin",
    "AZN: Azerbaijani Manat",
    "BAM: Bosnia-Herzegovina Convertible Mark",
    "BBD: Barbadian Dollar",
    "BDT: Bangladeshi Taka",
    "BGN: Bulgarian Lev",
    "BHD: Bahraini Dinar",
    "BIF: Burundian Franc",
    "BMD: Bermudan Dollar",
    "BND: Brunei Dollar",
    "BOB: Bolivian Boliviano",
    "BRL: Brazilian Real",
    "BSD: Bahamian Dollar",
    "BTC: Bitcoin",
    "BTN: Bhutanese Ngultrum",
    "BWP: Botswanan Pula",
    "BYN: Belarusian Ruble",
    "BZD: Belize Dollar",
    "CAD: Canadian Dollar",
    "CDF: Congolese Franc",
    "CHF: Swiss Franc",
    "CLF: Chilean Unit of Account (UF)",
    "CLP: Chilean Peso",
    "CNH: Chinese Yuan (Offshore)",
    "CNY: Chinese Yuan",
    "COP: Colombian Peso",
    "CRC: Costa Rican Colón",
    "CUC: Cuban Convertible Peso",
    "CUP: Cuban Peso",
    "CVE: Cape Verdean Escudo",
    "CZK: Czech Republic Koruna",
    "DJF: Djiboutian Franc",
    "DKK: Danish Krone",
    "DOP: Dominican Peso",
    "DZD: Algerian Dinar",
    "EGP: Egyptian Pound",
    "ERN: Eritrean Nakfa",
    "ETB: Ethiopian Birr",
    "EUR: Euro",
    "FJD: Fijian Dollar",
    "FKP: Falkland Islands Pound",
    "GBP: British Pound Sterling",
    "GEL: Georgian Lari",
    "GGP: Guernsey Pound",
    "GHS: Ghanaian Cedi",
    "GIP: Gibraltar Pound",
    "GMD: Gambian Dalasi",
    "GNF: Guinean Franc",
    "GTQ: Guatemalan Quetzal",
    "GYD: Guyanaese Dollar",
    "HKD: Hong Kong Dollar",
    "HNL: Honduran Lempira",
    "HRK: Croatian Kuna",
    "HTG: Haitian Gourde",
    "HUF: Hungarian Forint",
    "IDR: Indonesian Rupiah",
    "ILS: Israeli New Sheqel",
    "IMP: Manx pound",
    "INR: Indian Rupee",
    "IQD: Iraqi Dinar",
    "IRR: Iranian Rial",
    "ISK: Icelandic Króna",
    "JEP: Jersey Pound",
    "JMD:Jamaican Dollar",
    "JOD: Jordanian Dinar",
    "JPY: Japanese Yen",
    "KES: Kenyan Shilling",
    "KGS:Kyrgystani Som",
    "KHR: Cambodian Riel",
    "KMF: Comorian Franc",
    "KPW: North Korean Won",
    "KRW: South Korean Won",
    "KWD: Kuwaiti Dinar",
    "KYD: Cayman Islands Dollar",
    "KZT: Kazakhstani Tenge",
    "LAK: Laotian Kip",
    "LBP: Lebanese Pound",
    "LKR: Sri Lankan Rupee",
    "LRD: Liberian Dollar",
    "LSL: Lesotho Loti",
    "LYD: Libyan Dinar",
    "MAD: Moroccan Dirham",
    "MDL: Moldovan Leu",
    "MGA: Malagasy Ariary",
    "MKD: Macedonian Denar",
    "MMK: Myanma Kyat",
    "MNT: Mongolian Tugrik",
    "MOP: Macanese Pataca",
    "MRU: Mauritanian Ouguiya",
    "MUR: Mauritian Rupee",
    "MVR: Maldivian Rufiyaa",
    "MWK: Malawian Kwacha",
    "MXN: Mexican Peso",
    "MYR: Malaysian Ringgit",
    "MZN: Mozambican Metical",
    "NAD: Namibian Dollar",
    "NGN: Nigerian Naira",
    "NIO: Nicaraguan Córdoba",
    "NOK: Norwegian Krone",
    "NPR: Nepalese Rupee",
    "NZD: New Zealand Dollar",
    "OMR: Omani Rial",
    "PAB: Panamanian Balboa",
    "PEN: Peruvian Nuevo Sol",
    "PGK: Papua New Guinean Kina",
    "PHP: Philippine Peso",
    "PKR: Pakistani Rupee",
    "PLN: Polish Zloty",
    "PYG: Paraguayan Guarani",
    "QAR: Qatari Rial",
    "RON: Romanian Leu",
    "RSD: Serbian Dinar",
    "RUB: Russian Ruble",
    "RWF: Rwandan Franc",
    "SAR: Saudi Riyal",
    "SBD: Solomon Islands Dollar",
    "SCR: Seychellois Rupee",
    "SDG: Sudanese Pound",
    "SEK: Swedish Krona",
    "SGD: Singapore Dollar",
    "SHP: Saint Helena Pound",
    "SLL: Sierra Leonean Leone",
    "SOS: Somali Shilling",
    "SRD: Surinamese Dollar",
    "SSP: South Sudanese Pound",
    "STD: São Tomé and Príncipe Dobra (pre-2018)",
    "STN: São Tomé and Príncipe Dobra",
    "SVC: Salvadoran Colón",
    "SYP: Syrian Pound",
    "SZL: Swazi Lilangeni",
    "THB: Thai Baht",
    "TJS: Tajikistani Somoni",
    "TMT: Turkmenistani Manat",
    "TND: Tunisian Dinar",
    "TOP: Tongan Pa'anga",
    "TRY: Turkish Lira",
    "TTD: Trinidad and Tobago Dollar",
    "TWD: New Taiwan Dollar",
    "TZS: Tanzanian Shilling",
    "UAH: Ukrainian Hryvnia",
    "UGX: Ugandan Shilling",
    "USD: United States Dollar",
    "UYU: Uruguayan Peso",
    "UZS: Uzbekistan Som",
    "VEF:Venezuelan Bolívar Fuerte (Old)",
    "VES:Venezuelan Bolívar Soberano",
    "VND:Vietnamese Dong",
    "VUV:Vanuatu Vatu",
    "WST: Samoan Tala",
    "XAF: CFA Franc BEAC",
    "XAG: Silver Ounce",
    "XAU: Gold Ounce",
    "XCD: East Caribbean Dollar",
    "XDR: Special Drawing Rights",
    "XOF: CFA Franc BCEAO",
    "XPD: Palladium Ounce",
    "XPF: CFP Franc",
    "XPT: Platinum Ounce",
    "YER: Yemeni Rial",
    "ZAR: South African Rand",
    "ZMW: Zambian Kwacha",
    "ZWL: Zimbabwean Dollar",
  ].map((currency) => ({ label: currency, value: currency }));
  return (
    <FormProvider {...form}>
      <Stack
        marginTop={"15px"}
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          // maxW="940px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          // border="1px solid #d6dade"
          // borderRadius="md"
        >
          <HStack gap={0}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              as="h2"
              flex={1}
              size="md"
            >
              Service Charges
            </Heading>
            <Link
              color="primary.950"
              fontSize=" var(--x-font-size-small, .845rem"
              paddingBottom="1px"
              href="/app/payroll/organisationSettings"
            >
              Organisation Settings <Icon as={ChevronRightIcon} />
            </Link>
          </HStack>

          <Divider orientation="horizontal" />

          <Container marginBottom={"25px"}>
            <FormControl paddingTop="20px">
              <FormLabel
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight="600"
                fontSize=".845rem"
                paddingTop="25px"
              >
                Service Charges Head
              </FormLabel>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Charges Head is required",
                  },
                }}
                control={form.control}
                name="chargesHead"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Charges Head "
                    withValidation
                    options={ServiceChargesHead}
                    value={ServiceChargesHead.find(
                      (ServiceChargesHead) =>
                        ServiceChargesHead.value === field.value
                    )}
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.value);
                      form.setValue("serviceCharges", 0);
                      form.setValue("fixedAmount", 0);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </FormControl>
            {watchChargeHead !== "FIX_CHARGES" && (
              <Controller
                name="serviceCharges"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Service Charges is required",
                  },
                  validate: (value) => {
                    if (value)
                      if (value < 0) {
                        return "Service Charges cannot be negative";
                      } else if (value > 100) {
                        return "Service Charges cannot be more than 100%";
                      }
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    label="Service Charges (%) "
                    labelProps={{
                      fontSize: ".845rem",
                      marginTop: "25px",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                    input={{
                      ...field,
                      value: field.value,
                      isInvalid: error !== undefined,
                      fontSize: "15px",
                      variant: "outline",
                      type: "number",
                      border: "1px solid hsl(0, 0%, 80%)",
                      borderTopRadius: "3px",
                      alignContent: "center",
                      onChange: (e) => {
                        field.onChange(Number(e.target.valueAsNumber));
                      },
                    }}
                    placeholder=""
                  />
                )}
              />
            )}
            {watchChargeHead === "FIX_CHARGES" && (
              <Controller
                name="fixedAmount"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Fixed Amount is required",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    label="Fixed Amount"
                    labelProps={{
                      fontSize: ".845rem",
                      marginTop: "25px",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                      alignContent: "center",
                      value: field.value
                        ? Number(field.value).toLocaleString()
                        : "",
                      onChange: (e) => {
                        const value = e.target.value.replace(/,/g, "");
                        const parsedValue = Number(value);
                        field.onChange(parsedValue);
                      },
                    }}
                    placeholder=""
                  />
                )}
              />
            )}

            <Text
              margin={"0px 12px 8px 0px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontWeight="600"
              fontSize=".845rem"
              paddingTop="25px"
            >
              Currency:
            </Text>

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Currency is required",
                },
              }}
              // control={form.control}
              control={form.control}
              name="currency"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  // marginBottom="30px"
                  // width={"48em"}
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Currency "
                  withValidation
                  options={currencyOptions}
                  value={currencyOptions.find(
                    (currencyOptions) => currencyOptions.value === field.value
                  )}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Container>
          <Stack
            alignItems={"flex-end"}
            marginBottom={"15px"}
            marginRight={"15px"}
          >
            <ButtonGroup
              borderRadius="4px"
              fontSize="var(--x-font-size-small, .845rem)"
            >
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setIsLoading(true);
                      BusinessServiceChargeApi.AddBusinessServiceCharges(
                        data,
                        (success) => {
                          toast({
                            title: "Business Service Charges Added",

                            status: "success",
                          });
                          setIsLoading(false);
                          setSelectedTab(selectedTab + 1);
                        },
                        (error) => {
                          toast({
                            title: "Business Service Charges Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    },
                    (error) => {
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                {isLoading ? "Saving..." : " Save and Next"}
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit(
                    (data) => {
                      setIsLoading(true);
                      BusinessServiceChargeApi.AddBusinessServiceCharges(
                        data,
                        (success) => {
                          toast({
                            title: "Business Service Charges Added",

                            status: "success",
                          });
                          setIsLoading(false);
                        },
                        (error) => {
                          toast({
                            title: "Business Service Charges Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    },
                    (error) => {
                      setIsLoading(false);
                    }
                  )(e);
                }}
              >
                {isLoading ? "Saving..." : " Save "}
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      </Stack>
    </FormProvider>
  );
}
