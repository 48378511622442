import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";

import { useState } from "react";
import { EmergencyContactApi } from "../../../../../Api/EmergencyContact";

interface AddContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
}
export default function AddContactModal({
  isOpen,
  onClose,
  empId,
  setLastUpdated,
}: AddContactModalProps) {
  const addressRegex = /^(?!\d+$)[A-Za-z0-9\s#,\-]+$/;
  const form = useFormContext<EmergencyContactApi.AddEmergencyContact>();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const alphanumericRegex = /^[A-Za-z0-9\s]+$/;
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Emergency Contact
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            borderTop={"1px solid #ddd"}
            borderBottom={"1px solid #ddd"}
          >
            <Box>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Name
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Emergency Contact Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabetic characters are allowed",
                      },
                    }}
                    control={form.control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",
                          fontWeight: "500",
                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder="Emergency Contact Name"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Number
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Emergency Contact Number is required",
                      },
                      minLength: {
                        value: 11,
                        message:
                          "Emergency Contact Number must be 11 characters",
                      },
                      maxLength: {
                        value: 14,
                        message:
                          "Emergency Contact Number must be 14 characters",
                      },
                    }}
                    control={form.control}
                    name="contact"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",
                          fontWeight: "500",
                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",
                          onChange: (e) => {
                            field.onChange(Number(e.target.value));
                          },
                          borderRadius: "4px",
                          minLength: 11,
                          maxLength: 14,
                        }}
                        placeholder="Emergency Contact Number"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Email
                </Text>
                <Box w={"380px"}>
                  <Controller
                    control={form.control}
                    name="email"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",
                          fontWeight: "500",
                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder="Emergency Contact Email"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Relationship
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Emergency Contact Relationship is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabetic characters are allowed",
                      },
                    }}
                    control={form.control}
                    name="relationship"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",
                          fontWeight: "500",
                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder="Emergency Contact Relationship"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Address
                </Text>
                <Box w={"380px"}>
                  <Controller
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: "Address is required",
                    //   },
                    //   validate: {
                    //     notOnlyNumbers: (value) =>
                    //       addressRegex.test(value) ||
                    //       "Address shouldn't only contain numbers",
                    //   },
                    // }}
                    control={form.control}
                    name="address"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",
                          fontWeight: "500",
                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder="Emergency Contact Address"
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  if (form.getValues("id")) {
                    EmergencyContactApi.UpdateEmergencyContact(
                      form.getValues("id")!,
                      data,
                      (success) => {
                        toast({
                          title: "Emergency Contact Updated",
                          status: "success",
                        });
                        setIsLoading(false);
                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  } else {
                    EmergencyContactApi.AddEmergencyContact(
                      empId!,
                      data,
                      (success) => {
                        toast({
                          title: "Emergency Contact Added",
                          status: "success",
                        });
                        setIsLoading(false);
                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }
                })(e);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
