import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { EmployeeLeave } from "../../../Api/EmployeeLeave";

interface RejectLeaveProps {
  isOpen: boolean;
  onClose: () => void;

  setLastUpdated: (timeStamp: number) => void;
}
export function RejectLeave({
  isOpen,
  onClose,
  setLastUpdated,
}: RejectLeaveProps) {
  const param = useParams();
  const form = useFormContext<EmployeeLeave.RejectLeave>();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box h="50px">
          <ModalHeader
            alignItems="center"
            fontSize="18px"
            color={"customColor.gray.800"}
            _dark={{
              color: "customColor.dark.250",
            }}
          >
            Add Note
          </ModalHeader>
          <ModalCloseButton />
        </Box>
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Controller
            name="note"
            rules={{
              required: {
                value: true,
                message: "Type a Note",
              },
            }}
            control={form.control}
            render={({ field, fieldState }) => (
              <>
                <Textarea
                  {...field}
                  isInvalid={fieldState.invalid}
                  errorBorderColor="Red"
                  border="1px solid #e5e7eb"
                  _hover={{
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _focus={{
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _focusVisible={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  mt="10px"
                  mb="15
                      px"
                  overflowX="hidden"
                  maxLength={300}
                  w="400px"
                  shadow="inner"
                  placeholder="Type a Note"
                  h="160px"
                />
                {fieldState.invalid && (
                  <Text color="red" fontSize="12px">
                    {fieldState.error?.message}
                  </Text>
                )}
              </>
            )}
          />
        </ModalBody>
        <ModalFooter h="50px" w="100%" justifyContent={"flex-end"}>
          <Button
            padding={"7.52px 12px"}
            borderRadius="3px"
            w="100px"
            h="40px"
            colorScheme="red"
            type="submit"
            mr={3}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                EmployeeLeave.LeaveRejected(
                  param.id!,
                  data,
                  (success) => {
                    setLastUpdated(Date.now());
                    toast({
                      title: "Leave Rejected",

                      status: "success",
                    });
                    setIsLoading(false);
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Leave Rejected Failed",
                      description: error,
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                );
              })(e);
            }}
          >
            Reject
          </Button>
          <Button
            w="80px"
            h="40px"
            variant="outline"
            padding={"7.52px 12px"}
            borderRadius="3px"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
