import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { BusinessTaxRate } from "../../../../Api/BusinessTaxRate";

interface DeleteBulkProps {
  isOpen: boolean;
  onClose: () => void;
  selectedIds: string[];
  setLastUpdated: (time: number) => void;
  length?: number;
}

export default function DeleteBulk({
  isOpen,
  onClose,
  selectedIds,
  setLastUpdated,
  length,
}: DeleteBulkProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleBulkDelete = () => {
    setIsLoading(true);
    selectedIds.forEach((id) => {
      BusinessTaxRate.BulkDeleteTaxRates(
        [id],
        () => {
          toast({
            title: "Tax Bulk Delete Successfully",
            status: "success",
          });
          setIsLoading(false);
          setLastUpdated(Date.now());
        },
        () => {
          toast({
            title: "Error Deleting Tax Bulk",
            status: "error",
          });
          setIsLoading(false);
        }
      );
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Bulk Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          fontSize={"0.8125rem"}
          fontWeight={"600"}
          _light={{
            color: "customColor.black7",
          }}
        >
          <p>Are you sure you want to delete {length} tax rates?</p>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            isLoading={isLoading}
            onClick={handleBulkDelete}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
