import { Icon, Spinner, Stack, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { MdDownload } from "react-icons/md";
import { PayslipsModule } from "../../Api/PaySlips";

interface PayslipDownloadingProps {
  id: string;
  paymentDate: string;
}
export default function PayslipDownloading({
  id,
  paymentDate,
}: PayslipDownloadingProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return loading ? (
    <Spinner size="sm" color="gray.500" />
  ) : (
    <Stack>
      <Icon
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        borderStyle={"solid"}
        borderWidth={"1px"}
        borderColor={"borders.tableBorder"}
        bg={"#fff"}
        w="30px"
        h="30px"
        px="7px"
        py="7px"
        as={MdDownload}
        fontSize="22px"
        color="#6B7280"
        _hover={{
          color: "#3454d1",
        }}
        onClick={() => {
          setLoading(true);
          PayslipsModule.DownloadPayslip(
            id,
            (response) => {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `Salary Slip ${moment(paymentDate).format("DD MMM YYYY")}.pdf`
              );
              document.body.appendChild(link);
              link.click();
              setLoading(false);
            },
            (message) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
              setLoading(false);
            }
          );
        }}
      />
      {/* <IconButton
        aria-label="download"
        borderRadius="50%"
        borderStyle={"solid"}
        borderWidth={"1px"}
        borderColor={"borders.tableBorder"}
        bg={"#fff"}
        color="#6B7280"
        icon={<MdDownload color="#A0AEC0" fontSize={"24px"} />}
        _hover={{
          color: "#3454d1",
        }}
        onClick={() => {
          setLoading(true);
          PayslipsModule.DownloadPayslip(
            id,
            (response) => {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `Salary Slip ${moment(paymentDate).format("DD MMM YYYY")}.pdf`
              );
              document.body.appendChild(link);
              link.click();
              setLoading(false);
            },
            (message) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
              setLoading(false);
            }
          );
        }}
      /> */}
    </Stack>
  );
}
