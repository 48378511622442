import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";

import { AiOutlineClose } from "react-icons/ai";
import { Shifts } from "../../../API/Shifts";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface DeleteAllShiftsModalProps {
  isOpen: boolean;
  onClose: () => void;
  viewedshiftids: Array<string>;
  setViewedShiftIds: (shiftIds: Array<string>) => void;
}
export default function DeleteAllShiftsModal({
  isOpen,
  onClose,
  viewedshiftids,
  setViewedShiftIds,
}: DeleteAllShiftsModalProps) {
  const toast = useToast();
  const { setLastUpdated } = useScheduleContext();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px="32px" pt="32px" pb="0px">
          <HStack>
            <Text
              flex={1}
              _light={{
                color: "customColor.black7",
              }}
              fontSize=".9975rem"
            >
              Remove all shifts
            </Text>
            <IconButton
              aria-label="close"
              variant="ghost"
              icon={<AiOutlineClose color="#3454D1" size="20px" />}
              onClick={onClose}
            />
          </HStack>
        </ModalHeader>

        <ModalBody px="32px" pt="28px" pb="24px">
          <Text fontSize={"0.8125rem"} fontWeight={"600"} color="red">
            Warning! This action is permanent. Please proceed with caution.
          </Text>
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"400"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Are you sure you want to permanently delete all the shifts you are
            viewing?
          </Text>
        </ModalBody>

        <ModalFooter px="24px" pt="10px" pb="24px">
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fontSize={"13px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            onClick={() => {
              Shifts.DeleteShiftBulk(
                { shiftIds: viewedshiftids },
                () => {
                  toast({
                    title: " Shifts Deleted",
                    status: "success",
                  });
                  setLastUpdated(Date.now());
                  setViewedShiftIds([]);
                  onClose();
                },
                (error) => {
                  toast({
                    title: "Error",
                    description: error,
                    status: "error",
                  });

                  onClose();
                }
              );
            }}
          >
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
