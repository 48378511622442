import {
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { AccessControl } from "../../../../api/AccessControl";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { MappingModuleApi } from "../../../Api/MappingModule";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";
interface EmploymentComponentProps {
  selectedEmployeeFilters: {
    empType: boolean;
    empBasis: boolean;
    payrollPayCalenderId: boolean;
    employeeGroupId: boolean;
    ratesId: boolean;
    department: boolean;
    jobTitle: boolean;
    isManagementStaff: boolean;
    manager: boolean;
    incomeType: boolean;
    joiningDate: boolean;
  };
  users: AccessControl.UserDetail[];
  earningAdded: any[];
  payOptions: PayrollCalendar.Calendar[];
  EmploymentForm: UseFormReturn<
    MappingModuleApi.EmployeeDetailFilters,
    any,
    undefined
  >;
}
export function EmploymentComponent({
  selectedEmployeeFilters,
  earningAdded,
  users,
  payOptions,
  EmploymentForm,
}: EmploymentComponentProps) {
  const toast = useToast();
  const employeeOptions = [
    { value: "6561a85a-0aee-4f60-969d-21a90ddf2975", label: "None" },
  ];
  const incomeTypeOptions = [
    { value: "SALARY_AND_WAGES", label: "Monthly Salary" },
    { value: "CLOSELY_HELD_PAYEES", label: "Closely held payees" },
    { value: "WORKING_HOLIDAY_MAKER", label: "Working holiday maker" },
    {
      value: "PER_HOUR_RATE",
      label: "Per Hour Rate",
    },
    {
      value: "PER_DAY_RATE",
      label: "Per Day Rate",
    },
  ];
  const empType = [
    { value: "EMPLOYEE", label: "Permanent" },
    { value: "CONTRACTOR", label: "Contractual" },
  ];
  const employeeBasicOptions = [
    { value: "FULL_TIME", label: "Full-time" },
    { value: "PART_TIME", label: "Part-time" },
    { value: "CASUAL", label: "Daily Wager" },
  ];

  return (
    <SimpleGrid columns={3} spacing={3}>
      {selectedEmployeeFilters.empType && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Employment Type
          </Text>
          <Controller
            control={EmploymentForm.control}
            name="empType"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder=""
                isInvalid={fieldState.invalid}
                withValidation
                options={empType}
                value={empType.find((option) => option.value === field.value)}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value); // Update the event value
                  // Reset selected filters when the event changes
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.empBasis && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Employment basis
          </Text>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Employee Basis is required",
              },
            }}
            control={EmploymentForm.control}
            name="empBasis"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                placeholder=""
                isInvalid={fieldState.invalid}
                ErrorMessage="Select Account Type"
                withValidation
                options={employeeBasicOptions}
                value={employeeBasicOptions.find(
                  (op) => op.value === field.value
                )}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value);
                  EmploymentForm.setValue("empBasis", newValue?.value);
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.payrollPayCalenderId && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Payroll Calendar
          </Text>
          <Controller
            rules={{
              required: {
                value: true,
                message: "PayRoll Calendar is required",
              },
            }}
            control={EmploymentForm.control}
            name="payrollPayCalenderId"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder="Select"
                defaultValue={"804: Wages Payable - Payroll"}
                // marginBottom="30px"
                // width={"48em"}
                isInvalid={fieldState.invalid}
                ErrorMessage="Select PayRoll Calendar type"
                withValidation
                options={payOptions}
                value={{
                  id: field.value,
                  name:
                    payOptions.find(
                      (payOptions) => payOptions.id === field.value
                    )?.name ?? "",
                }}
                onChange={(newValue: any) => field.onChange(newValue?.id)}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.employeeGroupId && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Employee Group
          </Text>
          <Controller
            control={EmploymentForm.control}
            name="employeeGroupId"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                // {...field}
                placeholder="Select Employment Group"
                // marginBottom="30px"
                // width={"48em"}
                // isInvalid={fieldState.invalid}
                ErrorMessage="Select Employment Group "
                withValidation
                options={employeeOptions}
                value={employeeOptions.find((op) => op.value === field.value)}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value);
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}{" "}
      {selectedEmployeeFilters.ratesId && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Ordinary earning rate
          </Text>
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "  Ordinary Earning is required",
            //   },
            // }}
            control={EmploymentForm.control}
            name="ratesId"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage=" Select Ordinary Earning"
                placeholder="Select..."
                options={earningAdded.map((earning) => ({
                  value: earning.id,
                  label: earning.name,
                }))}
                value={
                  earningAdded.find((earning) => earning.id === field.value)
                    ? {
                        value: field.value,
                        label: earningAdded.find(
                          (earning) => earning.id === field.value
                        ).name,
                      }
                    : null
                }
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.incomeType && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Employee Income Type
          </Text>
          <Controller
            control={EmploymentForm.control}
            name="empIncomeType"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder=""
                isInvalid={fieldState.invalid}
                ErrorMessage="Select department type"
                withValidation
                options={incomeTypeOptions}
                value={incomeTypeOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.joiningDate && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Joining Date
          </Text>
          <Controller
            control={EmploymentForm.control}
            name="startDate"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "date",

                  borderTopRadius: "3px",
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.department && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Department
          </Text>
          <Controller
            control={EmploymentForm.control}
            name="department"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.jobTitle && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Job title
          </Text>
          <Controller
            control={EmploymentForm.control}
            name="jobTitle"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.isManagementStaff && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Is Management Staff
          </Text>
          <Controller
            name="isManagementStaff"
            control={EmploymentForm.control}
            render={({ field, fieldState }) => (
              <RadioGroup {...field} value={field?.value?.toString()}>
                <HStack>
                  <Radio
                    isInvalid={fieldState.invalid}
                    size="lg"
                    value={"true"}
                    colorScheme="green"
                    lineHeight={"1.5rem"}
                    onChange={() =>
                      EmploymentForm.setValue("isManagementStaff", true)
                    }
                  >
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Yes
                    </Text>
                  </Radio>

                  <Radio
                    isInvalid={fieldState.invalid}
                    size="lg"
                    value={"false"}
                    colorScheme="red"
                    fontSize={"0.8125rem"}
                    fontWeight="600"
                    lineHeight={"1.5rem"}
                    onChange={() =>
                      EmploymentForm.setValue("isManagementStaff", false)
                    }
                  >
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      No
                    </Text>
                  </Radio>
                </HStack>
              </RadioGroup>
            )}
          />
        </Stack>
      )}
      {selectedEmployeeFilters.manager && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Line Manager
          </Text>
          <Controller
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Line Manager is required",
            //   },
            // }}
            control={EmploymentForm.control}
            name="manager"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                placeholder=" Line Manager Type"
                isInvalid={fieldState.invalid}
                ErrorMessage="Select Account Type"
                withValidation
                options={users?.map((user: any) => ({
                  value: user?.user?.id,
                  label: user?.user?.firstName + " " + user?.user?.lastName,
                }))}
                value={users
                  ?.map((user: any) => ({
                    value: user?.user?.id,
                    label: user?.user?.firstName + " " + user?.user?.lastName,
                  }))
                  .filter((option: any) =>
                    field?.value?.includes(option.value)
                  )}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value);
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
    </SimpleGrid>
  );
}
