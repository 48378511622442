import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { CustomInput } from "../../../../Common/CustomInput";

interface FilterModalProps {
  FilterData: {
    name: string;
    dataType?: string | undefined;
    startDate?: string | undefined;
    endDate?: string | undefined;
    startRate?: string | undefined;
    endRate?: string | undefined;
    StartKeyName?: string;
    EndKeyName?: string;
    singleProperty?: string;
    PayLoadDataType?: any;
  }[];
  isOpen: boolean;
  onClose: () => void;
}

export function FilterModal({ isOpen, onClose, FilterData }: FilterModalProps) {
  const form = useFormContext();
  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box h="50px">
          <ModalHeader
            alignItems="center"
            fontSize="18px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.250",
            }}
          >
            Filter
          </ModalHeader>
          <ModalCloseButton />
        </Box>

        <ModalBody maxH={"600px"} overflow={"auto"}>
          <Stack margin={"0px 0px 20px"} padding={"0px"}>
            <InputGroup ml={[0, "0px"]}>
              <InputLeftElement>
                <Icon as={FiSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
              />
            </InputGroup>
          </Stack>

          <Text
            fontSize={"16px"}
            fontWeight={"bold"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            paddingBottom={"6px"}
          >
            Columns
          </Text>
          <Accordion allowMultiple>
            <Stack border={"1px solid #e8e8e8"} gap={0}>
              {FilterData.map((item, i) => {
                return (
                  <AccordionItem key={i}>
                    <AccordionButton
                      padding={"8px"}
                      _hover={{
                        bg: "rgb(242, 243, 244)",
                      }}
                    >
                      <AccordionIcon
                        height="26px"
                        width="26px"
                        marginRight={"8px"}
                        _hover={{
                          bg: "rgba(0, 10, 30, 0.05);",
                          borderRadius: "50%",
                        }}
                      />
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"8px"}
                        fontSize={"15px"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                      >
                        {item.name}
                      </Box>
                    </AccordionButton>

                    <AccordionPanel padding={"16px"}>
                      {item.startRate !== undefined &&
                        item.endRate !== undefined && (
                          <HStack>
                            <Controller
                              control={form.control}
                              name={item.StartKeyName as string}
                              render={({ field }) => (
                                <CustomInput
                                  // {...field}
                                  ref={field.ref}
                                  input={{
                                    ...field,
                                    w: "100%",
                                    height: "38px",
                                    fontSize: ".845rem",
                                    padding: "12px 15px",
                                    variant: "outline",
                                    type: item.startRate,

                                    borderRightRadius: "none",
                                    borderTopLeftRadius: "3px",
                                    onChange: (e) => {
                                      field.onChange(
                                        item.PayLoadDataType(e.target.value)
                                      );
                                    },
                                  }}
                                  placeholder="Contain"
                                />
                              )}
                            />
                            {/* <CustomInput
                              input={{
                                w: "100%",
                                height: "38px",
                                fontSize: ".845rem", padding: "12px 15px",
                                variant: "outline",
                                type: item.startRate,
                                
                                borderRightRadius: "none",
                                borderTopLeftRadius: "3px",
                              }}
                              placeholder="Contain"
                            /> */}
                            <Text
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.white",
                              }}
                            >
                              {" "}
                              to{" "}
                            </Text>
                            <Controller
                              control={form.control}
                              name={item.EndKeyName as string}
                              render={({ field }) => (
                                <CustomInput
                                  ref={field.ref}
                                  input={{
                                    ...field,
                                    w: "100%",
                                    height: "38px",
                                    fontSize: ".845rem",
                                    padding: "12px 15px",
                                    variant: "outline",
                                    type: item.endRate,

                                    borderRightRadius: "none",
                                    borderTopLeftRadius: "3px",
                                    onChange: (e) => {
                                      field.onChange(
                                        item.PayLoadDataType(e.target.value)
                                      );
                                    },
                                  }}
                                  placeholder="Contain"
                                />
                              )}
                            />
                            {/* // <CustomInput
                            //   input={{
                            //     w: "100%",
                            //     height: "38px",
                            //     fontSize: ".845rem", padding: "12px 15px",
                            //     variant: "outline",
                            //     type: item.endRate,
                            //     
                            //     borderRightRadius: "none",
                            //     borderTopLeftRadius: "3px",
                            //   }}
                            //   placeholder="Contain"
                            // /> */}
                          </HStack>
                        )}
                      {item.dataType !== undefined && (
                        <Controller
                          control={form.control}
                          name={item.singleProperty as string}
                          render={({ field }) => (
                            <CustomInput
                              ref={field.ref}
                              input={{
                                ...field,
                                w: "100%",
                                height: "38px",
                                fontSize: ".845rem",
                                padding: "12px 15px",
                                variant: "outline",
                                type: item.dataType,

                                borderRightRadius: "none",
                                borderTopLeftRadius: "3px",
                                onChange: (e) => {
                                  field.onChange(
                                    item.PayLoadDataType(e.target.value)
                                  );
                                },
                              }}
                              placeholder="Contain"
                            />
                          )}
                        />
                        // <CustomInput
                        //   input={{
                        //     w: "100%",
                        //     height: "38px",
                        //     fontSize: ".845rem", padding: "12px 15px",
                        //     variant: "outline",
                        //     type: item.dataType,
                        //
                        //     borderRightRadius: "none",
                        //     borderTopLeftRadius: "3px",
                        //   }}
                        //   placeholder="Contain"
                        // />
                      )}
                      <HStack gap={0}>
                        {item.startDate !== undefined && (
                          <Controller
                            control={form.control}
                            name={item.StartKeyName as string}
                            render={({ field }) => (
                              <CustomInput
                                ref={field.ref}
                                input={{
                                  ...field,
                                  w: "100%",
                                  height: "38px",
                                  fontSize: ".845rem",
                                  padding: "12px 15px",
                                  variant: "outline",
                                  type: item.startDate,

                                  borderRightRadius: "none",
                                  borderTopLeftRadius: "3px",
                                  onChange: (e) => {
                                    field.onChange(
                                      item.PayLoadDataType(e.target.value)
                                    );
                                  },
                                }}
                                placeholder="any"
                              />
                            )}
                          />
                          // <CustomInput
                          //   input={{
                          //     w: "100%",
                          //     height: "38px",
                          //     fontSize: ".845rem", padding: "12px 15px",
                          //     variant: "outline",
                          //     type: item.startDate,
                          //
                          //     borderRightRadius: "none",
                          //     borderTopLeftRadius: "3px",
                          //   }}
                          //   placeholder="any"
                          // />
                        )}
                        {item.endDate !== undefined && (
                          <Controller
                            control={form.control}
                            name={item.EndKeyName as string}
                            render={({ field }) => (
                              <CustomInput
                                ref={field.ref}
                                input={{
                                  ...field,
                                  w: "100%",
                                  height: "38px",
                                  fontSize: ".845rem",
                                  padding: "12px 15px",
                                  variant: "outline",
                                  type: item.endDate,

                                  borderRightRadius: "none",
                                  borderTopLeftRadius: "3px",
                                  onChange: (e) => {
                                    field.onChange(
                                      item.PayLoadDataType(e.target.value)
                                    );
                                  },
                                }}
                                placeholder="any"
                              />
                            )}
                          />
                          // <CustomInput
                          //   input={{
                          //     w: "100%",
                          //     height: "38px",
                          //     fontSize: ".845rem", padding: "12px 15px",
                          //     variant: "outline",
                          //     type: item.endDate,
                          //
                          //     borderRightRadius: "none",
                          //     borderTopLeftRadius: "3px",
                          //   }}
                          //   placeholder="any"
                          // />
                        )}
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Stack>
          </Accordion>
        </ModalBody>
        <ModalFooter h="50px" w="100%">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={3}
            onClick={onClose}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
