import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { NotificationGroup } from "../../../Api/NotificationGroup";

interface CreateGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
}

export default function CreateGroupModal({
  isOpen,
  onClose,
  empId,
  setLastUpdated,
}: CreateGroupModalProps) {
  const { employeesList } = useBusinessContext();

  const form = useFormContext<NotificationGroup.NotificationGroup>();
  const toast = useToast();
  const allEmployeesOption = {
    id: "all",
    firstName: "All",
    lastName: "Employees",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Create Group
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTop={"1px solid #ddd"} borderBottom={"1px solid #ddd"}>
          <Controller
            name="name"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Group name is required",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                label="Title"
                labelProps={{
                  fontSize: "13px",
                  marginTop: "8px",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  padding: "12px 15px",
                  borderTopRadius: "3px",
                }}
                placeholder="Group Name"
              />
            )}
          />
          <Stack gap={0} marginY={"8px"}>
            <Text
              fontSize={"13px"}
              padding={"1px 0px 4px"}
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
              mb={"5px"}
            >
              Select Employees
            </Text>

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Employee is required",
                },
              }}
              control={form.control}
              name="employeeIds"
              render={({ field, fieldState }) => {
                return (
                  <ReactSelect
                    {...field}
                    withValidation
                    isMulti
                    closeMenuOnSelect={false}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please Select Employee"
                    placeholder="Select Employee"
                    options={[allEmployeesOption, ...(employeesList || [])]}
                    value={
                      field.value.includes("all")
                        ? [allEmployeesOption]
                        : employeesList?.filter((emp: any) =>
                            field.value?.includes(emp.id)
                          ) ?? []
                    }
                    onChange={(val: any) => {
                      if (val.some((emp: any) => emp.id === "all")) {
                        field.onChange(
                          employeesList &&
                            employeesList.map((emp: any) => emp.id)
                        );
                      } else {
                        field.onChange(val.map((emp: any) => emp.id));
                      }
                    }}
                    getOptionLabel={(option: any) => {
                      return option.id === "all"
                        ? "All Employees"
                        : `${option?.firstName} ${option?.lastName}`;
                    }}
                    getOptionValue={(option: any) => option.id}
                    // options={employeesList}
                    // value={
                    //   field.value
                    //     ? {
                    //         id: field.value,
                    //         firstName:
                    //           employeesList?.find(
                    //             (emp: any) => emp.id === field.value
                    //           )?.firstName ?? "",
                    //         lastName:
                    //           employeesList?.find(
                    //             (emp: any) => emp.id === field.value
                    //           )?.lastName ?? "",
                    //       }
                    //     : undefined
                    // }
                    // onChange={(val: any) => {
                    //   field.onChange(val?.id);
                    // }}
                    // getOptionLabel={(option: any) =>
                    //   option.firstName + " " + option.lastName
                    // }
                    // getOptionValue={(option: any) => option.id}
                  />
                );
              }}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={form.handleSubmit((data) => {
              if (form.getValues("id")) {
                NotificationGroup.UpdateNotificationGroup(
                  form.getValues("id")!,
                  data,
                  (response) => {
                    setLastUpdated(Date.now());
                    onClose();
                    toast({
                      title: "Group Updated",
                      status: "success",
                      duration: 2000,
                    });
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      description: err,
                      status: "error",
                      duration: 2000,
                    });
                  }
                );
              } else {
                NotificationGroup.CreateNotificationGroup(
                  data,
                  (response) => {
                    setLastUpdated(Date.now());
                    onClose();
                    toast({
                      title: "Group Created",
                      status: "success",
                      duration: 2000,
                    });
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      description: err,
                      status: "error",
                      duration: 2000,
                    });
                  }
                );
              }
            })}
          >
            {form.getValues("id") ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
