import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";

export function ImportForInvoices() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const handleDelete = () => {
    // setImportData([]);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  return (
    <Stack h="calc(100vh - 70px)" overflowY="auto" gap={0}>
      <Stack
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bg: "customColor.gray.450",
        }}
        padding={"10px 21.2px 5.28px"}
      >
        <Container maxW={"980px"} padding={"0"}>
          <Stack gap={0}>
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink href="" fontSize={"13px"} color={"#3454d1"}>
                  Sales overview
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="" fontSize={"13px"} color={"#3454d1"}>
                  invoices
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Text
              fontWeight={"bold"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black7",
              }}
              fontSize={"24px"}
            >
              Import your sales invoices
            </Text>
          </Stack>
        </Container>
      </Stack>
      <Container maxW={"980px"} padding={"21.12px 0px"}>
        <Stack direction={"row"}>
          <Box
            bg="#E8F1FF"
            flex={7}
            marginRight={"40px"}
            gap={0}
            border={"1px solid #c1c1c1"}
            h={"fit-content"}
          >
            <Stack padding={"10px 0px"} gap={0}>
              <Stack marginLeft={"10px"} marginRight={"10px"} gap={0}>
                <Stack padding={"0px"} gap={0}>
                  <Text
                    fontWeight={"700"}
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    What system are you importing from?
                  </Text>
                  <RadioGroup>
                    <Stack gap={0}>
                      <Radio value="1" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Hris360
                        </Text>
                      </Radio>
                      <Radio value="2" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          MYOB - Service Sales Format
                        </Text>
                      </Radio>
                      <Radio value="3" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          MYOB - Item Sales Format
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
                <Stack paddingTop={"10px"} marginTop={"5px"} gap={0}>
                  <Text
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    paddingBottom={"10px"}
                  >
                    To import invoices from another system please follow the
                    steps below...
                  </Text>
                  <Text
                    fontSize={"14.3px"}
                    fontWeight={"bold"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    margin={"0px 0px 5px"}
                    padding={"0px 0px 3.575px"}
                  >
                    Step 1. Download our invoices template file
                  </Text>
                  <Text
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Start by downloading our invoices CSV (Comma Separated
                    Values) template file. This file has the correct column
                    headings Hris360 needs to import your invoice data.
                  </Text>
                  <HStack
                    margin={"5px 0px 0px"}
                    alignItems={"center"}
                    gap={0}
                    marginBottom={"20px"}
                  >
                    <SiMicrosoftexcel width={"16px"} height={"16px"} />
                    <Link
                      color={"#3454d1"}
                      fontSize={"12px"}
                      paddingLeft={"3px"}
                    >
                      Download template file
                    </Link>
                  </HStack>
                  <Text
                    fontSize={"14.3px"}
                    fontWeight={"bold"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    margin={"0px 0px 5px"}
                    padding={"0px 0px 3.575px"}
                  >
                    Step 2. Copy your invoices into the template
                  </Text>
                  <Text
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Export your invoices from your old system as a comma
                    separated list. Using Excel or another spreadsheet editor,
                    copy and paste your invoices from the exported file into the
                    Hris360 template. Make sure the invoice data you copy
                    matches the column headings provided in the template.
                  </Text>
                  <Text
                    marginTop={"5px"}
                    fontSize={"12px"}
                    _light={{
                      color: "#BF4900",
                    }}
                  >
                    <b>IMPORTANT:</b> Do not change the column headings provided
                    in the Hris360 template. These need to be unchanged for the
                    import to work in the next step.
                  </Text>
                  <Text
                    marginTop={"5px"}
                    fontSize={"12px"}
                    _light={{
                      color: "#BF4900",
                    }}
                    marginBottom={"20px"}
                  >
                    Dates are assumed to be in English (Australia) format. For
                    example, 25/12/2024 or 25 Dec 2024.
                  </Text>
                  <Text
                    fontSize={"14.3px"}
                    fontWeight={"bold"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    margin={"0px 0px 5px"}
                    padding={"0px 0px 3.575px"}
                  >
                    Step 3. Import the updated template file
                  </Text>
                  <Text
                    fontSize={"12px"}
                    paddingBottom={"5px"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Select the file to import
                  </Text>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Button
                      backgroundColor={"#fff"}
                      borderRadius={"3px"}
                      border={"1px solid #dcdee4"}
                      fontSize={"13px"}
                      variant={"solid"}
                      textColor={"customColor.black7"}
                      padding={"12px 16px"}
                      fontWeight={600}
                      _hover={{
                        backgroundColor: "#fff",
                        color: "#3454D1",
                      }}
                      onClick={() => {
                        if (fileInputRef.current) {
                          fileInputRef.current.click();
                        }
                      }}
                    >
                      Browse
                    </Button>{" "}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <Text
                      fontSize={"11px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      no file selected
                    </Text>
                  </Stack>
                  <Text
                    fontSize={"12px"}
                    padding={"5px 0px 15px"}
                    _light={{
                      color: "customColor.gray.850",
                    }}
                  >
                    The file you import must be a CSV (Comma Separated Values)
                    file. The name of your file should end with either{" "}
                    <b>.csv</b> or <b>.txt</b>
                  </Text>
                  <Text
                    fontSize={"11px"}
                    _light={{
                      color: "customColor.gray.850",
                    }}
                  >
                    Would you like to update contact address details?
                  </Text>
                  <RadioGroup>
                    <Stack gap={0}>
                      <Radio value="1" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.gray.850",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          No, ignore all address details
                        </Text>
                      </Radio>
                      <Radio value="2" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.gray.850",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Yes, update contacts with imported address details
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Text
                    fontSize={"11px"}
                    paddingTop={"20px"}
                    _light={{
                      color: "customColor.gray.850",
                    }}
                  >
                    Is the UnitAmount field tax inclusive or exclusive?
                  </Text>
                  <RadioGroup>
                    <Stack gap={0}>
                      <Radio value="1" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.gray.850",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Tax Exclusive
                        </Text>
                      </Radio>
                      <Radio value="2" margin={"5px 0px"}>
                        <Text
                          fontSize={"12px"}
                          _light={{
                            color: "customColor.gray.850",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Tax Inclusive
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              bg={"#D7E7FF"}
              padding={"8px 0px 10px"}
              alignItems={"flex-end"}
            >
              <Stack direction={"row"} padding={"0px 10px 0px 10px"}>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                >
                  Import
                </Button>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box
            h={"fit-content"}
            // bg="#FFFAEA"
            padding={"10px 15px"}
            flex={5}
            // border={"1px solid #fbdbc2"}
            gap={0}
          ></Box>
        </Stack>
      </Container>
    </Stack>
  );
}
