import {
  Avatar,
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import { MdCancel, MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { UserProfileApi } from "../../Api/UserProfile";

export function UserProfile() {
  const [showPhoneNumber, setShowPhoneNumber] = useState(true);
  const { currentEmployeeId } = useBusinessContext();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const toast = useToast();
  let countryData = Country.getAllCountries();
  const form = useForm<UserProfileApi.UserDetail>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      country: "",
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    UserProfileApi.GetUserProfile(
      (data) => {
        form.reset(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <HStack
          _light={{
            bg: "#fff",
            boxShadow:
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
          }}
          _dark={{
            bg: "customColor.dark.50",
          }}
          justifyContent={"space-between"}
          minH={"65px"}
          padding={"0 30px"}
        >
          <Heading
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"18px"}
          >
            Profile Settings
          </Heading>
          <Stack direction={"row"}>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={form.handleSubmit(
                (data) => {
                  UserProfileApi.UpdateUserProfile(
                    data,
                    (success) => {
                      toast({
                        title: "User Profile Updated",
                        status: "success",
                      });
                      decodedToken.accessLevel !== "EMPLOYEE" &&
                        navigate("/app/payroll/Dashboard");
                      decodedToken.accessLevel === "EMPLOYEE" &&
                        navigate(
                          `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                        );
                    },

                    (error) => {
                      toast({
                        title: "User Profile Update Failed",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",
                    status: "error",
                  });
                }
              )}
            >
              Save
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                decodedToken.accessLevel !== "EMPLOYEE" &&
                  navigate("/app/payroll/Dashboard");
                decodedToken.accessLevel === "EMPLOYEE" &&
                  navigate(
                    `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                  );
              }}
            >
              Cancel
            </Button>
          </Stack>
        </HStack>

        <Container
          maxW={"container.xl"}
          padding={"30px 42px 5px 42px"}
          h="calc(100vh - 135px)"
          overflowY="auto"
        >
          <Stack
            marginBottom={"24px"}
            gap={0}
            transition={"all .3s ease"}
            boxShadow={
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
            }
            borderRadius={"10px"}
            _light={{
              bg: "#fff",
            }}
            padding={"25px"}
          >
            <Heading
              _light={{
                color: "customColor.black7",
              }}
              _dark={{ color: "customColor.white" }}
              fontSize={"16px"}
              marginBottom={"24px"}
            >
              Basic Information
            </Heading>

            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Profile Image:
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <Avatar w={"99px"} h={"99px"} borderRadius={"5px"} />
                <Box>
                  <Button
                    backgroundColor={"transparent"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    variant={"solid"}
                    textColor={"customColor.black7"}
                    padding={"12px 16px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                      color: "#3454D1",
                    }}
                  >
                    Upload Image
                  </Button>
                </Box>
              </Stack>
            </Stack>
            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Name
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <Stack direction={"row"} alignItems={"baseline"}>
                  <InputGroup>
                    <InputLeftAddon h={"38px"}>
                      <FiUser />
                    </InputLeftAddon>
                    <Controller
                      control={form.control}
                      name="firstName"
                      render={({ field, fieldState: { invalid } }) => (
                        // <CustomInput
                        //   ref={field.ref}
                        //   validationMessage={error?.message}
                        //   withValidation
                        //   input={{
                        //     ...field,
                        //     isInvalid: error !== undefined,
                        //     h: "45px",
                        //     w: "100%",
                        //     fontSize: ".845rem",
                        //     padding: "12px 15px",
                        //     _light: {
                        //       color: "customColor.black7",
                        //     },
                        //     variant: "outline",
                        //     type: "text",

                        //     borderRadius: "5px",
                        //   }}
                        //   placeholder=""
                        // />
                        <Input
                          placeholder="First Name"
                          borderRadius={"0px"}
                          borderRightRadius={"5px"}
                          h={"38px"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _light={{
                            color: "customColor.black7",
                          }}
                          padding="12px 15px"
                          border="1px solid #e5e7eb"
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          isInvalid={invalid}
                          {...field}
                        />
                      )}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon h={"38px"}>
                      <FiUser />
                    </InputLeftAddon>
                    <Controller
                      control={form.control}
                      name="lastName"
                      render={({ field, fieldState: { invalid } }) => (
                        // <CustomInput
                        //   ref={field.ref}
                        //   validationMessage={error?.message}
                        //   withValidation
                        //   input={{
                        //     ...field,
                        //     isInvalid: error !== undefined,
                        //     h: "30px",

                        //     fontSize: "12px",
                        //     paddingLeft: "5px",
                        //     variant: "outline",
                        //     type: "text",
                        //     borderWidth: "1px",
                        //     borderStyle: "solid",
                        //     borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                        //     borderTopRadius: "0",
                        //   }}
                        //   placeholder=""
                        // />
                        <Input
                          placeholder="Last Name"
                          borderRadius={"0px"}
                          borderRightRadius={"5px"}
                          h={"38px"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _light={{
                            color: "customColor.black7",
                          }}
                          padding="12px 15px"
                          border="1px solid #e5e7eb"
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          isInvalid={invalid}
                          {...field}
                        />
                      )}
                    />
                  </InputGroup>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Email
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <InputGroup>
                  <InputLeftAddon h={"38px"}>
                    <MdOutlineMailOutline />
                  </InputLeftAddon>
                  <Controller
                    control={form.control}
                    name="email"
                    render={({ field, fieldState: { invalid } }) => (
                      // <CustomInput
                      //   ref={field.ref}
                      //   validationMessage={error?.message}
                      //   withValidation
                      //   input={{
                      //     ...field,
                      //     isInvalid: error !== undefined,
                      //     h: "30px",
                      //     w: "386px",

                      //     fontSize: "12px",
                      //     paddingLeft: "5px",
                      //     variant: "outline",
                      //     type: "text",
                      //     borderWidth: "1px",
                      //     borderStyle: "solid",
                      //     borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                      //     borderTopRadius: "0",
                      //   }}
                      //   placeholder=""
                      // />
                      <Input
                        placeholder="Email"
                        borderRadius={"0px"}
                        borderRightRadius={"5px"}
                        h={"38px"}
                        fontSize=".845rem"
                        fontWeight={400}
                        _light={{
                          color: "customColor.black7",
                        }}
                        border="1px solid #e5e7eb"
                        _focus={{
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _hover={{
                          border: "1px solid hsl(0, 0%, 70%)",
                        }}
                        _focusVisible={{
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        isInvalid={invalid}
                        {...field}
                      />
                    )}
                  />
                </InputGroup>
              </Stack>
            </Stack>
            <Stack
              marginBottom={"24px"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack flex={2}>
                <Text
                  fontSize={"13.44px"}
                  _light={{ color: "customColor.gray.64748B" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"600"}
                >
                  Location
                </Text>
              </Stack>
              <Stack flex={4} paddingLeft={"12px"}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Country is required",
                    },
                  }}
                  control={form.control}
                  name="country"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select Country"
                      // marginBottom="30px"
                      // width={"48em"}

                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Country"
                      withValidation
                      options={countryData}
                      value={countryData.find((op) => op.name === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.name)
                      }
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </Stack>
            </Stack>

            {showPhoneNumber && (
              <Stack
                marginBottom={"24px"}
                direction={"row"}
                alignItems={"center"}
              >
                <Stack flex={2}>
                  <Text
                    fontSize={"13.44px"}
                    _light={{ color: "customColor.gray.64748B" }}
                    _dark={{ color: "customColor.white" }}
                    fontWeight={"600"}
                  >
                    Phone Number
                  </Text>
                </Stack>
                <Stack
                  flex={4}
                  direction={"row"}
                  alignItems={"center"}
                  paddingLeft={"12px"}
                >
                  <InputGroup>
                    <InputLeftAddon h={"38px"}>
                      <MdOutlinePhone />
                    </InputLeftAddon>

                    <Controller
                      control={form.control}
                      name="phone"
                      render={({ field, fieldState: { invalid } }) => (
                        // <CustomInput
                        //   ref={field.ref}
                        //   validationMessage={error?.message}
                        //   withValidation
                        //   input={{
                        //     ...field,
                        //     isInvalid: error !== undefined,
                        //     h: "30px",

                        //     fontSize: "12px",
                        //     paddingLeft: "5px",
                        //     variant: "outline",
                        //     type: "text",
                        //     borderWidth: "1px",
                        //     borderStyle: "solid",
                        //     borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                        //     borderTopRadius: "0",
                        //   }}
                        //   placeholder=""
                        // />
                        <Input
                          placeholder="Phone Number"
                          borderRadius={"0px"}
                          h={"38px"}
                          fontSize=".845rem"
                          fontWeight={400}
                          _light={{
                            color: "customColor.black7",
                          }}
                          border="1px solid #e5e7eb"
                          _focus={{
                            borderWidth: "1px",

                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          _hover={{
                            border: "1px solid hsl(0, 0%, 70%)",
                          }}
                          _focusVisible={{
                            borderWidth: "1px",
                            boxShadow: "0 0 0 1px #2684FF",
                            _hover: {
                              borderWidth: "1px",
                              borderColor: "#2684FF",
                              boxShadow: "0 0 0 1px #2684FF",
                            },
                          }}
                          isInvalid={invalid}
                          {...field}
                        />
                      )}
                    />
                    <InputRightAddon h={"38px"}>
                      {" "}
                      <Icon
                        as={MdCancel}
                        color={"primary.950"}
                        _hover={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowPhoneNumber(false)}
                      />
                    </InputRightAddon>
                  </InputGroup>
                </Stack>
              </Stack>
            )}
            {/* {showMobileNumber && ( */}
            {/* <Stack
                padding={"5px 0px 7px"}
                direction={"row"}
                borderBottom={"1px solid #ebeeef"}
                alignItems={"center"}
              >
                <Stack flex={1}>
                  <Text fontSize={"12px"} _light={{ color: "customColor.black4" }}
                _dark={{ color: "customColor.white" }} fontWeight={"600"}>
                    Mobile Number
                  </Text>
                </Stack>
                <Stack flex={4} direction={"row"} alignItems={"center"}>
                  <Stack w={"386px"} direction={"row"}>
                    <Controller
                      control={form.control}
                      name="phone"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                       ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            h: "30px",
                           
                            fontSize: "12px",
                            paddingLeft: "5px",
                            variant: "outline",
                            type: "text",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                            borderTopRadius: "0",
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                  
                </Stack>
              </Stack> */}
            {/* )} */}

            {/* <Stack
                padding={"5px 0px 7px"}
                direction={"row"}
                alignItems={"center"}
              >
                <Stack flex={1}>
                  <Text fontSize={"12px"} _light={{ color: "customColor.black4" }}
                _dark={{ color: "customColor.white" }} fontWeight={"600"}>
                    Password
                  </Text>
                </Stack>
                <Stack flex={4} direction={"row"} alignItems={"center"}>
                  <Stack w={"386px"} direction={"row"}>
                    <Controller
                      control={form.control}
                      name="password"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                       ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            h: "30px",
                           
                            fontSize: "12px",
                            paddingLeft: "5px",
                            variant: "outline",
                            type: "text",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                            borderTopRadius: "0",
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack> */}
          </Stack>
        </Container>
      </Stack>
    </FormProvider>
  );
}
