import { axiosInstance } from "./axiosInstance-hris";
declare module PayItemEarningApi {
  type AddNewEarning = {
    id?: string;
    category: string;
    name: string;
    displayName: string;
    rateType: string;
    fixedAmount: number;
    chartAccountId: string;
    isTaxable: boolean;
    retirementFundExcemption: boolean;
    reportableActivityStatement: boolean;
    multiple: number;
    rateUnitType: string;
    active: boolean;
    unitRate?: number;
    allowanceType: string;
    baseSalaryPercentage: number;
    allowancesPercentage: number;
  };
  type EarningById = {
    id: string;
    category: string;
    name: string;
    rateType: string;
    fixedAmount: number;
    isTaxable?: boolean;
    reportableActivityStatement: boolean;
    multiple: number;
    rateUnitType: string;
    unitRate: string;
    allowancesPercentage?: number;
    baseSalaryPercentage?: number;
    allowancesPercentageId?: string;
    baseSalaryId?: string;
    active: boolean;
    chartOfAccount: {
      id: string;
      code: number;
      name: string;
    };
  };
}
module PayItemEarningApi {
  const API_URL = "/payroll-pay-items-earnings";
  export function NewEarningAdded(
    data: AddNewEarning,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchEarning(
    success: (data: Array<EarningById>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetTimeSheetPayItems(
    success: (data: Array<EarningById>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/timesheet-pay-items`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchEarningByID(
    id: string,
    success: (data: AddNewEarning) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateEarning(
    id: string,
    data: Partial<AddNewEarning>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function IsActive(
    id: string,
    isActive: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/status/${id}/${isActive}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteEarning(
    id: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
}
export { PayItemEarningApi };
