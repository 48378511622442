import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";

import { differenceInYears, isAfter } from "date-fns";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { EmployeeEducation } from "../../../../../Api/EmployeeEducation";

interface EducationModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
}
export default function EducationModal({
  isOpen,
  onClose,
  empId,
  setLastUpdated,
}: EducationModalProps) {
  const QualificationOptions: Array<{ label: string; value: string }> = [
    {
      label: "Masters",
      value: "Masters",
    },
    {
      label: "Bachelors",
      value: "Bachelors",
    },
    {
      label: "Diploma",
      value: "Diploma",
    },
    {
      label: "Intermediate",
      value: "Intermediate",
    },
    {
      label: "Matriculation",
      value: "Matriculation",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];
  const EducationType: Array<{ label: string; value: string }> = [
    {
      label: "Online Course",
      value: "ONLINE_COURSE",
    },
    {
      label: "Attended School  ",
      value: "ATTENDED_SCHOOL",
    },
  ];
  let countryData = Country.getAllCountries();
  const form = useFormContext<EmployeeEducation.AddEducation>();
  const toast = useToast();
  const today = new Date(); // Get the current date

  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Education
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTop={"1px solid #ddd"} borderBottom={"1px solid #ddd"}>
          <Box>
            <Flex justifyContent="space-between" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Qualification
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="qualification"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Qualification"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Qualification"
                      withValidation
                      options={QualificationOptions}
                      value={QualificationOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Course Name
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Course Name is required",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  name="courseName"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex
              justifyContent="space-between"
              mt="16px"
              alignItems={"center"}
            >
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Institution Name
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Institution Name is required",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  name="instituteName"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex
              justifyContent="space-between"
              mt="16px"
              alignItems={"center"}
            >
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Education Type
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="educationType"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Education type"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Education type"
                      withValidation
                      options={EducationType}
                      value={EducationType.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex
              justifyContent="space-between"
              mt="16px"
              alignItems={"center"}
            >
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Country of Institution
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{ required: true }}
                  name="country"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Country"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select country"
                      withValidation
                      options={countryData}
                      value={countryData.find((op) => op.name === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.name)
                      }
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Date From
              </Text>
              <Box w="380px">
                <Controller
                  rules={{
                    required: "This field is required",
                    validate: {
                      isNotFuture: (value) =>
                        isAfter(today, new Date(value)) ||
                        "Date cannot be a future date",
                      isBeforeDateTo: (value) =>
                        !form.getValues("dateTo") ||
                        isAfter(
                          new Date(form.getValues("dateTo")),
                          new Date(value)
                        ) ||
                        "Start date must be before end date",
                      qualificationDuration: (value) => {
                        const qualification = form.getValues("qualification");
                        const yearsDifference = differenceInYears(
                          new Date(form.getValues("dateTo")),
                          new Date(value)
                        );
                        if (
                          qualification === "Masters" &&
                          yearsDifference < 1
                        ) {
                          return "Masters qualification must be at least 1 year";
                        } else if (
                          qualification === "Bachelors" &&
                          yearsDifference < 3
                        ) {
                          return "Bachelors qualification must be at least 3 years";
                        } else if (
                          qualification === "Intermediate" &&
                          yearsDifference < 2
                        ) {
                          return "Intermediate qualification must be at least 2 years";
                        } else if (
                          qualification === "Matriculation" &&
                          yearsDifference < 2
                        ) {
                          return "Matriculation qualification must be at least 2 years";
                        } else if (
                          qualification === "Diploma" &&
                          yearsDifference < 2
                        ) {
                          return "Diploma qualification must be at least 2 years";
                        } else if (
                          qualification === "Others" &&
                          yearsDifference < 2
                        ) {
                          return "Other qualification must be at least 2 years";
                        }
                        return true;
                      },
                    },
                  }}
                  control={form.control}
                  name="dateFrom"
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={fieldState.error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "date",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Date to
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{
                    required: "This field is required",
                    validate: {
                      isNotFuture: (value) =>
                        isAfter(today, new Date(value)) ||
                        "Date cannot be a future date",
                      isAfterDateFrom: (value) =>
                        !form.getValues("dateFrom") ||
                        isAfter(
                          new Date(value),
                          new Date(form.getValues("dateFrom"))
                        ) ||
                        "End date must be after start date",
                    },
                  }}
                  name="dateTo"
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={fieldState.error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "date",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (form.getValues("id")) {
                  EmployeeEducation.UpdateEducation(
                    form.getValues("id")!,
                    data,
                    (success) => {
                      toast({
                        title: "Education Updated",
                        status: "success",
                      });
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: err,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                } else {
                  EmployeeEducation.PostEducation(
                    empId!,
                    data,
                    (success) => {
                      toast({
                        title: "Education Added",
                        status: "success",
                      });
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: err,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {isLoading ? "Saving" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
