import { Divider, Stack, Text, useToast } from "@chakra-ui/react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import SetupGuideItems from "./SetupGuideItems";

export default function SetupGuideComponent() {
  const user = useAuthUser();
  const UserDetail = user();

  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Stack padding={"16px"} gap={0}>
      <Stack padding={"8px"} gap={0}>
        <Text
          _light={{
            color: "customColor.black5",
          }}
          _dark={{
            color: "customColor.white",
          }}
          margin={"0px 0px 16px"}
          fontSize={"20px"}
          fontWeight={"700"}
        >
          Welcome to HRIS-360
        </Text>
        <Text
          _light={{
            color: "customColor.black5",
          }}
          _dark={{
            color: "customColor.white",
          }}
          margin={"0px 0px 16px"}
          fontSize={"16px"}
          fontWeight={"400"}
        >
          Hi, this guide shows you where to start and how to get the most out of
          HRIS
        </Text>
        <SetupGuideItems />
      </Stack>

      <Divider
        margin={"16px 0px"}
        _light={{
          bg: "000A1E1A",
        }}
        _dark={{
          bg: "customColor.white",
        }}
      />
    </Stack>
  );
}
