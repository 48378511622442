import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IoIosArrowDown } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
interface DateRangeProps {
  startDateKey: string;
  endDateKey: string;
}
export function DateRange({ startDateKey, endDateKey }: DateRangeProps) {
  // const [selectedDateStart, setDateRangeStart] = useState("");
  // const [selectedDateEnd, setDateRangeEnd] = useState("");
  const [selectedDateRangeType, setSelectedDateRangeType] =
    useState("This month");

  // const handleDateRangeStart = (date: any) => {
  //   setDateRangeStart(date);
  // };
  // const handleDateRangeEnd = (date: any) => {
  //   setDateRangeEnd(date);
  // };
  const handleDateRangeSelectionType = (option: any) => {
    setSelectedDateRangeType(option);
  };
  const form = useFormContext();
  return (
    <Stack margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
      <HStack gap={0}>
        <Text
          fontSize="13px"
          display={"block"}
          fontWeight="600"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Date Range
        </Text>
        <Text
          paddingLeft={"3px"}
          fontSize="13px"
          display={"block"}
          fontWeight="400"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          ({selectedDateRangeType})
        </Text>
      </HStack>

      <HStack gap={0}>
        <Stack w={"42.5%"}>
          <Controller
            control={form.control}
            // name="filters.dateRange.from"
            name={startDateKey}
            defaultValue={moment().startOf("month").format("YYYY-MM-DD")}
            rules={{
              onChange(event) {
                if (event.target.value === "") {
                  form.setValue(startDateKey, undefined);
                }
              },
            }}
            render={({ field }) => (
              <CustomInput
                input={{
                  h: "38px",
                  fontSize: "0.845rem",
                  padding: "12px 15px",
                  _hover: {
                    border: "1px solid hsl(0, 0%, 70%)",
                  },
                  _focus: {
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  },
                  bg: "#fff",
                  variant: "outline",
                  type: "date",

                  borderLeftRadius: "5px",
                  max: "9999-12-31",
                  value: field.value,
                  onChange: field.onChange,
                }}
              />
            )}
          />
        </Stack>

        <Stack w={"42.5%"}>
          <Controller
            control={form.control}
            // name="filters.dateRange.to"
            name={endDateKey}
            defaultValue={moment().endOf("month").format("YYYY-MM-DD")}
            rules={{
              onChange(event) {
                if (event.target.value === "") {
                  form.setValue(endDateKey, undefined);
                }
              },
            }}
            render={({ field }) => (
              <CustomInput
                input={{
                  h: "38px",

                  bg: "#fff",
                  variant: "outline",
                  _hover: {
                    border: "1px solid hsl(0, 0%, 70%)",
                  },
                  _focus: {
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  },
                  type: "date",
                  fontSize: "0.845rem",
                  padding: "12px 15px",
                  max: "9999-12-31",
                  value: field.value,
                  onChange: field.onChange,
                }}
              />
            )}
          />
        </Stack>

        <Menu>
          <MenuButton
            w={"15%"}
            as={IconButton}
            h={"38px"}
            _light={{
              bgColor: "customColor.white",
              color: "customColor.black7",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            fontWeight={"400"}
            borderTop={"1px solid #e5e7eb"}
            borderRight={"1px solid #e5e7eb"}
            borderBottom={"1px solid #e5e7eb"}
            borderRightRadius={"4px"}
            _hover={{
              color: "customColor.black7",
              bg: "#f2f3f4",
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _active={{
              color: "customColor.black7",
              bg: "#f2f3f4",
              borderWidth: "1px",
              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
            icon={<IoIosArrowDown />}
          />

          <MenuList
            overflowY="auto"
            zIndex={"overlay"}
            minW={"300px"}
            maxH={"400px"}
            overflow={"auto"}
          >
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("This month");
                  form.setValue(
                    startDateKey,
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  // handleDateRangeStart(
                  //   moment().startOf("month").format("YYYY-MM-DD")
                  // );
                  form.setValue(
                    endDateKey,
                    moment().endOf("month").format("YYYY-MM-DD")
                  );
                  // handleDateRangeEnd(
                  //   moment().endOf("month").format("YYYY-MM-DD")
                  // );
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    This month
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment().startOf("month").format("Do MMM YYYY")} -{" "}
                    {moment().endOf("month").format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("This quarter");
                  form.setValue(
                    startDateKey,
                    moment().startOf("quarter").format("YYYY-MM-DD")
                  );
                  // handleDateRangeStart(
                  //   moment().startOf("quarter").format("YYYY-MM-DD")
                  // );
                  form.setValue(
                    endDateKey,
                    moment().endOf("quarter").format("YYYY-MM-DD")
                  );
                  // handleDateRangeEnd(
                  //   moment().endOf("quarter").format("YYYY-MM-DD")
                  // );
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    This quarter
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment().startOf("quarter").format("Do MMM YYYY")} -{" "}
                    {moment().endOf("quarter").format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>

            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("This financial year");
                  form.setValue(
                    startDateKey,
                    moment().startOf("year").format("YYYY-MM-DD")
                  );
                  form.setValue(
                    endDateKey,
                    moment().endOf("year").format("YYYY-MM-DD")
                  );
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    This financial year
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment().startOf("year").format("Do MMM YYYY")} -{" "}
                    {moment().endOf("year").format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            <Stack
              borderBottom="1px solid #ccced2"
              marginBottom={"12px"}
              marginTop={"12px"}
            />
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("Last Month");
                  form.setValue(
                    startDateKey,
                    moment()
                      .subtract(1, "months")
                      .startOf("month")
                      .format("YYYY-MM-DD")
                  );
                  // handleDateRangeStart(
                  //   moment()
                  //     .subtract(1, "months")
                  //     .startOf("month")
                  //     .format("YYYY-MM-DD")
                  // );
                  form.setValue(
                    endDateKey,
                    moment()
                      .subtract(1, "months")
                      .endOf("month")
                      .format("YYYY-MM-DD")
                  );
                  // handleDateRangeEnd(
                  //   moment()
                  //     .subtract(1, "months")
                  //     .endOf("month")
                  //     .format("YYYY-MM-DD")
                  // );
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Last Month
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment()
                      .subtract(1, "months")
                      .startOf("month")
                      .format("Do MMM YYYY")}{" "}
                    -{" "}
                    {moment()
                      .subtract(1, "months")
                      .endOf("month")
                      .format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("Last quarter");
                  form.setValue(
                    startDateKey,
                    moment()
                      .subtract(1, "quarters")
                      .startOf("quarter")
                      .format("YYYY-MM-DD")
                  );
                  form.setValue(
                    endDateKey,
                    moment()
                      .subtract(1, "quarters")
                      .endOf("quarter")
                      .format("YYYY-MM-DD")
                  );
                  // handleDateRangeStart(
                  //   moment()
                  //     .subtract(1, "quarters")
                  //     .startOf("quarter")
                  //     .format("YYYY-MM-DD")
                  // );
                  // handleDateRangeEnd(
                  //   moment()
                  //     .subtract(1, "quarters")
                  //     .endOf("quarter")
                  //     .format("YYYY-MM-DD")
                  // );
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Last quarter
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment()
                      .subtract(1, "quarters")
                      .startOf("quarter")
                      .format("Do MMM YYYY")}{" "}
                    -{" "}
                    {moment()
                      .subtract(1, "quarters")
                      .endOf("quarter")
                      .format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("Last financial year");
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Last financial year
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  ></Text>
                </HStack>
              </HStack>
            </MenuItem>
            <Stack
              borderBottom="1px solid #ccced2"
              marginBottom={"12px"}
              marginTop={"12px"}
            />
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("Month to date");
                  form.setValue(
                    startDateKey,
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  form.setValue(endDateKey, moment().format("YYYY-MM-DD"));
                  // handleDateRangeStart(
                  //   moment().startOf("month").format("YYYY-MM-DD")
                  // );
                  // handleDateRangeEnd(moment().format("YYYY-MM-DD"));
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Month to date
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment().startOf("month").format("Do MMM YYYY")} -{" "}
                    {moment().format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("Quarter to date");
                  form.setValue(
                    startDateKey,
                    moment().startOf("quarter").format("YYYY-MM-DD")
                  );
                  form.setValue(endDateKey, moment().format("YYYY-MM-DD"));
                  // handleDateRangeStart(
                  //   moment().startOf("quarter").format("YYYY-MM-DD")
                  // );
                  // handleDateRangeEnd(moment().format("YYYY-MM-DD"));
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Quarter to date
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment().startOf("quarter").format("Do MMM YYYY")} -
                    {moment().format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            <MenuItem
              key={1}
              padding={"0px 12px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.1)",
              }}
            >
              <HStack
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  handleDateRangeSelectionType("Year to date");
                  form.setValue(
                    startDateKey,
                    moment().startOf("year").format("YYYY-MM-DD")
                  );
                  form.setValue(endDateKey, moment().format("YYYY-MM-DD"));
                  // handleDateRangeStart(
                  //   moment().startOf("year").format("YYYY-MM-DD")
                  // );
                  // handleDateRangeEnd(moment().format("YYYY-MM-DD"));
                }}
              >
                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Year to date
                  </Text>
                </HStack>

                <HStack alignItems="center">
                  <Text
                    padding={"8px"}
                    margin={"0px 0px 0px 12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {moment().startOf("year").format("Do MMM YYYY")} -{" "}
                    {moment().format("Do MMM YYYY")}
                  </Text>
                </HStack>
              </HStack>
            </MenuItem>
            {/* <HStack
              justifyContent="space-between"
              width="100%"
              onClick={() => {
                handleDateRangeSelectionType("Custom");
              }}>
              <HStack alignItems="center">
                <CustomInput
                  input={{
                    w: "100%",
                    h: "38px",
                    fontSize: "15px",
                    bg: "#fff",
                    variant: "outline",
                    type: "date",
                    border: "1px solid",
                    borderColor: "#b7bec5",
                    borderLeftRadius: "5px",
                    max: "9999-12-31",
                  }}
                />
              </HStack>

              <HStack alignItems="center">
                <Text
                  padding={"8px"}
                  margin={"0px 0px 0px 12px"}
                  _light={{
                    color: "customColor.black5",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontSize={"15px"}
                >
                  Select or type
                </Text>
              </HStack>
            </HStack> */}
          </MenuList>
        </Menu>
      </HStack>
    </Stack>
  );
}
