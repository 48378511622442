// import { FaBeer } from 'react-icons/fa';
import {
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import HRISLOGO from "../../../assets/hris-logo.png";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { Authentication } from "../../Api/Authentication";

export function SignUp() {
  let countryData = Country.getAllCountries();

  const navigate = useNavigate();

  const toast = useToast();

  const [query] = useSearchParams();

  const returnUrl = query.get("returnUrl");
  const { control, handleSubmit } = useForm<
    Authentication.Signup & {
      isAgreedWithTC: boolean;
    }
  >({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      isAgreedWithTC: false,
      password: "",
      country: "",
    },
  });
  const onSubmit = handleSubmit((data) => {
    Authentication.RegisterUser(
      data,
      (success) => {
        toast({
          title: "Register Successful",
          description:
            "Please check your email, email is sent to your email address to verify your account",

          status: "success",
        });
        if (returnUrl) {
          navigate(returnUrl);
        } else {
          navigate("/login");
        }
      },
      (error) => {
        toast({
          title: "Registration Failed",
          description: error,
          status: "error",
        });
      }
    );
  });
  const background1 = require("../../../assets/backgroundImages/background1.jpg");
  const background2 = require("../../../assets/backgroundImages/background2.jpg");
  const background3 = require("../../../assets/backgroundImages/background3.jpg");
  const background4 = require("../../../assets/backgroundImages/background4.jpg");
  const background5 = require("../../../assets/backgroundImages/background5.jpg");
  const background6 = require("../../../assets/backgroundImages/background6.jpg");
  const background7 = require("../../../assets/backgroundImages/background7.jpg");
  const background8 = require("../../../assets/backgroundImages/background8.jpg");
  const background9 = require("../../../assets/backgroundImages/background9.jpg");
  const background10 = require("../../../assets/backgroundImages/background10.jpg");
  const background11 = require("../../../assets/backgroundImages/background11.jpg");
  const background12 = require("../../../assets/backgroundImages/background12.jpg");
  const background13 = require("../../../assets/backgroundImages/background13.jpg");
  const background14 = require("../../../assets/backgroundImages/background14.jpg");
  const background15 = require("../../../assets/backgroundImages/background15.jpg");
  const background16 = require("../../../assets/backgroundImages/background16.jpg");
  const background17 = require("../../../assets/backgroundImages/background17.jpg");
  const background18 = require("../../../assets/backgroundImages/background18.jpg");
  const [backgroundImage, setBackgroundImage] = useState("");
  const getRandomImage = () => {
    const images = [
      background1,
      background2,
      background3,
      background4,
      background5,
      background6,
      background7,
      background8,
      background9,
      background10,
      background11,
      background12,
      background13,
      background14,
      background15,
      background16,
      background17,
      background18,
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };
  useEffect(() => {
    setBackgroundImage(getRandomImage());
  }, []);
  return (
    <form onSubmit={onSubmit}>
      <Stack
        direction={{ base: "column", md: "row" }}
        gap={0}
        _light={{
          backgroundColor: "customColor.gray.900",
        }}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        h={"100vh"}
        w={"100wh"}
        overflow={"hidden"}
      >
        <Stack
          gap={0}
          overflowX="hidden"
          padding={"78px 98px"}
          flex={{ base: 1, md: 4 }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          // issue in _light
          _light={{
            backgroundColor: {
              base: "customColor.gray.400",
              lg: "customColor.white",
            },
          }}
          borderLeft={"1px solid #ddd"}
          zIndex={1}
          // gap={0}
          // overflowX="hidden"
          // padding={{
          //   base: "30px",
          //   lg: "0",
          // }}
          // flex={{ base: 1, md: 6 }}
          // minW={"490px"}
          // _dark={{
          //   bgColor: "customColor.dark.50",
          // }}
          // // issue in _light
          // _light={{
          //   backgroundColor: {
          //     base: "customColor.gray.400",
          //     lg: "customColor.white",
          //   },
          // }}
          // borderLeft={"1px solid #ddd"}
          // zIndex={1}
        >
          <Container
            bgColor={{
              base: "white",
              lg: "transparent",
            }}
            padding={{ base: "20px", lg: "0px 20px" }}
            borderRadius={"10px"}
            boxShadow={{
              base: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
              lg: "none",
            }}
            // margin={{
            //   base: "auto",
            //   lg: "0px 50px",
            // }}
            // maxW={"450px"}
            // padding={"48px"}
            // bgColor={{
            //   base: "white",
            //   lg: "transparent",
            // }}
            // borderRadius={"10px"}
            // boxShadow={{
            //   base: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
            //   lg: "none",
            // }}
          >
            <Stack padding={"0px"} gap={0}>
              <Image
                objectFit="fill"
                w="115px"
                margin={"0px 0px 24px"}
                src={HRISLOGO}
                alt="Logo"
                onClick={() => {
                  navigate("/");
                }}
              />
              <Heading
                fontSize={"24px"}
                fontWeight={"700"}
                margin={"0px 0px 24px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Register
              </Heading>
              <Text
                fontSize={"13px"}
                fontWeight={"700"}
                margin={"0px 0px 8px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Manage all your hris360
              </Text>
              <Text
                fontSize={"13px"}
                _light={{
                  color: "customColor.gray.64748B",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Let's get you all setup, so you can verify your personal account
                and begine setting up your profile.
              </Text>
            </Stack>
            <Stack padding={"8px 0px 0px"} margin={"24px 0px 0px"} gap={0}>
              <Stack margin={"0px 0px 24px"} gap={0}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  First Name
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "First name is required",
                    },
                  }}
                  control={control}
                  name="firstName"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        variant: "outline",
                        type: "text",
                        bg: "#fff",
                        _light: {
                          color: "customColor.black7",
                        },
                        _dark: {
                          color: "customColor.white",
                        },
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        border: "1px solid #e5e7eb",

                        borderTopRadius: "3px",
                      }}
                      placeholder="First name"
                    />
                  )}
                />
              </Stack>
              <Stack margin={"0px 0px 24px"} gap={0}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Last Name
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Last name is required",
                    },
                  }}
                  control={control}
                  name="lastName"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={error?.message}
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        variant: "outline",
                        type: "text",
                        bg: "#fff",
                        _light: {
                          color: "customColor.black7",
                        },
                        _dark: {
                          color: "customColor.white",
                        },
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        border: "1px solid #e5e7eb",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Last name"
                    />
                  )}
                />
              </Stack>

              <Stack margin={"0px 0px 24px"} gap={0}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Email address
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "email is required",
                    },
                  }}
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={error?.message}
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        variant: "outline",
                        type: "text",
                        bg: "#fff",
                        _light: {
                          color: "customColor.black7",
                        },
                        _dark: {
                          color: "customColor.white",
                        },
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        border: "1px solid #e5e7eb",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Email"
                    />
                  )}
                />
              </Stack>
              <Stack margin={"0px 0px 24px"} gap={0}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Password
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                  }}
                  control={control}
                  name="password"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        variant: "outline",
                        type: "text",
                        bg: "#fff",
                        _light: {
                          color: "customColor.black7",
                        },
                        _dark: {
                          color: "customColor.white",
                        },
                        padding: "12px 15px",
                        fontSize: ".845rem",
                        border: "1px solid #e5e7eb",

                        borderTopRadius: "3px",
                      }}
                      placeholder="Password"
                    />
                  )}
                />
              </Stack>
              <Stack margin={"0px 0px 24px"} gap={0} width={"100%"}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Phone Number
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Phone number is required",
                    },
                  }}
                  control={control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <>
                      <PhoneInput
                        inputProps={field}
                        country={"au"}
                        placeholder="Phone"
                        inputStyle={{
                          height: "40px",
                          border: "1px solid",
                          width: "100%",
                          fontSize: ".845rem",
                          borderColor: fieldState.error ? "red" : " #e5e7eb",

                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                        }}
                        buttonStyle={{
                          backgroundColor: "transparent",
                          borderRadius: "5px",
                          borderColor: "#e5e7eb",
                        }}
                      />
                      {fieldState.error && (
                        <Text color="red.500" fontSize="sm">
                          {fieldState.error.message}
                        </Text>
                      )}
                    </>
                  )}
                />
              </Stack>
              <Stack margin={"0px 0px 24px"} gap={0}>
                <Text
                  fontSize={"13px"}
                  margin={"0px 0px 8px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Location
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Country is required",
                    },
                  }}
                  control={control}
                  name="country"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select Country"
                      // marginBottom="30px"
                      // width={"48em"}

                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Country"
                      withValidation
                      options={countryData}
                      value={countryData.find((op) => op.name === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.name)
                      }
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </Stack>

              <Text
                margin={"0px 0px 24px"}
                fontSize={"12px"}
                _light={{
                  color: "customColor.gray.64748B",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                By clicking <b>Create Account</b> below, you agree to our terms
                of service and privacy statement.
              </Text>

              <Controller
                control={control}
                name="isAgreedWithTC"
                rules={{
                  required: {
                    value: true,
                    message: "Agree with T&Cs and privacy policy",
                  },
                }}
                render={({
                  field: { value, ...field },
                  fieldState: { error },
                }) => (
                  <Checkbox
                    margin={"4px 0px 8px"}
                    {...field}
                    isInvalid={error !== undefined}
                    isChecked={value}
                  >
                    {" "}
                    <Text
                      fontSize={"13px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                    >
                      I agree to all the<strong> Terms & Conditions</strong> and{" "}
                      <strong>Fees</strong>
                    </Text>
                  </Checkbox>
                )}
              />

              <Button
                data-testid="login-button"
                height={"40px"}
                marginBottom={"15px"}
                size={"md"}
                margin={"40px 0px 0px"}
                fontSize={"13px"}
                borderRadius={"3px"}
                colorScheme="Cornflower"
                padding={"15px 20px"}
                fontWeight={"700"}
                lineHeight={"1rem"}
                onClick={handleSubmit((data) => {
                  Authentication.RegisterUser(
                    data,
                    (success) => {
                      toast({
                        title: "Register Successful",
                        description:
                          "Please check your email, email is sent to your email address to verify your account",

                        status: "success",
                      });
                      if (returnUrl) {
                        navigate(returnUrl);
                      } else {
                        navigate("/login");
                      }
                    },
                    (error) => {
                      toast({
                        title: "Registration Failed",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                })}
              >
                Create Account
              </Button>
              {/* <Box position="relative" padding="0">
                <Divider bg={"#E2E5EC"} borderWidth={"1px"} />
                <AbsoluteCenter
                  _light={{
                    bg: "white",
                  }}
                  _dark={{ bg: "customColor.dark.100" }}
                  px="0"
                >
                  <Text
                    fontSize={"10px"}
                    padding={"0px 10px"}
                    _light={{
                      color: "customColor.gray.100",
                    }}
                    _dark={{
                      color: "customColor.cream",
                    }}
                  >
                    OR SIGN UP USING
                  </Text>
                </AbsoluteCenter>
              </Box> */}
              {/* <HStack marginTop={"15px"} gap={0} w={"100%"}>
                <Stack padding={"0px 4px"} w={"100%"}>
                  <Button
                    leftIcon={<FaFacebookF />}
                    height={"40px"}
                    borderRadius={"3px"}
                    padding={"6.49px 11px"}
                    size={"md"}
                    fontSize={"12px"}
                    colorScheme="MutedLavender"
                  >
                    FACEBOOK
                  </Button>
                </Stack>
                <Stack padding={"0px 4px"} w={"100%"}>
                  <Button
                    leftIcon={<FaGoogle />}
                    height={"40px"}
                    borderRadius={"3px"}
                    padding={"6.49px 11px"}
                    size={"md"}
                    fontSize={"12px"}
                    colorScheme="UltraRed"
                  >
                    GOOGLE
                  </Button>
                </Stack>
              </HStack> */}
            </Stack>
            <Stack padding={0} margin={"48px 0px 0px"} gap={0}>
              <HStack>
                {" "}
                <Text
                  fontSize={"14px"}
                  _light={{
                    color: "customColor.gray.64748B",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Already have an account?
                </Text>
                <Link
                  fontSize="14px"
                  padding="0px"
                  margin={"0px"}
                  color="customColor.black7"
                  fontWeight={"700"}
                  href="/login"
                  _hover={{ color: "#3454d1" }}
                >
                  Login
                </Link>
              </HStack>
            </Stack>
          </Container>
        </Stack>
        <Stack
          flex={{ base: 0, md: 9 }}
          display={{ base: "none", lg: "block" }}
          height={"130vh"}
        >
          <Image
            src={backgroundImage}
            alt="Background"
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            bottom={"0px"}
            h={"100%"}
            objectFit={"cover"}
            w={"100%"}
          />
        </Stack>
      </Stack>
    </form>
  );
}
