import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaMoneyBillWave, FaTruckMoving } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { BillingAddressModal } from "./Modal/BillingAddressModal";
import { DeliveryAddressModal } from "./Modal/DeliveryAddressModal";

interface AddressProp {
  addressRef: React.RefObject<HTMLDivElement>;
}
export function Address({ addressRef }: AddressProp) {
  const billingAddressModal = useDisclosure();
  const deliveryAddressModal = useDisclosure();
  return (
    <Stack
      ref={addressRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"35px"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
      borderBottom={"2px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          color={"customColor.black7"}
          margin={"14px 0px"}
          fontSize={"17px"}
          fontWeight={"700"}
        >
          Address
        </Text>
        <Stack spacing={"35px"}>
          <Stack
            border={"1px solid #e5e7eb"}
            padding={"16px"}
            borderRadius={"4px"}
          >
            <HStack alignItems={"center"} gap={0}>
              <Icon as={FaMoneyBillWave} />
              <Text
                fontSize={"15px"}
                color={"customColor.black7"}
                margin={"0px 0px 0px 12px"}
                fontWeight={"700"}
              >
                Billing address
              </Text>
            </HStack>
            <InputGroup ml={[0, "0px"]}>
              <InputLeftElement
                pointerEvents="none"
                color="customColor.black7"
                fontSize="1.2em"
              >
                <Icon as={IoSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
              />
            </InputGroup>
            <Box>
              <Button
                margin={"8px 0px 0px"}
                variant={"ghost"}
                fontSize={"13px"}
                borderRadius={"3px"}
                color={"#3454d1"}
                padding={"12px 16px"}
                onClick={() => billingAddressModal.onOpen()}
              >
                Enter address manually
              </Button>
            </Box>
          </Stack>
          <Stack
            border={"1px solid #e5e7eb"}
            padding={"16px"}
            borderRadius={"4px"}
          >
            <HStack alignItems={"center"} gap={0}>
              <Icon as={FaTruckMoving} />
              <Text
                fontSize={"15px"}
                color={"customColor.black7"}
                margin={"0px 0px 0px 12px"}
                fontWeight={"700"}
              >
                Delivery address
              </Text>
            </HStack>
            <InputGroup ml={[0, "0px"]}>
              <InputLeftElement
                pointerEvents="none"
                color="customColor.black7"
                fontSize="1.2em"
              >
                <Icon as={IoSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
              />
            </InputGroup>
            <Box>
              <Button
                margin={"8px 0px 0px"}
                variant={"ghost"}
                fontSize={"13px"}
                borderRadius={"3px"}
                color={"#3454d1"}
                padding={"12px 16px"}
                onClick={() => deliveryAddressModal.onOpen()}
              >
                Enter address manually
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
      <BillingAddressModal
        isOpen={billingAddressModal.isOpen}
        onClose={billingAddressModal.onClose}
      />
      <DeliveryAddressModal
        isOpen={deliveryAddressModal.isOpen}
        onClose={deliveryAddressModal.onClose}
      />
    </Stack>
  );
}
