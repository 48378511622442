import { Container, Stack, Text } from "@chakra-ui/layout";
import {
  Button,
  HStack,
  Heading,
  Image,
  Link,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { Authentication } from "../../Api/Authentication";
import HRISLOGO from "./../../../assets/hris-logo.png";
export function ForgotPassword() {
  const { control, handleSubmit } = useForm<Authentication.ForgetPassword>({
    defaultValues: {
      email: "",
    },
  });

  const navigate = useNavigate();
  const toast = useToast();
  const background1 = require("../../../assets/backgroundImages/background1.jpg");
  const background2 = require("../../../assets/backgroundImages/background2.jpg");
  const background3 = require("../../../assets/backgroundImages/background3.jpg");
  const background4 = require("../../../assets/backgroundImages/background4.jpg");
  const background5 = require("../../../assets/backgroundImages/background5.jpg");
  const background6 = require("../../../assets/backgroundImages/background6.jpg");
  const background7 = require("../../../assets/backgroundImages/background7.jpg");
  const background8 = require("../../../assets/backgroundImages/background8.jpg");
  const background9 = require("../../../assets/backgroundImages/background9.jpg");
  const background10 = require("../../../assets/backgroundImages/background10.jpg");
  const background11 = require("../../../assets/backgroundImages/background11.jpg");
  const background12 = require("../../../assets/backgroundImages/background12.jpg");
  const background13 = require("../../../assets/backgroundImages/background13.jpg");
  const background14 = require("../../../assets/backgroundImages/background14.jpg");
  const background15 = require("../../../assets/backgroundImages/background15.jpg");
  const background16 = require("../../../assets/backgroundImages/background16.jpg");
  const background17 = require("../../../assets/backgroundImages/background17.jpg");
  const background18 = require("../../../assets/backgroundImages/background18.jpg");
  const [backgroundImage, setBackgroundImage] = useState("");
  const getRandomImage = () => {
    const images = [
      // backgroundImages,
      background1,
      background2,
      background3,
      background4,
      background5,
      background6,
      background7,
      background8,
      background9,
      background10,
      background11,
      background12,
      background13,
      background14,
      background15,
      background16,
      background17,
      background18,
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };
  useEffect(() => {
    setBackgroundImage(getRandomImage());
  }, []);
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      gap={0}
      _light={{
        backgroundColor: "customColor.gray.900",
      }}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      h={"100vh"}
      w={"100wh"}
      overflow={"hidden"}
    >
      <Stack
        flex={{ base: 0, md: 9 }}
        display={{ base: "none", lg: "block" }}
        height={"130vh"}
      >
        <Image
          src={backgroundImage}
          alt="Background"
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          bottom={"0px"}
          h={"100%"}
          objectFit={"cover"}
          w={"100%"}
        />
      </Stack>
      <Stack
        gap={0}
        overflowX="hidden"
        padding={"138px 98px"}
        flex={{ base: 1, md: 4 }}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        // issue in _light
        _light={{
          backgroundColor: {
            base: "customColor.gray.400",
            lg: "customColor.white",
          },
        }}
        borderLeft={"1px solid #ddd"}
        zIndex={1}
      >
        <Container
          bgColor={{
            base: "white",
            lg: "transparent",
          }}
          padding={{ base: "20px", lg: "0px 20px" }}
          borderRadius={"10px"}
          boxShadow={{
            base: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
            lg: "none",
          }}
        >
          <Stack padding={"0px"} gap={0}>
            <Image
              objectFit="fill"
              w="115px"
              margin={"0px 0px 24px"}
              src={HRISLOGO}
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
            />
            <Heading
              fontSize={"24px"}
              fontWeight={"700"}
              margin={"0px 0px 24px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Reset your password
            </Heading>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              margin={"0px 0px 8px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.pearlWhite",
              }}
            >
              Reset to your username/password
            </Text>
            <Text
              fontSize={"13px"}
              _light={{
                color: "customColor.gray.64748B",
              }}
              _dark={{
                color: "customColor.pearlWhite",
              }}
            >
              Enter your username or email address and we will send you a link
              to reset your password.
            </Text>
          </Stack>
          <Stack padding={"8px 0px 0px"} margin={"24px 0px 0px"} gap={0}>
            <Stack margin={"0px 0px 40px"} gap={0}>
              <Text
                fontSize={"13px"}
                margin={"0px 0px 8px"}
                fontWeight={"700"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Email address
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                }}
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      variant: "outline",
                      type: "text",
                      bg: "#fff",
                      _light: {
                        color: "customColor.black7",
                      },
                      _dark: {
                        color: "customColor.white",
                      },
                      padding: "12px 15px",
                      fontSize: ".845rem",
                      border: "1px solid #e5e7eb",

                      borderTopRadius: "3px",
                    }}
                    placeholder="Email address"
                    label=""
                  />
                )}
              />
            </Stack>
            <Button
              type="submit"
              data-testid="login-button"
              height={"40px"}
              size={"md"}
              fontSize={"13px"}
              borderRadius={"3px"}
              fontWeight={700}
              colorScheme="Cornflower"
              padding={"15px 20px"}
              lineHeight={"1rem"}
              onClick={handleSubmit(
                (data) => {
                  Authentication.Forget(
                    data,
                    () => {
                      toast({
                        title: "Email Sent for Reset Password",
                        description:
                          "Please check your email, an email is sent to your email address to reset your password",
                        status: "success",
                      });
                      navigate("/login");
                    },
                    (error) => {
                      toast({
                        title: "Login Failed",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",

                    status: "error",
                  });
                }
              )}
            >
              Reset
            </Button>
          </Stack>
          <Stack padding={0} margin={"48px 0px 0px"} gap={0}>
            <HStack>
              {" "}
              <Text
                fontSize={"14px"}
                _light={{
                  color: "customColor.gray.64748B",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Don't have an account?
              </Text>
              <Link
                fontSize="14px"
                padding="0px"
                margin={"0px"}
                color="customColor.black7"
                fontWeight={"700"}
                href="/signup"
                _hover={{ color: "#3454d1" }}
              >
                Create an Account
              </Link>
            </HStack>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
