import {
  HStack,
  Stack,
  Switch,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";
import Deductions from "./Deductions/Deductions";
import Earning from "./Earning/Earning";
import Leave from "./Leave/Leave";
import Reimbursement from "./Reimbursements/Reimbursement";
import StaturyBenefits from "./StaturyBenifits/StaturyBenefits";
interface PayItemProps {
  setSelectedTabForStatutory: (tab: number) => void;
  selectedTabForStatutory: number;
}
export default function PayItem({
  setSelectedTabForStatutory,
  selectedTabForStatutory,
}: PayItemProps) {
  const [showInactive, setShowInactive] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <Stack
      marginTop={"15px"}
      marginBottom={"1.25rem"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Tabs
        orientation={"horizontal"}
        variant={"unstyled"}
        index={selectedTab}
        onChange={setSelectedTab}
        isLazy
      >
        <TabList
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
          justifyContent={"none"}
          alignItems={"center"}
          h={"60px"}
        >
          <Stack direction={"row"} flex={1}>
            <Tooltip
              label="Earning refers to the income or compensation received by an individual or entity in exchange for work, services, or investments."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="bottom"
            >
              <Tab
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                onClick={() => setSelectedTab(0)}
              >
                Earning
              </Tab>
            </Tooltip>
            <Tooltip
              label="Deductions represent the subtraction or removal of certain amounts, such as taxes or contributions, from a person's income or a company's revenue."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="bottom"
            >
              <Tab
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                onClick={() => setSelectedTab(1)}
              >
                Deductions
              </Tab>
            </Tooltip>
            <Tooltip
              label="Reimbursements denote the repayment of expenses incurred by an individual or entity, typically by another party or organization, often for business-related costs."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="bottom"
            >
              <Tab
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                onClick={() => setSelectedTab(2)}
              >
                Reimbursements
              </Tab>
            </Tooltip>
            <Tooltip
              label=" Leave refers to an approved absence from work or duty granted to an employee, typically for vacation, sick time, or personal reasons."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="bottom"
            >
              <Tab
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                onClick={() => setSelectedTab(3)}
              >
                Leave
              </Tab>
            </Tooltip>
            <Tooltip
              label="Statutory Benefits are legally mandated employee benefits, such as social security, unemployment insurance, or workers' compensation, provided by government regulations or laws."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="bottom"
            >
              <Tab
                fontSize={"13px"}
                _light={{
                  color: "customColor.black7",
                }}
                onClick={() => setSelectedTab(4)}
              >
                Statuary Benefits
              </Tab>
            </Tooltip>
          </Stack>
          <Stack direction={"row"}>
            <HStack
              style={{ marginTop: "auto" }}
              display={{ xs: "none", md: "flex" }}
              padding={"8px 16px"}
              alignItems={"center"}
            >
              <Switch
                size="md"
                borderRadius={"40px"}
                w={"40px"}
                onChange={() => setShowInactive(!showInactive)}
              />
              <Text
                fontSize={"0.8125rem"}
                margin={"0px 0px 0px 5px"}
                _light={{
                  color: "customColor.black7",
                }}
                lineHeight={"1.5"}
                marginLeft={"-8px"}
              >
                Show inactive items
              </Text>
            </HStack>
          </Stack>
        </TabList>
        <TabIndicator
          // border="3px solid #2196F3"
          border="3px solid #2196F3"
          borderRadius="1px"
          height="2px"
        />
        <TabPanels>
          <TabPanel padding={0}>
            <Earning
              showInactive={showInactive}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <Deductions
              showInactive={showInactive}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <Reimbursement
              showInactive={showInactive}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <Leave
              showInactive={showInactive}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <StaturyBenefits
              setSelectedTab={setSelectedTabForStatutory}
              selectedTab={selectedTabForStatutory}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Stack
        marginX={"auto"}
        borderTop={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginRight={"auto"}
        marginLeft={"auto"}
        w="100%"
        marginTop={"20px"}
      >
        <HistoryAccordion />
      </Stack>
    </Stack>
  );
}
