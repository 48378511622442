import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { EmployeeLeaveEntitlement } from "../../Api/EmployeeLeaveEntitlement";
import { EmployeeLeaveModule } from "../../Api/EmployeeLeaveModule";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";

interface LeaveProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  FecthedLeaves: Array<PayItemLeaveApi.LeaveById>;
  employeeId: string;
  lastUpdated: number;
  FetchLeavesEligibility?: Array<EmployeeLeaveEntitlement.LeaveEntitlement>;
}

export default function LeaveForEmployee({
  isOpen,
  onClose,
  setLastUpdated,
  FecthedLeaves,
  employeeId,
  lastUpdated,
  FetchLeavesEligibility,
}: // selectedLeaveType,
LeaveProps) {
  const form = useFormContext<EmployeeLeaveModule.NewLeave>();

  const toast = useToast();
  const watchSelectedLeaveType = useWatch({
    control: form.control,
    name: "payrollPayItemLeaveId",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hours, setHours] =
    useState<EmployeeLeaveEntitlement.fetchEntitlementsHours>();
  useEffect(() => {
    if (watchSelectedLeaveType) {
      EmployeeLeaveEntitlement.FetchEntitlementsHours(
        employeeId,
        watchSelectedLeaveType,
        (success) => {
          setHours(success);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [watchSelectedLeaveType]);
  const { employeesList } = useBusinessContext();
  const watchStartDate = useWatch({
    control: form.control,
    name: "startDate",
  });
  const watchEndDate = useWatch({
    control: form.control,
    name: "endDate",
  });
  const quantity =
    watchStartDate && watchEndDate
      ? moment(moment(watchEndDate).format("YYYY-MM-DD")).diff(
          moment(moment(watchStartDate).format("YYYY-MM-DD")),
          "days"
        ) + 1
      : 0;

  useEffect(() => {
    if (isOpen) {
      form.reset({
        ...form.getValues(),
      });
    }
  }, [isOpen]);
  // const quantity =
  //   watchStartDate && watchEndDate
  //     ? moment(watchEndDate).diff(watchStartDate, "days") + 1
  //     : 0;
  const difference = (hours?.balance ?? 0) - quantity;
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="400px" maxH="550px">
          <ModalHeader
            fontSize=".9975rem"
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
            padding={"12px 20px"}
            fontWeight={"700"}
            lineHeight={"1.75rem"}
            borderBottom={"1px solid #e8e8e8"}
          >
            Leave Request
          </ModalHeader>
          <ModalCloseButton />
          <Box overflowY="auto">
            <ModalBody padding={"8px 20px"}>
              <Stack>
                <Text
                  fontSize=".8125rem"
                  fontWeight="bold"
                  lineHeight={"1.3077"}
                  marginBottom="-7px"
                  padding={"1px 0px 4px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Employee
                </Text>

                <HStack
                  border={"1px solid #a6a9b0"}
                  padding={"5px"}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"3px"}
                >
                  <Avatar
                    name={
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.firstName +
                      " " +
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.lastName
                    }
                    h={"24px"}
                    w={"24px"}
                    size={"sm"}
                    marginLeft={"8px"}
                    bg={"#daa3e4"}
                    color={"#3f1946"}
                  />
                  <Text
                    fontSize={"15px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    {employeesList?.find((emp) => emp.id === employeeId)
                      ?.firstName +
                      " " +
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.lastName}
                  </Text>
                </HStack>

                <Text
                  marginTop={"15px"}
                  fontSize=".8125rem"
                  fontWeight="bold"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-7px"
                  padding={"1px 0px 4px"}
                >
                  Leave Type
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Employee Status is required",
                    },
                  }}
                  control={form.control}
                  name="payrollPayItemLeaveId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={FecthedLeaves?.map((leave) => ({
                        value: leave.id,
                        label: leave.name,
                      }))}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Status"
                      placeholder="Select..."
                      value={
                        FecthedLeaves?.find((leave) => leave.id === field.value)
                          ? {
                              value: field.value,
                              label: FecthedLeaves?.find(
                                (leave) => leave.id === field.value
                              )?.name,
                            }
                          : null
                      }
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption.value);
                        const entitlementId = FetchLeavesEligibility?.find(
                          (leave) =>
                            leave.payrollPayItemLeaveId === selectedOption.value
                        )?.id;
                        if (entitlementId) {
                          form.setValue("leaveEntitlementId", entitlementId);
                        }
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                {watchSelectedLeaveType && (
                  <Stack>
                    <HStack borderBottom={"1px solid grey"}>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        Current Leave Balance
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {FecthedLeaves.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.unitType ?? "Days"}
                      </Heading>
                    </HStack>
                    <HStack>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {
                          FecthedLeaves.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.name
                        }
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {hours?.balance}
                      </Heading>
                    </HStack>
                    <Stack
                      direction="row"
                      spacing={0}
                      w={"100%"}
                      alignItems={"baseline"}
                    >
                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Start Date is required",
                            },
                            validate: (value) => {
                              const today = new Date();
                              today.setHours(0, 0, 0, 0); // Set the time to midnight for an accurate comparison

                              if (new Date(value) < today) {
                                return "Start Date cannot be earlier than today";
                              }

                              return true;
                            },
                          }}
                          control={form.control}
                          name="startDate"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                padding: "12px 15px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                fontSize: ".845rem",
                                backgroundColor: "#fff",
                                type: "date",

                                borderTopRadius: "3px",
                              }}
                              placeholder=""
                              label="Start date"
                              labelProps={{
                                fontSize: ".845rem",
                                fontWeight: "600",
                                _light: {
                                  color: "customColor.black7",
                                },
                                marginTop: "15px",

                                lineHeight: "1.3077",
                                marginBottom: "-4px",
                                padding: "1px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "End Date is required",
                            },
                            validate: (value) => {
                              const startDate = form.getValues("startDate"); // Get the start date from the form values
                              const today = new Date();
                              today.setHours(0, 0, 0, 0); // Set the time to midnight for an accurate comparison

                              if (new Date(value) < today) {
                                return "End Date cannot be earlier than today";
                              }

                              if (new Date(value) < new Date(startDate)) {
                                return "End Date cannot be earlier than Start Date";
                              }

                              return true;
                            },
                          }}
                          control={form.control}
                          name="endDate"
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              ref={field.ref}
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                w: "100%",
                                fontSize: ".845rem",
                                padding: "12px 15px",
                                _light: {
                                  color: "customColor.black7",
                                },
                                variant: "outline",
                                type: "date",
                                borderTopRadius: "3px",
                              }}
                              placeholder=""
                              label="End date"
                              labelProps={{
                                fontSize: ".845rem",
                                fontWeight: "600",
                                _light: {
                                  color: "customColor.black7",
                                },
                                marginTop: "15px",
                                lineHeight: "1.3077",
                                marginBottom: "-4px",
                                padding: "1px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                    <HStack justifyContent="space-between">
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        Total leave submission days
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {/* {quantity} */}
                        {quantity}
                      </Heading>
                    </HStack>

                    {difference < 0 && (
                      <HStack justifyContent="space-between">
                        <Heading
                          fontSize={".8125rem"}
                          fontWeight={"normal"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          paddingBottom={"5px"}
                        >
                          {employeesList?.find((emp) => emp.id === employeeId)
                            ?.firstName +
                            " " +
                            employeesList?.find((emp) => emp.id === employeeId)
                              ?.lastName}{" "}
                          your unpaid leave will be
                        </Heading>
                        <Heading
                          fontSize={".8125rem"}
                          fontWeight={"bold"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          paddingBottom={"5px"}
                        >
                          {difference}
                        </Heading>
                      </HStack>
                    )}

                    <Text
                      fontSize=".8125rem"
                      marginTop={"15px"}
                      fontWeight="bold"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      marginBottom="-12px"
                      padding={"1px 0px 4px"}
                    >
                      Description
                    </Text>
                    <Controller
                      name="description"
                      rules={{
                        required: {
                          value: true,
                          message: "Type a description",
                        },
                      }}
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <>
                          <Textarea
                            {...field}
                            isInvalid={fieldState.invalid}
                            errorBorderColor="Red"
                            fontSize=".845rem"
                            fontWeight={400}
                            border="1px solid #e5e7eb"
                            _hover={{
                              border: "1px solid hsl(0, 0%, 70%)",
                            }}
                            _focus={{
                              borderWidth: "1px",

                              boxShadow: "0 0 0 1px #2684FF",
                              _hover: {
                                borderWidth: "1px",
                                borderColor: "#2684FF",
                                boxShadow: "0 0 0 1px #2684FF",
                              },
                            }}
                            _focusVisible={{
                              borderWidth: "1px",
                              boxShadow: "0 0 0 1px #2684FF",
                              _hover: {
                                borderWidth: "1px",
                                borderColor: "#2684FF",
                                boxShadow: "0 0 0 1px #2684FF",
                              },
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            padding="12px 15px"
                            overflowX="hidden"
                            maxLength={300}
                            shadow="inner"
                            placeholder="Type a Note"
                          />
                          {fieldState.invalid && (
                            <Text color="red" fontSize="12px">
                              {fieldState.error?.message}
                            </Text>
                          )}
                        </>
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            </ModalBody>
          </Box>
          <ModalFooter padding={"20px"} borderTop={"1px solid #e8e8e8"}>
            {/* {form.getValues("id") && (
              <ButtonGroup
                minH={"40px"}
                spacing="1px"
                colorScheme={"red"}
                flex={1}
              >
                <Button
                  borderLeftRadius="3px"
                  w="100px"
                  borderRight="0px"
                  minH={"40px"}
                  px="16px"
                  isLoading={isLoading}
                  width="fit-content"
                  onClick={() => {
                    setIsLoading(true);
                    EmployeeLeave.LeaveRejected(
                      form.getValues("id")!,
                      {},
                      () => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "leave Rejected",
                          status: "success",
                        });
                        setIsLoading(false);
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }}
                >
                  Reject
                </Button>
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    borderRightRadius="3px"
                    aria-label="dropdown menu"
                    icon={<FiChevronDown />}
                    minH={"40px"}
                  />
                  <MenuList minW="100px">
                    <MenuItem
                      fontSize="13px"
                      onClick={() => {
                        rejectModal.onOpen();
                      }}
                    >
                      Reject with a note
                    </MenuItem>
                  </MenuList>
                </Menu>
              </ButtonGroup>
            )} */}
            <Flex>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    EmployeeLeaveModule.CreateLeave(
                      {
                        ...data,
                        quantity: quantity,
                      },
                      (success) => {
                        toast({
                          title: "Leave is added",

                          status: "success",
                        });
                        setIsLoading(false);

                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Add Leave Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  })(e);
                }}
              >
                Submit
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
