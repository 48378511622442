import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

interface HeaderPostedPayslipProps {
  totalEarnings?: any;
  totalTaxs?: any;
  totalDeductions?: any;
  totalNetPay?: any;
  totalReimbursement?: any;
}

export function HeaderPostedPayslip({
  totalEarnings,
  totalDeductions,
  totalReimbursement,
  totalTaxs,
  totalNetPay,
}: HeaderPostedPayslipProps) {
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      border="1px solid #dadddf"
    >
      <Stack padding="20px 20px 40px 20px">
        <Container
          justifyContent="center"
          alignItems="center"
          maxWidth={"100%"}
          padding="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          border="1px solid #d6dade"
          borderRadius="md"
        >
          <SimpleGrid columns={[3, 5]}>
            {[
              {
                name: "Earning",
                number: totalEarnings
                  ? totalEarnings
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#333333",
              },
              {
                name: "Taxes",
                number: totalTaxs
                  ? totalTaxs.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#333333",
              },
              {
                name: "Deductions",
                number: totalDeductions
                  ? totalDeductions
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#333333",
              },
              {
                name: "Reimbursement",
                number: totalReimbursement
                  ? totalReimbursement
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#333333",
              },
              {
                name: "Net Pay",
                number: totalNetPay
                  ? totalNetPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#333333",
              },
            ].map((item) => (
              <Stack
                key={item.name}
                borderRight="1px solid #e8ebee"
                padding="20px 10px 20px 10px"
                gap="0rem"
                flex={1}
              >
                <Heading
                  fontSize="12px"
                  fontWeight="bold"
                  _light={{
                    color: item.nameColor,
                  }}
                  _dark={{
                    color: "customColor.cream",
                  }}
                >
                  {item.name}
                </Heading>
                <Stack
                  padding={0}
                  gap="0.2rem"
                  direction={{ xs: "column", lg: "row" }}
                >
                  {" "}
                  <Tooltip label={item.number} fontSize="md">
                    <Text
                      _light={{
                        color: item.numberColor,
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontWeight="bold"
                      fontSize="24px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {item.number}
                    </Text>
                  </Tooltip>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Container>
      </Stack>
    </Stack>
  );
}
