import { HStack, Stack, Text } from "@chakra-ui/react";
import { Employees } from "../../../Api/Employees";
interface BankDetailsProps {
  bankDetailsData: Employees.EmployeeAllDetail | undefined;
}
export function BankDetails({ bankDetailsData }: BankDetailsProps) {
  return (
    <Stack flex={1} padding={"0px 5px"} margin={"0px 0px 40px"}>
      <HStack
        justifyContent={"space-between"}
        borderBottom={"2px solid #dadddf"}
        padding={"0px 0px 10px"}
        margin={"14px 0px 20px"}
      >
        <Text
          _dark={{
            color: "customColor.white",
          }}
          _light={{
            color: "#3454d1",
          }}
          fontWeight={"700"}
          _hover={{
            _light: {
              color: "#3454d1",
            },
          }}
        >
          Bank Details
        </Text>
        {/* <Button
                  variant={"link"}
                  color={"primary.950"}
                  onClick={() => {
                    personalDetail.onOpen();
                  }}
                >
                  Edit
                </Button>
                <PersonalDetailModal
                  isOpen={personalDetail.isOpen}
                  onClose={personalDetail.onClose}
                /> */}
      </HStack>
      <HStack>
        <Stack flex={1}>
          {["Bank Name", "Account Title", "IBAN"].map((item, i) => {
            return (
              <Stack key={i}>
                <Text
                  _dark={{
                    color: "customColor.white",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"13px"}
                  fontWeight={"700"}
                >
                  {item}
                </Text>
              </Stack>
            );
          })}
        </Stack>
        <Stack flex={2}>
          {[
            `${bankDetailsData?.employeeBankDetails?.bankName ?? "NA"}`,
            // `${bankDetailsData?.employeeBankDetails?.branchCode ?? "NA"}`,
            `${bankDetailsData?.employeeBankDetails?.accountName ?? "NA"}`,
            // `${bankDetailsData?.employeeBankDetails?.accountNumber ?? "NA"}`,
            `${bankDetailsData?.employeeBankDetails?.iban ?? "NA"}`,
          ].map((item, i) => {
            return (
              <Stack key={i}>
                <Text
                  _dark={{
                    color: "customColor.white",
                  }}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"13px"}
                >
                  {item}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      </HStack>
    </Stack>
  );
}
function formatCNIC(cnic: any) {
  if (!cnic) return "NA";
  if (cnic.length !== 13) return cnic; // Return as is if not 13 digits

  return `${cnic.slice(0, 5)}-${cnic.slice(5, 12)}-${cnic.slice(12)}`;
}
