import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";

interface NotificationDeleteModalProps extends ModalProps {
  onDelete: () => void;
}
export default function NotificationDeleteModal(
  props: Omit<NotificationDeleteModalProps, "children">
) {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent mx="10px">
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          py="4px"
        >
          Confirmation
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          fontSize={"0.8125rem"}
          fontWeight={"600"}
          _light={{
            color: "customColor.black7",
          }}
        >
          Are you sure you want to Delete this?
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="flex-end"
          pt="16px"
          border="0px"
        >
          <ButtonGroup size="xs" variant="solid" borderRadius="5px">
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              fontSize={"13px"}
              padding={"10px 15px"}
              borderRadius={"3px"}
              minH={"40px"}
              border={"1px solid #FDEDED"}
              bg={"#FDEDED"}
              color={"#EA4D4D"}
              minW={"75px"}
              _hover={{
                border: "1px solid #EA4D4D",
                bg: "#EA4D4D",
                color: "#fff",
              }}
              onClick={props.onDelete}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
