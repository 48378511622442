import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { Country } from "country-state-city";
import { useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { PayrollHolidayGroup } from "../../../Api/PayrollHolidayGroup";

interface AddHolidayGroupProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (time: number) => void;
}
export function AddHolidayGroup({
  isOpen,
  onClose,
  setLastUpdated,
}: AddHolidayGroupProps) {
  const toast = useToast();
  // const form = useForm<PayrollHolidayGroup.HolidayGroup>({
  //   defaultValues: {
  //     groupName: "",
  //   },
  // });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let countryData = Country.getAllCountries();
  const form = useFormContext<PayrollHolidayGroup.HolidayGroup>();
  const id = form.getValues("id");
  return (
    <FormProvider {...form}>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="18px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
          >
            {id ? "Edit Holiday Group" : "Add Holiday Group"}
          </ModalHeader>
          <Divider orientation="horizontal" />
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={600}
                _dark={{ color: "customColor.white" }}
              >
                Name
              </FormLabel>
              <Controller
                name="groupName"
                rules={{
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                }}
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        size: "md",

                        variant: "outline",
                        type: "text",
                        fontSize: ".845rem",
                        borderRadius: "3px",
                        padding: "12px 15px",
                        alignContent: "center",
                      }}
                      placeholder=" "
                    />
                    {/* <Text color="red" fontSize="12px">
                    {errors.nameholiday && errors.nameholiday.message}
                  </Text> */}
                  </>
                )}
              />
              <FormLabel
                marginTop="10px"
                fontSize="12px"
                fontWeight={600}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
              >
                Add existing holidays from group
              </FormLabel>
              {/* <Controller
              name="holidaygroup"
              control={control}
              render={({ field }) => ( */}
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Country is required",
                  },
                }}
                control={form.control}
                name="country"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select Country"
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Country"
                    withValidation
                    options={countryData}
                    value={countryData.find((op) => op.name === field.value)}
                    onChange={(newValue: any) => field.onChange(newValue?.name)}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.name}
                  />
                )}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex direction="row" justifyContent="end">
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (id) {
                      PayrollHolidayGroup.UpdateHolidayGroup(
                        id,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Holiday Group is updated",
                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Holiday Group Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayrollHolidayGroup.CreateHolidayGroup(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Holiday group is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Holiday Group Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {id ? "Edit" : "Add"}
              </Button>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                marginLeft={"8px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
