import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeeEducation {
  type AddEducation = {
    id?: string;
    educationType: string;
    qualification: string;
    courseName: string;
    instituteName: string;
    country: string;
    dateFrom: string;
    dateTo: string;
  };
  type FetchEducation = {
    id: string;
    qualification: string;
    educationType: string;
    courseName: string;
    instituteName: string;
    country: string;
    dateFrom: string;
    dateTo: string;
    empId: string;
  };
}
module EmployeeEducation {
  const URL = "/employee-education";

  export function PostEducation(
    id: string,
    data: AddEducation,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetEducationDetails(
    id: string,
    success: (response: Array<FetchEducation>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateEducation(
    id: string,
    data: Partial<AddEducation>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteEducation(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { EmployeeEducation };
