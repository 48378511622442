import {
  Box,
  Button,
  Divider,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { EmployeeNotes } from "../../../Api/EmployeeNotes";

interface AddNewNotesProps {
  isOpen: boolean;
  onClose: () => void;
  selectedNoteId: string | undefined;
  setLastUpdated: (timeStamp: number) => void;
}
export function AddNewNotes({
  isOpen,
  onClose,
  selectedNoteId,
  setLastUpdated,
}: AddNewNotesProps) {
  const param = useParams();
  const form = useFormContext<EmployeeNotes.AddNewNote>();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FormProvider {...form}>
      <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Box h="50px">
            <ModalHeader
              alignItems="center"
              fontSize="18px"
              color={"customColor.gray.800"}
              _dark={{
                color: "customColor.dark.250",
              }}
            >
              {selectedNoteId ? "Delete" : "Add New Note"}
            </ModalHeader>
            <ModalCloseButton />
          </Box>
          <Divider orientation="horizontal" />
          <ModalBody
            border="1px solid #ddd"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "gray.50",
            }}
          >
            <FormControl>
              <Controller
                name="note"
                rules={{
                  required: {
                    value: true,
                    message: "Type a Note",
                  },
                }}
                control={form.control}
                render={({ field, fieldState }) => (
                  <>
                    <Textarea
                      {...field}
                      isInvalid={fieldState.invalid}
                      errorBorderColor="Red"
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "customColor.white",
                      }}
                      mt="10px"
                      mb="15
                      px"
                      overflowX="hidden"
                      maxLength={300}
                      w="400px"
                      shadow="inner"
                      placeholder="Type a Note"
                      h="160px"
                    />
                    {fieldState.invalid && (
                      <Text color="red" fontSize="12px">
                        {fieldState.error?.message}
                      </Text>
                    )}
                  </>
                )}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter
            h="50px"
            w="100%"
            justifyContent={selectedNoteId ? "flex-start" : "flex-end"}
          >
            <Button
              fontSize={"13px"}
              bgColor={selectedNoteId ? "#FDEDED" : "#3454D1"}
              h={"40px"}
              borderRadius={"3px"}
              border={
                selectedNoteId ? "1px solid #FDEDED" : "1px solid #3454D1"
              }
              color={selectedNoteId ? "#EA4D4D" : "#fff"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: selectedNoteId ? "#EA4D4D" : "#3454D1",
                color: selectedNoteId ? "#fff" : "#fff",
                border: selectedNoteId
                  ? "1px solid #EA4D4D"
                  : "1px solid #3454D1",
              }}
              type="submit"
              isLoading={isLoading}
              mr={3} /*onClick={handleSaveNote}*/
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  if (selectedNoteId) {
                    EmployeeNotes.Delete(
                      selectedNoteId,

                      (success) => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "Note is deleted",

                          status: "success",
                        });
                        setIsLoading(false);
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Delete Note Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  } else {
                    EmployeeNotes.NewNoteAdded(
                      param.id,
                      data,
                      (success) => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "Note is added",

                          status: "success",
                        });
                        setIsLoading(false);
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Add Account Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }
                })(e);
              }}
            >
              {selectedNoteId ? "Delete" : "Save"}
            </Button>
            <Button
              onClick={onClose}
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
