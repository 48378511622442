import {
  Box,
  Button,
  Checkbox,
  Container,
  CSSReset,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  HStack,
  Icon,
  Image,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { LoadScript } from "@react-google-maps/api";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  FaCcApplePay,
  FaCcMastercard,
  FaCcStripe,
  FaCcVisa,
  FaCheck,
  FaGooglePay,
} from "react-icons/fa";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { MdImage } from "react-icons/md";
import { AddressInput } from "../../../Common/AddressInput";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { AddDetailInvoices } from "../../Api/AddDetailInvoices";

interface AddDetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddDetailsDrawer({
  isOpen,
  onClose,
}: AddDetailsDrawerProps) {
  const backgroundImage = require("../../../assets/hris-logo.png");
  const sampleImage = require("../../../assets/SampleImageForInvoices.png");
  const [imageScale, setImageScale] = useState(1); // initial scale
  const [text, setText] =
    useState(`When paying by cheque, please complete this payment advice, detach and post to the address provided.

Online payment preferred - use our account 083-234-12345678 or use the 'Pay online now' link to pay via PayPal with your credit card.`);
  const form = useForm<AddDetailInvoices.InvoicesDetail>({
    defaultValues: {
      postalStreetAddress: "",
      postalCity: "",
      postalState: "",
      postalPostcode: "",
      postalCountry: "",
    },
  });
  let countryData = Country.getAllCountries();
  const handleChange = (e: any) => {
    const inputText = e.target.value;

    setText(inputText);
  };
  const handleSliderChange = (value: number) => {
    setImageScale(value / 100); // adjust scale based on slider value
  };
  const [postalAddressManually, setPostalAddressManually] = useState(false);
  const [postalProvinces, setPostalProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) => country.name === form.getValues("postalCountry")
      )?.isoCode
    );
    const postalProvinces = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setPostalProvinces(postalProvinces);
  }, [form.watch("postalCountry")]);

  return (
    <FormProvider {...form}>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl" placement="top">
        <DrawerOverlay />
        <DrawerContent h={"100%"}>
          <Stack
            gap={0}
            h={"60px"}
            boxShadow={"0 1px 0 0 rgba(0,10,30,.2),0 3px 0 0 rgba(0,10,30,.05)"}
            border="1px solid #e5e7eb"
            zIndex={1}
          >
            <HStack
              marginLeft={"30px"}
              marginRight={"4px"}
              alignItems={"center"}
              gap={0}
            >
              <Button
                bg={"#fff"}
                h={"34px"}
                color={"customColor.black7"}
                padding={""}
                borderRadius={"full"}
                _hover={{
                  bg: "rgba(0,10,30,.05)",
                }}
                onClick={onClose}
              >
                <IoMdClose onClick={onClose} fontSize={"22px"} />
              </Button>
              {/* <Stack padding={"10px 0px "} w={"40px"} h={"40px"}>
                <IoMdClose onClick={onClose} fontSize={"22px"} />
              </Stack> */}

              <Text
                _light={{
                  color: "customColor.black7",
                }}
                fontSize={"17px"}
                paddingRight={"12px"}
                marginRight={"8px"}
                paddingTop={"18px"}
                fontWeight={"bold"}
                paddingBottom={"18px"}
              >
                Set Up Invoice Template
              </Text>
            </HStack>
          </Stack>

          <DrawerBody
            _dark={{
              bgColor: "customColor.dark.100",
            }}
            _light={{
              bgColor: "customColor.gray.400",
            }}
            p={0}
          >
            <HStack gap={0} alignItems={"flex-start"}>
              <Stack
                flex={1}
                p={0}
                _light={{ bg: "customColor.white" }}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Container maxW={"470px"} margin={"0 30px"} p={0}>
                  <Stack margin={"64px 0px 32px"} gap={0}>
                    <Text
                      marginBottom={"17.43px "}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"21px"}
                      fontWeight={"bold"}
                    >
                      Set up invoice template
                    </Text>
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"15px"}
                    >
                      Set up your invoice template with key information so you
                      can send your first invoice and start getting paid.
                    </Text>
                  </Stack>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"13px"}
                    fontWeight={"bold"}
                  >
                    Logo
                  </Text>
                  <Stack
                    border={"1px solid #e5e7eb"}
                    borderRadius={"3px"}
                    cursor={"all-scroll"}
                    overflow={"hidden"}
                    h={"97px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Image
                      src={backgroundImage}
                      alt="Background"
                      transform={`scale(${imageScale})`}
                      transformOrigin={"center center"}
                      objectFit={"cover"}
                    />
                  </Stack>
                  <HStack padding={"15px 10px"} marginBottom={"32px"}>
                    <MdImage fontSize={"20px"} />
                    <Slider
                      aria-label="slider-ex-2"
                      colorScheme="gray"
                      min={100}
                      max={200}
                      value={imageScale * 100}
                      onChange={handleSliderChange}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                    <MdImage fontSize={"30px"} />
                  </HStack>
                  <Stack gap={0} marginBottom={"32px"}>
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize=".8125rem"
                      fontWeight="700"
                    >
                      Postal address
                    </Text>
                    <Stack gap={0}>
                      <LoadScript
                        googleMapsApiKey="AIzaSyCZ6p-O1f1OsTcmPNOEitFCQBBDML9fOr4"
                        loadingElement={<div>Loading...</div>}
                        libraries={["places"]}
                      >
                        <AddressInput
                          onPlaceSelected={(place) => {
                            form.setValue(
                              "postalStreetAddress",
                              place?.formatted_address
                            );
                            form.setValue("postalCity", place?.city);
                            form.setValue("postalCountry", place?.country);
                            form.setValue("postalState", place?.state);
                            form.setValue("postalPostcode", place?.postcode);
                          }}
                          input={{
                            _light: {
                              color: "customColor.black7",
                            },
                            border: "1px solid #e5e7eb",
                            _hover: {
                              border: "1px solid hsl(0, 0%, 70%)",
                            },
                            borderTopRadius: "3px",
                            _focus: {
                              borderWidth: "1px",

                              boxShadow: "0 0 0 1px #2684FF",
                              _hover: {
                                borderWidth: "1px",
                                borderColor: "#2684FF",
                                boxShadow: "0 0 0 1px #2684FF",
                              },
                            },
                            _focusVisible: {
                              borderWidth: "1px",
                              boxShadow: "0 0 0 1px #2684FF",
                              _hover: {
                                borderWidth: "1px",
                                borderColor: "#2684FF",
                                boxShadow: "0 0 0 1px #2684FF",
                              },
                            },
                            fontSize: ".845rem",
                            placeholder: "Search address",
                            padding: "12px 15px",
                            variant: "outline",
                            marginTop: "04px",
                            marginBottom: "12px",
                            type: "text",
                          }}
                        />
                      </LoadScript>
                    </Stack>

                    {postalAddressManually && (
                      <>
                        <Controller
                          name="postalStreetAddress"
                          control={form.control}
                          render={({ field }) => (
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              gap={0}
                            >
                              <Stack flex={1} width={"100%"}>
                                <CustomInput
                                  withValidation
                                  ref={field.ref}
                                  input={{
                                    ...field,
                                    w: "100%",
                                    h: "38px",
                                    padding: "12px 15px",
                                    fontSize: ".845rem",
                                    marginBottom: "12px",
                                    variant: "outline",
                                    type: "text",
                                    borderTopRadius: "3px",
                                    alignContent: "center",
                                  }}
                                  placeholder=""
                                  label="Street Address"
                                  labelProps={{
                                    marginBottom: "4px",
                                    fontSize: ".845rem",
                                    fontWeight: "600",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                  }}
                                />
                              </Stack>
                            </Stack>
                          )}
                        />
                        <Controller
                          name="postalCity"
                          control={form.control}
                          // rules={{ maxLength: 10 }}
                          render={({ field }) => (
                            <CustomInput
                              withValidation
                              ref={field.ref}
                              input={{
                                ...field,
                                w: "100%",
                                h: "38px",
                                padding: "12px 15px",
                                fontSize: ".845rem",
                                marginBottom: "12px",
                                variant: "outline",
                                type: "text",
                                borderTopRadius: "3px",
                                alignContent: "center",
                              }}
                              placeholder=""
                              label="City/Town"
                              labelProps={{
                                marginBottom: "4px",
                                fontSize: ".845rem",
                                fontWeight: "600",
                                _light: {
                                  color: "customColor.black7",
                                },
                              }}
                            />
                          )}
                        />
                        <HStack gap={0} alignItems={"baseline"}>
                          <Stack flex={1} marginBottom={"8px"} gap={0}>
                            <Text
                              fontSize={".845rem"}
                              fontWeight={"600"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              marginBottom={"4px"}
                            >
                              State/Province
                            </Text>
                            <Controller
                              control={form.control}
                              name="postalState"
                              render={({ field, fieldState }) => (
                                <ReactSelect
                                  {...field}
                                  key={field.value}
                                  options={postalProvinces}
                                  ErrorMessage="Please Select State"
                                  placeholder="Select..."
                                  value={postalProvinces?.find(
                                    (op: any) => op.name === field.value
                                  )}
                                  onChange={(newValue: any) => {
                                    field.onChange(newValue?.name);
                                  }}
                                  getOptionLabel={(option: any) => option.name}
                                  getOptionValue={(option: any) => option.name}
                                />
                              )}
                            />
                          </Stack>
                          <Stack flex={1}>
                            <Controller
                              name="postalPostcode"
                              control={form.control}
                              // rules={{ maxLength: 10 }}
                              render={({ field }) => (
                                <CustomInput
                                  withValidation
                                  ref={field.ref}
                                  input={{
                                    w: "100%",

                                    h: "38px",
                                    padding: "12px 15px",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    marginBottom: "8px",
                                    fontSize: ".845rem",
                                    variant: "outline",
                                    type: "text",

                                    borderTopRadius: "3px",
                                  }}
                                  placeholder=""
                                  label="Zip/PostCode"
                                  labelProps={{
                                    marginBottom: "4px",
                                    fontSize: ".845rem",
                                    fontWeight: "600",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </HStack>
                        <CSSReset />
                        <Box
                          textAlign="left"
                          borderTopRadius="3px"
                          placeholder="Country"
                          marginBottom={"12px"}
                        >
                          <Text
                            fontSize={".845rem"}
                            fontWeight={"600"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            marginBottom={"4px"}
                          >
                            Country
                          </Text>
                          {/* <Countryselector onChange={handleCountryChange} /> */}
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Country is required",
                              },
                            }}
                            control={form.control}
                            name="postalCountry"
                            render={({ field, fieldState }) => (
                              <ReactSelect
                                {...field}
                                placeholder="Select Country"
                                // marginBottom="30px"
                                // width={"48em"}
                                isInvalid={fieldState.invalid}
                                ErrorMessage="Select Country"
                                withValidation
                                options={countryData}
                                value={countryData.find(
                                  (op) => op.name === field.value
                                )}
                                onChange={(newValue: any) => {
                                  field.onChange(newValue?.name);
                                }}
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option.name}
                              />
                            )}
                          />
                        </Box>
                      </>
                    )}
                    <Box>
                      <Button
                        variant={"ghost"}
                        h={"38px"}
                        color={"#3454d1"}
                        _hover={{
                          bg: "rgba(0,10,30,.05)",
                        }}
                        onClick={() => {
                          setPostalAddressManually(!postalAddressManually);
                        }}
                      >
                        {postalAddressManually
                          ? "Enter address from search"
                          : "Enter address manually"}
                      </Button>
                    </Box>
                  </Stack>
                  <CustomInput
                    withValidation
                    input={{
                      w: "100%",

                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      marginBottom: "32px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="ABN"
                    labelProps={{
                      marginBottom: "4px",
                      fontSize: ".845rem",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                  <Text
                    margin={"17.43px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"21px"}
                    fontWeight={"bold"}
                  >
                    Which payment methods can customers use to pay your
                    invoices?
                  </Text>
                  <Checkbox
                    size={"lg"}
                    alignItems={"center"}
                    paddingBottom={"4px"}
                  >
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"17px"}
                      fontWeight={"bold"}
                    >
                      Card and wallet payments
                    </Text>
                  </Checkbox>
                  <Stack paddingLeft={"32px"} gap={0}>
                    <Stack
                      margin={"8px 0px 0px"}
                      padding={"20px 24px"}
                      spacing={5}
                      _light={{
                        bgColor: "customColor.gray.400",
                      }}
                      borderRadius={"3px"}
                      border={"1px solid #e5e7eb"}
                    >
                      <Text
                        fontSize={"15px"}
                        fontWeight={"700"}
                        _light={{
                          color: "customColor.black7",
                        }}
                      >
                        Give your customers more ways to pay invoices online and
                        get paid up to twice as fast
                      </Text>
                      <Stack direction={"row"}>
                        <Stack
                          w={"100%"}
                          alignItems={"flex-start"}
                          flex={1}
                          borderRight={"2px solid #a6a9b0"}
                        >
                          <Icon as={FaCcStripe} fontSize={"28px"} />
                        </Stack>

                        <Icon flex={1} as={FaCcVisa} fontSize={"28px"} />
                        <Icon flex={1} as={FaCcMastercard} fontSize={"28px"} />
                        <Icon flex={1} as={FaCcApplePay} fontSize={"28px"} />
                        <Stack
                          w={"100%"}
                          alignItems={"center"}
                          flex={1}
                          borderRadius={"full"}
                          border={"1px solid #a6a9b0"}
                        >
                          <Icon flex={1} as={FaGooglePay} fontSize={"28px"} />
                        </Stack>
                      </Stack>
                      <Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                          <FaCheck color="green" fontSize={"13px"} />
                          <Text
                            fontSize={"13px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Card payments
                          </Text>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                          <FaCheck color="green" fontSize={"13px"} />
                          <Text
                            fontSize={"13px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Digital wallets (Apple Pay, Google Pay)
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack alignItems={"center"} justifyItems={"center"}>
                      <Box>
                        <Button
                          variant={"ghost"}
                          h={"38px"}
                          color={"#3454d1"}
                          rightIcon={
                            <HiOutlineArrowTopRightOnSquare fontSize={"20px"} />
                          }
                          _hover={{
                            bg: "rgba(0,10,30,.05)",
                          }}
                        >
                          View Stripe transaction fees
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                  <Checkbox
                    size={"lg"}
                    alignItems={"center"}
                    marginTop={"32px"}
                    paddingBottom={"4px"}
                  >
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontSize={"17px"}
                      fontWeight={"bold"}
                    >
                      Payments advice (invoice and statement)
                    </Text>
                  </Checkbox>
                  <Stack paddingLeft={"32px"} gap={0}>
                    <Textarea
                      value={text}
                      onChange={handleChange}
                      fontSize={"15px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      h={"160px"}
                      padding={"7px 16px"}
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                    />
                    <Text
                      paddingTop={"8px"}
                      marginBottom={"76px"}
                      fontSize={"11px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      Enter your bank account number and any other details on
                      how you would like to be paid. This information will
                      display on all invoices and customer statements.
                    </Text>
                  </Stack>
                </Container>
              </Stack>

              <Stack flex={1} padding={"8px 20px"} position="sticky" top="0">
                <Stack padding={"8px 20px"}>
                  <Stack
                    padding={"16px 40px"}
                    boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                    border="1px solid #e5e7eb"
                    _light={{ bg: "customColor.white" }}
                  >
                    <Image
                      src={sampleImage}
                      alt="Background"
                      w={"100%"}
                      h={"100%"}
                      objectFit={"cover"}
                    />
                  </Stack>
                </Stack>
                <VStack>
                  <Text
                    fontSize={"15px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    marginBottom={"24px"}
                  >
                    This Sample image shows where your information will appear
                  </Text>
                </VStack>
              </Stack>
            </HStack>
          </DrawerBody>

          <DrawerFooter
            boxShadow={
              "0 -1px 0 0 rgba(0,10,30,.2),0 -3px 0 0 rgba(0,10,30,.05)"
            }
            border="1px solid #e5e7eb"
            padding={"16px"}
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"12px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
            >
              Save and set up Stripe
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </FormProvider>
  );
}
