import { Container, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
export default function LeaveDummy() {
  return (
    <>
      <Container
        justifyContent="center"
        alignItems="center"
        textAlign={"center"}
        p="0px"
        maxW="940px"
        border="1px solid #ddd"
        borderRadius="5px"
      >
        <Table variant="simple" size="sm">
          <Thead
            h="30px"
            bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
          >
            <Tr>
              {[
                "Employee",
                "Leave Type",
                "Start Date",
                "End Date",
                "Hours",
              ].map((item, i) => {
                return (
                  <Th
                    color="primary.700"
                    fontSize="11px"
                    borderRight="1px solid #ddd"
                    key={i}
                    style={item === "Leave Type" ? { width: "40%" } : {}}
                  >
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Danish</Td>
              <Td>Annual Leave</Td>
              <Td>1 Oct 2023</Td>
              <Td>1 Nov 2023</Td>
              <Td>9</Td>
            </Tr>
            <Tr>
              <Td>Irsam</Td>
              <Td>Personal Leave</Td>
              <Td>15 May 2023</Td>
              <Td>5 June 2023</Td>
              <Td>8</Td>
            </Tr>
            <Tr>
              <Td>Sameer</Td>
              <Td>Community Service Leave</Td>
              <Td>5 July 2023</Td>
              <Td>20 July 2023</Td>
              <Td>7</Td>
            </Tr>
          </Tbody>
        </Table>
      </Container>
    </>
  );
}
