import {
  Box,
  Button,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { NavigateFunction, useParams } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { PayItemDeductionApi } from "../../Api/PayItemDeduction";
import { PayItemEarningApi } from "../../Api/PayItemEarning";
import { PayItemReimbursementApi } from "../../Api/PayItemReinbursement";
import { PayItemRetirement } from "../../Api/PayItemRetirement";
import { PayTemp } from "../../Api/PayTemp";

import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import { Deduction } from "./components/deduction";
import { Earning } from "./components/earning";
import { Reimbursement } from "./components/reimbursement";
import { Retirement } from "./components/retirement";
import { DeductionLineModal } from "./Modal/DeductionLine";
import { EarningLineModal } from "./Modal/EarningLineModal";
import { ReimbursementLineModal } from "./Modal/ReimbursementLine";
import { StaturyBenefitsModal } from "./Modal/StaturyBenefitsModal";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
  navigate: NavigateFunction;
}
export default function Paytemp({
  setSelectedTab,
  selectedTab,
  navigate,
}: TabProps) {
  const toast = useToast();

  const form = useForm<PayTemp.AddNewPayTemp>({
    defaultValues: {
      grossSalary: 0,
      earnings: [],
      deductions: [],
      reimbursments: [],
      retirements: [],
      deletedIds: [],
    },
  });
  const earningLine = useDisclosure();
  const [accountEarning, setAccountEarning] = useState<
    PayItemEarningApi.EarningById[]
  >([]);
  const deductionLine = useDisclosure();
  const [accountDeduction, setAccountDeduction] = useState<
    PayItemDeductionApi.DeductionById[]
  >([]);
  const reimbursementLine = useDisclosure();
  const retirementModal = useDisclosure();
  const [accountReimbursement, setAccountReimbursement] = useState<
    PayItemReimbursementApi.ReimbursementById[]
  >([]);
  const [accountRetirement, setAccountRetirement] = useState<
    Array<PayItemRetirement.FetchRetirementFund>
  >([]);
  const param = useParams();
  const earningFieldArray = useFieldArray({
    control: form.control,
    name: "earnings",
    keyName: "uniqueId",
  });
  const deductionFieldArray = useFieldArray({
    control: form.control,
    name: "deductions",
    keyName: "uniqueId",
  });
  const reimbursementFieldArray = useFieldArray({
    control: form.control,
    name: "reimbursments",
    keyName: "uniqueId",
  });
  const retirementFieldArray = useFieldArray({
    control: form.control,
    name: "retirements",
    keyName: "uniqueId",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (param.id) {
      PayTemp.GetPayTemp(
        param.id!,
        (data) => {
          form.reset(data);
          setIsLoading(false);
        },
        (error) => {
          toast({
            title: "Pay Template not found",
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Pay Template not found",
        status: "error",
      });
    }
  }, []);
  const [earningResetData, setEarningResetData] = useState<{
    payItemEarningId: string;
    calculationType: string;
    rateType: PayTemp.PayTemplateRateTypeEnum;
    index?: number;
  }>({
    payItemEarningId: "",
    calculationType: "",
    rateType: PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT,
    index: undefined,
  });
  const [deductionResetData, setDeductionResetData] = useState<{
    payItemDeductionId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemDeductionId: "",
    calculationType: "",
    index: undefined,
  });
  const [reimbursementResetData, setReimbursementResetData] = useState<{
    payItemReimbursementId: string;
    description: string;
    index?: number;
  }>({
    payItemReimbursementId: "",
    description: "",
    index: undefined,
  });
  const [retirementResetData, setRetirementResetData] = useState<{
    payItemRetirementId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemRetirementId: "",
    calculationType: "",
    index: undefined,
  });

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        justifyContent="center"
        alignItems="center"
        textAlign={"left"}
        marginBottom={"30px"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Heading
          textAlign="left"
          fontSize=".9975rem"
          _light={{
            color: "customColor.black7",
          }}
          fontWeight="bold"
          padding="20px"
          as="h2"
          size="md"
        >
          Pay Template
        </Heading>

        <Box
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginTop="20px"
          px="10px"
        >
          <Stack spacing="20px">
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.gray.950",
              }}
              border={"1px solid #e5e5e5"}
              padding={"15px 10px 20px"}
              gap={0}
            >
              <Stack direction="row" alignItems={"center"}>
                <Stack flex={1}>
                  <Text
                    fontSize={"12px "}
                    margin={"0px 0px 4px"}
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"700"}
                  >
                    Gross Salary
                  </Text>
                  <Text
                    color={"primary.950"}
                    padding={"6px 0px 0px"}
                    fontSize={"12px"}
                    borderTop={"1px solid #CCC !important"}
                    borderBottom={"1px solid #E8EAEC !important"}
                    marginBottom={"5px"}
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    Gross Salary
                  </Text>
                </Stack>
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Gross Salary is required",
                        },
                      }}
                      defaultValue={0}
                      control={form.control}
                      name={`grossSalary`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            defaultValue: 0,
                            bg: "#fff",
                            variant: "outline",
                            type: "text",
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : "",
                            onChange: (e: any) => {
                              const value = e.target.value.replace(/,/g, "");
                              field.onChange(Number(value));
                            },
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                            maxW: "91.33px",
                            height: "26.33px",
                            padding: "0px 0px 0px 3px",
                            boxShadow: "none",
                            placeholder: "",
                            _hover: {
                              borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                            },
                          }}
                          label="Fixed Amount"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              </Stack>
            </Stack>
            {/* <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                earningFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                earningFieldArray.fields.length > 0 ? "15px 10px 20px" : "none"
              }
              gap={0}
            >
              <Stack direction="row" alignItems={"center"}>
                <Stack flex={1}>
                  <Text
                    fontSize={"12px "}
                    margin={"0px 0px 4px"}
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"700"}
                  >
                    Gross Salary
                  </Text>
                  <Text
                    color={"#0590C3"}
                    padding={"6px 0px 0px"}
                    fontSize={"12px"}
                    borderTop={"1px solid #CCC !important"}
                    borderBottom={"1px solid #E8EAEC !important"}
                    marginBottom={"5px"}
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    Gross Salary
                  </Text>
                </Stack>
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Gross Salary is required",
                        },
                      }}
                      defaultValue={0}
                      control={form.control}
                      name={`grossSalary`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            defaultValue: 0,
                            variant: "outline",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(Number(e.target.valueAsNumber));
                            },
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                            maxW: "91.33px",
                            height: "26.33px",
                            padding: "0px 0px 0px 3px",
                            boxShadow: "none",
                            placeholder: "",
                            _hover: {
                              borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                            },
                          }}
                          label="Fixed Amount"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#444",
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              </Stack>
            </Stack> */}
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                earningFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                earningFieldArray.fields.length > 0 ? "15px 10px 20px" : "none"
              }
              gap={0}
            >
              <Earning
                form={form}
                earningFieldArray={earningFieldArray}
                earningLine={earningLine}
                accountEarning={accountEarning}
                setAccountEarning={setAccountEarning}
                setResetData={setEarningResetData}
              />
              <Button
                variant="outline"
                fontSize="12px"
                bg="primary.50"
                color="primary.950"
                _hover={{
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                w={"141px"}
                bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                onClick={() => {
                  setEarningResetData({
                    payItemEarningId: "",
                    calculationType: "",
                    rateType: PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT,
                    index: undefined,
                  });
                  earningLine.onOpen();
                }}
              >
                + Add Earning Line
              </Button>
              <EarningLineModal
                isOpen={earningLine.isOpen}
                onClose={earningLine.onClose}
                resetData={earningResetData}
                form={form}
                onPressOK={(data) => {
                  data?.index !== undefined
                    ? earningFieldArray.update(data?.index, {
                        ...data,
                        payTemplateId: form.getValues("id"),
                      })
                    : earningFieldArray.append({
                        ...data,
                        payTemplateId: form.getValues("id"),
                      });
                }}
                account={accountEarning}
              />
            </Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                deductionFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                deductionFieldArray.fields.length > 0
                  ? "15px 10px 20px"
                  : "none"
              }
            >
              <Deduction
                form={form}
                deductionFieldArray={deductionFieldArray}
                deductionLine={deductionLine}
                accountDeduction={accountDeduction}
                setAccountDeduction={setAccountDeduction}
                setResetData={setDeductionResetData}
              />
              <Button
                variant="outline"
                fontSize="12px"
                bg="primary.50"
                color="primary.950"
                _hover={{
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                w={"141px"}
                bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                onClick={() => {
                  setDeductionResetData({
                    payItemDeductionId: "",
                    calculationType: "",
                    index: undefined,
                  });
                  deductionLine.onOpen();
                }}
              >
                + Add Deduction Line
              </Button>
              <DeductionLineModal
                isOpen={deductionLine.isOpen}
                onClose={deductionLine.onClose}
                resetData={deductionResetData}
                onPressOK={(data) => {
                  data?.index !== undefined
                    ? deductionFieldArray.update(data?.index, {
                        ...data,
                        payTemplateId: form.getValues("id"),
                      })
                    : deductionFieldArray.append({
                        ...data,
                        payTemplateId: form.getValues("id"),
                      });
                }}
                account={accountDeduction}
              />
            </Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                reimbursementFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                reimbursementFieldArray.fields.length > 0
                  ? "15px 10px 20px"
                  : "none"
              }
            >
              <Reimbursement
                form={form}
                reimbursementFieldArray={reimbursementFieldArray}
                reimbursementLine={reimbursementLine}
                accountReimbursement={accountReimbursement}
                setAccountReimbursement={setAccountReimbursement}
                setResetData={setReimbursementResetData}
              />
              <Button
                variant="outline"
                fontSize="12px"
                bg="primary.50"
                color="primary.950"
                _hover={{
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                w={"181px"}
                bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                onClick={() => {
                  setReimbursementResetData({
                    payItemReimbursementId: "",
                    description: "",
                    index: undefined,
                  });
                  reimbursementLine.onOpen();
                }}
              >
                + Add Reimbursement Line
              </Button>
              <ReimbursementLineModal
                isOpen={reimbursementLine.isOpen}
                onClose={reimbursementLine.onClose}
                resetData={reimbursementResetData}
                onPressOK={(data) => {
                  data?.index !== undefined
                    ? reimbursementFieldArray.update(data?.index, {
                        ...data,
                        payTemplateId: form.getValues("id"),
                      })
                    : reimbursementFieldArray.append({
                        ...data,
                        payTemplateId: form.getValues("id"),
                      });
                }}
                account={accountReimbursement}
              />
            </Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor:
                  earningFieldArray.fields.length > 0
                    ? "customColor.gray.950"
                    : "#FFF",
              }}
              border={
                retirementFieldArray.fields.length > 0
                  ? "1px solid #e5e5e5"
                  : "none"
              }
              padding={
                retirementFieldArray.fields.length > 0
                  ? "15px 10px 20px"
                  : "none"
              }
            >
              <Retirement
                form={form}
                retirementFieldArray={retirementFieldArray}
                retirementLine={retirementModal}
                accountRetirement={accountRetirement}
                setAccountRetirement={setAccountRetirement}
                setResetData={setRetirementResetData}
              />
              <Button
                variant="outline"
                fontSize="12px"
                bg="primary.50"
                color="primary.950"
                _hover={{
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                w={"181px"}
                bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                onClick={() => {
                  setRetirementResetData({
                    payItemRetirementId: "",
                    calculationType: "",
                    index: undefined,
                  });
                  retirementModal.onOpen();
                }}
              >
                + Add Statuary Benefits Line
              </Button>
              <StaturyBenefitsModal
                isOpen={retirementModal.isOpen}
                onClose={retirementModal.onClose}
                resetData={retirementResetData}
                onPressOK={(data) => {
                  data?.index !== undefined
                    ? retirementFieldArray.update(data?.index, {
                        ...data,
                        payTemplateId: form.getValues("id"),
                      })
                    : retirementFieldArray.append({
                        ...data,
                        payTemplateId: form.getValues("id"),
                      });
                }}
                account={accountRetirement}
              />
            </Stack>
          </Stack>

          <HStack marginTop="10px" justifyContent="end" spacing="8px">
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              // onClick={() => window.location.reload()}
              onClick={() => {
                navigate(-1);
                // navigate("/app/payroll/PayrollEmployee");
              }}
            >
              Cancel
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#3454D1"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              // onClick={() => window.location.reload()}
              onClick={() => form.reset()}
            >
              Reset
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  PayTemp.PayTempAdded(
                    param.id!,
                    data,
                    (success) => {
                      toast({
                        title: "Pay Template is added",

                        status: "success",
                      });
                      setIsLoading(false);
                      form.reset(success);

                      setSelectedTab(selectedTab + 1);
                    },
                    (error) => {
                      toast({
                        title: "Pay Template Failed",
                        description: error,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                })(e);
              }}
            >
              Save and Next
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  PayTemp.PayTempAdded(
                    param.id!,
                    data,
                    (success) => {
                      toast({
                        title: "Pay Template is added",

                        status: "success",
                      });
                      setIsLoading(false);
                      form.reset(success);
                    },
                    (error) => {
                      toast({
                        title: "Pay Template Failed",
                        description: error,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                })(e);
              }}
            >
              Save
            </Button>
          </HStack>
        </Box>
        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Box>
    </Skeleton>
  );
}
