import axiosInstanceGateway from "./axiosInstance-gateway";

declare module BusinessBillingsApi {
  type BusinessBillingsData = {
    id?: string;
    invoiceType: string;
    dayOfMonth: number;
    occurence: string;
  };
}
module BusinessBillingsApi {
  const API_URL = "/business-billings";
  export function AddBusinessBillings(
    data: BusinessBillingsData,
    success: (data: BusinessBillingsData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetBusinessBillings(
    businessId: string | undefined,
    success: (data: BusinessBillingsData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/${businessId}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { BusinessBillingsApi };
