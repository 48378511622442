import { Box, HStack, Progress, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import getRandomHexCode from "../../../Payroll/Api/getRandomHexCode";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export function Provinces() {
  const form = useFormContext<Analytics.DashBoardData>();
  const { editDashboard, dashboardAnalytics } = useDashboardContext();
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   mb="20px"
    //   boxShadow="0 4px 8px rgba(15,34,58,.35)"
    //   _hover={{
    //     boxShadow: "0 4px 8px rgba(15,34,58,.65)",
    //   }}
    //   bgColor="#FFFFFF"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   overflowY="auto"
    //   // display={
    //   //   editDashboard || form.getValues("hideOrShowTiles.provincesData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    // >
    <Box
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      w="100%"
      // h="100%"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
      sx={{
        "::-webkit-scrollbar": {
          width: "0px", // Initially, the scrollbar is hidden
          background: "transparent",
        },
        ":hover::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on hover
        },
        ":active::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible when active
        },
        ":focus::-webkit-scrollbar": {
          width: "8px", // Scrollbar visible on focus
        },
        // Add for Firefox and other browsers if needed
        scrollbarWidth: "none",
        ":hover": {
          scrollbarWidth: "thin",
        },
        ":active": {
          scrollbarWidth: "thin",
        },
        ":focus": {
          scrollbarWidth: "thin",
        },
      }}
    >
      <Box padding={"25px"} borderBottom={"1px solid #e5e7eb"}>
        <Text
          fontSize="16px"
          fontWeight={600}
          lineHeight="1.2"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
        >
          Provinces
        </Text>
      </Box>

      <Box padding={"25px"}>
        {Object.entries(dashboardAnalytics?.employeeCountInProvinces || {}).map(
          ([key, value]: any, i) => (
            <HStack key={key} mt="8px">
              <Text
                w="30%"
                fontSize="14.4px"
                fontWeight={400}
                lineHeight="1.5"
                _light={{
                  color: "customColor.gray.64748B",
                }}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {key}
              </Text>

              <Box w="70%">
                <Progress
                  value={
                    (value / Number(dashboardAnalytics?.totalEmpCount)) * 100
                  }
                  borderRadius={"4px"}
                  size={"sm"}
                  flex={1}
                  sx={{
                    "& > div": {
                      bgColor: getRandomHexCode(),
                    },
                  }}
                />
              </Box>
            </HStack>
          )
        )}
      </Box>
    </Box>
    // </Card>
  );
}
