import {
  Box,
  Button,
  Checkbox,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { ChartOfAccounts } from "../../../../../Api/ChartOfAccounts";
import { PayItemEarningApi } from "../../../../../Api/PayItemEarning";
interface EmploymentTerminationModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedEarningId: string | undefined;
  setLastUpdated: (timeStamp: number) => void;
  allAccount: ChartOfAccounts.FetchChartOfAccounts[];
}
export default function EmploymentTerminationModal({
  isOpen,
  onClose,
  allAccount,
  setLastUpdated,
  selectedEarningId,
}: EmploymentTerminationModalProps) {
  const toast = useToast();
  const form = useFormContext<PayItemEarningApi.AddNewEarning>();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FormProvider {...form}>
      <Box overflowY="auto">
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            maxW="360px"
            maxH={"650px"}
          >
            <ModalHeader
              fontSize={"15.21px"}
              margin={"15.21px 0pxs"}
            ></ModalHeader>
            <ModalCloseButton />
            <Box overflowY="auto">
              <ModalBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={
                    "https://edge.xero.com/people/payroll/assets/images/settings/pay-items/earnings.svg"
                  }
                />
                <Heading
                  fontSize={"15px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"15px 0px"}
                  textAlign={"center"}
                >
                  Employment Termination Payment
                </Heading>
              </ModalBody>

              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  mb={"5px"}
                >
                  Earnings Name
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Earning Name is required",
                    },
                  }}
                  control={form.control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                {""}
                <Controller
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: "Earning Name is required",
                  //   },
                  // }}
                  control={form.control}
                  name="displayName"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        // isInvalid: error !== undefined,
                        h: "",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "Displayed on employees' payslips",
                      }}
                      label="Display Name (Optional)"
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"600"}
                >
                  Expense Account
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Account type is required",
                    },
                  }}
                  control={form.control}
                  name="chartAccountId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Account type"
                      withValidation
                      options={allAccount}
                      value={{
                        id: field.value,
                        code:
                          allAccount.find(
                            (account) => account.id === field.value
                          )?.code ?? "",
                        name:
                          allAccount.find(
                            (account) => account.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) =>
                        `${option.code}:${option.name}`
                      }
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"600"}
                >
                  ETP Type
                </Text>
                {/* <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Allowance type is required",
                    },
                  }}
                  control={control}
                  name="etpType"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Allowance type"
                      withValidation
                      options={etp}
                      value={etp.find((etp) => etp.value === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                /> */}
              </ModalBody>
              <ModalBody>
                <Stack>
                  <Controller
                    control={form.control}
                    name="isTaxable"
                    render={({
                      field: { value, ...field },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        {...field}
                        isChecked={value}
                        isInvalid={error !== undefined}
                        colorScheme="rgb(50,70,90,.35)"
                        margin={"1px 7px 0px 0px"}
                        iconColor={"#fff"}
                        // isChecked={value}
                      >
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          Apply income tax
                        </Text>
                      </Checkbox>
                    )}
                  />
                  <Controller
                    control={form.control}
                    name="retirementFundExcemption"
                    render={({
                      field: { value, ...field },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        {...field}
                        isChecked={value}
                        isInvalid={error !== undefined}
                        colorScheme="rgb(50,70,90,.35)"
                        margin={"1px 7px 0px 0px"}
                        iconColor={"#fff"}
                        // isChecked={value}
                      >
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          Exempt from Retirement Guarantee Contribution
                        </Text>
                      </Checkbox>
                    )}
                  />
                  <Controller
                    control={form.control}
                    name="reportableActivityStatement"
                    render={({
                      field: { value, ...field },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        {...field}
                        isChecked={value}
                        isInvalid={error !== undefined}
                        colorScheme="rgb(50,70,90,.35)"
                        margin={"1px 7px 0px 0px"}
                        iconColor={"#fff"}
                        // isChecked={value}
                      >
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          Reportable as W1 on Activity Statement
                        </Text>
                      </Checkbox>
                    )}
                  />
                </Stack>
              </ModalBody>
            </Box>

            <ModalFooter>
              <Button
                onClick={onClose}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
              <Button
                marginLeft={"8px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (selectedEarningId) {
                      PayItemEarningApi.UpdateEarning(
                        selectedEarningId,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Earning is updated",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Earning Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayItemEarningApi.NewEarningAdded(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Earning is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Earning Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {selectedEarningId ? "Update" : "Add"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </FormProvider>
  );
}
