import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedCalendarId: string | undefined;
}
export function Delete({
  isOpen,
  onClose,
  setLastUpdated,
  selectedCalendarId,
}: DeleteProps) {
  const toast = useToast();
  const form = useFormContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent maxW={"360px"}>
        <ModalHeader
          padding={"0px 50px 0px 20px"}
          margin={"15px 0px"}
          fontSize={"18px"}
          fontWeight={"700"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Delete Pay Calendar
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"0px 20px"} margin={"13px 0px"}>
          <Text
            fontSize={"13px"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Are you sure want to delete this pay calendar?
          </Text>
        </ModalBody>
        <ModalFooter padding={"20px"}>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel{" "}
          </Button>
          <Button
            ml="8px"
            variant="solid"
            fontSize={"13px"}
            margin={"0px 0px 0px 10px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                PayrollCalendar.DeleteCalendar(
                  selectedCalendarId!,
                  (success) => {
                    setLastUpdated(Date.now());
                    toast({
                      title: "Calendar is deleted",
                      status: "success",
                    });
                    setIsLoading(false);
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Delete Calendar Failed",
                      description: error,
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                );
              })(e);
            }}
          >
            {" "}
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
