import { Checkbox, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { InviteUser } from "../../../../../api/InviteUser";
interface TimeAttendancePermissionsProps {
  isLoading?: boolean;
}
export default function TimeAttendancePermissions({
  isLoading,
}: TimeAttendancePermissionsProps) {
  const form = useFormContext<InviteUser.UserInviteDetail>();
  // const parentCheckbox = useWatch({
  //   control: form.control,
  //   name: "allowedFeatures.timeAndAttendance",
  // });
  return (
    <Stack paddingLeft={"2.5px"} gap={0} paddingTop={"10px"}>
      <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
        {/* <Checkbox
          paddingLeft={"8px"}
          size={"md"}
          value=""
          isChecked={Object.values(parentCheckbox).every((value) => {
            return value;
          })}
          // isIndeterminate={checkedItems.some(Boolean) && !checkAll}
          onChange={(e) => {
            form.setValue(
              "permissions.timeAndAttendance.schedules",
              e.target.checked
            );
            form.setValue(
              "permissions.timeAndAttendance.locations",
              e.target.checked
            );
            form.setValue(
              "permissions.timeAndAttendance.notifications",
              e.target.checked
            );
          }}
        >
          <Text
            fontSize={"13px"}
            padding={"8px 0px"}
            color={"#000A1EBF"}
            fontWeight={"bold"}
            flex={1}
          >
            Full Access
          </Text>
        </Checkbox> */}
      </Stack>
      <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
        <SimpleGrid columns={3}>
          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.timeAndAttendance.schedules"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  value={field.value?.toString()}
                  isChecked={field.value}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Schedule
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.timeAndAttendance.locations"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Location
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.timeAndAttendance.notifications"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  size={"md"}
                  isChecked={field.value}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Notification
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
}
