import axiosInstanceGateway from "../Payroll/Api/axiosInstance-gateway";

declare module BusinessInvitation {
  type AcceptInvitation = {
    error: string;
    action: string;
  };
}
module BusinessInvitation {
  const API_URL = "/business-invitation";

  export function AcceptInvitation(
    inviteToken: string,
    success: (response: AcceptInvitation) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/accept/${inviteToken}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { BusinessInvitation };
