import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { BusinessBillingsApi } from "../../Api/BusinessBillings";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Billings({ setSelectedTab, selectedTab }: TabProps) {
  const invoiceOptions: Array<{ label: string; value: string }> = [
    {
      label: "Pre-Paid",
      value: "PRE_PAID",
    },
    {
      label: "Post-Paid",
      value: "POST_PAID",
    },
  ];
  const billingOptions: Array<{ label: string; value: string }> = [
    {
      label: "of the following month",
      value: "FOLLOWING_MONTH",
    },
    {
      label: "day after the bill date",
      value: "ONE_DAY_AFTER_BILLING_MONTH",
    },
    {
      label: "day after the bill month",
      value: "ONE_DAY_AFTER_DUE_DATE",
    },
    {
      label: "of the current month",
      value: "BILLING_MONTH",
    },
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const { currentBusinessId } = useBusinessContext();
  const form = useForm<BusinessBillingsApi.BusinessBillingsData>({
    defaultValues: {
      invoiceType: "",
      dayOfMonth: 0,
      occurence: "",
    },
  });
  useEffect(() => {
    if (currentBusinessId) {
      BusinessBillingsApi.GetBusinessBillings(
        currentBusinessId,
        (data) => {
          form.reset(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [currentBusinessId, form.reset]);
  const watchOccurence = useWatch({
    control: form.control,
    name: "occurence",
  });
  return (
    <Stack
      marginTop={"15px"}
      p="0px"
      // maxW="940px"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      // border="1px solid #d6dade"
      // borderRadius="md"
      marginBottom={"1.25rem"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
      >
        <HStack gap={0}>
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px"
            as="h2"
            _light={{
              color: "customColor.black7",
            }}
            flex={1}
            size="md"
          >
            Billings
          </Heading>
          <Link
            color="primary.950"
            fontSize=" var(--x-font-size-small, .8125rem"
            paddingBottom="1px"
            href="/app/payroll/organisationSettings"
          >
            Organisation Settings <Icon as={ChevronRightIcon} />
          </Link>
        </HStack>

        <Divider orientation="horizontal" />

        <Container marginBottom={"25px"}>
          <FormLabel
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontWeight="600"
            fontSize=".845rem"
            paddingTop="25px"
          >
            Invoice
          </FormLabel>

          <Controller
            rules={{
              required: {
                value: true,
                message: "Invoice Type is required",
              },
            }}
            control={form.control}
            name="invoiceType"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                placeholder="Select Invoice Type"
                // marginBottom="30px"
                // width={"48em"}
                isInvalid={fieldState.invalid}
                ErrorMessage="Select Invoice Type "
                withValidation
                options={invoiceOptions}
                value={invoiceOptions.find(
                  (invoiceOptions) => invoiceOptions.value === field.value
                )}
                onChange={(newValue: any) => field.onChange(newValue?.value)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
          <FormLabel
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontWeight="600"
            fontSize=".845rem"
            paddingTop="25px"
          >
            Bill Due Date
          </FormLabel>
          <HStack gap={0} alignItems={"baseline"}>
            <Stack flex={1}>
              <Controller
                name="dayOfMonth"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Day Of Month is required",
                  },
                  validate: (value) => {
                    if (value < 0) {
                      return "Day Of Month cannot be negative";
                    } else if (
                      value > 31 &&
                      (watchOccurence === "FOLLOWING_MONTH" ||
                        watchOccurence === "BILLING_MONTH")
                    ) {
                      return "Day Of Month cannot be more than 31";
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      fontSize: ".845rem",
                      h: "38px",
                      variant: "outline",
                      type: "text",
                      onChange: (e) => {
                        field.onChange(Number(e.target.value));
                      },

                      borderTopRadius: "3px",
                      borderRightRadius: "0px",
                      alignContent: "center",
                    }}
                    placeholder=""
                  />
                )}
              />
            </Stack>
            <Stack flex={6}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Occurrence Type is required",
                  },
                }}
                control={form.control}
                name="occurence"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select Occurrence"
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Occurrence "
                    withValidation
                    options={billingOptions}
                    value={billingOptions.find(
                      (billingOptions) => billingOptions.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </Stack>
          </HStack>
        </Container>
        <Stack
          alignItems={"flex-end"}
          marginBottom={"15px"}
          marginRight={"15px"}
        >
          <ButtonGroup
            borderRadius="4px"
            fontSize="var(--x-font-size-small, .8125rem)"
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    BusinessBillingsApi.AddBusinessBillings(
                      data,
                      (success) => {
                        toast({
                          title: "Business Billings Added",

                          status: "success",
                        });
                        setIsLoading(false);

                        setSelectedTab(selectedTab + 1);
                      },
                      (error) => {
                        toast({
                          title: "Business Billings Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              {isLoading ? "Saving..." : " Save and Next"}
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    BusinessBillingsApi.AddBusinessBillings(
                      data,
                      (success) => {
                        toast({
                          title: "Business Billings Added",

                          status: "success",
                        });
                        setIsLoading(false);
                      },
                      (error) => {
                        toast({
                          title: "Business Billings Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              {isLoading ? "Saving..." : " Save "}
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
    </Stack>
  );
}
