import { HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useAuthHeader } from "react-auth-kit";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayTemp } from "../../../Api/PayTemp";

interface EarningFileProps {
  form?: any;
  earningFieldArray?: any;
  earningLine?: any;
  earningAdded: any[];

  setResetData: (resetData: any) => void;
  currentEmployeeId?: string | undefined;
}
export function EarningFile({
  form,
  earningFieldArray,
  earningLine,

  earningAdded,
  currentEmployeeId,
  setResetData,
}: EarningFileProps) {
  const toast = useToast();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());

  return (
    <Stack>
      <Text
        fontSize={"12px "}
        margin={"0px 0px 4px"}
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
      >
        Earnings
      </Text>
      {earningFieldArray.fields.map(
        (item: PayTemp.EarningRate & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                fontSize={"12px "}
                margin={"0px 0px 4px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"700"}
              >
                Earnings Rate{" "}
              </Text>
              <Text
                color={"primary.950"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setResetData({ ...item, index: i });
                  earningLine.onOpen();
                }}
              >
                {(earningAdded &&
                  earningAdded.find(
                    (earningAdded) => earningAdded.id === item.payItemEarningId
                  )?.name) ??
                  ""}
              </Text>
            </Stack>

            {form.getValues(`earnings.${i}.calculationType`) === "RATE" &&
              earningAdded &&
              earningAdded.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "RATE_PER_UNIT" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Hour is required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.hours`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            bg: "#fff",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(Number(e.target.valueAsNumber));
                            },
                            _light: {
                              color: "customColor.black7",
                            },
                            maxW: "91.33px",
                            height: "26.33px",
                            placeholder: "",
                          }}
                          label={
                            earningAdded &&
                            earningAdded.find(
                              (earningAdded) =>
                                earningAdded.id === item.payItemEarningId
                            )?.rateUnitType
                          }
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "rate is required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.rate`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            bg: "#fff",
                            type: "text",
                            value: field.value
                              ? Number(field.value)?.toLocaleString()
                              : "",
                            onChange: (e: any) => {
                              const value = e.target.value?.replace(/,/g, "");
                              field.onChange(Number(value));
                            },
                            maxW: "91.33px",
                            height: "26.33px",
                            _light: {
                              color: "customColor.black7",
                            },

                            placeholder: "",
                          }}
                          label="Rate"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />

                    <CustomInput
                      withValidation
                      input={{
                        isInvalid: false,
                        fontSize: "12px",
                        variant: "outline",
                        type: "text",
                        bg: "#fff",
                        isDisabled: true,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        value: Number(
                          `${(
                            (form.watch(`earnings.${i}.hours`) ?? 0) *
                            (form.watch(`earnings.${i}.rate`) ?? 0)
                          )?.toFixed(2)}`
                        )?.toLocaleString(),
                        maxW: "91.33px",
                        height: "26.33px",
                        _light: {
                          color: "customColor.black7",
                        },
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                        _hover: {},
                      }}
                      label="Total"
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        _light: {
                          color: "customColor.black7",
                        },
                        margin: "0px 0px 4px",
                      }}
                    />
                  </HStack>
                </Stack>
              )}
            {form.getValues(`earnings.${i}.calculationType`) === "RATE" &&
              earningAdded?.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "GROSS_SALARY" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      control={form.control}
                      name={`earnings.${i}.hours`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            bg: "#fff",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(Number(e.target.valueAsNumber));
                            },
                            isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                            _light: {
                              color: "customColor.black7",
                            },

                            maxW: "91.33px",
                            height: "26.33px",

                            placeholder: "",
                          }}
                          label="Days"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={form.control}
                      name={`earnings.${i}.rate`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            // isDisabled: true,
                            variant: "outline",
                            bg: "#fff",
                            type: "text",
                            // value: field.value
                            //   ? Number(field.value).toLocaleString()
                            //   : "",
                            // value: `$`,
                            onChange: (e: any) => {
                              const value = e.target.value.replace(/,/g, "");
                              field.onChange(Number(value));
                            },

                            maxW: "91.33px",
                            height: "26.33px",
                            _light: {
                              color: "customColor.black7",
                            },

                            placeholder: "",
                          }}
                          label="Rate"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />

                    <CustomInput
                      withValidation
                      input={{
                        isInvalid: false,
                        fontSize: "12px",
                        variant: "outline",
                        type: "number",

                        isDisabled: true,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        value: `${Math.ceil(
                          form.watch(`earnings.${i}.hours`) *
                            (form.watch(`earnings.${i}.rate`) ?? 0)
                        )}`,
                        maxW: "91.33px",
                        height: "26.33px",
                        _light: {
                          color: "customColor.black7",
                        },
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                        _hover: {},
                      }}
                      label="Amount"
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        _light: {
                          color: "customColor.black7",
                        },
                        margin: "0px 0px 4px",
                      }}
                    />
                  </HStack>
                </Stack>
              )}
            {form.getValues(`earnings.${i}.calculationType`) ===
              "ANNUAL_SALARY" &&
              earningAdded?.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "GROSS_SALARY" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      name={`earnings.${i}.amount`}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Amount is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            type: "text",
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : "",
                            onChange: (e: any) => {
                              const value = e.target.value.replace(/,/g, "");
                              field.onChange(Number(value));
                            },
                            borderWidth: "1px",
                            borderStyle: "solid",
                            maxW: "91.33px",
                            height: "26.33px",
                            _light: {
                              color: "customColor.black7",
                            },
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label="Amount"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              )}
            {form.getValues(`earnings.${i}.calculationType`) ===
              "USE_EARNINGS_RATE_MULTIPLY" &&
              earningAdded &&
              earningAdded.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "RATE_PER_UNIT" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Hours is required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.hours`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            bg: "#fff",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(Number(e.target.valueAsNumber));
                            },
                            borderWidth: "1px",
                            borderStyle: "solid",
                            maxW: "91.33px",
                            height: "26.33px",
                            _light: {
                              color: "customColor.black7",
                            },
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label={
                            earningAdded &&
                            earningAdded.find(
                              (earningAdded) =>
                                earningAdded.id === item.payItemEarningId
                            )?.rateUnitType
                          }
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              )}

            {form.getValues(`earnings.${i}.calculationType`) ===
              "ANNUAL_SALARY" &&
              earningAdded &&
              earningAdded.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "RATE_PER_UNIT" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Units are required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.hours`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            variant: "outline",
                            type: "number",
                            onChange: (e) => {
                              field.onChange(Number(e.target.valueAsNumber));
                            },
                            borderWidth: "1px",
                            borderStyle: "solid",
                            maxW: "91.33px",
                            bg: "#fff",
                            height: "26.33px",
                            _light: {
                              color: "customColor.black7",
                            },
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label={"Units"}
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Annual Salary is required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.annualSalary`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            bg: "#fff",
                            variant: "outline",
                            type: "text",
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : "",
                            onChange: (e: any) => {
                              const value = e.target.value.replace(/,/g, "");
                              field.onChange(Number(value));
                            },
                            // value: field.value.toLocaleString,
                            borderWidth: "1px",
                            borderStyle: "solid",

                            maxW: "91.33px",
                            height: "26.33px",
                            _light: {
                              color: "customColor.black7",
                            },
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label="Annual Salary "
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              )}
            {form.getValues(`earnings.${i}.calculationType`) === "RATE" &&
              earningAdded &&
              earningAdded.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "FIXED_AMOUNT" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Fixed Amount is required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.earningFixedAmount`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            _light: {
                              color: "customColor.black7",
                            },
                            bg: "#fff",
                            variant: "outline",
                            type: "text",
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : "",
                            onChange: (e: any) => {
                              const value = e.target.value.replace(/,/g, "");
                              field.onChange(Number(value));
                            },

                            borderWidth: "1px",
                            borderStyle: "solid",

                            maxW: "91.33px",
                            height: "26.33px",
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label="Fixed Amount "
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              )}
            {form.getValues(`earnings.${i}.calculationType`) ===
              "USE_EARNINGS_RATE_MULTIPLY" &&
              earningAdded &&
              earningAdded.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "MULTIPLE_OF_ORDINARY_EARNINGS" && (
                <Stack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Units are required",
                      },
                    }}
                    control={form.control}
                    name={`earnings.${i}.hours`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          _light: {
                            color: "customColor.black7",
                          },
                          bg: "#fff",
                          variant: "outline",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },
                          borderWidth: "1px",
                          borderStyle: "solid",
                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                          _hover: {},
                        }}
                        label={"Hours"}
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </Stack>
              )}
            {form.getValues(`earnings.${i}.calculationType`) ===
              "USE_EARNINGS_RATE_MULTIPLY" &&
              earningAdded &&
              earningAdded.find(
                (earningAdded) => earningAdded.id === item.payItemEarningId
              )?.rateType === "FIXED_AMOUNT" && (
                <Stack alignItems={"flex-end"}>
                  <HStack>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Fixed Amount is required",
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.earningFixedAmount`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",
                            _light: {
                              color: "customColor.black7",
                            },
                            bg: "#fff",
                            variant: "outline",
                            type: "text",
                            // value: field.value
                            //   ? Number(field.value).toLocaleString()
                            //   : "",
                            // onChange: (e: any) => {
                            //   const value = e.target.value.replace(/,/g, "");
                            //   field.onChange(Number(value));
                            // },
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : Number("0"),
                            onChange: (e: any) => {
                              const value = e.target.value.replace(/,/g, "");
                              field.onChange(Number(value));
                            },

                            borderWidth: "1px",
                            borderStyle: "solid",

                            maxW: "91.33px",
                            height: "26.33px",
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label="Fixed Amount "
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            _light: {
                              color: "customColor.black7",
                            },
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </HStack>
                </Stack>
              )}
            <Icon
              fontSize={"20px"}
              as={IoIosCloseCircleOutline}
              marginTop={"15px"}
              color={"grey"}
              _hover={{
                color: "red.500",
              }}
              onClick={() => earningFieldArray.remove(i)}
            />
          </Stack>
        )
      )}
    </Stack>
  );
}
