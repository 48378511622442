import {
  Icon,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { IoPerson } from "react-icons/io5";
import { MdGroups } from "react-icons/md";
import { TimeSheetQuantity } from "../TimesheetQuantity/TimeSheetQuantity";
import { TimeSheet } from "./TimeSheet";

export function TimeSheetParents() {
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
    >
      <Tabs position="relative" variant="unstyled" isLazy mt="20px">
        <TabList>
          <Tab
            padding={"8px 16px"}
            margin={"0px 0px -1px"}
            color={"#495057"}
            _selected={{
              color: "customColor.Blueberry",
            }}
          >
            <Stack
              alignItems={"center"}
              _dark={{
                color: "customColor.pealWhite",
              }}
            >
              <Icon as={IoPerson} />
              <Text fontSize={"14.4px"}>Employee Timesheet</Text>
            </Stack>
          </Tab>
          <Tab
            padding={"8px 16px"}
            margin={"0px 0px -1px"}
            color={"#495057"}
            _selected={{
              color: "customColor.Blueberry",
            }}
          >
            <Stack
              alignItems={"center"}
              _dark={{
                color: "customColor.pealWhite",
              }}
            >
              <Icon as={MdGroups} w={"20px"} h={"20px"} />
              <Text fontSize={"14.4px"}>Group Timesheet</Text>
            </Stack>
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel padding={0}>
            <TimeSheet />
          </TabPanel>
          <TabPanel padding={0}>
            <TimeSheetQuantity />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
