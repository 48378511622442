import { axiosInstance } from "./axiosInstance-hris";
declare module PayrollCalendar {
  type AddNewCalendar = {
    payPeriod: string;
    name: string;
    startDate: string;
    nextPaymentDate: string;
  };
  type Calendar = {
    id: string;
    businessId: string;
    payPeriod: string;
    name: string;
    startDate: string;
    nextPaymentDate: string;
    isActive: boolean;
  };
}
module PayrollCalendar {
  const API_URL = "/payroll-calendars";
  export function CreateCalendar(
    data: AddNewCalendar,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateCalendar(
    id: string,
    data: Partial<AddNewCalendar>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchCalendar(
    success: (data: Array<Calendar>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteCalendar(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function IsActive(
    id: string,
    isActive: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/status/${id}/${isActive}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PayrollCalendar };
