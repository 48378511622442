import { Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Finish } from "./Screens/Finish";
import { OrganisationDetail } from "./Screens/OrganisationDetail";

// interface ATOConnectionProps {
//   onAddClicked: (data: any) => void;
// }
export function ATOConnection() {
  // const steps = [{ title: "Organisation details" }, { title: "Finish" }];
  // const { activeStep } = useSteps({
  //   index: 1,
  //   count: steps.length,
  // });

  const [singleTouchSettings, setSingleTouchSettings] = useState<Array<any>>(
    []
  );
  const navigate = useNavigate();
  return (
    <Stack bg={"#FFFFFF"}>
      <Stack padding={"20px"} h={"60px"} border={"1px solid #ccced2"}>
        <Heading
          fontSize={"1.0625rem"}
          fontWeight={"700"}
          lineHeight={"1.75rem"}
        >
          ATO Connection
        </Heading>
      </Stack>
      <Stack padding={"16px"} gap={"-0.5rem"}>
        {/* <OrganisationDetail /> */}
        {singleTouchSettings.length > 0 ? <Finish /> : <OrganisationDetail />}
        {singleTouchSettings.length > 0 ? (
          <HStack justifyContent={"space-between"} paddingBottom={"15px"}>
            <Button
              variant={"ghost"}
              border={"1px solid #a6a9b0"}
              padding={"11px 16px"}
              fontSize={"15px"}
              fontWeight={"700"}
              h={"36px"}
              w={"65px"}
              borderRadius={"3px"}
              onClick={() => {
                setSingleTouchSettings([]);
              }}
            >
              Back
            </Button>
            <Button
              variant={"solid"}
              border={"1px solid"}
              borderColor={"primary.950"}
              padding={"11px 16px"}
              fontSize={"15px"}
              fontWeight={"700"}
              h={"36px"}
              w={"65px"}
              borderRadius={"3px"}
              onClick={() => navigate("/app/payroll/PayEmployee")}
            >
              Done
            </Button>
          </HStack>
        ) : (
          <Stack alignItems={"end"}>
            <Button
              h={"36px"}
              margin={"0px 0px 0px 12px"}
              padding={"11px 16px"}
              borderRadius={"3px"}
              // onAddClicked={(data) => {
              //   setSingleTouchSettings((prev) => [...prev, data]);
              // }}
              onClick={() => {
                setSingleTouchSettings((prev) => [...prev, "123"]);
              }}
            >
              Continue
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
