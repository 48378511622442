import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";
import EmployementTypeChart from "../EmployementTypeChart";

export function EmployementCategory() {
  const { editDashboard, dashboardAnalytics } = useDashboardContext();
  const form = useFormContext<Analytics.DashBoardData>();
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   boxShadow="0 4px 8px rgba(15,34,58,.35)"
    //   _hover={{
    //     boxShadow: "0 4px 8px rgba(15,34,58,.65)",
    //   }}
    //   // display={
    //   //   editDashboard ||
    //   //   form.getValues("hideOrShowTiles.employementCategoryData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    // >
    <Box
      p="25px"
      color={"customColor.black"}
      _dark={{
        bgColor: "customColor.dark.50",
        color: "customColor.dark.150",
      }}
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Flex justifyContent="space-between">
        <Box>
          <Text
            my="4px"
            as={"h4"}
            fontSize="24px"
            fontWeight={500}
            lineHeight="1.2"
            word-wrap="break-word"
          >
            {dashboardAnalytics?.managementStaffCount}
          </Text>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.250",
            }}
            word-wrap="break-word"
            fontSize="14.4px"
            fontWeight="500"
            lineHeight={1.5}
          >
            Management
          </Text>
        </Box>
        <EmployementTypeChart
          contractual={dashboardAnalytics?.managementContractStaffCount}
          permanant={dashboardAnalytics?.managementPermanentStaffCount}
        />
      </Flex>
      <HStack
        mt="16px"
        divider={
          <Text
            mx="4px"
            _dark={{
              color: "customColor.dark.250",
            }}
            _light={{
              color: "customColor.gray.64748B",
            }}
          >
            |
          </Text>
        }
      >
        <Text
          _light={{
            color: "customColor.gray.64748B",
          }}
          _dark={{
            color: "customColor.dark.250",
          }}
          fontSize="14.4px"
          fontWeight="400px"
          lineHeight={1.5}
        >
          <Text as="span" color="#34C38F" fontSize="14.4px">
            {dashboardAnalytics?.managementPermanentStaffCount}
          </Text>{" "}
          Permanant
        </Text>
        <Text
          _light={{
            color: "customColor.gray.64748B",
          }}
          _dark={{
            color: "customColor.dark.250",
          }}
          fontSize="14.4px"
          fontWeight="400px"
          lineHeight={1.5}
        >
          <Text as="span" color="#34C38F" fontSize="14.4px">
            {dashboardAnalytics?.managementContractStaffCount}
          </Text>{" "}
          Contractual
        </Text>
      </HStack>
    </Box>
    // </Card>
  );
}
