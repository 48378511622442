import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";

type ContainerStyle = {
  width: string;
  height: string;
};

const containerStyle: ContainerStyle = {
  width: "100%",
  height: "400px",
};
interface ScheduleMapProps {
  latitute?: number | string;
  longitude?: number | string;
  setLatitute: (lat: any) => void;
  setLongitude: (lng: any) => void;
}
export default function ScheduleMap({
  latitute,
  longitude,
  setLatitute,
  setLongitude,
}: ScheduleMapProps) {
  const [center, setCenter] = useState({
    lat: Number(latitute) ?? 24.8607,
    lng: Number(longitude) ?? 67.0011,
  });
  useEffect(() => {
    if (latitute && longitude) {
      const newCenter = {
        lat: Number(latitute),
        lng: Number(longitude),
      };
      setCenter(newCenter);
      fetchAddress(newCenter.lat, newCenter.lng);
    }
  }, [latitute, longitude]);

  const onMarkerDragEnd = (e: any) => {
    setCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    setLatitute(e.latLng.lat());
    setLongitude(e.latLng.lng());
    fetchAddress(e.latLng.lat(), e.latLng.lng());
  };
  const onMapClick = (e: any) => {
    setCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    setLatitute(e.latLng.lat());
    setLongitude(e.latLng.lng());
    fetchAddress(e.latLng.lat(), e.latLng.lng());
  };
  const fetchAddress = (lat: number, lng: number) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCZ6p-O1f1OsTcmPNOEitFCQBBDML9fOr4`
    )
      .then((response) => response.json())
      .then((data) => {});
  };
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onClick={onMapClick}
    >
      <Marker position={center} draggable={true} onDragEnd={onMarkerDragEnd} />
    </GoogleMap>
  );
}
