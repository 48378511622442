import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { DateRange } from "../../../Payroll/Pages/Report/Components/DateRange";
import {
  convertminutesToHours,
  ReadeableFormatDate,
  ReadeableFormatTimeWithoutDate,
} from "../../../Payroll/Pages/TimeSheet/utils";
import { Attendence } from "../../API/Attendance";
import { PaginationControl } from "./Pagination/PaginationControl";

export default function AttendanceReport() {
  const [showDetails, setShowDetails] = useState<string | null>(null);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const toggleDetails = (employeeId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(employeeId)) {
        newSet.delete(employeeId);
      } else {
        newSet.add(employeeId);
      }
      return newSet;
    });
  };

  const expandAll = () => {
    const allIds = attendanceRecords.map((record: any) => record.employee_id);
    setExpandedRows(new Set(allIds));
  };

  const collapseAll = () => {
    setExpandedRows(new Set());
  };
  const form = useForm<Attendence.attendanceReport>({
    defaultValues: {
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().endOf("month").format("YYYY-MM-DD"),
      page: 1,
      limit: 100,
    },
  });
  const [attendanceRecords, setAttendanceRecords] = useState<any>();

  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const toasts = useToast();
  const [limit, setLimit] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  useEffect(() => {
    setIsLoading(true);
    Attendence.generateReport(
      {
        fromDate: form.getValues("fromDate"),
        toDate: form.getValues("toDate"),
        employeeIds:
          (form.getValues("employeeIds")?.length ?? 0) > 0
            ? form.getValues("employeeIds")
            : undefined,
        locationIds:
          (form.getValues("locationIds")?.length ?? 0) > 0
            ? form.getValues("locationIds")
            : undefined,
        page: pageNumber,
        limit: limit,
      },
      (data) => {
        setAttendanceRecords(data?.data);
        setPageCount(Math.ceil(data?.total / limit));
        setTotalCount(data?.total);
        setIsLoading(false);
      },
      (error) => {
        toasts({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated, limit, pageNumber]);
  const { employeesList, locationList, businessesList, currentBusinessId } =
    useBusinessContext();
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }
    return pageButtons;
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  return (
    <FormProvider {...form}>
      <Stack h={"calc(100vh - 70px)"} overflowY="auto" w="100%">
        <Container maxW="container.xl" p={0}>
          <Fragment>
            <Stack
              padding={"20px"}
              borderTop={"1px solid #cfd2d4"}
              borderBottom={"1px solid #dadddf"}
            >
              <Stack px="15px" pt="15px">
                <Text
                  fontSize={"24px"}
                  fontWeight={"700"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  textAlign="center"
                >
                  Attendance Report
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  textAlign="center"
                >
                  {businessesList.find((b) => b.id === currentBusinessId)
                    ?.name ?? ""}
                </Text>
                <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
                  <DateRange startDateKey="fromDate" endDateKey="toDate" />
                  <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                    <HStack gap={0} mb="8px">
                      <Text
                        fontSize="13px"
                        display={"block"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                      >
                        Employee/s (Multiple)
                      </Text>
                    </HStack>
                    <Controller
                      control={form.control}
                      name="employeeIds"
                      render={({ field, fieldState }) => {
                        return (
                          <ReactSelect
                            {...field}
                            withValidation
                            isMulti
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Please Select Employee"
                            placeholder="Select Employee"
                            options={employeesList}
                            value={
                              employeesList?.filter((emp: any) =>
                                field.value?.includes(emp.id)
                              ) ?? []
                            }
                            onChange={(val: any) => {
                              field.onChange(val.map((emp: any) => emp.id));
                            }}
                            getOptionLabel={(option: any) => {
                              return `${option?.firstName} (${option?.lastName})`;
                            }}
                            getOptionValue={(option: any) => option.id}
                            closeMenuOnSelect={false}
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
                    <HStack gap={0} mb="8px">
                      <Text
                        fontSize="13px"
                        display={"block"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                      >
                        Location/s (Multiple)
                      </Text>
                    </HStack>
                    <Controller
                      control={form.control}
                      name="locationIds"
                      render={({ field, fieldState }) => {
                        return (
                          <ReactSelect
                            {...field}
                            withValidation
                            isMulti
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Please Select Employee"
                            placeholder="Select Employee"
                            options={locationList}
                            value={
                              locationList?.filter((loc: any) =>
                                field.value?.includes(loc.id)
                              ) ?? []
                            }
                            onChange={(val: any) => {
                              field.onChange(val.map((loc: any) => loc.id));
                            }}
                            getOptionLabel={(option: any) => {
                              return `${option?.name}`;
                            }}
                            getOptionValue={(option: any) => option.id}
                            closeMenuOnSelect={false}
                          />
                        );
                      }}
                    />
                  </Box>
                </SimpleGrid>
                <Flex
                  w={"100%"}
                  padding={"10px 10px 10px 0px"}
                  justifyContent="space-between"
                >
                  <Box>
                    <PaginationControl
                      limit={limit}
                      setLimit={setLimit}
                      setPageNumber={setPageNumber}
                      totalCount={totalCount}
                    />
                  </Box>
                  <HStack>
                    <Button
                      isLoading={isLoading}
                      fontSize={"13px"}
                      bgColor="#3454D1"
                      h={"40px"}
                      borderRadius={"3px"}
                      borderWidth={"1px"}
                      borderColor={"#3454d1!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#3454D1",
                      }}
                      onClick={() => {
                        setLastUpdated(Date.now());
                        setPageNumber(1);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      isLoading={isLoading}
                      fontSize={"13px"}
                      bgColor="#3454D1"
                      h={"40px"}
                      borderRadius={"3px"}
                      borderWidth={"1px"}
                      borderColor={"#3454d1!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#3454D1",
                      }}
                      onClick={expandAll}
                    >
                      Expand All
                    </Button>
                    <Button
                      isLoading={isLoading}
                      fontSize={"13px"}
                      bgColor="#DCDCDC" // Use a different color for distinction
                      h={"40px"}
                      borderRadius={"3px"}
                      borderWidth={"1px"}
                      borderColor={"#A9A9A9!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#A9A9A9",
                      }}
                      onClick={collapseAll}
                    >
                      Collapse All
                    </Button>
                  </HStack>
                </Flex>
                <Stack
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  borderRadius={"4px"}
                  border="1px solid #f6f6f6"
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                >
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th w="40px" pr="0px"></Th>
                        <Th
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Unique ID
                        </Th>
                        <Th
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Full Name
                        </Th>
                        <Th
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          Department
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {attendanceRecords?.map(
                        (employee: any, index: number) => (
                          <>
                            <Tr key={index}>
                              <Td pr="0px">
                                <IconButton
                                  aria-label="Toggle Details"
                                  borderRadius="50%"
                                  icon={
                                    expandedRows.has(employee.employee_id) ? (
                                      <MdKeyboardArrowDown size="30" />
                                    ) : (
                                      <MdKeyboardArrowRight size="30" />
                                    )
                                  }
                                  onClick={() =>
                                    toggleDetails(employee.employee_id)
                                  }
                                />
                              </Td>
                              <Td
                                fontWeight={500}
                                fontSize="13.5px"
                                color={"customColor.black7"}
                                lineHeight={1.5}
                              >
                                {employee?.unique_id}
                              </Td>
                              <Td
                                fontWeight={500}
                                fontSize="13.5px"
                                color={"customColor.black7"}
                                lineHeight={1.5}
                              >
                                {employee?.first_name} {employee?.last_name}
                              </Td>
                              <Td
                                fontWeight={500}
                                fontSize="13.5px"
                                color={"customColor.black7"}
                                lineHeight={1.5}
                              >
                                {employee?.last_name}
                              </Td>
                            </Tr>
                            {expandedRows.has(employee.employee_id) && (
                              <Tr>
                                <Td colSpan={8}>
                                  {Object.entries(
                                    employee?.attendanceReport
                                  ).map(([date, shifts]: any) => (
                                    <Box key={date}>
                                      <Flex
                                        h="33.5px"
                                        w="100%"
                                        bg="primary.100"
                                        alignItems="center"
                                      >
                                        <Text
                                          fontSize="13px"
                                          fontWeight="bold"
                                          w="100%"
                                          ml="8px"
                                          color="gray.800"
                                        >
                                          {ReadeableFormatDate(date)}
                                        </Text>
                                      </Flex>
                                      {shifts?.length > 0 ? (
                                        <Table>
                                          <Thead>
                                            <Tr>
                                              <Th
                                                w={"15%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"left"}
                                              >
                                                Scheduled Date
                                              </Th>
                                              <Th
                                                w={"15%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"left"}
                                              >
                                                Scheduled Time
                                              </Th>
                                              <Th
                                                w={"15%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"left"}
                                              >
                                                Recorded Date
                                              </Th>
                                              <Th
                                                w={"15%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"left"}
                                              >
                                                Recorded Time
                                              </Th>
                                              <Th
                                                w={"10%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"left"}
                                              >
                                                Status
                                              </Th>
                                              <Th
                                                w={"18%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"center"}
                                              >
                                                Start Time Gap
                                              </Th>
                                              <Th
                                                w={"18%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"center"}
                                              >
                                                End Time Gap
                                              </Th>
                                              <Th
                                                w={"14%"}
                                                borderBottomColor="borders.tableBorder"
                                                color={"customColor.black7"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                fontWeight={700}
                                                whiteSpace="nowrap"
                                                fontSize="12px"
                                                textTransform="none"
                                                textAlign={"center"}
                                              >
                                                Total Gap
                                              </Th>
                                            </Tr>
                                          </Thead>
                                          <Tbody>
                                            {shifts.map(
                                              (shift: any, i: number) => {
                                                const isOddRow = i % 2 === 1;
                                                const bgColor = isOddRow
                                                  ? "#E9EFF7"
                                                  : "";
                                                return (
                                                  <Tr
                                                    key={shift.shift_id}
                                                    style={{
                                                      backgroundColor: bgColor,
                                                    }}
                                                    maxH="20px"
                                                  >
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="left"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {ReadeableFormatDate(
                                                        shift.shift_instance_start_date
                                                      )}{" "}
                                                      to{" "}
                                                      {ReadeableFormatDate(
                                                        shift.shift_instance_end_date
                                                      )}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="left"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.shift_instance_start_time &&
                                                      shift.shift_instance_end_time
                                                        ? ReadeableFormatTimeWithoutDate(
                                                            shift.shift_instance_start_date,
                                                            shift.shift_instance_start_time
                                                          )
                                                        : "-"}{" "}
                                                      to{" "}
                                                      {shift.shift_instance_start_time &&
                                                      shift.shift_instance_end_time
                                                        ? ReadeableFormatTimeWithoutDate(
                                                            shift.shift_instance_end_date,
                                                            shift.shift_instance_end_time
                                                          )
                                                        : "-"}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="left"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.shift_record_start_date
                                                        ? ReadeableFormatDate(
                                                            shift.shift_record_start_date
                                                          )
                                                        : "-"}{" "}
                                                      to{" "}
                                                      {shift.shift_record_end_date
                                                        ? ReadeableFormatDate(
                                                            shift.shift_record_end_date
                                                          )
                                                        : "-"}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="left"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.shift_record_start_time &&
                                                      shift.shift_record_start_date
                                                        ? ReadeableFormatTimeWithoutDate(
                                                            shift.shift_record_start_date,
                                                            shift.shift_record_start_time
                                                          )
                                                        : "-"}{" "}
                                                      to{" "}
                                                      {shift.shift_record_end_time &&
                                                      shift.shift_record_end_date
                                                        ? ReadeableFormatTimeWithoutDate(
                                                            shift.shift_record_end_date,
                                                            shift.shift_record_end_time
                                                          )
                                                        : "-"}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="left"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.status}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="center"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.start_time_gap_in_minutes
                                                        ? convertminutesToHours(
                                                            shift.start_time_gap_in_minutes
                                                          )
                                                        : "-"}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="center"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.end_time_gap_in_minutes
                                                        ? convertminutesToHours(
                                                            shift.end_time_gap_in_minutes
                                                          )
                                                        : "-"}
                                                    </Td>
                                                    <Td
                                                      fontWeight={500}
                                                      fontSize="12px"
                                                      color={
                                                        "customColor.black7"
                                                      }
                                                      lineHeight={1.5}
                                                      textAlign="center"
                                                      py="2px"
                                                      maxH="20px"
                                                    >
                                                      {shift.start_time_gap_in_minutes &&
                                                      shift.end_time_gap_in_minutes
                                                        ? convertminutesToHours(
                                                            shift.start_time_gap_in_minutes +
                                                              shift.end_time_gap_in_minutes
                                                          )
                                                        : "-"}
                                                    </Td>
                                                  </Tr>
                                                );
                                              }
                                            )}
                                          </Tbody>
                                        </Table>
                                      ) : (
                                        <Flex
                                          h="33.5px"
                                          w="100%"
                                          bg="#FFFFFF"
                                          alignItems="center"
                                        >
                                          <Text
                                            fontSize="13px"
                                            fontWeight="bold"
                                            w="100%"
                                            ml="8px"
                                            color="gray.800"
                                            textAlign={"center"}
                                          >
                                            No Record Found
                                          </Text>
                                        </Flex>
                                      )}
                                    </Box>
                                  ))}
                                </Td>
                              </Tr>
                            )}
                          </>
                        )
                      )}
                    </Tbody>
                  </Table>
                  <Stack borderTop={"1px solid #dcdee4 "}>
                    <HStack
                      marginTop={"9.35px"}
                      padding={"25px"}
                      fontSize={"13px"}
                      _light={{
                        color: "customColor.black2",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      justifyContent={"space-between"}
                    >
                      <Text
                        fontWeight={"normal"}
                        color={"customColor.6b7885"}
                        _dark={{
                          color: "customColor.dark.200",
                        }}
                        fontSize={"11px"}
                      >
                        Showing {limit * (pageNumber - 1) + 1} to{" "}
                        {limit + limit * (pageNumber - 1)} of {totalCount}{" "}
                        entries
                      </Text>
                      <HStack
                        spacing="13px"
                        justify={{ xs: "center", md: "end" }}
                      >
                        <IconButton
                          aria-label="back"
                          border={"1px solid #e8eaf2"}
                          borderRadius={"full"}
                          backgroundColor="transparent"
                          _hover={{
                            backgroundColor: "#3454D1",
                            color: "white",
                          }}
                          onMouseEnter={() => setIsHovered2(true)}
                          onMouseLeave={() => setIsHovered2(false)}
                          icon={
                            <FaArrowLeft
                              size={15}
                              color={
                                isHovered2
                                  ? "#FFFFFF"
                                  : pageNumber <= 1 || pageNumber > pageCount
                                  ? "#828ea0"
                                  : "#283c50"
                              }
                            />
                          }
                          isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                          onClick={() => {
                            if (pageNumber > 1 && pageNumber <= pageCount) {
                              pageNumberInputRef.current?.value &&
                                (pageNumberInputRef.current.value = String(
                                  pageNumber - 1
                                ));
                              setPageNumber(pageNumber - 1);
                            }
                          }}
                        />
                        <HStack spacing="13px">{renderPageNumbers()}</HStack>
                        <IconButton
                          aria-label="right"
                          backgroundColor="transparent"
                          border={"1px solid #e8eaf2"}
                          borderRadius={"full"}
                          _hover={{
                            backgroundColor: "#3454D1",
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          icon={
                            <FaArrowRight
                              size={15}
                              color={
                                isHovered
                                  ? "#FFFFFF"
                                  : pageNumber >= pageCount || pageNumber < 1
                                  ? "#828ea0"
                                  : "#283c50"
                              }
                              style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                            />
                          }
                          isDisabled={pageNumber >= pageCount || pageNumber < 1}
                          onClick={() => {
                            if (pageNumber < pageCount && pageNumber >= 1) {
                              pageNumberInputRef.current?.value &&
                                (pageNumberInputRef.current.value = String(
                                  pageNumber + 1
                                ));
                              setPageNumber(pageNumber + 1);
                            }
                          }}
                        />
                      </HStack>
                    </HStack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Fragment>
        </Container>
      </Stack>
    </FormProvider>
  );
}
