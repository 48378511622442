import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

export function CalendarHeader({ children, ...props }: TextProps) {
  return (
    <Text
      color="textcolors.primary"
      fontSize="20px"
      fontWeight={500}
      lineHeight={1.2}
      {...props}
    >
      {children}
    </Text>
  );
}
export default CalendarHeader;
