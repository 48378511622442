import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeeNotes {
  type AddNewNote = {
    note: string;
  };
  type Note = {
    id: string;
    createdAt: string;
    note: string;
    notesTakenBy: string;
  };
}
module EmployeeNotes {
  const API_URL = "/employee-notes";
  export function NewNoteAdded(
    empId: string | undefined,
    data: AddNewNote,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/${empId}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function GetNoteDetails(
    empId: string | undefined,
    success: (data: Array<Note>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function Delete(
    id: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
}
export { EmployeeNotes };
