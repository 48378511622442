import { useColorMode } from "@chakra-ui/react";
import Select, { StylesConfig } from "react-select";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import { CustomInput } from "./CustomInput";
interface ReactSelectForPayRollSettingProps
  extends React.ComponentProps<StateManagedSelect> {
  ErrorMessage?: string;
  label?: string;
  withValidation?: boolean;
  isInvalid?: boolean;
}

type SelectTypes = StylesConfig | undefined;
const customSytles = (styles?: SelectTypes) =>
  ({
    ...styles,
    container: (provided, state) => ({
      ...provided,
      display: "block",
      width: "100%",
      minHeight: "48px",
      zIndex: 0,

      ...styles?.container?.(provided, state),
    }),
    control: (provided, state) => ({
      ...provided,
      w: "100%",
      zIndex: 0,
      height: "30px",
      // minWidth: '100%',
      display: "block",
      borderWidth: "1px",
      borderRadius: "5px",
      fontSize: "13px",
      // border: " 0.0833333rem solid rgb(110, 116, 129)",
      border: "1px solid #E2E8F0",
      // borderColor: "rgb(110, 116, 129)"
      //   ? "var(--chakra-colors-primary-500)"
      //   : "var(--chakra-colors-gray-300)",
      // "&:hover": { borderColor: "gray.300" },
      boxShadow: "0 0 0 0 transparent !important",
      ...styles?.control?.(provided, state),
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 1000,
      fontSize: "11px",
      color: state.isSelected
        ? "customColor.white"
        : state.isFocused
        ? "black" // Change color to black on hover
        : "customColor.defaultBlack",
      // backgroundColor: state.isSelected ? "#32465A" : "transparent",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  } as SelectTypes);
export function ReactSelectForPayRollSetting({
  ErrorMessage,
  label,
  withValidation,
  isInvalid,
  ...SelectProps
}: ReactSelectForPayRollSettingProps) {
  const { colorMode } = useColorMode();
  return (
    <CustomInput
      label={label}
      withValidation={withValidation}
      validationMessage={ErrorMessage}
      input={{
        isInvalid,
        style: { display: "block" },
      }}
    >
      <Select
        styles={customSytles({
          control: (provided) => ({
            ...provided,

            borderColor: isInvalid ? "red" : provided.borderColor,
          }),
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: colorMode === "light" ? "white" : "#30303a",
            color: colorMode === "light" ? "customColor.defaultBlack" : "white",
          }),
        })}
        {...SelectProps}
        menuPortalTarget={document.body}
        menuPlacement="auto"
      />
    </CustomInput>
  );
}
