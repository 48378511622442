import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { TbMinusVertical } from "react-icons/tb";
interface PayTempProps {
  selectedPayTempFilters: {
    earning: boolean;
    deduction: boolean;
    statutoryBenefits: boolean;
    reimbursement: boolean;
  };
  setSelectedPayTempFilters: React.Dispatch<
    React.SetStateAction<{
      earning: boolean;
      deduction: boolean;
      statutoryBenefits: boolean;
      reimbursement: boolean;
    }>
  >;
  events: string;
}
export function PayTempFilter({
  setSelectedPayTempFilters,
  selectedPayTempFilters,
  events,
}: PayTempProps) {
  const form = useFormContext();
  const toggleFilter = (filterName: keyof typeof selectedPayTempFilters) => {
    setSelectedPayTempFilters((prev) => {
      const isCurrentlyChecked = prev[filterName];
      const updatedFilters = {
        ...prev,
        [filterName]: isCurrentlyChecked ? undefined : true,
      };

      if (!updatedFilters[filterName]) {
        form.setValue(filterName, undefined);
      }

      return updatedFilters;
    });
  };
  const selectedCount = Object.values(selectedPayTempFilters).filter(
    Boolean
  ).length;
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              <Flex>
                <TbMinusVertical fontSize={"22px"} color={"hsl(0,0%,80%)"} />
                <ChevronDownIcon
                  fontSize={"22px"}
                  color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                  _hover={{
                    color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                  }}
                />
              </Flex>
            } //5A5A5A //383c40
            width={"100%"}
            textAlign={"start"}
            h={"38px"}
            _light={{
              bgColor: "customColor.white",
              color: "customColor.black7",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            fontWeight={"400"}
            border={"1px solid #e5e7eb"}
            borderRadius={"4px"}
            _hover={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid hsl(0, 0%, 70%)",
            }}
            _active={{
              color: "black",
              bg: "#f2f3f4",
              borderWidth: "1px",

              boxShadow: "0 0 0 1px #2684FF",
              _hover: {
                borderWidth: "1px",
                borderColor: "#2684FF",
                boxShadow: "0 0 0 1px #2684FF",
              },
            }}
          >
            {selectedCount > 0 ? `${selectedCount} Selected` : "Filters"}
          </MenuButton>

          <MenuList minWidth="200px" maxH={"300px"} overflowX={"auto"}>
            {[
              {
                name: "Earnings",
                onChange: () => toggleFilter("earning"),
                isChecked: !!selectedPayTempFilters.earning,
              },
              {
                name: "Deductions",
                onChange: () => toggleFilter("deduction"),
                isChecked: !!selectedPayTempFilters.deduction,
              },
              {
                name: "Reimbursement",
                onChange: () => toggleFilter("reimbursement"),
                isChecked: !!selectedPayTempFilters.reimbursement,
              },
              {
                name: "Benefits",
                onChange: () => toggleFilter("statutoryBenefits"),
                isChecked: !!selectedPayTempFilters.statutoryBenefits,
              },
            ].map((item, i) => {
              return (
                <MenuItem key={i}>
                  <Checkbox onChange={item.onChange} isChecked={item.isChecked}>
                    <Text
                      fontSize={".845rem"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      fontWeight={"600"}
                    >
                      {item.name}
                    </Text>
                  </Checkbox>
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
}
