import { Checkbox, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";

import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { MappingModuleApi } from "../../../Api/MappingModule";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
interface LeaveProps {
  selectedLeaveFilters: {
    payrollPayItemLeaveId: boolean;
    calculationMethod: boolean;
    quantity: boolean;
    balance: boolean;
    terminationUnusedBalance: boolean;
    etpLeaveType: boolean;
    includeRetirementContribution: boolean;
    accruedQuantity: boolean;
    openingBalance: boolean;
  };

  fetchLeaves: PayItemLeaveApi.LeaveById[];

  LeaveEntitlementForm: UseFormReturn<
    MappingModuleApi.LeaveEntitlementFilters,
    any,
    undefined
  >;
}
export function LeaveComponent({
  selectedLeaveFilters,
  fetchLeaves,
  LeaveEntitlementForm,
}: LeaveProps) {
  const leaveCalculationOptions = [
    {
      value: "FIXED_AMOUNT_EACH_PERIOD",
      label: "Fixed Amount Each Period",
    },
    { value: "MANUALLY_RECOREDED_RATE", label: "Manually Recorded Rate" },
    { value: "NO_CALCULATION_REQUIRED", label: "No Calculation Required" },
  ];
  const unusedBalanceOptions = [
    { value: "NOT_PAID_OUT", label: "Not Paid Out" },
    { value: "PAID_OUT", label: "Paid Out" },
  ];
  const watchSelectedLeaveType = useWatch({
    control: LeaveEntitlementForm.control,
    name: "payrollPayItemLeaveId",
  });
  const watchLeaveCalculation = useWatch({
    control: LeaveEntitlementForm.control,
    name: "calculationMethod",
  });

  return (
    <SimpleGrid columns={3} spacing={3}>
      {selectedLeaveFilters.payrollPayItemLeaveId && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Leave
          </Text>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Select Leave Type is required",
              },
            }}
            control={LeaveEntitlementForm.control}
            name="payrollPayItemLeaveId"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                options={fetchLeaves?.map((leave) => ({
                  value: leave.id,
                  label: leave.name,
                }))}
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage="Please Select Leave Type"
                placeholder="Select..."
                value={
                  fetchLeaves?.find((leave) => leave.id === field.value)
                    ? {
                        value: field.value,
                        label: fetchLeaves?.find(
                          (leave) => leave.id === field.value
                        )?.name,
                      }
                    : null
                }
                onChange={(selectedOption: any) =>
                  field.onChange(selectedOption.value)
                }
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedLeaveFilters.calculationMethod && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Leave Calculation Method
          </Text>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Select Leave Calculation Method is required",
              },
            }}
            control={LeaveEntitlementForm.control}
            name="calculationMethod"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                options={leaveCalculationOptions}
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage="Select Leave Calculation Method "
                placeholder="Select..."
                value={leaveCalculationOptions.find(
                  (op) => op.value === field.value
                )}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value);
                  LeaveEntitlementForm.reset({
                    ...LeaveEntitlementForm.getValues(),
                    quantity: 0,
                    accruedQuantity: 0,

                    balance: 0,
                    openingBalance: 0,
                  });
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedLeaveFilters.quantity && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Quantity
          </Text>
          <Controller
            control={LeaveEntitlementForm.control}
            name="quantity"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  bg: "#fff",
                  borderTopRadius: "3px",
                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}
      {selectedLeaveFilters.balance && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Balance
          </Text>
          <Controller
            control={LeaveEntitlementForm.control}
            name="balance"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}
      {selectedLeaveFilters.terminationUnusedBalance && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            On termination unused balance is
          </Text>
          <Controller
            rules={{
              required: {
                value: true,
                message: " UnUsed Balance  is required",
              },
            }}
            control={LeaveEntitlementForm.control}
            name="terminationUnusedBalance"
            render={({ field, fieldState }) => (
              <ReactSelect
                {...field}
                key={field.value}
                options={unusedBalanceOptions}
                withValidation
                isInvalid={fieldState.invalid}
                ErrorMessage="Select unused balance"
                placeholder="Select..."
                value={unusedBalanceOptions.find(
                  (op) => op.value === field.value
                )}
                onChange={(newValue: any) => {
                  field.onChange(newValue?.value);
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            )}
          />
        </Stack>
      )}
      {selectedLeaveFilters.etpLeaveType && (
        <Stack gap={0}>
          <Controller
            control={LeaveEntitlementForm.control}
            name="etpLeaveType"
            render={({ field }) => (
              <Checkbox
                isChecked={field.value}
                isDisabled={
                  fetchLeaves?.find((op) => op.id === watchSelectedLeaveType)
                    ?.category === "PAID_LEAVE"
                    ? true
                    : false
                }
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
              >
                <Text
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  ETP Leave Type
                </Text>
              </Checkbox>
            )}
          />
        </Stack>
      )}
      {selectedLeaveFilters.includeRetirementContribution && (
        <Controller
          control={LeaveEntitlementForm.control}
          name="includeRetirementContribution"
          render={({ field }) => (
            <Checkbox
              isChecked={field.value}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
            >
              <Text
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Includes Superannuation Guarantee Contribution
              </Text>
            </Checkbox>
          )}
        />
      )}
      {selectedLeaveFilters.accruedQuantity && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Accrued Quantity
          </Text>
          <Controller
            control={LeaveEntitlementForm.control}
            name="accruedQuantity"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  bg: "#fff",
                  borderTopRadius: "3px",
                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}{" "}
      {selectedLeaveFilters.openingBalance && (
        <Stack gap={0}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Opening balance this year
          </Text>
          <Controller
            control={LeaveEntitlementForm.control}
            name="openingBalance"
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  bg: "#fff",
                  borderTopRadius: "3px",
                  onChange: (e) => {
                    field.onChange(Number(e.target.value));
                  },
                }}
                placeholder=""
              />
            )}
          />
        </Stack>
      )}{" "}
    </SimpleGrid>
  );
}
