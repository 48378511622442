import {
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Image,
  ListIcon,
  ListItem,
  Stack,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";

export default function Menu() {
  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");

  return (
    <Stack mx="38px">
      <HStack
        w=""
        className="navbar navbar-light bg-light"
        bg={"transparent"}
        height={"60px"}
        marginTop={"30px"}
        boxSizing="content-box"
      >
        <Flex flex="1">
          <Image
            borderRadius="full"
            src="https://bit.ly/dan-abramov"
            alt="Dan Abramov"
            height={"47.5px"}
            width={"48px"}
          />
        </Flex>

        <Button
          leftIcon={<AiOutlineClose color="rgb(0, 137, 220)" />}
          variant="link"
          textColor={"black"}
          fontSize={"18px"}
          fontWeight={"light"}
          _hover={{
            textDecoration: "2px underline",
            textDecorationColor: "rgb(0, 137, 220) ",
            boxShadow: "none",
            outlineColor: "#213B55",
          }}
        >
          Close
        </Button>
      </HStack>
      <Stack marginTop={isSmallerThan1000 ? "2rem" : "5rem"}></Stack>
      <UnorderedList display={"contents"} styleType={"none"} spacing={3}>
        {[
          "Features",
          "Pricing",
          "For small business",
          "For accountants and bookkeepers",
          "Support",
        ].map((item, i) => {
          return (
            <ListItem key={i}>
              <Button
                variant={"link"}
                fontSize={isSmallerThan1000 ? "28px" : "51.6px"}
                color={"#213B55"}
                fontWeight={"Bold"}
                textDecoration={"5px underline "}
                textDecorationColor={"rgb(0, 137, 220) "}
                lineHeight={"1.3"}
                _hover={{
                  textDecoration: "5px underline",
                  textDecorationColor: "rgb(0, 137, 220) ",
                  color: "#213B55",
                  textUnderlineOffset: "0.5rem",
                }}
              >
                {item}
              </Button>
              <ListIcon
                as={AiOutlineRight}
                width="2.px15rem"
                height="2.15rem"
                size={"2.15rem"}
                color={"rgb(0, 137, 220)"}
                fontWeight={"bold"}
              />
            </ListItem>
          );
        })}
      </UnorderedList>
      <ButtonGroup marginTop={isSmallerThan1000 ? "1rem" : "2rem"}>
        <Button
          flex={1}
          backgroundColor={"rgb(80,220,170)"}
          borderRadius={"5px"}
          fontSize={"15px"}
          variant={"solid"}
          textColor={"black"}
          border={"0.166667rem solid rgb(80, 220, 170)"}
          minHeight={"47.2px"}
          _hover={{
            backgroundColor: "rgb(74, 196, 157)",
            border: "0.2rem solid rgb(74, 196, 157)",
          }}
        >
          Try HRIS 360 for free
        </Button>
        <Button
          flex={1}
          variant={"outline"}
          borderRadius={"5px"}
          fontSize={"15px"}
          textColor={"black"}
          border={"0.166667rem solid rgb(142, 148, 159)"}
          minHeight={"47.2px"}
          _hover={{
            backgroundColor: "#213B55",
            color: "white",
            border: "none",
          }}
        >
          Log in
        </Button>
      </ButtonGroup>
    </Stack>
  );
}
