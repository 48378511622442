import {
  Avatar,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
  Wrap,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AccessControl } from "../../../../../../../api/AccessControl";
import { DeleteCurrentUser } from "./DeleteCurrentUser";

interface CurrentUsersProps {
  allActiveUsers: Array<AccessControl.UserDetail>;
  setLastUpdated: (timestamp: number) => void;
  isLoading: boolean;
}
export default function CurrentUsers({
  allActiveUsers,
  setLastUpdated,
  isLoading,
}: CurrentUsersProps) {
  const navigate = useNavigate();
  const toast = useToast();
  const DeleteModal = useDisclosure();
  const [selectedId, setSelectedId] = useState<string>("");
  const features: Array<{ label: string; value: string }> = [
    {
      label: "Employee CheckIn",
      value: "employeeCheckIn",
    },
    {
      label: "Employee Details",
      value: "employeeDetails",
    },
    {
      label: "Tax Rates",
      value: "taxRates",
    },
    {
      label: "Chart Of Accounts",
      value: "chartOfAccounts",
    },
    {
      label: "Timesheets",
      value: "timesheets",
    },
    {
      label: "Pay Employee",
      value: "payRuns",
    },
    {
      label: "Payroll setting",
      value: "payrollSettings",
    },
    {
      label: "Personal Details",
      value: "personalDetails",
    },
    {
      label: "Financial Details",
      value: "financialDetails",
    },
    {
      label: "Schedule",
      value: "schedules",
    },
    {
      label: "Location",
      value: "locations",
    },
    {
      label: "Notification",
      value: "notifications",
    },
  ];
  const AccessLevel = [
    {
      name: "Admin",
      value: "ADMIN",
    },
    {
      name: "Manager",
      value: "MANAGER",
    },
    {
      name: "Supervisor",
      value: "SUPERVISOR",
    },
    {
      name: "Client",
      value: "CLIENT",
    },
  ];
  return (
    <Fragment>
      <Stack
        padding={"16px"}
        gap={0}
        direction={"row"}
        borderBottom={"1px solid #ccced2"}
      >
        <Heading
          fontSize={"0.9375rem"}
          _light={{
            color: "customColor.black7",
          }}
          margin={"0px 12px 0px 0px"}
          alignSelf={"center"}
        >
          {`Current users ${allActiveUsers.length}`}
        </Heading>
      </Stack>
      <Fragment>
        {allActiveUsers?.map((user, i) => {
          return (
            <Skeleton height={"100%"} isLoaded={!isLoading}>
              <Stack
                direction={"row"}
                gap={0}
                justifyContent={"space-between"}
                _hover={{
                  _light: {
                    bg: "#f2f3f4",
                  },
                  _dark: {
                    bg: "customColor.gray.800",
                  },
                }}
                _active={{ bg: "#e6e7e9" }}
                onClick={() => {
                  navigate(`/app/payroll/updatePermission/${user.id}`, {
                    state: { status: "ACTIVE" },
                  });
                }}
              >
                <Stack direction={"row"} gap={0} flex={1}>
                  <Avatar
                    name={`${user.user.firstName} ${user.user.lastName}`}
                    margin={"0px 16px"}
                    width={"40px"}
                    height={"40px"}
                    fontSize={"0.9375rem"}
                    bg={"#FDD580"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontWeight={"700"}
                    alignSelf={"center"}
                  />
                  <Box padding={"12px 16px 12px 16px"} width="100%">
                    <HStack justifyContent="space-between">
                      <Stack
                        gap={0}
                        direction={{
                          xs: "column",

                          lg: "row",
                        }}
                      >
                        <Text
                          fontSize={"0.9375rem"}
                          fontWeight={"bold"}
                          margin={"0px 12px 0px 0px"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          {`${user.user.firstName} ${user.user.lastName}`}
                        </Text>

                        <Text
                          fontSize={"0.9375rem"}
                          fontWeight={"normal"}
                          margin={"0px 12px 0px 0px"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          {user.user.email}
                        </Text>
                      </Stack>
                      <Box
                        display="flex"
                        justifyContent="center"
                        minWidth="100px"
                        alignItems="center"
                      >
                        <Tag
                          color="#582E00"
                          bg="#FDD3A6"
                          fontSize="13px"
                          padding="0px 4px"
                          borderRadius="3px"
                          size="md"
                        >
                          {
                            AccessLevel.find(
                              (item) => item.value === user.accessLevel
                            )?.name
                          }
                        </Tag>
                      </Box>
                    </HStack>
                    <Wrap spacing="5px">
                      {Object.entries(user.allowedFeatures)
                        .map(([category, allowedFeatures]) =>
                          Object.entries(allowedFeatures)
                            .filter(([feature, value]) => value === true)
                            .map(([feature, value]) => {
                              const labelObject = features.find(
                                (item) => item.value === feature
                              );
                              if (labelObject) {
                                const { label, value } = labelObject;
                                return (
                                  <Text
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.dark.200",
                                    }}
                                    fontSize={"13px"}
                                    // whiteSpace="nowrap"
                                  >
                                    {label}
                                  </Text>
                                );
                              }
                              return null;
                            })
                        )
                        .flat()
                        .filter((tag) => tag !== null)
                        .map((tag, index, array) => (
                          <Fragment key={index}>
                            {tag}
                            {index < array.length - 1 && ", "}
                          </Fragment>
                        ))}
                    </Wrap>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  align={"center"}
                  margin={"0px 8px"}
                  padding={0}
                >
                  <Menu>
                    <MenuButton
                      as={Button}
                      padding={"6px 10px"}
                      backgroundColor={"transparent"}
                      h={"40px"}
                      w={"40px"}
                      marginLeft={"8px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      borderRadius={"full"}
                      _hover={{
                        backgroundColor: "rgba(0,10,30,.05)",
                        color: "#000a1e",
                      }}
                      _active={{
                        backgroundColor: "rgba(0,10,30,.1)",
                        color: "#000a1e",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Icon as={FiMoreVertical} />
                    </MenuButton>
                    <MenuList padding={"12px 0px"} minW={"177px"}>
                      <MenuItem
                        key={i}
                        fontSize={"0.9375rem"}
                        padding={"8px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        _hover={{
                          backgroundColor: "rgba(0,10,30,.1)",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/app/payroll/updatePermission/${user.id}`, {
                            state: { status: "ACTIVE" },
                          });
                        }}
                      >
                        Update Permission
                      </MenuItem>
                      <MenuItem
                        key={i}
                        fontSize={"0.9375rem"}
                        padding={"8px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        _hover={{
                          backgroundColor: "rgba(0,10,30,.1)",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedId(user.id);
                          DeleteModal.onOpen();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>
            </Skeleton>
          );
        })}
        <DeleteCurrentUser
          isOpen={DeleteModal.isOpen}
          onClose={DeleteModal.onClose}
          id={selectedId}
          setLastUpdated={setLastUpdated}
        />
      </Fragment>
    </Fragment>
  );
}
