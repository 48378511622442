import {
  Button,
  Center,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { StatutoryBenefitsApi } from "../../Api/StautoryBenefitsApi";

interface ApproveProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
  selectedIds: string | undefined;
}
export default function ApproveSuperannuation({
  isOpen,
  onClose,
  setLastUpdated,
  selectedIds,
}: ApproveProps) {
  const { businessesList, currentBusinessId } = useBusinessContext();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const FormForApprove = useForm<StatutoryBenefitsApi.Approve>({
    defaultValues: {
      id: "",
      code: "",
    },
  });
  useEffect(() => {
    if (FormForApprove) {
      FormForApprove.setValue("id", selectedIds);
    }
  }, [selectedIds]);
  const navigate = useNavigate();
  return (
    <FormProvider {...FormForApprove}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
            padding={"16px 12px"}
          >
            Approve payment {selectedIds}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            borderTop={"1px solid #ddd"}
            borderBottom={"1px solid #ddd"}
            padding={"16px 12px"}
          >
            <Text
              fontSize={"0.8125rem"}
              fontWeight={"400"}
              _light={{
                color: "customColor.black7",
              }}
            >
              {" "}
              An authorization code has been sent to the person who makes
              payment approve for this account
            </Text>
            <Center>
              <Stack w={"250px"} paddingTop={"12px"} paddingBottom={"20px"}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Code is required",
                    },
                  }}
                  control={FormForApprove.control}
                  name="code"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,

                        isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                      }}
                      placeholder=""
                      label="Enter authorization code"
                      labelProps={{
                        fontSize: ".845rem",
                        fontWeight: "600",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                    />
                  )}
                />
                <Link fontSize={"13px"} color={"#3454d1"}>
                  Haven't received it? Resend a new code
                </Link>
              </Stack>
            </Center>
            <Text
              paddingTop={"10px"}
              borderTop={"1px solid #ddd"}
              fontSize={"13px"}
              color={"customColor.black7"}
            >
              Reminder: This transaction will appear on your bank statement as
              <b>
                {" "}
                {
                  businessesList.find(
                    (business) => business.id === currentBusinessId
                  )?.name
                }
              </b>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="link"
              fontSize={"13px"}
              margin={"0px 10px 0px 0px"}
              padding={"10px 15px"}
              minH={"32px"}
              onClick={onClose}
              color={"#EA4D4D"}
              minW={"75px"}
              isLoading={isLoading}
            >
              Reject
            </Button>
            <Button
              fontSize={"13px"}
              h={"32px"}
              bgColor="#3454D1"
              marginRight={"10px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={(e) => {
                FormForApprove.handleSubmit(
                  (data) => {
                    StatutoryBenefitsApi.RequestSentForApproval(
                      data.id!,
                      data.code,
                      (success) => {
                        toast({
                          title: "Approved successfully",
                          status: "success",
                        });
                        setLastUpdated(Date.now());
                        navigate("/app/payroll/Superannuation");
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Approved Failed ",
                          description: error,
                          status: "error",
                        });
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Failed To Approve",
                      status: "error",
                    });
                  }
                )(e);
              }}
              // onClick={() => {
              //   console.log(FormForApprove.getValues());
              // }}
            >
              Approve & Submit
            </Button>

            <Button
              onClick={onClose}
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"32px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
