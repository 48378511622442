import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

export function ContentHeading({ children, ...props }: TextProps) {
  return (
    <Text fontSize="11px" color="icons.primary" {...props}>
      {children}
    </Text>
  );
}
export default ContentHeading;
