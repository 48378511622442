import { axiosInstance } from "./axiosInstance-hris";

declare module EmployeeExperience {
  type AddExperience = {
    id?: string;
    startDate: string;
    endDate: string;
    companyName: string;
    position: string;
    jobDescription: string;
    leavingReason: string;
    country: string;
    employerEmail?: string;
    employeeContactNumber?: string;
  };
  type FetchExperience = {
    id: string;
    startDate: string;
    endDate: string;
    companyName: string;
    position: string;
    jobDescription: string;
    leavingReason: string;
    country: string;
  };
}
module EmployeeExperience {
  const URL = "/employee-experiences";

  export function PostExperience(
    id: string,
    data: AddExperience,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function GetExperienceDetails(
    id: string,
    success: (response: Array<FetchExperience>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${URL}/${id}`)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function UpdateExperience(
    id: string,
    data: Partial<AddExperience>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${URL}/${id}`, data)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }

  export function DeleteExperience(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${URL}/${id}`)
      .then((response) => {
        success(response.data.message);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
}
export { EmployeeExperience };
