import axiosInstanceGateway from "./axiosInstance-gateway";
declare module SetupGuideApi {
  type SetupGuideData = {
    completed: boolean;
    completedItems: {
      users: boolean;
      employees: boolean;
      orgDetails: boolean;
      chartOfAccounts: boolean;
      payrollSettings: boolean;
    };
    businessId?: string;
    id?: string;
  };
}
module SetupGuideApi {
  const API_URL = "setup-guide";
  export function SetupGuide(
    success: (data: SetupGuideData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  // export function DefaultSetupGuide(
  //   success: (data: SetupGuideData) => void,
  //   error: (message: string) => void
  // ) {
  //   axiosInstanceGateway
  //     .get(`${API_URL}/create-default`)
  //     .then(({ data }) => {
  //       success(data);
  //     })
  //     .catch((e) => {
  //       error(e?.response?.data?.message ?? e?.message);
  //     });
  // }
  export function UpdateSetupGuide(
    data: Partial<SetupGuideData>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      });
  }
}
export { SetupGuideApi };
