import axiosInstanceGateway from "./axiosInstance-gateway";

declare module Authentication {
  type Signup = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    country: string;
  };

  type SignIn = {
    email: string;
    password: string;
  };

  type LoginResponse = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    isVerified: boolean;
    phone: string;
    country: string;
    token: string;
  };
  type ResetPassword = {
    password: string;

    token: string;
  };
  type ForgetPassword = {
    email: string;
  };
  type ChangePassword = {
    email: string;
  };

  type AddAccount = {
    name: string;
    accountCode: string;
    type: string;
    currency: string;
    userId: string;
  };

  type SetTerminationDate = {
    reasonOfTermination: string;
    terminationDate: string;
  };
}
module Authentication {
  const API_URL = "/auth";
  export function RegisterUser(
    data: Signup,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}/register`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }

  export function Forget(
    data: ForgetPassword,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/forgotPassword/${data.email}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function PasswordChange(
    data: ChangePassword,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/changePassword/${data.email}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }

  export function Reset(
    data: ResetPassword,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}/resetPassword`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }

  export function LoginUser(
    data: SignIn,
    success: (user: LoginResponse) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}/login`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
    // .catch((error) => {
    //   error(error?.response?.data?.message ?? error.message);
    // });
  }
  export function VerifyEmail(
    confirmationToken: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/confirmUser/${confirmationToken}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
}
export { Authentication };
