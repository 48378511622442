import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSelect } from "../../../Common/ReactSelect";
import { Employees } from "../../Api/Employees";
import { PayItemEarningApi } from "../../Api/PayItemEarning";
import { TimeSheetNewDetail } from "../../Api/TimeSheetDetail";
import { AddTimeSheet } from "./Modals/AddTimesheet";
export function TimeSheet() {
  const employeeOptions = [{ value: "all", label: "all" }];
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState("");
  const [employeesList, setEmployeesList] = useState<
    Employees.timeSheetEmployees[]
  >([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const toast = useToast();
  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  useEffect(() => {
    Employees.FetchTimeSheetEmployees(
      (data) => {
        setEmployeesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);

  const form = useForm<TimeSheetNewDetail.AddNewDetail>({
    defaultValues: {
      employeeId: "",
      period: "",
      status: "",
      totalHours: 0,

      payRatesData: [
        {
          payItemsEarningsId: "",
          dayHours: {},
        },
      ],
    },
  });
  const addTimeSheet = useDisclosure();

  const AddShift = useDisclosure();
  const navigate = useNavigate();
  const [allTimesheets, setAllTimesheets] = useState<
    TimeSheetNewDetail.FetchTimeSheetData[]
  >([]);
  const { id } = useParams();
  const [lastUpdated, setLastUpdated] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(allTimesheets);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };
  const statusFilter: Array<{ value: string; label: string }> = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Approved",
      value: "APPROVED",
    },
    {
      label: "Awaiting Approval",
      value: "AWAITING_APPROVAL",
    },
    {
      label: "Pending Approval",
      value: "PENDING_APPROVAL",
    },
    {
      label: "Processed",
      value: "PROCESSED",
    },
    {
      label: "Overdue",
      value: "OVERDUE",
    },
    {
      label: "Draft",
      value: "DRAFT",
    },
  ];
  const [selectedTimesheet, setSelectedTimesheet] = useState<string>(
    statusFilter[0].value
  );
  const [selectedFilterStartDate, setSelectedFilterStartDate] =
    useState<string>();
  const [selectedFilterEndDate, setSelectedFilterEndDate] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    TimeSheetNewDetail.FetchTimeSheets(
      {
        status: selectedTimesheet,
        startDate: selectedFilterStartDate,
        endDate: selectedFilterEndDate,
        // employeeId: "b3c580e8-8ec9-4f4a-9458-858ca3dc587c",
        employeeId: id,
      },
      (data) => {
        setAllTimesheets(data?.data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  const [earningAdded, setEarningAdded] = useState<Array<any>>([]);
  useEffect(() => {
    PayItemEarningApi.FetchEarning(
      (data) => {
        setEarningAdded(data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        padding={"24px"}
      >
        <Heading
          paddingTop={"24px"}
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"18px"}
          margin={"0px 4px 0px 0px"}
        >
          Timesheets
        </Heading>

        <Stack
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          paddingTop={"20px"}
          borderBottom={"1px solid #dadddf"}
        >
          <Stack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            borderRadius={"4px"}
            paddingTop={"10px"}
            paddingLeft={"10px"}
            margin={"0px 1px 20px"}
            border="1px solid #f6f6f6"
            padding={"7.52px 12px"}
            boxShadow={
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
            }
            gap={0}
          >
            <Stack margin={"5px 20px 0px 0px"} direction={"row"}>
              <Stack flex={3} gap={0}>
                <Stack maxW={"157px"}>
                  <Text
                    fontSize={"12px"}
                    margin={"0px 0px 5px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"bold"}
                  >
                    Timesheet Filter
                  </Text>
                  <ReactSelect
                    placeholder=""
                    options={statusFilter}
                    value={statusFilter.find(
                      (option) => option.value === selectedTimesheet
                    )}
                    onChange={(newValue: any) => {
                      setSelectedTimesheet(newValue?.value);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack alignItems={"flex-end"} padding={"10px"}>
              <Stack direction={"row"}>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    setSelectedTimesheet("");
                    setSelectedFilterStartDate("");
                    setSelectedFilterEndDate("");
                    setSelectedEmployee("");
                  }}
                >
                  Reset Filter
                </Button>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    setLastUpdated(Date.now());
                  }}
                >
                  Apply Filter
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack paddingTop={"20px"} paddingBottom={"20px"} gap={0}>
          <Stack align={"flex-start"}>
            <Tooltip
              hasArrow
              placement="top"
              padding={"10px"}
              label="It will be an upcoming feature(will only for daily wagers)"
              bg="gray.300"
              color="black"
            >
              <Button
                margin={"0px 10px 15px 0px"}
                fontSize={"13px"}
                isDisabled
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={addTimeSheet.onOpen}
              >
                + Add Timesheet
              </Button>
            </Tooltip>
            <AddTimeSheet
              currentEmployeeId={id}
              isOpen={addTimeSheet.isOpen}
              onClose={addTimeSheet.onClose}
            />
          </Stack>
          <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
            <Stack
              fontSize={"16px"}
              _dark={{
                bgColor: "customColor.dark.50",
                color: "customColor.dark.250",
              }}
              _light={{
                bgColor: "customColor.white",
                color: "customColor.black7",
              }}
              gap={0}
              border="1px solid #f6f6f6"
              borderRadius="4px"
              boxShadow={
                "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
              }
            >
              <Stack
                direction={"row"}
                margin={"5px 0px 0px 5px"}
                fontSize={"12px"}
                color={"#888"}
                align={"center"}
                padding={"5px 20px 5px 20px"}
                gap={0}
              >
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "#3454d1",
                  }}
                >
                  Approve
                </Button>
                <Text>No timesheets selected</Text>
              </Stack>
              <Box w="100%" overflowX="auto">
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      color={"customColor.black7"}
                      _dark={{ color: "customColor.dark.150" }}
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{ color: "customColor.dark.150" }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                        />
                      </Th>
                      {[
                        {
                          name: "First Name",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Surname",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Period",
                          value: "25%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Status",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Last Edited",
                          value: "25%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Hours",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "View Details",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                      ].map((item, i) => (
                        <Th
                          key={i}
                          padding={item.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={item.value}
                        >
                          {item.name}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paginatedData.map((item: any, i: any) => (
                      <Tr
                        key={i}
                        _hover={{ bgColor: "#f2f3f8", cursor: "pointer" }}
                        onClick={() => {
                          if (item.status === "DRAFT") {
                            navigate("/app/payroll/TimesheetsEnterDetail", {
                              state: {
                                timesheetId: item.id,
                                employeeId: item.employeeId,
                                period: item.period,
                                status: item.status,
                                dateRange: item.dateRange,
                              },
                            });
                          } else {
                            navigate(
                              `/app/payroll/TimesheetsDetail/${item.id}`
                            );
                          }
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            size="md"
                            borderColor="#adb5bd"
                            bgColor="white"
                          />
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tooltip label={item?.employee?.firstName}>
                            <Text>
                              {truncateText(item?.employee?.firstName, 10)}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tooltip label={item?.employee?.lastName}>
                            <Text>
                              {truncateText(item?.employee?.lastName, 10)}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tooltip label={item?.period}>
                            <Text>{truncateText(item?.period, 10)}</Text>
                          </Tooltip>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tag
                            padding="5px 6px"
                            fontWeight={500}
                            fontSize="13.44px"
                            lineHeight={1.5}
                            borderRadius={"3px"}
                            color={
                              item?.status === "APPROVED"
                                ? "#3454D1"
                                : item?.status === "DRAFT"
                                ? "#EA4D4D"
                                : "#000000"
                            }
                            backgroundColor={
                              item?.status === "APPROVED"
                                ? "#EBEEFA"
                                : item?.status === "DRAFT"
                                ? "#FDEDED"
                                : "#F0F0F0"
                            }
                            _dark={{
                              color:
                                item?.status === "APPROVED"
                                  ? "#3454D1"
                                  : item?.status === "DRAFT"
                                  ? "customColor.dark.150"
                                  : "#FFFFFF",
                              backgroundColor:
                                item?.status === "APPROVED"
                                  ? "#EBEEFA"
                                  : item?.status === "DRAFT"
                                  ? "customColor.dark.bg"
                                  : "#333333",
                            }}
                          >
                            {item?.status === "APPROVED"
                              ? "Approved"
                              : item?.status === "DRAFT"
                              ? "Draft"
                              : "Others"}
                          </Tag>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tooltip label={item?.updatedAt}>
                            <Text>{truncateText(item?.updatedAt, 10)}</Text>
                          </Tooltip>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tooltip label={item?.totalHours}>
                            <Text>{truncateText(item?.totalHours, 10)}</Text>
                          </Tooltip>
                        </Td>
                        <Td
                          padding="10px 21px 10px 10px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{ color: "customColor.dark.150" }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <HStack
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Icon
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="50%"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              bg={"#fff"}
                              w="25px"
                              h="25px"
                              px="5px"
                              py="5px"
                              as={MdOutlineRemoveRedEye}
                              fontSize="22px"
                              color="#6B7280"
                              _hover={{ color: "#3454d1" }}
                              onClick={() => {
                                if (item.status === "DRAFT") {
                                  navigate(
                                    "/app/payroll/TimesheetsEnterDetail",
                                    {
                                      state: {
                                        timesheetId: item.id,
                                        employeeId: item.employeeId,
                                        period: item.period,
                                        status: item.status,
                                        dateRange: item.dateRange,
                                      },
                                    }
                                  );
                                } else {
                                  navigate(
                                    `/app/payroll/TimesheetsDetail/${item.id}`
                                  );
                                }
                              }}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Stack>
          </Skeleton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
