import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { Insurance } from "../../Api/Insurance";

interface InsuranceModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
  // insuranceType?: Array<{ label: string; value: string }>;
}
export default function InsuranceModal({
  isOpen,
  onClose,
  setLastUpdated,
  empId,
}: InsuranceModalProps) {
  const CoverageOptions: Array<{ label: string; value: string }> = [
    {
      label: "Single",
      value: "SINGLE",
    },

    {
      label: "Spouse,Children",
      value: "MARRIED_WITH_CHILDREN",
    },
    {
      label: "Parent",
      value: "WITH_PARENTS",
    },
  ];
  const form = useFormContext<Insurance.AddInsurance>();
  const toast = useToast();
  const watchRadioValue = useWatch({
    control: form.control,
    name: "type",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Insurance
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex justifyContent="space-between">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Insurance Type
              </Text>
              <Box w="380px">
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Insurance Type is required",
                    },
                  }}
                  name="type"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup {...field}>
                      <HStack>
                        <Radio
                          isInvalid={fieldState.invalid}
                          size="md"
                          value="HEALTH"
                          colorScheme="blue"
                          fontWeight={"700"}
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            fontSize={"0.9375rem"}
                            fontWeight={"500"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Health
                          </Text>
                        </Radio>

                        <Radio
                          isInvalid={fieldState.invalid}
                          size="md"
                          defaultChecked
                          value="GROUP_LIFE"
                          colorScheme="blue"
                          fontWeight={"700"}
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            fontSize={"0.9375rem"}
                            fontWeight={"500"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Group Life
                          </Text>
                        </Radio>
                        <Radio
                          isInvalid={fieldState.invalid}
                          size="md"
                          defaultChecked
                          value="OTHER"
                          colorScheme="blue"
                          fontWeight={"700"}
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            fontSize={"0.9375rem"}
                            fontWeight={"500"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Others
                          </Text>
                        </Radio>
                        <Radio
                          isInvalid={fieldState.invalid}
                          size="md"
                          value="OPD"
                          colorScheme="blue"
                          fontWeight={"700"}
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            fontSize={"0.9375rem"}
                            fontWeight={"500"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            OPD
                          </Text>
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                />
              </Box>
            </Flex>
            {/* {(watchRadioValue === "HEALTH" ||
              watchRadioValue === "GROUP_LIFE" ||
              watchRadioValue === "OTHER") && ( */}
            <Flex justifyContent="space-between" mt="8px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Name
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage="This field is required"
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        type: "text",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            {/* )} */}

            {(watchRadioValue === "HEALTH" ||
              watchRadioValue === "GROUP_LIFE") && (
              <Flex justifyContent="space-between" mt="16px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Plan
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="plan"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {watchRadioValue === "OTHER" && (
              <Flex justifyContent="space-between" mt="16px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Insurance Type
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="insuranceType"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {watchRadioValue === "GROUP_LIFE" && (
              <Flex justifyContent="space-between" mt="8px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Natural death coverage amount
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="naturalDeathAmount"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },

                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e) => {
                            const value = e.target.value.replace(/,/g, "");
                            const parsedValue = Number(value);
                            field.onChange(parsedValue);
                            form.setValue(
                              "accidentalDeathAmount",
                              parsedValue * 2
                            );
                          },

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {watchRadioValue === "GROUP_LIFE" && (
              <Flex justifyContent="space-between" mt="8px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Accidental death coverage amount
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="accidentalDeathAmount"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          isDisabled: true,
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },

                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e) => {
                            const value = e.target.value.replace(/,/g, "");
                            const parsedValue = Number(value);
                            field.onChange(parsedValue);
                          },

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}

            {watchRadioValue !== "GROUP_LIFE" && (
              <Flex justifyContent="space-between" mt="8px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  In-patient coverage amount per year
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="amount"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },

                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e) => {
                            const value = e.target.value.replace(/,/g, "");
                            const parsedValue = Number(value);
                            field.onChange(parsedValue);
                          },

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}

            {watchRadioValue === "HEALTH" && (
              <Flex justifyContent="space-between" mt="16px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Per Day Room Charges
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="roomCharges"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },

                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e) => {
                            const value = e.target.value.replace(/,/g, "");
                            const parsedValue = Number(value);
                            field.onChange(parsedValue);
                          },

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {watchRadioValue === "HEALTH" && (
              <Flex justifyContent="space-between" mt="8px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  OPD Coverage Amount
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="opdAmount"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },

                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e) => {
                            const value = e.target.value.replace(/,/g, "");
                            const parsedValue = Number(value);
                            field.onChange(parsedValue);
                          },

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {watchRadioValue === "OTHER" && (
              <Flex justifyContent="space-between" mt="16px">
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Other Information
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="otherInformation"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        validationMessage="This field is required"
                        input={{
                          ...field,
                          w: "100%",
                          isInvalid: fieldState.invalid,
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {/* {(watchRadioValue === "HEALTH" ||
              watchRadioValue === "GROUP_LIFE") && ( */}
            <Flex justifyContent="space-between" mt="16px">
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Coverage
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="coverage"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Coverage"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Coverage"
                      withValidation
                      options={CoverageOptions}
                      value={CoverageOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Box>
            </Flex>
            {/* )} */}
            {/* <Flex justifyContent="space-between" mt="16px">
              <Text w="115px" fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}>
                Policy Documents
              </Text>
              <Box w="380px">
                <Controller
                  name=""
                  control={form.control}
                  render={({ field }) => (
                    <Input
                      border={"1px solid #ddd"}
                      maxWidth="500px"
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "customColor.white",
                      }}
                      h="139px"
                      type="file"
                      borderRadius={"1px"}
                    />
                  )}
                />
              </Box>
            </Flex> */}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (form.getValues("id")) {
                  Insurance.UpdateInsurance(
                    form.getValues("id")!,
                    data,
                    () => {
                      setLastUpdated(Date.now());
                      onClose();
                      toast({
                        title: "Insurance Updated",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    },
                    (error) => {
                      toast({
                        title: error,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    }
                  );
                } else {
                  Insurance.PostInsurance(
                    data,
                    (data) => {
                      setLastUpdated(Date.now());
                      onClose();
                      toast({
                        title: "Insurance Added",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    },
                    (err) => {
                      toast({
                        title: err,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {isLoading ? "Saving" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
