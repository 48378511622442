import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";

import React from "react";
import { LeftSideComponent } from "./NewBrandingModalComponents/LeftSideComponent";
import { RightSideComponent } from "./NewBrandingModalComponents/RightSideComponent";

interface NewBrandingModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
}
export default function NewBrandingModal({
  isOpen,
  onClose,
}: NewBrandingModalProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxW={"880px"} overflowX={"auto"} h={"600px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          padding={"12px 15px"}
          fontSize="18px"
        >
          New Branding Theme
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          bg={"customColor.gray.400"}
          padding={"15px"}
          borderTop={"1px solid #e5e7eb"}
          borderBottom={"1px solid #e5e7eb"}
          gap={0}
        >
          <Stack direction={"row"} gap={0}>
            <LeftSideComponent />
            <RightSideComponent />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            marginRight={"8px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
          >
            {isLoading ? "Saving" : "Save"}
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
