import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { PayrollCalendar } from "../../Api/PayRollCalendar";

import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import { AddCalendar } from "./ModalForCalendar/AddCalendar";
import { Delete } from "./ModalForCalendar/Delete";
interface CalendartabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Calendartab({
  setSelectedTab,
  selectedTab,
}: CalendartabProps) {
  const [payOptions, setPayOptions] = useState<PayrollCalendar.Calendar[]>([]);
  const [selectedCalendarId, setSelectedCalendarId] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  useEffect(() => {
    PayrollCalendar.FetchCalendar(
      (data) => {
        setPayOptions(data);

        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);

  const form = useForm<PayrollCalendar.Calendar>({
    defaultValues: {
      id: "",
      businessId: "",
      payPeriod: "",
      name: "",
      startDate: "",
      nextPaymentDate: "",
      isActive: false,
    },
  });
  const addNewCalendar = useDisclosure();
  const deleteModal = useDisclosure();
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const sortData = (field: string) => {
    const sortedData = [...payOptions].sort((a, b) => {
      const aValue = a[field as keyof PayrollCalendar.Calendar];
      const bValue = b[field as keyof PayrollCalendar.Calendar];
      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setPayOptions(sortedData);
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  return (
    <FormProvider {...form}>
      <Delete
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        setLastUpdated={setLastUpdated}
        selectedCalendarId={selectedCalendarId}
      />

      <Box
        justifyContent="center"
        alignItems="center"
        marginTop={"15px"}
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <HStack justifyContent="space-between" w="100%">
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            padding="20px"
            _light={{
              // color: "customColor.calendar.100",
              color: "customColor.black7",
            }}
            as="h2"
            size="md"
          >
            Pay Calender
          </Heading>
          <Tooltip
            label="A Pay Calendar is a schedule indicating the dates on which employees receive their wages or salaries from their employer."
            fontSize="sm"
            padding={"10px"}
            bg={"#fff"}
            border={"1px solid #ddd"}
            _light={{
              color: "customColor.black7",
            }}
            maxW={"300px"}
            placement="auto"
          >
            <Button
              right="15px"
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                form.reset({
                  id: "",
                  businessId: "",
                  payPeriod: "",
                  name: "",
                  startDate: "",
                  nextPaymentDate: "",
                  isActive: false,
                });
                setSelectedCalendarId(undefined);
                addNewCalendar.onOpen();
              }}
            >
              Add
            </Button>
          </Tooltip>
        </HStack>
        <Divider orientation="horizontal" />
        <AddCalendar
          isOpen={addNewCalendar.isOpen}
          onClose={addNewCalendar.onClose}
          setLastUpdated={setLastUpdated}
          selectedCalendarId={selectedCalendarId}
        />
        {/* Add Button Modal */}
        <Skeleton height={"100%"} isLoaded={!isLoading}>
          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Name",
                      value: "20%",
                      field: "name",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Pay Period",
                      value: "20%",
                      field: "payPeriod",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Next Pay Period",
                      value: "20%",
                      field: "",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Next Payment Date",
                      value: "10%",
                      field: "nextPaymentDate",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Status",
                      value: "10%",
                      field: "isActive",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "View Details",
                      value: "10%",
                      field: "",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "",
                      value: "10%",
                      field: "",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                        cursor="pointer"
                        onClick={() => item.field && sortData(item.field)} // Call sort function on click
                      >
                        {item.name}
                        {sortField === item.field && (
                          <Icon
                            as={
                              sortOrder === "asc"
                                ? AiOutlineSortAscending
                                : AiOutlineSortDescending
                            }
                            ml="5px"
                          />
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody padding={0}>
                {payOptions &&
                  payOptions.map((item: any, i: any) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.isActive ? 1 : 0.6,
                            backgroundColor: item?.isActive
                              ? "none"
                              : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.isActive ? "line-through" : "none"
                            }
                          >
                            {item.name}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.isActive ? 1 : 0.6,
                            backgroundColor: item?.isActive
                              ? "none"
                              : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.isActive ? "line-through" : "none"
                            }
                          >
                            {item.payPeriod === "FORTNIGHTLY"
                              ? "Fortnightly"
                              : item.payPeriod === "MONTHLY"
                              ? "Monthly"
                              : item.payPeriod === "QUARTERLY"
                              ? "Quarterly"
                              : item.payPeriod === "TWICE_MONTHLY"
                              ? "Twice Monthly"
                              : item.payPeriod === "WEEKLY"
                              ? "Weekly"
                              : item.payPeriod === "FOUR_WEEKLY"
                              ? "Four Weekly"
                              : "Other"}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.isActive ? 1 : 0.6,
                            backgroundColor: item?.isActive
                              ? "none"
                              : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.isActive ? "line-through" : "none"
                            }
                          >
                            {item.payPeriod === "MONTHLY" && (
                              <Text>
                                {moment(item.startDate).format("D MMM YYYY")}-{" "}
                                {moment(item.startDate)
                                  .add(1, "months")
                                  .subtract(1, "days")
                                  .format("D MMM YYYY")}
                              </Text>
                            )}
                            {item.payPeriod === "FORTNIGHTLY" && (
                              <Text>
                                {moment(item.startDate).format("D MMM YYYY")}-{" "}
                                {moment(item.startDate)
                                  .add(13, "days")
                                  .format("D MMM YYYY")}
                              </Text>
                            )}
                            {item.payPeriod === "TWICE_MONTHLY" && (
                              <Text>
                                {moment(item.startDate).format("D MMM YYYY")}-{" "}
                                {moment(item.startDate)
                                  .add(14, "days")
                                  .format("D MMM YYYY")}
                              </Text>
                            )}
                            {item.payPeriod === "QUARTERLY" && (
                              <Text>
                                {moment(item.startDate).format("D MMM YYYY")}-{" "}
                                {moment(item.startDate)
                                  .add(1, "quarter")
                                  .subtract(1, "days")
                                  .format("D MMM YYYY")}
                              </Text>
                            )}
                            {item.payPeriod === "WEEKLY" && (
                              <Text>
                                {moment(item.startDate).format("D MMM YYYY")}-{" "}
                                {moment(item.startDate)
                                  .add(6, "days")
                                  .format("D MMM YYYY")}
                              </Text>
                            )}
                            {item.payPeriod === "FOUR_WEEKLY" && (
                              <Text>
                                {moment(item.startDate).format("D MMM YYYY")}-{" "}
                                {moment(item.startDate)
                                  .add(27, "days")
                                  .format("D MMM YYYY")}
                              </Text>
                            )}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.isActive ? 1 : 0.6,
                            backgroundColor: item?.isActive
                              ? "none"
                              : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.isActive ? "line-through" : "none"
                            }
                          >
                            {moment(item.nextPaymentDate).format("D MMM YYYY")}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tag
                            padding="5px 6px"
                            fontWeight={600}
                            fontSize="13.44px"
                            lineHeight={1.5}
                            borderRadius={"3px"}
                            color={item?.isActive ? "#3454D1" : "#EA4D4D"}
                            backgroundColor={
                              item?.isActive ? "#EBEEFA" : "#FDEDED"
                            }
                            _dark={{
                              color: item?.isActive
                                ? "#3454D1"
                                : "customColor.dark.150",
                              backgroundColor: item?.isActive
                                ? "#EBEEFA"
                                : "customColor.dark.bg",
                            }}
                          >
                            {item?.isActive ? "Active" : "Deactive"}
                          </Tag>
                          {/* {item.isActive ? "Active" : "Inactive"} */}
                        </Td>
                        <Td
                          padding="10px"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.isActive ? 1 : 0.6,
                            backgroundColor: item?.isActive
                              ? "none"
                              : "gray.200",
                          }}
                        >
                          <HStack alignItems="center" justifyContent="center">
                            <Icon
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="50%"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              bg={"#fff"}
                              w="25px"
                              h="25px"
                              px="5px"
                              py="5px"
                              as={MdOutlineRemoveRedEye}
                              fontSize="22px"
                              color="#6B7280"
                              _hover={{
                                color: "#3454d1",
                              }}
                              onClick={() => {
                                if (item.isActive) {
                                  setSelectedCalendarId(item?.id);
                                  form.reset({
                                    payPeriod: item.payPeriod,
                                    name: item.name,
                                    startDate: item.startDate,
                                    nextPaymentDate: item.nextPaymentDate,
                                  });
                                  addNewCalendar.onOpen();
                                }
                              }}
                            />
                            {/* <Button
                              w="25px"
                              h="25px"
                              as={IconButton}
                              icon={<MdOutlineRemoveRedEye />}
                              color="#6B7280"
                              _hover={{
                                color: "#3454d1",
                                bg: "#fff",
                              }}
                              bg={"#fff"}
                              fontSize="12.6px"
                              fontWeight={400}
                              lineHeight="1.5"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              textAlign="center"
                              borderRadius="50%"
                              px="5px"
                              py="5px"
                              onClick={() => {
                                if (item.isActive) {
                                  setSelectedCalendarId(item?.id);
                                  form.reset({
                                    payPeriod: item.payPeriod,
                                    name: item.name,
                                    startDate: item.startDate,
                                    nextPaymentDate: item.nextPaymentDate,
                                  });
                                  addNewCalendar.onOpen();
                                }
                              }}
                              disabled={!item.isActive}
                            /> */}
                          </HStack>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Menu>
                            <MenuButton
                              as={Button}
                              padding={0}
                              backgroundColor={"transparent"}
                              h={"34.5px"}
                              marginLeft={"8px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              display={{
                                lg: "flex",
                                md: "flex",
                                sm: "flex",
                                xs: "flex",
                              }}
                              borderRadius={"full"}
                              _hover={{
                                backgroundColor: "primary.50",
                              }}
                              _active={{
                                backgroundColor: "primary.100",
                              }}
                            >
                              <Icon as={FiMoreVertical} />
                            </MenuButton>
                            <MenuList style={{ minWidth: "140px" }}>
                              {[
                                {
                                  name: "Edit",
                                  onClick: () => {
                                    setSelectedCalendarId(item?.id);
                                    form.reset({
                                      payPeriod: item.payPeriod,
                                      name: item.name,
                                      startDate: item.startDate,
                                      nextPaymentDate: item.nextPaymentDate,
                                    });
                                    addNewCalendar.onOpen();
                                  },
                                },
                                {
                                  name: item.isActive
                                    ? "Mark as Inactive"
                                    : "Mark as Active",
                                  onClick: () => {
                                    setIsLoading(true);
                                    PayrollCalendar.IsActive(
                                      item.id,
                                      !item.isActive, // Toggle the isActive state
                                      (res) => {
                                        setLastUpdated(Date.now());
                                        toast({
                                          title: item.isActive
                                            ? "Calendar is inactive"
                                            : "Calendar is active",
                                          status: "success",
                                        });
                                        setIsLoading(false);
                                      },
                                      (error) => {
                                        toast({
                                          title: item.isActive
                                            ? "Failed to inactive calendar"
                                            : "Failed to activate calendar",
                                          status: "success",
                                        });
                                        setIsLoading(false);
                                      }
                                    );
                                  },
                                },
                                {
                                  name: "Delete",
                                  onClick: () => {
                                    setSelectedCalendarId(item.id);
                                    deleteModal.onOpen();
                                  },
                                },
                              ].map((menuItem, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    padding={"1px 0px 1px 20px"}
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.white",
                                    }}
                                    onClick={menuItem.onClick}
                                  >
                                    <Button
                                      variant={"ghost"}
                                      fontWeight={"normal"}
                                      fontSize={"13px"}
                                      padding={"0px 20px 0px 0px"}
                                      _light={{
                                        color: "customColor.black7",
                                      }}
                                      _dark={{
                                        color: "customColor.white",
                                      }}
                                      _hover={{ bg: "none" }}
                                    >
                                      {menuItem.name}
                                    </Button>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            {/* <Stack
              direction="row"
              alignItems="center"
              gap={0}
              paddingLeft={"12px"}
              paddingRight={"5px"}
            >
              <Stack
                direction="row"
                alignItems="center"
                marginTop="10px"
                flex={1}
                gap={0}
              >
                <Stack direction={"row"} gap={0}>
                  <Text
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    padding={"5px 0px"}
                    fontSize={"12px"}
                  >{`Page`}</Text>
                  <Select
                    h={"25px"}
                    w={"50px"}
                    paddingLeft={"3px"}
                    paddingRight={"3px"}
                    style={{
                      borderRadius: "3px",
                      fontWeight: "normal",
                      padding: "0px 0px 0px 3px",
                    }}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                    fontSize={"12px"}
                    placeholder=""
                    value={selectedPage}
                    onChange={(event: any) => {
                      const newPage = parseInt(event.target.value, 10);
                      setSelectedPage(newPage);
                      setCurrentPage(newPage);
                    }}
                  >
                    {getPageNumbers(
                      Math.ceil((payOptions?.length ?? 0) / itemsPerPage)
                    ).map((pageNumber) => (
                      <option
                        key={pageNumber}
                        value={pageNumber}
                        style={{
                          width: "70px",
                          height: "42px",
                          padding: "13px 10px 12px",
                          fontSize: "12px",
                          color: "#048ABB",
                        }}
                      >
                        {pageNumber}
                      </option>
                    ))}
                  </Select>
                  <Text
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    padding={"5px 0px"}
                    fontSize={"12px"}
                  >{` of ${Math.ceil(
                    payOptions?.length ?? 0 / itemsPerPage
                  )}`}</Text>
                  <Text
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    padding={"5px 0px"}
                    fontSize={"12px"}
                    marginLeft={"3px"}
                  >
                    ({payOptions?.length ?? 0} total items)
                  </Text>
                </Stack>

                <Text
                  marginLeft="10px"
                  color={"customColor.gray.800"}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  padding={"5px 0px"}
                  fontSize={"12px"}
                  marginRight={"5px"}
                >
                  Showing
                </Text>
                
                <Select
                  h={"25px"}
                  w={"50px"}
                  style={{
                    borderRadius: "3px",
                    fontWeight: "normal",
                    padding: "0px 0px 0px 3px",
                  }}
                  borderWidth={"1px"}
                  borderStyle={"solid"}
                  borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                  fontSize={"12px"}
                  placeholder=""
                  value={itemsPerPage}
                  onChange={(event: any) => {
                    const newValue = parseInt(event.target.value, 10);
                    setItemsPerPage(newValue);
                    setCurrentPage(1);
                  }}
                >
                  {[25, 50, 100, 200, 500, 1000].map((value) => (
                    <option
                      key={value}
                      value={value}
                      style={{
                        width: "70px",
                        height: "42px",
                        padding: "13px 10px 12px",
                        fontSize: "12px",
                        color: "#048ABB",
                      }}
                    >
                      {String(value)}
                    </option>
                  ))}
                </Select>

                <Text
                  color={"customColor.gray.800"}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  padding={"5px 0px"}
                  fontSize={"12px"}
                  marginLeft={"5px"}
                >
                  Items per page:
                </Text>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                paddingY="10px"
                gap={0}
              >
                {currentPage > 1 && (
                  <>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() => setCurrentPage(1)}
                    >
                      <Icon as={MdKeyboardDoubleArrowLeft} />
                      Start
                    </Button>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <Icon as={MdKeyboardArrowLeft} />
                      Prev
                    </Button>
                  </>
                )}
                {getPageNumbers(
                  Math.ceil((payOptions?.length ?? 0) / itemsPerPage)
                ).map((pageNumber) => (
                  <Button
                    key={pageNumber}
                    variant={"link"}
                    color={"#3454d1"}
                    fontSize={"12px"}
                    fontWeight={"normal"}
                    padding={0}
                    margin={"0px 0px 0px 5px"}
                    onClick={() => {
                      setSelectedPage(pageNumber);
                      setCurrentPage(pageNumber);
                    }}
                    isActive={pageNumber === selectedPage}
                    _active={{
                      color: "black",
                    }}
                  >
                    {pageNumber}
                  </Button>
                ))}
                {currentPage <
                  Math.ceil((payOptions?.length ?? 0) / itemsPerPage) && (
                  <>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil((payOptions?.length ?? 0) / itemsPerPage)
                          )
                        )
                      }
                    >
                      Next
                      <Icon as={MdKeyboardArrowRight} />
                    </Button>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() =>
                        setCurrentPage(
                          Math.ceil((payOptions?.length ?? 0) / itemsPerPage)
                        )
                      }
                    >
                      End
                      <Icon as={MdKeyboardDoubleArrowRight} />
                    </Button>
                  </>
                )}
              </Stack>
            </Stack> */}
          </Box>
          <Stack alignItems={"flex-end"} margin={"20px 20px 0px 0px"}>
            <Button
              colorScheme="green"
              border={"1px solid green"}
              textColor={"customColor.white"}
              borderRadius={"3px"}
              fontSize={"13px"}
              h={"40px"}
              marginLeft={"12px"}
              variant={"solid"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        </Skeleton>

        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Box>
    </FormProvider>
  );
}
