import { Checkbox, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { InviteUser } from "../../../../../api/InviteUser";

interface PayrollPermissionsProps {
  isLoading?: boolean;
}
export default function PayrollPermissions({
  isLoading,
}: PayrollPermissionsProps) {
  const form = useFormContext<InviteUser.UserInviteDetail>();
  // const parentCheckbox = useWatch({
  //   control: form.control,
  //   name: "allowedFeatures.payroll",
  // });
  return (
    <Stack paddingLeft={"2.5px"} gap={0} paddingTop={"10px"}>
      <Stack padding={0} alignItems={"center"} direction={"row"} gap={0}>
        {/* <Checkbox
            paddingLeft={"8px"}
            size={"md"}
            value=""
            isChecked={Object.values(parentCheckbox).every((value) => {
              return value;
            })}
            // isIndeterminate={checkedItems.some(Boolean) && !checkAll}
            onChange={(e) => {
              form.setValue("permissions.payroll.payRuns", e.target.checked);
              form.setValue(
                "permissions.payroll.payrollSettings",
                e.target.checked
              );
              form.setValue("permissions.payroll.timesheets", e.target.checked);
            }}
          >
            <Text
              fontSize={"13px"}
              padding={"8px 0px"}
              color={"#000A1EBF"}
              fontWeight={"bold"}
              flex={1}
            >
              Full Access
            </Text>
          </Checkbox> */}
      </Stack>
      <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
        <SimpleGrid columns={3}>
          {/* {watchEmployee && (
              <Stack padding={0} gap={0}>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px"}
                    color={"#000A1EBF"}
                    width={"50%"}
                  >
                    Personal Information
                  </Text>
                  <Checkbox size={"md"} paddingLeft={"8px"} />
                </Stack>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px"}
                    color={"#000A1EBF"}
                    width={"50%"}
                  >
                    Financial Information
                  </Text>
                  <Checkbox size={"md"} paddingLeft={"8px"} />
                </Stack>
              </Stack>
            )} */}

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.payroll.timesheets"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Timesheets
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.payroll.payRuns"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Pay Employee
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>

          <Stack direction={"row"} gap={0}>
            <Controller
              control={form.control}
              name="allowedFeatures.payroll.payrollSettings"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  isChecked={field.value}
                  size={"md"}
                  value={field.value?.toString()}
                >
                  <Text
                    fontSize={"13px"}
                    padding={"8px 0px 8px 8px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    fontWeight={"bold"}
                  >
                    Payroll setting
                  </Text>
                </Checkbox>
              )}
            />
          </Stack>
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
}
