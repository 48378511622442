import {
  Box,
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { BusinessTaxRate } from "../../../Api/BusinessTaxRate";
import { AddNewTax } from "./Modal/AddNewTax";
import { AdvancedTaxRate } from "./Modal/AdvancedTaxRate";
import DeleteBulk from "./Modal/BulkDelete";
import DeleteModal from "./Modal/DeleteModal";

type SortOrder = "asc" | "desc" | "";
export function TaxRates() {
  const advancedTaxRate = useDisclosure();
  const addNewTax = useDisclosure();
  const [taxRate, setTaxRate] = useState<BusinessTaxRate.FetchTaxRate[]>([]);
  const [selectedId, setSelectedId] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const bulkDelete = useDisclosure();
  const deleteModal = useDisclosure();

  const form = useForm({
    defaultValues: {
      id: "",
      name: "",
      tax_type: "",
      tax_components: [
        {
          name: "",
          tax: 0,
        },
      ],
    },
  });
  const toast = useToast();
  const [sortColumn, setSortColumn] = useState<string>(""); // Track the current column
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const handleSort = (column: string) => {
    let order: SortOrder = "asc";

    // Toggle between asc and desc
    if (sortColumn === column && sortOrder === "asc") {
      order = "desc";
    }

    setSortColumn(column);
    setSortOrder(order);

    const sortedData = [...taxRate].sort((a, b) => {
      let aValue = a[column as keyof BusinessTaxRate.FetchTaxRate];
      let bValue = b[column as keyof BusinessTaxRate.FetchTaxRate];

      // Handle case where aValue or bValue might not be a string
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (order === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    setTaxRate(sortedData);
  };

  // Render sorting icons
  const renderSortIcon = (column: string) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <FaSortUp style={{ marginLeft: "8px" }} />
      ) : (
        <FaSortDown style={{ marginLeft: "8px" }} />
      );
    }
    return null;
  };
  useEffect(() => {
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRate(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);

  const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const initialCheckboxes = taxRate.reduce((acc: any, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [taxRate]);

  const handleCheckboxChange = (id: string) => {
    const updatedCheckboxes = {
      ...checkboxes,
      [id]: !checkboxes[id],
    };
    setCheckboxes(updatedCheckboxes);

    // Add or remove ID from selectedIds
    if (updatedCheckboxes[id]) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleCheckAll = () => {
    const updatedCheckboxes = Object.keys(checkboxes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: !checkboxes[key],
      }),
      {}
    );
    setCheckboxes(updatedCheckboxes);
  };
  const length = Object.values(checkboxes).filter((value) => value).length;
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          justifyContent={"center"}
          border={"1px solid #e5e7eb"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          gap={0}
          padding={"0px 0px 0px 20px"}
          minH={"60px"}
        >
          <Link
            color={"#3454d1"}
            fontWeight={"normal "}
            fontSize={"11px"}
            href="advancedSettings"
          >
            Advanced accounting
          </Link>
          <Heading
            fontSize="24px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontWeight="700"
          >
            Tax Rates
          </Heading>
        </Stack>
        <Container maxW={"100%"} padding={"30px 20px"}>
          <AdvancedTaxRate
            isOpen={advancedTaxRate.isOpen}
            onClose={advancedTaxRate.onClose}
          />
          <AddNewTax
            isOpen={addNewTax.isOpen}
            onClose={addNewTax.onClose}
            setLastUpdated={setLastUpdated}
            selectedId={selectedId}
          />
          <Stack
            direction={"row"}
            padding={0}
            gap={0}
            justifyContent={"flex-end"}
            marginBottom={"15px"}
          >
            <Button
              leftIcon={<BsFillPlusSquareFill size={"13px"} />}
              margin={"0px 10px 0px 0px"}
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#2c48b5",
                border: "1px solid #2c48b5",
              }}
              onClick={() => {
                form.reset({
                  name: "",
                  tax_type: "",
                  tax_components: [
                    {
                      name: "",
                      tax: 0,
                    },
                  ],
                });
                setSelectedId(undefined);
                addNewTax.onOpen();
              }}
            >
              New Tax Rate
            </Button>
            <Button
              borderRadius={"3px"}
              border={"1px solid #1c9420"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              background={"#1c9420"}
              textColor={"#fff"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#17801a",
                border: "1px solid #17801a",
              }}
              onClick={() => advancedTaxRate.onOpen()}
            >
              Change to Advanced Tax Rate
            </Button>
          </Stack>
          <Stack
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            borderColor="#e5e7eb"
            borderWidth="1px"
            borderStyle={"solid"}
            gap={0}
          >
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              direction={"row"}
              gap={0}
              alignItems={"center"}
              h={"40px"}
              padding={"0px 10px"}
            >
              <Button
                margin={"0px 10px 0px 0px "}
                variant="solid"
                fontSize={"11px"}
                padding={"6px 9px"}
                h={"30px"}
                borderRadius={"3px"}
                border={"1px solid #FDEDED"}
                bg={"#FDEDED"}
                color={"#EA4D4D"}
                minW={"75px"}
                _hover={{
                  border: "1px solid #EA4D4D",
                  bg: "#EA4D4D",
                  color: "#fff",
                }}
                onClick={() => bulkDelete.onOpen()}
              >
                Delete
              </Button>

              <Text
                fontSize={"11px"}
                _light={{
                  color: "customColor.black7",
                }}
                margin={"6px 0px 0px"}
              >
                {length} item(s) selected
              </Text>
            </Stack>

            <Skeleton height="100%" isLoaded={!isLoading}>
              <Box
                overflowX="auto"
                p="0px"
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
              >
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      borderTopWidth={"1px"}
                      borderTopColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={Object.values(checkboxes).every(
                            (value) => value
                          )}
                          onChange={handleCheckAll}
                        />
                      </Th>

                      {[
                        {
                          name: "Name",
                          value: "35%",
                          paddingValue: "8px 26px 8px 15px",
                          alias: "name",
                        },
                        {
                          name: "Tax Rate",
                          value: "25%",
                          paddingValue: "8px 26px 8px 15px",
                          alias: "itemTotalTax",
                        },
                        {
                          name: "Accounts using this Tax Rate",
                          value: "25%",
                          paddingValue: "8px 26px 8px 15px",
                          alias: "chartOfAccountsCount",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            padding="15px"
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            w={item.value}
                            fontWeight={700}
                            fontSize="12px"
                            textTransform="none"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            onClick={() => handleSort(item.alias)}
                            cursor="pointer"
                          >
                            <HStack>
                              <Text>{item.name}</Text>
                              <Text>{renderSortIcon(item.alias)}</Text>
                            </HStack>
                          </Th>
                        );
                      })}
                      <Th
                        padding="15px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        // w={item.value}
                        fontWeight={700}
                        fontSize="12px"
                        textTransform="none"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        cursor="pointer"
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody padding={0}>
                    {taxRate?.map((item: any, i: any) => {
                      const itemTotalTax = item.tax_components?.reduce(
                        (total: any, component: any) => {
                          return total + component.tax;
                        },
                        0
                      );
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            addNewTax.onOpen();
                            setSelectedId(item?.id);
                            form.reset({
                              name: item.name,
                              tax_type: item.tax_type,
                              tax_components: item.tax_components.map(
                                (component: any) => {
                                  return {
                                    name: component.name,
                                    tax: component.tax,
                                  };
                                }
                              ),
                            });
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            whiteSpace="nowrap"
                            fontWeight={500}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={checkboxes[item.id]}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.name}
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text h={"13.5px"}>{`${itemTotalTax} %`}</Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text h={"13.5px"}>
                              {item.chartOfAccountsCount}
                            </Text>
                          </Td>
                          <Td
                            padding="10px 21px 10px 10px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <HStack>
                              <Icon
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                as={MdOutlineRemoveRedEye}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={() => {
                                  addNewTax.onOpen();
                                  setSelectedId(item?.id);
                                  form.reset({
                                    name: item.name,
                                    tax_type: item.tax_type,
                                    tax_components: item.tax_components.map(
                                      (component: any) => {
                                        return {
                                          name: component.name,
                                          tax: component.tax,
                                        };
                                      }
                                    ),
                                  });
                                }}
                              />
                              <Icon
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                as={MdDelete}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={(e) => {
                                  setSelectedId(item?.id);
                                  e.stopPropagation();
                                  deleteModal.onOpen();
                                }}
                              />
                            </HStack>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Skeleton>
          </Stack>
        </Container>
      </Stack>
      <DeleteModal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        id={selectedId}
        setLastUpdated={setLastUpdated}
      />
      <DeleteBulk
        isOpen={bulkDelete.isOpen}
        onClose={bulkDelete.onClose}
        selectedIds={selectedIds}
        setLastUpdated={setLastUpdated}
        length={length}
      />
    </FormProvider>
  );
}
