import { axiosInstance } from "./axiosInstance-hris";
declare module PayrollOrganisation {
  type Organisation = {
    liabilityAccount: string;
    wagesExpenseAccount: string;
    wagesPayableAccount: string;
    retirementLiabilityAccount: string;
    retirementExpenseAccount: string;
    employeeGroup: string;
    timesheetCategory: string;
    showAnnualSalary: boolean;
    showEmployementBasis: boolean;
    companyLogo: string;
    address?: string;
    province?: string;
    country?: string;
    city?: string;
    postalCode?: string;
    email: string;
    mobileNumber: string;
    taxNumber?: string;
  };
}
module PayrollOrganisation {
  const API_URL = "/payroll-organisation";
  export function CreateOrganisation(
    data: Organisation,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function UpdateOrganisation(
    data: Partial<Organisation>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchOrganisation(
    success: (data: Organisation) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { PayrollOrganisation };
