import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import { EmployeesReport } from "../../../Api/EmployeeReport";
import { ColumnsComponent } from "../Components/Columns";
import { EarningRate } from "../Components/EarningRate";
import { EmployeeMenu } from "../Components/EmployeeMenu";
import { FilterComponent } from "../Components/FilterComponent";
import { Grouping } from "../Components/Grouping";
import { TimesheetCategory } from "../Components/TimesheetCategory";
import { ReportingTable } from "../Tables/ReportingTable";
import { Column } from "./Columns";
interface GenericObject {
  [key: string]: any;
}
export function TimesheetSummaryReport() {
  const [selectedGroupBy, setSelectedGroupBy] =
    useState<EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]>();

  const [clonedSelectedGroupBy, setClonedSelectedGroupBy] =
    useState<EmployeesReport.GroupingTimesheetSummaryReport["groupBy"]>(); //
  const groupingBtn: Array<{
    groupingItem: Array<{
      label: string;
      key?: EmployeesReport.GroupingTimesheetSummaryReport["groupBy"];
    }>;
  }> = [
    {
      groupingItem: [
        { label: "None", key: "NONE" },
        { label: "Earning Rate", key: "EARNING_RATE" },
        { label: "Employee", key: "EMPLOYEE" },
        { label: "Employee Group", key: "EMPLOYEE_GROUP" },
        { label: "Timesheet Category", key: "TIMESHEET_CATEGORY" },
      ],
    },
  ];
  const name = " Timesheet Summary ";
  const FilterData: Array<{
    name: string;
    startRate?: string;
    endRate?: string;
    dataType?: string;
    endDate?: string;
    startDate?: string;
  }> = [
    { name: "Calendar", dataType: "text" },
    { name: "Employee group", dataType: "text" },
    { name: "Units", startRate: "text", endRate: "text" },
  ];

  const toast = useToast();
  const reportForm = useForm<EmployeesReport.TimesheetSummaryReport>({
    defaultValues: {
      columns: {
        calendar: false,
        earningRate: false,

        employee: false,
        employeeGroup: false,

        timesheetCategory: false,

        units: false,
      },
    },
  });
  const [selectedColumns, setSelectedColumns] = useState<Array<Column>>([
    colBtns[0].colItem[0],
    colBtns[0].colItem[2],
    colBtns[0].colItem[3],
  ]);
  const [clonedSelectedColumns, setClonedSelectedColumns] = useState<
    Array<Column>
  >([...colBtns[0].colItem]);

  const [selectedData, setSelectedData] = useState<
    | EmployeesReport.fetchTimesheetSummaryReport
    | EmployeesReport.fetchGroupByTimesheetSummaryReport
  >({
    data: [],
  });
  function postcolumns() {
    reportForm.handleSubmit((data) => {
      EmployeesReport.CreateTimesheetSummaryReport(
        data,
        (success) => {
          setSelectedData(success);
          setClonedSelectedGroupBy(selectedGroupBy);
          setClonedSelectedColumns(selectedColumns);
          toast({
            title: "Report Created",
            description: "Report Created Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        (error) => {
          toast({
            title: "Report Creation Failed",
            description: "Report Creation Failed",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
    })();
  }
  useEffect(() => {
    postcolumns();
  }, []);

  const headerLabels: { [key: string]: string } = {
    calendar: "Calendar",
    earningRate: "Earning Rate ",
    employee: "Employee",
    employeeGroup: "Employee Group",

    timesheetCategory: "Timesheet Category",
    units: "Units",

    // ... any other custom labels
  };
  const downloadExcel = () => {
    const flattenData = (data: GenericObject[]): GenericObject[] => {
      return data.map((item) => {
        const flattened: GenericObject = {};

        const flatten = (obj: GenericObject): GenericObject => {
          const result: GenericObject = {};

          Object.entries(obj).forEach(([key, value]) => {
            if (key === "employeeEmployementDetails" && value === null) {
              return;
            }

            if (
              typeof value === "object" &&
              value !== null &&
              !(value instanceof Date) &&
              !(value instanceof Array)
            ) {
              if (key === "employeeEmployementDetails") {
                Object.assign(result, flatten(value));
              } else {
                // For other nested objects, prefix the key and flatten
                const nestedObject = flatten(value);
                Object.entries(nestedObject).forEach(
                  ([nestedKey, nestedValue]) => {
                    result[`${key}_${nestedKey}`] = nestedValue;
                  }
                );
              }
            } else {
              // Non-object values are added directly
              result[key] = value;
            }
          });

          return result;
        };

        Object.assign(flattened, flatten(item));
        return flattened;
      });
    };

    const workbook = XLSX.utils.book_new();
    const flattenedData = flattenData(selectedData.data);
    const dataWithCustomHeaders = applyCustomLabels(
      flattenedData,
      headerLabels
    );
    const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Use FileSaver to save the file
    saveAs(blob, "report.xlsx");
  };
  const applyCustomLabels = (
    data: GenericObject[],
    labels: { [key: string]: string }
  ): GenericObject[] => {
    return data.map((item) => {
      const customizedItem: GenericObject = {};
      Object.keys(item).forEach((key) => {
        const newKey = labels[key] || key; // Use the custom label if it exists, otherwise use the key as is
        customizedItem[newKey] = item[key];
      });
      return customizedItem;
    });
  };

  return (
    <FormProvider {...reportForm}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          gap={0}
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _light={{
            bgColor: "customColor.white",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
        >
          <Stack gap={0} padding={"16px 0px"} margin={"0px 4px 0px 16px"}>
            <Link
              color={"#0078C8"}
              fontWeight={"400;"}
              fontSize={"13px"}
              href="organisationSettings"
            >
              Reports
            </Link>
            <Text
              fontWeight={"bold"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black",
              }}
              fontSize={"17px"}
              padding={"0px 12px 0px 0px"}
            >
              {name}
            </Text>
          </Stack>
        </Stack>
        <Container padding={"0px 12px"} maxW="980px">
          <Stack padding={"0px"}>
            <SimpleGrid columns={{ xs: 2, sm: 2, md: 3, lg: 4 }} spacing={2}>
              <EmployeeMenu />
              <EarningRate />
              <TimesheetCategory />

              {/* <DateRange /> */}

              <ColumnsComponent
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                colBtns={colBtns}
              />
              <Grouping
                setSelectedGroupBy={setSelectedGroupBy}
                groupingBtn={groupingBtn}
              />
              <FilterComponent FilterData={FilterData} />
            </SimpleGrid>
            <Box
              display="flex"
              justifyContent="flex-end"
              margin={"20px 0px"}
              paddingLeft={"10px"}
            >
              <Button
                borderRadius={"3px"}
                fontSize={"15px"}
                h={"40px"}
                padding={"11px 12px"}
                onClick={reportForm.handleSubmit((data) => {
                  EmployeesReport.CreateTimesheetSummaryReport(
                    data,
                    (success) => {
                      setSelectedData(success);
                      setClonedSelectedGroupBy(selectedGroupBy);
                      setClonedSelectedColumns(selectedColumns);
                      toast({
                        title: "Report Created",
                        description: "Report Created Successfully",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                    },
                    (error) => {
                      toast({
                        title: "Report Creation Failed",
                        description: "Report Creation Failed",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  );
                })}
              >
                Upload
              </Button>
              {/* <Button onClick={downloadExcel} borderRadius={"3px"}>
              Download as Excel
            </Button> */}
            </Box>
          </Stack>
        </Container>
        <Container maxW={"1150px"} paddingLeft={"22px"} paddingRight={"22px"}>
          <ReportingTable
            name={name}
            Data={Data}
            DataWithKey={DataWithKey}
            selectedColumns={clonedSelectedColumns}
            selectedData={selectedData}
            groupBy={clonedSelectedGroupBy}

            // tabRef={tabRef}
          />
        </Container>
        <Box
          borderTop="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          position="fixed"
          left={0}
          bottom="0"
          w="full"
          h="50px"
          // zIndex={"overlay"}
        >
          <Container
            justifyContent="center"
            alignItems="flex-end"
            textAlign={"right"}
            maxW={"90%"}
          >
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                border={"1px solid #a6a9b0"}
                rightIcon={<ChevronDownIcon />}
                borderRadius={"3px"}
                marginTop={"10px"}
              >
                Export
              </MenuButton>
              <MenuList style={{ minWidth: "120px" }}>
                <MenuItem
                  onClick={downloadExcel}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Excel
                  </Text>
                </MenuItem>

                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    PDF
                  </Text>
                </MenuItem>
                <MenuItem
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    Google Sheet
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Container>
        </Box>
      </Stack>
    </FormProvider>
  );
}

const colBtns: Array<{
  colItem: Array<Column>;
}> = [
  {
    colItem: [
      { colLabel: "Calendar", column: "calendar", position: 2 },
      {
        colLabel: "Earning Rate",
        column: "earningRate",
        position: 3,
        groupingKey: "EARNING_RATE",
      },
      {
        colLabel: "Employee",
        column: "employee",
        position: 4,
        groupingKey: "EMPLOYEE",
      },
      {
        colLabel: "Employee Group",
        column: "employeeGroup",
        position: 5,
        groupingKey: "EMPLOYEE_GROUP",
      },

      {
        colLabel: "Timesheet Category",
        column: "timesheetCategory",
        position: 6,
        groupingKey: "TIMESHEET_CATEGORY",
      },
      { colLabel: "Units", column: "units", position: 7 },
    ],
  },
];
const DataWithKey = {
  NONE: [
    {
      calendar: "Four Weekly",
      earningRate: "Fixed Rate",
      employee: "James Lebron",

      employeeGroup: "none",
      timesheetCategory: "Grouped",
      unit: "Days",
    },
    {
      calendar: "Four Weekly",
      earningRate: "Fixed Rate",
      employee: "James Lebron",

      employeeGroup: "none",
      timesheetCategory: "Grouped",
      unit: "Days",
    },
    {
      calendar: "Four Weekly",
      earningRate: "Fixed Rate",
      employee: "James Lebron",

      employeeGroup: "none",
      timesheetCategory: "Grouped",
      unit: "Days",
    },
  ],
};
const Data = [
  {
    calendar: "Four Weekly",
    earningRate: "Fixed Rate",
    employee: "James Lebron",

    employeeGroup: "none",
    timesheetCategory: "Grouped",
    unit: "Days",
  },
  {
    calendar: "Four Weekly",
    earningRate: "Fixed Rate",
    employee: "James Lebron",

    employeeGroup: "none",
    timesheetCategory: "Grouped",
    unit: "Days",
  },
  {
    calendar: "Four Weekly",
    earningRate: "Fixed Rate",
    employee: "James Lebron",

    employeeGroup: "none",
    timesheetCategory: "Grouped",
    unit: "Days",
  },
];
