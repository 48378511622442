import { Box } from "@chakra-ui/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useFormContext } from "react-hook-form";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";

// Assuming this is the structure of your incoming data
interface DashboardAnalytics {
  management: { [key: string]: number };
  nonManagement: { [key: string]: number };
}

// Type definitions for the categories and qualifications
type Category = "Management" | "Non-Management";
type EducationQualification =
  | "Masters Degree"
  | "Bachelors Degree"
  | "Diploma"
  | "Intermediate"
  | "Matriculation";

// Interface for the structured data used in the chart
interface PreparedData {
  Management: Record<EducationQualification, number>;
  "Non-Management": Record<EducationQualification, number>;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const convertKeyToQualification = (key: string): EducationQualification => {
  // Simplified conversion logic; you'll need to implement actual mapping based on your keys
  const map: { [key: string]: EducationQualification } = {
    Masters: "Masters Degree",
    Bachelors: "Bachelors Degree",
    Diploma: "Diploma",
    Intermediate: "Intermediate",
    Matriculation: "Matriculation",
  };
  return map[key] || "Matriculation"; // Default or error handling
};

export default function BarChart() {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();
  let preparedData: PreparedData = {
    Management: {
      "Masters Degree": 0,
      "Bachelors Degree": 0,
      Diploma: 0,
      Intermediate: 0,
      Matriculation: 0,
    },
    "Non-Management": {
      "Masters Degree": 0,
      "Bachelors Degree": 0,
      Diploma: 0,
      Intermediate: 0,
      Matriculation: 0,
    },
  };

  const mapDataToLabels = (
    data: { [key: string]: number },
    category: Category
  ) => {
    Object.entries(data).forEach(([key, value]) => {
      const qualification = convertKeyToQualification(key);
      if (qualification in preparedData[category]) {
        preparedData[category][qualification] = value;
      }
    });
  };

  // Dynamically update preparedData based on the provided barChartData
  if (dashboardAnalytics?.educationChart) {
    mapDataToLabels(
      dashboardAnalytics?.educationChart?.management,
      "Management"
    );
    mapDataToLabels(
      dashboardAnalytics?.educationChart?.nonManagement,
      "Non-Management"
    );
  }

  const data = {
    labels: [
      "Masters Degree",
      "Bachelors Degree",
      "Diploma",
      "Intermediate",
      "Matriculation",
    ],
    datasets: [
      {
        label: "Management",
        data: Object.values(preparedData.Management),
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Non-Management",
        data: Object.values(preparedData["Non-Management"]),
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };
  const form = useFormContext<Analytics.DashBoardData>();
  return (
    <Box
      display="flex" // Enables flexbox
      flexDirection="column" // Stack children vertically
      alignItems="center" // Center content horizontally
      justifyContent="center" // Center content vertically
      word-wrap="break-word"
      _light={{
        color: "customColor.gray.64748B",
      }}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      minW={"100%"}
      p="25px"
      overflowY="auto"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Bar
        data={data}
        options={options}
        style={{ width: "100%", height: "100%", alignSelf: "center" }}
      />
    </Box>
  );
}
