import {
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { PayslipsModule } from "../../Api/PaySlips";
import HistoryAccordion from "../Superannuation/History/HistoryAccordion";
import PayslipDownloading from "./PayslipdownloadingButton";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Payslips({ setSelectedTab, selectedTab }: TabProps) {
  const { id } = useParams();
  const toasts = useToast();
  const [payslips, setPayslips] = useState<Array<PayslipsModule.PayslipData>>(
    []
  );
  const { businessesList, currentBusinessId } = useBusinessContext();
  let countryData = Country.getAllCountries();
  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;
  const currencyData = countryData.find(
    (country) => country.name === countryName
  )?.currency;
  useEffect(() => {
    PayslipsModule.GetPayslips(
      { employeeId: id! },
      (response) => {
        setPayslips(response);
      },
      (message) => {
        toasts({
          title: "Error",
          description: message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  }, []);

  return (
    <Stack
      gap={0}
      textAlign={"left"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      marginBottom={"30px"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Heading
        textAlign="left"
        fontSize=".9975rem"
        _light={{
          color: "customColor.black7",
        }}
        fontWeight="bold"
        padding="20px"
        as="h2"
        size="md"
      >
        Salary Slip History
      </Heading>
      <Box overflowX="auto" p="0px">
        <Table variant="simple" w="100%" overflowX="auto" marginBottom={"30px"}>
          <Thead h={"36.8px"}>
            <Tr
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.150",
              }}
              borderTopWidth={"1px"}
              borderTopColor="borders.tableBorder"
            >
              {["Sr", "Period", "Payment Date", "Total Pay", ""].map(
                (item, i) => {
                  return (
                    <Th
                      key={i}
                      padding="8px 26px 8px 15px"
                      borderBottomColor="borders.tableBorder"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                    >
                      {item}
                    </Th>
                  );
                }
              )}
            </Tr>
          </Thead>
          {payslips?.length > 0 ? (
            <Tbody>
              {payslips.map((payslip, i) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {i + 1}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {`${
                        payslip?.payRun?.payrollCalendar?.name
                      } ending ${moment(payslip?.payRun?.endDate).format(
                        "DD MMM YYYY"
                      )}`}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {moment(payslip?.paymentDate).format("DD MMM YYYY")}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {`${currencyData} ${(
                        payslip?.totalEarnings +
                        payslip?.totalReimbursements -
                        payslip?.totalDeductions -
                        payslip?.totalTax
                      ).toLocaleString()}`}
                    </Td>

                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <PayslipDownloading
                        id={payslip?.id}
                        paymentDate={payslip?.paymentDate}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Tbody>
              <Tr>
                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderBottomWidth="1px"
                  borderBottomColor={"borders.tableBorder"}
                ></Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderBottomWidth="1px"
                  borderBottomColor={"borders.tableBorder"}
                ></Td>
                <Td
                  fontWeight={500}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderBottomWidth="1px"
                  borderBottomColor={"borders.tableBorder"}
                  textAlign={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  pb={"40px"}
                  pt={"30px"}
                >
                  <Image
                    boxSize="30"
                    src="https://mts.rs/Picture/4339/png/prijava-smetnji.png"
                  />
                  <Text fontSize="15px">No Salary Slips</Text>
                </Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderBottomWidth="1px"
                  borderBottomColor={"borders.tableBorder"}
                ></Td>
                <Td
                  padding="15px"
                  fontWeight={500}
                  fontSize="13.44px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  lineHeight={1.5}
                  borderBottomWidth="1px"
                  borderBottomColor={"borders.tableBorder"}
                ></Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </Box>

      <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
        <Button
          colorScheme="green"
          border={"1px solid green"}
          textColor={"customColor.white"}
          borderRadius={"3px"}
          fontSize={"13px"}
          h={"40px"}
          variant={"solid"}
          padding={"12px 16px"}
          marginRight={"5px"}
          fontWeight={600}
          onClick={() => setSelectedTab(selectedTab + 1)}
        >
          Next
        </Button>
      </Stack>

      <Stack
        justify={"flex-end"}
        marginX={"auto"}
        borderTop={"1px solid #d6dade"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginRight={"auto"}
        marginLeft={"auto"}
        w="100%"
        marginTop={"20px"}
      >
        <HistoryAccordion />
      </Stack>
    </Stack>
  );
}
