import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";

import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelectForInvoiceSettings } from "../../../../Common/ReactSelectForInvoiceSettings";

export function RightSideComponent() {
  const [fontSize, setFontSize] = useState("");
  const [font, setFont] = useState("");
  const FontOption: Array<{ label: string; value: string }> = [
    {
      label: "Arial",
      value: "Arial",
    },
    {
      label: "Calibri",
      value: "Calibri",
    },
    {
      label: "Cambria",
      value: "Cambria",
    },
    {
      label: "Cardo",
      value: "Cardo",
    },
    {
      label: "Fira Sans",
      value: "Fira Sans",
    },
    {
      label: "Georgia",
      value: "Georgia",
    },
    {
      label: "HK Grotesk",
      value: "HK Grotesk",
    },
    {
      label: "Myrial",
      value: "Myrial",
    },
  ];
  const FontSizeOption: Array<{ label: string; value: string }> = [
    {
      label: "8pt",
      value: "8pt",
    },
    {
      label: "9pt",
      value: "9pt",
    },
    {
      label: "10pt",
      value: "10pt",
    },
    {
      label: "11pt",
      value: "11pt",
    },
  ];
  return (
    <Stack
      gap={0}
      flex={1}
      borderLeft={"1px solid #ddd"}
      marginTop={"10px"}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      border="1px solid #e5e7eb"
      bg={"#fff"}
      padding={"10px"}
      h={"fit-content"}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        paddingBottom={"10px"}
        paddingLeft={"10px"}
      >
        <Text
          whiteSpace={"nowrap"}
          flex={1}
          fontSize={"11px"}
          color={"customColor.black7"}
          fontWeight={"700"}
        >
          Name
        </Text>
        <Stack flex={2} justifyContent={"flex-end"}>
          <CustomInput
            withValidation
            input={{
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              fontSize: "12px",
              backgroundColor: "#fff",
              variant: "outline",
              // color: "#ccc",

              lineHeight: "1.2",
              h: "20px",

              type: "text",

              borderRadius: "0px",
              borderLeftRadius: 0,
              borderRightRadius: 0,
            }}
            placeholder=""
          />
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={0} alignItems={"flex-start"}>
        <Stack
          gap={0}
          paddingLeft={"10px"}
          flex={2}
          direction={"row"}
          alignItems={"start"}
          paddingBottom={"10px"}
          w={"70px"}
        >
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Page Size
          </Text>

          <Stack
            spacing={1}
            marginTop={"-3px"}
            flex={1}
            justifyContent={"flex-end"}
          >
            <RadioGroup defaultValue="2">
              <Stack>
                <Radio colorScheme="red" value="1" size="sm">
                  <Text
                    textAlign={"right"}
                    fontSize={"11px"}
                    color={"customColor.black7"}
                  >
                    A4
                  </Text>
                </Radio>
                <Radio colorScheme="green" value="2" size="sm">
                  <Text
                    textAlign={"right"}
                    fontSize={"11px"}
                    color={"customColor.black7"}
                  >
                    Us Letter
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack paddingLeft={"10px"} w={"30%"}>
          <Stack border={"1px solid #ddd"} padding={"5px 0px 1px 5px"} gap={0}>
            <Text
              fontSize={"11px"}
              color={"customColor.black7"}
              fontWeight={"700"}
            >
              Measure in
            </Text>
            <RadioGroup defaultValue="2">
              <Stack direction={"row"}>
                <Radio colorScheme="red" value="1" size="sm">
                  <Text
                    textAlign={"right"}
                    fontSize={"11px"}
                    color={"customColor.black7"}
                  >
                    cm
                  </Text>
                </Radio>
                <Radio colorScheme="green" value="2" size="sm">
                  <Text
                    textAlign={"right"}
                    fontSize={"11px"}
                    color={"customColor.black7"}
                  >
                    inches
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>

      <Stack w={"100%"} paddingLeft={"10px"}>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Top Margin
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Bottom Margin
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Address padding
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Font
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <ReactSelectForInvoiceSettings
              withValidation
              options={FontOption}
              value={FontOption.find((op) => op.value === font)}
              onChange={(newValue: any) => {
                setFont(newValue?.value);
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Font Size
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <ReactSelectForInvoiceSettings
              withValidation
              options={FontSizeOption}
              value={FontSizeOption.find((op) => op.value === fontSize)}
              onChange={(newValue: any) => {
                setFontSize(newValue?.value);
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Draft Invoices Title
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Approved Invoices title
          </Text>
          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Overdue Invoices title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Credit Note title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Statement title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Draft Purchase Order title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Purchase Order title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Draft Quote title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Quote title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Remittance Advice title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Text
            whiteSpace={"nowrap"}
            flex={1}
            fontSize={"11px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Receipt title
          </Text>

          <Stack flex={2} justifyContent={"flex-end"}>
            <CustomInput
              withValidation
              input={{
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: "12px",
                backgroundColor: "#fff",
                variant: "outline",
                // color: "#ccc",

                lineHeight: "1.2",
                h: "20px",

                type: "text",

                borderRadius: "0px",
                borderLeftRadius: 0,
                borderRightRadius: 0,
              }}
              placeholder=""
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
