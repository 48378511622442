import {
  Avatar,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsFillShieldFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { GrPersonalComputer } from "react-icons/gr";
import { InviteUser } from "../../../../../api/InviteUser";
import { Edit } from "./Modal/EditModal";

export function UserProfile() {
  const editModal = useDisclosure();
  const form = useFormContext<InviteUser.UserInviteDetail>();
  const toast = useToast();
  return (
    <Box
      position={"sticky"}
      top={0}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      borderWidth="1px"
      borderStyle={"solid"}
      borderColor="#f6f6f6"
      borderRadius={"5px"}
      gap={0}
      h={"fit-content"}
    >
      <Stack
        gap={0}
        justifyContent={"center"}
        borderBottom={"1px solid lightgrey"}
        alignItems={"center"}
        padding={"20px"}
        w={{ md: "100%", lg: "290px" }}
      >
        <Avatar
          name={
            form.getValues("user.firstName") +
            " " +
            form.getValues("user.lastName")
          }
          src="https://bit.ly/broken-link"
          bg={"#FDD580"}
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"21px"}
          h={"80px"}
          w={"80px"}
          fontWeight={"700"}
          marginBottom={"12px"}
        />
        <Text
          fontSize={"17px"}
          fontWeight={"700"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black7",
          }}
        >
          {form.getValues("user.firstName") +
            " " +
            form.getValues("user.lastName")}
        </Text>
        <Text
          fontSize={"13px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.200",
          }}
        >
          {form.getValues("user.email")}
        </Text>
        <Text
          fontSize={"13px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.200",
          }}
        >
          {form.getValues("status") === "PENDING" ? "Pending" : "Active"}
        </Text>
        {form.getValues("status") === "PENDING" && (
          <Stack direction={"row"} marginTop={"12px"}>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              // color={"#0078C8"}
              // _dark={{
              //   bgColor: "customColor.dark.50",
              // }}
              // _light={{
              //   bgColor: "customColor.white",
              // }}
              // borderRadius={"3px"}
              // margin={"0px 12px 0px 0px"}
              // padding={"5px 12px"}
              // fontSize={"13px"}
              // border={"1px solid #a6a9b0"}
              // _hover={{
              //   bg: "#f2f3f4",
              // }}
              onClick={() => {
                editModal.onOpen();
              }}
            >
              Edit details
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              // variant={"solid"}
              // color={"#fff"}
              // borderRadius={"3px"}
              // padding={"5px 12px"}
              // fontSize={"13px"}
              // border={"1px solid #47C5E8"}
              onClick={() => {
                InviteUser.ResendInvitation(
                  form.getValues("user.email")!,
                  () => {
                    toast({
                      title: "Invitation sent",
                      description: "Invitation has been sent to the user",
                      status: "success",
                    });
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      description: err,
                      status: "error",
                    });
                  }
                );
              }}
            >
              Resend Invite
            </Button>
          </Stack>
        )}
      </Stack>
      <Edit isOpen={editModal.isOpen} onClose={editModal.onClose} />

      <Stack
        gap={0}
        borderBottom={"1px solid lightgrey"}
        padding={"20px"}
        w={{ md: "100%", lg: "290px" }}
      >
        <Heading
          fontSize={"13px"}
          margin={"0px 0px 16px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.200",
          }}
        >
          Current role and security
        </Heading>
        <HStack>
          <Icon as={FaUserAlt} fontSize={"15px"} marginBottom={"30px"} />
          <Text
            fontSize={"13px"}
            fontWeight={"400"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
          >
            Standard (Non-cash coding), Manage users - Reports - Bank account
            admin, Payroll admin
          </Text>
        </HStack>
        <HStack>
          <Icon as={BsFillShieldFill} />
          <Text
            fontSize={"13px"}
            fontWeight={"400"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
          >
            Two-factor authentication not enabled
          </Text>
        </HStack>
      </Stack>
      <Stack gap={0} padding={"20px"} w={{ md: "100%", lg: "290px" }}>
        <Heading
          fontSize={"13px"}
          margin={"0px 0px 16px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.200",
          }}
        >
          History
        </Heading>
        <HStack alignItems={"center"}>
          <Icon as={GrPersonalComputer} fontSize={"15px"} />
          <Text
            fontSize={"13px"}
            fontWeight={"400"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
          >
            Logged in Nov 13, 2023 at 10:02 AM
          </Text>
        </HStack>
        <HStack>
          <Icon as={AiOutlineCalendar} />
          <Text
            fontSize={"13px"}
            fontWeight={"400"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
          >
            Current as Oct 9, 2023
          </Text>
        </HStack>
      </Stack>
    </Box>
  );
}
