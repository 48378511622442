import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { ReactSelectTimeSheet } from "../../../../Common/ReactSelectTimeSheet";

interface AddProjectProps {
  isOpen: boolean;
  onClose: () => void;
}
export function AddProject({ isOpen, onClose }: AddProjectProps) {
  const timesheet = [
    { value: "Syed Irsam Hashmi", label: "Syed Irsam Hashmi" },
  ];
  const [selectedTimesheet, setSelectedTimesheet] = useState();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"410px"}>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text fontSize={"18px"} padding={"15px 0px"}>
            Add Projects time entries
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack direction={"row"}>
            <Stack flex={1} gap={0}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Staff name in Projects
              </Text>
              <ReactSelectTimeSheet
                placeholder=""
                options={timesheet}
                value={selectedTimesheet}
                onChange={(newValue: any) => {
                  setSelectedTimesheet(newValue);
                }}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
              />
            </Stack>
            <Stack flex={1} gap={0}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Period total
              </Text>
              <Text
                fontSize={"12px"}
                marginTop={"5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"normal"}
              >
                0 hours
              </Text>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              w={"78px"}
              h={"29px"}
              colorScheme="primary"
              fontSize={"12px"}
              padding={"6px 12px"}
              borderRadius="3px"
              border={"1px solid"}
              borderColor={"primary.950"}
              onClick={onClose}
            >
              Continue
            </Button>
            <Button
              w={"78px"}
              h={"28px"}
              marginLeft="20px"
              colorScheme="customGray"
              fontSize={"12px"}
              padding={"6px 0px"}
              border={"1px solid #949a9e"}
              borderRadius="3px"
              onClick={onClose}
              _hover={{ bg: "#949a9e", border: "1px solid #899094" }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
