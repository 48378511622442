import { Box, HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemReimbursementApi } from "../../../Api/PayItemReinbursement";
import { PayTemp } from "../../../Api/PayTemp";

interface ReimbursementProps {
  form?: any;
  reimbursementFieldArray?: any;
  reimbursementLine?: any;
  currentEmployeeId?: string | undefined;
  accountReimbursement?: PayItemReimbursementApi.ReimbursementById[];
  setAccountReimbursement?: (accountReimbursement: any) => void;
  setResetData?: (resetData: any) => void;
}
export function Reimbursement({
  form,
  reimbursementFieldArray,
  reimbursementLine,
  setAccountReimbursement,
  accountReimbursement,
  setResetData,
  currentEmployeeId,
}: ReimbursementProps) {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const toast = useToast();
  useEffect(() => {
    PayItemReimbursementApi.FetchReimbursement(
      (res) => {
        if (setAccountReimbursement) {
          setAccountReimbursement(res);
        }
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Box gap={0}>
      <Text
        fontSize={"12px "}
        margin={"0px 0px 4px"}
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
      >
        Reimbursement
      </Text>
      {reimbursementFieldArray.fields.map(
        (item: PayTemp.ReimbursementRate & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"end"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                color={"primary.950"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (decodedToken.accessLevel !== "EMPLOYEE") {
                    reimbursementLine.onOpen();
                  }

                  if (setResetData) {
                    setResetData({ ...item, index: i });
                  }
                }}
              >
                {(accountReimbursement &&
                  accountReimbursement.find(
                    (accountReimbursement) =>
                      accountReimbursement.id === item.payItemReimbursementId
                  )?.name) ??
                  ""}
                {form.getValues(`reimbursments.${i}.description`) && ": "}
                {form.getValues(`reimbursments.${i}.description`)}
              </Text>
            </Stack>

            {form.getValues(`reimbursments.${i}.payItemReimbursementId`) ===
              item.payItemReimbursementId && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Amount is required",
                      },
                    }}
                    control={form.control}
                    name={`reimbursments.${i}.amount`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          _light: {
                            color: "customColor.black7",
                          },
                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Amount "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {decodedToken.accessLevel !== "EMPLOYEE" && (
              <Stack>
                <Icon
                  fontSize={"20px"}
                  as={IoIosCloseCircleOutline}
                  marginTop={"15px"}
                  color={"grey"}
                  _hover={{
                    color: "red.500",
                  }}
                  onClick={() => reimbursementFieldArray.remove(i)}
                />
              </Stack>
            )}
          </Stack>
        )
      )}
    </Box>
  );
}
