import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccessControl } from "../../../../../../../api/AccessControl";

interface DeleteCurrentUserProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  setLastUpdated: (timestamp: number) => void;
}
export function DeleteCurrentUser({
  isOpen,
  onClose,
  id,
  setLastUpdated,
}: DeleteCurrentUserProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"350px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete User
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          fontSize={"0.8125rem"}
          fontWeight={"600"}
          _light={{
            color: "customColor.black7",
          }}
          border={"1px solid #E5E5E5"}
        >
          Are you sure you want to delete this user?
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" alignItems={"flex-end"}>
            <Button
              variant="solid"
              fontSize={"13px"}
              padding={"10px 15px"}
              borderRadius={"3px"}
              minH={"40px"}
              border={"1px solid #FDEDED"}
              bg={"#FDEDED"}
              color={"#EA4D4D"}
              minW={"75px"}
              _hover={{
                border: "1px solid #EA4D4D",
                bg: "#EA4D4D",
                color: "#fff",
              }}
              onClick={() => {
                setIsLoading(true);
                AccessControl.DeleteUserById(
                  id,
                  () => {
                    toast({
                      title: "User Deleted",
                      description: "User Deleted Successfully",
                      status: "success",
                    });
                    setIsLoading(false);
                    setLastUpdated(Date.now());
                    onClose();
                  },
                  (err) => {
                    toast({
                      title: "Error",
                      description: err,
                      status: "error",
                    });
                    setIsLoading(false);
                    onClose();
                  }
                );
              }}
            >
              Delete
            </Button>
            <Button
              onClick={onClose}
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
