import {
  Box,
  Button,
  Container,
  Divider,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { IoMdDocument } from "react-icons/io";
import { IoSchoolSharp } from "react-icons/io5";
import {
  MdBoy,
  MdOutlineContactEmergency,
  MdOutlineLocalHospital,
  MdOutlineRoomPreferences,
  MdWork,
} from "react-icons/md";
import Dependents from "../EmployeeDetails/Tabs/Dependents/Dependents";
import DocumentUpload from "../EmployeeDetails/Tabs/DocumentsUpload/DocumentUpload";
import Education from "../EmployeeDetails/Tabs/Education/Education";
import Experience from "../EmployeeDetails/Tabs/Experience/Experience";
import NextOfKin from "../EmployeeDetails/Tabs/NextOfKin/NextOfKin";
import References from "../EmployeeDetails/Tabs/References/references";

import { jwtDecode } from "jwt-decode";
import { useAuthHeader, useAuthUser, useSignOut } from "react-auth-kit";
import { CiBank } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Employees } from "../../Api/Employees";
import { BankAccount } from "../EmployeeDetails/Tabs/Bank Account";
import EmergencyContact from "../EmployeeDetails/Tabs/EmergencyContact/EmergencyContact";
import { EmployeeInfo } from "./EmployeeInfo";
import { EmployeeMedicalBenefits } from "./EmployeeMedicalBenefits";
import { EmployeeStatistics } from "./EmployeeStatistic";
import { EmployeeStatutoryBenefits } from "./EmployeeStatutoryBenefits";

export function EmployeeDashboard() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { isOpen, onToggle } = useDisclosure();
  const [userName, setUserName] = useState("");
  const user = useAuthUser();
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  useEffect(() => {
    const UserDetail = user();

    if (UserDetail) {
      const fullName = UserDetail.firstName + " " + UserDetail.lastName;
      setUserName(fullName); // Update the state variable
    } else {
      toast({
        title: "Name not found in the user object",
        status: "error",
      });
    }
  }, []);
  const toast = useToast();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] =
    useState<Employees.EmployeeAllDetail>();
  useEffect(() => {
    Employees.EmployeeAllDetail(
      (data) => {
        setEmployeeData(data);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, [selectedTab === 0]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const [isLargerThanMD] = useMediaQuery("(min-width: 1000px)");
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [selectedTab]);

  const tabCategories: Array<{
    categoryName: string;
    tabs: Array<{
      label: string;
      index: number;
      iconName?: any;
      completion?: any;
    }>;
  }> = [
    {
      categoryName: "Personal Information",
      tabs: [
        { label: "EmployeeDashboard", iconName: <IoSchoolSharp />, index: 0 },
        {
          label: "Education",
          iconName: <IoSchoolSharp />,
          index: 1,
          completion: employeeData?.education?.length,
        },
        {
          label: "Experience",
          iconName: <MdWork />,
          index: 2,
          completion: employeeData?.experiences?.length,
        },
        {
          label: "References",
          iconName: <MdOutlineRoomPreferences />,
          index: 3,
          completion: employeeData?.references?.length,
        },

        {
          label: "Dependents",
          iconName: <MdOutlineLocalHospital />,
          index: 4,
          completion: employeeData?.dependents?.length,
        },
        {
          label: "Next of Kin",
          iconName: <MdBoy />,
          index: 5,
          completion: employeeData?.nextOfKins?.length,
        },
        {
          label: "Emergency Contact",
          iconName: <MdOutlineContactEmergency />,
          index: 6,
          completion: employeeData?.emergencyContacts?.length,
        },

        // { label: "Opening Balances", iconName: <AiOutlineBank />, index: 8 },

        {
          label: "Documents",
          iconName: <IoMdDocument />,
          index: 7,
          completion: employeeData?.employeeDocuments?.length,
        },
      ],
    },
    {
      categoryName: "Benefits",
      tabs: [
        {
          label: "Group Life Isurance",
          iconName: <IoMdDocument />,
          index: 8,
        },
        {
          label: "Medical",
          iconName: <IoMdDocument />,
          index: 9,
        },
      ],
    },
    {
      categoryName: "Bank Details",
      tabs: [
        {
          label: "Bank Details",
          iconName: <CiBank />,
          index: 10,
        },
      ],
    },
  ];
  return (
    <Stack
      h="calc(100vh - 70px)"
      w={"100%"}
      overflowY="auto"
      gap={0}
      bgColor={"customColor.gray.400"}
      _dark={{ bgColor: "customColor.dark.100" }}
      _light={{ bgColor: "customColor.gray.400" }}
    >
      <Stack
        boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
        _dark={{ bgColor: "customColor.dark.50" }}
        _light={{ bgColor: "customColor.white" }}
        h={{ sm: "100px", md: "60px" }}
      >
        <Text
          fontWeight={"bold"}
          _dark={{ color: "customColor.dark.150" }}
          _light={{ color: "customColor.black7" }}
          padding={"16px 12px 16px 0px "}
          fontSize={"17px"}
          margin={"0px 42px 0px 42px"}
        >
          HRIS360 Me
        </Text>
      </Stack>
      <Stack
        direction={isLargerThanMD ? "row" : "column"}
        padding={isLargerThanMD ? "30px 15px 5px 15px" : "30px 20px 5px 20px"}
        spacing={"24px"}
        width={"100%"}
      >
        <Container
          maxW={isLargerThanMD ? "80%" : "100%"}
          padding={0}
          margin={0}
        >
          <Box paddingLeft={"1px"} w={"100%"}>
            <Tabs
              index={selectedTab}
              orientation={isLargerThanMD ? "vertical" : "horizontal"}
              onChange={setSelectedTab}
              isLazy
              align={isLargerThanMD ? "start" : "start"}
            >
              <Stack
                gap={0}
                width={"30%"}
                h={"fit-content"}
                position={"sticky"}
                top={"20px"}
              >
                {isLargerThanMD ? (
                  <Stack
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                    }}
                    zIndex={1}
                    overflowY="auto"
                    h="100%"
                    sx={{
                      "::-webkit-scrollbar": {
                        width: "0px", // Initially, the scrollbar is hidden
                        background: "transparent",
                      },
                      ":hover::-webkit-scrollbar": {
                        width: "8px", // Scrollbar visible on hover
                      },
                      ":active::-webkit-scrollbar": {
                        width: "8px", // Scrollbar visible when active
                      },
                      ":focus::-webkit-scrollbar": {
                        width: "8px", // Scrollbar visible on focus
                      },
                      // Add for Firefox and other browsers if needed
                      scrollbarWidth: "none",
                      ":hover": {
                        scrollbarWidth: "thin",
                      },
                      ":active": {
                        scrollbarWidth: "thin",
                      },
                      ":focus": {
                        scrollbarWidth: "thin",
                      },
                    }}
                    // borderWidth="1px"
                    // borderColor="gray.200"

                    boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                    borderWidth="1px"
                    borderStyle={"solid"}
                    borderColor="#f6f6f6"
                    padding={"15px"}
                    gap={0}
                  >
                    {tabCategories.map((category, i) => (
                      <Box key={i}>
                        <Text
                          fontSize={"14.4px"}
                          fontWeight={"bold"}
                          _light={{
                            // color: "customColor.black7",
                            color: "#E97132",
                          }}
                          margin={"24px 0px 8px"}
                        >
                          {category.categoryName}
                        </Text>
                        {category.tabs.map((tab, j) => (
                          <Tab
                            marginLeft="0px"
                            justifyContent="flex-start"
                            w="100%"
                            p={"5px 15px"}
                            key={j}
                            marginBottom={"5px"}
                            fontSize="13px"
                            borderRadius={"3px"}
                            cursor="pointer"
                            color="#74788D"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _selected={{
                              bgColor: "#EAEBEF",
                              color: "customColor.black8",
                              borderRadius: "5px",
                              transition: "all .3s ease",
                            }}
                            _hover={{
                              bgColor: "#EAEBEF",
                              color: "customColor.black8",
                              borderRadius: "5px",
                              transition: "all .3s ease",
                            }}
                            onClick={() => setSelectedTab(tab.index)}
                          >
                            <Stack
                              w={"100%"}
                              direction={"row"}
                              gap={0}
                              alignItems={"center"}
                              _hover={{
                                color: "customColor.black8",
                              }}
                              justifyContent={"space-between"}
                            >
                              <HStack>
                                <Button
                                  as={IconButton}
                                  color={
                                    selectedTab === tab.index
                                      ? "#001327"
                                      : "#283C50"
                                  }
                                  _hover={{
                                    bg: "transparent",
                                  }}
                                  icon={tab.iconName}
                                  variant={"ghost"}
                                  padding={"0px"}
                                />
                                {/* <Icon as={tab.iconName} /> */}
                                <Text
                                  fontSize={"13px"}
                                  fontWeight={"600"}
                                  _hover={{
                                    color: "customColor.black8",
                                  }}
                                >
                                  {tab.label}
                                </Text>
                              </HStack>
                              {tab?.completion && (
                                <FaCheckCircle size="18px" color="#50C878" />
                              )}
                            </Stack>
                          </Tab>
                        ))}
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Box>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<AiOutlineCaretDown />}
                        backgroundColor={"#fff"}
                        borderRadius={"3px"}
                        justifyContent="flex-end"
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        h={"40px"}
                        minW={"170px"}
                        variant={"solid"}
                        textColor={"customColor.black7"}
                        padding={"12px 16px"}
                        fontWeight={600}
                        _hover={{
                          color: "primary.950",
                          backgroundColor: "#fff",
                        }}
                        cursor={"pointer"}
                        _active={{
                          backgroundColor: "#fff",
                          color: "primary.950",
                        }}
                      >
                        {tabCategories
                          .map((tabCategory) =>
                            tabCategory.tabs.find(
                              (tab) => tab.index === selectedTab
                            )
                          )
                          .filter(Boolean)
                          .map((tab: any) => tab.label)}
                      </MenuButton>

                      <Portal>
                        <MenuList
                          zIndex={"overlay"}
                          maxH={"300px"}
                          overflowX={"scroll"}
                        >
                          {tabCategories.map((tabCategory, i) => (
                            <MenuGroup
                              key={i}
                              title={tabCategory.categoryName}
                              padding={"6px 12px"}
                              margin={0}
                            >
                              {tabCategory.tabs.map((tab, j) => (
                                <MenuItem
                                  key={j}
                                  fontSize={"13.44px"}
                                  onClick={() => {
                                    setSelectedTab(tab.index);
                                  }}
                                >
                                  {tab.label}
                                </MenuItem>
                              ))}
                              <Divider />
                            </MenuGroup>
                          ))}
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                )}
              </Stack>

              <Stack padding={"10px"}></Stack>

              <TabPanels overflowX={"auto"} width={"70%"}>
                <TabPanel p={0}>
                  <EmployeeStatistics employeeData={employeeData} />
                </TabPanel>

                <TabPanel p={0}>
                  <Education
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[1]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Experience
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[2]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <References
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[3]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Dependents
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[4]}
                  />
                </TabPanel>

                <TabPanel p={0}>
                  <NextOfKin
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[5]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <EmergencyContact
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[6]}
                  />
                </TabPanel>

                {/* <TabPanel p={0} >
                <OpeningBal />
              </TabPanel> */}

                <TabPanel p={0}>
                  <DocumentUpload
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[10]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <EmployeeStatutoryBenefits
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[10]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <EmployeeMedicalBenefits
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[10]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <BankAccount
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[10]}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Container>
        <Container
          maxW={isLargerThanMD ? "20%" : "100%"}
          padding={0}
          h={"fit-content"}
          position={"sticky"}
          top={"20px"}
        >
          <EmployeeInfo employeeData={employeeData} />
        </Container>
      </Stack>
    </Stack>
  );
}
