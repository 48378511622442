import {
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import { FiSearch } from "react-icons/fi";
import { RiFilterFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AccountWithoutBankFeed } from "./Modal/AccountWithoutBankFeed";

export function AddBankAccount() {
  let countryData = Country.getAllCountries();
  const navigate = useNavigate();
  const accountWithoutBankFeed = useDisclosure();
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <HStack
        height={"60px"}
        borderBottom={"1px solid #a6a9b0"}
        padding={"15px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Heading
          display={"flex"}
          flex={1}
          padding-right="12px"
          _light={{
            color: "customColor.black7",
          }}
          fontSize={"0.9375rem"}
          lineHeight={"1.5rem"}
          fontWeight={"700"}
        >
          Add Bank Accounts
        </Heading>
        <Button
          h={["auto", "40px"]}
          fontSize={"13px"}
          bgColor="#3454D1"
          borderRadius={"3px"}
          borderWidth={"1px"}
          borderColor={"#3454d1!important"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            bgColor: "#3454D1",
          }}
          onClick={() => accountWithoutBankFeed.onOpen()}
        >
          Add without bank feed
        </Button>
      </HStack>
      <Container
        p={"0px"}
        maxW="778px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginTop={"20px"}
        border="1px solid #a6a9b0"
        borderRadius="md"
      >
        <Stack padding="20px" textAlign={"center"}>
          <Heading
            fontSize=".9975rem"
            fontWeight="bold"
            as="h2"
            size="md"
            _light={{
              color: "customColor.black7",
            }}
          >
            Find account
          </Heading>
          <Text
            fontSize={"15px"}
            _dark={{
              color: "customColor.dark.150",
            }}
            _light={{
              color: "customColor.black7",
            }}
          >
            Search for a bank, credit card or payment provider to connect to
            HRIS 360
          </Text>
        </Stack>
        <Divider orientation="horizontal" />
        <Container
          maxW="778px"
          pb="8px"
          marginX="auto"
          color={"rgb(0,10,30,.75)"}
          px="0px"
        >
          <HStack paddingTop="16px" paddingBottom={"16px"} marginLeft={"16px"}>
            <Stack display={"flex"} flex={1}>
              <InputGroup
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
              >
                <InputLeftElement>
                  <Icon as={FiSearch} />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search"
                  // padding={"7px 16px 7px 2px"}
                  borderRadius={"3px"}
                  border={"1px solid #a6a9b0"}
                  h={["auto", "40px"]}
                  _focusVisible={{
                    outlineColor: "grey",
                    outlineWidth: "3px",
                  }}
                  _hover={{
                    border: "1px solid #80858f",
                  }}
                />
              </InputGroup>
            </Stack>
            <Menu>
              <MenuButton
                as={Button}
                variant={"ghost"}
                leftIcon={<RiFilterFill />}
                alignItems={"center"}
                verticalAlign={"top"}
                lineHeight={"1rem"}
                minH={"40px"}
                minW={"40px"}
                padding={"11px 16px"}
                marginRight={"20px"}
                bg={"transparent"}
                borderRadius={"3px"}
                color={"#0073c0"}
                fontWeight={"700"}
                fontSize={"15px"}
                _hover={{
                  bg: "rgba(0,10,30,.05)",
                  color: "#0073c0",
                }}
              >
                Filter by country
              </MenuButton>
              <MenuList w={"298px"} padding={0} height="300px" overflowY="auto">
                <Stack borderBottom={"1px solid #ddd"} padding={"12px"}>
                  <Text
                    marginLeft={"4px"}
                    paddingRight={"8px"}
                    fontWeight={"700"}
                    fontSize={".9375rem"}
                    lineHeight={"1.5rem"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    Country
                  </Text>
                </Stack>

                <Stack borderBottom={"1px solid #ddd"} padding={"12px"}>
                  <InputGroup
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                    }}
                  >
                    <InputLeftElement>
                      <Icon as={FiSearch} />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder="Search"
                      // padding={"7px 16px 7px 2px"}
                      borderRadius={"3px"}
                      bg={"rgba(0,10,30,.05)"}
                      border={"none"}
                      h={["auto", "40px"]}
                      _focusVisible={{
                        outlineColor: "grey",
                        outlineWidth: "3px",
                      }}
                      _hover={{
                        border: "1px solid #80858f",
                      }}
                    />
                  </InputGroup>
                </Stack>
                {/* <Stack borderBottom={"1px solid #ddd"} padding={"12px"}>
                  </Stack> */}

                {countryData.map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"8px 20px"}
                      _hover={{
                        bg: "#f2f3f4",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      {/* <option key={i}   value={item.name}> */}
                      {item.name}
                      {/* </option> */}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>
          <Button
            borderRadius={"full"}
            bg={"#ddd"}
            marginLeft={"16px"}
            marginBottom={"20px"}
            _light={{ color: "customColor.customBlack" }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"0.8125rem"}
            lineHeight={"1.25rem"}
            paddingLeft={"12px"}
            paddingRight={"12px"}
            height={"32px"}
            _hover={{
              bg: "#a6a9b0",
            }}
          >
            <Text
              marginRight={"8px"}
              fontWeight={"700"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Country
            </Text>
            <Text
              fontWeight={"400"}
              textDecoration={"underline"}
              _light={{
                color: "customColor.black7",
              }}
            >
              Australia
            </Text>
          </Button>
        </Container>
      </Container>
      <Container
        p={"0px"}
        maxW="778px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        marginTop={"10px"}
        marginBottom={"50px"}
        border="1px solid #a6a9b0"
        borderRadius="md"
        borderBottom={"1px solid #a6a9b0"}
      >
        <Stack
          padding="16px"
          textAlign={"left"}
          borderBottom={"1px solid #a6a9b0"}
        >
          <Heading
            fontSize=".9975rem"
            fontWeight="bold"
            as="h2"
            size="md"
            _light={{
              color: "customColor.black7",
            }}
            marginLeft={"4px"}
          >
            Popular banks in Australia
          </Heading>
        </Stack>
        {[
          {
            name: "ANZ (AU)",
            onClick: () => navigate("/app/payroll/AddAccount"),
          },
          { name: "Commonwealth Bank CBA (AU)" },
          { name: "National Australia Bank NAB (AU)" },
          { name: "St George Bank (AU)" },
          { name: "Westpac (AU)" },
          { name: "Westpac (Australia) - Credit Card" },
        ].map((item, i) => {
          return (
            <Stack
              key={i}
              padding="16px"
              textAlign={"left"}
              borderBottom={"1px solid #a6a9b0"}
              _hover={{
                bg: "#f2f3f4",
              }}
            >
              <Text
                fontSize={"15px"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
                marginLeft={"4px"}
                onClick={item.onClick}
              >
                {item.name}
              </Text>
            </Stack>
          );
        })}
      </Container>
      <AccountWithoutBankFeed
        isOpen={accountWithoutBankFeed.isOpen}
        onClose={accountWithoutBankFeed.onClose}
      />
    </Stack>
  );
}
