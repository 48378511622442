import {
  Box,
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdDelete, MdEdit } from "react-icons/md";
import { NotificationGroup } from "../../../Api/NotificationGroup";
import CreateGroupModal from "../Modal/CreateGroup";
import { DeleteNotification } from "../Modal/DeleteModal";

export default function ManageNotificationGroup() {
  const form = useForm<NotificationGroup.NotificationGroup>({
    defaultValues: {
      employeeIds: [],
      name: "",
      id: undefined,
    },
  });
  const notificationGroup = useDisclosure();
  const [deleteId, setDeleteId] = useState<string>("");
  const deleteModal = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [notificationGroups, setNotificationGroups] = useState<any>([]);
  useEffect(() => {
    NotificationGroup.FetchNotificationGroups(
      {
        page: 1,
        limit: 100,
      },
      (data) => {
        setNotificationGroups(data?.data);
      },
      (error) => {}
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Stack
        bgColor="customColor.gray.400"
        p={"20px"}
        // h={"calc(100vh - 70px)"}
        // overflowY="auto"
      >
        <Container maxW="container.xl" p={0}>
          <Box
            justifyContent="center"
            alignItems="center"
            p="10px"
            borderBottom={"1px solid #d6dade"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <HStack justifyContent="space-between">
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight="bold"
                as="h2"
                size="md"
              >
                Notification Groups
              </Heading>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                h={"40px"}
                borderRadius={"3px"}
                border={"primary.950"}
                variant={"solid"}
                padding={"12px 16px"}
                //   marginRight={"20px"}
                textAlign={"center"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  notificationGroup.onOpen();
                  form.reset({
                    employeeIds: [],
                    name: "",
                  });
                }}
              >
                Create Group
              </Button>
            </HStack>
          </Box>
          <Stack>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              borderRadius="4px"
              bg={"#fff"}
              boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              borderWidth="1px"
              borderStyle={"solid"}
              borderColor="#f6f6f6"
              p={"0px"}
            >
              <Box w="100%" overflowX="auto">
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        maxW={"5%"}
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                        />
                      </Th>
                      {[
                        {
                          name: "Title",
                          value: "20%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Total Employees",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        // {
                        //   name: "Employees",
                        //   value: "20%",
                        //   paddingValue: "8px 26px 8px 15px",
                        // },
                        {
                          name: "Created At",
                          value: "20%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Updated At",
                          value: "20%",
                          paddingValue: "8px 26px 8px 15px",
                        },

                        {
                          name: "Edit",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Delete",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            padding={item.paddingValue}
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                            width={item.value}
                            textAlign={i === 1 ? "center" : "left"}
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {notificationGroups?.map((item: any, i: number) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                            />
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>{item?.name}</Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            textAlign="center"
                          >
                            <Text>{item?.employeeIds?.length ?? 0}</Text>
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>
                              {moment(item?.createdAt).format(
                                "DD/MM/YYYY hh:mm a"
                              )}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text>
                              {moment(item?.updatedAt).format(
                                "DD/MM/YYYY hh:mm a"
                              )}
                            </Text>

                            {/* <Text>{item?.status}</Text> */}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={() => {
                              form.reset({
                                employeeIds: item?.employeeIds,
                                name: item?.name,
                                id: item?.id,
                              });
                              notificationGroup.onOpen();
                            }}
                          >
                            <MdEdit />
                          </Td>

                          <Td
                            padding="15px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MdDelete />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
                <CreateGroupModal
                  isOpen={notificationGroup.isOpen}
                  onClose={notificationGroup.onClose}
                  setLastUpdated={setLastUpdated}
                />
                <DeleteNotification
                  isOpen={deleteModal.isOpen}
                  onClose={deleteModal.onClose}
                  //   selectedId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </FormProvider>
  );
}
