import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Divider,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { Moment } from "moment-timezone";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaSortDown } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { splitArrayIntoChunks } from "../../TimeSheet/utils";
import Submit from "./SubmitModal";
import { TimesheetTable } from "./TimeSheetTable";

export function Timesheet() {
  const startDate = moment().date(1);
  const endDate = moment().date(30);
  const [weeklyIndex, setWeeklyIndex] = useState(0);
  const [days, setDays] = useState<Array<Moment>>([]);
  const [splitedDays, setSplitedDays] = useState<Array<Array<Moment>>>([]);
  const navigate = useNavigate();
  useEffect(() => {
    let startDateMoment = moment(startDate, "YYYY-MM-DD");
    let endDateMoment = moment(endDate, "YYYY-MM-DD");
    let numberOfDays = endDateMoment.diff(startDateMoment, "days");
    let days = Array.from({ length: numberOfDays + 1 }).map((_, i) => {
      return moment(startDateMoment).add(i, "days");
    });
    setDays(days);
    setSplitedDays(splitArrayIntoChunks(days));
  }, []);

  const submitModal = useDisclosure();
  return (
    <Stack
      gap={0}
      minW={"980px"}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Box
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderBottom={"1px solid #cfd2d4"}
      >
        <Container
          h="76px"
          maxW={"100%"}
          padding={"16px 20px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink
                href="Timesheet"
                fontSize={"13px"}
                color={"primary.950"}
              >
                Timesheet
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href=""
                fontSize={"11px"}
                _light={{
                  color: "customColor.black6",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                padding={"0px 20px 0px 0px"}
              ></BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <HStack justifyContent={"space-between"}>
            <HStack gap={0} alignItems={"center"}>
              <Heading
                fontWeight={"bold"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize={"18px"}
                paddingRight={"16px"}
                margin={"0px 4px 0px 0px"}
              >
                {`Timesheets for 7 - 30 Jun 2024 `}
              </Heading>
              <Tag
                bgColor={"#FDD3A6"}
                _light={{
                  color: "customColor.black7",
                }}
                h={"20px"}
                borderRadius={"4px"}
                textAlign={"center"}
                fontSize={"13px"}
              >
                Draft
              </Tag>
            </HStack>
            <HStack>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                marginBottom={"5px"}
                onClick={() => {
                  submitModal.onOpen();
                }}
              >
                Submit
              </Button>
              <Submit
                isOpen={submitModal.isOpen}
                onClose={submitModal.onClose}
              />
              <Menu>
                <MenuButton
                  as={Button}
                  padding={"6px 10px"}
                  backgroundColor={"transparent"}
                  w={"40px"}
                  color={"black"}
                  display={{
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  }}
                  _hover={{
                    backgroundColor: "transparent",
                  }}
                  _active={{
                    backgroundColor: "transparent",
                  }}
                  borderRadius={"full"}
                  _focus={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Icon as={FiMoreVertical} fontSize={"20px"} />
                </MenuButton>
                <MenuList style={{ minWidth: "140px" }} padding={"12px 0px"}>
                  <MenuItem
                    padding={"8px 20px"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Delete Timesheet
                  </MenuItem>
                  <MenuItem
                    padding={"8px 20px"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Close
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
        </Container>
      </Box>

      <Container
        maxW={"container.xl"}
        padding={"40px 26px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <HStack gap={0} marginBottom={"12px"} justifyContent={"space-between"}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<FaSortDown fontSize={"20px"} />}
              padding={"11px 16px"}
              backgroundColor={"transparent"}
              _light={{
                color: "customColor.black7",
              }}
              display={{
                lg: "flex",
                md: "flex",
                sm: "flex",
                xs: "flex",
              }}
              h={"54px"}
              _hover={{
                backgroundColor: "rgba(0,10,30,.05)",
                _light: {
                  color: "customColor.black7",
                },
              }}
              _active={{
                backgroundColor: "rgba(0,10,30,.05)",
                _light: {
                  color: "customColor.black7",
                },
              }}
              fontSize={"21px"}
              _focus={{
                backgroundColor: "transparent",
              }}
            >
              Sat 1 - Fri 7 Jun
            </MenuButton>
            <MenuList style={{ minWidth: "175px" }} padding={"12px 0px"}>
              <MenuItem
                padding={"8px 20px"}
                fontSize={"13.44px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Sat 1 - Fri 7 Jun
              </MenuItem>
              <MenuItem
                padding={"8px 20px"}
                fontSize={"13.44px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Sat 8 - Fri 14 Jun
              </MenuItem>
              <MenuItem
                padding={"8px 20px"}
                fontSize={"13.44px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Sat 15 - Fri 21 Jun
              </MenuItem>
              <MenuItem
                padding={"8px 20px"}
                fontSize={"13.44px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Sat 22 - Fri 28 Jun
              </MenuItem>
              <MenuItem
                padding={"8px 20px"}
                fontSize={"13.44px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Sat 29 - sun 30 Jun
              </MenuItem>
            </MenuList>
          </Menu>
          <HStack gap={0}>
            <Button
              bg={"white"}
              leftIcon={<FaChevronLeft fontSize={"12px"} />}
              _hover={{
                bg: "none",
              }}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              borderLeftRadius={"4px"}
              padding={"12px 16px"}
              h={"32px"}
              textColor={"customColor.black7"}
              isDisabled={weeklyIndex === 0}
              onClick={() => {
                if (setWeeklyIndex) {
                  setWeeklyIndex((prevIndex: any) => {
                    const newIndex = prevIndex - 1;

                    return newIndex;
                  });
                }
              }}
            >
              Previous
            </Button>
            <Button
              bg={"white"}
              alignItems={"center"}
              rightIcon={<FaChevronRight fontSize={"12px"} />}
              _hover={{
                bg: "none",
              }}
              borderLeft={"none"}
              h={"32px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              padding={"12px 16px"}
              textColor={"#3454d1"}
              borderRightRadius={"4px"}
              isDisabled={weeklyIndex === splitedDays.length - 1}
              onClick={() => {
                if (setWeeklyIndex) {
                  setWeeklyIndex((prevIndex: any) => {
                    const newIndex = prevIndex + 1;

                    return newIndex;
                  });
                }
              }}
            >
              Next Week
            </Button>
          </HStack>
        </HStack>
        <TimesheetTable splitedDays={splitedDays} weeklyIndex={weeklyIndex} />
        <Stack marginTop={"32px"} w={"420px"}>
          {[
            {
              period: "Sat 1 - Fri 7",
              tagName: "Current Week",
              hours: "7h",
            },
            {
              period: "Sat 8 - Fri 14",

              hours: "0h",
            },
            {
              period: "Sat 15 - Fri 21",

              hours: "0h",
            },
            {
              period: "Sat 22 - Fri 28",

              hours: "0h",
            },
            {
              period: "Sat 29 - Sun 30",

              hours: "0h",
            },
          ].map((item, i) => {
            return (
              <Stack
                w={"420px"}
                key={i}
                direction={"row"}
                padding={"8px 26px 8px 15p"}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Text
                    fontSize={"15px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    {item.period}
                  </Text>
                  {item.tagName && (
                    <Tag
                      boxShadow={"inset 0 0 0 1px rgba(0,10,30,.5)"}
                      bg={"#fff"}
                      padding={"0 4px"}
                      borderRadius={"3px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      {item.tagName}
                    </Tag>
                  )}
                </Stack>

                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={"15px"}
                >
                  {item.hours}
                </Text>
              </Stack>
            );
          })}
          <Divider bg={"#A6A9B0"} margin={"12px 0px"} height={"1px"} />
          <HStack
            w={"420px"}
            direction={"row"}
            paddingTop={"8px"}
            justifyContent={"space-between"}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"18px"}
              fontWeight={"bold"}
            >
              {" "}
              Total
            </Text>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"21px"}
              fontWeight={"bold"}
            >
              {" "}
              7h
            </Text>
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
}
