import { axiosInstance } from "../../Payroll/Api/axiosInstance-hris";

declare module Notifications {
  enum NotificationType {
    START_END_SHIFT = "START_END_SHIFT",
    START_SHIFT = "START_SHIFT",
    END_SHIFT = "END_SHIFT",
    START_END_BREAK = "START_END_BREAK",
    START_END_SHIFT_BREAK = "START_END_SHIFT_BREAK",
    SHIFT_TRANSFER = "SHIFT_TRANSFER",
    TRANSFER_RESPONSE = "TRANSFER_RESPONSE",
  }
  type CreatePayload = {
    notify: keyof typeof NotificationType;
    employees: string[];
    locations: string[];
    reminders: number[];
    message: string;
    turnOn: boolean;
    title: string;
    sms: boolean;
    email: boolean;
    push: boolean;
  };

  type Notification = {
    id: string;
    notify: keyof typeof NotificationType;
    employees: string[];
    locations: string[];
    reminders: number[];
    message: string;
    turnOn: boolean;
    createdAt: number;
    title: string;
  };
}

module Notifications {
  const API_BASE_URL = "timesheet/api/notify";
  export function Create(
    data: CreatePayload,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(API_BASE_URL, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function Fetch(
    success: (response: Array<Notification>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(API_BASE_URL)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Update(
    id: string,
    data: Partial<CreatePayload>,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_BASE_URL}/${id}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function Delete(
    id: string,
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchById(
    id: string,
    success: (response: CreatePayload) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_BASE_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}

export { Notifications };
