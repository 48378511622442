import { Link, Stack, Text } from "@chakra-ui/react";
interface ReportsHeaderProps {
  name: string;
}
export default function ReportsHeader({ name }: ReportsHeaderProps) {
  return (
    <Stack gap={0} padding={"16px 0px"} margin={"0px 4px 0px 16px"}>
      <Link
        color={"primary.950"}
        fontWeight={"400;"}
        fontSize={"13px"}
        href={"/app/payroll/Reports"}
      >
        Reports
      </Link>
      <Text
        fontWeight={"bold"}
        _dark={{
          color: "customColor.dark.150",
        }}
        _light={{
          color: "customColor.black7",
        }}
        fontSize={"17px"}
        padding={"0px 12px 0px 0px"}
      >
        {name}
      </Text>
    </Stack>
  );
}
