import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { AccessControl } from "../../../../api/AccessControl";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";
import { EmploymentModal } from "../EmploymentModal";
import { LeaveModal } from "../LeaveModal";
import { PayTemplateModal } from "../PayTemplateModal";

interface LeaveProps {
  events: string;

  fetchLeaves: PayItemLeaveApi.LeaveById[];
  earningAdded: any[];
  deductionAdded: any[];
  reimbursementAdded: any[];
  statutoryAdded: any[];
  users: AccessControl.UserDetail[];
  employeesList: any[];
  payOptions: PayrollCalendar.Calendar[];
}
export function TableComponent({
  events,
  earningAdded,
  deductionAdded,
  employeesList,
  reimbursementAdded,
  statutoryAdded,
  fetchLeaves,
  users,
  payOptions,
}: LeaveProps) {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const employementModal = useDisclosure();
  const payTempModal = useDisclosure();
  const leaveModal = useDisclosure();

  const [checkedList, setCheckedList] = useState<Record<string, boolean>>({});
  const handleHeaderCheckboxChange = () => {
    setIsCheckedAll(!isCheckedAll);
    const newCheckedList: Record<string, boolean> = {};
    employeesList?.forEach((employee) => {
      newCheckedList[employee.id] = !isCheckedAll;
    });
    setCheckedList(newCheckedList);
  };

  const handleCheckboxChange = (id: string) => {
    const newCheckedList = { ...checkedList, [id]: !checkedList[id] };
    setCheckedList(newCheckedList);
    const allChecked = Object.values(newCheckedList).every(Boolean);
    setIsCheckedAll(allChecked);
  };

  const toasts = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const filteredEmployees = employeesList.filter((emp) => {
    const fullName =
      emp.firstName.toLowerCase() + " " + emp.lastName.toLowerCase();
    const uniqueIdString = String(emp.uniqueId);

    return (
      fullName.includes(searchQuery.toLowerCase()) ||
      emp.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      emp.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      uniqueIdString.includes(searchQuery)
    );
  });
  const selectedEmployeeIds =
    employeesList &&
    employeesList
      .filter((emp: any) => checkedList[emp.id])
      .map((emp: any) => emp.id);
  return (
    <Stack>
      <Container
        maxW={"container.lg"}
        padding={"0"}
        bg={"#fff"}
        boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
      >
        <Box
          overflowX="auto"
          p="0px"
          borderBottomWidth="1px"
          borderBottomColor={"borders.tableBorder"}
        >
          <Box p="4">
            <Input
              placeholder="Search employee"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          <TableContainer maxH="500px" overflowY={"auto"}>
            <Table variant="simple" w="100%">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  <Th
                    padding="8px 26px 8px 30px"
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                  >
                    <Checkbox
                      size="md"
                      borderColor="#adb5bd"
                      bgColor="white"
                      isChecked={isCheckedAll}
                      onChange={handleHeaderCheckboxChange}
                    />
                  </Th>
                  {[
                    {
                      name: "Employee ID",
                      paddingValue: "8px 26px 8px 15px",
                      display: true,
                    },
                    {
                      name: "First Name",
                      paddingValue: "8px 26px 8px 15px",
                      display: true,
                    },
                    {
                      name: "Last Name",
                      paddingValue: "8px 26px 8px 15px",
                      display: true,
                    },
                    {
                      name: "Gross Salary",
                      paddingValue: "8px 26px 8px 15px",
                      display: events === "PAY_TEMP",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        display={item.display ? "table-cell" : "none"}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody padding={0}>
                {filteredEmployees?.map((emp: any, i: any) => (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                        isChecked={checkedList[emp.id] || false}
                        onChange={() => handleCheckboxChange(emp.id)}
                        // Stop propagation here
                      />
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.uniqueId}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.firstName}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.lastName}
                    </Td>
                    {events === "PAY_TEMP" && (
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {emp?.payTemplate?.grossSalary}
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Stack padding={"20px 16px"} alignItems={"flex-end"}>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              if (selectedEmployeeIds.length === 0) {
                toasts({
                  title: "Please select atleast one employee",
                  status: "error",

                  isClosable: true,
                });
                return;
              }
              if (events === "EMPLOYMENT_DETAILS") {
                employementModal.onOpen();
              } else if (events === "PAY_TEMP") {
                payTempModal.onOpen();
              } else if (events === "LEAVE") {
                leaveModal.onOpen();
              }
            }}
          >
            Update
          </Button>
        </Stack>
      </Container>
      <EmploymentModal
        users={users}
        payOptions={payOptions}
        earningAdded={earningAdded}
        isOpen={employementModal.isOpen}
        onClose={employementModal.onClose}
        selectedEmployeeIds={selectedEmployeeIds}
      />
      <LeaveModal
        fetchLeaves={fetchLeaves}
        isOpen={leaveModal.isOpen}
        onClose={leaveModal.onClose}
        selectedEmployeeIds={selectedEmployeeIds}
        employeesList={employeesList}
      />

      <PayTemplateModal
        earningAdded={earningAdded}
        deductionAdded={deductionAdded}
        statutoryAdded={statutoryAdded}
        selectedEmployeeIds={selectedEmployeeIds}
        reimbursementAdded={reimbursementAdded}
        isOpen={payTempModal.isOpen}
        onClose={payTempModal.onClose}
      />
    </Stack>
  );
}
