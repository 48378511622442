// Header.tsx
import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { ChangeDateModal } from "./ChangePaymentDatePayrun";

interface HeaderProps {
  totalEarnings?: any;
  totalDeductions?: any;
  totalTaxs?: any;
  totalRetirementFunds?: any;
  totalNetPay?: any;
  status?: string;

  itemWidth?: string;
  paymentDate?: string;
  setPaymentDate: (selectedDate: string) => void;
}

export function Header({
  totalEarnings,
  totalRetirementFunds,
  totalTaxs,
  totalNetPay,
  totalDeductions,
  status,
  itemWidth,
  paymentDate,
  setPaymentDate,
}: HeaderProps) {
  const setDate = useDisclosure();
  const { id } = useParams();
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      border="1px solid #dadddf"
    >
      <Stack padding="20px 20px 40px 20px">
        <Container
          justifyContent="center"
          alignItems="center"
          maxWidth={itemWidth}
          padding="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          border="1px solid #d6dade"
          borderRadius="md"
        >
          <SimpleGrid columns={[3, 7]}>
            {[
              {
                name: "Earning",
                number: totalEarnings
                  ? totalEarnings
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#283C50",
              },
              {
                name: "Deductions",
                number: totalDeductions
                  ? totalDeductions
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#283C50",
              },
              {
                name: "Taxes",
                number: totalTaxs
                  ? totalTaxs.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#283C50",
              },
              {
                name: "Statuary Benefits",
                number: totalRetirementFunds
                  ? totalRetirementFunds
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#283C50",
              },
              {
                name: "Net Pay",
                number: totalNetPay
                  ? totalNetPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00",
                nameColor: "#999999",
                numberColor: "#283C50",
              },
              {
                name: "Status",
                number: status === "DRAFT" ? "Draft" : "Posted",
                nameColor: "#999999",
                numberColor: "#AAAAAA",
              },

              {
                name: "Payment Date",
                onClick: () => {
                  setDate.onOpen();
                },
                underLine: true,
                number: paymentDate
                  ? moment(paymentDate).format("D MMM YYYY")
                  : "--",

                nameColor: "#3454d1",
                numberColor: "#283C50",
              },
            ].map((item) => (
              <Stack
                key={item.name}
                borderRight="1px solid #e8ebee"
                padding="20px 10px 20px 10px"
                gap="0rem"
                flex={1}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                <Heading
                  fontSize="12px"
                  fontWeight="bold"
                  _light={{
                    color: item.nameColor,
                  }}
                  _dark={{
                    color: "customColor.cream",
                  }}
                  _hover={{
                    cursor: item.underLine ? "pointer" : "default",
                    textDecoration: item.underLine ? "underline" : "none",
                  }}
                  onClick={item.onClick}
                >
                  {item.name}
                </Heading>
                <Stack
                  padding={0}
                  gap="0.2rem"
                  direction={{ xs: "column", lg: "row" }}
                >
                  <Tooltip label={item.number} fontSize="md">
                    <Text
                      _light={{
                        color: item.numberColor,
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontWeight="bold"
                      fontSize="24px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {item.number === "Payment Date"
                        ? paymentDate
                        : item.number}
                    </Text>
                  </Tooltip>
                  {/* <Text
                    color="#AAAAAA"
                    fontWeight="400"
                    fontSize="13px"
                    whiteSpace="nowrap"
                    padding={0}
                  >
                    {item.decimal}
                  </Text> */}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Container>
      </Stack>
      <ChangeDateModal
        isOpen={setDate.isOpen}
        onClose={setDate.onClose}
        setPaymentDate={setPaymentDate}
        paymentDate={paymentDate}
        payslipId={id}
      />
    </Stack>
  );
}
