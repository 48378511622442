import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { ChevronRightIcon } from "@chakra-ui/icons";

import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { BusinessAgreementApi } from "../../Api/BusinessAgreement";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}

export default function Agreement({ setSelectedTab, selectedTab }: TabProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const { currentBusinessId } = useBusinessContext();
  const form = useForm<BusinessAgreementApi.BusinessAgreementData>({
    defaultValues: {
      contractStartDate: "",
      contractEndDate: "",
      clientReference: "",
    },
  });

  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    if (currentBusinessId) {
      BusinessAgreementApi.GetBusinessAgreements(
        currentBusinessId,
        (data) => {
          form.reset(data);
        },
        (error) => {
          toast({
            title: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, [currentBusinessId, reset, toast]);

  const [duration, setDuration] = useState<string>("");
  const calculateDuration = (start: any, end: any) => {
    if (!start || !end) return;

    const startDate = moment(start);
    const endDate = moment(end);

    const duration = moment.duration(endDate.diff(startDate));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    setDuration(`${years} years, ${months} months, and ${days} days`);
  };
  useEffect(() => {
    const { contractStartDate, contractEndDate } = form.getValues();
    calculateDuration(contractStartDate, contractEndDate);
  }, [form.watch("contractStartDate"), form.watch("contractEndDate")]);
  return (
    <Stack
      marginTop={"15px"}
      p="0px"
      // maxW="940px"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      // border="1px solid #d6dade"
      // borderRadius="md"
      marginBottom={"1.25rem"}
      border={"1px solid #f6f6f6"}
      borderRadius="4px"
      boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
      >
        <HStack gap={0}>
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            _light={{
              color: "customColor.black7",
            }}
            fontWeight="bold"
            padding="20px"
            as="h2"
            size="md"
            flex={1}
          >
            Contract Information
          </Heading>
          <Link
            color="primary.950"
            fontSize=" var(--x-font-size-small, .8125rem"
            paddingBottom="1px"
            href="/app/payroll/organisationSettings"
          >
            Organisation Settings <Icon as={ChevronRightIcon} />
          </Link>
        </HStack>

        <Divider orientation="horizontal" />

        <Container marginBottom={"25px"}>
          <HStack>
            <Controller
              name="contractStartDate"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Contract Start Date is required",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  label="Contract Start Date "
                  labelProps={{
                    fontSize: ".845rem",
                    marginTop: "25px",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "date",
                    padding: "12px 15px",
                    h: "38px",
                    borderTopRadius: "3px",
                    alignContent: "center",
                  }}
                  placeholder=""
                />
              )}
            />
            <Controller
              name="contractEndDate"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Contract End Date is required",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  label="Contract End Date "
                  labelProps={{
                    fontSize: ".845rem",
                    marginTop: "25px",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "date",
                    padding: "12px 15px",
                    borderTopRadius: "3px",
                    alignContent: "center",
                  }}
                  placeholder=""
                />
              )}
            />
          </HStack>
          <HStack>
            <Text
              fontWeight={"700"}
              fontSize={"0.8125rem"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.light",
              }}
              marginTop={"25px"}
            >
              Duration:
            </Text>
            <Text
              fontWeight={"400"}
              fontSize={"0.8125rem"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.light",
              }}
              marginTop={"25px"}
            >
              {duration}
            </Text>
          </HStack>
          <Controller
            name="clientReference"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Contract Reference Series is required",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                label="Contract Reference Series "
                labelProps={{
                  fontSize: ".845rem",
                  marginTop: "25px",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  padding: "12px 15px",
                  h: "38px",
                  borderTopRadius: "3px",
                  alignContent: "center",
                }}
                placeholder=""
              />
            )}
          />
        </Container>
        <Stack
          alignItems={"flex-end"}
          marginBottom={"15px"}
          marginRight={"15px"}
        >
          <ButtonGroup
            borderRadius="4px"
            fontSize="var(--x-font-size-small, .8125rem)"
          >
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    BusinessAgreementApi.AddBusinessAgreements(
                      data,
                      (success) => {
                        toast({
                          title: "Business Agreement Added",

                          status: "success",
                        });
                        setIsLoading(false);
                        form.reset(success);
                        setSelectedTab(selectedTab + 1);
                      },
                      (error) => {
                        toast({
                          title: "Business Agreement Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              {isLoading ? "Saving..." : " Save and Next"}
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    BusinessAgreementApi.AddBusinessAgreements(
                      data,
                      (success) => {
                        toast({
                          title: "Business Agreement Added",

                          status: "success",
                        });
                        setIsLoading(false);
                      },
                      (error) => {
                        toast({
                          title: "Business Agreement Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  },
                  (error) => {
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              {isLoading ? "Saving..." : " Save "}
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
    </Stack>
  );
}
