export function flattenNestedObject(obj: any, parentKey = ""): any {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      const nested = flattenNestedObject(obj[key], newKey);
      return {...acc, ...nested};
    }
    return {...acc, [newKey]: obj[key]};
  }, {});
}
