import {
  Box,
  HStack,
  Icon,
  Link,
  Stack,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaCarAlt } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
export default function Tabular() {
  return (
    <Stack
      h="calc(100vh - 155px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      padding={"20px"}
    >
      <Stack
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius="4px"
        bg={"#fff"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderWidth="1px"
        borderStyle={"solid"}
        borderColor="#f6f6f6"
        padding={"0"}
      >
        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {[
                  {
                    name: "Date",
                    value: "20%",
                    paddingValue: "8px 26px 8px 30px",
                  },
                  {
                    name: "Start Time",
                    value: "25%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Staff",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Shift Type",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Allowances",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Trackers",
                    value: "15%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {[
                {
                  date: "Mon,22 Apr 2024",
                  startTime: "09:00 AM",
                  staff: "Vacant shift",
                  shiftType: "Night Shift",
                  allowance: "Expense",
                },
                {
                  date: "Mon,22 Apr 2024",
                  startTime: "09:00 AM",
                  staff: "Irsam",
                  shiftType: "Sleepover",
                  allowance: "Expense",
                },
                {
                  date: "Mon,22 Apr 2024",
                  startTime: "09:00 AM",
                  staff: "Danish",
                  shiftType: "Transport",
                  allowance: "Expense",
                },
              ].map((item, i) => (
                <Tr
                  key={i}
                  _hover={{
                    bgColor: "#f2f3f8",
                    cursor: "pointer",
                  }}
                >
                  <Td
                    flexWrap={"wrap"}
                    padding="15px 15px 15px 30px"
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    fontWeight={500}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Link color={"primary.950"}>{item.date}</Link>
                  </Td>
                  <Td
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <HStack>
                      <Text>{item.startTime}</Text>
                      <Text>{item.date}</Text>
                    </HStack>
                  </Td>
                  <Td
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {item.staff}
                  </Td>

                  <Td
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    {item.shiftType}
                  </Td>
                  <Td
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    padding="15px"
                    fontWeight={500}
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <Tag size={"md"} variant="subtle" colorScheme="cyan">
                      <TagLabel>{item.allowance}</TagLabel>
                    </Tag>
                  </Td>

                  <Td
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    padding="15px"
                    fontSize="13.44px"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    lineHeight={1.5}
                    borderTopWidth="1px"
                    borderBottomWidth="0px"
                    borderTopColor={"borders.tableBorder"}
                  >
                    <HStack>
                      <Icon
                        borderRadius="50%"
                        borderStyle={"solid"}
                        borderWidth={"1px"}
                        borderColor={"borders.tableBorder"}
                        bg={"#fff"}
                        w="25px"
                        h="25px"
                        px="5px"
                        py="5px"
                        as={FaCarAlt}
                        fontSize="22px"
                        color="#BDBDBD"
                        _hover={{
                          cursor: "not-allowed",
                          // color: "#3454d1",
                        }}
                      />
                      <Icon
                        borderRadius="50%"
                        borderStyle={"solid"}
                        borderWidth={"1px"}
                        borderColor={"borders.tableBorder"}
                        bg={"#fff"}
                        w="25px"
                        h="25px"
                        px="5px"
                        py="5px"
                        as={FaDollarSign}
                        fontSize="22px"
                        color="#BDBDBD"
                        _hover={{
                          cursor: "not-allowed",
                          // color: "#3454d1",
                        }}
                      />
                      <Icon
                        borderRadius="50%"
                        borderStyle={"solid"}
                        borderWidth={"1px"}
                        borderColor={"borders.tableBorder"}
                        bg={"#fff"}
                        w="25px"
                        h="25px"
                        px="5px"
                        py="5px"
                        as={IoDocumentText}
                        fontSize="22px"
                        color="#BDBDBD"
                        _hover={{
                          cursor: "not-allowed",
                          // color: "#3454d1",
                        }}
                      />
                      {/* <Button
                        as={IconButton}
                        icon={<FaCarAlt size="16px" color="#BDBDBD" />}
                        colorScheme="primary"
                        variant="outline"
                        size="xs"
                        w="35px"
                        h="35px"
                        display="flex"
                       
                        
                        borderRadius="50%"
                        borderColor={"gray.400"}
                        _hover={{
                          bg: "none",
                          cursor: "not-allowed",
                        }}
                      ></Button> */}
                      {/* <Button
                        as={IconButton}
                        icon={<FaDollarSign size="16px" color="#BDBDBD" />}
                        colorScheme="primary"
                        variant="outline"
                        size="xs"
                        w="35px"
                        h="35px"
                        display="flex"
                       
                        
                        borderRadius="50%"
                        borderColor={"gray.400"}
                        _hover={{
                          bg: "none",
                          cursor: "not-allowed",
                        }}
                      ></Button> */}
                      {/* <Button
                        as={IconButton}
                        icon={<IoDocumentText size="16px" color="#BDBDBD" />}
                        colorScheme="primary"
                        variant="outline"
                        size="xs"
                        w="35px"
                        h="35px"
                        display="flex"
                       
                        
                        borderRadius="50%"
                        borderColor={"gray.400"}
                        _hover={{
                          bg: "none",
                          cursor: "not-allowed",
                        }}
                      ></Button> */}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </Stack>
  );
}
