import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { UploadAttachments } from "../../../../../Api/UploadAttachments";

interface DeleteDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  empId: string;
  fileName: string;
  GetAllDocuments: Array<UploadAttachments.Document>;
  setGetAllDocuments: (value: Array<UploadAttachments.Document>) => void;
  setLastUpdated: (value: number) => void;
}
export function DeleteDocumentModal({
  isOpen,
  onClose,
  GetAllDocuments,
  empId,
  fileName,
  id,
  setGetAllDocuments,
  setLastUpdated,
}: DeleteDocumentModalProps) {
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"350px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {" "}
            Are you sure you want to delete this
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => {
              UploadAttachments.DeleteDocument(
                id,
                empId,
                fileName,
                () => {
                  setGetAllDocuments(
                    GetAllDocuments.filter((d) => d.id !== id)
                  );
                  toast({
                    title: "Document deleted successfully",
                    status: "success",
                  });
                  setLastUpdated(Date.now());
                  onClose();
                },
                () => {
                  toast({
                    title: "Error deleting document",
                    status: "error",
                  });
                  onClose();
                }
              );
            }}
          >
            Delete
          </Button>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
