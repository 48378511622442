import {
  Button,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { PiBagFill } from "react-icons/pi";
import AddTimesheet from "./AddTimesheet";

interface TimesheetTableProps {
  splitedDays: any;
  weeklyIndex: any;
}
export function TimesheetTable({
  splitedDays,
  weeklyIndex,
}: TimesheetTableProps) {
  const addTimesheet = useDisclosure();
  const form = useForm({
    defaultValues: {
      id: "",
      notes: "",
      work: [
        {
          timeEntry: "Work",
          startTime: "",
          endTime: "",
          duration: "",
        },
      ],
    },
  });

  return (
    <FormProvider {...form}>
      <Stack paddingTop={"20px"} paddingBottom={"20px"} gap={0}>
        <Stack
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          margin={"0px 0px 30px"}
          padding={"15px 0px 20px"}
          border="1px solid #f6f6f6"
          borderRadius="4px"
          boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
        >
          <HStack gap={0}>
            <Stack padding={"1px 16px 1px 12px"} flex={1}>
              <Text
                fontSize={"13.44px"}
                fontWeight={"700"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Date
              </Text>
            </Stack>
            <Stack padding={0} flex={14} direction={"row"} gap={0}>
              {/* {days.map((day, index) => ( */}
              {splitedDays?.[weeklyIndex]?.map((day: any, index: any) => (
                <Stack key={index} flex={1} padding={"1px 16px"}>
                  <Text
                    fontSize={"13.44px"}
                    fontWeight={"700"}
                    textAlign={"center"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    {day.format("ddd DD")}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </HStack>
          <Divider bg={"#A6A9B0"} margin={"12px 0px"} height={"1px"} />
          <HStack gap={0}>
            <Stack padding={"12px 0px 20px 12px"} flex={1}>
              <Text
                fontSize={"13.44px"}
                fontWeight={"700"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Hours
              </Text>
            </Stack>
            <Stack padding={0} flex={14} direction={"row"} gap={0}>
              {splitedDays?.[weeklyIndex]?.map((day: any, index: any) => (
                <Stack
                  key={index}
                  flex={1}
                  padding={""}
                  onClick={() => {
                    addTimesheet.onOpen();
                  }}
                >
                  <Button
                    variant={"ghost"}
                    padding={"1px 16px"}
                    color={"primary.950"}
                  >
                    Edit
                  </Button>
                  <Stack
                    border={".5px solid #ccced2"}
                    bg={"#f2f3f4"}
                    minH={"96px"}
                    _hover={{
                      border: ".5px solid black",
                    }}
                    margin={"0px 2px 4px"}
                    padding={"12px"}
                    borderRadius={"3px"}
                  >
                    <Stack alignItems={"center"} direction={"row"}>
                      <Stack
                        bg={"#A6D0EC"}
                        h={"24px"}
                        w={"24px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Icon as={PiBagFill} />
                      </Stack>
                      <Text
                        fontWeight={"400"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        fontSize={"13.44px"}
                      >
                        Work
                      </Text>
                    </Stack>
                    <Text
                      fontWeight={"400"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={"15px"}
                    >
                      8:00 am - 3:00 pm
                    </Text>
                    <Text
                      fontWeight={"400"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={"11px"}
                    >
                      7h
                    </Text>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </HStack>
          <Divider bg={"#A6A9B0"} margin={"12px 0px"} height={"1px"} />
          <HStack gap={0}>
            <Stack padding={"10px 0px 10px 12px"} flex={1}>
              <Text
                fontSize={"13.44px"}
                fontWeight={"700"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Total
              </Text>
            </Stack>
            <Stack padding={0} flex={14} direction={"row"} gap={0}>
              {splitedDays?.[weeklyIndex]?.map((day: any, index: any) => (
                <Stack key={index} flex={1} padding={"10px 0px"}>
                  <Text
                    fontSize={"17px"}
                    fontWeight={"700"}
                    textAlign={"center"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    {index === 0 ? "7h" : "0h"}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </HStack>
        </Stack>
        <AddTimesheet
          isOpen={addTimesheet.isOpen}
          onClose={addTimesheet.onClose}
        />
      </Stack>
    </FormProvider>
  );
}
