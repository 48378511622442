import {
  Box,
  Button,
  Checkbox,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { IoIosAddCircle, IoIosCloseCircleOutline } from "react-icons/io";

import moment from "moment";
import { CustomInput } from "../../../../Common/CustomInput";
import { ChartOfAccounts } from "../../../Api/ChartOfAccounts";
import { TaxFinancialYear } from "../../../Api/TaxFinancialYear";

interface AddTaxSlabsProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedId: string | undefined;
}

export function AddTaxSlabs({
  isOpen,
  onClose,
  setLastUpdated,
  selectedId,
}: AddTaxSlabsProps) {
  const FormTaxSlab = useFormContext<TaxFinancialYear.AddNewTax>();
  const [chartOfAccountEarning, setChartOfAccountEarning] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setChartOfAccountEarning(res);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      },
      "LIABILITY"
    );
  }, []);

  const handleStartDateChange = (startDate: string) => {
    const endDate = moment(startDate)
      .add(1, "year")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    FormTaxSlab.setValue("endDate", endDate);
  };

  const taxBracketsComponent = useFieldArray({
    control: FormTaxSlab.control,
    name: "taxBrackets",
  });

  const toast = useToast();

  const handleAddTaxBracket = () => {
    taxBracketsComponent.append({
      minimum: undefined,
      maximum: undefined,
      percentage: undefined,
      isHighestSlab: false,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        // _light={{
        //   color: "customColor.black2",
        // }}
        // _dark={{
        //   color: "customColor.white",
        // }}
        minW={"550px"}
      >
        <ModalHeader
          padding={"12px 15px"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"18px"}
          lineHeight={"1.75rem"}
          fontWeight={"bold"}
        >
          {selectedId ? "Edit" : "Add"} Tax Slabs
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          borderTop={"1px solid #dfe8f6"}
          borderBottom={"1px solid #dfe8f6"}
        >
          <Stack direction={"row"} paddingBottom={"15px"} alignItems={"center"}>
            <Stack>
              <Controller
                name="name"
                control={FormTaxSlab.control}
                rules={{
                  required: {
                    value: true,
                    message: "Tax Slab Display name is required",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      placeholder: "",
                      type: "text",
                      h: "40px",
                      w: "200px",
                      fontSize: "13px",

                      // borderLeft: "1px solid #b5b8bb",
                      // borderRight: "1px solid #e8eaec",
                      // borderTop: "1px solid #bdbfc3",
                      // borderBottom: "1px solid  #e2e4e7",
                      marginBottom: "15px",
                      borderRadius: "none",
                      padding: "12px 15px",
                      bgColor: "#fff",
                    }}
                    label="Tax Slab Name"
                    labelProps={{
                      fontSize: "12px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontWeight: "600",
                    }}
                  />
                )}
              />
              <Stack
                direction={"row"}
                paddingBottom={"15px"}
                alignItems={"center"}
              >
                <Stack>
                  <Controller
                    name="startDate"
                    control={FormTaxSlab.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Tax Slab Start Date is required",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          placeholder: "",
                          type: "date",
                          h: "40px",
                          w: "200px",
                          fontSize: "13px",

                          // borderLeft: "1px solid #b5b8bb",
                          // borderRight: "1px solid #e8eaec",
                          // borderTop: "1px solid #bdbfc3",
                          // borderBottom: "1px solid  #e2e4e7",
                          borderRadius: "none",
                          padding: "12px 15px",
                          bgColor: "#fff",
                          onChange: (e) => {
                            handleStartDateChange(e.target.value);
                            field.onChange(e);
                          },
                        }}
                        label="Start Date"
                        labelProps={{
                          fontSize: "12px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontWeight: "600",
                        }}
                      />
                    )}
                  />
                </Stack>
                <Text marginTop={"25px"}>~</Text>
                <Stack>
                  <Controller
                    name="endDate"
                    control={FormTaxSlab.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Tax Slab End Date is required",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          placeholder: "",
                          type: "date",
                          h: "40px",
                          w: "200px",
                          fontSize: "13px",

                          // borderLeft: "1px solid #b5b8bb",
                          // borderRight: "1px solid #e8eaec",
                          // borderTop: "1px solid #bdbfc3",
                          // borderBottom: "1px solid  #e2e4e7",
                          borderRadius: "none",
                          padding: "12px 15px",
                          bgColor: "#fff",
                          isDisabled: true,
                        }}
                        label="End Date"
                        labelProps={{
                          fontSize: "12px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontWeight: "600",
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>

              <Stack width={"200px"}>
                <CustomInput
                  withValidation
                  input={{
                    placeholder: "",
                    type: "text",
                    h: "40px",
                    w: "200px",
                    fontSize: "13px",
                    isDisabled: true,

                    value: chartOfAccountEarning.find(
                      (item) =>
                        item.id === FormTaxSlab.getValues("chartOfAccountId")
                    )?.name,

                    borderRadius: "none",
                    padding: "12px 15px",
                    bgColor: "#fff",
                  }}
                  label="Chart of Account"
                  labelProps={{
                    fontSize: "12px",
                    _light: {
                      color: "customColor.black7",
                    },
                    fontWeight: "600",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            gap={0}
            paddingTop={"15px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontWeight="bold"
              fontSize="12px"
              margin={"0px 12px 8px 0px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Tax Brackets
            </Text>
            <Stack>
              {!FormTaxSlab.watch(
                `taxBrackets.${
                  taxBracketsComponent.fields.length - 1
                }.isHighestSlab`
              ) && (
                <Icon
                  as={IoIosAddCircle}
                  margin={"0px 12px 8px 0px"}
                  color={"#3454D1"}
                  h={"30px"}
                  w={"30px"}
                  onClick={handleAddTaxBracket}
                  _hover={{
                    color: "#3454D1",
                  }}
                />
              )}
            </Stack>
          </Stack>

          <Stack direction="column" spacing={2}>
            {taxBracketsComponent.fields.map((field, index) => (
              <Stack
                direction="row"
                key={field.id}
                alignItems="center"
                border={"1px solid #dfe8f6"}
              >
                <Stack
                  flex={1}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  padding={"5px 10px 10px 10px"}
                >
                  <Stack alignItems={"center"}>
                    <Controller
                      name={`taxBrackets.${index}.minimum`}
                      control={FormTaxSlab.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Minimum is required",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            placeholder: "",
                            type: "number",
                            h: "25px",
                            w: "118.6px",
                            fontSize: "13px",

                            borderRadius: "none",
                            padding: "12px 15px",
                            bgColor: "#fff",
                            onChange: (e) => {
                              field.onChange(Number(e.target.value));
                            },
                          }}
                          label="Minimum"
                          labelProps={{
                            fontSize: "12px",
                            _light: {
                              color: "customColor.black7",
                            },
                            mb: 0,
                            fontWeight: "600",
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Text marginTop={"17px"} fontWeight={"600"}>
                    -
                  </Text>
                  {!FormTaxSlab.watch(`taxBrackets.${index}.isHighestSlab`) && (
                    <Stack>
                      <Controller
                        name={`taxBrackets.${index}.maximum`}
                        control={FormTaxSlab.control}
                        rules={{
                          required: {
                            value: true,
                            message: "Maximum is required",
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              placeholder: "",
                              type: "number",
                              h: "25px",
                              w: "118.6px",
                              fontSize: "13px",

                              borderRadius: "none",
                              padding: "12px 15px",
                              bgColor: "#fff",
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                            }}
                            label="Maximum"
                            labelProps={{
                              fontSize: "12px",
                              _light: {
                                color: "customColor.black7",
                              },
                              mb: 0,
                              fontWeight: "600",
                            }}
                          />
                        )}
                      />
                    </Stack>
                  )}

                  {taxBracketsComponent.fields.length - 1 === index && (
                    <Stack marginTop={"17px"}>
                      <Controller
                        name={`taxBrackets.${index}.isHighestSlab`}
                        control={FormTaxSlab.control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            isChecked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              FormTaxSlab.setValue(
                                `taxBrackets.${index}.maximum`,
                                undefined
                              );
                            }}
                          >
                            <Text
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight={"600"}
                              fontSize={"0.8125rem"}
                            >
                              Highest Slab
                            </Text>
                          </Checkbox>
                        )}
                      />
                    </Stack>
                  )}
                </Stack>
                <Stack>
                  <Controller
                    name={`taxBrackets.${index}.percentage`}
                    control={FormTaxSlab.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          placeholder: "",
                          type: "number",
                          h: "25px",
                          w: "70px",
                          fontSize: "13px",

                          borderRadius: "none",
                          padding: "12px 15px",
                          bgColor: "#fff",
                          onChange: (e) => {
                            field.onChange(Number(e.target.value));
                          },
                        }}
                        label="Percentage"
                        labelProps={{
                          fontSize: "12px",
                          _light: {
                            color: "customColor.black7",
                          },
                          mb: 0,
                          fontWeight: "600",
                        }}
                      />
                    )}
                  />
                </Stack>

                {taxBracketsComponent.fields.length > 1 && (
                  // <Button
                  //   variant={"ghost"}
                  //   fontSize={"13px"}
                  //   _light={{
                  //     color: "customColor.black",
                  //   }}
                  //   _dark={{
                  //     color: "customColor.white",
                  //   }}
                  //   _hover={{
                  //     color: "#c00",
                  //     bg: "transparent",
                  //   }}
                  //   alignItems={"center"}
                  //   marginTop={"15px"}
                  //   padding={"0px"}
                  //   onClick={() => {
                  //     taxBracketsComponent.remove(index);
                  //   }}
                  // >
                  //   X
                  // </Button>
                  <Icon
                    fontSize={"20px"}
                    as={IoIosCloseCircleOutline}
                    color={"grey"}
                    marginRight={"10px"}
                    _hover={{
                      color: "red.500",
                    }}
                    onClick={() => {
                      taxBracketsComponent.remove(index);
                    }}
                  />
                )}
              </Stack>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Box flex={1}>
            {selectedId && (
              <Button
                variant="solid"
                boxShadow={"none"}
                h={"40px"}
                w={"102.6px"}
                fontSize={"12px"}
                borderRadius={"3px"}
                padding={"6px 18px 5px"}
                isLoading={isLoading}
                colorScheme={
                  FormTaxSlab.getValues("isActivated") ? "red" : "green"
                }
                onClick={() => {
                  setIsLoading(true);
                  const isActivated = FormTaxSlab.getValues("isActivated");
                  TaxFinancialYear.UpdateStatus(
                    selectedId,
                    isActivated ? false : true,
                    (success) => {
                      toast({
                        title: "Tax Financial Year Updated",
                        status: "success",
                      });
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: "Tax Financial Year Failed",
                        description: err,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                }}
              >
                {FormTaxSlab.getValues("isActivated")
                  ? "Deactivate"
                  : "Activate"}
              </Button>
            )}
          </Box>

          <Fragment>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              marginRight={"8px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                FormTaxSlab.handleSubmit(
                  (data) => {
                    setIsLoading(true);
                    if (selectedId) {
                      TaxFinancialYear.UpdateTaxFinancialYear(
                        selectedId,
                        data,
                        (success) => {
                          toast({
                            title: "Tax Financial Year Updated",
                            status: "success",
                          });
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Tax Financial Year Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      TaxFinancialYear.AddTaxFinancialYear(
                        data,
                        (success) => {
                          toast({
                            title: "Tax Financial Year Added",
                            status: "success",
                          });
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Tax Financial Year Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  },
                  (error) => {
                    toast({
                      title: "Failed",
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                )(e);
              }}
            >
              {selectedId ? "Update" : "Save"}
            </Button>
            <Button
              onClick={onClose}
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Cancel
            </Button>
          </Fragment>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
