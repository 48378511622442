import { HStack, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

interface PaginationProps {
  totalCount: number;
}

export default function PaginationComponent({ totalCount }: PaginationProps) {
  const form = useFormContext();
  const totalLimit = form.getValues("pagination.limit");
  const totalPages = Math.ceil(totalCount / totalLimit);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const renderPageNumbers = () => {
    const pageButtons = [];
    const pageNumber = form.getValues("pagination.page");

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => form.setValue("pagination.page", i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => form.setValue("pagination.page", 1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(totalPages - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => form.setValue("pagination.page", i)}
          />
        );
      }

      if (pageNumber < totalPages - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={totalPages}
          aria-label={`Page ${totalPages}`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          backgroundColor={pageNumber === totalPages ? "#3454D1" : "#fff"}
          color={pageNumber === totalPages ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === totalPages ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{totalPages}</Text>}
          onClick={() => form.setValue("pagination.page", totalPages)}
        />
      );
    }

    return pageButtons;
  };

  return (
    <HStack
      marginTop={"9.35px"}
      paddingBottom={"25px"}
      fontSize={"13px"}
      _light={{
        color: "customColor.black2",
      }}
      _dark={{
        color: "customColor.white",
      }}
      justifyContent={"space-between"}
    >
      <Text
        fontSize={"14.4px"}
        _light={{
          color: "customColor.black7",
        }}
      >
        Showing {totalLimit * (form.getValues("pagination.page") - 1) + 1} to{" "}
        {Math.min(
          totalCount,
          totalLimit + totalLimit * (form.getValues("pagination.page") - 1)
        )}{" "}
        of {totalCount}
      </Text>
      <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
        <IconButton
          aria-label="back"
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          backgroundColor="#fff"
          _hover={{
            backgroundColor: "#3454D1",
            color: "white",
          }}
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
          icon={
            <FaArrowLeft
              size={15}
              color={
                isHovered2
                  ? "#FFFFFF"
                  : form.getValues("pagination.page") <= 1
                  ? "#828ea0"
                  : "#283c50"
              }
            />
          }
          isDisabled={form.getValues("pagination.page") <= 1}
          onClick={() => {
            const currentPage = form.getValues("pagination.page");
            if (currentPage > 1) {
              form.setValue("pagination.page", currentPage - 1);
            }
          }}
        />
        <HStack spacing="13px">{renderPageNumbers()}</HStack>
        <IconButton
          aria-label="right"
          backgroundColor="#fff"
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          _hover={{
            backgroundColor: "#3454D1",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          icon={
            <FaArrowRight
              size={15}
              color={
                isHovered
                  ? "#FFFFFF"
                  : form.getValues("pagination.page") >= totalPages
                  ? "#828ea0"
                  : "#283c50"
              }
            />
          }
          isDisabled={form.getValues("pagination.page") >= totalPages}
          onClick={() => {
            const currentPage = form.getValues("pagination.page");
            if (currentPage < totalPages) {
              form.setValue("pagination.page", currentPage + 1);
            }
          }}
        />
      </HStack>
    </HStack>
  );
}
