import { Stack } from "@chakra-ui/react";
import { Employees } from "../../Api/Employees";
import { BankDetails } from "./MyDetail/BankDetails";
import { ContactDetail } from "./MyDetail/ContactDetail";
import { DependentsRecord } from "./MyDetail/DependentsRecord";
import { DocumentHistory } from "./MyDetail/DocumentHistory";
import { EducationHistory } from "./MyDetail/EducationHistory";
import { EmergencyContact } from "./MyDetail/EmergencyContact";
import { Employment } from "./MyDetail/Employment";
import { NextToKinRecord } from "./MyDetail/NextToKinRecord";
import { PersonalDetail } from "./MyDetail/PersonalDetail";
import { PreviousExperience } from "./MyDetail/PreviousExperience";
import { PreviousReference } from "./MyDetail/PreviousReference";

interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function MyDetails({ employeeData }: DetailsProps) {
  return (
    <Stack padding={"24px"}>
      <Stack>
        <Stack flex={2} gap={0}>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <PersonalDetail employeeData={employeeData} />
            <Employment employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <ContactDetail employeeData={employeeData} />
            <BankDetails bankDetailsData={employeeData} />
          </Stack>
          {/* <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          ></Stack> */}
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <EmergencyContact employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <EducationHistory employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <PreviousExperience employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <PreviousReference employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <NextToKinRecord employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <DependentsRecord employeeData={employeeData} />
          </Stack>
          <Stack
            spacing={10}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <DocumentHistory employeeData={employeeData} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
