import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";

interface ContactProp {
  contactDetailsRef: React.RefObject<HTMLDivElement>;
}
export function ContactDetail({ contactDetailsRef }: ContactProp) {
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [faxNumber, setFaxNumber] = useState(false);
  const [directDialNumber, setDirectDialNumber] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(false);

  return (
    <Stack
      ref={contactDetailsRef}
      _dark={{ bgColor: "customColor.dark.50" }}
      _light={{ bgColor: "customColor.white" }}
      paddingBottom={"30px"}
      borderTop={"1px solid #e5e7eb"}
      borderRight={"1px solid #e5e7eb"}
      borderLeft={"1px solid #e5e7eb"}
      borderBottom={"2px solid #e5e7eb"}
    >
      <Container maxW={"450px"}>
        <Text
          padding={"16px 0px"}
          margin={"14px 0px"}
          fontSize={"17px"}
          color={"customColor.black7"}
          fontWeight={"700"}
        >
          Contact Details
        </Text>
        <Stack spacing={"25px"}>
          <CustomInput
            // validationMessage={error?.message}
            withValidation
            // ref={field.ref}
            input={{
              // ...field,

              // isInvalid: error !== undefined,
              w: "100%",

              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              fontSize: ".845rem",
              variant: "outline",
              type: "text",

              borderTopRadius: "3px",
            }}
            placeholder=""
            label="Contact Name (required)"
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
          <CustomInput
            // validationMessage={error?.message}
            withValidation
            // ref={field.ref}
            input={{
              // ...field,

              // isInvalid: error !== undefined,
              w: "100%",

              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              fontSize: ".845rem",
              variant: "outline",
              type: "number",

              borderTopRadius: "3px",
            }}
            placeholder=""
            label="Account Number "
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
          <Stack>
            <Text fontSize={"17px"} fontWeight={"700"} paddingBottom={"10px"}>
              Primary Person
            </Text>
            <HStack gap={0}>
              <CustomInput
                // validationMessage={error?.message}
                withValidation
                // ref={field.ref}
                input={{
                  // ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",
                  marginRight: "0.5px",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  borderRightRadius: "none",
                  borderTopLeftRadius: "4px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                }}
                placeholder=""
                label="First Name "
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
              <CustomInput
                // validationMessage={error?.message}
                withValidation
                // ref={field.ref}
                input={{
                  // ...field,

                  // isInvalid: error !== undefined,
                  w: "100%",

                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  borderLeftRadius: "none",
                  borderTopRightRadius: "4px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                }}
                placeholder=""
                label="Last Name "
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            </HStack>
          </Stack>
          <CustomInput
            // validationMessage={error?.message}
            withValidation
            // ref={field.ref}
            input={{
              // ...field,

              // isInvalid: error !== undefined,
              w: "100%",

              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              borderLeftRadius: "none",
              borderTopRightRadius: "4px",
              fontSize: ".845rem",
              variant: "outline",
              type: "email",
            }}
            placeholder=""
            label="Email"
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
          <Stack>
            <Text fontSize={"17px"} fontWeight={"700"} paddingBottom={"10px"}>
              Business Info
            </Text>
            {phoneNumber === true && (
              <Stack>
                <Text
                  fontSize={"0.845rem"}
                  color={"customColor.black7"}
                  fontWeight={"600"}
                >
                  Phone Number
                </Text>
                <HStack gap={0} paddingBottom={"15px"}>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Country"
                    />
                  </Stack>
                  <Stack flex={3}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Area"
                    />{" "}
                  </Stack>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "number",
                      }}
                      placeholder="Number"
                    />
                  </Stack>
                  <Stack
                    h={"38px"}
                    border={"1px solid #e5e7eb"}
                    flex={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Icon
                      as={IoMdCloseCircle}
                      fontSize={"22px"}
                      _hover={{
                        cursor: "pointer",
                        color: "#3454D1",
                      }}
                      onClick={() => {
                        setPhoneNumber(!phoneNumber);
                      }}
                    />
                  </Stack>
                </HStack>
              </Stack>
            )}
            {faxNumber === true && (
              <Stack>
                <Text
                  fontSize={"0.845rem"}
                  color={"customColor.black7"}
                  fontWeight={"600"}
                >
                  Fax Number
                </Text>
                <HStack gap={0} paddingBottom={"15px"}>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Country"
                    />
                  </Stack>
                  <Stack flex={3}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Area"
                    />{" "}
                  </Stack>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "number",
                      }}
                      placeholder="Number"
                    />
                  </Stack>
                  <Stack
                    h={"38px"}
                    border={"1px solid #e5e7eb"}
                    flex={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Icon
                      as={IoMdCloseCircle}
                      fontSize={"22px"}
                      _hover={{
                        cursor: "pointer",
                        color: "#3454D1",
                      }}
                      onClick={() => {
                        setFaxNumber(!faxNumber);
                      }}
                    />
                  </Stack>
                </HStack>
              </Stack>
            )}
            {directDialNumber === true && (
              <Stack>
                <Text
                  fontSize={"0.845rem"}
                  color={"customColor.black7"}
                  fontWeight={"600"}
                >
                  Direct dial Number
                </Text>
                <HStack gap={0} paddingBottom={"15px"}>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Country"
                    />
                  </Stack>
                  <Stack flex={3}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Area"
                    />{" "}
                  </Stack>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "number",
                      }}
                      placeholder="Number"
                    />
                  </Stack>
                  <Stack
                    h={"38px"}
                    border={"1px solid #e5e7eb"}
                    flex={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Icon
                      as={IoMdCloseCircle}
                      fontSize={"22px"}
                      _hover={{
                        cursor: "pointer",
                        color: "#3454D1",
                      }}
                      onClick={() => {
                        setDirectDialNumber(!directDialNumber);
                      }}
                    />
                  </Stack>
                </HStack>
              </Stack>
            )}
            {mobileNumber === true && (
              <Stack>
                <Text
                  fontSize={"0.845rem"}
                  color={"customColor.black7"}
                  fontWeight={"600"}
                >
                  Mobile Number
                </Text>
                <HStack gap={0} paddingBottom={"15px"}>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",
                        marginRight: "0.5px",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        borderRightRadius: "none",
                        borderTopLeftRadius: "4px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Country"
                    />
                  </Stack>
                  <Stack flex={3}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                      }}
                      placeholder="Area"
                    />{" "}
                  </Stack>
                  <Stack flex={5}>
                    <CustomInput
                      // validationMessage={error?.message}
                      withValidation
                      // ref={field.ref}
                      input={{
                        // ...field,

                        // isInvalid: error !== undefined,
                        w: "100%",

                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        marginRight: "0.5px",
                        borderLeftRadius: "none",
                        borderTopRightRadius: "none",
                        borderBottomRightRadius: "none",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "number",
                      }}
                      placeholder="Number"
                    />
                  </Stack>
                  <Stack
                    h={"38px"}
                    border={"1px solid #e5e7eb"}
                    flex={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Icon
                      onClick={() => {
                        setMobileNumber(!mobileNumber);
                      }}
                      as={IoMdCloseCircle}
                      fontSize={"22px"}
                      _hover={{
                        cursor: "pointer",
                        color: "#3454D1",
                      }}
                    />
                  </Stack>
                </HStack>
              </Stack>
            )}
            <Box>
              <Menu>
                <MenuButton as={Button} variant={"ghost"} color={"#3454d1"}>
                  Add phone number
                </MenuButton>
                <MenuList zIndex={"9999"}>
                  {[
                    {
                      name: "Phone number",
                      onClick: () => {
                        setPhoneNumber(!phoneNumber);
                      },
                      isVisible: !phoneNumber, // Conditionally hide
                    },
                    {
                      name: "Fax number",
                      onClick: () => {
                        setFaxNumber(!faxNumber);
                      },
                      isVisible: !faxNumber, // Conditionally hide
                    },
                    {
                      name: "Direct dial number",
                      onClick: () => {
                        setDirectDialNumber(!directDialNumber);
                      },
                      isVisible: !directDialNumber, // Conditionally hide
                    },
                    {
                      name: "Mobile number",
                      onClick: () => {
                        setMobileNumber(!mobileNumber);
                      },
                      isVisible: !mobileNumber, // Conditionally hide
                    },
                  ]
                    .filter((item) => item.isVisible) // Filter out hidden options
                    .map((item, i) => (
                      <MenuItem key={i} onClick={item.onClick}>
                        {item.name}
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </Box>
          </Stack>
          <CustomInput
            // validationMessage={error?.message}
            withValidation
            // ref={field.ref}
            input={{
              // ...field,

              // isInvalid: error !== undefined,
              w: "100%",
              marginRight: "0.5px",
              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              borderRightRadius: "none",
              borderTopLeftRadius: "4px",
              fontSize: ".845rem",
              variant: "outline",
              type: "text",
            }}
            placeholder=""
            label="Website "
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
          <CustomInput
            // validationMessage={error?.message}
            withValidation
            // ref={field.ref}
            input={{
              // ...field,

              // isInvalid: error !== undefined,
              w: "100%",
              marginRight: "0.5px",
              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              borderRightRadius: "none",
              borderTopLeftRadius: "4px",
              fontSize: ".845rem",
              variant: "outline",
              type: "number",
            }}
            placeholder=""
            label="ABN "
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
          <CustomInput
            // validationMessage={error?.message}
            withValidation
            // ref={field.ref}
            input={{
              // ...field,

              // isInvalid: error !== undefined,
              w: "100%",
              marginRight: "0.5px",
              h: "38px",
              padding: "12px 15px",
              _light: {
                color: "customColor.black7",
              },
              borderRightRadius: "none",
              borderTopLeftRadius: "4px",
              fontSize: ".845rem",
              variant: "outline",
              type: "number",
            }}
            placeholder=""
            label="ACN "
            labelProps={{
              fontSize: ".845rem",
              fontWeight: "600",
              _light: {
                color: "customColor.black7",
              },
            }}
          />
          <Stack gap={0}>
            <Text
              margin={"0px 12px 8px 0px"}
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
              fontSize={".845rem"}
            >
              Note
            </Text>
            <Textarea
              bg={"#fff"}
              border={"1px solid #e5e7eb"}
              _hover={{
                border: "1px solid hsl(0, 0%, 70%)",
              }}
              _focus={{
                borderWidth: "1px",

                boxShadow: "0 0 0 1px #2684FF",
                _hover: {
                  borderWidth: "1px",
                  borderColor: "#2684FF",
                  boxShadow: "0 0 0 1px #2684FF",
                },
              }}
              _focusVisible={{
                borderWidth: "1px",
                boxShadow: "0 0 0 1px #2684FF",
                _hover: {
                  borderWidth: "1px",
                  borderColor: "#2684FF",
                  boxShadow: "0 0 0 1px #2684FF",
                },
              }}
              borderRadius={"0"}
            />{" "}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
