import {
  Button,
  ButtonGroup,
  Container,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdDelete, MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { LocationApi } from "../../API/LocationAPi";
import { Areas } from "./Tabs/Areas";
import { General } from "./Tabs/General";

export function AddLocation() {
  const navigate = useNavigate();
  const toast = useToast();
  const { currentBusinessId, setLastUpdatedLocations } = useBusinessContext();
  const { id } = useParams();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const form = useForm<LocationApi.Location>({
    defaultValues: {
      address: "",
      business_id: "",
      addressCoordinates: {
        latitude: "",
        longitude: "",
      },
      areas: [],
      code: "",
      name: "",
    },
  });
  useEffect(() => {
    if (id) {
      LocationApi.FetchLocationById(
        id,
        (data) => {
          form.reset(data);
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
            status: "error",
          });
        }
      );
    }
  }, []);
  return (
    <FormProvider {...form}>
      <Stack maxH="calc(100vh - 72px)" overflowY="auto">
        <Container padding={"0px 10px"} maxW={"container.md"}>
          <Stack
            gap={0}
            justifyContent={"space-between"}
            direction={"row"}
            padding={"15px 8px 0px 0px"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={0}>
              <Stack
                padding={"7px 7px 7px 0px"}
                _hover={{
                  bg: "gray.200",
                  borderRadius: "full",
                }}
                onClick={() => navigate("/app/TimeAttendance/Location")}
              >
                <Icon
                  as={MdOutlineKeyboardDoubleArrowLeft}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"16px"}
                />
              </Stack>

              <Heading
                fontSize={"17px"}
                _light={{
                  color: "customColor.black7",
                }}
                paddingLeft={"3px"}
                paddingRight={"3px"}
              >
                Locations
              </Heading>
            </Stack>
            {id && (
              <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                  <Button
                    variant="solid"
                    fontSize={"13px"}
                    padding={"10px 15px"}
                    borderRadius={"3px"}
                    border={"1px solid #FDEDED"}
                    bg={"#FDEDED"}
                    color={"#EA4D4D"}
                    minW={"75px"}
                    _hover={{
                      border: "1px solid #EA4D4D",
                      bg: "#EA4D4D",
                      color: "#fff",
                    }}
                    rightIcon={<MdDelete />}
                    onClick={onToggle}
                  >
                    Delete
                  </Button>
                </PopoverTrigger>
                <PopoverContent color="white" bg="#fff" borderColor="blue.100">
                  <PopoverArrow bg="#fff" />
                  <PopoverCloseButton color={"primary.950"} />
                  <PopoverHeader
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    Confirmation!
                  </PopoverHeader>
                  <PopoverBody
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    Are you sure you want to Delete this Location?
                  </PopoverBody>
                  <PopoverFooter padding={"16px 12px 8px"}>
                    <Stack alignItems={"flex-end"}>
                      <Stack direction={"row"} gap={0}>
                        <Button
                          backgroundColor={"#fff"}
                          borderRadius={"3px"}
                          border={"1px solid #dcdee4"}
                          fontSize={"13px"}
                          variant={"solid"}
                          textColor={"#ea4d4d"}
                          padding={"12px 16px"}
                          marginRight={"8px"}
                          fontWeight={600}
                          _hover={{
                            backgroundColor: "#fff",
                          }}
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          fontSize={"13px"}
                          bgColor="#3454D1"
                          borderRadius={"3px"}
                          borderWidth={"1px"}
                          borderColor={"#3454d1!important"}
                          variant={"solid"}
                          padding={"12px 16px"}
                          fontWeight={700}
                          _hover={{
                            bgColor: "#3454D1",
                          }}
                          onClick={() => {
                            LocationApi.DeleteLocation(
                              id!,
                              () => {
                                toast({
                                  title: "Success",
                                  description: "Location Deleted Successfully",
                                  status: "success",
                                });
                                setLastUpdatedLocations(Date.now());
                                navigate("/app/TimeAttendance/Location");
                              },
                              (err) => {
                                toast({
                                  title: "Error",
                                  description: err,
                                  status: "error",
                                });
                              }
                            );
                          }}
                        >
                          Yes
                        </Button>
                      </Stack>
                    </Stack>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            )}
          </Stack>

          <Container maxW="container.md" mt="10px" padding={0}>
            <General />

            <Areas />

            <Stack
              alignItems={"flex-end"}
              marginTop={"15px"}
              paddingBottom={"15px"}
              paddingRight={"8px"}
            >
              <ButtonGroup>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"#ea4d4d"}
                  padding={"12px 16px"}
                  marginRight={"5px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => navigate("/app/TimeAttendance/Location")}
                >
                  Cancel
                </Button>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={form.handleSubmit((data) => {
                    const LocationData = {
                      ...data,
                      addressCoordinates: {
                        latitude: String(data?.addressCoordinates?.latitude),
                        longitude: String(data?.addressCoordinates?.longitude),
                      },
                      business_id: currentBusinessId!,
                    };
                    if (id) {
                      LocationApi.UpdateLocation(
                        id,
                        LocationData,
                        (success) => {
                          toast({
                            title: "Success",
                            description: "Location Updated Successfully",
                            status: "success",
                          });
                          setLastUpdatedLocations(Date.now());
                          navigate("/app/TimeAttendance/Location");
                        },
                        (err) => {
                          toast({
                            title: "Error",
                            description: err,
                            status: "error",
                          });
                        }
                      );
                    } else {
                      LocationApi.AddLocation(
                        LocationData,
                        (success) => {
                          toast({
                            title: "Success",
                            description: "Location Created Successfully",
                            status: "success",
                          });
                          setLastUpdatedLocations(Date.now());
                          navigate("/app/TimeAttendance/Location");
                        },
                        (err) => {
                          toast({
                            title: "Error",
                            description: err,
                            status: "error",
                          });
                        }
                      );
                    }
                  })}
                >
                  {id ? "Update" : "Create"}
                </Button>
              </ButtonGroup>
            </Stack>
          </Container>
        </Container>
      </Stack>
    </FormProvider>
  );
}
