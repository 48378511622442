import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";
import { PayItemEarningApi } from "../../../Api/PayItemEarning";
import { PayTemp } from "../../../Api/PayTemp";

interface EarningLineModalProps {
  isOpen: boolean;
  form?: any;
  onClose: () => void;
  resetData: {
    payItemEarningId: string;
    calculationType: string;
    rateType: PayTemp.PayTemplateRateTypeEnum;
    index?: number;
  };

  onPressOK: (data: {
    payItemEarningId: string;
    calculationType?: string;
    // calculationType?: PayTemp.PayTemplateCalcTypeEnum;
    rateType: PayTemp.PayTemplateRateTypeEnum;
    category: PayTemp.PayTemplateItemTypeEnum;
    rateMultiplier?: number | undefined;
    earningFixedAmount?: number | undefined;
    isTaxable: boolean;
    index?: number;
    percentage?: number;
    rate?: number;
    amount?: number;
    hours?: number | null;
    annualSalary?: number | null;
  }) => void;
  account: PayItemEarningApi.EarningById[];
}
export function EarningLineModal({
  isOpen,
  onClose,
  onPressOK,
  account,
  resetData,
  form,
}: EarningLineModalProps) {
  const [payItemId, setPayItemId] = useState("");
  const [index, setIndex] = useState<number | undefined>(resetData.index);
  const [calculationType, setCalculationType] = useState("");
  // useState<PayTemp.PayTemplateCalcTypeEnum>(
  //   PayTemp.PayTemplateCalcTypeEnum.FIXED_AMOUNT
  // );
  const handlePayItemChange = (newValue: any) => {
    setPayItemId(newValue?.id);
    const selectedAccount = account.find((acc) => acc.id === newValue?.id);
    const newRateType: any =
      selectedAccount?.rateType || PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT;
    setRateType(newRateType);
    // const calculationTypeValue =
    //   selectedAccount?.rateType === "GROSS_SALARY" &&
    //   calculationType === "GROSS_SALARY"
    //     ? account.find((account) => account.id === newValue?.id)?.baseSalaryId
    //       ? "ALLOWANCE_SALARY"
    //       : "BASE_SALARY"
    //     : "";
    // setCalculationType(calculationTypeValue);
    // setCalculationType(
    //   selectedAccount?.rateType === "GROSS_SALARY"
    //     ? account.find((account) => account.id === newValue?.id)?.baseSalaryId
    //       ? "ALLOWANCE_SALARY"
    //       : "BASE_SALARY"
    //     : ""
    // );
  };
  const [rateType, setRateType] = useState<PayTemp.PayTemplateRateTypeEnum>(
    PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT
  );
  const handleCalculationTypeChange = (value: any) => {
    setCalculationType(value);
  };
  useEffect(() => {
    setPayItemId(resetData.payItemEarningId);
    setCalculationType(resetData.calculationType);
    setRateType(resetData.rateType);
    setIndex(resetData.index);
  }, [resetData]);
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Add an Earnings Line
        </ModalHeader>

        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack gap={0}>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              marginBottom={"08px"}
              fontWeight={"bold"}
            >
              Earning Rate <Icon color="teal.500" as={QuestionIcon} />
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={account}
              value={{
                id: payItemId,
                name:
                  account.find((account) => account.id === payItemId)?.name ??
                  "",
              }}
              onChange={handlePayItemChange}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </Stack>
          <RadioGroup
            value={calculationType}
            onChange={handleCalculationTypeChange}
          >
            <HStack spacing={0}>
              <Radio
                size="md"
                value="USE_EARNINGS_RATE_MULTIPLY"
                colorScheme="blue"
                isDisabled={
                  (account.find((account) => account.id === payItemId)
                    ?.rateType === "FIXED_AMOUNT" &&
                    account.find((account) => account.id === payItemId)
                      ?.fixedAmount !== null) ||
                  (account.find((account) => account.id === payItemId)
                    ?.rateType === "RATE_PER_UNIT" &&
                    account.find((account) => account.id === payItemId)
                      ?.unitRate !== "") ||
                  account.find((account) => account.id === payItemId)
                    ?.rateType === "MULTIPLE_OF_ORDINARY_EARNINGS"
                    ? false
                    : true
                }
              >
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  Use Earnings Rate
                </Text>
              </Radio>
              <Radio
                size="md"
                value="RATE"
                colorScheme="blue"
                isDisabled={
                  account.find((account) => account.id === payItemId)
                    ?.rateType === "MULTIPLE_OF_ORDINARY_EARNINGS"
                    ? //    ||
                      // account.find((account) => account.id === payItemId)
                      //   ?.rateType === "GROSS_SALARY"
                      true
                    : false
                }
              >
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  {account.find((account) => account.id === payItemId)
                    ?.rateType === "GROSS_SALARY"
                    ? "Timesheet"
                    : "Rate Per Unit"}
                </Text>
              </Radio>
              <Radio
                size="md"
                value="ANNUAL_SALARY"
                colorScheme="blue"
                isDisabled={
                  account.find((account) => account.id === payItemId)
                    ?.rateType === "RATE_PER_UNIT"
                    ? false
                    : true
                }
              >
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  Annual Salary
                </Text>
              </Radio>
              <Radio
                size="md"
                value="ANNUAL_SALARY"
                colorScheme="blue"
                // isDisabled={
                //   (account.find((account) => account.id === payItemId)
                //     ?.rateType === "FIXED_AMOUNT" &&
                //     account.find((account) => account.id === payItemId)
                //       ?.fixedAmount !== null) ||
                //   (account.find((account) => account.id === payItemId)
                //     ?.rateType === "RATE_PER_UNIT" &&
                //     account.find((account) => account.id === payItemId)
                //       ?.unitRate !== "") ||
                //   account.find((account) => account.id === payItemId)
                //     ?.rateType === "MULTIPLE_OF_ORDINARY_EARNINGS"
                //     ? false
                //     : true
                // }
                isDisabled={
                  account.find((account) => account.id === payItemId)
                    ?.rateType === "GROSS_SALARY"
                    ? //    ||
                      // account.find((account) => account.id === payItemId)
                      //   ?.rateType === "GROSS_SALARY"
                      false
                    : true
                }
              >
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.pearlWhite",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  Monthly
                </Text>
              </Radio>
            </HStack>
          </RadioGroup>
          <Stack gap={0}>
            <FormControl>
              <FormLabel
                mb={0}
                fontWeight="bold"
                marginTop="10px"
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Expense Account
              </FormLabel>

              <Text
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                marginTop={"5px"}
                marginBottom={"5px"}
                borderTop={"1px solid #999"}
                borderBottom={"1px solid #ccc"}
              >
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.code
                }
                {":"}
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.name
                }
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onPressOK({
                payItemEarningId: payItemId,
                isTaxable:
                  !account.find((account) => account.id === payItemId)
                    ?.isTaxable ?? false,
                calculationType,
                index: index,
                rateType,
                category: PayTemp.PayTemplateItemTypeEnum.EARNING,
                rateMultiplier: account.find(
                  (account) => account.id === payItemId
                )?.multiple,
                percentage: undefined,
                amount:
                  PayTemp.PayTemplateRateTypeEnum.GROSS_SALARY &&
                  calculationType === "ANNUAL_SALARY"
                    ? form.getValues("grossSalary")
                    : undefined,
                annualSalary:
                  PayTemp.PayTemplateRateTypeEnum.GROSS_SALARY &&
                  calculationType === "ANNUAL_SALARY"
                    ? null
                    : undefined,
                hours:
                  PayTemp.PayTemplateRateTypeEnum.GROSS_SALARY &&
                  calculationType === "ANNUAL_SALARY"
                    ? null
                    : undefined,
                rate:
                  rateType === PayTemp.PayTemplateRateTypeEnum.GROSS_SALARY &&
                  calculationType === "RATE"
                    ? Math.ceil(
                        form.watch("incomeType") === "PER_DAY_RATE"
                          ? ((form.watch("grossSalary") ?? 0) * 12) / 365
                          : ((form.watch("grossSalary") ?? 0) * 12) / 365 / 8
                      )
                    : undefined,
                earningFixedAmount:
                  calculationType === "USE_EARNINGS_RATE_MULTIPLY"
                    ? account.find((account) => account.id === payItemId)
                        ?.fixedAmount
                    : undefined,
              });
              onClose();
            }}
          >
            Add
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
