import {
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { jwtDecode } from "jwt-decode";
import { useAuthHeader } from "react-auth-kit";
import { Controller, useForm } from "react-hook-form";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Hrislogo from "../../../assets/HRISMainLogo.png";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Authentication } from "../../Api/Authentication";

export function ChangePasswordEmail() {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const logedinData = localStorage.getItem("_auth_state");
  let parsedEmail = "";

  if (logedinData) {
    const parsedData = JSON.parse(logedinData); // Parse the JSON string into an object
    parsedEmail = parsedData.email; // Extract the email from the parsed object
  }

  const { control, handleSubmit } = useForm<Authentication.ChangePassword>({
    defaultValues: {
      email: parsedEmail,
    },
  });

  const navigate = useNavigate();
  const { currentEmployeeId } = useBusinessContext();
  const toast = useToast();

  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Container maxW={"container.sm"} padding={"40px 20px 40px 20px"}>
        <Stack
          gap={0}
          transition={"all .3s ease"}
          boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
          borderRadius={"10px"}
          _light={{
            bg: "#fff",
          }}
          padding={"50px"}
        >
          <Flex justifyContent="center">
            <Image
              objectFit="fill"
              w="115px"
              margin={"0px 0px 24px"}
              src={Hrislogo}
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
            />
          </Flex>
          <Text
            fontSize={"24px"}
            _light={{ color: "customColor.black7" }}
            _dark={{ color: "customColor.white" }}
            fontWeight={"700"}
            marginBottom={"24px"}
          >
            Change Password
          </Text>
          <Text
            fontSize={"13.44"}
            _light={{ color: "customColor.gray.64748B" }}
            _dark={{ color: "customColor.white" }}
            fontWeight={"500"}
            marginBottom={"24px"}
          >
            To change your password, enter your email address, system will send
            you link to change password.
          </Text>

          <Stack gap={0} marginBottom={"24px"}>
            <Text
              fontSize={"13.44px"}
              _light={{ color: "customColor.black7" }}
              _dark={{ color: "customColor.white" }}
              fontWeight={"600"}
              marginBottom={"8px"}
            >
              Email address
            </Text>
            <InputGroup>
              <InputLeftAddon h={"40px"}>
                <MdOutlineAlternateEmail />
              </InputLeftAddon>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    isDisabled={true} // The input is disabled
                    borderRadius={"0px"}
                    borderRightRadius={"5px"}
                    type={"email"}
                    defaultValue={parsedEmail}
                    h={"40px"}
                    fontSize=".845rem"
                    fontWeight={400}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding="12px 15px"
                    border="1px solid #e5e7eb"
                    _hover={{
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _focusVisible={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _focus={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    isInvalid={invalid}
                    {...field} // Connects the input to the form
                  />
                )}
              />
            </InputGroup>
          </Stack>

          <HStack gap={0} w={"100%"}>
            <Button
              type="submit"
              data-testid="login-button"
              height={"40px"}
              size={"md"}
              w={"100%"}
              fontSize={"13px"}
              borderRadius={"3px"}
              marginRight={"8px"}
              fontWeight={700}
              colorScheme="Cornflower"
              padding={"15px 20px"}
              lineHeight={"1rem"}
              onClick={handleSubmit(
                (data) => {
                  Authentication.PasswordChange(
                    data,
                    () => {
                      toast({
                        title: "Email Sent for Reset Password",
                        description:
                          "Please check your email, an email is sent to your email address to reset your password",
                        status: "success",
                      });
                      decodedToken.accessLevel !== "EMPLOYEE" &&
                        navigate("/app/payroll/Dashboard");
                      decodedToken.accessLevel === "EMPLOYEE" &&
                        navigate(
                          `/app/payroll/EmployeeDashboard/${currentEmployeeId}`
                        );
                    },
                    (error) => {
                      toast({
                        title: "Request to Change Password Failed",
                        description: error,
                        status: "error",
                      });
                    }
                  );
                },
                (error) => {
                  toast({
                    title: "Error",
                    status: "error",
                  });
                }
              )}
            >
              Send Email
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              w={"100%"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              marginLeft={"8px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
}
