import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { LocationApi } from "../../../API/LocationAPi";

type ContainerStyle = {
  width: string;
  height: string;
};

const containerStyle: ContainerStyle = {
  width: "100%",
  height: "400px",
};
export default function Map() {
  const form = useFormContext<LocationApi.Location>();
  const WatchLatitude = useWatch({
    control: form.control,
    name: "addressCoordinates.latitude",
  });
  const WatchLongitude = useWatch({
    control: form.control,
    name: "addressCoordinates.longitude",
  });
  const [center, setCenter] = useState({
    lat: Number(WatchLatitude) ?? 24.8607,
    lng: Number(WatchLongitude) ?? 67.0011,
  });
  useEffect(() => {
    if (WatchLatitude && WatchLongitude) {
      const newCenter = {
        lat: Number(WatchLatitude),
        lng: Number(WatchLongitude),
      };
      setCenter(newCenter);
      fetchAddress(newCenter.lat, newCenter.lng);
    }
  }, [WatchLatitude, WatchLongitude]);

  const onMarkerDragEnd = (e: any) => {
    setCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    form.setValue("addressCoordinates", {
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
    fetchAddress(e.latLng.lat(), e.latLng.lng());
  };
  const onMapClick = (e: any) => {
    setCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    form.setValue("addressCoordinates", {
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
    fetchAddress(e.latLng.lat(), e.latLng.lng());
  };
  const fetchAddress = (lat: number, lng: number) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCZ6p-O1f1OsTcmPNOEitFCQBBDML9fOr4`
    )
      .then((response) => response.json())
      .then((data) => {
        form.setValue("address", data.results[0].formatted_address);
      });
  };
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onClick={onMapClick}
    >
      <Marker position={center} draggable={true} onDragEnd={onMarkerDragEnd} />
    </GoogleMap>
  );
}
