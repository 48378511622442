import {
  Button,
  Container,
  Flex,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AccessControl } from "../../../api/AccessControl";
import { ReactSelect } from "../../../Common/ReactSelect";
import { MappingModuleApi } from "../../Api/MappingModule";
import { PayItemDeductionApi } from "../../Api/PayItemDeduction";
import { PayItemEarningApi } from "../../Api/PayItemEarning";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";
import { PayItemReimbursementApi } from "../../Api/PayItemReinbursement";
import { PayItemRetirement } from "../../Api/PayItemRetirement";
import { PayrollCalendar } from "../../Api/PayRollCalendar";
import { EmploymentComponent } from "./Components/EmploymentComponent";
import { LeaveComponent } from "./Components/LeaveComponent";
import { PayTempComponent } from "./Components/PayTempComponent";
import { TableComponent } from "./Components/TableComponent";
import { EmploymentFilters } from "./FilterMenus/EmploymentFilters";
import { LeaveFilter } from "./FilterMenus/LeaveFilter";
import { PayTempFilter } from "./FilterMenus/PayTempFilter";

export function MappingWindow() {
  const EmploymentForm = useForm<MappingModuleApi.EmployeeDetailFilters>({});
  const PayTempForm = useForm<MappingModuleApi.PayTemplateFilters>({});
  const LeaveEntitlementForm =
    useForm<MappingModuleApi.LeaveEntitlementFilters>({
      defaultValues: {
        payrollPayItemLeaveId: undefined,
        calculationMethod: undefined,
        quantity: undefined,
        balance: undefined,
        terminationUnusedBalance: undefined,
        etpLeaveType: undefined,
        includeRetirementContribution: undefined,
        accruedQuantity: undefined,
        openingBalance: undefined,
      },
    });

  const toast = useToast();
  const [payOptions, setPayOptions] = useState<PayrollCalendar.Calendar[]>([]);
  const [users, setUsers] = useState<AccessControl.UserDetail[]>([]);
  const [events, setEvents] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (events === "EMPLOYMENT_DETAILS") {
      AccessControl.GetAllUser(
        (data) => {
          setUsers(data);
        },
        (error) => {
          toast({
            title: "Users not found",
            status: "error",
          });
        }
      );
      PayrollCalendar.FetchCalendar(
        (data) => {
          setPayOptions(data);
        },
        (error) => {
          toast({
            title: "Error",
            status: "error",
          });
        }
      );
    }
  }, [events]);
  const [fetchLeaves, setFetchLeaves] = useState<
    Array<PayItemLeaveApi.LeaveById>
  >([]);
  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (data) => {
        setFetchLeaves(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [events]);

  const [selectedEmployeeFilters, setSelectedEmployeeFilters] = useState({
    empType: false,
    empBasis: false,
    payrollPayCalenderId: false,
    employeeGroupId: false,
    ratesId: false,
    department: false,
    jobTitle: false,
    isManagementStaff: false,
    manager: false,
    incomeType: false,
    joiningDate: false,
  });

  const [selectedPayTempFilters, setSelectedPayTempFilters] = useState({
    earning: false,
    deduction: false,
    statutoryBenefits: false,
    reimbursement: false,
  });

  const [selectedLeaveFilters, setSelectedLeaveFilters] = useState({
    payrollPayItemLeaveId: false,
    calculationMethod: false,
    quantity: false,
    balance: false,
    terminationUnusedBalance: false,
    etpLeaveType: false,
    includeRetirementContribution: false,
    accruedQuantity: false,
    openingBalance: false,
  });

  const [table, setTable] = useState(false);

  const [earningAdded, setEarningAdded] = useState<Array<any>>([]);
  const [deductionAdded, setDeductionAdded] = useState<Array<any>>([]);
  const [statutoryAdded, setStatutoryAdded] = useState<Array<any>>([]);
  const [reimbursementAdded, setReimbursementAdded] = useState<Array<any>>([]);

  useEffect(() => {
    if (events === "PAY_TEMP" || events === "EMPLOYMENT_DETAILS") {
      PayItemEarningApi.FetchEarning(
        (data) => {
          const activeData = data.filter((item) => item.active === true);
          setEarningAdded(activeData);
        },
        (error) => {
          toast({
            title: "Earning not found",
            status: "error",
            description: error,
          });
        }
      );
    } else if (events === "PAY_TEMP") {
      PayItemRetirement.FetchRetirementFund(
        (data) => {
          // Filter out only active items
          const activeData = data.filter((item) => item.active === true);
          setStatutoryAdded(activeData);
        },
        (error) => {
          toast({
            title: "Retirement Fund not found",
            status: "error",
            description: error,
          });
        }
      );
      PayItemDeductionApi.FetchDeduction(
        (data) => {
          const activeData = data.filter((item) => item.active === true);
          setDeductionAdded(activeData);
        },
        (error) => {
          toast({
            title: "Deduction not found",
            status: "error",
            description: error,
          });
        }
      );
      PayItemReimbursementApi.FetchReimbursement(
        (data) => {
          const activeData = data.filter((item) => item.active === true);
          setReimbursementAdded(activeData);
        },
        (error) => {
          toast({
            title: "Reimbursement not found",
            status: "error",
            description: error,
          });
        }
      );
    }
  }, [events]);
  useEffect(() => {
    if (events === "PAY_TEMP") {
      PayItemRetirement.FetchRetirementFund(
        (data) => {
          // Filter out only active items
          const activeData = data.filter((item) => item.active === true);
          setStatutoryAdded(activeData);
        },
        (error) => {
          toast({
            title: "Retirement Fund not found",
            status: "error",
            description: error,
          });
        }
      );
      PayItemDeductionApi.FetchDeduction(
        (data) => {
          const activeData = data.filter((item) => item.active === true);
          setDeductionAdded(activeData);
        },
        (error) => {
          toast({
            title: "Deduction not found",
            status: "error",
            description: error,
          });
        }
      );
      PayItemReimbursementApi.FetchReimbursement(
        (data) => {
          const activeData = data.filter((item) => item.active === true);
          setReimbursementAdded(activeData);
        },
        (error) => {
          toast({
            title: "Reimbursement not found",
            status: "error",
            description: error,
          });
        }
      );
    }
  }, [events]);
  const EventOptions: Array<{ label: string; value: string }> = [
    {
      label: "Employment Detail",
      value: "EMPLOYMENT_DETAILS",
    },
    {
      label: "Pay Template",
      value: "PAY_TEMP",
    },
    {
      label: "Leave",
      value: "LEAVE",
    },
  ];
  const [employeesList, setEmployeesList] = useState<Array<any>>([]);
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack
        w={"100%"}
        top={0}
        paddingTop={"20px"}
        paddingBottom={"20px"}
        position="sticky"
        h={"60px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        zIndex="1000"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
      >
        <Text
          fontSize={"16px"}
          fontWeight={"700"}
          margin={"0px 8px 0px 16px"}
          padding={"0px 10px 0px 0px"}
          _light={{
            color: "customColor.black7",
          }}
        >
          Mapping Window
        </Text>
      </Stack>
      <Stack padding={"30px"} spacing={6}>
        <Container
          maxW={"container.lg"}
          padding={"20px 16px"}
          bg={"#fff"}
          boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
        >
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px 0px"}
          >
            Event
          </Text>

          <ReactSelect
            placeholder=""
            ErrorMessage="Select Event type"
            withValidation
            options={EventOptions}
            value={EventOptions.find((option) => option.value === events)}
            onChange={(newValue: any) => {
              setEvents(newValue?.value);
              // Reset selected filters when the event changes
              setSelectedEmployeeFilters({
                empType: false,
                empBasis: false,
                payrollPayCalenderId: false,
                employeeGroupId: false,
                ratesId: false,
                department: false,
                jobTitle: false,
                isManagementStaff: false,
                manager: false,
                incomeType: false,
                joiningDate: false,
              });
              setSelectedPayTempFilters({
                earning: false,
                deduction: false,
                statutoryBenefits: false,
                reimbursement: false,
              });
              setSelectedLeaveFilters({
                payrollPayItemLeaveId: false,
                calculationMethod: false,
                quantity: false,
                balance: false,
                terminationUnusedBalance: false,
                etpLeaveType: false,
                includeRetirementContribution: false,
                accruedQuantity: false,
                openingBalance: false,
              });
              LeaveEntitlementForm.reset();
              EmploymentForm.reset();
              PayTempForm.reset();
              setTable(false);
            }}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
          />
        </Container>
        {events && (
          <Container
            maxW={"container.lg"}
            padding={"20px 16px"}
            bg={"#fff"}
            boxShadow={
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
            }
          >
            <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
              <Stack flex={1}>
                <Text
                  fontSize={"15px"}
                  fontWeight={"700"}
                  color={"customColor.black7"}
                >
                  Filters
                </Text>
                <FormProvider {...EmploymentForm}>
                  {events === "EMPLOYMENT_DETAILS" && (
                    <EmploymentComponent
                      selectedEmployeeFilters={selectedEmployeeFilters}
                      users={users}
                      earningAdded={earningAdded}
                      payOptions={payOptions}
                      EmploymentForm={EmploymentForm}
                    />
                  )}
                </FormProvider>
                <FormProvider {...PayTempForm}>
                  {events === "PAY_TEMP" && (
                    <PayTempComponent
                      selectedPayTempFilters={selectedPayTempFilters}
                      earningAdded={earningAdded}
                      deductionAdded={deductionAdded}
                      reimbursementAdded={reimbursementAdded}
                      statutoryAdded={statutoryAdded}
                      PayTempForm={PayTempForm}
                    />
                  )}
                </FormProvider>
                <FormProvider {...LeaveEntitlementForm}>
                  {events === "LEAVE" && (
                    <LeaveComponent
                      selectedLeaveFilters={selectedLeaveFilters}
                      fetchLeaves={fetchLeaves}
                      LeaveEntitlementForm={LeaveEntitlementForm}
                    />
                  )}
                </FormProvider>
              </Stack>
              <Stack alignItems={"flex-end"}>
                <FormProvider {...EmploymentForm}>
                  {events === "EMPLOYMENT_DETAILS" && (
                    <EmploymentFilters
                      events={"EMPLOYMENT_DETAILS"}
                      selectedEmployeeFilters={selectedEmployeeFilters}
                      setSelectedEmployeeFilters={setSelectedEmployeeFilters}
                    />
                  )}
                </FormProvider>
                <FormProvider {...PayTempForm}>
                  {events === "PAY_TEMP" && (
                    <PayTempFilter
                      events={"PAY_TEMP"}
                      selectedPayTempFilters={selectedPayTempFilters}
                      setSelectedPayTempFilters={setSelectedPayTempFilters}
                    />
                  )}
                </FormProvider>
                <FormProvider {...LeaveEntitlementForm}>
                  {events === "LEAVE" && (
                    <LeaveFilter
                      events={"LEAVE"}
                      selectedLeaveFilters={selectedLeaveFilters}
                      setSelectedLeaveFilters={setSelectedLeaveFilters}
                    />
                  )}
                </FormProvider>
              </Stack>
            </HStack>
            <Stack alignItems={"flex-end"} marginTop={"15px"}>
              {" "}
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  setIsLoading(true);
                  const Payload =
                    events === "EMPLOYMENT_DETAILS"
                      ? { employmentDetails: EmploymentForm.getValues() }
                      : events === "PAY_TEMP"
                      ? { payTemplate: PayTempForm.getValues() }
                      : { leaveEntitlement: LeaveEntitlementForm.getValues() };
                  MappingModuleApi.fetchEmployeeForMapping(
                    Payload,
                    (data) => {
                      setEmployeesList(data);
                      setIsLoading(false);
                      setTable(true);
                    },
                    (error) => {
                      toast({
                        title: "Error",
                        description: error,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                }}
              >
                Get Affected Employee
              </Button>
            </Stack>
          </Container>
        )}
        {table && !isLoading ? (
          <TableComponent
            events={events}
            fetchLeaves={fetchLeaves}
            earningAdded={earningAdded}
            deductionAdded={deductionAdded}
            reimbursementAdded={reimbursementAdded}
            statutoryAdded={statutoryAdded}
            users={users}
            payOptions={payOptions}
            employeesList={employeesList}
          />
        ) : (
          table && (
            <Container
              maxW={"container.lg"}
              padding={"0"}
              bg={"#fff"}
              boxShadow={
                "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
              }
              h={"200px"}
            >
              <Flex
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Text>No data found</Text>
              </Flex>
            </Container>
          )
        )}
      </Stack>
    </Stack>
  );
}
