import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { EmployeeNotes } from "../../Api/EmployeeNotes";
import { AddNewNotes } from "./Modal/AddNewNotes";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Notes({ setSelectedTab, selectedTab }: TabProps) {
  const param = useParams();
  const [selectedNoteId, setSelectedNoteId] = useState<string>();
  const [notes, setNotes] = useState<EmployeeNotes.Note[]>([]);
  const [lastUpdated, setLastUpdated] = useState<number>();
  const [isloading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const noteId = param.id;
    EmployeeNotes.GetNoteDetails(
      noteId,
      (data) => {
        setNotes(data);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [lastUpdated]);
  const addNewNote = useDisclosure();

  const form = useForm<EmployeeNotes.Note>({
    defaultValues: {
      id: "",
      createdAt: "",
      note: "",
      notesTakenBy: "",
    },
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredNotes = notes.filter((note) =>
    note.note.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <FormProvider {...form}>
      <Stack gap={0}>
        <Box
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          justifyContent="center"
          alignItems="center"
          textAlign={"left"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderRadius={"5px"}
          border={"1px solid #f6f6f6"}
        >
          <HStack
            position="relative"
            mx="auto"
            justifyContent={"space-between"}
          >
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              _light={{
                color: "customColor.black7",
              }}
              fontWeight="bold"
              padding="20px"
              as="h2"
              size="md"
            >
              Notes
            </Heading>

            <Button
              variant="link"
              mr="20px"
              _light={{
                color: "primary.950",
              }}
              // bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
              onClick={() => {
                form.reset({
                  id: "",
                  createdAt: "",
                  note: "",
                  notesTakenBy: "",
                });
                setSelectedNoteId(undefined);
                addNewNote.onOpen();
              }}
            >
              Add New Note
            </Button>
          </HStack>

          {/* <Box
            position="relative"
            borderRadius="4px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "#F8FAFA",
            }}
            border="1px solid #ddd"
            marginTop="15px"
          > */}
          <Stack
            padding={"15px"}
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
          >
            <Box

            //// bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
            >
              <Text
                fontWeight={500}
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.200",
                }}
              >
                Showing {notes.length} Notes
              </Text>
            </Box>
            <Box h="40px">
              <Stack gap={0} direction={"row"} alignItems={"center"}>
                <Text
                  fontSize={"13.44px"}
                  _light={{
                    color: "customColor.gray.6b7885",
                  }}
                >
                  Search:
                </Text>
                <InputGroup ml={[0, "7px"]}>
                  <Input
                    type="text"
                    placeholder="Search"
                    borderRadius={"3px"}
                    h={["auto", "40px"]}
                    _focusVisible={{
                      border: "1px solid grey",
                    }}
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Stack>
              {/* <FormControl fontSize="14px">
                <InputGroup
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  mt="7px"
                  position="absolute"
                  h="25px"
                  right="10px"
                  borderRadius="3px"
                  w="250px"
                >
                  <InputRightElement
                    h="25px"
                    children={<Search2Icon fontSize="13px" color="gray.500" />}
                  />
                  <Input
                    h="25px"
                    fontSize="13px"
                    type="search"
                    placeholder="Search notes"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </FormControl> */}
            </Box>
          </Stack>

          <Skeleton isLoaded={!isloading} height="100%">
            <Box overflowX="auto" p="0px">
              {" "}
              <Table
                variant="simple"
                w="100%"
                overflowX="auto"
                marginBottom={"30px"}
              >
                <Thead h={"36.8px"}>
                  <Tr
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    borderTopWidth={"1px"}
                    borderTopColor="borders.tableBorder"
                  >
                    {["Date / Time", "User", "Note"].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                        >
                          {item}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredNotes.length > 0 ? (
                    filteredNotes.map((note, index) => (
                      <Tr
                        key={index}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedNoteId(note?.id);
                          form.reset({
                            note: note.note,
                          });
                          addNewNote.onOpen();
                        }}
                      >
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {moment(note.createdAt).format("D MMMM YYYY hh:mm A")}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {note.notesTakenBy}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {note.note}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderBottomWidth="1px"
                        borderBottomColor={"borders.tableBorder"}
                      ></Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderBottomWidth="1px"
                        borderBottomColor={"borders.tableBorder"}
                        textAlign={"center"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <Image
                          boxSize="30"
                          src="https://cdn.icon-icons.com/icons2/491/PNG/512/file-notes-document_48083.png"
                        />
                        <Text
                          marginBottom={"15px"}
                          _dark={{
                            color: "customColor.dark.250",
                          }}
                          fontWeight={500}
                          fontSize="13.44px"
                          _light={{
                            color: "customColor.black7",
                          }}
                          marginTop="5px"
                        >
                          No notes added
                        </Text>
                        <Button
                          variant="link"
                          _light={{
                            color: "primary.950",
                          }}
                          onClick={() => {
                            form.reset({
                              id: "",
                              createdAt: "",
                              note: "",
                              notesTakenBy: "",
                            });
                            setSelectedNoteId(undefined);
                            addNewNote.onOpen();
                          }}
                        >
                          Add New Note
                        </Button>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderBottomWidth="1px"
                        borderBottomColor={"borders.tableBorder"}
                      ></Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Skeleton>

          {/* </Box> */}
          <AddNewNotes
            isOpen={addNewNote.isOpen}
            onClose={addNewNote.onClose}
            selectedNoteId={selectedNoteId}
            setLastUpdated={setLastUpdated}
          />
          {/*Add earning Line Modal*/}
          <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
            <Button
              colorScheme="green"
              border={"1px solid green"}
              textColor={"customColor.white"}
              borderRadius={"3px"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        </Box>
      </Stack>
    </FormProvider>
  );
}
