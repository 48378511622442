import {
  Avatar,
  Box,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { ArcElement, Chart, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { FaGripLinesVertical } from "react-icons/fa";
import io from "socket.io-client";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Shifts } from "../../../TimeAttendance/API/Shifts";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export function EmployeeTracking() {
  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0"); // Convert hours to 12-hour format
    return `${formattedHours}:${minutes}:${seconds}${ampm}`;
  };

  const [formattedTime, setFormattedTime] = useState(getCurrentTime());

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getCurrentTime();
      setFormattedTime(time);
    }, 1000);
    // Clear the interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, []);
  const { employeesList } = useBusinessContext();
  // Sample data for the bar chart
  const [shiftData, setShiftData] = useState({
    totalShifts: 0,
    countOfShiftsYetToStart: 0,
    countOfStartedShifts: 0,
    countOfShiftsEnded: 0,
    countOfLateStartedShifts: 0,
    countOfOnTimeStartedShifts: 0,
    timedInEmployees: [],
    notTimedInEmployees: [],
  });
  useEffect(() => {
    Shifts.GetAttendance(
      {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      (data) => {
        setShiftData(data);
      },
      (err) => {}
    );
  }, []);
  useEffect(() => {
    const socket = io(
      `${process.env.REACT_APP_API_URL_SOCKET_GATEWAY}/employee-live-attendance`,
      { path: "/ws/time" }
    );

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    // Listen for shift data updates from the server
    socket.on("employeeShiftsAttendance", (data) => {
      setShiftData(data);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);
  const chartRef = useRef<Chart<"bar">>(null);
  const baseColors = [
    "rgba(255, 99, 132, 0.2)", // Light Red
    "rgba(75, 192, 192, 0.2)", // Light Green
    "rgba(255, 205, 86, 0.2)", // Light Yellow
    "rgba(54, 162, 235, 0.2)", // Light Blue
    "rgba(153, 102, 255, 0.2)", // Light Purple
  ];

  const borderColors = [
    "rgba(255, 99, 132, 1)", // Darker Red
    "rgba(75, 192, 192, 1)", // Darker Green
    "rgba(255, 205, 86, 1)", // Darker Yellow
    "rgba(54, 162, 235, 1)", // Darker Blue
    "rgba(153, 102, 255, 1)", // Darker Purple
  ];
  const data = {
    labels: [
      "Total Shifts",
      "Shifts Started",
      "Shifts Ended",
      "Shifts Not Started",
      "Shifts Started On Time",
      "Shifts Started Late",
    ],
    datasets: [
      {
        label: "Shifts",
        backgroundColor: (context: any) => {
          const chart = chartRef.current;
          if (!chart) return baseColors[context.dataIndex];

          const ctx = chart.ctx;
          const barIndex = context.dataIndex;

          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, baseColors[barIndex]);
          gradient.addColorStop(1, borderColors[barIndex]);

          return gradient;
        },
        borderColor: borderColors,
        borderWidth: 1, // Border thickness
        barThickness: 50,
        data: [
          shiftData.totalShifts,
          shiftData.countOfStartedShifts,
          shiftData.countOfShiftsEnded,
          shiftData.countOfShiftsYetToStart,
          shiftData.countOfOnTimeStartedShifts,
          shiftData.countOfLateStartedShifts,
        ],
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false, // Ensures all labels are shown
        },
        // Custom bar width setting
        stacked: true, // Enable stacked bars for consistent width
        barThickness: "10px", // Set bar width to 100px
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };
  //  const options = {
  //    plugins: {
  //      datalabels: {
  //        anchor: "end",
  //        align: "start",
  //        color: "#000",
  //        font: {
  //          weight: "bold",
  //        },
  //      },
  //    },
  //    scales: {
  //      x: {
  //        grid: {
  //          display: false,
  //        },
  //      },
  //      y: {
  //        grid: {
  //          display: true,
  //        },
  //        beginAtZero: true,
  //      },
  //    },
  //  };
  const pieData = [
    {
      label: "Total Shifts",
      data: [
        shiftData.totalShifts,
        shiftData.totalShifts - shiftData.totalShifts,
      ],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "#F1F5F9"],
      borderColor: ["rgba(255, 99, 132, 1)", "#E2E8F0"],
    },
    {
      label: "Shifts Started",
      data: [
        shiftData.countOfStartedShifts,
        shiftData.totalShifts - shiftData.countOfStartedShifts,
      ],
      backgroundColor: ["rgba(75, 192, 192, 0.2)", "#F1F5F9"],
      borderColor: ["rgba(75, 192, 192, 1)", "#E2E8F0"],
    },
    {
      label: "Shifts Ended",
      data: [
        shiftData.countOfShiftsEnded,
        shiftData.totalShifts - shiftData.countOfShiftsEnded,
      ],
      backgroundColor: ["rgba(255, 205, 86, 0.2)", "#F1F5F9"],
      borderColor: ["rgba(255, 205, 86, 1)", "#E2E8F0"],
    },
    {
      label: "Shifts Not Started",
      data: [
        shiftData.countOfShiftsYetToStart,
        shiftData.totalShifts - shiftData.countOfShiftsYetToStart,
      ],
      backgroundColor: ["rgba(54, 162, 235, 0.2)", "#F1F5F9"],
      borderColor: ["rgba(54, 162, 235, 1)", "#E2E8F0"],
    },
    {
      label: "Shifts Started On Time",
      data: [
        shiftData.countOfOnTimeStartedShifts,
        shiftData.totalShifts - shiftData.countOfOnTimeStartedShifts,
      ],
      backgroundColor: ["rgba(153, 102, 255, 0.2)", "#F1F5F9"],
      borderColor: ["rgba(153, 102, 255, 1)", "#E2E8F0"],
    },
    {
      label: "Shifts Started Late",
      data: [
        shiftData.countOfLateStartedShifts,
        shiftData.totalShifts - shiftData.countOfLateStartedShifts,
      ],
      backgroundColor: ["#FFD580", "#F1F5F9"],
      borderColor: ["#FF5722", "#E2E8F0"],
    },
  ];
  return (
    <Box
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      w="100%"
      //   h={editDashboard ? "calc(100% - 30px)" : "100%"}

      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Box p={"25px"} borderBottom={"1px solid #e5e7eb"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight="1.2"
          fontSize="16px"
          fontWeight={600}
        >
          Employee Tracking
        </Text>
      </Box>
      <Stack direction={"row"} justifyContent={"space-between"} p="20px">
        <HStack>
          <Text
            fontSize=".9975rem"
            color="customColor.black7"
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={"600"}
          >
            Current Date
          </Text>
          <Text
            fontSize=".9975rem"
            color="customColor.black7"
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={"400"}
          >
            {moment().format("DD MMMM YYYY")}
          </Text>
        </HStack>
        <HStack>
          <Text
            fontSize=".9975rem"
            color="customColor.black7"
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={"600"}
          >
            Current Time
          </Text>
          <Text
            fontSize=".9975rem"
            color="customColor.black7"
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={"400"}
          >
            {formattedTime}
          </Text>
        </HStack>
      </Stack>
      <Box
        _dark={{ bgColor: "customColor.dark.50" }}
        _light={{ bgColor: "customColor.white" }}
        borderRadius="4px"
        bg={"#fff"}
        p={"30px"}
        overflowX={"auto"}
      >
        <Grid templateColumns="repeat(6, 1fr)" gap={0}>
          <GridItem w="100%" h="100px" boxShadow="md">
            <Text textAlign="center" fontWeight="500" textColor="#3454d1">
              Total Shifts
            </Text>
            <Box
              w="100%"
              h="70%"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
            >
              <Text fontSize="24px" fontWeight="700" textAlign="center">
                {shiftData.totalShifts}
              </Text>
            </Box>
          </GridItem>
          <GridItem w="100%" h="100px" boxShadow="md">
            <Text textAlign="center" fontWeight="500" textColor="#3454d1">
              Shifts Started
            </Text>
            <Box
              w="100%"
              h="70%"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
            >
              <Text fontSize="24px" fontWeight="700" textAlign="center">
                {shiftData.countOfStartedShifts}
              </Text>
            </Box>
          </GridItem>
          <GridItem w="100%" h="100px" boxShadow="md">
            <Text textAlign="center" fontWeight="500" textColor="#3454d1">
              Shifts Ended
            </Text>
            <Box
              w="100%"
              h="70%"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
            >
              <Text fontSize="24px" fontWeight="700" textAlign="center">
                {shiftData.countOfShiftsEnded}
              </Text>
            </Box>
          </GridItem>
          <GridItem w="100%" h="100px" boxShadow="md">
            <Text textAlign="center" fontWeight="500" textColor="#3454d1">
              Shifts Not Started
            </Text>
            <Box
              w="100%"
              h="70%"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
            >
              <Text fontSize="24px" fontWeight="700" textAlign="center">
                {shiftData.countOfShiftsYetToStart}
              </Text>
            </Box>
          </GridItem>
          <GridItem w="100%" h="100px" boxShadow="md">
            <Text textAlign="center" fontWeight="500" textColor="#3454d1">
              Shifts Started On Time
            </Text>
            <Box
              w="100%"
              h="70%"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
            >
              <Text fontSize="24px" fontWeight="700" textAlign="center">
                {shiftData.countOfOnTimeStartedShifts}
              </Text>
            </Box>
          </GridItem>
          <GridItem w="100%" h="100px" boxShadow="md">
            <Text textAlign="center" fontWeight="500" textColor="#3454d1">
              Shifts Started Late
            </Text>
            <Box
              w="100%"
              h="70%"
              justifyContent="center"
              alignContent="center"
              justifyItems="center"
              alignItems="center"
            >
              <Text fontSize="24px" fontWeight="700" textAlign="center">
                {shiftData.countOfLateStartedShifts}
              </Text>
            </Box>
          </GridItem>
        </Grid>
        <SimpleGrid columns={6} spacing={0} mt={4} display={"none"}>
          {pieData.map((data, index) => (
            <Box
              key={index}
              p={"16px 0"}
              borderWidth={1}
              borderRadius="lg"
              h={"200px"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Doughnut
                data={{
                  labels: ["Category", "Remaining"],
                  datasets: [
                    {
                      data: data.data,
                      backgroundColor: data.backgroundColor,
                      borderColor: data.borderColor,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    datalabels: {
                      display: true,
                      color: "black",
                      align: "center",
                      anchor: "center",
                      // formatter: (value: number, context: any): string => {
                      //   const total = context.dataset.data.reduce(
                      //     (acc: number, val: number) => acc + val,
                      //     0
                      //   );
                      //   const percentage =
                      //     ((value / total) * 100).toFixed(2) + "%";
                      //   return percentage;
                      // },
                    },
                    legend: {
                      display: false,
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </Box>
          ))}
        </SimpleGrid>
        <SimpleGrid columns={6} spacing={0} mt={4} display={"none"}>
          <Text marginTop={"-125px"} textAlign={"center"} fontWeight={"bold"}>
            {shiftData.totalShifts}
          </Text>
          <HStack
            marginTop={"-125px"}
            maxW={"100%"}
            gap={0}
            marginLeft={"-7px"}
            textAlign={"center"}
            alignItems={"flex-start"}
          >
            <Text
              textAlign={"end"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {(shiftData.countOfStartedShifts / shiftData.totalShifts) * 100}%
            </Text>
            <Stack marginTop={"3px"}>
              <FaGripLinesVertical fontSize={"12px"} />
            </Stack>

            <Text
              textAlign={"start"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {100 -
                (shiftData.countOfStartedShifts / shiftData.totalShifts) * 100}
              %
            </Text>
          </HStack>
          <HStack
            marginTop={"-125px"}
            maxW={"100%"}
            gap={0}
            marginLeft={"-7px"}
            textAlign={"center"}
            alignItems={"flex-start"}
          >
            <Text
              textAlign={"end"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {(shiftData.countOfShiftsEnded / shiftData.totalShifts) * 100}%
            </Text>
            <Stack marginTop={"3px"}>
              <FaGripLinesVertical fontSize={"12px"} />
            </Stack>

            <Text
              textAlign={"start"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {100 -
                (shiftData.countOfShiftsEnded / shiftData.totalShifts) * 100}
              %
            </Text>
          </HStack>
          <HStack
            marginTop={"-125px"}
            maxW={"100%"}
            gap={0}
            textAlign={"center"}
            alignItems={"flex-start"}
          >
            <Text
              textAlign={"end"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {(shiftData.countOfShiftsYetToStart / shiftData.totalShifts) *
                100}
              %
            </Text>
            <Stack marginTop={"3px"}>
              <FaGripLinesVertical fontSize={"12px"} />
            </Stack>

            <Text
              textAlign={"start"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {100 -
                (shiftData.countOfShiftsYetToStart / shiftData.totalShifts) *
                  100}
              %
            </Text>
          </HStack>
          <HStack
            marginTop={"-125px"}
            maxW={"100%"}
            gap={0}
            marginLeft={"-7px"}
            textAlign={"center"}
            alignItems={"flex-start"}
          >
            <Text
              textAlign={"end"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {(shiftData.countOfOnTimeStartedShifts / shiftData.totalShifts) *
                100}
              %
            </Text>
            <Stack marginTop={"3px"}>
              <FaGripLinesVertical fontSize={"12px"} />
            </Stack>

            <Text
              textAlign={"start"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {100 -
                (shiftData.countOfOnTimeStartedShifts / shiftData.totalShifts) *
                  100}
              %
            </Text>
          </HStack>
          <HStack
            marginTop={"-125px"}
            maxW={"100%"}
            gap={0}
            marginLeft={"-7px"}
            textAlign={"center"}
            alignItems={"flex-start"}
          >
            <Text
              textAlign={"end"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {(shiftData.countOfLateStartedShifts / shiftData.totalShifts) *
                100}
              %
            </Text>
            <Stack marginTop={"3px"}>
              <FaGripLinesVertical fontSize={"12px"} />
            </Stack>

            <Text
              textAlign={"start"}
              fontWeight={"bold"}
              w={"100%"}
              fontSize={"12px"}
              alignItems={"flex-start"}
            >
              {100 -
                (shiftData.countOfLateStartedShifts / shiftData.totalShifts) *
                  100}
              %
            </Text>
          </HStack>
        </SimpleGrid>
      </Box>
      <Bar
        data={data}
        options={{
          ...options,
          plugins: {
            datalabels: {
              color: "#000",
              font: {
                size: 20,
                weight: "bold",
              },
            },
          },
        }}
        plugins={[ChartDataLabels]}
        style={{
          width: "100%",
          maxHeight: "300px",
          alignSelf: "center",
        }}
      />

      <HStack
        marginTop={"10px"}
        marginBottom={"20px"}
        paddingLeft={"20px"}
        paddingRight={"20px"}
        alignItems={"flex-start"}
      >
        <Stack
          flex={1}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderRadius="4px"
          bg={"#fff"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
          p={"0px"}
          overflowX="auto"
          maxH={"300px"}
        >
          <Stack
            p={"25px"}
            borderBottom={"1px solid #e5e7eb"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.150",
              }}
              lineHeight="1.2"
              fontSize="16px"
              fontWeight={600}
            >
              Shifts Started
            </Text>
          </Stack>

          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Employee ID",
                      value: "25%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Full Name",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Department",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Designation",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {shiftData?.timedInEmployees.length > 0 &&
                  shiftData?.timedInEmployees?.map((emp: any, i: any) => {
                    const empData = employeesList?.find(
                      (employee) => employee.id === emp
                    );

                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {empData?.uniqueId}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Stack direction={"row"} alignItems={"center"}>
                            <Avatar
                              size="sm"
                              objectFit="fill"
                              name={
                                empData?.firstName + " " + empData?.lastName
                              }
                              src={empData?.photo}
                              borderWidth={"2px"}
                              borderColor={"gray.200"}
                            />
                            <Text
                              _hover={{
                                color: "#3454d1",
                              }}
                            >
                              {empData?.firstName + " " + empData?.lastName}
                            </Text>
                          </Stack>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          fontWeight={500}
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {empData?.employeeEmployementDetails?.department}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {empData?.employeeEmployementDetails?.jobTitle}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </Stack>
        <Stack
          flex={1}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderRadius="4px"
          bg={"#fff"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
          p={"0px"}
          overflowX="auto"
          maxH={"300px"}
        >
          <Stack
            p={"25px"}
            borderBottom={"1px solid #e5e7eb"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.150",
              }}
              lineHeight="1.2"
              fontSize="16px"
              fontWeight={600}
            >
              Shifts Not Started
            </Text>
          </Stack>

          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Employee ID",
                      value: "25%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Full Name",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Department",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Designation",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {shiftData?.notTimedInEmployees.length > 0 &&
                  shiftData?.notTimedInEmployees?.map((emp: any, i: any) => {
                    const empData = employeesList?.find(
                      (employee) => employee.id === emp
                    );
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {empData?.uniqueId}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Stack direction={"row"} alignItems={"center"}>
                            <Avatar
                              size="sm"
                              objectFit="fill"
                              name={
                                empData?.firstName + " " + empData?.lastName
                              }
                              src={empData?.photo}
                              borderWidth={"2px"}
                              borderColor={"gray.200"}
                            />
                            <Text
                              _hover={{
                                color: "#3454d1",
                              }}
                            >
                              {empData?.firstName + " " + empData?.lastName}
                            </Text>
                          </Stack>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          fontWeight={500}
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {empData?.employeeEmployementDetails?.department}
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {empData?.employeeEmployementDetails?.jobTitle}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </HStack>
    </Box>
  );
}
