import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Employees } from "../../../Api/Employees";
import { PayRun } from "../../../Api/PayRun";
import { TimeSheetNewDetail } from "../../../Api/TimeSheetDetail";

interface AddTimeSheetProps {
  isOpen: boolean;
  onClose: () => void;
  employeesList: Array<Employees.timeSheetEmployees>;
}
export function AddTimeSheet({
  isOpen,
  onClose,
  employeesList,
}: AddTimeSheetProps) {
  const [selectedEmployee, setSelectedEmployee] = useState<string>();
  const [payPeriods, setPayPeriods] = useState<PayRun.PayPeriodByCalenderID[]>(
    []
  );

  const navigate = useNavigate();

  const form = useFormContext<TimeSheetNewDetail.AddNewDetail>();
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (selectedEmployee) {
      PayRun.FetchPayPeriodByCalenderID(
        selectedEmployee,
        (data) => {
          setPayPeriods(data);
          setLoading(false);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
          setLoading(false);
        }
      );
    }
  }, [selectedEmployee]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text
            fontSize={"18px"}
            padding={"15px 0px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Add Timesheet
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack direction={"row"}>
            <Stack flex={1}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Employee/s
              </Text>
              <Controller
                name="employeeId"
                control={form.control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    placeholder=""
                    withValidation
                    options={employeesList}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            firstName: employeesList.find(
                              (employee) => employee.id === field.value
                            )?.firstName,
                            lastName: employeesList.find(
                              (employee) => employee.id === field.value
                            )?.lastName,
                          }
                        : undefined
                    }
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.id);
                      setSelectedEmployee(
                        newValue?.employeeEmployementDetails
                          ?.payrollPayCalenderId
                      );
                    }}
                    getOptionLabel={(option: any) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    getOptionValue={(option: any) => option}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                color={"customColor.gray.800"}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Period
              </Text>

              <Controller
                control={form.control}
                name="period"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select "
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Pay Period"
                    withValidation
                    options={payPeriods}
                    value={
                      field.value
                        ? {
                            string: field.value,
                          }
                        : undefined
                    }
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.string);
                    }}
                    getOptionLabel={(option: any) => option?.string}
                    getOptionValue={(option: any) => option}
                  />
                )}
              />
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                setLoading(true);
                navigate("/app/payroll/TimesheetsEnterDetail", {
                  state: {
                    employeeId: form.getValues("employeeId"),
                    period: form.getValues("period"),
                    PayrollCalendarId: selectedEmployee,
                    dateRange: {
                      startDate: payPeriods.find(
                        (payPeriod) =>
                          payPeriod.string === form.getValues("period")
                      )?.startDate,
                      endDate: payPeriods.find(
                        (payPeriod) =>
                          payPeriod.string === form.getValues("period")
                      )?.endDate,
                    },
                  },
                });
                setLoading(false);
              }}
            >
              Add
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
