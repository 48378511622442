import { AbsoluteCenter, Box, Center, Image, Spinner } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import HRISLOGO from "./../../../assets/hris-logo.png";
export default function RedirectingBusiness() {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const navigate = useNavigate();
  useEffect(() => {
    if (decodedToken?.accessLevel === "EMPLOYEE") {
      navigate(`/app/payroll/EmployeeDashboard/${decodedToken?.employeeId}`);
    } else {
      navigate("/app/payroll/Dashboard");
    }
  }, []);
  return (
    <Box bgColor="white" height="100vh">
      <AbsoluteCenter>
        <Image src={HRISLOGO} />
        <Box>
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
