import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RiArrowUpDownFill } from "react-icons/ri";

export function Filter() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedSort, setSelectedSort] = useState("All");
  const [openPopover, setOpenPopover] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleConfirmDateRange = () => {
    setOpenPopover(null);
    onClose();
  };

  const handleOpenPopover = (popover: string) => {
    if (openPopover === popover) {
      setOpenPopover(null);
      onClose();
    } else {
      setOpenPopover(popover);
      onOpen();
    }
  };

  const handleMenuItemClick = () => {
    setOpenPopover(null);
    onClose();
  };

  return (
    <Menu isOpen={isOpen} closeOnSelect={false}>
      <MenuButton
        as={Button}
        leftIcon={<RiArrowUpDownFill />}
        alignItems={"center"}
        verticalAlign={"top"}
        lineHeight={"1rem"}
        minH={"40px"}
        minW={"40px"}
        padding={"11px 16px"}
        bg={"transparent"}
        borderRadius={"3px"}
        color={"rgba(0,10,30,.65)"}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
        fontSize={"15px"}
        _hover={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
        _active={{
          bg: "rgba(0,10,30,.05)",
          color: "rgba(0,10,30,.75)",
        }}
        onClick={isOpen ? onClose : onOpen} // Toggle menu open/close
      >
        {selectedSort}
      </MenuButton>
      <MenuList>
        <MenuItem
          fontSize={"15px"}
          color={"customColor.black7"}
          marginBottom={"12px"}
          onClick={() => {
            setSelectedSort("All");
            onClose();
          }}
        >
          All
        </MenuItem>

        <Stack borderTop={"1px solid #ddd"} padding={"12px 0px"}>
          <Text
            padding={"0px 12px 12px"}
            fontSize={"15px"}
            color={"customColor.black7"}
            fontWeight={"700"}
          >
            Filter
          </Text>

          <Popover
            placement="right-start"
            closeOnBlur={false}
            isOpen={openPopover === "type"}
            onClose={() => {
              setOpenPopover(null);
              onClose();
            }}
          >
            <PopoverTrigger>
              <Button
                fontSize={"15px"}
                color={"customColor.black7"}
                fontWeight={"400"}
                variant="ghost"
                justifyContent="flex-start"
                w="full"
                _hover={{
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                }}
                onClick={() => handleOpenPopover("type")}
              >
                Type
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody paddingLeft={0} paddingRight={0}>
                <Stack padding={"12px 0px"}>
                  <Text
                    padding={"0px 12px 12px"}
                    fontSize={"15px"}
                    color={"customColor.black7"}
                    fontWeight={"700"}
                    borderBottom={"1px solid #ddd"}
                  >
                    Type
                  </Text>
                  <MenuItem
                    fontSize={"15px"}
                    onClick={() => {
                      handleMenuItemClick();
                      setSelectedSort("Annual Leave");
                    }}
                  >
                    Annual Leave
                  </MenuItem>
                  <MenuItem
                    fontSize={"15px"}
                    onClick={() => {
                      handleMenuItemClick();
                      setSelectedSort("Personal (Sick/Carer's Leave)");
                    }}
                  >
                    Personal (Sick/Carer's Leave)
                  </MenuItem>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Popover
            placement="right-start"
            closeOnBlur={false}
            isOpen={openPopover === "date"}
            onClose={() => {
              setOpenPopover(null);
              onClose();
            }}
          >
            <PopoverTrigger>
              <Button
                fontSize={"15px"}
                color={"customColor.black7"}
                fontWeight={"400"}
                variant="ghost"
                justifyContent="flex-start"
                w="full"
                _hover={{
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                }}
                onClick={() => {
                  handleOpenPopover("date");
                  setSelectedSort("Date");
                }}
              >
                Date
              </Button>
            </PopoverTrigger>
            <PopoverContent alignItems={"center"}>
              <PopoverBody>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateRangeChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />

                <Button
                  onClick={handleConfirmDateRange}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                >
                  Apply
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Popover
            placement="right-start"
            closeOnBlur={false}
            isOpen={openPopover === "status"}
            onClose={() => {
              setOpenPopover(null);
              onClose();
            }}
          >
            <PopoverTrigger>
              <Button
                fontSize={"15px"}
                color={"customColor.black7"}
                fontWeight={"400"}
                variant="ghost"
                justifyContent="flex-start"
                w="full"
                _hover={{
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                }}
                onClick={() => handleOpenPopover("status")}
              >
                Status
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody paddingLeft={0} paddingRight={0}>
                <Stack padding={"12px 0px"}>
                  <Text
                    padding={"0px 12px 12px"}
                    fontSize={"15px"}
                    color={"customColor.black7"}
                    fontWeight={"700"}
                    borderBottom={"1px solid #ddd"}
                  >
                    Status
                  </Text>
                  <MenuItem
                    fontSize={"15px"}
                    onClick={() => {
                      handleMenuItemClick();
                      setSelectedSort("To Review");
                    }}
                  >
                    To Review
                  </MenuItem>
                  <MenuItem
                    fontSize={"15px"}
                    onClick={() => {
                      handleMenuItemClick();
                      setSelectedSort("Upcoming");
                    }}
                  >
                    Upcoming
                  </MenuItem>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Stack>
      </MenuList>
    </Menu>
  );
}
