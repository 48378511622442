import { Checkbox, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";
import { BusinessDetail } from "../../../Api/BusinessDetail";

interface IncludeCheckboxProps {
  name: string;
  disabled?: boolean;
}
export function IncludeCheckbox({ name, disabled }: IncludeCheckboxProps) {
  const form = useFormContext<BusinessDetail.BusinessDetails>();
  const watchedInvoiceInclusionFields = useWatch({
    control: form.control,
    name: "invoiceInclusionFields",
  });

  return (
    <FormControl fontSize="x-small">
      <HStack align="center" marginBottom="10px">
        <Checkbox
          name={name}
          isDisabled={disabled}
          isChecked={watchedInvoiceInclusionFields?.includes(name)}
          onChange={(e) => {
            const isChecked = e.target.checked;
            if (isChecked) {
              form.setValue("invoiceInclusionFields", [
                ...watchedInvoiceInclusionFields,
                name,
              ]);
            } else {
              form.setValue(
                "invoiceInclusionFields",
                watchedInvoiceInclusionFields.filter((x) => x !== name)
              );
            }
          }}
        />
        <FormLabel m="0px">Include</FormLabel>
      </HStack>
    </FormControl>
  );
}
