import {
  Button,
  ButtonGroup,
  Checkbox,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { IoInformationCircle } from "react-icons/io5";
import { MappingModuleApi } from "../../Api/MappingModule";

import { useEffect } from "react";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";

interface LeaveModalProps {
  isOpen: boolean;
  fetchLeaves: PayItemLeaveApi.LeaveById[];
  employeesList: any[];
  onClose: () => void;
  selectedEmployeeIds: any[] | undefined;
}
export function LeaveModal({
  isOpen,
  onClose,
  selectedEmployeeIds,
  fetchLeaves,
  employeesList,
}: LeaveModalProps) {
  const form = useForm<MappingModuleApi.Leave>({
    defaultValues: {
      payrollPayItemLeaveId: undefined,
      calculationMethod: undefined,
      quantity: undefined,
      terminationUnusedBalance: undefined,
      etpLeaveType: undefined,
      includeRetirementContribution: undefined,
      accruedQuantity: undefined,
      openingBalance: undefined,
      ids: selectedEmployeeIds,
    },
  });
  const leaveCalculationOptions = [
    { value: "FIXED_AMOUNT_EACH_PERIOD", label: "Fixed Amount Each Period" },
    { value: "MANUALLY_RECOREDED_RATE", label: "Manually Recorded Rate" },
    { value: "NO_CALCULATION_REQUIRED", label: "No Calculation Required" },
  ];
  const unusedBalanceOptions = [
    { value: "NOT_PAID_OUT", label: "Not Paid Out" },
    { value: "PAID_OUT", label: "Paid Out" },
  ];
  const watchSelectedLeaveType = useWatch({
    control: form.control,
    name: "payrollPayItemLeaveId",
  });
  const watchLeaveCalculation = useWatch({
    control: form.control,
    name: "calculationMethod",
  });
  const watchUnUsedBalance = useWatch({
    control: form.control,
    name: "terminationUnusedBalance",
  });
  const watchETP = useWatch({
    control: form.control,
    name: "etpLeaveType",
  });
  const PAID_OUT_OPTION = { value: "PAID_OUT", label: "Paid Out" };
  const UNPAID_OUT_OPTION = { value: "NOT_PAID_OUT", label: "Unpaid Out" };
  useEffect(() => {
    const selectedLeave = fetchLeaves?.find(
      (op) => op.id === watchSelectedLeaveType
    );
    if (selectedLeave) {
      const category = selectedLeave.category;
      if (category === "PAID_LEAVE") {
        form.setValue("terminationUnusedBalance", PAID_OUT_OPTION.value);
      } else if (category === "UNPAID_LEAVE") {
        form.setValue("terminationUnusedBalance", UNPAID_OUT_OPTION.value);
      }
    }
  }, [watchSelectedLeaveType, fetchLeaves, form.setValue]);
  const toasts = useToast();
  useEffect(() => {
    if (isOpen) {
      form.reset({
        payrollPayItemLeaveId: undefined,
        calculationMethod: undefined,
        quantity: undefined,
        terminationUnusedBalance: undefined,
        etpLeaveType: undefined,
        includeRetirementContribution: undefined,
        accruedQuantity: undefined,
        openingBalance: undefined,
        ids: selectedEmployeeIds,
      });
    }
  }, [isOpen]);
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"980px"}>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Leave Modal
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            padding={"16px 24px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            border={"1px solid #E5E5E5"}
          >
            <SimpleGrid spacing={10} columns={{ xs: 2, md: 3 }}>
              <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"0.8125rem"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    lineHeight={"1.3077"}
                    margin={"0px 0px 5px"}
                    padding={"1px 0px 0px"}
                  >
                    Leave
                  </Text>
                  <Icon
                    as={IoInformationCircle}
                    color={"#999"}
                    w={"15px"}
                    h={"18px"}
                  />
                </Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Leave Type is required",
                    },
                  }}
                  control={form.control}
                  name="payrollPayItemLeaveId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={fetchLeaves?.map((leave) => ({
                        value: leave.id,
                        label: leave.name,
                      }))}
                      withValidation
                      // isDisabled={form.getValues("id") ? true : false}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Leave Type"
                      placeholder="Select..."
                      value={
                        fetchLeaves?.find((leave) => leave.id === field.value)
                          ? {
                              value: field.value,
                              label: fetchLeaves?.find(
                                (leave) => leave.id === field.value
                              )?.name,
                            }
                          : null
                      }
                      onChange={(selectedOption: any) =>
                        field.onChange(selectedOption.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              {(watchSelectedLeaveType === "CARER'S_UNPAID" ||
                watchSelectedLeaveType === "COMPASSIONATE_PAID" ||
                watchSelectedLeaveType === "LONG_SERVICE" ||
                watchSelectedLeaveType === "OTHERS_UNPAID" ||
                watchSelectedLeaveType === "STUDY_LEAVE" ||
                watchSelectedLeaveType === "COMPASSIONATE_UNPAID") && (
                <Stack gap={0} margin={"0px "} padding={"7px 5px"}>
                  <Stack direction={"row"} gap={0}>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      margin={"0px 0px 5px"}
                      padding={"1px 0px 0px"}
                    >
                      Leave Category
                    </Text>
                    <Icon
                      as={IoInformationCircle}
                      color={"#999"}
                      w={"15px"}
                      h={"18px"}
                    />
                  </Stack>
                  <Text
                    fontSize="13px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    lineHeight={"1.3077"}
                    marginBottom="-4px"
                    padding={"1px 0px 4px"}
                  >
                    {
                      fetchLeaves?.find(
                        (op) => op.id === watchSelectedLeaveType
                      )?.name
                    }
                  </Text>
                </Stack>
              )}

              <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"0.8125rem"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    lineHeight={"1.3077"}
                    margin={"0px 0px 5px"}
                    padding={"1px 0px 0px"}
                  >
                    Leave Calculation Method
                  </Text>
                  <Icon
                    as={IoInformationCircle}
                    color={"#999"}
                    w={"15px"}
                    h={"18px"}
                  />
                </Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Leave Calculation Method is required",
                    },
                  }}
                  control={form.control}
                  name="calculationMethod"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={leaveCalculationOptions}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Leave Calculation Method "
                      placeholder="Select..."
                      value={leaveCalculationOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                        form.reset({
                          ...form.getValues(),
                          // quantityPerPayPeriod: 0,
                          // balance: 0,
                          // openingBalance: 0,
                        });
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>

              {watchLeaveCalculation === "MANUALLY_RECOREDED_RATE" && (
                <Stack margin={"0px"} padding={"7px 5px"} direction={"row"}>
                  <Stack gap={0}>
                    <Stack direction={"row"} gap={0}>
                      <Text
                        fontSize={"0.8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 5px"}
                        padding={"1px 0px 0px"}
                      >
                        {fetchLeaves.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.unitType ?? "Days"}{" "}
                        Entitlement per payrun
                      </Text>
                      <Icon
                        as={IoInformationCircle}
                        color={"#999"}
                        w={"15px"}
                        h={"18px"}
                      />
                    </Stack>

                    <Controller
                      control={form.control}
                      name="quantity"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            h: "38px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            bg: "#fff",
                            borderTopRadius: "3px",
                            onChange: (e) => {
                              field.onChange(Number(e.target.value));
                            },
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              )}

              {(watchLeaveCalculation === "FIXED_AMOUNT_EACH_PERIOD" ||
                watchLeaveCalculation === "NO_CALCULATION_REQUIRED") && (
                <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                  <Stack direction={"row"} gap={0}>
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      margin={"0px 0px 5px"}
                      padding={"1px 0px 0px"}
                    >
                      Annual Entitlement/{" "}
                      {fetchLeaves.find(
                        (op) => op.id === watchSelectedLeaveType
                      )?.unitType ?? "Days"}{" "}
                    </Text>
                    <Icon
                      as={IoInformationCircle}
                      color={"#999"}
                      w={"15px"}
                      h={"18px"}
                    />
                  </Stack>

                  <Controller
                    control={form.control}
                    name="accruedQuantity"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          h: "38px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          onChange: (e) => {
                            field.onChange(Number(e.target.value));
                          },
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Stack>
              )}
              <Stack gap={0} margin={"0px"} padding={"7px 5px"}>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"0.8125rem"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    lineHeight={"1.3077"}
                    margin={"0px 0px 5px"}
                    padding={"1px 0px 0px"}
                  >
                    Opening Balance
                  </Text>
                  <Icon
                    as={IoInformationCircle}
                    color={"#999"}
                    w={"15px"}
                    h={"18px"}
                  />
                </Stack>

                <Controller
                  control={form.control}
                  name="openingBalance"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        h: "38px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        onChange: (e) => {
                          field.onChange(Number(e.target.value));
                        },
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Stack>
              {watchLeaveCalculation === "BASED_ON_ORDINARY_EARNINGS" && (
                <Stack gap={0}>
                  <Stack margin={"0px"} padding={"7px 5px"} gap={0}>
                    <Stack direction={"row"} gap={0}>
                      <Text
                        fontSize={"0.8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        margin={"0px 0px 5px"}
                        padding={"1px 0px 0px"}
                      >
                        Days of leave accrued annually by a full-time employee
                      </Text>
                      <Icon
                        as={IoInformationCircle}
                        color={"#999"}
                        w={"15px"}
                        h={"18px"}
                      />
                    </Stack>

                    <Controller
                      control={form.control}
                      name="quantity"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            h: "38px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                            onChange: (e) => {
                              field.onChange(Number(e.target.value));
                            },
                          }}
                          placeholder=""
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              )}

              {/* {watchLeaveCalculation === "FIXED_AMOUNT_EACH_PERIOD" && (
                <Stack gap={0}>
                  <Stack
                    direction={"row"}
                    // Adjusted gap to better align text and icon
                  >
                    <Text
                      fontSize={"0.8125rem"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      margin={"0px 0px 5px"}
                      padding={"1px 0px 0px"}
                    >
                      {fetchLeaves.find(
                        (op) => op.id === watchSelectedLeaveType
                      )?.unitType ?? "Days"}{" "}
                      Entitlement per payrun
                    </Text>
                    <Icon
                      as={IoInformationCircle}
                      color={"#999"}
                      w={"15px"}
                      h={"18px"}
                    />
                  </Stack>

                  <Controller
                    control={form.control}
                    name="quantity"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          h: "38px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",
                          isDisabled: true,

                          borderTopRadius: "3px",
                          onChange: (e) => {
                            field.onChange(Number(e.target.value));
                          },
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Stack>
              )} */}

              <Stack margin={"0px"} padding={"7px 5px"} gap={0}>
                <Stack direction={"row"} gap={0}>
                  <Text
                    fontSize={"0.8125rem"}
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    lineHeight={"1.3077"}
                    margin={"0px 0px 5px"}
                    padding={"1px 0px 0px"}
                  >
                    On termination unused balance is
                  </Text>
                  <Icon
                    as={IoInformationCircle}
                    color={"#999"}
                    w={"15px"}
                    h={"18px"}
                  />
                </Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: " UnUsed Balance  is required",
                    },
                  }}
                  control={form.control}
                  name="terminationUnusedBalance"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={unusedBalanceOptions}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select unused balance"
                      placeholder="Select..."
                      value={unusedBalanceOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              {watchUnUsedBalance === "PAID_OUT" && (
                <Stack>
                  <Controller
                    control={form.control}
                    name="etpLeaveType"
                    render={({ field }) => (
                      <Checkbox
                        isChecked={field.value}
                        isDisabled={
                          fetchLeaves?.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.category === "PAID_LEAVE"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      >
                        <Text
                          fontSize={"0.8125rem"}
                          fontWeight={"600"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          ETP Leave Type
                        </Text>
                      </Checkbox>
                    )}
                  />
                  {watchETP && (
                    <Controller
                      control={form.control}
                      name="includeRetirementContribution"
                      render={({ field }) => (
                        <Checkbox
                          isChecked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        >
                          <Text
                            fontSize={"0.8125rem"}
                            fontWeight={"600"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Includes Superannuation Guarantee Contribution
                          </Text>
                        </Checkbox>
                      )}
                    />
                  )}
                </Stack>
              )}
            </SimpleGrid>
          </ModalBody>

          <ModalFooter padding={"16px 24px"}>
            <ButtonGroup size="sm" alignItems={"flex-end"}>
              <Button
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={form.handleSubmit((data) => {
                  MappingModuleApi.UpdateLeaveEntitlement(
                    {
                      ...data,
                      ids: selectedEmployeeIds ?? [],
                    },
                    () => {
                      toasts({
                        title: "Leave Entitlement Updated Successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      onClose();
                    },
                    (err) => {
                      toasts({
                        title: "Failed to Update Leave Entitlement",
                        status: "error",
                        description: err,
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  );
                })}
              >
                Update
              </Button>
              <Button
                onClick={onClose}
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"8px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
