import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactSelectTimeSheet } from "../../../../Common/ReactSelectTimeSheet";
import { Employees } from "../../../Api/Employees";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";
import { PayRun } from "../../../Api/PayRun";
import { ProductivityTracker } from "../../../Api/ProductivityTracker";

interface AddProductivityModalProps {
  isOpen: boolean;
  onClose: () => void;
  earningAdded: any;
}
export function AddProductivityModal({
  isOpen,
  onClose,
  earningAdded,
}: AddProductivityModalProps) {
  const [payOptions, setPayOptions] = useState<PayrollCalendar.Calendar[]>([]);
  const [SelectedPay, setSelectedPay] = useState<string>();
  const [SelectedCalender, setSelectedCalnder] = useState<string>();

  const [payCalender, setPayCalender] = useState<Array<Employees.calndersbyid>>(
    []
  );
  const [payPeriods, setPayPeriods] = useState<PayRun.PayPeriodByCalenderID[]>(
    []
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const form = useFormContext<ProductivityTracker.AddNewDetail>();
  const toast = useToast();
  useEffect(() => {
    PayrollCalendar.FetchCalendar(
      (data) => {
        setPayOptions(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    if (SelectedCalender) {
      Employees.CalendersByEarningId(
        SelectedCalender,
        (data) => {
          setPayCalender(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [SelectedCalender]);
  useEffect(() => {
    if (SelectedPay) {
      PayRun.FetchPayPeriodByCalenderID(
        SelectedPay,
        (data) => {
          setPayPeriods(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [SelectedPay]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text
            fontSize={"18px"}
            padding={"15px 0px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Add Productivity
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <Stack direction={"column"}>
            <Stack flex={1}>
              <Text
                fontSize={"12px"}
                margin={"0px 0px 5px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
                fontWeight={"bold"}
              >
                Earnings
              </Text>
              <Controller
                name="earningRateId"
                control={form.control}
                render={({ field }) => (
                  <ReactSelectTimeSheet
                    {...field}
                    placeholder=""
                    withValidation
                    options={earningAdded.map((earning: any) => ({
                      id: earning.id,
                      name: earning.name,
                    }))}
                    value={
                      field.value
                        ? {
                            id: field.value,
                            name: earningAdded.find(
                              (earning: any) => earning.id === field.value
                            )?.name,
                          }
                        : undefined
                    }
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.id);
                      setSelectedCalnder(newValue?.id);
                    }}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />
            </Stack>
            {SelectedCalender && (
              <Stack flex={1}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Calenders
                </Text>
                <Controller
                  name="calenderId"
                  control={form.control}
                  render={({ field }) => (
                    <ReactSelectTimeSheet
                      {...field}
                      placeholder=""
                      withValidation
                      options={payCalender}
                      value={
                        field.value
                          ? {
                              id: field.value,
                              name: payCalender.find(
                                (calender: any) => calender.id === field.value
                              )?.name,
                            }
                          : undefined
                      }
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.id);
                        setSelectedPay(newValue?.id);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </Stack>
            )}
            {SelectedPay && (
              <Stack flex={1}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Period
                </Text>

                <Controller
                  control={form.control}
                  name="period"
                  render={({ field, fieldState }) => (
                    <ReactSelectTimeSheet
                      {...field}
                      placeholder="Select "
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Pay Period"
                      withValidation
                      options={payPeriods}
                      value={
                        field.value
                          ? {
                              string: field.value,
                            }
                          : undefined
                      }
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.string);
                      }}
                      getOptionLabel={(option: any) => option?.string}
                      getOptionValue={(option: any) => option?.id}
                    />
                  )}
                />
              </Stack>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                setIsLoading(true);
                navigate("/app/payroll/TimesheetsEnterQuatityDetail", {
                  state: {
                    earningAdded: earningAdded,
                    earningRateId: form.getValues("earningRateId"),
                    period: form.getValues("period"),
                    calenderId: form.getValues("calenderId"),
                    dateRange: {
                      startDate: payPeriods.find(
                        (payPeriod) =>
                          payPeriod.string === form.getValues("period")
                      )?.startDate,
                      endDate: payPeriods.find(
                        (payPeriod) =>
                          payPeriod.string === form.getValues("period")
                      )?.endDate,
                    },
                  },
                });
                setIsLoading(false);
              }}
            >
              Add
            </Button>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
