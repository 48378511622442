import { useColorMode } from "@chakra-ui/react";
import Select, { StylesConfig } from "react-select";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import { CustomInput } from "./CustomInput";
interface ReactSelectTimeSheetProps
  extends React.ComponentProps<StateManagedSelect> {
  ErrorMessage?: string;
  label?: string;
  withValidation?: boolean;
  isInvalid?: boolean;
}

type SelectTypes = StylesConfig | undefined;
const customSytles = (styles?: SelectTypes) =>
  ({
    ...styles,
    container: (provided, state) => ({
      ...provided,
      display: "block",
      width: "100%",

      borderRadius: "0px",
      zIndex: 0,
      ...styles?.container?.(provided, state),
    }),
    control: (provided, state) => ({
      ...provided,
      w: "100%",
      zIndex: 0,
      minWidth: "100%",

      display: "block",
      borderWidth: "1px",
      borderRadius: "0px",
      fontSize: "12px",
      fontWeight: "400",
      color: "#444444",

      //   borderColor: state.isFocused
      //     ? "var(--chakra-colors-primary-500)"
      //     : "var(--chakra-colors-gray-300)",
      //   "&:hover": { borderColor: "gray.300" },
      borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
      boxShadow: "0 0 0 0 transparent !important",
      ...styles?.control?.(provided, state),
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: "400",

      padding: "13px 10px 12px",
      zIndex: 1000,
      color: state.isSelected
        ? "customColor.white"
        : state.isFocused
        ? "black" // Change color to black on hover
        : "customColor.defaultBlack",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  } as SelectTypes);
export function ReactSelectTimeSheet({
  ErrorMessage,
  label,
  withValidation,
  isInvalid,
  ...SelectProps
}: ReactSelectTimeSheetProps) {
  const { colorMode } = useColorMode();
  return (
    <CustomInput
      label={label}
      withValidation={withValidation}
      validationMessage={ErrorMessage}
      input={{
        isInvalid,
        style: { display: "block" },
      }}
    >
      <Select
        styles={customSytles({
          control: (provided) => ({
            ...provided,

            borderColor: isInvalid ? "red" : provided.borderColor,
          }),
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: colorMode === "light" ? "white" : "#30303a",
            color: colorMode === "light" ? "customColor.defaultBlack" : "white",
          }),
        })}
        {...SelectProps}
        menuPortalTarget={document.body}
        menuPlacement="auto"
      />
    </CustomInput>
  );
}
