import {
  Box,
  Button,
  Checkbox,
  Collapse,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
  SimpleGrid,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaFilter } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { RiLayoutColumnFill } from "react-icons/ri";
import { TbGridDots } from "react-icons/tb";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";

export function BillsTable() {
  const { isOpen, onToggle } = useDisclosure();
  const [dateType, setDateType] = useState();
  const columns = [
    "From",
    "Status",
    "Reference",
    "Date",
    "Due Date",
    "Paid",
    "Due",
    "Currency",
    "Files",
    "Credited",
    "Paid date",
    "Total",
  ];

  const [items, setItems] = useState(columns);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const onDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const onDragOver = (index: number) => {
    if (draggedIndex === null) return;

    const draggedOverItem = items[index];

    // if the item is dragged over itself, ignore
    if (draggedIndex === index) {
      return;
    }

    // filter out the currently dragged item
    let newItems = items.filter((_, idx) => idx !== draggedIndex);

    // add the dragged item after the dragged over item
    newItems.splice(index, 0, items[draggedIndex]);

    setDraggedIndex(index);
    setItems(newItems);
  };

  const onDragEnd = () => {
    setDraggedIndex(null);
  };
  const DateType = [
    {
      value: "ANY",
      label: "Any",
    },
    {
      value: "TRANSACTION_DATE",
      label: "Transaction Date",
    },
    {
      value: "DUE_DATE",
      label: "Due Date",
    },
    {
      value: "PAID_DATE",
      label: "Paid Date",
    },
  ];
  return (
    <Stack
      _light={{
        bg: "customColor.white",
      }}
    >
      <Stack
        gap={0}
        // maxWidth={"1164px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius="4px"
        bg={"#fff"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderWidth="1px"
        borderStyle={"solid"}
        borderColor="#f6f6f6"
        p={"0px"}
      >
        <SimpleGrid
          columns={{ xs: 4, sm: 4, md: 6, lg: 8, xl: 10 }}
          p="25px"
          gap={5}
        >
          <GridItem colSpan={2}>
            <Text
              textAlign={"left"}
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              margin={"0px 0px 8px"}
            >
              Search
            </Text>
            <InputGroup ml={[0, "0"]}>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "38px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
                // onChange={(e) => {
                //   debounced(e.target.value);
                // }}
              />
            </InputGroup>
          </GridItem>

          <GridItem colSpan={2}>
            <CustomInput
              withValidation
              input={{
                w: "100%",
                h: "38px",
                fontSize: ".845rem",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                variant: "outline",
                type: "date",

                borderTopRadius: "3px",
              }}
              placeholder=""
              label="Start Date"
              labelProps={{
                fontSize: ".845rem",
                fontWeight: "600",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <CustomInput
              withValidation
              input={{
                w: "100%",
                h: "38px",
                fontSize: ".845rem",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                variant: "outline",
                type: "date",

                borderTopRadius: "3px",
              }}
              placeholder=""
              label="End Date"
              labelProps={{
                fontSize: ".845rem",
                fontWeight: "600",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Text
              textAlign={"left"}
              fontSize=".845rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              margin={"0px 0px 8px"}
            >
              Date type
            </Text>
            <ReactSelect
              options={DateType}
              withValidation
              placeholder="Select..."
              value={DateType.find((op) => op.value === dateType)}
              onChange={(newValue: any) => {
                setDateType(newValue);
              }}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                marginTop={"25px"}
                h={"38px"}
                color={"#3454d1"}
                fontSize={"15px"}
                bgColor={"customColor.white"}
                rightIcon={<FaFilter />}
                fontWeight={"700"}
                variant={"ghost"}
                _hover={{
                  bg: "#f2f3f4",
                }}
                _active={{
                  bg: "#f2f3f4",
                }}
              >
                Filter
              </MenuButton>
              <MenuList
                overflowY="auto"
                zIndex={"overlay"}
                maxH={"300px"}
                w={"300px"}
                padding={0}
              >
                <Stack h={"60px"} borderBottom={"1px solid #e5e7eb"} gap={0}>
                  <Text
                    padding={"12px"}
                    fontSize={"15px"}
                    color={"customColor.black7"}
                    fontWeight={"700"}
                  >
                    Filter
                  </Text>
                </Stack>
                <Stack gap={0} margin={"16px"}>
                  <Stack direction={"row"} paddingTop={"12px"}>
                    <Checkbox size={"lg"}>
                      <Text
                        fontSize={"15px"}
                        color={"customColor.black7"}
                        fontWeight={"600"}
                      >
                        Deleted & Voided
                      </Text>
                    </Checkbox>
                  </Stack>
                  <Stack
                    direction={"row"}
                    paddingTop={"12px"}
                    marginTop={"16px"}
                  >
                    <Checkbox size={"lg"}>
                      <Text
                        fontSize={"15px"}
                        color={"customColor.black7"}
                        fontWeight={"600"}
                      >
                        Show overdue bills
                      </Text>
                    </Checkbox>
                  </Stack>
                </Stack>
                <Stack
                  borderTop={"1px solid #e5e7eb"}
                  margin={"16px 0 0px 0"}
                  gap={0}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    _hover={{
                      bg: "#f2f3f4",
                    }}
                    cursor={"pointer"}
                    onClick={onToggle}
                    alignItems={"center"}
                  >
                    <Text
                      fontSize={"15px"}
                      color={"customColor.black7"}
                      padding={"12px"}
                    >
                      Documents types
                    </Text>
                    <IconButton
                      aria-label={"sum-button"}
                      icon={
                        isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />
                      }
                      bg={"transparent"}
                      _light={{
                        color: "customColor.black2",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize={"15px"}
                      _hover={{
                        bg: "transparent",
                      }}
                    />
                  </Stack>
                  <Collapse in={isOpen} animateOpacity>
                    {[
                      "Bills",
                      "Credit Notes",
                      "Prepayments",
                      "Overpayment",
                    ].map((item, i) => {
                      return (
                        <Stack padding={"12px 20px"} key={i}>
                          <Checkbox size={"lg"}>
                            <Text
                              fontSize={"15px"}
                              color={"customColor.black7"}
                              fontWeight={"600"}
                            >
                              {item}
                            </Text>
                          </Checkbox>
                        </Stack>
                      );
                    })}
                  </Collapse>
                </Stack>
                <Box
                  borderTop={"1px solid #e5e7eb"}
                  position={"sticky"}
                  zIndex={9999}
                  bottom={0}
                  bgColor={"customColor.white"}
                >
                  <Button
                    margin={"12px 20px 12px 12px"}
                    h={"38px"}
                    color={"#3454d1"}
                    fontSize={"15px"}
                    padding={"5px 12px"}
                    bgColor={"customColor.white"}
                    fontWeight={"700"}
                    variant={"ghost"}
                    _hover={{
                      bg: "#f2f3f4",
                    }}
                    _active={{
                      bg: "#f2f3f4",
                    }}
                  >
                    Reset to default
                  </Button>
                </Box>
              </MenuList>
            </Menu>
          </GridItem>
          <GridItem colSpan={1}>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                marginTop={"25px"}
                h={"38px"}
                color={"#3454d1"}
                fontSize={"15px"}
                bgColor={"customColor.white"}
                rightIcon={<RiLayoutColumnFill />}
                fontWeight={"700"}
                variant={"ghost"}
                _hover={{
                  bg: "#f2f3f4",
                }}
                _active={{
                  bg: "#f2f3f4",
                }}
              >
                Columns
              </MenuButton>
              <MenuList
                overflowY="auto"
                zIndex={"overlay"}
                maxH={"300px"}
                w={"300px"}
                padding={0}
              >
                <Stack borderBottom={"1px solid #e5e7eb"} gap={0}>
                  <Text
                    padding={"12px"}
                    fontSize={"15px"}
                    color={"customColor.black7"}
                    fontWeight={"700"}
                  >
                    Columns
                  </Text>
                  <Text
                    padding={"12px"}
                    fontSize={"13px"}
                    color={"customColor.black7"}
                    fontWeight={"400"}
                  >
                    Select columns to show in the table or drag to reorder
                  </Text>
                </Stack>
                <Stack gap={0} margin={"16px"}>
                  {items.map((item, index) => (
                    <Stack
                      key={item}
                      direction={"row"}
                      paddingTop={"12px"}
                      alignItems={"center"}
                      draggable
                      onDragStart={() => onDragStart(index)}
                      onDragOver={(e) => {
                        e.preventDefault();
                        onDragOver(index);
                      }}
                      onDragEnd={onDragEnd}
                      style={{
                        backgroundColor:
                          draggedIndex === index ? "#f2f3f4" : "",
                        cursor: "move",
                      }}
                    >
                      <TbGridDots />
                      <Checkbox size={"lg"}>
                        <Text
                          fontSize={"15px"}
                          color={"customColor.black7"}
                          fontWeight={"600"}
                        >
                          {item}
                        </Text>
                      </Checkbox>
                    </Stack>
                  ))}
                </Stack>

                <Box
                  borderTop={"1px solid #e5e7eb"}
                  position={"sticky"}
                  zIndex={9999}
                  bottom={0}
                  bgColor={"customColor.white"}
                >
                  <Button
                    margin={"12px 20px 12px 12px"}
                    h={"38px"}
                    color={"#3454d1"}
                    fontSize={"15px"}
                    padding={"5px 12px"}
                    bgColor={"customColor.white"}
                    fontWeight={"700"}
                    variant={"ghost"}
                    _hover={{
                      bg: "#f2f3f4",
                    }}
                    _active={{
                      bg: "#f2f3f4",
                    }}
                    onClick={() => setItems(columns)}
                  >
                    Reset to default
                  </Button>
                </Box>
              </MenuList>
            </Menu>
          </GridItem>
        </SimpleGrid>
        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                borderTopWidth={"1px"}
                borderTopColor="borders.tableBorder"
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {[
                  {
                    name: "From",
                    value: "20%",
                    paddingValue: "8px 26px 8px 30px",
                  },
                  {
                    name: "Status",
                    value: "20%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Reference",
                    value: "20%",
                    paddingValue: "8px 26px 8px 15px",
                  },

                  {
                    name: "Date",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Due Date",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Paid",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Due",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {[
                {
                  from: "Heritage Trust",
                  status: "Awaiting payment",

                  ref: "Sub",
                  date: "29 Jul 2024",
                  dueDate: "29 Aug 2024",
                  paid: "0.00",
                  due: "1690.58",
                },
                {
                  from: "Carlton Technical Books",
                  status: "Draft",

                  ref: "",
                  date: "29 Jul 2024",
                  dueDate: "29 Aug 2024",
                  paid: "0.00",
                  due: "1690.58",
                },
                {
                  from: "Xero",
                  status: "Paid",

                  ref: "",
                  date: "29 Jul 2024",
                  dueDate: "29 Aug 2024",
                  paid: "0.00",
                  due: "1690.58",
                },
              ].map((item, i) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item.from}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Tag
                        fontSize={"11px"}
                        borderRadius={"2px"}
                        color={
                          item?.status === "Awaiting payment"
                            ? "customColor.black7"
                            : item?.status === "Draft"
                            ? "customColor.black2"
                            : item?.status === "Paid"
                            ? "#28649A"
                            : "black"
                        }
                        bg={
                          item?.status === "Awaiting payment"
                            ? "#A6D3BB"
                            : item?.status === "Draft"
                            ? "#000A1E33"
                            : item?.status === "Paid"
                            ? "#fff"
                            : "#fff"
                        }
                        border={"1px solid #e5e7eb"}
                      >
                        {item?.status}
                      </Tag>
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.ref}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.date}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.dueDate}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.paid}
                    </Td>

                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item.due}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Stack borderTop={"1px solid #dcdee4 "}>
          <HStack
            marginTop={"9.35px"}
            padding={"25px"}
            fontSize={"13px"}
            _light={{
              color: "customColor.black2",
            }}
            _dark={{
              color: "customColor.white",
            }}
            justifyContent={"space-between"}
          ></HStack>
        </Stack>
      </Stack>
    </Stack>
  );
}
