import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateCreditBill } from "./CreateCreditBill";

export function CreditBill() {
  const navigate = useNavigate();

  const [cancel, setCancel] = useState(false);
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "#f8f8f8",
      }}
      h={"calc(100vh - 70px)"}
      // h="calc(100vh - 130px)"
      lineHeight={"1"}
      overflowY="auto"
    >
      <Stack
        // boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        border="1px solid #e5e7eb"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        padding={"16px"}
        gap={0}
      >
        <Container padding={0} maxW="container.md">
          <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink href="" fontSize={"13px"} color={"#3454d1"}>
                Purchase overview
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="" fontSize={"13px"} color={"#3454d1"}>
                Credit Bill
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Text
            _light={{ color: "customColor.black7" }}
            fontSize={"24px"}
            fontWeight={"700"}
          >
            New Credit Note
          </Text>
        </Container>
      </Stack>

      <Container maxW="container.lg" padding={"24px 0px"}>
        <CreateCreditBill />

        <Stack paddingTop={"16px"} paddingBottom={"32px"}>
          <Text
            _light={{
              color: "customColor.gray.64748B",
            }}
            fontSize={"12px"}
            fontWeight={"600"}
            paddingBottom={"15px"}
          >
            History & Notes
          </Text>
          <Box>
            <Button
              borderRadius={"3px"}
              borderRightRadius={0}
              backgroundColor={"#fff"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              textColor={"customColor.black7"}
              padding={"12px 16px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
                color: "#3454D1",
              }}
              onClick={() => {
                setCancel(!cancel);
              }}
            >
              Add Note
            </Button>
            {cancel && (
              <Stack gap={0}>
                <Text
                  paddingTop={"15px"}
                  paddingBottom={"3px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"12px"}
                >
                  Note
                </Text>

                <Textarea
                  w={"350px"}
                  bg={"#fff"}
                  border={"1px solid #e5e7eb"}
                  _hover={{
                    border: "1px solid hsl(0, 0%, 70%)",
                  }}
                  _focus={{
                    borderWidth: "1px",

                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  _focusVisible={{
                    borderWidth: "1px",
                    boxShadow: "0 0 0 1px #2684FF",
                    _hover: {
                      borderWidth: "1px",
                      borderColor: "#2684FF",
                      boxShadow: "0 0 0 1px #2684FF",
                    },
                  }}
                  borderRadius={"0"}
                />
                <Stack
                  _light={{
                    bg: "#fff",
                  }}
                  direction={"row"}
                  padding={"8px 0px 10px"}
                  w={"350px"}
                >
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                  >
                    {"Save"}
                  </Button>
                  <Button
                    backgroundColor={"#fff"}
                    borderRadius={"3px"}
                    border={"1px solid #dcdee4"}
                    fontSize={"13px"}
                    variant={"solid"}
                    textColor={"#ea4d4d"}
                    padding={"12px 16px"}
                    marginRight={"8px"}
                    fontWeight={600}
                    _hover={{
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      setCancel(!cancel);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
}
