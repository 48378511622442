import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

export function FinancialYear() {
  const [selectedFinancialYear, setSelectedOptionFinancialYear] =
    useState("2024");

  const handleOptionSelectFinancialYear = (option: any) => {
    setSelectedOptionFinancialYear(option);
  };
  const FinancialYearOptions = [
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ];
  return (
    <Stack
      margin={"20px 0px 0px"}
      padding={"0px 0px 0px 10px"}
      direction={"row"}
      alignItems={"center"}
    >
      <Text
        fontSize="12px"
        display={"block"}
        fontWeight="700"
        _light={{
          color: "customColor.black2",
        }}
        _dark={{
          color: "customColor.white",
        }}
        w={"100%"}
      >
        Financial Year
      </Text>

      <Box width={"100%"}>
        <HStack>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              w={"157px"}
              textAlign={"start"}
              h={"25px"}
              _light={{
                bgColor: "customColor.white",
              }}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              color={"black"}
              fontWeight={"400"}
              border={"1px solid #b7bec5"}
              borderRadius={"5px"}
              _hover={{
                color: "black",
                bg: "#f2f3f4",
                border: "1px solid #a6a9b0",
              }}
              _active={{
                color: "black",
                bg: "#f2f3f4",
                border: "1px solid #a6a9b0",
              }}
            >
              {selectedFinancialYear}
            </MenuButton>
            <MenuList
              overflowY="auto"
              zIndex={"overlay"}
              overflow={"auto"}
              maxH={"300px"}
            >
              {FinancialYearOptions.map((item, i) => {
                return (
                  <MenuItem
                    key={1}
                    padding={"0px 12px"}
                    _hover={{
                      backgroundColor: "rgba(0,10,30,.1)",
                    }}
                    onClick={() => handleOptionSelectFinancialYear(item)}
                  >
                    <Text
                      padding={"8px"}
                      margin={"0px 12px 0px 0px"}
                      _light={{
                        color: "customColor.black5",
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontSize={"15px"}
                    >
                      {item}
                    </Text>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </HStack>
      </Box>
    </Stack>
  );
}
