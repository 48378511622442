import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";

export function OpeningBalance() {
  const [value, setValue] = React.useState("0");
  return (
    <Stack gap={0}>
      <Box padding={"20px"}>
        <Heading fontSize={"17px"}>Employee opening balances</Heading>
      </Box>
      <Box padding={"16px"} gap={0}>
        <Text
          margin={"0px 0px 16px"}
          fontSize={"15px"}
          color="customColor.black"
        >
          Opening balances are what you have paid your employees during the tax
          year before they were added to HRIS-360. If you've already reported
          year-to-date payroll information to the ATO then these balances don't
          need to be reported again through HRIS-360.
        </Text>
        <Text fontSize={"13px"} fontWeight={"700"} color="customColor.black">
          In your previous payroll solution were year-to-date values reported to
          the ATO?
        </Text>
      </Box>
      <Stack padding={"16px"} spacing={0}>
        <RadioGroup onChange={setValue} value={value}>
          <Stack
            // paddingTop={"16px"}
            // paddingLeft={"7px"}
            // paddingRight={"7px"}
            // paddingBottom={"16px"}
            padding={"23px 7px 23px 7px"}
            border={"1px solid #a6a9b0"}
            borderTopRadius={"3px"}
            gap={0}
          >
            <Radio
              size="md"
              value="1"
              colorScheme="blue"
              fontSize={"0.9375rem"}
              fontWeight={"700"}
              lineHeight={"1.5rem"}
              alignItems={"flex-start"}
            >
              <Text
                alignItems={"flex-start"}
                marginTop={"-5px"}
                fontWeight={"700"}
                fontSize={"15px"}
              >
                Year-to-date amounts were reported via STP or PSAR
              </Text>
              <Text
                fontSize={"15px"}
                margin={"15px 0px 0px"}
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight={"400"}
              >
                Opening balances will be excluded from future STP submission so
                that they aren't reported twice.
              </Text>
              <Stack
                bg={"#f2f3f4"}
                border={"1px solid #a6a9b0"}
                borderRadius={"3px"}
                padding={"16px"}
                marginTop={"16px"}
              >
                <HStack gap={0} alignItems={"flex-start"}>
                  <Icon as={AiFillInfoCircle} />
                  <Text
                    lineHeight={"1.5rem"}
                    fontSize={"13px"}
                    color={"customColor.black2"}
                    _dark={{
                      color: "customColor.dark.200",
                    }}
                    margin={"-5px 0px 0px 12px"}
                  >
                    If year-to-date amounts were not finalised in your previous
                    payroll software, you may need to report previous key
                    identifiers to the ATO.{" "}
                    <Link>
                      Learn about migrating to HRIS-360 Payroll
                      <Icon as={BsBoxArrowUpRight} />
                    </Link>
                  </Text>
                </HStack>
              </Stack>
            </Radio>
          </Stack>
          <Stack
            borderLeft={"1px solid #a6a9b0"}
            borderRight={"1px solid #a6a9b0"}
            borderBottom={"1px solid #a6a9b0"}
            borderBottomRadius={"3px"}
            padding={"23px 7px 23px 7px"}
          >
            <Radio
              size="md"
              value="2"
              colorScheme="blue"
              fontSize={"0.9375rem"}
              fontWeight={"700"}
              lineHeight={"1.5rem"}
              alignItems={"flex-start"}
            >
              <Text
                alignItems={"flex-start"}
                marginTop={"-5px"}
                fontWeight={"700"}
                fontSize={"15px"}
              >
                Year-to-date amounts were not reported, or reported as nil
                amounts
              </Text>
              <Heading
                fontSize={"15px"}
                margin={"15px 0px 0px"}
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight={"400"}
              >
                Opening balances will be included in future STP submissions so
                that the yearly total is reported.
              </Heading>
            </Radio>
          </Stack>
        </RadioGroup>
      </Stack>
      <Stack padding={"16px"}>
        <HStack gap={0}>
          <Icon />
          <Text paddingLeft={"8px"} fontWeight={"700"} fontSize={"15px"}>
            Need Help?
          </Text>
          <Text fontSize={"15px"} paddingLeft={"3px"}>
            Find out more about{" "}
            <Link fontSize={"15px"}>reporting opening balances with STP.</Link>
          </Text>
        </HStack>
      </Stack>
      <Stack
        padding={"16px"}
        alignItems={"end"}
        borderTop={"1px solid #ccced2"}
      >
        <Button
          minH={"36px"}
          padding={"11px 16px"}
          margin={"0px 0px 0px 12px"}
          borderRadius={"3px"}
        >
          Save Changes
        </Button>
      </Stack>
    </Stack>
  );
}
