import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { ChartOfAccounts } from "../../../../../Api/ChartOfAccounts";
import { PayItemReimbursementApi } from "../../../../../Api/PayItemReinbursement";

interface AddModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedReimbursementId: string | undefined;
}

export default function AddModal({
  isOpen,
  onClose,
  selectedReimbursementId,
  setLastUpdated,
}: AddModalProps) {
  const [account, setAccount] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setAccount(res);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      },
      "LIABILITY"
    );
  }, []);

  const form = useFormContext<PayItemReimbursementApi.AddNewReimbursement>();
  const toast = useToast();
  return (
    <FormProvider {...form}>
      <Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            maxWidth={"350px"}
          >
            <ModalHeader
              fontSize={"15.21px"}
              margin={"15.21px 0pxs"}
            ></ModalHeader>
            <ModalCloseButton />
            <ModalBody
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                src={
                  "https://edge.xero.com/people/payroll/assets/images/settings/pay-items/reimbursements.svg"
                }
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              />
              <Heading
                fontSize={"15px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                margin={"15px 0px"}
                textAlign={"center"}
                justifyContent={"center"}
              >
                Reimbursement
              </Heading>
            </ModalBody>
            <ModalBody>
              <Text
                fontSize={"13px"}
                padding={"1px 0px 4px"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
                mb={"5px"}
              >
                Reimbursement Name
              </Text>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Reimbursement Name is required",
                  },
                }}
                control={form.control}
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      fontSize: "15px",
                      variant: "outline",
                      type: "text",
                      // w:"",
                      mb: "-2px",

                      borderRadius: "5px",
                      padding: "12px 15px",
                      boxShadow: "none",
                      placeholder: "",
                    }}
                  />
                )}
              />
            </ModalBody>
            <ModalBody>
              <Text
                fontSize={"13px"}
                padding={"1px 0px 4px"}
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight={"600"}
              >
                Account
              </Text>

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Account type is required",
                  },
                }}
                control={form.control}
                name="chartAccountId"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    placeholder="Select"
                    // marginBottom="30px"
                    // width={"48em"}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Account type"
                    withValidation
                    options={account}
                    value={{
                      id: field.value,
                      name:
                        account.find((account) => account.id === field.value)
                          ?.name ?? "",
                    }}
                    onChange={(newValue: any) => field.onChange(newValue?.id)}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                  />
                )}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onClose}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
              <Button
                marginLeft={"8px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (selectedReimbursementId) {
                      PayItemReimbursementApi.UpdateReimbursement(
                        selectedReimbursementId,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Leave is updated",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayItemReimbursementApi.NewReimbursementAdded(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Leave is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {selectedReimbursementId ? "Update" : "Add"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </FormProvider>
  );
}
