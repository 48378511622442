import {
  Alert,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { CustomInput } from "../../../Common/CustomInput";
import { Leaves } from "../../Api/Leaves";

interface MonthlyCalendarProps {
  onDateSelect: (range: { start: Moment | null; end: Moment | null }) => void;
}

export function MonthlyCalendar({ onDateSelect }: MonthlyCalendarProps) {
  const [showAlert, setShowAlert] = useState(true);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    null
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(null);

  const startDate: Moment = moment(currentMonth).startOf("month");
  const endDate: Moment = moment(currentMonth).endOf("month");
  const daysInMonth: number = endDate.diff(startDate, "days") + 1;
  const firstDayOfMonth: number = startDate.day();
  const lastDayOfMonth: number = 6 - endDate.day();
  const dates: Moment[] = Array.from({ length: daysInMonth }, (_, index) =>
    moment(startDate).add(index, "days")
  );

  const prevDates: Moment[] = Array.from({ length: firstDayOfMonth }, (_, i) =>
    moment(startDate).subtract(i + 1, "days")
  ).reverse();
  const upComingDates: Moment[] = Array.from(
    { length: lastDayOfMonth },
    (_, i) => moment(endDate).add(i + 1, "days")
  );

  const days: Moment[] = prevDates.concat(dates.concat(upComingDates));
  const weeksInMonth: number = Math.ceil((daysInMonth + firstDayOfMonth) / 7);
  const daysOfWeek: string[] = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];
  const form = useFormContext<Leaves.CreateLeavesBulk>();
  const watchSelectedLeaveCategory = useWatch({
    control: form.control,
    name: "leaveRequest.leaveCategory",
  });
  const handlePrevMonth = (): void => {
    setCurrentMonth(moment(currentMonth).subtract(1, "month").toDate());
  };

  const handleNextMonth = (): void => {
    setCurrentMonth(moment(currentMonth).add(1, "month").toDate());
  };

  const handleDateClick = (date: Moment): void => {
    if (watchSelectedLeaveCategory === "CASHOUT_LEAVE") {
      return;
    }

    const isCurrentMonth = date.month() === currentMonth.getMonth();

    if (!isCurrentMonth) {
      // Allow selecting only a start date from a previous month, but not a range
      setSelectedStartDate(date);
      setSelectedEndDate(null);
      onDateSelect({ start: date, end: null });
      return;
    }

    if (!selectedStartDate) {
      // If no start date is selected, set the clicked date as the start date
      setSelectedStartDate(date);
      setSelectedEndDate(null);
      onDateSelect({ start: date, end: null });
    } else if (selectedStartDate && !selectedEndDate) {
      // Allow selecting the same date as both start and end for a 1-day leave
      if (date.isBefore(selectedStartDate, "day")) {
        alert("End date cannot be before the start date");
        return;
      }
      // Set the clicked date as the end date
      setSelectedEndDate(date);
      onDateSelect({ start: selectedStartDate, end: date });
    } else {
      // Reset if both start and end dates are selected, and set the new start date
      setSelectedStartDate(date);
      setSelectedEndDate(null);
      onDateSelect({ start: date, end: null });
    }
  };

  return (
    <Stack
      opacity={watchSelectedLeaveCategory === "CASHOUT_LEAVE" ? 0.5 : 1}
      cursor={
        watchSelectedLeaveCategory === "CASHOUT_LEAVE"
          ? "not-allowed"
          : "pointer"
      }
      padding={"16px"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      boxShadow={
        "0 3px 3px -2px rgba(39,44,51,.1), 0 3px 4px 0 rgba(39,44,51,.04), 0 1px 8px 0 rgba(39,44,51,.02)"
      }
      border={"1px solid #dfe2e6"}
      borderRadius={".5rem"}
      transition={"box-shadow .28s cubic-bezier(.4,0,.2,1)"}
    >
      <Text
        fontSize={"13px"}
        _light={{
          // color: "customColor.calendar.100",
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.white",
        }}
        fontWeight={"700"}
        margin={"0px 0px 8px"}
      >
        LEAVE PERIOD
      </Text>
      <CustomInput
        withValidation
        input={{
          w: "100%",
          fontSize: "15px",
          variant: "outline",
          type: "text",
          border: "1px solid #edf0f2",
          borderTopRadius: "3px",
          value:
            selectedStartDate && selectedEndDate
              ? `${selectedStartDate.format(
                  "D MMM YYYY"
                )} - ${selectedEndDate.format("D MMM YYYY")}`
              : selectedStartDate
              ? `Start: ${selectedStartDate.format("D MMM YYYY")}`
              : "",
          isDisabled: true,
        }}
        // placeholder={
        //   selectedStartDate && selectedEndDate
        //     ? `${selectedStartDate.format(
        //         "D MMM YYYY"
        //       )} - ${selectedEndDate.format("D MMM YYYY")}`
        //     : selectedStartDate
        //     ? `Start: ${selectedStartDate.format("D MMM YYYY")}`
        //     : ""
        // }
      />
      {showAlert && (
        <Alert status="info">
          <AlertIcon />
          Select a start and end date as it is a range
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setShowAlert(false)}
          />
        </Alert>
      )}
      <Stack direction="row" justifyContent="space-between" gap={0}>
        <Stack padding={"0px 8px"}>
          <Button
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            onClick={handlePrevMonth}
            as={IconButton}
            icon={
              <MdOutlineKeyboardArrowLeft color={"#283C50"} fontSize={"30px"} />
            }
            border={"1px solid #E5E5E5"}
            padding={"0px "}
          />
        </Stack>

        <Stack direction={"row"} gap={0}>
          <Text
            fontSize={"18.9px"}
            _light={{
              // color: "customColor.calendar.50",
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.pearlWhite",
            }}
            fontWeight={"700"}
            margin={"0px 0px 0px 5.3698px"}
          >
            {moment(currentMonth).format("MMMM ")}
          </Text>
          <Text
            fontSize={"18.9px"}
            _light={{
              // color: "customColor.calendar.50",
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.pearlWhite",
            }}
            fontWeight={"400"}
            padding={"0px 0px 0px 5.30527px"}
          >
            {moment(currentMonth).format(" YYYY")}
          </Text>
        </Stack>
        <Stack padding={"0px 8px"}>
          <Button
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            onClick={handleNextMonth}
            as={IconButton}
            icon={
              <MdOutlineKeyboardArrowRight
                color={"#283C50"}
                fontSize={"30px"}
              />
            }
            padding={"0px"}
            border={"1px solid #E5E5E5"}
          />
        </Stack>
      </Stack>

      <TableContainer
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        overflowY="auto"
      >
        <Table>
          <Thead
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <Tr margin={"8px 0px"} padding={0}>
              {daysOfWeek.map((day, i) => (
                <Th p={0} border={"none"} key={i} w="14.2%">
                  <Stack
                    spacing="1px"
                    py="5px"
                    padding={0}
                    borderLeft="1px"
                    border={"none"}
                    textAlign="center"
                  >
                    <Text
                      fontSize="12.6px"
                      fontWeight={700}
                      _light={{
                        color: "customColor.calendar.150",
                      }}
                      _dark={{
                        color: "customColor.cream",
                      }}
                      padding={"3px"}
                    >
                      {day}
                    </Text>
                  </Stack>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.from({ length: weeksInMonth }).map((_, i) => (
              <Tr key={i}>
                {daysOfWeek.map((_, k) => {
                  const date: Moment = days[i * 7 + k];

                  const isCurrentMonth: boolean =
                    date.month() === currentMonth.getMonth();

                  return (
                    <Td
                      key={k}
                      padding={"3px"}
                      border={"none"}
                      role="group"
                      onClick={() => handleDateClick(date)}
                      _hover={{
                        bg: date.isSame(moment(), "day") ? "none" : "#f3f5f9",
                      }}
                    >
                      <Box
                        minW={"59px"}
                        minH={"39px"}
                        textAlign="center"
                        justifyItems={"center"}
                        alignItems="center"
                        justifyContent="center"
                        borderLeft="1px"
                        border={"none"}
                        h="100%"
                        overflowY="auto"
                        backgroundColor={
                          selectedStartDate &&
                          selectedEndDate &&
                          date.isBetween(
                            selectedStartDate,
                            selectedEndDate,
                            null,
                            "[]"
                          )
                            ? "#B2D2FF"
                            : selectedStartDate &&
                              date.isSame(selectedStartDate, "day")
                            ? "blue.300"
                            : date.isSame(moment(), "day")
                            ? "#3454D1"
                            : "#F8F9FA80"
                        }
                      >
                        <Text
                          minW="20px"
                          fontSize="14px"
                          fontWeight={400}
                          paddingTop={"10px"}
                          alignSelf="end"
                          flex={1}
                          lineHeight={1.5}
                          color={
                            date.isSame(moment(), "day")
                              ? "#fff"
                              : isCurrentMonth
                              ? "#283C50"
                              : "#808080"
                          }
                        >
                          {date?.format("D")}
                        </Text>
                      </Box>
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
