import { HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useAuthHeader } from "react-auth-kit";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayTemp } from "../../../Api/PayTemp";

interface RetirementFileProps {
  form?: any;
  retirementFieldArray?: any;
  currentEmployeeId?: string | undefined;
  retirementLine?: any;
  statutoryAdded: any[];

  setResetData?: (resetData: any) => void;
}
export function RetirementFile({
  form,
  retirementFieldArray,

  retirementLine,
  statutoryAdded,
  currentEmployeeId,

  setResetData,
}: RetirementFileProps) {
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  const toast = useToast();
  // useEffect(() => {
  //   if (setstatutoryAdded) {
  //     setstatutoryAdded(statutoryAdded);
  //   }
  // }, []);
  return (
    <Stack>
      <Text
        fontSize={"12px "}
        margin={"0px 0px 4px"}
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontWeight={"700"}
      >
        Statuary Benefit (Employer Contribution)
      </Text>
      {retirementFieldArray?.fields.map(
        (item: PayTemp.RetirementRate & { uniqueId: string }, i: any) => (
          <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
            <Stack flex={1}>
              <Text
                color={"primary.950"}
                padding={"6px 0px 0px"}
                fontSize={"12px"}
                borderTop={"1px solid #CCC !important"}
                borderBottom={"1px solid #E8EAEC !important"}
                marginBottom={"5px"}
                _hover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (decodedToken.accessLevel !== "EMPLOYEE") {
                    retirementLine.onOpen();
                  }

                  if (setResetData) {
                    setResetData({ ...item, index: i });
                  }
                }}
              >
                {(statutoryAdded &&
                  statutoryAdded.find(
                    (statutoryAdded) =>
                      statutoryAdded.id === item.payItemRetirementId
                  )?.name) ??
                  ""}
                {/* {form.getValues(`reimbursments.${i}.description`) && ": "}
                {form.getValues(`reimbursments.${i}.description`)} */}
              </Text>
            </Stack>

            {form.getValues(`retirements.${i}.calculationType`) ===
              "EMPLOYER_PROVIDENT_FUND" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          _light: {
                            color: "customColor.black7",
                          },
                          height: "26.33px",

                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`retirements.${i}.calculationType`) ===
              "GRATUITY" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`retirements.${i}.calculationType`) ===
              "SOCIAL_SECURITY" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          _light: {
                            color: "customColor.black7",
                          },
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Minimum Wage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.minimumWage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          isDisabled: true,
                          _light: {
                            color: "customColor.black7",
                          },
                          placeholder: "",
                        }}
                        label="Minimum Wage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <CustomInput
                    withValidation
                    input={{
                      fontSize: "12px",
                      variant: "outline",
                      bg: "#fff",
                      type: "text",
                      value: Number(
                        (form.watch(`retirements.${i}.minimumWage`) *
                          form.watch(`retirements.${i}.percentage`)) /
                          100
                      ).toLocaleString(),
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",

                      isDisabled: true,
                      maxW: "91.33px",
                      height: "26.33px",
                      padding: "12px 15px",
                      boxShadow: "none",
                      _light: {
                        color: "customColor.black7",
                      },
                      placeholder: "",
                      _hover: {
                        borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                      },
                    }}
                    label="Amount"
                    labelProps={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      _light: {
                        color: "customColor.black7",
                      },
                      margin: "0px 0px 4px",
                    }}
                  />
                </HStack>
              </Stack>
            )}
            {form.getValues(`retirements.${i}.calculationType`) ===
              "EMPLOYER_EOBI" && (
              <Stack alignItems={"flex-end"}>
                <HStack>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.percentage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },
                          isDisabled: decodedToken.accessLevel === "EMPLOYEE",
                          maxW: "91.33px",
                          height: "26.33px",
                          _light: {
                            color: "customColor.black7",
                          },
                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                        label="Percentage "
                        labelProps={{
                          fontSize: "12px",

                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Minimum Wage is required",
                      },
                    }}
                    control={form.control}
                    name={`retirements.${i}.minimumWage`}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "12px",
                          variant: "outline",
                          bg: "#fff",
                          type: "text",
                          value: field.value
                            ? Number(field.value).toLocaleString()
                            : "",
                          onChange: (e: any) => {
                            const value = e.target.value.replace(/,/g, "");
                            field.onChange(Number(value));
                          },

                          maxW: "91.33px",
                          height: "26.33px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          boxShadow: "none",
                          isDisabled: true,
                          placeholder: "",
                        }}
                        label="Minimum Wage "
                        labelProps={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    )}
                  />
                  <CustomInput
                    withValidation
                    input={{
                      fontSize: "12px",
                      variant: "outline",
                      bg: "#fff",
                      type: "text",
                      value: Number(
                        (form.watch(`retirements.${i}.minimumWage`) *
                          form.watch(`retirements.${i}.percentage`)) /
                          100
                      ).toLocaleString(),
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",

                      isDisabled: true,
                      maxW: "91.33px",
                      _light: {
                        color: "customColor.black7",
                      },
                      height: "26.33px",
                      padding: "12px 15px",
                      boxShadow: "none",
                      placeholder: "",
                      _hover: {
                        borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                      },
                    }}
                    label="Amount"
                    labelProps={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      _light: {
                        color: "customColor.black7",
                      },
                      margin: "0px 0px 4px",
                    }}
                  />
                </HStack>
              </Stack>
            )}
            {decodedToken.accessLevel !== "EMPLOYEE" && (
              <Stack>
                <Icon
                  fontSize={"20px"}
                  as={IoIosCloseCircleOutline}
                  marginTop={"15px"}
                  color={"grey"}
                  _hover={{
                    color: "red.500",
                  }}
                  onClick={() => retirementFieldArray.remove(i)}
                />
              </Stack>
            )}
          </Stack>
        )
      )}
    </Stack>
  );
}
