import {
  AbsoluteCenter,
  Avatar,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Notifications } from "../../API/notifications";
import NotificationDeleteModal from "./deleteModal";

export default function NotificationList() {
  const navigate = useNavigate();
  const deleteModal = useDisclosure();
  const [notificationId, setNotificationId] = useState<string>("");
  const [isLoading, setIsLoading] = useBoolean(true);
  const [data, setData] = useState<Array<Notifications.Notification>>([]);
  const toast = useToast({
    position: "bottom",
  });
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  useEffect(() => {
    Notifications.Fetch(
      (data) => {
        setIsLoading.off();
        setData(data);
      },
      (error) => {
        setIsLoading.off();
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);
  return (
    <VStack
      align="flex-start"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Container padding={"0px 10px"} maxW={"container.xl"}>
        <HStack justify="space-between" w="full" padding={"15px 8px 0px 0px"}>
          <Heading
            fontSize={"25px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Notifications
          </Heading>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              navigate("/app/TimeAttendance/notification");
            }}
          >
            Create Notification
          </Button>
        </HStack>
        {isLoading ? (
          <AbsoluteCenter>
            <Spinner size="lg" color="secondary.500" />
          </AbsoluteCenter>
        ) : (
          <Fragment>
            <NotificationDeleteModal
              isOpen={deleteModal.isOpen}
              onClose={deleteModal.onClose}
              onDelete={() => {
                Notifications.Delete(
                  notificationId,
                  () => {
                    setLastUpdated(Date.now());
                    deleteModal.onClose();
                    toast({
                      title: "Success",
                      description: "Notification Deleted",
                      status: "success",
                    });
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                  }
                );
              }}
            />
            <Stack padding={"10px 0px"} gap={0} w={"100%"}>
              <Stack
                color={"customColor.gray.800"}
                fontSize={"16px"}
                _dark={{
                  bgColor: "customColor.dark.50",
                  color: "customColor.dark.250",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
                gap={0}
                border="1px solid #f6f6f6"
                borderRadius="4px"
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              >
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      {[
                        {
                          name: "Title",
                          value: "15%",
                          paddingValue: "8px 26px 8px 30px",
                        },
                        {
                          name: "Employees",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Locations",
                          value: "30%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Reminders",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Status",
                          value: "10%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Created on",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                      ].map((label, i) => (
                        <Th
                          key={i}
                          padding={label.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={label.value}
                        >
                          {label.name}
                        </Th>
                      ))}

                      {/* <Th
                        display={{ sm: "none", md: "table-cell" }}
                        maxW="200px"
                        px="12px"
                        borderBottomColor="#f5f6f8"
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        _light={{
                          color: "customColor.black",
                        }}
                        fontWeight={600}
                        whiteSpace="nowrap"
                        fontSize="14.4px"
                        textTransform="none"
                      ></Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isLoading ? (
                      <Tr py="12px">
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          colSpan={6}
                        >
                          <Center>
                            <Spinner size="lg" color="secondary.500" />
                          </Center>
                        </Td>
                      </Tr>
                    ) : data.length > 0 ? (
                      data.map((notification, i) => {
                        return (
                          <Tr
                            key={i}
                            _hover={{
                              bgColor: "#f2f3f8",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              //   onRowClick(inspection);
                            }}
                            backgroundColor="white"
                          >
                            <Td
                              display={{ sm: "none", md: "table-cell" }}
                              padding="15px 15px 15px 30px"
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              fontWeight={500}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {notification.title}
                            </Td>
                            <Td
                              display={{ sm: "none", md: "table-cell" }}
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <Avatar
                                w="30px"
                                h="30px"
                                p="16px"
                                textColor="gray.500"
                                fontSize="12px"
                                fontWeight={400}
                                name={notification.employees.length.toString()}
                                backgroundColor="gray.100"
                              />
                            </Td>
                            <Td
                              display={{ sm: "none", md: "table-cell" }}
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <Avatar
                                w="30px"
                                h="30px"
                                p="16px"
                                textColor="gray.500"
                                fontSize="12px"
                                fontWeight={400}
                                name={notification.locations.length.toString()}
                                backgroundColor="gray.100"
                              />
                            </Td>

                            <Td
                              display={{ sm: "none", md: "table-cell" }}
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {notification.reminders.join(", ")} (mins)
                            </Td>
                            <Td
                              display={{ sm: "none", md: "table-cell" }}
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {notification.turnOn ? "Active" : "Paused"}
                            </Td>
                            <Td
                              display={{ sm: "none", md: "table-cell" }}
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {moment
                                .unix(notification.createdAt)
                                .format("DD MMM YYYY")}
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <Menu placement="bottom-end">
                                <MenuButton
                                  as={IconButton}
                                  // borderRightRadius="8px"
                                  backgroundColor="transparent"
                                  _hover={{ backgroundColor: "transparent" }}
                                  _active={{ backgroundColor: "transparent" }}
                                  aria-label="dropdown menu"
                                  icon={
                                    <FiSettings color="#545f70" size={20} />
                                  }
                                />
                                <MenuList minW="100px">
                                  <MenuItem
                                    fontSize="13px"
                                    color={"customColor.black7"}
                                    onClick={() => {
                                      navigate(
                                        `/app/TimeAttendance/notification/${notification.id}`
                                      );
                                    }}
                                  >
                                    Edit Schedule
                                  </MenuItem>
                                  <MenuItem
                                    fontSize="13px"
                                    color={"customColor.black7"}
                                    onClick={() => {
                                      setNotificationId(notification.id);
                                      deleteModal.onOpen();
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                  <MenuItem
                                    color={"customColor.black7"}
                                    fontSize="13px"
                                    onClick={() => {
                                      Notifications.Update(
                                        notification.id,
                                        {
                                          turnOn: !notification.turnOn,
                                        },
                                        (data) => {
                                          setLastUpdated(Date.now());
                                          toast({
                                            title: "Success",
                                            description: "Notification Updated",
                                            status: "success",
                                          });
                                        },
                                        (error) => {
                                          toast({
                                            title: "Error",
                                            description: error,
                                            status: "error",
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    {notification.turnOn ? "Pause" : "Resume"}
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr py="12px">
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          colSpan={6}
                        >
                          <Center w="100%">
                            <Text fontSize={"14px"}>
                              No Notifications Found !
                            </Text>
                          </Center>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Stack>
            </Stack>
          </Fragment>
        )}
      </Container>
    </VStack>
  );
}
