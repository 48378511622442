import {
  Button,
  Checkbox,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

interface ShowAndHideModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function ShowAndHideModal({
  isOpen,
  onClose,
}: ShowAndHideModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxH={"650px"} overflowX={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize="17px"
        >
          Show/hide fields
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTop={"1px solid #ddd"} borderBottom={"1px solid #ddd"}>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={400}
            fontSize={"15px"}
            paddingBottom={"16px"}
          >
            Select fields depending on your invoicing needs. This won't change
            how your invoices look to customers.
          </Text>
          <Stack marginBottom={"20px"}>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={600}
              fontSize={"13px"}
            >
              Invoices fields
            </Text>
            <Stack border={"1px solid #a6a9b0"} borderRadius={"3px"} gap={0}>
              <Stack padding={"7px"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Reference
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Invoice Number
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Issued Date
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Due Date
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontWeight={600}
              fontSize={"13px"}
            >
              Table fields
            </Text>
            <Stack border={"1px solid #a6a9b0"} borderRadius={"3px"} gap={0}>
              <Stack padding={"7px"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Items
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Quantity
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Price
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Discount
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Tax Amount
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Region
                  </Text>
                </Checkbox>
              </Stack>
              <Stack padding={"7px"} borderTop={"1px solid #a6a9b0"}>
                <Checkbox size={"lg"}>
                  <Text
                    fontSize="15px"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"500"}
                  >
                    Project
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>
          </Stack>
          <Text
            paddingTop={"12px"}
            fontSize={"12px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            To Change what customer see on invoice, edit the{" "}
            <Link color={"#3454d1"}>branding theme</Link>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
          >
            {"Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
