import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CustomInput } from "../../../../Common/CustomInput";

interface SaveTemplateProps {
  isOpen: boolean;
  onClose: () => void;
}
export function SaveTemplate({ isOpen, onClose }: SaveTemplateProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"400px"}>
        <ModalHeader padding={"0px 5px 0px 15px"}>
          <Text fontSize={"18px"} padding={"15px 0px"}>
            Save Timesheet as Template
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
        >
          <CustomInput
            withValidation
            input={{
              isInvalid: false,
              w: "100%",
              fontSize: "12px",
              variant: "outline",
              type: "text",
              borderWidth: "1px",
              bg: "#fff",
              borderStyle: "solid",
              borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
              height: "25px",
              borderRadius: 0,
            }}
            placeholder=""
            label="Template Name"
            labelProps={{
              fontSize: "12px",
              fontWeight: "700",
              margin: "0px 0px 5px",
              color: "#444",
            }}
          />
          <Stack
            color={"customColor.gray.800"}
            _dark={{
              color: "customColor.dark.250",
            }}
            fontSize={"12px"}
            paddingTop={"15px"}
            gap={0}
          >
            <Text margin={"0px 0px 15px"} fontWeight={"bold"}>
              Save your valuable time!
            </Text>
            <Text fontWeight={"normal"}>
              Save a timesheet allow you to use the same hours and earnings
              rates in a timesheet later on.
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter padding={"5px"}>
          <ButtonGroup size="sm" marginLeft="700px">
            <Button
              w={"78px"}
              h={"29px"}
              colorScheme="primary"
              fontSize={"12px"}
              padding={"6px 12px"}
              borderRadius="3px"
              border={"1px solid"}
              borderColor={"primary.950"}
              onClick={onClose}
            >
              Continue
            </Button>
            <Button
              w={"78px"}
              h={"28px"}
              marginLeft="20px"
              colorScheme="customGray"
              fontSize={"12px"}
              padding={"6px 0px"}
              border={"1px solid #949a9e"}
              borderRadius="3px"
              onClick={onClose}
              _hover={{ bg: "#949a9e", border: "1px solid #899094" }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
