import { AbsoluteCenter, Box, Image, Text, VStack } from "@chakra-ui/react";
import HrisLogo from "../assets/hris-logo.png";
export default function PageNotFound() {
  return (
    <Box h="calc(100vh - 60px)">
      <AbsoluteCenter>
        <VStack>
          <Box w="350px" h="100px">
            <Image src={HrisLogo} alt="404" />
          </Box>
          <Text fontSize="6xl" fontWeight="bold">
            404
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Page Not Found
          </Text>
          <Text>Apologies, but the page you requested could not be found.</Text>
        </VStack>
      </AbsoluteCenter>
    </Box>
  );
}
