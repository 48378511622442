import { Box, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../Common/CustomInput";

interface AddTaxesProps {
  form: any;
  taxesFieldArray: any;
}
export default function AddTaxes({ form, taxesFieldArray }: AddTaxesProps) {
  return (
    <Box gap={0}>
      {taxesFieldArray.fields.map((item: any, i: any) => (
        <Stack direction="row" alignItems={"center"} key={item.uniqueId}>
          <Stack flex={1}>
            <Text
              fontSize={"12px "}
              margin={"0px 0px 4px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"700"}
            >
              Tax Type
            </Text>
            <Text
              color={"#3454d1"}
              padding={"6px 0px 0px"}
              fontSize={"12px"}
              borderTop={"1px solid #CCC !important"}
              borderBottom={"1px solid #E8EAEC !important"}
              marginBottom={"5px"}
              _hover={{
                cursor: "pointer",
              }}
            >
              Taxes
            </Text>
          </Stack>

          {form.getValues(`taxes.${i}.payItemId`) === item.payItemId && (
            <Stack alignItems={"flex-end"}>
              <HStack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Amount is required",
                    },
                  }}
                  control={form.control}
                  name={`taxes.${i}.amount`}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "12px",
                        variant: "outline",
                        type: "text",
                        value:
                          field.value !== undefined && field.value !== null
                            ? Number(field.value).toLocaleString()
                            : "",

                        onChange: (e: any) => {
                          const value = e.target.value.replace(/,/g, "");
                          field.onChange(Number(value));
                        },

                        maxW: "91.33px",
                        height: "26.33px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                      label="Amount "
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "customColor.black7",
                        margin: "0px 0px 4px",
                      }}
                    />
                  )}
                />
              </HStack>
            </Stack>
          )}

          <Stack>
            <Icon
              fontSize={"20px"}
              as={IoIosCloseCircleOutline}
              marginTop={"15px"}
              color={"grey"}
              _hover={{
                color: "red.500",
              }}
              onClick={() => taxesFieldArray.remove(i)}
            />
          </Stack>
        </Stack>
      ))}
    </Box>
  );
}
