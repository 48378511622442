import {
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { CustomInput } from "../../../../../Common/CustomInput";

interface DeliveryAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DeliveryAddressModal({
  isOpen,
  onClose,
}: DeliveryAddressModalProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Add Delivery Address
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <Stack spacing={"35px"}>
            <InputGroup ml={[0, "0px"]} bg={"#fff"}>
              <InputLeftElement
                pointerEvents="none"
                color="customColor.black7"
                fontSize="1.2em"
              >
                <Icon as={IoSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
              />
            </InputGroup>
            <CustomInput
              withValidation
              input={{
                w: "100%",
                bg: "#fff",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",
                type: "text",

                borderTopRadius: "3px",
              }}
              placeholder=""
              label="Attention"
              labelProps={{
                fontSize: ".845rem",
                fontWeight: "600",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
            <CustomInput
              withValidation
              input={{
                w: "100%",
                bg: "#fff",
                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",
                type: "text",

                borderTopRadius: "3px",
              }}
              placeholder=""
              label="Address"
              labelProps={{
                fontSize: ".845rem",
                fontWeight: "600",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
            <HStack gap={"0.5"}>
              <CustomInput
                withValidation
                input={{
                  w: "100%",
                  bg: "#fff",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="City"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
              <CustomInput
                withValidation
                input={{
                  w: "100%",
                  bg: "#fff",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="State"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            </HStack>
            <HStack gap={"0.5"}>
              <CustomInput
                withValidation
                input={{
                  w: "100%",
                  bg: "#fff",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Zip Code"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
              <CustomInput
                withValidation
                input={{
                  w: "100%",
                  bg: "#fff",
                  h: "38px",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",

                  borderTopRadius: "3px",
                }}
                placeholder=""
                label="Country"
                labelProps={{
                  fontSize: ".845rem",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
              />
            </HStack>
            <Stack
              direction={"row"}
              padding={"7px"}
              bg={"#fff"}
              border={"1px solid #e5e7eb"}
            >
              <Checkbox size={"lg"}>
                <Text fontSize={".845rem"} color={"customInput.black7"}>
                  Use an Billing Address
                </Text>
              </Checkbox>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            borderRadius={"3px"}
            h={"40px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#2c48b5",
            }}
            margin={"0px 0px 0px 10px"}
            w={"104.6px"}
            isLoading={isLoading}
          >
            Add address
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
