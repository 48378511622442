import {
  Avatar,
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { RiArchiveDrawerFill } from "react-icons/ri";
import { TbGitMerge } from "react-icons/tb";
import { AddNewGroup } from "./Components/AddNewGroup";

import { Archive } from "./Components/Modal/Archieve";
import { MergeModal } from "./Components/Modal/MergeModal";
import { SortingFilter } from "./Components/SortingFilter";

export function AllContactsTable() {
  const achieveModal = useDisclosure();
  const mergeModal = useDisclosure();
  return (
    <Stack
      _light={{
        bg: "customColor.white",
      }}
    >
      <Stack
        gap={0}
        // maxWidth={"1164px"}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderRadius="4px"
        bg={"#fff"}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
        borderWidth="1px"
        borderStyle={"solid"}
        borderColor="#f6f6f6"
        p={"0px"}
      >
        <Stack p={"25px"} gap={0} borderBottom={"1px solid #e5e7eb"}>
          <Text
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            margin={"0px 0px 8px"}
          >
            Search
          </Text>
          <HStack>
            <Stack gap={0} flex={1}>
              <InputGroup ml={[0, "0"]}>
                <Input
                  type="text"
                  placeholder="Search"
                  borderRadius={"3px"}
                  h={["auto", "38px"]}
                  _focusVisible={{
                    border: "1px solid grey",
                  }}
                  // onChange={(e) => {
                  //   debounced(e.target.value);
                  // }}
                />
              </InputGroup>
            </Stack>
            <SortingFilter />
          </HStack>
        </Stack>
        <Stack p={"10px 25px"} gap={0} direction={"row"} alignItems={"center"}>
          <Text
            flex={1}
            textAlign={"left"}
            fontSize=".845rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
          >
            Search
          </Text>
          <HStack>
            <AddNewGroup />

            <Button
              h={"32px"}
              color={"#3454d1"}
              fontSize={"15px"}
              bgColor={"customColor.white"}
              leftIcon={<TbGitMerge />}
              fontWeight={"700"}
              variant={"ghost"}
              _hover={{
                bg: "#f2f3f4",
              }}
              _active={{
                bg: "#f2f3f4",
              }}
              onClick={() => {
                mergeModal.onOpen();
              }}
            >
              Merge
            </Button>

            <Button
              h={"32px"}
              color={"#3454d1"}
              fontSize={"15px"}
              bgColor={"customColor.white"}
              leftIcon={<RiArchiveDrawerFill />}
              fontWeight={"700"}
              variant={"ghost"}
              _hover={{
                bg: "#f2f3f4",
              }}
              _active={{
                bg: "#f2f3f4",
              }}
              onClick={() => achieveModal.onOpen()}
            >
              Archive
            </Button>
          </HStack>
        </Stack>
        <Archive isOpen={achieveModal.isOpen} onClose={achieveModal.onClose} />
        <MergeModal isOpen={mergeModal.isOpen} onClose={mergeModal.onClose} />
        <Box overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                <Th
                  padding="8px 26px 8px 30px"
                  borderBottomColor="borders.tableBorder"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                  fontWeight={700}
                  whiteSpace="nowrap"
                  fontSize="12px"
                  textTransform="none"
                >
                  <Checkbox
                    size="md"
                    borderColor="#adb5bd"
                    bgColor="white"
                    //   isChecked={isCheckedAll}
                    //   onChange={handleHeaderCheckboxChange}
                  />
                </Th>
                {[
                  {
                    name: "Contact",
                    value: "75%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "You owe",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "They owe",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },

                  {
                    name: "",
                    value: "5%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {[
                {
                  name: "132 Collins",
                  desc: "03156558833",
                  youOwe: "",
                  theyOwe: "",
                },
                {
                  name: "7-Eleven ",
                  desc: "irsanhashmi10@gmail.com",
                  youOwe: "",
                  theyOwe: "",
                },
                {
                  name: "Abby & Wells ",
                  desc: "",
                  youOwe: "",
                  theyOwe: "",
                },
                {
                  name: "ABC Furniture",
                  desc: "asmakhanam1205@gmail.com",
                  youOwe: "",
                  theyOwe: "",
                },
              ].map((item, i) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"

                        // Stop propagation here
                      />
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Stack direction={"row"}>
                        <Avatar
                          name={item.name}
                          borderRadius={"3px"}
                          size={"sm"}
                        />
                        <Stack gap={0}>
                          <Text
                            fontWeight={600}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                          >
                            {item.name}
                          </Text>
                          <Text
                            fontWeight={400}
                            fontSize="11px"
                            color={"customColor.gray.64748B"}
                          >
                            {item.desc}
                          </Text>
                        </Stack>
                      </Stack>
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item?.youOwe}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {item?.theyOwe}
                    </Td>

                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Menu>
                        <MenuButton
                          as={Button}
                          padding={0}
                          backgroundColor={"transparent"}
                          h={"34.5px"}
                          marginLeft={"8px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          display={{
                            lg: "flex",
                            md: "flex",
                            sm: "flex",
                            xs: "flex",
                          }}
                          borderRadius={"full"}
                          _hover={{
                            backgroundColor: "primary.50",
                          }}
                          _active={{
                            backgroundColor: "primary.100",
                          }}
                        >
                          <Icon as={FiMoreVertical} />
                        </MenuButton>
                        <MenuList style={{ minWidth: "140px" }}>
                          {[
                            {
                              name: "Edit",
                            },
                            {
                              name: "Archive",
                              onClick: () => {
                                achieveModal.onOpen();
                              },
                            },
                          ].map((menuItem, i) => {
                            return (
                              <MenuItem
                                key={i}
                                padding={"1px 0px 1px 20px"}
                                onClick={menuItem.onClick}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                _dark={{
                                  color: "customColor.white",
                                }}
                              >
                                <Button
                                  variant={"ghost"}
                                  fontWeight={"normal"}
                                  fontSize={"13px"}
                                  padding={"0px 20px 0px 0px"}
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.white",
                                  }}
                                  _hover={{ bg: "none" }}
                                >
                                  {menuItem.name}
                                </Button>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </Stack>
  );
}
