import { axiosInstance } from "../../Payroll/Api/axiosInstance-hris";
import { axiosInstanceTime } from "./axiosInstanceTime";

declare module ShiftReport {
  type ReportPayload = {
    locationIds: Array<string>;
    startDate: string;
    endDate: string;
  };
  type GroupingReportPayload = {
    groupBy?:
      | "EMPLOYEE_NAME"
      | "ABN"
      | "TFN"
      | "CONTRACTOR_NANE"
      | "CONTRACTOR_ABN"
      | "CONTRACTOR_ACN"
      | "LOCATION_NAME"
      | "LOCATION_CODE"
      | "LOCATION_INDUSTRY"
      | "LOCATION_CONTACT_NAME"
      | "LOCATION_AREA";
    startDate: string;
    endDate: string;
    employeeId?: string;
    // filters: { [key: string]: string[] };
  };
  type CheckIn = {
    startTime: number;
    endTime: number;
  };

  type Shift = {
    startTime: number;
    endTime: number;
    checkIn: Array<CheckIn>;
  };

  type DayData = {
    shifts: { [key: string]: Shift };
  };

  type ReportResponse = {
    [areaId: string]: {
      [employeeId: string]: {
        [date: string]: DayData;
      };
    };
  };

  type ReportRecord = {
    locationName: string;
    locationAddress: string;
    locationCode: string;
    locationContactName: string;
    locationEmail: string;
    locationPhone: string;
    locationWebsite: string;
    locationIndustry: string;

    employeeName: string;
    employeeEmail: string;
    employeeTFN: string;
    employeeABN: string;
    contractorName: string;
    contractorABN: string;
    contractorACN: string;

    areaName: string;
    shiftId: string;
    shiftScheduleId: string;
    scheduleAreaId: string;
    areaId: string;
    shiftsCheckIns: {
      [date: string]: Array<{
        startTime: number;
        endTime: number;
        message?: string;
        areasCheckIns: Array<{
          startTime: number;
          endTime: number;
        }>;
      }>;
    };
  };
  type GroupingReportResponse = {
    [groupBy: string]: Array<ReportRecord>;
  };

  type WithoutGroupingReportResponse = Array<ReportRecord>;
}
module ShiftReport {
  const API_URL = "employee-shifts-record";
  export function CreateReport(
    data: ReportPayload,
    success: (respose: ReportResponse) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/checkIn`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.data);
      });
  }

  export function CreateGroupingReport(
    data: GroupingReportPayload,
    success: (
      respose: GroupingReportResponse | Array<ReportRecord>
      // filters: {}
    ) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .post(`${API_URL}/timesheet/report`, data)
      .then(({ data }) => {
        if (data?.data) success(data?.data);
        else success(data);
      })
      .catch((e: any) => {
        error(e?.response?.data?.message ?? e?.data);
      });
  }
}
export { ShiftReport };
