import { Box, Button, Checkbox, Icon, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { AiOutlineDrag } from "react-icons/ai";
import "react-resizable/css/styles.css";
import { useBusinessContext } from "../../Hooks/BusinessContext";
import { Analytics } from "../../Payroll/Api/DashboardAnalytics";
import { TaxFinancialYear } from "../../Payroll/Api/TaxFinancialYear";
import TotalStaff from "./Components/TotalStaff";
import TurnOver from "./Components/TurnOver";

export default function ResizableDashboard() {
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const { businessesList, currentBusinessId } = useBusinessContext();
  const toast = useToast();
  const [editDashboard, setEditDashboard] = useState(true);
  const [analytics, setAnalytics] = useState<Analytics.DashboardAnalytics>();
  const [newlayout, setNewLayout] = useState([]);
  useEffect(() => {
    if (currentBusinessId) {
      Analytics.FetchAnalytics(
        (data) => {
          setAnalytics(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [currentBusinessId]);
  const [taxFinancialYear, setTaxFinancialYear] = useState<
    TaxFinancialYear.FetchTax[]
  >([]);
  useEffect(() => {
    TaxFinancialYear.GetTaxFinancialYear(
      (data) => {
        setTaxFinancialYear(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  const [gridBoxes, setGridBoxes] = useState([
    {
      key: "1",
      component: TotalStaff,
      props: {
        analytics,
        editDashboard,
      },
      minW: 2,
      minH: 4,
      x: 0,
      y: 0,
      w: 2,
      h: 4,
      isVisible: true,
    },
    {
      key: "2",
      component: TurnOver,
      props: {
        analytics,
        editDashboard,
      },
      minW: 2,
      minH: 4,
      x: 2,
      y: 0,
      w: 2,
      h: 4,
      isVisible: true,
    },
    // {
    //   key: "3",
    //   component: EmployementType,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 2,
    //   minH: 4,
    //   x: 4,
    //   y: 0,
    //   w: 2,
    //   h: 4,
    //   isVisible: true,
    // },
    // {
    //   key: "4",
    //   component: EmployementCategory,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 2,
    //   minH: 4,
    //   x: 6,
    //   y: 0,
    //   w: 2,
    //   h: 4,
    //   isVisible: true,
    // },
    // {
    //   key: "5",
    //   component: UpgradeAccount,
    //   props: {
    //     // analytics,
    //     editDashboard,
    //   },
    //   minW: 2,
    //   minH: 5,
    //   x: 8,
    //   y: 0,
    //   w: 2,
    //   h: 5,
    //   isVisible: true,
    // },
    // {
    //   key: "6",
    //   component: Provinces,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 2,
    //   minH: 8,
    //   x: 10,
    //   y: 0,
    //   w: 2,
    //   h: 8,
    //   isVisible: true,
    // },
    // {
    //   key: "7",
    //   component: TaxSalbs,
    //   props: {
    //     taxFinancialYear,
    //     editDashboard,
    //   },
    //   minW: 4,
    //   minH: 6,
    //   x: 0,
    //   y: 4,
    //   w: 4,
    //   h: 6,
    //   isVisible: true,
    // },
    // {
    //   key: "8",
    //   component: UpcomingBirthdays,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 4,
    //   minH: 6,
    //   x: 4,
    //   y: 4,
    //   w: 4,
    //   h: 6,
    //   isVisible: true,
    // },
    // {
    //   key: "9",
    //   component: SocialSources,
    //   props: {
    //     // analytics,
    //     editDashboard,
    //   },
    //   minW: 4,
    //   minH: 8,
    //   x: 8,
    //   y: 5,
    //   w: 4,
    //   h: 8,
    //   isVisible: true,
    // },
    // {
    //   key: "10",
    //   component: EmployeesTable,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 8,
    //   minH: 15,
    //   x: 0,
    //   y: 6,
    //   w: 8,
    //   h: 15,
    //   isVisible: true,
    // },
    // {
    //   key: "11",
    //   component: DepartmentalStats,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 6,
    //   minH: 12,
    //   x: 8,
    //   y: 8,
    //   w: 6,
    //   h: 12,
    // },
    // {
    //   key: "12",
    //   component: ShiftRosters,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 8,
    //   minH: 15,
    //   x: 0,
    //   y: 15,
    //   w: 8,
    //   h: 15,
    // },
    // {
    //   key: "13",
    //   component: Attendence,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 4,
    //   minH: 6,
    // },

    // {
    //   key: "14",
    //   component: BarChart,
    //   props: {
    //     analytics,
    //     editDashboard,
    //   },
    //   minW: 8,
    //   minH: 15,
    //   x: 8,
    //   y: 20,
    //   w: 8,
    //   h: 15,
    // },
  ]);

  // const layouts = {
  // lg: gridBoxes.map((box, i) => ({
  //   i: box.key,
  //   x: (i * 2) % 12,
  //   y: Math.floor(i / 6),
  //   // w: 2,
  //   // h: 4,
  //   w: box.minW,
  //   h: box.minH,
  //   minW: box.minW,
  //   minH: box.minH,
  // })),
  // };
  const layout = useMemo(() => {
    return gridBoxes
      .filter((box) => box.isVisible)
      .map((box) => ({
        i: box.key,
        x: box.x,
        y: box.y,
        w: box.minW,
        h: box.minH,
        minW: box.minW,
        minH: box.minH,
      }));
  }, [gridBoxes]);
  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };
  const onLayoutChange = useCallback((layout: any) => {}, []);
  return (
    <Box height="calc(100vh - 72px)" overflow="auto">
      <Button onClick={() => console.log(gridBoxes)}>Save Layout</Button>
      <Button onClick={() => setEditDashboard(!editDashboard)}>Edit</Button>
      <ResponsiveGridLayout
        layouts={{ lg: layout }}
        breakpoints={breakpoints}
        cols={cols}
        rowHeight={30}
        className="layout"
        width={window.innerWidth}
        autoSize={true}
        isDraggable={editDashboard}
        isResizable={editDashboard}
        draggableHandle=".drag-handle"
        onDragStop={onLayoutChange}
        onResizeStop={onLayoutChange}
      >
        {gridBoxes.map((box) => (
          <Box
            key={box.key}
            alignItems="center"
            justifyContent="center"
            color="white"
            borderRadius="md"
            shadow="base"
            _hover={{ shadow: "md" }}
          >
            <Icon
              as={AiOutlineDrag}
              className="drag-handle"
              cursor="move"
              mr={2}
              position="absolute"
              zIndex={999}
            />
            {box.isVisible && <box.component />}
            <Checkbox
              isChecked={box.isVisible}
              onChange={() => {
                const newGridBoxes = gridBoxes.map((item) =>
                  item.key === box.key
                    ? { ...item, isVisible: !item.isVisible }
                    : item
                );
                setGridBoxes(newGridBoxes);
              }}
              color="gray.500"
              mr={2}
              position="absolute"
              zIndex={999}
              top={0}
              right={0}
            >
              Show
            </Checkbox>
          </Box>
        ))}
      </ResponsiveGridLayout>
    </Box>
  );
}
