import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { ProductivityTracker } from "../../Api/ProductivityTracker";
import { Header } from "./Header";
import { SaveTemplate } from "./Modals/SaveTemplate";

export function TimeSheetQuantityDetail() {
  const saveTemplate = useDisclosure();
  const param = useParams();
  const toast = useToast();
  const chunkSize = 7;
  const splitArrayIntoChunks = (array: any[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };
  let location = useLocation();
  const [days, setDays] = useState<Array<Moment>>([]);
  const [splitedDays, setSplitedDays] = useState<Array<Array<Moment>>>([]);
  const [weeklyIndex, setWeeklyIndex] = useState(0);
  const lastDateOfWeek =
    splitedDays[weeklyIndex]?.[splitedDays[weeklyIndex]?.length - 1]?.format(
      "DD MMM YYYY"
    );

  const [timesheetDataById, setTimesheetDataById] =
    useState<ProductivityTracker.FetchTimeSheetData>();
  const [totalHours, setTotalHours] = useState<number>(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (param.id) {
      ProductivityTracker.FetchTimeSheetById(
        param.id!,
        (data) => {
          setTimesheetDataById(data);
          let startDateMoment = moment(data.dateRange.startDate, "YYYY-MM-DD");
          let endDateMoment = moment(data.dateRange.endDate, "YYYY-MM-DD");
          let numberOfDays = endDateMoment.diff(startDateMoment, "days");
          let days = Array.from({ length: numberOfDays + 1 }).map((_, i) => {
            return moment(startDateMoment).add(i, "days");
          });
          setDays(days);
          setSplitedDays(splitArrayIntoChunks(days, chunkSize));
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    } else {
      toast({
        title: "TimeSheet data is not found",
        status: "error",
      });
    }
  }, []);

  const handleNavigateChunk = (direction: "next" | "prev") => {
    if (direction === "next" && weeklyIndex < splitedDays.length - 1) {
      setWeeklyIndex(weeklyIndex + 1);
    } else if (direction === "prev" && weeklyIndex > 0) {
      setWeeklyIndex(weeklyIndex - 1);
    }
  };

  // Recalculate total hours whenever chunk changes
  useEffect(() => {
    if (timesheetDataById) {
      const currentChunk = splitedDays[weeklyIndex];
      let sumHours = 0;

      currentChunk.forEach((day) => {
        const formattedDate = day.format("YYYY-MM-DD");
        const hours = timesheetDataById.employeesData.reduce(
          (total, entry) => total + (entry.dayHours[formattedDate] || 0),
          0
        );

        sumHours += hours;
      });

      setTotalHours(sumHours);
    }
  }, [weeklyIndex, timesheetDataById, splitedDays]);
  const totalQuantityOfAllWeeks = timesheetDataById?.totalQuantity;
  const { employeesList } = useBusinessContext();
  return (
    <Stack
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h="calc(100vh - 70px)"
      overflowY="auto"
    >
      <Box
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        borderBottom={"1px solid #cfd2d4"}
      >
        <Container
          h="50px"
          maxW={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"0px 20px"}
        >
          <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink
                href="Timesheets"
                fontSize={"11px"}
                color={"#3454d1"}
              >
                Timesheet
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href=""
                fontSize={"11px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                padding={"0px 20px 0px 0px"}
              >
                {
                  location?.state?.earningAdded?.find((earning: any) => {
                    return earning.id === timesheetDataById?.earningRateId;
                  })?.name
                }
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading
            fontWeight={"bold"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"18px"}
            margin={"0px 4px 0px 0px"}
          >
            Timesheets for {timesheetDataById?.period}
          </Heading>
        </Container>
      </Box>
      <Container maxW={"container.lg"} padding={"20px 20px"}>
        <Stack paddingTop={"20px"} paddingBottom={"20px"} gap={0}>
          <Header
            weeklyIndex={weeklyIndex}
            setWeeklyIndex={setWeeklyIndex}
            splitedDays={splitedDays}
            lastDateOfWeek={lastDateOfWeek}
            onNavigateChunk={handleNavigateChunk}
            totalWeeksSum={totalQuantityOfAllWeeks}
            earningRateId={timesheetDataById?.earningRateId}
            Status={timesheetDataById?.status}
            earningAdded={location?.state?.earningAdded}
          />
          <Stack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "#FBFBFB",
            }}
            margin={"0px 0px 30px"}
            padding={"15px 0px 20px"}
            border={"1px solid #e5e5e5"}
            overflowX={"auto"}
          >
            <Table variant="simple" w="100%" padding={0}>
              <Thead>
                <Tr>
                  <HStack padding={0} gap={0}>
                    <Th
                      padding="8px 26px 8px 15px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      h={"49px"}
                      flex={1}
                    >
                      Earning
                    </Th>

                    {splitedDays?.[weeklyIndex]?.map((day, i) => (
                      <Stack flex={1} key={i} padding={0} w={"49.04px"} gap={0}>
                        <Th
                          padding="8px 26px 8px 15px"
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          h={"33.2px"}
                          textAlign={"center"}
                          flex={1}
                        >
                          <Text
                            fontSize={"12px"}
                            fontWeight={"normal"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.250",
                            }}
                          >
                            {day.format("ddd")}
                          </Text>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"bold"}
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.250",
                            }}
                          >
                            {day.format("DD MMM")}
                          </Text>
                        </Th>
                      </Stack>
                    ))}
                  </HStack>
                  <Th
                    padding="8px 26px 8px 15px"
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                    h={"33.2px"}
                    textAlign={"center"}
                    flex={2}
                  >
                    Quantity
                  </Th>
                </Tr>
              </Thead>
              <Tbody
                // borderTop={"1.5px solid "}
                // borderColor={"borders.tableBorder"}
                padding={0}
              >
                {timesheetDataById?.employeesData.map((hours, i) => {
                  const dateChunks = splitArrayIntoChunks(
                    Object.entries(hours.dayHours).sort(([date1], [date2]) => {
                      const d1 = new Date(date1).getTime();
                      const d2 = new Date(date2).getTime();
                      return d1 - d2;
                    }),
                    chunkSize
                  );

                  const selectedWeekData = dateChunks[weeklyIndex] || [];

                  return (
                    <Tr
                      key={i}
                      padding={0}
                      fontSize={"12px"}
                      color={"#555"}
                      _hover={{
                        cursor: "pointer",
                        bg: "#eff1f5",
                      }}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "customColor.white",
                      }}
                    >
                      <HStack
                        padding={0}
                        gap={0}
                        alignItems={"center"}
                        borderBottom={"1px"}
                        borderColor={"borders.tableBorder"}
                      >
                        <Td
                          padding="15px"
                          flex={1}
                          border={"none"}
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                        >
                          {hours.employee?.firstName +
                            " " +
                            hours.employee?.lastName}
                        </Td>
                        {selectedWeekData.map(([date, hours]) => (
                          <Stack
                            flex={1}
                            key={i}
                            padding={0}
                            gap={0}
                            justify={"center"}
                            h={"auto"}
                          >
                            <Td
                              key={date}
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              flex={1}
                              border={"none"}
                              h={"auto"}
                            >
                              <Text textAlign={"center"}>{hours}</Text>
                            </Td>
                          </Stack>
                        ))}
                      </HStack>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        flex={2}
                        textAlign={"center"}
                        borderBottom={"1px"}
                        borderColor={"borders.tableBorder"}
                      >
                        {selectedWeekData.reduce(
                          (total, [, hours]) => total + hours,
                          0
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            variant={"link"}
            color={"#3454d1"}
            fontSize={"12px"}
            margin={"0px 10px 15px 0px"}
            padding={"6px 12px"}
            fontWeight={"normal"}
            onClick={saveTemplate.onOpen}
          >
            Save as Template
          </Button>
          <Stack direction={"row"} alignContent={"flex-end"}>
            {timesheetDataById?.status === "APPROVED" && (
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() =>
                  navigate("/app/payroll/TimesheetsEnterQuatityDetail", {
                    state: {
                      earningAdded: location?.state?.earningAdded,
                      timesheetId: timesheetDataById?.id,
                      earningRateId: timesheetDataById?.earningRateId,
                      calenderId: timesheetDataById?.calenderId,
                      period: timesheetDataById?.period,
                      status: timesheetDataById?.status,
                      dateRange: timesheetDataById?.dateRange,
                    },
                  })
                }
              >
                Revert to Draft
              </Button>
            )}

            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              color={"#ea4d4d"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={() => navigate("/app/payroll/Timesheets")}
            >
              Close
            </Button>
          </Stack>
        </Stack>
        <SaveTemplate
          isOpen={saveTemplate.isOpen}
          onClose={saveTemplate.onClose}
        />
      </Container>
    </Stack>
  );
}
