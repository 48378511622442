import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { Home } from "./Home/Home";

export default function Reports() {
  const isXs = useBreakpointValue({ sm: true, md: false });

  return (
    <Stack overflowX={"auto"} h="calc(100vh - 70px)">
      <Tabs w="100%">
        <Stack
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          h={{ sm: "auto", md: "60px" }}
        >
          <HStack justifyContent="space-between" alignItems={"center"}>
            <Stack flex={1} direction={{ xs: "column", md: "row" }}>
              <Text
                fontWeight={"bold"}
                _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black7",
                }}
                padding={"16px 12px 16px 0px "}
                fontSize={"17px"}
                margin={"0px 4px 0px 16px"}
              >
                Reports
              </Text>
              <TabList padding={0}>
                <Tab
                  fontSize={{ xs: "12px", lg: "15px" }}
                  padding={{ xs: "0px 10px", lg: "0px 20px" }}
                  h={"62px"}
                >
                  Home
                </Tab>
                <Tab
                  fontSize={{ xs: "12px", lg: "15px" }}
                  padding={{ xs: "0px 10px", lg: "0px 20px" }}
                >
                  Custom
                </Tab>
                <Tab
                  fontSize={{ xs: "12px", lg: "15px" }}
                  padding={{ xs: "0px 10px", lg: "0px 20px" }}
                >
                  Drafts
                </Tab>
                <Tab
                  fontSize={{ xs: "12px", lg: "15px" }}
                  padding={{ xs: "0px 10px", lg: "0px 20px" }}
                >
                  Published
                </Tab>
                <Tab
                  fontSize={{ xs: "12px", lg: "15px" }}
                  padding={{ xs: "0px 10px", lg: "0px 20px" }}
                >
                  Archive
                </Tab>
              </TabList>
            </Stack>

            {isXs ? (
              <Icon as={FiSearch} fontSize={"20px"} marginRight={"16px"} />
            ) : (
              <InputGroup border={"none"} width={"440px"} marginRight={"16px"}>
                <InputLeftElement>
                  <Icon as={FiSearch} />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search"
                  border={"1px solid lightgrey"}
                  borderRadius={"3px"}
                  h={["auto", "40px"]}
                  _focusVisible={{
                    border: "1px solid grey",
                  }}
                />
              </InputGroup>
            )}
          </HStack>
        </Stack>
        <TabPanels>
          <TabPanel padding={0}>
            <Home />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
