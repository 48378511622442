import { axiosInstance } from "./axiosInstance-hris";
declare module EmployeeLeaveModule {
  enum LeaveCategory {
    NORMAL_LEAVE = "NORMAL_LEAVE",
    CASHOUT_LEAVE = "CASHOUT_LEAVE",
  }
  type PayPeriodData = {
    id?: string | undefined;
    startDate: string;
    endDate: string;
    quantity: number;
    leaveId?: string;
    string: string | undefined;
  };
  type EmployeeData = {
    firstName: string;
    lastName: string;
  };
  type LeaveEntitlementData = {
    id: string;
    payrollPayItemsLeave: {
      name: string;
      unitType: string;
    };
  };
  type NewLeave = {
    startDate: string;
    endDate: string;
    quantity: number;
    description: string;
    leaveCategory: string;
    notes: string;
    leaveEntitlementId: string;
    payrollPayItemLeaveId: string;
    payoutDate: string;
    payPeriods: Array<PayPeriodData>;
  };
  type Leave = {
    id: string;
    empId: string;
    leaveCategory: string;
    payrollPayItemLeaveId: string;
    leaveEntitlementId: string;
    startDate: string;
    endDate: string;
    quantity: number;
    description: string;
    status: string;
    note: string;
    payoutDate: string;

    employee?: Array<EmployeeData>;
    payPeriods: Array<PayPeriodData>;
    leaveEntitlement: Array<LeaveEntitlementData>;
  };
}
module EmployeeLeaveModule {
  const API_URL = "/employee-leaves";
  export function CreateLeave(
    data: NewLeave,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/submit-leave-request`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }

  export function FetchLeave(
    empId: string,
    success: (data: Array<Leave>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { EmployeeLeaveModule };
