import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import { isAfter } from "date-fns";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { EmployeeExperience } from "../../../../../Api/EmployeeExperience";

interface ExperienceModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
}
export default function AddExperienceModal({
  isOpen,
  onClose,
  empId,
  setLastUpdated,
}: ExperienceModalProps) {
  let countryData = Country.getAllCountries();
  const form = useFormContext<EmployeeExperience.AddExperience>();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const today = new Date(); // Get the current date
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Experience
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Position
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Position is required",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  name="position"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Employer Name
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="companyName"
                  rules={{
                    required: {
                      value: true,
                      message: "Employee Name is required",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Employer Email
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="employerEmail"
                  rules={{
                    required: {
                      value: true,
                      message: "Employer email is required",
                    },
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Employer Contact Number
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="employeeContactNumber"
                  rules={{
                    required: {
                      value: true,
                      message: "Employer contact is required",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: error !== undefined,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Country
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{ required: true }}
                  name="country"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Country"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select country"
                      withValidation
                      options={countryData}
                      value={countryData.find((op) => op.name === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.name)
                      }
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.name}
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Date From
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="startDate"
                  rules={{
                    required: "This field is required",
                    validate: {
                      isNotFuture: (value) =>
                        isAfter(today, new Date(value)) ||
                        "Date cannot be a future date",
                      isBeforeDateTo: (value) =>
                        !form.getValues("endDate") ||
                        isAfter(
                          new Date(form.getValues("endDate")),
                          new Date(value)
                        ) ||
                        "Date from must be before Date to",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={fieldState.error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "date",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Date to
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  rules={{
                    required: "This field is required",
                    validate: {
                      isNotFuture: (value) =>
                        isAfter(today, new Date(value)) ||
                        "Date cannot be a future date",
                      isAfterDateFrom: (value) =>
                        !form.getValues("startDate") ||
                        isAfter(
                          new Date(value),
                          new Date(form.getValues("startDate"))
                        ) ||
                        "Date to must be after date from",
                    },
                  }}
                  name="endDate"
                  render={({ field, fieldState }) => (
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      validationMessage={fieldState.error?.message}
                      input={{
                        ...field,
                        w: "100%",
                        isInvalid: fieldState.invalid,
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "date",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Job description
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="jobDescription"
                  rules={{
                    required: {
                      value: true,
                      message: "Please add job description",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      fontSize={"0.8125rem"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      placeholder="Add Job Description"
                      isInvalid={fieldState.invalid}
                    />
                  )}
                />
                {form.formState.errors.jobDescription && (
                  <Text color="red.500" fontSize="15px">
                    {form.formState.errors.jobDescription.message}
                  </Text>
                )}
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Reason for leaving
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="leavingReason"
                  rules={{
                    required: {
                      value: true,
                      message: "Please add reason for leaving",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      fontSize={"0.8125rem"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      placeholder="Add Reason for leaving"
                      isInvalid={fieldState.invalid}
                    />
                  )}
                />
                {form.formState.errors.leavingReason && (
                  <Text color="red.500" fontSize="15px">
                    {form.formState.errors.leavingReason.message}
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (form.getValues("id")) {
                  EmployeeExperience.UpdateExperience(
                    form.getValues("id")!,
                    data,
                    (success) => {
                      toast({
                        title: "Experience Updated",
                        status: "success",
                      });
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: err,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                } else {
                  EmployeeExperience.PostExperience(
                    empId!,
                    data,
                    (success) => {
                      toast({
                        title: "Experience Added",
                        status: "success",
                      });
                      setIsLoading(false);
                      setLastUpdated(Date.now());
                      onClose();
                    },
                    (err) => {
                      toast({
                        title: err,
                        status: "error",
                      });
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {isLoading ? "Loading" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
