import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { PayItemDeductionApi } from "../../../../Api/PayItemDeduction";

interface DeleteDeductionProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId: string | undefined;
  setLastUpdated: (timeStamp: number) => void;
}

export function DeleteDeduction({
  isOpen,
  onClose,
  selectedId,
  setLastUpdated,
}: DeleteDeductionProps) {
  const toast = useToast();
  const form = useFormContext();
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Delete Deduction
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          fontSize={"0.8125rem"}
          fontWeight={"600"}
          _light={{
            color: "customColor.black7",
          }}
        >
          <p>Are you sure you want to delete this Deduction?</p>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onClose}
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fontSize={"13px"}
            padding={"10px 15px"}
            borderRadius={"3px"}
            minH={"40px"}
            border={"1px solid #FDEDED"}
            bg={"#FDEDED"}
            color={"#EA4D4D"}
            minW={"75px"}
            _hover={{
              border: "1px solid #EA4D4D",
              bg: "#EA4D4D",
              color: "#fff",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit(() => {
                setIsLoading(true);
                PayItemDeductionApi.DeleteDeduction(
                  selectedId,

                  (success) => {
                    setLastUpdated(Date.now());
                    toast({
                      title: "Delete Successfully",

                      status: "success",
                    });
                    setIsLoading(false);
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: "Delete Failed",
                      description: error,
                      status: "error",
                    });
                    setIsLoading(false);
                  }
                );
              })(e);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
