import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

export function EarningRate() {
  const [selectedStatus, setSelectedOptionStatus] = useState("All");

  const handleOptionSelectStatus = (option: any) => {
    setSelectedOptionStatus(option);
  };
  const earningRate = [
    "All",
    "Allowances exempt from tax withholding and super",
    "Allowances subject to tax withholding and super",
    "Director Fees",
    "JobKeeper Payment top up",
    "Ordinary Hours",
    "Overtime Hours (exempt from super)",
    "Paid Parental Leave",
    "Redundancy",
    "Worker Compensation",
  ];
  return (
    <Stack margin={"20px 0px 0px"} padding={"0px 0px 0px 10px"}>
      <HStack gap={0}>
        <Text
          fontSize="13px"
          display={"block"}
          fontWeight="700"
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          Earning Rate
        </Text>
        <Text
          paddingLeft={"3px"}
          fontSize="13px"
          display={"block"}
          fontWeight="400"
          _light={{
            color: "customColor.black2",
          }}
          _dark={{
            color: "customColor.white",
          }}
        >
          (Required)
        </Text>
      </HStack>

      <Box>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            width={"100%"}
            textAlign={"start"}
            h={"36px"}
            _light={{
              bgColor: "customColor.white",
            }}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            color={"black"}
            fontWeight={"400"}
            border={"1px solid #b7bec5"}
            borderRadius={"5px"}
            _hover={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid #a6a9b0",
            }}
            _active={{
              color: "black",
              bg: "#f2f3f4",
              border: "1px solid #a6a9b0",
            }}
          >
            {selectedStatus}
          </MenuButton>
          <MenuList overflowY="auto" zIndex={"overlay"} minW={"300px"}>
            {earningRate.map((item, i) => {
              return (
                <MenuItem
                  key={1}
                  padding={"0px 12px"}
                  _hover={{
                    backgroundColor: "rgba(0,10,30,.1)",
                  }}
                  onClick={() => handleOptionSelectStatus(item)}
                >
                  <Text
                    padding={"8px"}
                    margin={"0px 12px 0px 0px"}
                    _light={{
                      color: "customColor.black5",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"15px"}
                  >
                    {item}
                  </Text>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Box>
    </Stack>
  );
}
