import { ChevronDownIcon } from "@chakra-ui/icons"; // Example icon from Chakra UI
import { FormLabelProps, useColorMode } from "@chakra-ui/react";
import React from "react";
import { TbMinusVertical } from "react-icons/tb";
import Select, { components, StylesConfig } from "react-select";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import { CustomInput } from "./CustomInput";

interface ReactSelectProps extends React.ComponentProps<StateManagedSelect> {
  ErrorMessage?: string;
  label?: string;
  withValidation?: boolean;
  isInvalid?: boolean;
  labelProps?: FormLabelProps;
}

type SelectTypes = StylesConfig | undefined;

const customSytles = (styles?: SelectTypes) =>
  ({
    ...styles,
    container: (provided, state) => ({
      ...provided,
      display: "block",
      width: "100%",
      borderColor: "#e5e7eb",
      borderWidth: "1",
      minHeight: "40px",
      zIndex: 0,
      ...styles?.container?.(provided, state),
    }),
    control: (provided, state) => ({
      ...provided,
      w: "100%",
      zIndex: 0,
      minHeight: "35px",
      display: "block",
      borderWidth: "1px",
      borderRadius: "3px",
      fontSize: ".845rem",
      fontWeight: "normal",
      borderColor: "#e5e7eb",
      borderTopRadius: "3px",
      boxShadow: "0 0 0 0 transparent !important",
      ...styles?.control?.(provided, state),
    }),

    option: (provided, state) => ({
      ...provided,
      zIndex: 1000,
      color: state.isSelected
        ? "customColor.white"
        : state.isFocused
        ? "black" // Change color to black on hover
        : "customColor.black7",
      // backgroundColor: state.isSelected ? "#32465A" : "transparent",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  } as SelectTypes);

// Custom Dropdown Indicator Component
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <TbMinusVertical fontSize={"22px"} color={"hsl(0,0%,80%)"} />
      <ChevronDownIcon fontSize={"22px"} />
    </components.DropdownIndicator>
  );
};

export const ReactSelect = React.forwardRef<any, ReactSelectProps>(
  (
    {
      ErrorMessage,
      label,
      withValidation,
      isInvalid,
      labelProps,
      ...SelectProps
    },
    ref
  ) => {
    const { colorMode } = useColorMode();
    return (
      <CustomInput
        label={label}
        withValidation={withValidation}
        validationMessage={ErrorMessage}
        input={{
          isInvalid,
          style: {
            fontSize: ".845rem",
            display: "block",
          },
        }}
      >
        <Select
          components={{ DropdownIndicator, IndicatorSeparator: null }} // Use the custom dropdown indicator
          styles={customSytles({
            control: (provided) => ({
              ...provided,
              borderColor: isInvalid ? "red" : "#e5e7eb",
            }),
            menu: (provided, state) => ({
              ...provided,
              backgroundColor: colorMode === "light" ? "white" : "#30303a",
              color: colorMode === "light" ? "customColor.black7" : "white",
              fontSize: ".845rem",
            }),
          })}
          {...SelectProps}
          ref={ref}
          menuPortalTarget={document.body}
          menuPlacement="auto"
        />
      </CustomInput>
    );
  }
);
