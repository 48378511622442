import { Text } from "@chakra-ui/layout";
import {
  Button,
  Container,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Link,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GoLock } from "react-icons/go";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Authentication } from "../../Api/Authentication";
import HRISLOGO from "./../../../assets/hris-logo.png";
export function ResetPassword() {
  const [queryParams] = useSearchParams();
  const { control, handleSubmit, watch } =
    useForm<Authentication.ResetPassword>({
      defaultValues: {
        password: "",

        token: queryParams.get("token") ?? "",
      },
    });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const password = watch("password");
  const passwordsMatch = password === confirmPassword;
  const navigate = useNavigate();
  const toast = useToast();
  const background1 = require("../../../assets/backgroundImages/background1.jpg");
  const background2 = require("../../../assets/backgroundImages/background2.jpg");
  const background3 = require("../../../assets/backgroundImages/background3.jpg");
  const background4 = require("../../../assets/backgroundImages/background4.jpg");
  const background5 = require("../../../assets/backgroundImages/background5.jpg");
  const background6 = require("../../../assets/backgroundImages/background6.jpg");
  const background7 = require("../../../assets/backgroundImages/background7.jpg");
  const background8 = require("../../../assets/backgroundImages/background8.jpg");
  const background9 = require("../../../assets/backgroundImages/background9.jpg");
  const background10 = require("../../../assets/backgroundImages/background10.jpg");
  const background11 = require("../../../assets/backgroundImages/background11.jpg");
  const background12 = require("../../../assets/backgroundImages/background12.jpg");
  const background13 = require("../../../assets/backgroundImages/background13.jpg");
  const background14 = require("../../../assets/backgroundImages/background14.jpg");
  const background15 = require("../../../assets/backgroundImages/background15.jpg");
  const background16 = require("../../../assets/backgroundImages/background16.jpg");
  const background17 = require("../../../assets/backgroundImages/background17.jpg");
  const background18 = require("../../../assets/backgroundImages/background18.jpg");
  const [backgroundImage, setBackgroundImage] = useState("");
  const getRandomImage = () => {
    const images = [
      // backgroundImages,
      background1,
      background2,
      background3,
      background4,
      background5,
      background6,
      background7,
      background8,
      background9,
      background10,
      background11,
      background12,
      background13,
      background14,
      background15,
      background16,
      background17,
      background18,
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };
  useEffect(() => {
    setBackgroundImage(getRandomImage());
  }, []);
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      gap={0}
      _light={{
        backgroundColor: "customColor.gray.900",
      }}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      h={"100vh"}
      w={"100wh"}
      overflow={"hidden"}
    >
      <Stack
        flex={{ base: 0, md: 9 }}
        display={{ base: "none", lg: "block" }}
        height={"130vh"}
      >
        <Image
          src={backgroundImage}
          alt="Background"
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          bottom={"0px"}
          h={"100%"}
          objectFit={"cover"}
          w={"100%"}
        />
      </Stack>
      <Stack
        gap={0}
        overflowX="hidden"
        padding={"78px 98px"}
        flex={{ base: 1, md: 4 }}
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        // issue in _light
        _light={{
          backgroundColor: {
            base: "customColor.gray.400",
            lg: "customColor.white",
          },
        }}
        borderLeft={"1px solid #ddd"}
        zIndex={1}
      >
        <Container
          bgColor={{
            base: "white",
            lg: "transparent",
          }}
          padding={{ base: "20px", lg: "0px 20px" }}
          borderRadius={"10px"}
          boxShadow={{
            base: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
            lg: "none",
          }}
        >
          <Stack padding={"0px"} gap={0}>
            <Image
              objectFit="fill"
              w="115px"
              margin={"0px 0px 24px"}
              src={HRISLOGO}
              alt="Logo"
              onClick={() => {
                navigate("/");
              }}
            />
            <Heading
              fontSize={"24px"}
              fontWeight={"700"}
              margin={"0px 0px 24px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Resetting
            </Heading>
            <Text
              fontSize={"13px"}
              fontWeight={"700"}
              margin={"0px 0px 8px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.pearlWhite",
              }}
            >
              Reset to your password
            </Text>
            <Text
              fontSize={"13px"}
              _light={{
                color: "customColor.gray.64748B",
              }}
              _dark={{
                color: "customColor.pearlWhite",
              }}
            >
              Your password should be difficult for the others to guess. We
              recommend that you use a combination of upper case and lower case
              as well as numbers.
            </Text>
          </Stack>
          <Stack padding={"8px 0px 0px"} margin={"24px 0px 0px"} gap={0}>
            <Stack margin={"0px 0px 24px"} gap={0}>
              <Text
                fontSize="13.44px"
                _light={{ color: "customColor.black7" }}
                _dark={{ color: "customColor.white" }}
                fontWeight="600"
                marginBottom="8px"
              >
                Enter New Password
              </Text>
              <InputGroup>
                <InputLeftAddon h="40px">
                  <GoLock />
                </InputLeftAddon>
                <Controller
                  control={control}
                  name="password"
                  render={({ field, fieldState: { invalid } }) => (
                    <Stack width="100%">
                      <Input
                        placeholder="Enter New Password"
                        borderRadius="0px"
                        borderRightRadius="5px"
                        type={showPassword ? "text" : "password"}
                        h="40px"
                        fontSize=".845rem"
                        fontWeight={400}
                        _light={{ color: "customColor.black7" }}
                        padding="12px 15px"
                        border="1px solid #e5e7eb"
                        _hover={{ border: "1px solid hsl(0, 0%, 70%)" }}
                        _focusVisible={{
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        _focus={{
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        }}
                        isInvalid={invalid}
                        {...field}
                      />
                      <InputRightElement>
                        <IconButton
                          fontWeight="900"
                          fontStyle="normal"
                          color="#555b6d"
                          _hover={{ bg: "none" }}
                          aria-label="Toggle password visibility"
                          icon={showPassword ? <IoIosEyeOff /> : <IoMdEye />}
                          variant="outline"
                          borderWidth="0px"
                          h="24px"
                          w="20px"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                    </Stack>
                  )}
                />
              </InputGroup>
            </Stack>
            <Stack margin={"0px 0px 40px"} gap={0}>
              <Text
                fontSize="13.44px"
                _light={{ color: "customColor.black7" }}
                _dark={{ color: "customColor.white" }}
                fontWeight="600"
                marginBottom="8px"
              >
                Confirm New Password
              </Text>
              <InputGroup>
                <InputLeftAddon h="40px">
                  <GoLock />
                </InputLeftAddon>
                <Stack width="100%">
                  <Input
                    placeholder="Confirm New Password"
                    borderRadius="0px"
                    borderRightRadius="5px"
                    type={showConfirmPassword ? "text" : "password"}
                    h="40px"
                    fontSize=".845rem"
                    fontWeight={400}
                    _light={{ color: "customColor.black7" }}
                    padding="12px 15px"
                    border="1px solid #e5e7eb"
                    _hover={{ border: "1px solid hsl(0, 0%, 70%)" }}
                    _focusVisible={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _focus={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={!passwordsMatch && confirmPassword !== ""}
                  />
                  <InputRightElement>
                    <IconButton
                      fontWeight="900"
                      fontStyle="normal"
                      color="#555b6d"
                      _hover={{ bg: "none" }}
                      aria-label="Toggle confirm password visibility"
                      icon={showConfirmPassword ? <IoIosEyeOff /> : <IoMdEye />}
                      variant="outline"
                      borderWidth="0px"
                      h="24px"
                      w="20px"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  </InputRightElement>
                </Stack>
              </InputGroup>
              {!passwordsMatch && confirmPassword !== "" && (
                <Text color="red.500" fontSize="12px" mt="2">
                  Passwords do not match
                </Text>
              )}
            </Stack>
            <Button
              type="submit"
              data-testid="login-button"
              height={"40px"}
              size={"md"}
              w={"100%"}
              fontSize={"13px"}
              borderRadius={"3px"}
              fontWeight={700}
              colorScheme="Cornflower"
              padding={"15px 20px"}
              lineHeight={"1rem"}
              onClick={handleSubmit((data) => {
                if (passwordsMatch) {
                  Authentication.Reset(
                    data,
                    () => {
                      toast({
                        title: "Password Reset Successful",
                        status: "success",
                      });
                      navigate("/ConfirmPasswordReset");
                    },
                    (error) => {
                      toast({
                        title: "Password Change Failed",
                        description:
                          error ||
                          "An error occurred while changing the password.",
                        status: "error",
                      });
                    }
                  );
                } else {
                  toast({
                    title: "Password Mismatch",
                    description: "The passwords you entered do not match.",
                    status: "error",
                  });
                }
              })}
              isDisabled={!passwordsMatch}
            >
              Reset Password
            </Button>
          </Stack>
          <Stack padding={0} margin={"48px 0px 0px"} gap={0}>
            <HStack>
              {" "}
              <Text
                fontSize={"14px"}
                _light={{
                  color: "customColor.gray.64748B",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                Don't have an account?
              </Text>
              <Link
                fontSize="14px"
                padding="0px"
                margin={"0px"}
                color="customColor.black7"
                fontWeight={"700"}
                href="/signup"
                _hover={{ color: "#3454d1" }}
              >
                Create an Account
              </Link>
            </HStack>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
