import { axiosInstance } from "./axiosInstance-hris";
declare module PayItemReimbursementApi {
  type AddNewReimbursement = {
    id?: string;
    name: string;
    chartAccountId: string;
  };
  type ReimbursementById = {
    id: string;
    name: string;
    active: boolean;
    chartOfAccount: {
      code: 0;
      name: string;
    };
  };
}
module PayItemReimbursementApi {
  const API_URL = "/payroll-pay-items-reimbursements";
  export function NewReimbursementAdded(
    data: AddNewReimbursement,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function FetchReimbursement(
    success: (data: Array<ReimbursementById>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateReimbursement(
    id: string,
    data: Partial<AddNewReimbursement>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((err) => {
        error(
          err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
            ? err?.response?.data?.message
            : err?.response?.data?.message?.join(", ") ?? err.message
        );
      });
  }
  export function IsActive(
    id: string,
    isActive: boolean,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/status/${id}/${isActive}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function DeleteReimbursement(
    id: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message && e.message);
      });
  }
}
export { PayItemReimbursementApi };
