import { usePlacesWidget } from "react-google-autocomplete";
import { CustomInput, InputProps } from "./CustomInput";

interface PlaceDetails {
  formatted_address: string;
  location: {
    lat: number;
    lng: number;
  };
  place_id: string;
  country: string;
  postcode?: string;
  city?: string;
  street?: string;
  street_number?: string;
  state?: string;
  route?: string;
  subpremise?: string;
  premise?: string;
  neighborhood?: string;
  sublocality?: string;
  sublocality_level_1?: string;
}
export interface AddressInputProps extends InputProps {
  onPlaceSelected: (place: PlaceDetails) => void;
}
export function AddressInput(props: AddressInputProps) {
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: "AIzaSyCZ6p-O1f1OsTcmPNOEitFCQBBDML9fOr4",
    onPlaceSelected: (place) => {
      props.onPlaceSelected({
        formatted_address: place.formatted_address as string,
        location: {
          lat: place?.geometry?.location?.lat() as number,
          lng: place?.geometry?.location?.lng() as number,
        },
        place_id: place.place_id as string,
        country: place?.address_components?.find((c: any) =>
          c.types.includes("country")
        )?.long_name as string,
        postcode: place?.address_components?.find((c: any) =>
          c.types.includes("postal_code")
        )?.long_name as string,
        city: place?.address_components?.find((c: any) =>
          c.types.includes("locality")
        )?.long_name as string,
        state: place?.address_components?.find((c: any) =>
          c.types.includes("administrative_area_level_1")
        )?.long_name as string,
        street_number: place?.address_components?.find((c: any) =>
          c.types.includes("street_number")
        )?.long_name as string,
        street: place?.address_components?.find((c: any) =>
          c.types.includes("route")
        )?.long_name as string,
        route: place?.address_components?.find((c: any) =>
          c.types.includes("route")
        )?.long_name as string,
        subpremise: place?.address_components?.find((c: any) =>
          c.types.includes("subpremise")
        )?.long_name as string,
        premise: place?.address_components?.find((c: any) =>
          c.types.includes("premise")
        )?.long_name as string,
        neighborhood: place?.address_components?.find((c: any) =>
          c.types.includes("neighborhood")
        )?.long_name as string,
        sublocality: place?.address_components?.find((c: any) =>
          c.types.includes("sublocality")
        )?.long_name as string,
        sublocality_level_1: place?.address_components?.find((c: any) =>
          c.types.includes("sublocality_level_1")
        )?.long_name as string,
      });
    },
    options: {
      types: ["geocode", "establishment"],
    },
  });
  return (
    <CustomInput
      {...props}
      ref={ref}
      placeholder={props.input?.placeholder ?? ""}
    />
  );
}
