// SetDateModal.tsx
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayRun } from "../../../Api/PayRun";
import { PayslipsModule } from "../../../Api/PaySlips";

interface SetDateProps {
  payslipId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSelectDate: (selectedDate: string) => void; // Pass selected date to parent
}

export function SetDateModal({
  isOpen,
  onClose,
  onSelectDate,
  payslipId,
}: SetDateProps) {
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleConfirm = () => {
    const formattedDate = moment(new Date(selectedDate), "d MMM yyyy").format(
      "D MMM YYYY"
    );
    onSelectDate(formattedDate); // Pass selected date to parent
    onClose();
  };
  const form = useFormContext<PayRun.PayrunByEmpId>();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"380px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          padding={"15px 5px 15px 15px"}
          fontWeight={"700"}
          lineHeight={"1.75rem"}
          h={"52px"}
        >
          Set Date
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={"15px"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
          border={"1px solid #E5E5E5"}
          fontSize={"12px"}
        >
          <Text
            margin={"0px 0px 5px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Payment Date
          </Text>
          <Controller
            control={form.control}
            name={"paymentDate"}
            render={({ field }) => (
              <CustomInput
                ref={field.ref}
                withValidation
                input={{
                  ...field,
                  w: "100%",
                  padding: "12px 15px",
                  _light: {
                    color: "customColor.black7",
                  },
                  fontSize: ".845rem",
                  backgroundColor: "#fff",

                  variant: "outline",
                  type: "date",
                  lineHeight: "1.2",
                  h: "36px",

                  borderRadius: "4px",
                }}
              />
            )}
          />
        </ModalBody>

        <ModalFooter padding={"0px"}>
          <ButtonGroup
            size="sm"
            marginTop="10px"
            marginBottom={"10px"}
            marginRight={"10px"}
          >
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={() => {
                PayslipsModule.changePaymentDate(
                  payslipId!,
                  { paymentDate: form.getValues("paymentDate") },
                  (success) => {
                    toast({
                      title: "Payment Date Updated",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    form.setValue("paymentDate", form.getValues("paymentDate"));
                    form.reset({
                      ...form.getValues(),
                      paymentDate: form.getValues("paymentDate"),
                    });
                    onClose();
                  },
                  (err) => {
                    toast({
                      title: "Payment Date Update Failed",
                      status: "error",
                    });
                  }
                );
              }}
            >
              Save
            </Button>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"8px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
