import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { Shift } from "../../../API/Shift";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface UpdateBulkShiftsModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function UpdateBulkShiftsModal({
  isOpen,
  onClose,
}: UpdateBulkShiftsModalProps) {
  const { control, setValue } = useForm<
    Shift.ShiftsInBulk & {
      areas: Shift.FetchShift["areas"];
    }
  >();
  const [selectedOption, setSelectedOption] = useState<{
    name: any;
    label: string;
    type: string;
  }>({ name: "startTime", label: "Start Time", type: "time" });
  const fieldOptions = [
    { name: "startTime", label: "Start Time", type: "time" },
    { name: "endTime", label: "End Time", type: "time" },
    { name: "mealBreak", label: "Meal Break", type: "number" },
    { name: "restBreak", label: "Rest Break", type: "number" },
    { name: "remarks", label: "Notes", type: "text" },
  ];
  const { employees, locations } = useScheduleContext();
  // const [areasList, setAreasList] = useState<Location.ILocationMin['areas']>(
  //   [],
  // );
  const [areasList, setAreasList] = useState<any>([]);
  // const inputField: Array<{
  //   name: string,
  //   type: string,
  //   onchange: ()=> void
  // }>=[{
  //   type:
  // }]

  return (
    <form>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px="32px" pt="32px" pb="0px">
            <HStack>
              <Text
                flex={1}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize=".9975rem"
              >
                Bulk update (no. of shifts) shifts
              </Text>
              <IconButton
                aria-label="close"
                variant="ghost"
                icon={<AiOutlineClose color="#1da5d4" size="20px" />}
                onClick={onClose}
              />
            </HStack>
          </ModalHeader>

          <ModalBody px="32px" pt="28px" pb="24px">
            <HStack w="100%" justifyContent="space-between">
              <Stack w="100%">
                <Controller
                  control={control}
                  name="employeeId"
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder="Select Employee"
                        options={employees}
                        getOptionLabel={(option: any) => option.preferredTitle}
                        getOptionValue={(option: any) => option.id}
                        value={
                          field.value
                            ? {
                                id: field.value,
                                preferredTitle:
                                  employees.find(
                                    (emp: any) => emp.id === field.value
                                  )?.preferredTitle ?? "",
                              }
                            : undefined
                        }
                        onChange={(val: any) => {
                          field.onChange(val?.id);
                        }}
                      />
                    );
                  }}
                />
              </Stack>
              <Stack w="100%">
                <Controller
                  control={control}
                  name="locationId"
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder="Select Location"
                        options={locations}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        value={
                          field.value
                            ? {
                                id: field.value,
                                name:
                                  locations.find(
                                    (loc: any) => loc.id === field.value
                                  )?.name ?? "",
                              }
                            : undefined
                        }
                        onChange={(val: any) => {
                          field.onChange(val?.id);
                          setAreasList(val?.areas ?? []);
                          setValue("areas", []);
                        }}
                      />
                    );
                  }}
                />
              </Stack>
            </HStack>
            <Stack w="100%" marginTop="8px">
              <Controller
                control={control}
                name="mealBreak"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      placeholder="Select Area"
                      options={areasList}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      value={field.value}
                      onChange={(val: any) => {
                        field.onChange(val);
                        setValue(
                          "areaList",
                          val.map((area: any) => area.id)
                        );
                      }}
                    />
                  );
                }}
              />
            </Stack>
            <Text
              fontWeight={400}
              _light={{
                color: "customColor.black7",
              }}
              fontSize="13.44px"
              marginTop="8px"
            >
              This change will apply to all shifts visible.
            </Text>
            <HStack>
              <Stack w="40%">
                <ReactSelect
                  getOptionLabel={(op: any) => op.label}
                  getOptionValue={(op: any) => op.name}
                  placeholder="Choose field"
                  options={fieldOptions}
                  value={selectedOption}
                  onChange={(newValue: any) => {
                    setSelectedOption(newValue);
                  }}
                />
              </Stack>
              <Stack w="60%">
                <Controller
                  key={selectedOption?.name}
                  control={control}
                  name={selectedOption?.name}
                  render={({ field }) => {
                    return (
                      <CustomInput
                        key={selectedOption.name}
                        input={{
                          // ...field,
                          variant: "outline",
                          type: selectedOption?.type,
                          placeholder: selectedOption.label,
                        }}
                      />
                    );
                  }}
                />
              </Stack>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
            >
              Apply Update!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}
