import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { CustomInput } from "../../../Common/CustomInput";
import { useBusinessContext } from "../../../Hooks/BusinessContext";

interface ProcessProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: React.Dispatch<React.SetStateAction<number>>;
}
export default function ProcessSuperannuation({
  isOpen,
  onClose,
  setLastUpdated,
}: ProcessProps) {
  const { businessesList, currentBusinessId } = useBusinessContext();
  const [file, setFile] = useState<File | null>(null);

  // Explicitly typing the ref to refer to an HTMLInputElement
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      setFile(droppedFile);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          padding={"16px 12px"}
        >
          Process Payment
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderTop={"1px solid #ddd"}
          borderBottom={"1px solid #ddd"}
          padding={"16px 12px"}
        >
          <Text
            fontSize={"0.8125rem"}
            fontWeight={"400"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {" "}
            Enter the date on which you wish to process this payment and attach
            a challan in below box
          </Text>

          <Stack w={"250px"} paddingTop={"12px"} paddingBottom={"20px"}>
            <CustomInput
              input={{
                w: "100%",

                h: "38px",
                padding: "12px 15px",
                _light: {
                  color: "customColor.black7",
                },
                fontSize: ".845rem",
                variant: "outline",
                type: "date",

                borderTopRadius: "3px",
              }}
              placeholder=""
              label="Enter Date of Processing"
              labelProps={{
                fontSize: ".845rem",
                fontWeight: "600",
                _light: {
                  color: "customColor.black7",
                },
              }}
            />
          </Stack>

          <Text
            fontSize={"13px"}
            _light={{
              color: "customColor.black7",
            }}
            paddingBottom={"4px"}
          >
            <b>File to upload</b> (required)
          </Text>
          <Stack
            marginBottom={"20px"}
            border={"1px dashed #a6a9b0"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={"20px"}
            textAlign={"center"}
            borderRadius={"3px"}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            position="relative"
          >
            {file ? (
              <>
                <Image
                  src={URL.createObjectURL(file)}
                  alt="Dropped file"
                  maxWidth="100%"
                  maxHeight="170px"
                  borderRadius="3px"
                />
                <IconButton
                  icon={<CloseIcon />}
                  size="sm"
                  colorScheme="red"
                  position="absolute"
                  top="10px"
                  right="10px"
                  onClick={handleDelete}
                  aria-label="Delete Image"
                />
              </>
            ) : (
              <>
                <Text
                  fontSize={"15px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  marginBottom={"12px"}
                >
                  Drag and drop file or select manually
                </Text>
                <Button
                  backgroundColor={"transparent"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "#3454D1",
                  }}
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  Select File
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
              </>
            )}
          </Stack>
          <Text
            paddingTop={"10px"}
            borderTop={"1px solid #ddd"}
            fontSize={"13px"}
            color={"customColor.black7"}
          >
            Reminder: This transaction will appear on your bank statement as
            <b>
              {" "}
              {
                businessesList.find(
                  (business) => business.id === currentBusinessId
                )?.name
              }
            </b>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            fontSize={"13px"}
            h={"32px"}
            bgColor="#3454D1"
            marginRight={"10px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={onClose}
          >
            Process
          </Button>

          <Button
            onClick={onClose}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"32px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
