import axiosInstanceTime from "./axiosInstanceTime";

declare module AreasModule {
  type Area = {
    id: string;
    name: string;
    color: string;
    locationId: string;
    location: {
      id: string;
      name: string;
      business_id: string;
      code: string;
      address: string;
      addressCoordinates: {
        latitude: string;
        longitude: string;
      };
    };
  };
}
module AreasModule {
  const API_URL = "/areas";
  export function FetchAreas(
    success: (data: Array<Area>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchAreasByLocationId(
    locationId: string,
    success: (data: Array<Area>) => void,
    error: (message: string) => void
  ) {
    axiosInstanceTime
      .get(`${API_URL}/areas/${locationId}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { AreasModule };
