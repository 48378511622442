import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useRef, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { MdDelete } from "react-icons/md";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { Employees } from "../../../Api/Employees";
const excelFile = require("../../../../assets/template/employee.xlsx");
interface BulkModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdatedEmployees: (timestamp: number) => void;
}
export default function BulkModal({
  isOpen,
  onClose,
  setLastUpdatedEmployees,
}: BulkModalProps) {
  const auth = useAuthHeader();
  const [data, setData] = useState<Array<Employees.BulkUpload>>([]);
  const [columns, setColumns] = useState<Array<string>>([]);
  const [rows, setRows] = useState();
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const { setLastUpdated } = useBusinessContext();
  const [isUploading, setIsUploading] = useBoolean(false);

  function convertExcelDateToStandard(excelDate: number): string {
    const baseDate = new Date(1899, 11, 30);
    const jsDate = new Date(
      baseDate.getTime() + excelDate * 24 * 60 * 60 * 1000
    );
    const formattedDate = moment(jsDate).format("YYYY-MM-DD");
    return formattedDate;
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const uploadedData = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setColumns(uploadedData[0] as string[]);
      setRows(uploadedData.slice(1) as any);
      setData(transformData(uploadedData));
    };

    reader.readAsBinaryString(file);
  };

  const transformData = (dataArray: any) => {
    return dataArray.slice(1).map((row: any) => ({
      title: row[0],
      firstName: row[1],
      lastName: row[2],
      dateOfBirth: row[3],
      cnic: row[10],
      employeeEmployementDetails: {
        startDate: row[7],
        jobTitle: row[5],
        department: row[9],
      },
      gender: row[4],
      email: row[6],
      mobileNumber: row[15],
      address: row[8],
      city: row[11],
      province: row[12],
      country: row[13],
      postalCode: row[14],
      employeeBankDetails: {
        bankName: row[16],
        iban: row[17],
        accountNumber: row[18],
        branchCode: row[19],
        accountName: row[20],
      },
      grossSalary: row[21],
      uniqueId: row[22],
    }));
  };

  // const consoleData = () => {
  //   const decodedToken = jwtDecode<any>(auth());
  //   const formattedData = {
  //     employees: data.map((item) => ({
  //       title: item.title,
  //       firstName: item.firstName,
  //       lastName: item.lastName,
  //       cnic: String(item.cnic),
  //       dateOfBirth: convertExcelDateToStandard(Number(item.dateOfBirth)),
  //       gender: item.gender,
  //       email: item.email,
  //       mobileNumber: String(item.mobileNumber),
  //       address: item.address,
  //       city: item.city,
  //       province: item.province,
  //       country: item.country,
  //       postalCode: String(item.postalCode),
  //       // baseSalary: item.baseSalary,
  //       businessId: decodedToken.businessId,
  //       employeeBankDetails: {
  //         bankName: item.employeeBankDetails.bankName,
  //         iban: String(item.employeeBankDetails.iban),
  //         accountNumber: " ",
  //         branchCode: " ",
  //         accountName: " ",
  //       },
  //       employeeEmployementDetails: {
  //         startDate: convertExcelDateToStandard(
  //           Number(item.employeeEmployementDetails.startDate)
  //         ), // Assuming startDate is provided in Excel date format.
  //         department: item.employeeEmployementDetails.department,
  //         jobTitle: item.employeeEmployementDetails.jobTitle,
  //       },
  //     })),
  //   };
  //   setIsUploading.on();
  //   Employees.BulkUploadEmployee(
  //     formattedData,
  //     (res) => {
  //       toast({
  //         title: "Employees added successfully",
  //         status: "success",
  //       });

  //       setLastUpdated(Date.now());
  //       setLastUpdatedEmployees(Date.now());
  //       setIsUploading.off();
  //       handleDelete();
  //       onClose();
  //     },
  //     (err) => {
  //       setIsUploading.off();
  //       toast({
  //         title: "Something went wrong",
  //         description: err,
  //         status: "error",
  //       });
  //     }
  //   );
  // };
  const handleDelete = () => {
    setData([]);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody mt={"50px"}>
          <Container maxW="lg" justifyContent="center" alignItems="center">
            <Heading
              as="h3"
              _light={{
                color: "customColor.black7",
              }}
              fontSize="24px"
              textAlign="center"
            >
              Add employees here
            </Heading>
            <Heading
              as="h4"
              _light={{
                color: "customColor.black7",
              }}
              fontSize="18px"
              mt="50px"
              textAlign="center"
            >
              Drag and drop your list of employees here
            </Heading>
            <Flex
              w="100%"
              justifyContent="center"
              alignItems="center"
              mt="16px"
            >
              <PiMicrosoftExcelLogoFill size={24} color="#21A366" />
            </Flex>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              fontSize="13.44px"
              textAlign="center"
              mt="16px"
            >
              You can also{" "}
              <Link
                color="primary.950"
                href={excelFile}
                download="EmployeeUploadTemplate.xlsx"
              >
                download a template
              </Link>
            </Text>

            <Input
              ref={fileInputRef}
              mt="16px"
              display={fileName ? "none" : "block"}
              border="none"
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              w="250px"
            />
            {fileName && (
              <HStack spacing="2px" mt="16px">
                <PiMicrosoftExcelLogoFill size={18} color="#21A366" />
                <Text>{fileName}</Text>
                <IconButton
                  aria-label={""}
                  icon={<MdDelete size={18} />}
                  bg="none"
                  color="gray.500"
                  _hover={{ bg: "none" }}
                  onClick={handleDelete}
                />
              </HStack>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            marginRight={"8px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => {
              handleDelete();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isUploading}
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={() => {
              data.length > 0
                ? // ? consoleData()
                  navigate("/app/payroll/EmployeeBulkUploadPreview", {
                    state: {
                      columns: columns,
                      rows: rows,
                    },
                  })
                : toast({
                    title: "Please upload a file or add data in file",
                    status: "error",
                  });
            }}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
