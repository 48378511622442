import {
  Box,
  Container,
  Image,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { PayslipsModule } from "../../Api/PaySlips";
import PayslipDownloading from "../Employees/PayslipdownloadingButton";

export default function EmployeePayslips() {
  const { currentEmployeeId } = useBusinessContext();
  const toasts = useToast();
  const [payslips, setPayslips] = useState<Array<PayslipsModule.PayslipData>>(
    []
  );
  useEffect(() => {
    PayslipsModule.GetPayslips(
      { employeeId: currentEmployeeId! },
      (response) => {
        setPayslips(response);
      },
      (message) => {
        toasts({
          title: "Error",
          description: message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  }, []);
  return (
    // <Stack direction={"row"} spacing={6}>
    <Stack padding={"24px"} spacing={6}>
      <Stack flex={4} gap={0}>
        <Text
          fontWeight="600"
          lineHeight="1.2"
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black7",
          }}
          fontSize="18px"
          margin={"0px 0px 15px"}
        >
          {" "}
          Payslips History
        </Text>
        <Container
          maxW={"100%"}
          padding={0}
          margin={0}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderRadius="4px"
          bg={"#fff"}
          boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
          //   padding={"16px"}
        >
          <Box overflowX="auto" p="0px" w="100%">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Sr.",
                      value: "2%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    // {
                    //   name: "Emp ID",
                    //   value: "5%",
                    //   paddingValue: "8px 26px 8px 15px",
                    // },
                    {
                      name: "Month",
                      value: "18%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Period",
                      value: "30%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Wages",
                      value: "10%",
                      paddingValue: "8px 15px 8px 15px",
                    },
                    {
                      name: "Reimbursements",
                      value: "10%",
                      paddingValue: "8px 15px 8px 15px",
                    },

                    {
                      name: "Tax DD",
                      value: "5%",
                      paddingValue: "8px 15px 8px 15px",
                    },

                    {
                      name: "Other DD",
                      value: "5%",
                      paddingValue: "8px 15px 8px 15px",
                    },
                    {
                      name: "Net Pay",
                      value: "10%",
                      paddingValue: "8px 15px 8px 15px",
                    },
                    {
                      name: "",
                      value: "5%",
                      paddingValue: "8px 15px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              {payslips?.length > 0 ? (
                <Tbody>
                  {payslips?.map((payslip, i) => (
                    <Tr
                      key={i}
                      _hover={{
                        bgColor: "#f2f3f8",
                        cursor: "pointer",
                      }}
                    >
                      <Td
                        padding="15px 15px 15px 30px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {i + 1}
                      </Td>
                      {/* <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      ></Td> */}
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {moment(payslip?.payRun?.startDate).format("MMM YYYY")}
                      </Td>
                      <Tooltip
                        label={`${
                          payslip?.payRun?.payrollCalendar?.name
                        } ending ${moment(payslip?.payRun?.endDate).format(
                          "DD MMM YYYY"
                        )}`}
                        placement="top"
                      >
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          textTransform="none"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxW={"70px"}
                        >
                          {`${
                            payslip?.payRun?.payrollCalendar?.name
                          } ending ${moment(payslip?.payRun?.endDate).format(
                            "DD MMM YYYY"
                          )}`}
                        </Td>
                      </Tooltip>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {payslip?.totalEarnings.toLocaleString()}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        textAlign={"center"}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {payslip?.totalReimbursements.toLocaleString()}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        textAlign={"center"}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {payslip?.totalTax.toLocaleString()}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        textAlign={"center"}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {payslip?.totalDeductions?.toLocaleString()}
                      </Td>
                      {/* <Td
                      padding="12px"
                      fontSize="14px"
                      color={"customColor.tableHeaderColor"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor="inherit"
                      flex={1}
                    >
                      {payslip?.totalDeductions}
                    </Td> */}
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {`${(
                          payslip?.totalEarnings +
                          payslip?.totalReimbursements -
                          payslip?.totalDeductions -
                          payslip?.totalTax
                        ).toLocaleString()}`}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <PayslipDownloading
                          id={payslip?.id}
                          paymentDate={payslip?.paymentDate}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              ) : (
                <Tbody>
                  <Tr>
                    <Td colSpan={9} style={{ verticalAlign: "middle" }}>
                      <VStack
                        alignItems="center"
                        color="#999"
                        pt="30px"
                        pb="40px"
                      >
                        <Image
                          boxSize="30"
                          src="https://mts.rs/Picture/4339/png/prijava-smetnji.png"
                        />
                        <Text
                          fontSize="15px"
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          No Salary Slips
                        </Text>
                      </VStack>
                    </Td>
                  </Tr>
                </Tbody>
              )}
            </Table>
          </Box>
        </Container>
      </Stack>
      <Stack flex={1} gap={0}>
        <Text
          fontWeight="600"
          lineHeight="1.2"
          color={"customColor.black"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black7",
          }}
          fontSize="18px"
          margin={"0px 0px 15px"}
        >
          {" "}
          Payment Summary
        </Text>
        <Text
          fontWeight="500"
          lineHeight="24px"
          color={"customColor.black"}
          _dark={{
            color: "customColor.dark.150",
          }}
          _light={{
            color: "customColor.black7",
          }}
          fontSize="12px"
          margin={"0px 0px 12px"}
        >
          {" "}
          You have not been issued any payment summaries.
        </Text>
      </Stack>
    </Stack>
  );
}
