import axiosInstanceGateway from "./axiosInstance-gateway";
import { axiosInstance } from "./axiosInstance-hris";
declare module Analytics {
  type barGraphData = {
    management: {
      Masters: number;
      Bachelors: number;
      Diploma: number;
      Intermediate: number;
      Matriculation: number;
    };
    nonManagement: {
      Masters: number;
      Bachelors: number;
      Diploma: number;
      Intermediate: number;
      Matriculation: number;
    };
  };
  type DashboardAnalytics = {
    totalEmpCount: string;
    maleGenderEmpCount: string;
    femaleGenderEmpCount: string;
    otherGenderEmpCount: string;
    fullTimeEmployees: string;
    partTimeEmployees: string;
    casualEmployees: string;
    managementContractStaffCount: number;
    managementPermanentStaffCount: number;
    managementStaffCount: number;
    employeeCountInProvinces: {
      [key: string]: number;
    };
    employeeCountInDepartments: {
      [key: string]: number;
    };
    educationChart: barGraphData;
    birthdaysThisMonth: Array<birthday>;
    employeesStartedThisMonth: string;
    employeesEndedThisMonth: string;
  };
  type DashBoardData = {
    id?: string;
    layout: Array<{
      key: string;
      minW: number;
      component: any;
      props: any;
      minH: number;
      x: number;
      y: number;
      w: number;
      h: number;
      isVisible: boolean;
    }>;
  };
  type birthday = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    photo: string;
    id: string;
  };
}
module Analytics {
  const API_URL = "/analytics/dashboard";
  export function FetchAnalytics(
    success: (message: DashboardAnalytics) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  const API_URL_DASHBOARD = "/configurations";
  export function PostDashboardConfig(
    data: DashBoardData,
    success: (data: DashBoardData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL_DASHBOARD}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetDashboardConfig(
    success: (data: DashBoardData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL_DASHBOARD}/active`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateDashboardConfig(
    id: string,
    data: Partial<DashBoardData>,
    success: (data: DashBoardData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .patch(`${API_URL_DASHBOARD}/${id}`, data)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  const API_URL_BIRTHDAY = "/employees/send-birthday-email";
  export function Birthday(
    empId: string | undefined,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL_BIRTHDAY}/${empId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Analytics };
