import axiosInstanceGateway from "./axiosInstance-gateway";

declare module BusinessAgreementApi {
  type BusinessAgreementData = {
    id?: string;
    contractStartDate: string;
    contractEndDate: string;
    clientReference: string;
  };
}
module BusinessAgreementApi {
  const API_URL = "/business-setup-agreements";
  export function AddBusinessAgreements(
    data: BusinessAgreementData,
    success: (data: BusinessAgreementData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function GetBusinessAgreements(
    businessId: string | undefined,
    success: (data: BusinessAgreementData) => void,
    error: (message: string) => void
  ) {
    axiosInstanceGateway
      .get(`${API_URL}/${businessId}`)
      .then(({ data }) => {
        success(data?.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { BusinessAgreementApi };
