import { HStack, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import { Employees } from "../../../Api/Employees";
interface DetailsProps {
  employeeData: Employees.EmployeeAllDetail | undefined;
}
export function Employment({ employeeData }: DetailsProps) {
  const startDate = moment(employeeData?.employeeEmployementDetails?.startDate);
  const now = moment();

  const years = now.diff(startDate, "years");
  startDate.add(years, "years");

  const months = now.diff(startDate, "months");

  const service = `${years} Years ${months} Months`;
  return (
    <Stack flex={1} padding={"0px 5px"} margin={"0px 0px 20px"}>
      <Text
        _dark={{
          color: "customColor.white",
        }}
        _light={{
          color: "#3454d1",
        }}
        fontWeight={"700"}
        justifyContent={"space-between"}
        borderBottom={"2px solid #dadddf"}
        padding={"0px 0px 10px"}
        margin={"14px 0px 20px"}
        _hover={{
          _light: {
            color: "#3454d1",
          },
        }}
      >
        Employment
      </Text>

      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Job Title
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.employeeEmployementDetails?.jobTitle ?? "NA"}
          </Text>
        </Stack>
      </HStack>
      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Department
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.employeeEmployementDetails?.department ?? "NA"}
          </Text>
        </Stack>
      </HStack>
      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Start Date
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {employeeData?.employeeEmployementDetails
              ? moment(
                  employeeData?.employeeEmployementDetails?.startDate
                ).format("D MMMM YYYY")
              : "NA"}
          </Text>
        </Stack>
      </HStack>
      <HStack alignItems={"flex-start"}>
        <Stack flex={1}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
            fontWeight={"700"}
          >
            Service Duration
          </Text>
        </Stack>
        <Stack flex={2}>
          <Text
            _dark={{
              color: "customColor.white",
            }}
            _light={{
              color: "customColor.black7",
            }}
            fontSize={"13px"}
          >
            {service}
          </Text>
        </Stack>
      </HStack>
    </Stack>
  );
}
