import { Box } from "@chakra-ui/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDashboardContext } from "../../Hooks/DashboardContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type AttributeType = "gender" | "department" | "province" | "employeementType";
type CalculationType = "sum" | "average" | "median" | "min" | "max";

interface DynamicBarChartProps {
  attribute: AttributeType;
  calculation?: CalculationType;
  colors: string[]; // Now colors are passed as props from parent
  fontStyle?: "normal" | "italic" | "oblique";
  fontWeight?: "normal" | "bold";
  fontSize?: number;
}

// Calculation logic to process the data based on the calculation type
const calculateValues = (
  values: number[],
  calculation: CalculationType
): number[] => {
  switch (calculation) {
    case "min":
      const minValue = Math.min(...values);
      return values.map((value) => (value === minValue ? value : 0));
    case "max":
      const maxValue = Math.max(...values);
      return values.map((value) => (value === maxValue ? value : 0));
    case "average":
      const avgValue = values.reduce((a, b) => a + b, 0);
      return values.map((value) =>
        parseFloat(((value * 100) / avgValue).toFixed(2))
      );
    case "median":
      values.sort((a, b) => a - b);
      const mid = Math.floor(values.length / 2);
      const medianValue =
        values.length % 2 !== 0
          ? values[mid]
          : (values[mid - 1] + values[mid]) / 2;
      return new Array(values.length).fill(medianValue);
    case "sum":
    default:
      return values;
  }
};

// Function to check if a color is a gradient
function isGradient(color: string): boolean {
  return color.includes("linear-gradient");
}

// Function to fix the gradient by ensuring `rgba` and `rgb` are lowercase
function normalizeColorCase(color: string): string {
  return color.replace(/RGB/gi, "rgb").replace(/RGBA/gi, "rgba");
}

// Function to extract both hex and rgba colors from the gradient string
function extractColorsFromGradient(gradientString: string): string[] {
  const colors: string[] = [];
  // Regex for rgba and rgb
  const regexRgb = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([0-9.]+))?\)/g;
  // Regex for hex colors
  const regexHex = /#([0-9a-f]{3,6})/gi;
  let matchRgb, matchHex;

  // Match rgb and rgba colors
  while ((matchRgb = regexRgb.exec(gradientString)) !== null) {
    const [_, r, g, b, a] = matchRgb;
    colors.push(a ? `rgba(${r},${g},${b},${a})` : `rgb(${r},${g},${b})`);
  }

  // Match hex colors
  while ((matchHex = regexHex.exec(gradientString)) !== null) {
    colors.push(matchHex[0]); // Add hex color directly
  }

  return colors;
}

// Function to create gradient from extracted colors
function createGradientFromColors(ctx: any, chartArea: any, colors: string[]) {
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.top,
    0,
    chartArea.bottom
  );
  const colorStops = colors.length;

  colors.forEach((color, index) => {
    // Calculate the correct position for each color stop between 0 and 1
    const position = index / (colorStops - 1);
    gradient.addColorStop(position, color);
  });

  return gradient;
}

export default function DynamicBarChart({
  attribute,
  calculation = "sum",
  colors = ["#54a0ff"], // Default fallback color
  fontStyle = "normal",
  fontWeight = "normal",
  fontSize = 12,
}: DynamicBarChartProps) {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();

  // Ensure the colors array is not empty
  const safeColors = colors.length > 0 ? colors : ["#54a0ff"];

  let labels: string[] = [];
  let dataValues: number[] = [];

  // Prepare data based on the attribute prop
  if (attribute === "gender") {
    labels = ["Male", "Female"];
    dataValues = [
      dashboardAnalytics?.maleGenderEmpCount || 0,
      dashboardAnalytics?.femaleGenderEmpCount || 0,
    ];
  } else if (attribute === "department") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInDepartments || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInDepartments || {}
    );
  } else if (attribute === "province") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInProvinces || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInProvinces || {}
    );
  } else if (attribute === "employeementType") {
    labels = ["Full Time", "Part Time", "Daily Wagers"];
    dataValues = [
      dashboardAnalytics?.fullTimeEmployees || 0,
      dashboardAnalytics?.partTimeEmployees || 0,
      dashboardAnalytics?.casualEmployees || 0,
    ];
  }

  // Apply the calculation logic (sum, average, median, etc.)
  const calculatedDataValues = calculateValues(dataValues, calculation);

  const data = {
    labels,
    datasets: [
      {
        label: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Distribution`,
        data: calculatedDataValues, // Use the calculated data
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return safeColors.slice(0, labels.length);
          }

          return safeColors.slice(0, labels.length).map((color) => {
            // Fix the gradient by ensuring it ends at 100%
            const fixedColor = normalizeColorCase(color);

            if (isGradient(fixedColor)) {
              const extractedColors = extractColorsFromGradient(fixedColor); // Extract the colors from the gradient
              return createGradientFromColors(ctx, chartArea, extractedColors); // Apply the gradient to the chart
            } else {
              // Solid color
              return fixedColor;
            }
          });
        },
        borderColor: safeColors.slice(0, labels.length),
        borderWidth: 1,
        hoverBackgroundColor: safeColors.map((color) => {
          const fixedColor = normalizeColorCase(color);
          if (isGradient(fixedColor)) {
            const extractedColors = extractColorsFromGradient(fixedColor);
            return extractedColors[0]; // Use the first color for hover
          }
          return fixedColor;
        }),
        hoverBorderColor: "#000",
        borderRadius: 5, // Rounded corners for 3D effect
        borderSkipped: false, // Remove border skipping for consistent bar appearance
        barThickness: 50,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // Adjust this value according to your data range
        },
      },
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          font: {
            size: fontSize,
            weight: fontWeight,
            style: fontStyle,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: fontSize + 2,
            weight: fontWeight,
            style: fontStyle,
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: fontSize,
          weight: fontWeight,
          style: fontStyle,
        },
        backgroundColor: "#fff", // White background for contrast
        borderColor: "#000", // Border around tooltip
        borderWidth: 1,
        titleColor: "#000", // Title text color
        bodyColor: "#000", // Body text color
        displayColors: false, // Remove color box in tooltip
      },
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      minW={"100%"}
      p="20px"
      overflowY="auto"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Bar
        data={data as any}
        options={options}
        style={{ width: "100%", height: "100%", alignSelf: "center" }}
      />
    </Box>
  );
}
