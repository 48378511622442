import { Avatar, Box, Center, Link, SimpleGrid, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export default function SocialSources() {
  const form = useFormContext<Analytics.DashBoardData>();
  const { editDashboard } = useDashboardContext();
  return (
    // <Card
    //   h="100%"
    //   w="100%"
    //   border="1px"
    //   borderColor="#f6f6f6"
    //   borderRadius="4px"
    //   boxShadow="0 4px 8px rgba(15,34,58,.35)"
    //   _hover={{
    //     boxShadow: "0 4px 8px rgba(15,34,58,.65)",
    //   }}
    //   // display={
    //   //   editDashboard ||
    //   //   form.getValues("hideOrShowTiles.socialSourcesData.visible")
    //   //     ? "block"
    //   //     : "none"
    //   // }
    // >
    <Box
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      w="100%"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
    >
      <Box p={"25px"} borderBottom={"1px solid #e5e7eb"}>
        <Text
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight="1.2"
          fontSize="16px"
          fontWeight={600}
        >
          Social Source
        </Text>
      </Box>

      <Box padding={"25px"}>
        <Center>
          <Link href="https://maps.app.goo.gl/enU5mDeDPz7qCT8g6">
            <Avatar
              size="xl"
              h="40px"
              w="40px"
              objectFit="fill"
              name="Google"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8fWzhbo26PtpnIdCayT1beq3UOPcbPiD7uPvfx0nTfQ&s"
            />
          </Link>
        </Center>
      </Box>
      <Center>
        <Text
          as="a"
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          fontWeight={500}
          lineHeight="1.2"
          fontSize="18px"
          textAlign={"center"}
          mb="8px"
          href="https://maps.app.goo.gl/enU5mDeDPz7qCT8g6"
        >
          Location
        </Text>
      </Center>
      <Text
        textAlign="center"
        _light={{
          color: "customColor.gray.64748B",
        }}
        _dark={{
          color: "customColor.dark.250",
        }}
        fontSize="14.4px"
        fontWeight={400}
        lineHeight={1}
        mb="16px"
        mx="10px"
      >
        Here are our social sources where you can find us and get in
      </Text>

      <SimpleGrid columns={3}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center" // This centers the text within the Text components.
          h="full" // You may need to set a specific height for the Box to center content vertically within it, or use 'full' to take up all available space.
          px="10px"
        >
          <Link href="https://www.facebook.com/profile.php?id=61556203667985">
            <Avatar
              size="xl"
              my="16px"
              h="32px"
              w="32px"
              objectFit="cover"
              name="Facebook"
              src="https://www.facebook.com/images/fb_icon_325x325.png"
            />
          </Link>
          <Text
            as="a"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={500}
            lineHeight="1.2"
            mb="8px"
            fontSize="15px"
            cursor={"pointer"}
            href="https://www.facebook.com/profile.php?id=61556203667985"
          >
            Facebook
          </Text>
          {/* <Text
                _light={{
                   color: "customColor.gray.50"
               }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={400}
                  lineHeight="1.2"
                  fontSize="14.4px"
                >
                  125 sales
                </Text> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center" // This centers the text within the Text components.
          h="full" // You may need to set a specific height for the Box to center content vertically within it, or use 'full' to take up all available space.
          px="10px"
        >
          <Link href="https://www.linkedin.com/company/hrisonline">
            <Avatar
              size="xl"
              my="16px"
              h="32px"
              w="32px"
              objectFit="cover"
              name="LinkedIn"
              src="https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png"
            />
          </Link>
          <Text
            as="a"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={500}
            lineHeight="1.2"
            mb="8px"
            fontSize="15px"
            cursor={"pointer"}
            href="https://www.linkedin.com/company/hrisonline"
          >
            Linkedin
          </Text>
          {/* <Text
                _light={{
                   color: "customColor.gray.50"
               }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={400}
                  lineHeight="1.2"
                  fontSize="14.4px"
                >
                  112 sales
                </Text> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center" // This centers the text within the Text components.
          h="full" // You may need to set a specific height for the Box to center content vertically within it, or use 'full' to take up all available space.
          px="10px"
        >
          <Avatar
            size="xl"
            my="16px"
            h="32px"
            w="32px"
            objectFit="cover"
            name="Instagram"
            src="https://www.pngimages.in/uploads/png-webp/2022/2022-November/transparent_background_instagram_logo1.webp"
          />
          <Text
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.150",
            }}
            fontWeight={500}
            lineHeight="1.2"
            mb="8px"
            fontSize="15px"
          >
            Instagram
          </Text>
          {/* <Text
                _light={{
                   color: "customColor.gray.50"
               }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={400}
                  lineHeight="1.2"
                  fontSize="14.4px"
                >
                  104 sales
                </Text> */}
        </Box>
      </SimpleGrid>
      {/* <Flex
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              mt="16px"
            >
              <Text color="#5b73e8" fontSize="14.4px" fontWeight={500}>
                View All Sources
              </Text>
              <Icon
                as={MdKeyboardArrowRight}
                alignSelf="center"
                color={"#5b73e8"}
                fontWeight={400}
              />
            </Flex> */}
    </Box>
    // </Card>
  );
}
