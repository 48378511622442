import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

export default function HistoryAccordion() {
  return (
    <Accordion allowMultiple>
      <AccordionItem
        border={"none"}
        _light={{
          bgColor: "customColor.white",
        }}
        _dark={{
          bgColor: "customColor.dark.100",
        }}
      >
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                h={"56px"}
                padding={0}
                paddingBottom={"10px"}
                _light={{
                  bgColor: "customColor.white",
                }}
                _dark={{
                  bgColor: "customColor.dark.100",
                }}
                _hover={{
                  _light: {
                    bgColor: "customColor.white",
                  },
                }}
              >
                {isExpanded ? (
                  <Button
                    variant={"ghost"}
                    color={"black"}
                    borderRadius={"full"}
                    _hover={{
                      backgroundColor: "#f5f6f7",
                      color: "#000a1e",
                    }}
                  >
                    <RiArrowUpSLine fontSize={"24px"} />
                  </Button>
                ) : (
                  <Button
                    variant={"ghost"}
                    color={"black"}
                    borderRadius={"full"}
                    _hover={{
                      backgroundColor: "#f5f6f7",
                      color: "#000a1e",
                    }}
                  >
                    <RiArrowDownSLine fontSize="24px" />
                  </Button>
                )}
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  padding={"12px 16px 12px 0px"}
                >
                  <Text
                    fontSize={"15px"}
                    _light={{ color: "customColor.customBlack" }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"700"}
                    lineHeight={"1.5rem"}
                  >
                    History
                  </Text>
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel padding={0}>
              <Table variant="simple" w="100%">
                <Thead borderTop={"1px solid grey"}>
                  <Tr>
                    {["Date", "User", "Item", "Detail"].map((item, i) => {
                      return <Th key={i}>{item}</Th>;
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {[
                      "14 sep 2023 10.50pm",
                      "Irsam Hashmi",
                      "Superannuation fund,Chris Oldfield",
                      "Added superannuation fund: 'type'-Regulated Superannuation Fund, 'name'-Chris Oldfield, 'SPIN'-, 'USI'-35829057722001, 'auto migrated fund'-, 'employer number'-0652551066 ''status'-Active",
                    ].map((item, i) => {
                      return (
                        <Td key={i} fontSize={"13px"}>
                          {item}
                        </Td>
                      );
                    })}
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
